var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel", attrs: { id: "panel" } },
    [
      _c(
        "el-container",
        { staticClass: "layout-container" },
        [
          _c(
            "el-header",
            { staticClass: "layout-header", attrs: { height: "80px" } },
            [_c("header-view")],
            1
          ),
          _c(
            "el-container",
            { staticClass: "layout-mid" },
            [
              _c(
                "el-aside",
                { staticClass: "layout-aside" },
                [
                  _c(
                    "el-scrollbar",
                    { staticStyle: { height: "100%" } },
                    [_c("sidebar-nav")],
                    1
                  ),
                ],
                1
              ),
              _c("el-main", { staticClass: "layout-main" }, [
                _c(
                  "div",
                  { staticClass: "layout-main-content" },
                  [_c("router-view", { staticClass: "routerView" })],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("Notice", { ref: "notice", attrs: { noticeObj: _vm.noticeObj } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }