<template>
  <div class="main flex">
    <AsideList
      ref="asideRef"
      :asideList="asideList"
      keyId="topicTypeId"
      label="topicTypeName"
      @handlerItem="handlerAsideItem"
      @handlerAsideSearch="handlerAsideSearch"
      @getTitle="getTitle"
      :loading="asideLoading"
    ></AsideList>

    <div class="ml-16 right-content">
      <el-scrollbar class="wp-scrollbar" style="height: 100%">
        <SearchCard
          @handlerSearch="handlerSearch"
          class="search-card"
          :title="title"
        >
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker
                v-model="searchParams.timeSection"
                prefix-icon=""
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                clearable
                type="daterange"
                range-separator="-"
                start-placeholder="请选择开始时间"
                end-placeholder="请选择结束时间"
              >
              </el-date-picker>
            </div>

            <el-input
              class="search-w392 wp-input"
              v-model="searchParams.title"
              clearable
              placeholder="请输入专题名称"
            >
            </el-input>

            <el-input
              class="search-w190 wp-input"
              v-model="searchParams.topicNum"
              clearable
              placeholder="请输入专题编号"
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.topicStatus"
              clearable
              placeholder="请选择专题状态"
            >
              <el-option
                v-for="item in $globalData.TOPICSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16 table">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                专题数量：<span class="wp-blue">{{ total }}</span>
              </div>
            </div>
            <div class="flex">
              <el-button
                @click="addSpecial"
                class="wp-button wp-btn-icon"
                type="primary"
                icon="icon-tianjia"
                size="medium"
                >创建专题
              </el-button>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="tableLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column width="20">
                <template slot-scope="scope">
                  <div class="product_tip" v-if="scope.row.longItem">
                    <div class="product_tip_bg"></div>
                    <p>长期</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="topicNum"
                label="专题编号"
                width="100"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.topicNum }}</div>
                </template>
              </el-table-column>

              <el-table-column max-width="30" min-width="10"> </el-table-column>

              <el-table-column
                align="left"
                prop="title"
                label="专题名称"
                min-width="95"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div
                    class="textOverOneLine active-color"
                    @click="goDetail(scope.row.topicId)"
                  >
                    {{ scope.row.title }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="seedNum"
                label="发送人数"
                min-width="70"
              >
              </el-table-column>
              <el-table-column
                min-width="70"
                align="center"
                prop="receiveNum"
                label="接收人数"
              >
              </el-table-column>
              <el-table-column
                min-width="70"
                align="center"
                prop="completeNum"
                label="完成人数"
              >
              </el-table-column>
              <el-table-column
                align="center"
                min-width="70"
                prop="score"
                label="专题积分"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.score == -999 ? '按平台' : scope.row.score }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="创建时间"
                width="150"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="topicStatus"
                label="专题状态"
                min-width="70"
                max-width="100"
              >
                <template slot-scope="scope">
                  <div>
                    <div class="flex-center" v-if="scope.row.topicStatus == 1">
                      <div class="success-circle"></div>
                      <div class="success-color">已发布</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="disabled-circle"></div>
                      <div class="disabled-color">已结束</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                fixed="right"
                width="90"
              >
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div class="wp-action-icon">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="编辑"
                        placement="top"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                      >
                        <el-button
                          @click="editTopic(scope.row)"
                          type="text"
                          icon="icon-bianji1"
                          class="wp-btn-icon-big"
                          :class="scope.row.topicStatus == 2 ? 'no-drop' : ''"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>

                    <el-tooltip
                      class="item"
                      :visible-arrow="false"
                      popper-class="wp-btn-tooltip"
                      effect="dark"
                      content="更多"
                      placement="top"
                    >
                      <el-dropdown
                        placement="bottom-end"
                        trigger="click"
                        class="wp-dropdown wp-action-icon"
                        @command="handleMoreEvent"
                      >
                        <i class="el-icon-more wp-blue"></i>
                        <el-dropdown-menu
                          slot="dropdown"
                          :visibleArrow="false"
                          class="wp-dropdown-menu"
                        >
                          <el-dropdown-item
                            class="icon-style"
                            v-if="scope.row.topicStatus == 1"
                            icon="icon-zhuijia"
                            :command="['add', scope.row]"
                            >追加人员</el-dropdown-item
                          >
                          <el-dropdown-item
                            class="icon-style"
                            v-if="scope.row.topicStatus == 1"
                            icon="icon-duanxin"
                            :command="['send', scope.row]"
                            >发送短信</el-dropdown-item
                          >
                          <el-dropdown-item
                            class="icon-style"
                            v-if="scope.row.topicStatus == 1"
                            icon="icon-jieshu"
                            :command="['over', scope.row]"
                            >结束专题</el-dropdown-item
                          >
                          <el-dropdown-item
                            class="icon-style"
                            icon="icon-shanchu"
                            :command="['remove', scope.row]"
                            >删除专题</el-dropdown-item
                          >
                          <el-dropdown-item
                            class="icon-style"
                            icon="icon-jietu"
                            :command="['export', scope.row]"
                            >截图导出</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div
              class="wp-noData"
              v-if="tableData.length == 0 && !tableLoading"
            >
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </el-scrollbar>
    </div>
    <SpecialDialog
      @submitForm="submitForm"
      :formData="asideItem"
      :classifyAddFlag="classifyAddFlag"
      ref="classifyRef"
    >
    </SpecialDialog>
  </div>
</template>

<script>
import AsideList from '@/views/components/asideList'
import SearchCard from '@/views/components/searchCard'
import SpecialDialog from './specialDialog' // 分类弹窗

import reportedApi from '@/api/reported'

export default {
  name: 'panel',
  components: { AsideList, SearchCard, SpecialDialog },
  data () {
    return {
      topicObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 专题名称
        limit: 10, // 页大小
        sort: 2,
      },
      topicList: [], // 专题下拉框列表
      asideList: [], // 侧边栏分类列表
      title: "全部", // 标题
      searchParams: {
        page: 1,
        limit: 30,
        timeSection: "", // 时间段
        topicNum: "", // 专题编号
        title: "", // 专题名称
        topicStatus: "", // 专题状态
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        topicTypeId: "", // 专题分类Id
      },
      total: 0, // 总条数
      tableData: [],
      tableLoading: false,
      classifyAddFlag: true,
      asideItem: {}, // 侧边栏当前点击数据项
      classifyName: "", // 侧边栏分类搜素内容
    };
  },

  watch: {
    'searchParams.timeSection': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.searchParams.startTime = newVal[0]
          this.searchParams.endTime = newVal[1]
        } else {
          this.searchParams.timeSection = ""
          this.searchParams.startTime = ""
          this.searchParams.endTime = ""
        }

      }
    }
  },
  created () {
    this.getAsideList()
    this.getTableData()
  },

  methods: {
    // 获取表格数据
    async getTableData () {
      if (this.tableLoading) return
      this.tableLoading = true

      try {
        const params = { ...this.searchParams }
        console.log({ ...params });
        const res = await reportedApi.topicMainList(params)
        this.tableLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData
          this.tableData = data
          this.total = totalCount
        }
      } catch (error) {
        this.tableLoading = false
      }
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.topicList = []
        this.topicObj = { ...this.topicObj, ...data }
      }

      const res = await reportedApi.topicSelectList({ ...this.topicObj })
      if (res.returnCode == 200) {
        const ret = res.returnData
        // 专题列表
        this.topicList = [...this.topicList, ...ret.data]

        // 当前页小于总页数,+1
        if (this.topicObj.page < ret.totalPageNum) {
          this.topicObj.page++
          this.topicObj.more = true
        } else {
          this.topicObj.more = false
        }
      }
    },

    // 筛选区点击搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.getTableData()
    },

    // 分类搜索回调
    handlerAsideSearch (classifyName) {
      this.classifyName = classifyName
      this.getAsideList()
    },

    // 分类弹框提交回调
    async submitForm (formData) {
      const { classifyName: topicTypeName } = formData
      const topicTypeId = this.asideItem?.topicTypeId

      // 判断是否为新增或编辑
      const res = await (this.classifyAddFlag ? reportedApi.topicTypeInsert({ topicTypeName }) : reportedApi.topicTypeUpdate({ topicTypeName, topicTypeId, status: 1 }))

      if (res.returnCode == 200) {
        this.title = topicTypeName
        this.getAsideList()
        this.$refs.classifyRef.handleClose()
      } else {
        this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
      }
    },

    // 创建专题
    addSpecial () {
      this.$router.push({
        path: "/addSpecial",
        query: {
          // 专题分类Id
          topicTypeId: this.searchParams.topicTypeId
        }
      })
    },

    goDetail (id) {
      this.$router.push({
        path: "/reportedDetail",
        query: {
          topicId: this.$encrypt(id)
        }
      })
    },

    // 批量专题
    batchTask () {
      this.$router.push("/batchTask")
    },

    // 获取侧边栏列表
    async getAsideList () {
      if (this.asideLoading) return
      this.asideLoading = true

      try {
        const res = await reportedApi.topicTypeList({ topicTypeName: this.classifyName })
        this.asideLoading = false
        if (res.returnCode == 200) {
          this.asideList = res.returnData
        }
      } catch (error) {
        this.asideLoading = false
      }
    },

    /** 截图导出 */
    async exportFile (item, event) {
      const res = await reportedApi.topicArticleExport({ exportParam: 1, topicId: item.topicId })
      if (res.returnCode == 200) {
        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } else {
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
      }
    },

    // 侧边栏更多按钮操作回调
    handlerAsideItem (obj) {
      console.log(obj);
      this.asideItem = obj

      switch (obj.type) {
        case 'add':
          this.classifyAddFlag = true
          this.$refs.classifyRef.show()
          break;
        case 'edit':
          this.classifyAddFlag = false
          this.$refs.classifyRef.show()
          break;
        case 'remove':
          this.removeClassify()
          break;
        case 'shiftUp':
          this.handlerMove()
          break;
        case 'shiftDown':
          this.handlerMove()
          break;
        default:
          break;
      }
    },

    // 删除分类
    async removeClassify () {
      const topicTypeId = this.asideItem?.topicTypeId
      const res = await reportedApi.topicTypeVerifyRemove({ topicTypeId })
      if (res.returnCode == 200) {

        let msg = "删除后不可恢复"

        // 分类下的任务数量
        if (res.returnData.taskCount) {
          // 如果分类下有z专题,则更换提示
          msg = "分类下专题可在全部中查看"
        }

        this.$wpConfirm('提示', {
          tips: "确定删除吗？",
          content: msg,
          isShowCancelBtn: true,
        }).then(async () => {
          const ret = await reportedApi.deleteTopicType({ topicTypeId })
          // 删除成功,默认勾选全部,重新获取数据
          if (ret.returnCode == 200) {
            this.$refs.asideRef.clickAll()
          }
        }).catch(() => {
          console.log("取消")
        });
      }
    },

    // 上移下移回调
    async handlerMove () {
      const { moveId, movedId } = this.asideItem
      const res = await reportedApi.topicTypeMoveOrder({ moveId, movedId })
      if (res.returnCode == 200) {
        this.getAsideList()
      } else {
        this.$showMessage({
          type: "error",
          msg: res.returnMsg
        })
      }
    },

    // 点击侧边栏单项数据
    getTitle (item) {
      this.title = item.topicTypeName
      this.searchParams.topicTypeId = item.topicTypeId
      this.getAsideList()
      this.getTableData()
    },

    // 编辑专题
    editTopic (item) {
      if (item.topicStatus == 1) {
        this.$router.push({
          path: "/editTopic",
          query: {
            topicId: item.topicId,
          }
        })
      }

    },

    // 操作项回调
    handleMoreEvent (arr) {
      const type = arr[0]
      const item = arr[1]

      const query = {
        topicId: item.topicId
      }

      switch (type) {
        // 追加人员
        case 'add':
          this.$router.push({
            path: '/addToPeople',
            query
          })
          break;
        // 发送短信
        case 'send':
          this.sendNote(item)
          break
        // 删除专题
        case 'remove':
          this.removeTopic(item)
          break
        // 结束专题
        case 'over':
          this.overTopic(item)
          break
        // 结束专题
        case 'export':
          this.exportFile(item, event)
          break
      }
    },

    /** 结束专题 */
    overTopic (item) {
      this.$wpConfirm('提示', {
        tips: "确定结束该专题吗?",
        content: "结束后将不可再继续提交上报",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        // topicStatus 2:结束
        const res = await reportedApi.updateTopic({ topicId: item.topicId, topicStatus: 2 })
        if (res.returnCode == 200) {
          this.getTableData()
        }
      }).catch(() => {
        console.log("取消")
      });
    },

    /** 发送短信 */
    sendNote (item) {
      this.$wpConfirm('提示', {
        tips: "确定发送短信吗？",
        content: "发送短信将会提醒接收人",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        const res = await reportedApi.sendNote({ topicId: item.topicId })
        if (res.returnCode == 200 || res.returnCode == 10087) {
          if (res.returnCode == 10087) {
            this.$showMessage({ type: 'warning', msg: res.returnMsg })
          } else {
            this.$showMessage({ type: 'success', msg: '发送成功' })
          }
        }
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },

    /** 删除专题 */
    removeTopic (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        const res = await reportedApi.deleteTopic({ topicId: item.topicId })
        if (res.returnCode == 200) {
          this.getTableData()
        }
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },

    // 页大小回调
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTableData()
    },

    // 页码回调
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    }
  }
};
</script>
<style scoped lang="scss">
@import './index.scss';
</style>
