<template>
	<div class="panelTwo">
		<el-container class="layout-container">
			<el-header class="layout-header" height="80px">
				<header-view></header-view>
			</el-header>
			<el-container class="layout-mid">
				<el-main class="layout-main">
					<div class="layout-main-content">
						<router-view class="routerView" />
					</div>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import HeaderView from '@/layout/header/header.vue';


export default {
	name: 'panel',
	components: { HeaderView },
	data () {
		return {

		};
	},
	props: {},
	created () {

	},
	mounted () {

	},
	computed: {
		// ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
	},
	methods: {
		// ...mapMutations(['SET_REMINDNUM']),

	},
	beforeDestroy () {

	},
	watch: {
		$route: {
			handler (val, oldval) {
				// console.log(val, oldval);
				// if (val.meta.pPath && val.meta.pPath !== '/') {
				// 	this.menuSelect(val.meta.pPath);
				// } else {
				// 	this.menuSelect(val.path);
				// }
			},
			deep: true
		}
	}
};
</script>
<style scoped lang="scss">
@import './css/panelTwo.scss';
</style>
