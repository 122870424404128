var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-drill-dialog person-list" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "880px",
            "before-close": _vm.handleClose,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-body" }, [
            _c("div", {
              staticClass: "close-btn",
              on: { click: _vm.handleClose },
            }),
            _c("div", { staticClass: "dialog-body-content" }, [
              _c("div", { staticClass: "flex-CB dialog-body-box" }, [
                _c("div", { staticClass: "redbg" }),
                _c("div", { staticClass: "bluebg" }),
              ]),
              _c("div", { staticClass: "dialog-body-detail" }, [
                _c("div", { staticClass: "list-top flex-CB" }, [
                  _c("div", { staticClass: "list-top-item list-top-left  " }, [
                    _c("div", { staticClass: "flex-CB list-top-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "list-top-title list-top-left-left flex",
                        },
                        [_c("div", { staticClass: "rect" }), _vm._v("红方 ")]
                      ),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.redCount) + "人"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "list-top-item list-top-right " }, [
                    _c("div", { staticClass: "flex-CB list-top-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "list-top-title list-top-left-left flex",
                        },
                        [
                          _c("div", { staticClass: "rect rect2" }),
                          _vm._v("蓝方 "),
                        ]
                      ),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.blueCount) + "人"),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "list-box" },
                  [
                    _c(
                      "el-scrollbar",
                      {
                        staticStyle: { height: "100%" },
                        attrs: { "wrap-class": "scrollbar-wrapper" },
                      },
                      [
                        _c("div", { staticClass: "flex list-box-content" }, [
                          _c(
                            "div",
                            { staticClass: "list-box-item list-box-left " },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.redReceive, function (item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.userId,
                                      staticClass: "flex-Acenter",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "name flex-Acenter" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "textOverOneLine" },
                                            [_vm._v(_vm._s(item.userName))]
                                          ),
                                          _vm._v(" "),
                                          item.status == 1
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/drill/redQ.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "nick textOverOneLine" },
                                        [_vm._v(_vm._s(item.weiboName))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "desc textOverOneLine" },
                                        [_vm._v(_vm._s(item.groupName))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "list-box-item list-box-right " },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.blueReceive, function (item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.userId,
                                      staticClass: "flex-Acenter",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "name flex-Acenter" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "textOverOneLine" },
                                            [_vm._v(_vm._s(item.userName))]
                                          ),
                                          _vm._v(" "),
                                          item.status == 1
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/drill/blueQ.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "nick textOverOneLine" },
                                        [_vm._v(_vm._s(item.weiboName))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "desc textOverOneLine" },
                                        [_vm._v(_vm._s(item.groupName))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "list-bot flex-CB" }, [
                  _c("div", { staticClass: "list-bot-item  " }),
                  _c("div", { staticClass: "list-bot-item" }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }