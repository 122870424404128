import { post, get, getImg } from '@/utils/requestTwo'

// 统计分析-汇总信息
export function wpTaskDescribe (query) {
	return post(`/wpTask/analysis/describe`, query)
}

// 任务-列表
export function wpTaskPageList (query) {
	return post(`/wpTaskMain/pageList`, query, false)
}

// 统计分析-任务方式
export function wpTaskAnalysisStyle (query) {
	return post(`/wpTask/analysis/style`, query)
}

// 统计分析-分类
export function wpTaskAnalysisType (query) {
	return post(`/wpTask/analysis/type`, query)
}

// 统计分析-站点
export function wpTaskAnalysisWebsite (query) {
	return post(`/wpTask/analysis/website`, query)
}

// 星级占比
export function userStarLevelCount (query) {
	return post(`/index/userStarLevelCount`, query)
}

// 队伍人数占比
export function groupUserCount (query) {
	return post(`/index/groupUserCount`, query)
}

// 获取首页所有用户，队伍数，今日登录人数
export function groupCount (query) {
	return post(`/index/groupCount`, query)
}



// 首页-分组积分汇总排行
export function groupSummary (query) {
	return post(`/index/groupSummary`, query)
}

// 首页-人员积分汇总排行
export function memberSummary (query) {
	return post(`/index/memberSummary`, query)
}



