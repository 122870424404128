<template>
  <div class="flex-CB mt-16">
    <div class="people-list">
      <div class="people-tab">
        <div
          @click="activeTab = 1"
          :class="activeTab == 1 ? 'active-left-tab' : ''"
          class="tab-item"
        >
          人员列表
        </div>
        <div
          @click="activeTab = 2"
          :class="activeTab == 2 ? 'active-right-tab' : ''"
          class="tab-item"
        >
          常用人员
        </div>
      </div>

      <el-divider></el-divider>

      <div class="search-box">
        <div class="search-input">
          <el-input
            class="wp-input"
            v-model="searchContent"
            clearable
            placeholder="请输入搜索的内容"
          >
            <i
              slot="prefix"
              class="el-input__icon icon-sousuo"
              style="font-size: 20px"
            ></i>
          </el-input>
        </div>
      </div>

      <div class="tree">
        <PeopleTtee
          ref="peopleRef"
          :defaultExpanded="defaultExpanded"
          :defaultCheckedKeys.sync="activePeople"
          :peopleList="peopleList"
          :searchContent="searchContent"
          @handleCheck="handleCheck"
          @addCommonUser="addCommonUser"
          v-show="activeTab == 1"
        ></PeopleTtee>

        <!-- 常用人员 -->
        <PeopleTtee
          ref="commonRef"
          type="stock"
          :defaultExpanded="defaultExpandedCommon"
          :defaultCheckedKeys.sync="activeStockPeopleIds" :searchContent="searchContent" @handleCheck="handleCheck"
          @removeCommonUser="removeCommonUser" :peopleList="commonPeople" 
          nodeKey="commonId"
          v-show="activeTab == 2"
        ></PeopleTtee>
      </div>
    </div>

    <div class="select-people">
      <div class="flex-CB fs-14 head">
        <div>
          已选择分组或人员（ <span class="wp-blue">{{ list.length }}</span> 人）
        </div>
        <div class="flex-Acenter">
          <el-checkbox v-model="lastSend" @change="changeCheckbox"
            >上次发送人员</el-checkbox
          >
          <div class="flex-Acenter clear wp-blue" @click="clearAll">
            <i class="icon-shanchu"></i>
            <div class="fs-14 wp-blue">清空所有</div>
          </div>
        </div>
      </div>

      <el-scrollbar class="people">
        <div class="list">
          <div class="list-item" v-for="(item, index) in list" :key="item.id">
            <el-tooltip
              class="item"
              effect="dark"
              :tabindex="index"
              :disabled="!isShowTooltipWidth"
              :content="item.name"
              placement="top"
            >
              <div
                @mouseenter="visibilityChangeWidth($event)"
                class="textOverOneLine wp-center flex-3"
              >
                {{ item.name }}
              </div>
            </el-tooltip>
            <div>{{ $utils.replacePhone(item.mobile) }}</div>
            <el-tooltip
              class="item"
              effect="dark"
              :tabindex="index"
              :disabled="!isShowTooltipWidth"
              :content="item.groupName"
              placement="top"
            >
              <div
                @mouseenter="visibilityChangeWidth($event)"
                class="textOverOneLine wp-center flex-3"
              >
                {{ item.groupName }}
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :tabindex="index"
              :disabled="!isShowTooltipWidth"
              :content="item.custName"
              placement="top"
            >
              <div
                @mouseenter="visibilityChangeWidth($event)"
                class="textOverOneLine flex-3"
              >
                {{ item.custName }}
              </div>
            </el-tooltip>
            <div class="icon">
              <i
                v-if="!item.disabled"
                @click="tableRemovePeople(item, index)"
                class="el-icon-error"
              ></i>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import PeopleTtee from '@/views/components/peopleTree'

import taskApi from '@/api/task.js'
import reportedApi from '@/api/reported.js'
export default {
  components: { PeopleTtee },
  data () {
    return {
      activeTab: 1, // 1:人员列表 2:常用人员
      searchContent: "", // 树节点搜索内容
      lastSend: false, // 上次发送人员复选框
      list: [], // 右侧人员列表信息
      peopleList: [], // 人员列表
      commonPeople: [], // 常用人员
      activePeople: [], // 选中人员
      activeStockPeople: [], // 选中常用人员
      defaultExpanded: [],
      isShowTooltipWidth: false,
      activeStockPeopleIds:[],
      defaultExpandedCommon:[]
    }
  },
  created () {
    // 获取人员列表
    this.getAllPeopleTree()
    // 获取常用人员列表
    this.getCommonUserTree()
  },

  watch: {
    // 切换tab 清空搜索内容
    activeTab: {
      handler (newVal, oldVal) {
        this.searchContent = ""
        // 获取常用人员列表
        this.getCommonUserTree(1)
      }
    },
    activeStockPeople:{
      handler (newVal, oldVal) {
         this.activeStockPeopleIds =[...new Set(this.getActiveStockPeopleIds(this.commonPeople))]  
      }
    }
  },

  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    /** 表格中移除人员 */
    tableRemovePeople (item, index) {
      this.list.splice(index, 1)
      const idx = this.activePeople.findIndex(value => item.id == value)
      this.activePeople.splice(idx, 1)
      console.log(this.activePeople)
    },

    /** 获取全部人员列表 */
    async getAllPeopleTree () {
      const res = await taskApi.groupUserTree({})
      if (res.returnCode == 200) {
        this.peopleList = res.returnData
        this.defaultExpanded = [res?.returnData[0]?.id]
      }
    },

    /** 获取常用人员列表 */
    async getCommonUserTree (flag = 0) {
      if (!flag) {
        this.$nextTick(() => {
          this.$refs.commonRef.setDefaultExpandedKeys()
        })
      }

      // 常用人员
      const res = await taskApi.commonUserTree({})
      if (res.returnCode == 200) {
        this.commonPeople = res.returnData
        this.defaultExpandedCommon = [res?.returnData[0]?.commonId]
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 添加常用人员 */
    async addCommonUser (receiverIds) {
      const res = await taskApi.saveCommonUser({ receiverIds })
      if (res.returnCode == 200) {
        this.getCommonUserTree()
        this.$showMessage({ type: 'success', msg: '加入成功' })
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 移除常用人员 */
    async removeCommonUser (receiverIds) {
      const res = await taskApi.deleteCommonUser({ receiverIds })
      if (res.returnCode == 200) {
        this.getCommonUserTree()
        this.$showMessage({ type: 'success', msg: '移除成功' })
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 获取选中人员数据 */
    getFormData () {
      if (this.list?.length) {
        let list = []
        return list = this.list.reduce((prev, curr) => {
          const obj = {}
          obj.id = curr.id
          obj.pid = curr.pid
          prev.push(obj)
          return prev
        }, [])
      } else {
        this.$showMessage({ type: 'err', message: '请选择人员' })
      }
    },

    /** 清空所有已选中人员 */
    clearAll () {
      this.activePeople = []
      this.activeStockPeople = []
      this.list = []
      this.lastSend = false
    },

    /** 勾选上次发送人员复选框回调 */
    async changeCheckbox (flag) {
      if (flag) {
        const res = await taskApi.getBackReceiver({})
        if (res.returnCode == 200) {
          // 上次勾选人员信息
          const ids = res.returnData.reduce((prev, curr) => {
            console.log(curr);
            prev.push(curr.receiverId)
            return prev
          }, [])
          console.log(ids)
          this.activePeople = await ids
          this.activeStockPeople = await ids

          await this.getList()
        }
      }
    },

    // 人员列表
    handleCheck (obj) {
      if (!obj.checked) {
        // 保留只出现一次的值
        const activePeople = this.copare([...obj.deleteId, ...this.activePeople])
        this.activePeople = activePeople
        this.activeStockPeople = activePeople
      } else {
        const arr = [...new Set([...obj.peopleIds, ...this.activePeople])]
        // 全部人员 已选中Id列表
        this.activePeople = arr
        // 常用人员 已选中Id列表
        this.activeStockPeople = arr
      }
      this.$emit("changeUser", this.activePeople)
      this.getList()
    },

    /** 获取人员列表信息 */
    getList () {
      this.$nextTick(() => {
        // 人员列表
        const peopleList = this.$refs.peopleRef.getCurrentNode().filter(item => {
          if (!item.group) {
            return item
          }
        })
        this.list = peopleList
      })
    },

    // 只保留出现一次的值
    copare (arr) {
      let list = []
      for (let i = 0; i < arr.length; i++) {
        let num = 0
        for (let j = 0; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            num++
          }
        }
        if (num <= 1) {
          list.push(arr[i])
        }
      }
      return list
    },
    // 根据选中的常用人员的ids集合，得出commonId集合
    getActiveStockPeopleIds(array){
      let newArray = []
      array.forEach(element => {
        if(this.activeStockPeople.indexOf(element.id) != -1){
          newArray.push(element.commonId)
        }
        if (element.children && element.children.length > 0) {
          // 子集不为空   继续遍历子集 并存储遍历后的子集
          newArray = [...newArray,...this.getActiveStockPeopleIds(element.children)]
        } 
      });
      return newArray
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>