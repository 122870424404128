<template>
  <div
    id="j-barrage-content"
    :style="{ width: '100%', height: boxHeight + 'px' }"
    @click="showToolsBox = false"
  >
    <div
      id="full-barrage-content"
      :style="{ '--width': width, height: boxHeight }"
    ></div>
  </div>
</template>
<script>
// https://gitee.com/zheng_yongtao/jyeontu-component-warehouse/tree/master/JYeontuComponentWarehouse/packages/JBarrage/
//https://blog.csdn.net/Twinkle_sone/article/details/124239631

export default {
  name: "JBarrage",
  props: {
    // 数据
    barrageDate: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 是否全屏弹幕
    full: {
      type: Boolean,
      default: true
    },
    // 清理弹幕轮询定时 10 秒
    time: {
      type: Number,
      default: 10
    },
    // 是否循环
    repetition: {
      type: Boolean,
      default: true
    },
    // 容器的高度
    boxHeight: {
      type: [String, Number],
      default: "300"
    },
    // 弹幕元素的类名称
    barrageClassName: {
      type: String,
      default: "j-barrage-span"
    },
    oldeBarrageClassName: {
      type: String,
      default: "j-barrage-span2"
    }
  },
  data () {
    return {
      showBarrageDate: [],
      content: null,
      width: "1000px",
      showToolsBox: false,
      barrageNums: 0,
      clearBarrageTime: null,
      timerCreate: null,
      timerGenerate: null,
      showBarrageLength: 0, // 初始化的弹幕数量
      showBarragNums: 0,//已创建元素的弹幕数量
      newTimer: null,
      timerList: [],
      clearBarrageAllTime: null,
      destroyTimer: null
    };
  },
  created () { },
  watch: {

  },
  mounted () {
  },
  beforeDestroy () {
    this.clearTimeoutOne()
    clearTimeout(this.clearBarrageTime);
    clearTimeout(this.clearBarrageAllTime)
    this.clearBarrageAllTimer()
    clearTimeout(this.destroyTimer)
  },
  methods: {
    // 循环播放弹幕
    setNew () {
      // 当第一轮的弹幕创建完后 再
      // 轮询创建另一组
      // 初始化的弹幕 数组长度为0
      if (this.showBarrageLength == 0) return
      //初始化的弹幕 数组 = 已添加到页面的弹幕数量 并且 是允许循环的
      // 初始化的弹幕已全部添加到页面
      if (this.showBarrageLength == this.showBarragNums && this.repetition) {
        this.repet()
      }
    },
    repet () {
      //  清零弹幕数量
      this.showBarragNums = 0
      this.clearTimeoutOne()
      this.newTimer = setTimeout(() => {
        this.generateBarrage();
      }, 14 * 1000);
    },
    clearTimeoutOne () {
      clearTimeout(this.newTimeer)
      this.newTimer = null
    },
    initDate () {
      this.$nextTick(() => {
        // 初始化的弹幕数
        this.showBarrageLength = this.barrageDate.length
        // 已生成的弹幕条数
        this.showBarragNums = 0
        // 格式化数据
        this.formatDataList();
        // 生成弹幕
        this.generateBarrage();
        // 启动清除弹幕定时器
        this.clearBarrage();
      })
    },
    // 清除所有的
    clearBarrageAll (oldeBarrageClassName) {
      if (!oldeBarrageClassName) {
        oldeBarrageClassName = "j-barrage-span"
      }
      let content = this.content;
      let barrageList = document.getElementsByClassName(oldeBarrageClassName);
      barrageList = Array.from(barrageList);
      barrageList.map(item => {
        content.removeChild(item);
        this.barrageNums--;
      });
    },
    // 清除定时器
    clearBarrageAllTimer () {
      this.timerList.forEach(item => {
        clearTimeout(item)
        item = null
      })
    },
    // 清理弹幕
    clearBarrage () {
      let content = this.content;
      // 弹幕元素对象
      let barrageList = document.getElementsByClassName(this.barrageClassName);
      barrageList = Array.from(barrageList);
      barrageList.map(item => {
        // offsetLeft：元素的左外边框至包含元素的左内边框之间的像素距离。
        // 偏移到容器的左边时 清除元素
        if (item.offsetLeft <= 0) content.removeChild(item);
        this.barrageNums--;
      });
      clearTimeout(this.clearBarrageTime);
      this.clearBarrageTime = setTimeout(() => {
        this.clearBarrage();
      }, this.time * 1000);
    },
    // 发送弹幕
    sendBarrage (item) {
      // 格式化弹幕
      const obj = this.formatData({ ...item });
      // 添加到弹幕数据中
      this.showBarrageDate.push(obj);
      // 创建弹幕元素
      this.createBarrage(obj)
    },
    // 根据元素的泳道 得出top值
    getPosition (position = 1) {
      let content = this.content;
      //容器的宽度 
      this.width =
        content.offsetWidth +
        (5 * content.offsetWidth) / this.time +
        "px";
      if (position == 1) return 0
      return (position - 1) * 100
    },
    // 格式化单个数据，设置高度
    formatData (item = {}) {
      item.position = this.getPosition(item.position);
      return item;
    },
    // 格式化数据数组
    formatDataList () {
      // 是否全屏 
      if (this.full)
        this.content = document.getElementById("full-barrage-content");
      else this.content = document.getElementById("j-barrage-content");
      // 父级传过来的数据
      let showBarrageDate = [...this.barrageDate];
      showBarrageDate.map(item => {
        item = this.formatData(item);
      });
      // 要展示的弹幕数据
      this.showBarrageDate = showBarrageDate
    },
    // 销毁弹幕元素 
    destroyBarrage (dom = null) {
      if (!dom) return;
      let content = this.content;
      if (content.offsetLeft + content.offsetWidth + 800 < dom.offsetLeft) {
        content.removeChild(dom);
        this.barrageNums--;

      } else {
        clearTimeout(this.destroyTimer)
        this.destroyTimer = setTimeout(() => {
          this.destroyBarrage(dom);
        }, 1000);
      }
    },

    // 创建弹幕元素
    createBarrage (item) {
      const content = this.content;
      const divObj = document.createElement("div");
      divObj.innerHTML = `
      <div class="flex-Acenter">
                <div class="baber-left">
                  <img class="avatar" src="http://open.hnroger.com/wpinterface/weibo/img?url=${item.imgUrl}" alt="">
                </div>
                <div class="baber-right">
                  <p class="name textOverOneLine">${item.nickName}（${item.realName}）</p>
                  <p class="textOverOneLine desc baberrage-msg">${item.content}</p>
                </div>
            `;

      divObj.style.top = item.position + "px";
      divObj.style.position = 'absolute'

      divObj.style.right = "-800px";
      divObj.style.animation = `moveRight ${item.time}s linear forwards`;

      divObj.classList.add(this.barrageClassName, "text", item.className, 'flex', 'baberrage-item');

      content.appendChild(divObj);

      const imgElement = divObj.querySelector('.avatar');
      imgElement.addEventListener('error', () => {
        // 图片加载失败时执行此方法
        imgElement.src = this.$globalData.failAvatar; // 设置B图片的路径
      })

      this.barrageNums++;
      this.showBarragNums++
      this.setNew()
      this.destroyBarrage(divObj);
    },
    generateBarrage () {
      let timerList = []
      this.showBarrageDate.map((item, index) => {
        let timer = setTimeout(() => {
          this.createBarrage(item);
        }, index * 1500);
        timerList.push(timer)
      })
      this.timerList = timerList
    }
  }
}
</script>
<style vars="{width}" lang="scss">
@keyframes moveLeft {
  from {
    left: -800px;
  }

  to {
    left: 110%;
  }
}

@keyframes moveRight {
  from {
    right: -800px;
  }

  to {
    right: 110%;
  }
}

.bulletChat-block {
  height: calc(100% - 227px);

  /deep/.baberrage-stage {
    width: 100% !important;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .baberrage-item {
    position: absolute;
    right: -800px;
    margin-top: 10px;
    display: block;
    box-sizing: border-box;
    min-width: 400px;
    height: 90px;
    /*no*/
    padding: 8px;
    /*no*/
    width: auto;

    .baberrage-msg {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.blue {
      max-width: 800px;
      min-width: 400px;
      height: 90px;
      /*no*/
      background: rgba(0, 102, 255, 0.2);
      border: 2px solid #0066ff;
      border-radius: 45px;
      //   margin-right: 200px;
    }

    &.red {
      max-width: 800px;
      min-width: 400px;
      height: 90px;
      /*no*/
      background: rgba(218, 3, 42, 0.2);
      border: 2px solid #da032a;
      border-radius: 45px;
      //   margin-right: 100px;
    }
  }

  .baber-left {
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;

    img.avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .baber-right {
    flex-shrink: 0;

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #ffc000;
      line-height: 24px;
      max-width: 480px;
    }

    .desc {
      max-width: 480px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
  }
}
</style>
<style lang="scss" scoped>
#full-barrage-content {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}

#j-barrage-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .j-barrage-send-box {
    .j-barrage-tools-box {
      display: inline-block;
      background-color: #c78d3b;
      color: #fff;
    }

    .j-barrage-send-box-item {
      padding: 0.5rem 0;
    }

    .input-box {
      background-color: #f1f2f3;
      height: 2rem;
      padding: 0;
      border-radius: 8%;
      display: inline-block;

      .j-barrage-send-box-item-tools {
        text-decoration: underline;
        padding: 0 0.5rem;
        cursor: pointer;
      }

      .j-barrage-send-box-item-input {
        height: 1.5rem;
        line-height: 1.5rem;
        outline: none;
        border: none;
        background-color: transparent;
      }

      .j-barrage-send-box-item-btn {
        border-top-right-radius: 15%;
        border-bottom-right-radius: 15%;
        padding: 0.1rem;
        cursor: pointer;
        display: inline-block;
        height: 2rem;
        line-height: 2rem;
        padding: 0 0.8rem;
        background-color: #00aeec;
      }
    }
  }
}
</style>
