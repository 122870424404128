const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  roles: state => state.user.roles,
  isWpyAndAdmin: state => state.user.isWpyAndAdmin, // 单管理员
  isWpyAndAdmins: state => state.user.isWpyAndAdmins, // 多管理员
  isWpys: state => state.user.isWpys, // 多网评员
  isWpy: state => state.user.isWpy, // 单网评员
  wpyUnitTabs: state => state.user.wpyUnitTabs, // 网评员单位列表
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  visible_tabs: state => state.permission.visibleTabs,
  userInfo: state => state.user.userInfo,
  roleId: state => state.user.roleId,
  userList: state => state.user.userList,
  wpyUserList: state => state.user.wpyUserList,
  sysCust: state => state.user.sysCust,
  sysUserInfo: state => state.user.sysUserInfo,
  userId: state => state.user.userId,
  warn: state => state.user.warn,
  notif: state => state.user.notif,
  isShowRed: state => state.app.isShowRed,
  isShowTip: state => state.app.isShowTip,
  taskStatus: state => state.drill.taskStatus,
  blueTeamUsers: state => state.drill.blueTeamUsers,
  redTeamUsers: state => state.drill.redTeamUsers,
  allCommentList: state => state.drill.allCommentList,
  lineList: state => state.drill.lineList,
  detail: state => state.drill.detail,
  blueCommentList: state => state.drill.blueCommentList,
  redCommentList: state => state.drill.redCommentList,
  frequency: state => state.drill.frequency,
  redTopList: state => state.drill.redTopList,
  blueTopList: state => state.drill.blueTopList,
  isPlaybackEnd: state => state.drill.isPlaybackEnd,
  visibleNum: state => state.drill.visibleNum,
  reviewCustList: state => state.user.reviewCustList,
  excellentCustList: state => state.user.excellentCustList
}
export default getters
