<template>
  <div class="wp-drill-dialog person-list">
    <el-dialog title="" :visible.sync="dialogVisible" width="880px" :before-close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="dialog-body">
        <div @click="handleClose" class="close-btn"></div>
        <div class="dialog-body-content">
          <div class="flex-CB dialog-body-box">
            <div class="redbg">

            </div>
            <div class="bluebg">

            </div>
          </div>
          <div class="dialog-body-detail">
            <div class="list-top flex-CB">
              <div class="list-top-item list-top-left  ">
                <div class="flex-CB list-top-content">
                  <div class="list-top-title list-top-left-left flex">
                    <div class="rect"></div>红方
                  </div>
                  <div class="num">{{redCount}}人</div>
                </div>
              </div>
              <div class="list-top-item list-top-right ">
                <div class="flex-CB list-top-content">
                  <div class="list-top-title list-top-left-left flex">
                    <div class="rect rect2"></div>蓝方
                  </div>
                  <div class="num">{{blueCount}}人</div>
                </div>
              </div>
            </div>
            <div class="list-box">
              <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
                <div class="flex list-box-content">
                  <div class="list-box-item list-box-left ">
                    <ul>
                      <li class="flex-Acenter" v-for="item in redReceive" :key="item.userId">
                        <div class="name flex-Acenter">
                          <p class="textOverOneLine">{{item.userName}}</p> <img v-if="item.status == 1" src="~@/assets/images/drill/redQ.png" alt="" />
                        </div>
                        <div class="nick textOverOneLine">{{item.weiboName}}</div>

                        <div class="desc textOverOneLine">{{item.groupName}}</div>
                      </li>
                    </ul>
                  </div>

                  <div class="list-box-item list-box-right ">
                    <ul>
                      <li class="flex-Acenter" v-for="item in blueReceive" :key="item.userId">
                        <div class="name flex-Acenter">
                          <p class="textOverOneLine">{{item.userName}}</p> <img v-if="item.status == 1" src="~@/assets/images/drill/blueQ.png" alt="" />
                        </div>
                        <div class="nick textOverOneLine">{{item.weiboName}}</div>

                        <div class="desc textOverOneLine">{{item.groupName}}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <div class="list-bot flex-CB">
              <div class="list-bot-item  ">
              </div>
              <div class="list-bot-item">
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as  drillApi from '@/api/drill.js'
export default {
  name: 'personList',
  components: {},
  data () {
    return {
      dialogVisible: false,
      redReceive: [],
      blueReceive: [],
      redCount: 0,
      blueCount: 0
    };
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    isPlayback: {
      type: Boolean,
      default: false
    },
    blueTeamUsers: {
      type: Array,
      default: () => []
    },
    redTeamUsers: {
      type: Array,
      default: () => []
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    show () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    // 显示后获取数据
    init () {
      if (this.isPlayback) return this.setPlayback()
      this.getPageReceive(1)
      this.getPageReceive(2)
    },
    //获取比分统计
    async getPageReceive (type) {
      let query = {
        page: 1,
        limit: 1000,
        taskId: this.taskId,
        type: type
      }
      const res = await drillApi.pageReceive(query);
      if (res.returnCode != 200) return;
      res.returnData.page.data.forEach(element => {
        if (!element.weiboName) { element.weiboName = "-" }
      });
      if (type == 1) {
        this.redReceive = res.returnData.page.data
        this.redCount = res.returnData.redCount
      } else if (type == 2) {
        this.blueReceive = res.returnData.page.data
        this.blueCount = res.returnData.blueCount
      }
    },
    // 回放的数据
    setPlayback () {
      this.redReceive = this.redTeamUsers.map(item => { return { ...item, weiboName: item.weiboName ? item.weiboName : '-' } })
      this.redCount = this.redTeamUsers.length
      this.blueReceive = this.blueTeamUsers.map(item => { return { ...item, weiboName: item.weiboName ? item.weiboName : '-' } })
      this.blueCount = this.blueTeamUsers.length
    }

  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.init()
        } else {
          this.$reset('', [''])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.person-list {
  /deep/.el-dialog__body {
    width: 880px;
    height: 536px; /*no */
  }

  .dialog-body-content {
    width: 100%;
    height: 100%;
    height: 536px; /*no */
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

  .dialog-body {
    .list-top {
      width: 100%;
      // height: 70px;

      .list-top-item {
        width: 50%;
        padding: 0px 34px;
        // height: 70px;

        .list-top-content {
          padding: 28px 0px 16px 0px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        }

        .rect {
          width: 6px;
          height: 24px;
          background: #ff6c00;
          margin-right: 12px;

          &.rect2 {
            background: #00dcff;
          }
        }

        .list-top-title {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }

        .num {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .list-box {
      width: 100%;
      height: calc(100% - 110px); /*no */
      // padding-bottom: 40px;
      // box-sizing: border-box;

      .list-box-content {
        height: 100%;
      }

      .list-box-item {
        width: 50%;
        min-height: 434px; /*no */
        // height: 100%;
        padding: 0px 34px; /*no */

        ul {
          width: 100%;
          line-height: 18px;
          li {
            width: 100%;
            padding: 16px 0px; /*no */
            height: 50px; /*no */
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
            & > div {
              font-size: 14px;
              width: 33.3%;
              color: #ffffff;
              &.nick {
              }
            }
            .name {
              p {
                width: 4em;
              }
            }
            img {
              width: 20px;
              height: 20px;
              margin-left: 10px;
            }
            .desc {
              padding-left: 26px;
            }
          }
        }
      }
    }

    .list-bot {
      height: 40px; /*no */
      .list-bot-item {
        height: 100%;
        width: 50%;
      }
    }
  }
  .dialog-body-box {
    width: 100%;
    height: 100%;
  }
  .dialog-body-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0px;
    left: 0px;
  }

  .redbg {
    // background-color: #d32237;
    width: 50%;
    height: 100%;
    background: linear-gradient(0deg, #5f0a28, #cc042a);
  }

  .bluebg {
    width: 50%;
    height: 100%;
    // background-color: #0073e7;
    background: linear-gradient(0deg, #082681, #0165f2);
  }
}
</style>
