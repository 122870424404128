import { post, get } from '@/utils/requestTwo'

export default {
    // 任务列表
    getTaskList: (query = {}) => post("/wpTaskMain/pageList", query, false),

    // 任务-详情
    getTaskDetail: (query = {}) => post("/wpTaskMain/wpyDetail", query),

    // 文章-列表
    getArticleList: (query = {}) => post("/wpTaskArticle/wpyPageList", query, false),

    // 文章-新增
    addTaskArticle: (query = {}) => post("/wpTaskArticle/insert", query, false),

    // 文章-删除
    deleteTaskArticle: (query = {}) => post("/wpTaskArticle/delete", query),

    // 组员统计
    memberStatisticsList: (query = {}) => post("/wpTaskArticle/memberStatisticsList", query, false),

    // 作品统计
    wpyTaskStatisticsList: (query = {}) => post("/wpTaskArticle/wpyTaskStatisticsList", query, false),

    // 文章详情
    getTaskArticle: (query = {}) => post("/wpTaskArticle/get", query),

    // 文章-修改
    updateTaskArticle: (query = {}) => post("/wpTaskArticle/update", query, false),

    // 文章-获取驳回信息
    getVerify: (query = {}) => post("/wpTaskArticle/getVerify", query),

    // 统计表格信息
    getSummaries: (query = {}) => post("/wpTaskArticle/summaries", query, false),
}