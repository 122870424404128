var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulletChat" }, [
    _c("div", { staticClass: "top3-block flex-CB" }, [
      _c(
        "div",
        { staticClass: "top3-item red flex-CB" },
        [
          _vm._l(_vm.redTop, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "medal-item flex-column-center" },
              [
                _c("div", { staticClass: "medal-info" }, [
                  _c("div", { staticClass: "medal-header" }, [
                    index == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.jinPai + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == 1
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.yinPai + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == 2
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.dong + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "name textOverOneLine" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.nickName) +
                          "（" +
                          _vm._s(item.realName) +
                          "） "
                      ),
                    ]),
                  ]),
                  index == 0 && _vm.redShow
                    ? _c("div", { staticClass: "first-bg" })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "number-box flex-center" }, [
                  index == 0
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num1.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  index == 1
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num2.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  index == 2
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num3.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]
            )
          }),
          _vm._l(3 - _vm.redTop.length, function (item) {
            return _c("div", {
              key: item,
              staticClass: "medal-item flex-column-center",
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "top3-item blue flex-CB" },
        [
          _vm._l(_vm.blueTop, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "medal-item flex-column-center" },
              [
                _c("div", { staticClass: "medal-info" }, [
                  _c("div", { staticClass: "medal-header" }, [
                    index == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.jinPai + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == 1
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.yinPai + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == 2
                      ? _c(
                          "div",
                          {
                            staticClass: "bgIndex flex-center",
                            style: "background-image: url(" + _vm.dong + ");",
                          },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: item.imgUrl, alt: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.$globalData.failAvatar,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "name textOverOneLine" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.nickName) +
                          "（" +
                          _vm._s(item.realName) +
                          "） "
                      ),
                    ]),
                  ]),
                  index == 0 && _vm.blueShow
                    ? _c("div", { staticClass: "first-bg" })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "number-box flex-center" }, [
                  index == 0
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num1.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  index == 1
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num2.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  index == 2
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/images/drill/num3.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]
            )
          }),
          _vm._l(3 - _vm.blueTop.length, function (item) {
            return _c("div", {
              key: item,
              staticClass: "medal-item flex-column-center",
            })
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { ref: "bulletChatBlock", staticClass: "bulletChat-block" },
      [
        _c("j-barrage", {
          ref: "jBarrage",
          attrs: {
            repetition: _vm.repetition,
            barrageClassName: _vm.barrageClassName,
            barrageDate: _vm.barrageDate,
            laneNum: _vm.laneNum,
            time: 10,
            boxHeight: _vm.boxHeight,
            full: false,
            showBtn: true,
            startFrom: "right",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }