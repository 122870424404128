var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-drill-dialog resultView" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "custom-class": "drill-dialog",
            visible: _vm.dialogVisible,
            width: "1324px",
            top: "2vh",
            "before-close": _vm.handleClose,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-body" }, [
            !_vm.loading
              ? _c("div", {
                  staticClass: "close-btn",
                  on: { click: _vm.handleClose },
                })
              : _vm._e(),
            !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "dialog-body-content",
                    class: _vm.bodyclasses,
                  },
                  [
                    _c("div", { staticClass: "dialog-top flex-center" }, [
                      _c("div", { staticClass: "dialog-top-content flex-CB" }, [
                        _c("div", { staticClass: "dialog-top-item red-item" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "num-data flex-center flex-column-JC",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-EC label-value" },
                                [_c("p", [_vm._v(_vm._s(_vm.aTeamScore))])]
                              ),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("红方"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "dialog-top-mid" }),
                        _c(
                          "div",
                          { staticClass: "dialog-top-item blue-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "num-data flex-center flex-column-JC",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-EC label-value" },
                                  [_c("p", [_vm._v(_vm._s(_vm.bTeamScore))])]
                                ),
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("蓝方"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "dialog-mid flex-CB" },
                      _vm._l(_vm.scoreList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.name,
                            staticClass: "dialog-item flex-column",
                          },
                          [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "label-item label-item-red" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "num-data flex-center flex-column-JC",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-EC label-value" },
                                        [_c("p", [_vm._v(_vm._s(item.aScore))])]
                                      ),
                                      _c("p", { staticClass: "label" }, [
                                        _vm._v("红方"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "label-item label-item-blue" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "num-data flex-center flex-column-JC",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-EC label-value" },
                                        [_c("p", [_vm._v(_vm._s(item.bScore))])]
                                      ),
                                      _c("p", { staticClass: "label" }, [
                                        _vm._v("蓝方"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "tab-btns flex-center" },
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.handleClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "得分TOP5", name: "1" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "热评TOP5", name: "2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.activeName == "1"
                      ? _c(
                          "div",
                          { staticClass: "table-block" },
                          [
                            _c(
                              "el-scrollbar",
                              { staticStyle: { height: "100%" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.itemLoading,
                                        expression: "itemLoading",
                                      },
                                    ],
                                    staticClass: "drill-table",
                                    attrs: {
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          data: _vm.tableData,
                                          stripe: "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "date",
                                            label: "排名",
                                            align: "center",
                                            "min-width": "20",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    scope.row.index == 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bgIndex",
                                                            style:
                                                              "background-image: url(" +
                                                              _vm.jinPai +
                                                              ");",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .index
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : scope.row.index == 2
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bgIndex",
                                                            style:
                                                              "background-image: url(" +
                                                              _vm.yinPai +
                                                              ");",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .index
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : scope.row.index == 3
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bgIndex",
                                                            style:
                                                              "background-image: url(" +
                                                              _vm.dong +
                                                              ");",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .index
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#ffc000",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.index
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1460558271
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "typeName",
                                            label: "队伍",
                                            align: "center",
                                            "min-width": "20",
                                            "show-overflow-tooltip": "",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "nickName",
                                            label: "微博昵称",
                                            align: "center",
                                            "show-overflow-tooltip": "",
                                            "min-width": "20",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "realName",
                                            label: "姓名",
                                            "min-width": "20",
                                            align: "center",
                                            "show-overflow-tooltip": "",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "得分",
                                            align: "center",
                                            "min-width": "20",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "score" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.score
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            376211223
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.tableData.length == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "wp-noData" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/noData.png"),
                                                alt: "",
                                              },
                                            }),
                                            _c("p", [_vm._v("暂无内容")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeName == "2"
                      ? _c(
                          "div",
                          { staticClass: "table-block" },
                          [
                            _c(
                              "el-scrollbar",
                              { staticStyle: { height: "100%" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.itemLoading,
                                        expression: "itemLoading",
                                      },
                                    ],
                                    staticClass: "drill-list",
                                    attrs: {
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "list-box" }, [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.topComments,
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.id,
                                                staticClass: "flex",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "li-left" },
                                                  [
                                                    _c(
                                                      "el-image",
                                                      {
                                                        attrs: {
                                                          src: item.imgUrl,
                                                          alt: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "error",
                                                            },
                                                            slot: "error",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: _vm
                                                                  .$globalData
                                                                  .failAvatar,
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "li-right" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "li-right-top flex-CB",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "title flex",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.nickName
                                                                )
                                                              ),
                                                            ]),
                                                            item.typeId == 1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "title-label red",
                                                                  },
                                                                  [_vm._v("红")]
                                                                )
                                                              : _vm._e(),
                                                            item.typeId == 2
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "title-label blue",
                                                                  },
                                                                  [_vm._v("蓝")]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "time",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.createTime
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "desc" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.content
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm.topComments.length == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "wp-noData" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/noData.png"),
                                                alt: "",
                                              },
                                            }),
                                            _c("p", [_vm._v("暂无内容")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.loading
              ? _c("div", { staticClass: "dalog-box" }, [
                  _c("div", { staticClass: "wrapper" }, [
                    _c("div", { staticClass: "block" }, [
                      _c("div", { staticClass: "countDown-img" }),
                      _c("div", { staticClass: "countDown-text" }, [
                        _vm._v(" " + _vm._s(_vm.timeNum) + " "),
                      ]),
                    ]),
                    _c("p", [_vm._v("正在计算结果")]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }