var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/taskManagement" } } },
                    [_vm._v("投稿管理")]
                  ),
                  _c("el-breadcrumb-item", [_vm._v("任务详情")]),
                ],
                1
              ),
              _c("div", { staticClass: "wp-card mt-20 task-detail" }, [
                _c("div", { staticClass: "task-head" }, [
                  _vm.taskDetail.taskStatus == 1
                    ? _c("div", { staticClass: "task-state" }, [
                        _vm._v(" 已发布 "),
                      ])
                    : _c("div", { staticClass: "finsh-state" }, [
                        _vm._v("已结束"),
                      ]),
                  _c(
                    "div",
                    { staticClass: "textOverOneLine task-title fs-16 fw-bold" },
                    [_vm._v(" " + _vm._s(_vm.taskDetail.title) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "task-info mt-20 fs-14" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 任务编号："),
                      _c("span", [_vm._v(_vm._s(_vm.taskDetail.taskNumber))]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 任务类型："),
                      _c("span", [_vm._v(_vm._s(_vm.taskDetail.taskStyleStr))]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 任务站点："),
                      _c("span", [_vm._v(_vm._s(_vm.taskDetail.websiteName))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "task-item", staticStyle: { flex: "2" } },
                      [
                        _vm._v(" 任务方式："),
                        _c("span", [_vm._v(_vm._s(_vm.taskDetail.dictKey))]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "mt-20 flex" }, [
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 开始时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(_vm.taskDetail.startTime)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 结束时间："),
                      _c("span", { staticClass: "orange" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(_vm.taskDetail.endTime)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 任务积分："),
                      _c("span", { staticClass: "orange" }, [
                        _vm._v(_vm._s(_vm.taskDetail.score)),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 单人上限："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.taskDetail.maxArticle == 9999
                              ? "不限"
                              : _vm.taskDetail.maxArticle
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 字数要求："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.taskDetail.minWord == 9999
                              ? "不限"
                              : _vm.taskDetail.minWord
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.taskDetail.url
                    ? _c("div", { staticClass: "mt-20 task-item flex" }, [
                        _c("div", { staticStyle: { "min-width": "73px" } }, [
                          _vm._v("任务链接："),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "wp-blue textOverOneLine",
                            on: {
                              click: function ($event) {
                                return _vm.$utils.download(_vm.taskDetail.url)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.taskDetail.url) + " ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.taskDetail.author
                    ? _c("div", { staticClass: "mt-20 task-item flex" }, [
                        _c("span", { staticClass: "key-name" }, [
                          _vm._v("视频号："),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.taskDetail.author))]),
                      ])
                    : _vm._e(),
                  _vm.taskDetail.content
                    ? _c("div", { staticClass: "mt-12 task-item" }, [
                        _c("div", { staticClass: "work-content" }, [
                          _c("div", { staticClass: "key" }, [
                            _vm._v("任务要求："),
                          ]),
                          _c("p", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.content)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.taskDetail.remark
                    ? _c("div", { staticClass: "mt-12 task-item" }, [
                        _c("div", { staticClass: "work-content" }, [
                          _c("div", { staticClass: "key" }, [
                            _vm._v("任务备注："),
                          ]),
                          _c("p", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.remark)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.taskDetail.fileJson &&
                  _vm.taskDetail.fileJson.length &&
                  _vm.taskDetail.taskStyle == 2
                    ? _c(
                        "div",
                        { staticClass: "mt-12 task-item" },
                        [
                          _vm._v(" 图片视频： "),
                          _c("File", {
                            staticStyle: { "margin-top": "8px" },
                            attrs: { fileJson: _vm.taskDetail.fileJson },
                          }),
                        ],
                        1
                      )
                    : _vm.taskDetail.fileJson &&
                      _vm.taskDetail.fileJson.length &&
                      _vm.taskDetail.taskStyle == 3
                    ? _c(
                        "div",
                        { staticClass: "mt-12 task-item" },
                        [
                          _vm._v(" 目标图片： "),
                          _c("File", {
                            staticStyle: { "margin-top": "8px" },
                            attrs: { fileJson: _vm.taskDetail.fileJson },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "wp-card mt-16" }, [
                _c("div", { staticClass: "fs-16 fw-bold" }, [
                  _vm._v("汇总信息"),
                ]),
                _c("div", { staticClass: "num-list flex" }, [
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.taskDetail.seedNum))]),
                    _c("div", [_vm._v("发送人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.taskDetail.receiveNum))]),
                    _c("div", [_vm._v("接收人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.taskDetail.completeNum))]),
                    _c("div", [_vm._v("完成人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.taskDetail.incomplete))]),
                    _c("div", [_vm._v("未完成人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.taskDetail.completionRate || 0)),
                      _c("span", { staticClass: "fs-14" }, [_vm._v("%")]),
                    ]),
                    _c("div", [_vm._v("完成率")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.taskDetail.passRate || 0)),
                      _c("span", { staticClass: "fs-14" }, [_vm._v("%")]),
                    ]),
                    _c("div", [_vm._v("合格率")]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "fs-16 fw-bold" }, [
                    _vm._v("队伍完成情况"),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.teamLoading,
                          expression: "teamLoading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.teamList.length
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.teamList, stripe: "" } },
                            [
                              _c("el-table-column", { attrs: { width: "50" } }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { width: "120" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "groupName",
                                  width: "240",
                                  "show-overflow-tooltip": "",
                                  label: "分组名称",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "textOverOneLine",
                                              class: scope.row.child
                                                ? "group-name"
                                                : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getPeopleDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.groupName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3844018365
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "seedNum",
                                  label: "发送人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "jsTotal",
                                  label: "接收人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "popleNum",
                                  label: "完成人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "wcRate",
                                  label: "完成率",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(scope.row.wcRate) + "%"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2859916297
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.teamList.length == 0 && !_vm.teamLoading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.teamTotal,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.teamCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB people-info" }, [
                    _c("div", { staticClass: "flex-Acenter" }, [
                      _c("div", { staticClass: "fs-16 fw-bold" }, [
                        _vm._v("人员完成情况"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "fs-14 wp-blue flex-Acenter export",
                          on: { click: _vm.exportFile },
                        },
                        [
                          _c("i", { staticClass: "icon-daochu" }),
                          _c("span", [_vm._v("导出")]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "is-finish flex" }, [
                      _c(
                        "div",
                        {
                          class: _vm.activeFinish == 1 ? " active-finish" : "",
                          on: {
                            click: function ($event) {
                              return _vm.totggleFinish(1)
                            },
                          },
                        },
                        [_vm._v(" 已完成 ")]
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.activeFinish == 0 ? " active-finish" : "",
                          on: {
                            click: function ($event) {
                              return _vm.totggleFinish(0)
                            },
                          },
                        },
                        [_vm._v(" 未完成 ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.peopleLoading,
                          expression: "peopleLoading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeFinish == 1,
                              expression: "activeFinish == 1",
                            },
                          ],
                        },
                        [
                          _vm.peopoleList.length
                            ? _c(
                                "el-table",
                                {
                                  attrs: { data: _vm.peopoleList, stripe: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      type: "index",
                                      label: "序号",
                                      width: "70",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "fileJson",
                                      width: "150",
                                      label: "截图/附件",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              JSON.parse(scope.row.fileJson)
                                                .length
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            "visible-arrow": false,
                                                            trigger: "hover",
                                                            "popper-class":
                                                              "img-popper",
                                                            tabindex: 3,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "scaled-image",
                                                            attrs: {
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                              fit: "contain",
                                                            },
                                                          }),
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "22px",
                                                              height: "22px",
                                                            },
                                                            attrs: {
                                                              slot: "reference",
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3068207749
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { "min-width": "30" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "left",
                                      prop: "createName",
                                      "min-width": "150",
                                      "show-overflow-tooltip": "",
                                      label: "人员姓名",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "150",
                                      align: "left",
                                      prop: "groupName",
                                      "show-overflow-tooltip": "",
                                      label: "所在分组",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "150",
                                      align: "center",
                                      prop: "createTime",
                                      label: "提交时间",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        scope.row.createTime
                                                      )
                                                      .format(
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      676827023
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "totalScore",
                                      label: "奖励积分",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "articleStatus",
                                      label: "审核状态",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                scope.row.articleStatus == 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-center",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "success-circle",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "success-color",
                                                          },
                                                          [_vm._v("已通过")]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-center",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "noreceive-circle",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "noreceive-color",
                                                          },
                                                          [_vm._v("已驳回")]
                                                        ),
                                                      ]
                                                    ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1979728929
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { align: "center", label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.articleStatus == 1
                                                ? _c(
                                                    "RejectCause",
                                                    {
                                                      key: scope.row
                                                        .taskArticleId,
                                                      attrs: {
                                                        popoverType: "add",
                                                      },
                                                      on: {
                                                        handlerReject:
                                                          function ($event) {
                                                            return _vm.handlerReject(
                                                              $event,
                                                              scope.row
                                                            )
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "reject",
                                                        },
                                                        [_vm._v("驳回")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "RejectCause",
                                                    {
                                                      attrs: {
                                                        popoverType: "detail",
                                                        rejectObj:
                                                          _vm.rejectObj,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reject-disabled",
                                                          on: {
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.getReject(
                                                                  scope.row
                                                                    .taskArticleId
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [_vm._v(" 驳回 ")]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2684950845
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeFinish == 0,
                              expression: "activeFinish == 0",
                            },
                          ],
                        },
                        [
                          _vm.peopoleList.length
                            ? _c(
                                "el-table",
                                {
                                  attrs: { data: _vm.peopoleList, stripe: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      type: "index",
                                      label: "序号",
                                      width: "70",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { width: "40" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "left",
                                      width: "150",
                                      "show-overflow-tooltip": "",
                                      prop: "userName",
                                      label: "人员姓名",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "groupName",
                                      "show-overflow-tooltip": "",
                                      label: "所在分组",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "200",
                                      "show-overflow-tooltip": "",
                                      prop: "custName",
                                      label: "所在单位",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "taskReceiverStatus",
                                      label: "接收状态",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.taskReceiverStatus == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-center",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "noreceive-circle",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "noreceive-color",
                                                        },
                                                        [_vm._v("未接收")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-center",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "receive-circle",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "receive-color",
                                                        },
                                                        [_vm._v("已接收")]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3601034709
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "receiverTime",
                                      label: "接收时间",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (scope.row.receiverTime &&
                                                        _vm
                                                          .$moment(
                                                            scope.row
                                                              .receiverTime
                                                          )
                                                          .format(
                                                            "YYYY-MM-DD HH:mm"
                                                          )) ||
                                                        "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2263134334
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { align: "center", label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "wp-blue wp-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sendMessage(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 发送短信 ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      755772537
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.peopoleList.length == 0 && !_vm.peopleLoading
                        ? _c("div", { staticClass: "wp-noData mt-20" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.peopleTotal,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.peopleCurrentChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "wp-dialog",
        {
          attrs: { size: "small", dialogVisible: _vm.messageVisible },
          on: {
            cancel: _vm.messageCancel,
            "update:dialogVisible": function ($event) {
              _vm.messageVisible = $event
            },
            "update:dialog-visible": function ($event) {
              _vm.messageVisible = $event
            },
            determine: _vm.confirmMessage,
            handleClose: _vm.messageCancel,
          },
        },
        [
          _c("div", { staticClass: "message-box" }, [
            _c("div", { staticClass: "fs-16 fw-bold" }, [
              _vm._v("确定发送短信吗？"),
            ]),
            _c("div", { staticClass: "message-text" }, [
              _vm._v("发送短信将会提醒接收人"),
            ]),
          ]),
        ]
      ),
      _c(
        "wp-dialog",
        {
          attrs: {
            dialogVisible: _vm.groupingVisible,
            showSlotFooter: _vm.groupingStep == 2 && this.haveChild,
            showDefaultFooter: false,
            height: "550px",
            width: "820px",
            title: _vm.groupingName,
          },
          on: {
            cancel: _vm.groupingCancel,
            "update:dialogVisible": function ($event) {
              _vm.groupingVisible = $event
            },
            "update:dialog-visible": function ($event) {
              _vm.groupingVisible = $event
            },
            handleClose: _vm.groupingCancel,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.groupingStep == 1,
                  expression: "groupingStep == 1",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.twoTeamLoading,
                      expression: "twoTeamLoading",
                    },
                  ],
                  staticClass: "grouping-box wp-table",
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-text": "正在加载",
                  },
                },
                [
                  _vm.twoTeamList.length
                    ? _c(
                        "el-table",
                        {
                          ref: "twoTeamList",
                          attrs: {
                            data: _vm.twoTeamList,
                            stripe: "",
                            "row-style": _vm.rowStyle,
                          },
                          on: { "row-click": _vm.rowClick },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "index",
                              label: "序号",
                              width: "70",
                            },
                          }),
                          _c("el-table-column", { attrs: { width: "20" } }),
                          _c("el-table-column", {
                            attrs: {
                              align: "left",
                              prop: "groupName",
                              label: "分组名称",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "seedNum",
                              label: "发送人数",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "jsTotal",
                              label: "接收人数",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "popleNum",
                              label: "完成人数",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "wcRate",
                              label: "完成率",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.wcRate) + "%"),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2859916297
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.twoTeamList.length == 0 && !_vm.twoTeamLoading
                    ? _c("div", { staticClass: "wp-noData mt-20" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/noData.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                  _c("wp-pagination", {
                    staticClass: "mt-16",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.twoTeamTotal,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.twoTeamCurrentChange },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.groupingStep == 2,
                  expression: "groupingStep == 2",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.groupingStep2Loading,
                      expression: "groupingStep2Loading",
                    },
                  ],
                  staticClass: "grouping-box wp-table",
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-text": "正在加载",
                  },
                },
                [
                  _vm.groupingStep2Data.length
                    ? _c(
                        "el-table",
                        { attrs: { data: _vm.groupingStep2Data, stripe: "" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "index",
                              label: "序号",
                              width: "70",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "left",
                              prop: "createName",
                              "show-overflow-tooltip": "",
                              label: "人员名称",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "fileJson",
                              width: "150",
                              label: "截图/附件",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      JSON.parse(scope.row.fileJson).length
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "right-start",
                                                    "visible-arrow": false,
                                                    trigger: "hover",
                                                    "popper-class":
                                                      "img-popper",
                                                    tabindex: 3,
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticClass: "scaled-image",
                                                    attrs: {
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                      fit: "contain",
                                                    },
                                                  }),
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "22px",
                                                      height: "22px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                    },
                                                    slot: "reference",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3068207749
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              prop: "createTime",
                              label: "提交时间",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .$moment(scope.row.createTime)
                                              .format("YYYY-MM-DD HH:mm")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              676827023
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "totalScore",
                              label: "奖励积分",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              "min-width": "150",
                              prop: "articleStatus",
                              label: "审核状态",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        scope.row.articleStatus == 1
                                          ? _c(
                                              "div",
                                              { staticClass: "flex-center" },
                                              [
                                                _c("div", {
                                                  staticClass: "success-circle",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "success-color",
                                                  },
                                                  [_vm._v("已通过")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "flex-center" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "noreceive-circle",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "noreceive-color",
                                                  },
                                                  [_vm._v("已驳回")]
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1979728929
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.groupingStep2Data.length == 0 && !_vm.groupingStep2Loading
                    ? _c("div", { staticClass: "wp-noData mt-20" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/noData.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                  _c("wp-pagination", {
                    staticClass: "mt-16",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.groupingStep2Total,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.groupingStep2CurrentChange },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "template",
            { slot: "slotFooter" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" },
                  ],
                  staticClass: "wp-button",
                  staticStyle: { width: "120px" },
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.groupingStepCancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("返回 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }