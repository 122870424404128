var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "680px",
        height: "440px",
        dialogVisible: _vm.dialogVisible,
        showDefaultFooter: false,
        showSlotFooter: true,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "messageDetail" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.newEidtObj.title) + " "),
        ]),
        _c("div", { staticClass: "detail" }, [
          _c("pre", {
            staticClass: "pre-value",
            domProps: { innerHTML: _vm._s(_vm.newEidtObj.content) },
          }),
        ]),
        _c(
          "div",
          { staticClass: "file" },
          [
            _c("File", {
              staticStyle: { "margin-top": "10px" },
              attrs: { fileJson: _vm.fileJson },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "slotFooter" },
        [
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "wp-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.determine.apply(null, arguments)
                },
              },
            },
            [_vm._v("我知道了 ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }