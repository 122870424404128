var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "el-breadcrumb",
            {
              staticClass: "wp-breadcrumb",
              staticStyle: { margin: "20px 24px 0" },
              attrs: { "separator-class": "el-icon-arrow-right" },
            },
            [
              _c(
                "el-breadcrumb-item",
                {
                  attrs: {
                    to: { path: _vm.isLeader ? "/crewWorkDetails" : "/myWork" },
                  },
                },
                [_vm._v(_vm._s(_vm.isLeader ? "组员统计" : "我的作品"))]
              ),
              _c("el-breadcrumb-item", [_vm._v("作品详情")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("ContentCard", {
                attrs: {
                  title: _vm.taskDetail.title,
                  slotValue: "row-content",
                },
                scopedSlots: _vm._u([
                  {
                    key: "row-content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务编号："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskNumber)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务分类："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskStyleStr)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务站点："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.websiteName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务方式："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskWay)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务积分："),
                          _c("span", { staticClass: "score" }, [
                            _vm._v(_vm._s(_vm.taskDetail.score)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ContentCard", {
                staticClass: "mt-16",
                attrs: { slotValue: "vertical-content" },
                scopedSlots: _vm._u([
                  {
                    key: "vertical-content",
                    fn: function () {
                      return [
                        _vm.articleDetail.title
                          ? _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品标题："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.articleDetail.title)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.url
                          ? _c("div", { staticClass: "item mt-12 flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "key",
                                  staticStyle: { "min-width": "73px" },
                                },
                                [_vm._v("作品链接：")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "url textOverOneLine",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$utils.download(
                                        _vm.articleDetail.url
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.articleDetail.url) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.content
                          ? _c("div", { staticClass: "work-content" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品内容："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.articleDetail.content) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.fileJson &&
                        _vm.articleDetail.fileJson.length
                          ? _c(
                              "div",
                              { staticClass: "file-list" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "key",
                                    staticStyle: { "margin-bottom": "0px" },
                                  },
                                  [_vm._v("截图/附件：")]
                                ),
                                _c("File", {
                                  attrs: {
                                    fileJson: _vm.articleDetail.fileJson,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm.rejectDetail.resonRemark && !_vm.isLeader
                ? _c("div", { staticClass: "wp-card mt-16 footer" }, [
                    _c("div", { staticClass: "head" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("审核意见")]),
                    ]),
                    _c("div", { staticClass: "comment" }, [
                      _c(
                        "div",
                        { staticClass: "user" },
                        [
                          _c("el-avatar", {
                            staticStyle: { width: "32px", height: "32px" },
                            attrs: { src: _vm.headUrl },
                          }),
                          _c("div", { staticClass: "user-name" }, [
                            _vm._v(_vm._s(_vm.rejectDetail.createName)),
                          ]),
                          _c("div", { staticClass: "publish-time" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$moment(_vm.rejectDetail.createTime)
                                    .format("YYYY-MM-DD HH:mm")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "detail-content" }, [
                        _vm._v(
                          " " + _vm._s(_vm.rejectDetail.resonRemark) + " "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "hideDiv" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }