<template>
  <div class="header-view">
    <div class="header-left flex-Acenter">
      <img src="~@/assets/images/mls/mlslogo.png" alt="" v-if="isMls" />
      <img
        src="~@/assets/images/header/logo@2x.png"
        alt=""
        v-else-if="!isMls"
      />
    </div>
    <div class="header-right">
      <div class="admin-info">
        <div class="name-info">
          {{ userName
          }}
        </div>
        
      </div>

      
    </div>
  </div>
</template>

<script>
import { isMls } from '@/settings.js';
export default {
  name: 'header-view',
  components: {  },
  data () {
    return {
      userName:'',
      isMls: isMls
    };
  },
  props: {},
  created () {
    this.userName  = this.$route.query.userName ?this.$route.query.userName:''
  },
  mounted () {
    
  },
  computed: {
   
  },
  methods: {
   

  },
  beforeDestroy () {

  },
  watch: {
    
  }
};
</script>
<style scoped lang="scss">
@import './css/header.scss';
</style>
<style lang="scss">
.el-popover.el-popper.app-popover {
  min-width: 100px;
  background-color: #ffffff;
  border: solid 1px #c0ccfb;
  padding: 6px 10px;

  .popover-content {
    text-align: center;

    .code {
      width: 76px;
      height: 76px;
    }
  }
}

.xiaoxi-popper.el-popover {
  width: auto;
  padding: 12px 18px;
  min-width: 100px;
  z-index: 1000 !important;

  .xiaoxi-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    img {
      cursor: pointer;
      width: 16px;
      margin-left: 20px;
    }

    .out-btn {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      display: flex;
      align-items: center;
    }
    .icon-tuichu {
      font-size: 20px;
    }
  }
}
</style>
