var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "success mt-16" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "success-image" },
        [_c("el-image", { attrs: { src: _vm.url } })],
        1
      ),
      _c("div", { staticClass: "fs-18 fw-bold mt-16" }, [_vm._v("发布成功")]),
      _vm.messageFlag
        ? _c("div", { staticClass: "fs-14 mt-16" }, [
            _c(
              "span",
              {
                staticClass: "wp-blue wp-pointer",
                on: { click: _vm.goTaskList },
              },
              [_vm._v("点击返回")]
            ),
            _vm._v(" 列表界面 "),
          ])
        : _c("div", { staticClass: "fs-14 mt-16" }, [
            _vm._v(" 短信余量不足，短信未发送， "),
            _c(
              "span",
              {
                staticClass: "wp-blue wp-pointer",
                on: { click: _vm.goTaskList },
              },
              [_vm._v("点击返回")]
            ),
            _vm._v(" 列表界面 "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }