var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex shortcut-box" },
    [
      _vm._l(_vm.shortcutList, function (item) {
        return _c(
          "div",
          {
            key: item.taskMainRemarkId,
            staticClass: "shortcut-item flex-CB",
            on: {
              click: function ($event) {
                return _vm.clickShortcut(item)
              },
            },
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: item.remark,
                  placement: "top-start",
                },
              },
              [
                _c("div", { staticClass: "textOverOneLine" }, [
                  _vm._v(_vm._s(item.remark)),
                ]),
              ]
            ),
            _c("i", {
              staticClass: "icon-guanbi",
              on: {
                click: function (event) {
                  return _vm.removeQuick(item, event)
                },
              },
            }),
          ],
          1
        )
      }),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "top",
            width: "520",
            "popper-class": "basics-popper",
            trigger: "click",
          },
          on: { "after-leave": _vm.hidePopover },
        },
        [
          _c("el-input", {
            staticStyle: { width: "472px" },
            attrs: {
              type: "textarea",
              rows: 6,
              maxlength: "200",
              placeholder: "请输入快捷语",
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                {
                  staticClass: "wp-button",
                  attrs: { size: "medium", type: "text" },
                  on: { click: _vm.hide },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "wp-button",
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.addQuick },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shortcutList.length < 3,
                  expression: "shortcutList.length < 3",
                },
              ],
              staticClass: "add-shortcut",
              style: {
                marginLeft: _vm.shortcutList.length == 0 ? "0px" : "12px",
              },
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("i", { staticClass: "icon-tianjia" }),
              _c("div", [_vm._v("添加快捷语")]),
            ]
          ),
        ],
        1
      ),
      _c("div", { ref: "hide" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }