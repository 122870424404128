<template>
  <div class="echarts-div" :id="id"></div>
</template>

<script>
// import echarts from "echarts";

export default {
  // mixins: [resize],
  props: {
    seriesData: {
      type: Array,
      default: () => []
    },
    seriesData2: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: "chart"
    },
    xAxisData: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      myChart: null
    };
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  computed: {

  },
  methods: {
    setData () {
      if (this.myChart) {
        this.setOption()
      } else {
        this.initChart()
      }
    },
    remFontSize: function (rem) {
      var fontSize = document.documentElement.style.fontSize;
      return Math.floor(rem * fontSize.replace('px', ''));
    },
    resizeW () {
      this.myChart.resize()
    },
    initChart: function () {
      if (!document.getElementById(this.id)) return;
      this.myChart = this.$echarts.init(document.getElementById(this.id));
      this.setOption();
      window.addEventListener('resize', () => {
        if (this.myChart) {
          this.myChart.resize();
        }
      });
    },
    setOption: function () {
      let that = this
      let option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params, ticket, callback) {
            console.log(params)
            let list = []
            let listItem = ''
            let axisValueLabel = ''
            params.forEach(element => {
              axisValueLabel = element.axisValueLabel
              list.push(
                '<span style="display: inline-block;padding: 5px 0;" >' +
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                element.borderColor +
                ';border-radius: 50%;}"></i><span style="width:15px; display:inline-block;">' +
                '</span>' +
                element.value +
                '</span>'
              )
            });
            listItem = list.join('<br/>')
            return '<div style="padding:6px;">' + axisValueLabel + '<br/>' + listItem + '</div>'
          }
        },
        grid: {
          top: '20%',
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData,
          // boundaryGap: true,
          axisLabel: {
            // interval: 0,
            show: true,
            textStyle: {
              color: '#999999'
            },
            // fontSize: this.remFontSize(0.0625)
            // fontSize: this.remFontSize(0.083333)
          },
          nameLocation: 'start',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#0a3452'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#999999',
              // fontSize: this.remFontSize(0.083333)
            }
          },
          splitNumber: 4,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(102,102,102,0.25)',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            tooltip: {
              textStyle: {
                color: '#218AFF'
              }

            },
            // label: {
            //   show: false,
            //   position: 'top',
            //   formatter: '{c}',
            //   // color: '#00fff0',
            //   color: '#218AFF',
            //   // fontSize: this.remFontSize(0.0625)
            //   // fontSize: this.remFontSize(0.083333)
            // },
            data: this.seriesData,
            type: 'line',
            lineStyle: {
              color: '#218AFF',
              width: 1
            },
            symbol: "circle",
            animationDelay: function (idx) {
              return idx * 10;
            },
            itemStyle: {
              color: "#010001",
              borderColor: "#218AFF",
              borderType: "solid",
              borderWidth: 2,
              // opacity: 0,
              // normal: {
              //   color: '#218AFF',
              //   // lineStyle: {
              //   //   color: '#218AFF',
              //   // }
              // }
            },
            areaStyle: {
              // color: 'rgba(22,220,232,0.2)',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(33,138,255,1)' // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: 'rgba(33,138,255,0.8)' // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(33,138,255,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(33,138,255,0.05)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            smooth: false
          },
          {
            // label: {
            //   show: false,
            //   position: 'top',
            //   formatter: '{c}',
            //   // color: '#00fff0',
            //   color: '#F52402',
            //   // fontSize: this.remFontSize(0.0625)
            //   fontSize: this.remFontSize(0.083333)
            // },
            data: this.seriesData2,
            animationDelay: function (idx) {
              return idx * 10;
            },
            type: 'line',
            lineStyle: {
              color: '#F52402',
              width: 1
            },
            symbol: "circle",
            itemStyle: {
              color: "#010001",
              borderColor: "#F52402",
              borderType: "solid",
              borderWidth: 2,
              // opacity: 0,
              // normal: {
              //   color: "#F52402",
              //   // lineStyle: {
              //   //   color: "#F52402",
              //   // }
              // }
            },
            smooth: false,
            areaStyle: {
              // color: 'rgba(22,220,232,0.2)',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(245,36,2,1)' // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: 'rgba(245,36,2,0.8)' // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(245,36,2,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(245,36,2,0.05)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
          }
        ]
      };

      if (!this.myChart) {
        return;
      }
      this.myChart.setOption(option);
    },
  },
  watch: {
    'xAxisData': {
      handler: function (val, oldval) {
        if (val) {
          this.initChart()
        }
      },
      deep: true //对象内部的属性监听，也叫深度监听
    },
  },
};
</script>

<style scoped>
.chartsClass {
  padding-left: 1.2rem;
}
</style>
