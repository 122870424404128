var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: _vm.size === "small" ? "420px" : _vm.width,
            "before-close": _vm.handleClose,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": _vm.showClose,
            "modal-append-to-body": _vm.modalAppendToBody,
            modal: _vm.modal,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-body",
              style: {
                height: _vm.size === "small" ? _vm.smallHeight : _vm.height,
              },
            },
            [
              _c(
                "el-scrollbar",
                {
                  staticStyle: { height: "100%" },
                  attrs: { "wrap-class": "scrollbar-wrapper" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dialog-content" },
                    [_vm._t("default")],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.showDefaultFooter
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  class: { "dialog-footer-small": _vm.size === "small" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "wp-button",
                      attrs: { size: _vm.size == "small" ? "medium" : "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.cancelText) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "wp-button",
                      attrs: {
                        type: "primary",
                        size: _vm.size == "small" ? "medium" : "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.determine.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.determineText) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showSlotFooter
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  class: { "dialog-footer-small": _vm.size === "small" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [_vm._t("slotFooter")],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }