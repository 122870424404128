<template>
  <div class="wp-dialog">
    <el-dialog :title="title" :visible.sync="dialogVisible" :width="size === 'small' ? '420px' : width" :before-close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="showClose" :modal-append-to-body="modalAppendToBody" :modal="modal">
      <div class="dialog-body" :style="{
          height: size === 'small' ? smallHeight : height,
        }">
        <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
          <div class="dialog-content">
            <slot></slot>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer" :class="{ 'dialog-footer-small': size === 'small' }" v-if="showDefaultFooter">
        <el-button @click.stop="cancel" class="wp-button" :size="size == 'small' ? 'medium' : ''">{{ cancelText }}
        </el-button>
        <el-button class="wp-button" type="primary" v-preventReClick :size="size == 'small' ? 'medium' : ''" @click.stop="determine">{{ determineText }}
        </el-button>
      </span>
      <span slot="footer" class="dialog-footer" :class="{ 'dialog-footer-small': size === 'small' }" v-if="showSlotFooter">
        <slot name="slotFooter"></slot>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'WpDialog',
  components: {},
  data () {
    return {
      withPx: "680px",
      heightPx: "380px",
      footerHeight: '90px'
    };
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    showDefaultFooter: {
      type: Boolean,
      default: true
    },
    showSlotFooter: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '取 消'
    },
    determineText: {
      type: String,
      default: '确 定'
    },
    width: {
      type: String,
      default: '680px'
    },
    height: {
      type: String,
      default: '380px'
    },
    size: {
      type: String,
      default: '' // 可选 small 小弹窗
    },
    smallHeight: {
      type: String,
      default: '170px'
    },
    step: {
      type: Number,
      default: 0
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    }
  },
  created () {
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    determine () {
      this.$emit('determine')
    },
    handleClose () {
      this.$emit('handleClose')
    }
  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
</style>
