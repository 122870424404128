<template>
  <div class="main flex">

    <div class="right-content">
      <el-scrollbar class="wp-scrollbar" style="height: 100%">
        <SearchCard @handlerSearch="handlerSearch" class="search-card" :title="title">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker :default-time="['00:00:00', '23:59:59']" @change="changeTimeSection" type="datetimerange" v-model="timeSection" prefix-icon="" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" clearable range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
              </el-date-picker>
            </div>

            <el-input class="search-w392 wp-input" v-model="searchParams.title" clearable placeholder="请输入标题">
            </el-input>

            <el-input class="search-w190 wp-input" v-model="searchParams.custName" clearable placeholder="请输入单位名称">
            </el-input>
            <el-input class="search-w190 wp-input" v-model="searchParams.createName" clearable placeholder="请输入姓名">
            </el-input>
            <el-cascader ref="cascaderRef" class="search-w190 wp-cascader align-top" :options="groupList" @change="changeCascader" :props="defaultProps" collapse-tags placeholder="请选择所在分组" clearable>
            </el-cascader>
            <el-select class="search-w190 wp-select" v-model="searchParams.status" clearable placeholder="请选择状态">
              <el-option v-for="item in $globalData.REVIEW_CENTER_STATUS" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16 table">
          <div class="flex-CB">
            <div class="fs-14 flex">

            </div>
            <div class="flex">
              <el-button @click="exportFile" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu" v-preventReClick>导出
              </el-button>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column align="center" width="50" label="序号" type="index">

              </el-table-column>

              <el-table-column align="left" prop="title" label="标题" min-width="95" show-overflow-tooltip>
                
              </el-table-column>
              <el-table-column align="center" label="稿件" min-width="70">
                <template slot-scope="scope">

                  <img style="width: 22px; height: 22px" class="wp-pointer" @click="handlerDownload(scope.row)" :src="$utils.handlePreviewImg(scope.row.fileJson)" />
                </template>
              </el-table-column>
              <el-table-column min-width="70" align="center" label="终稿">
                <template slot-scope="scope">
                  <span v-if="scope.row.fileJsonEnd == ''">-</span>
                  <img v-else style="width: 22px; height: 22px" class="wp-pointer" @click="$utils.download($axios.defaults.baseURL + '/file/'+scope.row.fileJsonEnd[0].id)" :src="$utils.handlePreviewImg(scope.row.fileJsonEnd)" />
                </template>
              </el-table-column>
              <el-table-column min-width="100" align="center" prop="userName" label="姓名" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" min-width="100" prop="custName" label="单位" show-overflow-tooltip>
              </el-table-column>
              <el-table-column min-width="70" align="center" prop="words" label="字数">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ scope.row.words?scope.row.words:'-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="70" align="center" prop="royalties" label="稿酬">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ scope.row.royalties?scope.row.royalties:'-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="120" align="center" prop="createTime" label="提交时间">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="status" label="状态" min-width="70" max-width="100">
                <template slot-scope="scope">
                  <!-- 0删除 1未读 2已读 3采用 -->
                  <div>
                    <div class="flex-center" v-if="scope.row.status == 3">
                      <div class="success-circle"></div>
                      <div class="success-color">已采用</div>
                    </div>
                    <div class="flex-center" v-else-if="scope.row.status == 2">
                      <div class="disabled-circle"></div>
                      <div class="disabled-color">已读</div>
                    </div>
                    <div class="flex-center" v-else-if="scope.row.status == 1">
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">未读</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div class="wp-action-icon">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" :visible-arrow="false" popper-class="wp-btn-tooltip">
                        <el-button @click="editItem(scope.row)" type="text" icon="icon-bianji1" class="wp-btn-icon-big">
                        </el-button>
                      </el-tooltip>
                    </div>
                    <!-- 仅当状态为“已采用”时可以操作 -->
                    <div class="wp-action-icon" v-if="scope.row.status == 3">
                      <el-tooltip class="item" effect="dark" content="上传" placement="top" :visible-arrow="false" popper-class="wp-btn-tooltip">
                        <el-button @click="handlerUpload(scope.row)" type="text" icon="icon-shanchuan" class="wp-btn-icon-big">
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div class="wp-action-icon" v-else>
                      <el-tooltip class="item" effect="dark" content="采用" placement="top" :visible-arrow="false" popper-class="wp-btn-tooltip">
                        <el-button @click="use(scope.row)" type="text" icon="icon-use" class="wp-btn-icon-big" :class="scope.row.topicStatus == 2 ? 'no-drop' : ''">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </el-scrollbar>
    </div>
    <UploadFiles ref="uploadFiles" :reviewObj="reviewObj" @success="reviewSuccess" @close="reviewObj={}"></UploadFiles>
    <EidtReview ref="eidtReview" :reviewObj="reviewObj" @success="reviewSuccess" @close="reviewObj={}"></EidtReview>
  </div>
</template>

<script>
import SearchCard from '@/views/components/searchCard'

import reportedApi from '@/api/reported'
import UploadFiles from '../components/uploadFiles'
import EidtReview from '../components/eidtReview'
import { pageListWpReviewArticle, exportReview, downloadReview, adoptReview } from '@/api/review.js'
export default {
  name: 'panel',
  components: { SearchCard, UploadFiles, EidtReview },
  data () {
    return {
      title: "阅评中心", // 标题
      searchParams: {
        title: "", // 阅评名称
        page: 1, // 页码
        limit: 30, // 页大小
        custName: '',
        createName: '',//用户名
        status: "",//状态 1未读 2已读 3采用
        groupIds: [],//组ID数组
        startTime: '',
        endTime: ''
      },
      timeSection: '',
      total: 0, // 总条数
      tableData: [],
      loading: false,
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupList: [], // 分组树
      reviewObj: {},
      query: {},// 导出的参数
      isExporting: false, // 导出 的 锁
      isDownloading: false,
      isAdopting: false
    };
  },

  watch: {

  },
  created () {
    this.getGroupTree()
    this.getPageList()
  },

  methods: {
    // 获取表格数据
    async getPageList () {
      if (this.loading) return
      this.loading = true
      this.query = JSON.parse(JSON.stringify(this.searchParams))
      try {
        const res = await pageListWpReviewArticle({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.total = ret.totalCount
          ret.data.forEach(element => {
            element.fileJson = JSON.parse(element.fileJson)
            element.fileJsonEnd = element.fileJsonEnd ? JSON.parse(element.fileJsonEnd) : ''
          });
          this.tableData = ret.data
        }
      } catch (error) {
        this.loading = false
      }
    },
    // 筛选区点击搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.getPageList()
    },
    // 编辑字数稿酬
    editItem (item) {
      this.reviewObj = item
      this.$refs.eidtReview.show()
    },
    // 页大小回调
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getPageList()
    },

    // 页码回调
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getPageList()
    },
    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },
    // 获取分组树
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },
    //采用
    use (item) {
      this.$wpConfirm('采用', {
        tips: "确定采用该稿件吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postUse(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 采用请求
    async postUse (item) {
      let query = {
        reviewArticleId: item.reviewTitleId
      }
      if (this.isAdopting) return
      this.isAdopting= true
      try {
        let res = await adoptReview (query)
        this.isAdopting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.isAdopting = false
        console.log(error)
      }
     },
    handlerUpload (item) {
      this.reviewObj = item
      this.$refs.uploadFiles.show()
    },
    // 导出
    async exportFile (event) {
      let query = {
        ...this.query,
      }
      if (this.isExporting) return
      this.isExporting = true
      try {
        let res = await exportReview(query)
        this.isExporting = false
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } catch (error) {
        this.isExporting = false
        console.log(error)
      }

    },
    changeTimeSection (newVal) {
      if (newVal) {
        this.searchParams.startTime = newVal[0]
        this.searchParams.endTime = newVal[1]
      } else {
        this.timeSection = ""
        this.searchParams.startTime = ""
        this.searchParams.endTime = ""
      }
    },
    reviewSuccess () {
      this.reviewObj = {}
      this.refresh()
    },
    refresh () {
      this.searchParams.page = 1
      this.getPageList()
    },
    async handlerDownload (item) {
      
      let query = {
        reviewArticleId: item.reviewTitleId
      }
      if (this.isDownloading) return
      this.isDownloading = true
      try {
        let res = await downloadReview(query)
        this.isDownloading = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$utils.download(this.$axios.defaults.baseURL + '/file/' + item.fileJson[0].id)
        this.refresh()
      } catch (error) {
        this.isDownloading = false
        console.log(error)
      }

    }
  }
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
