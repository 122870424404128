<template>
  <div class="smsLogList">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
            <el-breadcrumb-item :to="{ name: 'unitManagList' }">单位管理</el-breadcrumb-item>
            <el-breadcrumb-item>短信管理（{{ custName }}）</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="wp-moudle-style tab-nav">
					<p class="nav-item active">充值记录</p>
					<p class="nav-item">短信日志</p>
				</div> -->
        <tab-nav :activeID="2" :custId="custId" :custName="custName"></tab-nav>
        <SearchCard title="" @handlerSearch="handlerSearch" class="title-card">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker v-model="searchQuery.datetimeValue" prefix-icon="" value-format="yyyy-MM-dd" clearable type="daterange" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" @change="changeDaterange" :picker-options="pickerOptions">
              </el-date-picker>
            </div>
            <el-input class="search-w190 wp-input" v-model.trim="searchQuery.recipientName" clearable placeholder="请输入接收人姓名">
            </el-input>
            <el-input class="search-w190 wp-input" v-model.trim="searchQuery.mobile" @input="resetNumMobile" clearable placeholder="请输入手机号码">
            </el-input>
          </template>
        </SearchCard>
        <div class="layout-block wp-moudle-style layout-block-bottom">
          <div class="table-box">
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="130" align="center">
                </el-table-column>
                <el-table-column prop="modularname" label="短信类型" min-width="150" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="seedmsg" label="名称" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="recipientname" label="接收人" min-width="150" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="seedmobile" label="手机号码" min-width="150" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="seedtime" label="发送时间" align="center" width="200">
                  <!-- yyyy-mm-dd hh:mm -->
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div class="pagination-row">
              <wp-pagination :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
              </wp-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchCard from '@/views/components/searchCard'
import TabNav from '@/views/systemSettings/unitManag/components/tabNav'
import { sysSmsChargeLog } from '@/api/systemSettings.js';
import { resetNum } from '@/utils/validate.js';
export default {
  name: 'smsManagList',
  components: { SearchCard, TabNav },
  data () {
    return {
      datetimeValue: null,
      loading: false,
      tableData: [],
      total: 0,
      searchQuery: {
        datetimeValue: null,
        recipientName: "",
        mobile: ""
      },
      pickerOptions: {},
      custId: '',
      custName: '',
      query: {
        page: 1,
        limit: 30,
        startTime: '',
        endTime: "",
        recipientName: "",
        mobile: ""
      }

    };
  },
  props: {},
  created () {
    if (this.$route.query._custId && this.$route.query._custName) {
      this.custId = this.$decrypt(this.$route.query._custId)
      this.custName = this.$decrypt(this.$route.query._custName)
      this.getsysSmsChargeList()
    } else {
      this.goBack()
    }
  },
  mounted () {
    this.setPickerOptions()
  },
  computed: {
  },
  methods: {
    setPickerOptions () {
      // 可按添加时间段筛选查看，默认为空，可选到时间点。可选择近3年的时间（例：今天2022年，可以选到2019年1月1日）
      // console.log(new Date().getFullYear() - 3)
      // console.log(this.startDate)
      let threeYearAgo = new Date(`${new Date().getFullYear() - 3}/01/01`)
      let startDate = threeYearAgo.getTime()
      this.$nextTick(() => {
        this.pickerOptions = {
          disabledDate (time) {
            return time.getTime() < startDate
          }
        }
      })

    },
    changeDaterange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      this.getsysSmsChargeList()
    },
    // 更新列表
    refresh () {
      this.query.page = 1
      this.getsysSmsChargeList()
    },
    // 搜索
    handlerSearch () {
      // console.log(this.searchQuery.datetimeValue)
      let datetimeValue = this.searchQuery.datetimeValue ? this.searchQuery.datetimeValue : []
      this.query = {
        ...this.query,
        ...this.searchQuery,
        startTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      delete this.query.datetimeValue
      this.refresh()
    },
    // 返回之前的页面
    goBack () {
      this.$router.go(-1)
    },
    // 获取列表
    async getsysSmsChargeList () {
      let query = {
        ...this.query,
        custId: this.custId
      }
      if (this.loading) return
      this.loading = true
      try {
        let res = await sysSmsChargeLog(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(element => {
          element.seedtime = this.$moment(element.seedtime).format("YYYY-MM-DD HH:mm")
        });
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 手机号重置 值
    resetNumMobile () {
      this.searchQuery.mobile = resetNum(this.searchQuery.mobile);
    }



  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/smsLogList.scss";
</style>
