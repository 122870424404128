<template>
  <wp-dialog @cancel="cancel" title="编辑" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="words" label=""  :rules="{ required: true, message: '请输入字数', trigger: 'blur' }">
          <el-input class="wp-input" maxlength="9" @change="resetNum($event,'words')" v-model.trim="ruleForm.words" placeholder="请输入字数" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item prop="royalties" label="" :rules="{ required: true, message: '请输入稿酬', trigger: 'blur' }">
          <el-input class="wp-input" maxlength="9" @change="resetNum($event,'royalties')"  v-model.trim="ruleForm.royalties" placeholder="请输入稿酬" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { updateWpReviewArticle, getWpReviewArticle } from '@/api/review.js'
import {  resetNum } from '@/utils/validate.js';
export default {
  name: 'addGroup',
  components: {},
  data () {
    return {
      dialogVisible: false,
      ruleForm: {
        words: "",
        royalties: ""
      },
      rules: {
      },
      fullscreenLoading: false,
      isPosting:false
    };
  },
  props: {
    reviewObj: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.$reset('ruleForm', [])
      this.dialogVisible = false
      this.$emit('close')
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.reviewObj.reviewTitleId) this.getWpReviewArticle()
    },
    // 创建 发起请求
    async postAdd () {
      if(this.isPosting) return
      this.isPosting = true
      let query = {
        reviewTitleId: this.reviewObj.reviewTitleId,
        words: this.ruleForm.words,
        royalties: this.ruleForm.royalties
      }
      try {
        this.fullscreenLoading = true
        let api = updateWpReviewArticle
        let res = await api(query)
        this.fullscreenLoading = false
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        this.fullscreenLoading = false
      }
    },
    async getWpReviewArticle () {
      try {
        let api = getWpReviewArticle
        let res = await api({ reviewTitleId: this.reviewObj.reviewTitleId })
       
        if (Number(res.returnCode) !== 200) {
          this.setInitData(this.reviewObj)
          return
        }
        this.setInitData(res.returnData)
      } catch (error) {
        console.log(error)
      }
    },
    setInitData (reviewObj) {
      this.ruleForm.words = reviewObj.words ? reviewObj.words.toString() : ''
      this.ruleForm.royalties = reviewObj.royalties ? reviewObj.royalties.toString() : ''
    },
     // 任务时间 输入框 验证并重置数值
     resetNum (val,param) {
      this.ruleForm[param] = resetNum(val)
    },
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {

          this.initDate();
        } else {
          
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 30px 50px 0px 50px;
}
</style>