import { post } from '@/utils/requestTwo'

// 新增阅评
export function insertWpReviewArticle (query) {
	return post(`/wpReviewArticle/insert`, query,false)
}

// 修改阅评
export function updateWpReviewArticle (query) {
	return post(`/wpReviewArticle/update`, query,false)
}
//  列表查询
export function pageListWpReviewArticle (query) {
	return post(`/wpReviewArticle/pageList`, query,false)
}

// 删除阅评
export function deleteWpReviewArticle (query) {
	return post(`/wpReviewArticle/delete`, query)
}

// 获取阅评详情
export function getWpReviewArticle (query) {
	return post(`/wpReviewArticle/get`, query)
}

// 检查阅评是否可编辑删除
export function checkReview (query) {
	return post(`/wpReviewArticle/checkReview`, query)
}

//列表导出
export function exportReview (query) {
	return post(`/wpReviewArticle/export`, query,false)
}

//终稿
export function finalizeReview (query) {
	return post(`/wpReviewArticle/finalize`, query,false)
}

//下载初稿为已读
export function downloadReview (query) {
	return post(`/wpReviewArticle/download`, query)
}


//采用
export function adoptReview (query) {
	return post(`/wpReviewArticle/adopt`, query)
}

