<template>
  <div class="drillList">
    <el-scrollbar style="height: 100%">
      <div class="scroll-content">
        <div class="layout-top">
          <div class="title">投稿演练</div>
          <div class="searchContent">
            <div class="wp-date-picker search-w392">
              <el-date-picker v-model="searchQuery.datetimeValue" prefix-icon="" value-format="yyyy-MM-dd" clearable type="daterange" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
              </el-date-picker>
            </div>
            <el-input class="search-w392 wp-input" v-model.trim="searchQuery.keyword" clearable placeholder="请输入任务标题">
            </el-input>
            <el-button @click="handlerSearch" class="wp-button wp-btn-icon" type="primary" icon="icon-sousuo" size="medium">
              搜索
            </el-button>
            <el-button @click="addDrill" class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium">
              创建演练
            </el-button>
          </div>
        </div>
        <div class="layout-content wp-table" v-loading="loadingTure" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
          <div class="list-box">
            <ul>
              <li class="li-row" v-for="(item,index) in tableData" :key="item.startTime">
                <div class="row-content flex-Acenter">
                  <p class="dot-out flex-column-center"><span class="dot-in"></span></p>
                  <p class="time">{{item.startTimeShow}}</p>
                </div>
                <div class="li-it-box flex-Acenter" v-for="(it,indx) in item.list" :key="it.id" @click.stop="showDrillDtail(it)">
                  <div class="it-right " :class="{hid:index == tableData.length - 1}"></div>
                  <div class="it-left">
                    <!-- 状态图片 -->
                    <div class="stauts-img">
                      <img src="~@/assets/images/drill/inProgress.png" alt="" v-if="it.taskStatus == 1">
                      <img src="~@/assets/images/drill/notStarted.png" alt="" v-else-if="it.taskStatus == 0">
                    </div>
                    <!-- 状态条颜色 未开始和进行中的是灰色， 红色胜利是红色 蓝色胜利是蓝色-->
                    <div class="stauts-rect" :class="{'stauts-rect1':it.stautsRect ==1,'stauts-rect2':it.stautsRect == 2,'stauts-rect3':it.stautsRect == 3}">
                    </div>
                    <!--标题 -->
                    <div class="it-left-top flex-CB">
                      <p class="title-p textOverOneLine">{{it.keyword}}
                      </p>
                      <div class="score flex-Acenter" v-if="it.taskStatus == 2">
                        <p class="red" :style="'width:'+it.pWidth+'px;'">{{it.redScore}}</p>
                        <span>:</span>
                        <p class="blue" :style="'width:'+it.pWidth+'px;'">{{it.blueScore}}</p>
                      </div>
                    </div>
                    <div class="action-row flex-CB">
                      <div class="action-row-left flex-Acenter">
                        <p>开始时间：<span>{{$moment(it.startTime).format("YYYY-MM-DD HH:mm")}}</span></p>
                        <p class="num-width">演练人数：<span>{{it.userTotal}}</span></p>
                        <p>创建人：<span>{{it.creatorName}}</span></p>
                      </div>
                      <!-- 未开始：可以查看成员（操作项均可用）、编辑、分享、删除。
进行中：查看成员（追加人员、移除均禁用）、不可编辑、不可删除、可以分享。
已完成：查看成员（追加人员、移除均禁用）、不可编辑、可删除、可分享、可查看回放。
（不可操作时该按钮隐藏） -->
                      <div class="action-row-right flex-Acenter">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                          <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big wp-btn-icon-big-grey" v-if="it.taskStatus == 0" @click.stop="eidtDrill(it)">
                          </el-button>
                        </el-tooltip>
                        <el-tooltip class=" item" effect="dark" content="查看成员" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                          <el-button type="text" icon="icon-chengyuan" class="wp-btn-icon-big wp-btn-icon-big-grey" @click.stop="viewMembers(it)">
                          </el-button>
                        </el-tooltip>
                        <el-popover @show="setUrl(it, it.id)" placement="top" width="214" trigger="hover" popper-class="img-popover">
                          <div>
                            <div :id="'qrcode' + it.id" style="width: 200px; height:200px"></div>
                            <p>扫码分享</p>
                          </div>
                          <!-- <a href="javascript:;" slot="reference" @click.stop><i class="iconfont iconzhuanfa2"></i></a> -->
                          <el-button type="text" icon="icon-fenxiang" slot="reference" class="wp-btn-icon-big wp-btn-icon-big-grey" @click.stop>
                          </el-button>
                        </el-popover>
                        <el-tooltip class=" item" effect="dark" content="回放" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                          <el-button type="text" icon="icon-huifang" class="wp-btn-icon-big wp-btn-icon-big-grey" v-if="it.taskStatus == 2" @click.stop="showDrillPlayback(it)">
                          </el-button>
                        </el-tooltip>
                        <el-tooltip class=" item" effect="dark" content="删除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                          <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big wp-btn-icon-big-grey" @click.stop="delItem(it)" v-if="it.taskStatus == 0 ||  it.taskStatus == 2">
                          </el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="wp-noData" v-if="tableData.length == 0 && !loadingTure">
            <img src="~@/assets/images/noData.png" alt="" />
            <p>暂无数据</p>
          </div>
          <wp-pagination class="mt-21" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
          </wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import * as  drillApi from '@/api/drill.js'
import QRCode from 'qrcodejs2';

// {
//     "page":1,
//     "limit":10,
//     "startTime":"2022-01-01 00:00:00",
//     "endTime":"2022-09-16 23:59:59",
//     "keyword":"测试2"
// }
export default {
  name: 'panel',
  components: {},
  data () {
    return {
      searchQuery: {
        datetimeValue: null,
        keyword: ''
      },
      query: { limit: 30, page: 1, startTime: '', endTime: '', keyword: '' },
      total: 0,
      tableData: [],
      loading: false,
      loadingTure: true,
      timer: null
    };
  },
  props: {},
  created () {

  },
  mounted () {
    this.getPageList()
    this.clearIntervalOne()
    this.timer = setInterval(() => { this.getPageList() }, 10000)
  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    // 查看回放
    showDrillPlayback (item) {
      let routeData = this.$router.resolve({
        path: '/drillDetailPlayback',
        query: {
          id: this.$encrypt(item.id),
          // taskStatus: item.taskStatus
        }
      });
      window.open(routeData.href, '_blank');
    },
    //查看任务详情
    showDrillDtail: function (item) {
      let routeData = this.$router.resolve({
        path: '/drillDetail',
        query: {
          id: this.$encrypt(item.id),
          // taskStatus: item.taskStatus
        }
      });
      window.open(routeData.href, '_blank');
    },
    // 添加演练
    addDrill () {
      this.$router.push({ name: 'addDrill' })
    },
    // 编辑演练
    eidtDrill (item) {
      this.$router.push({ name: 'addDrill', query: { id: this.$encrypt(item.id) } })
    },
    // 查看成员
    viewMembers (item) {
      this.$router.push({ name: 'drillMembers', query: { id: this.$encrypt(item.id) } })
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      // 获取接口
      this.loadingTure = true
      this.getPageList()
    },
    // 搜索查询
    handlerSearch () {
      let datetimeValue = this.searchQuery.datetimeValue ? this.searchQuery.datetimeValue : []
      let times = {
        startTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      this.query = { ...this.query, ...this.searchQuery, ...times }
      delete this.query.datetimeValue
      this.refresh()
    },
    refresh () {
      this.query.page = 1
      this.loadingTure = true
      this.getPageList()
    },
    // 获取列表
    async getPageList () {

      let query = {
        ...this.query
      }
      if (this.loading) return
      try {
        this.loading = true
        let res = await drillApi.pageTask(query)
        this.loading = false
        this.loadingTure = false
        if (Number(res.returnCode) !== 200) return
        let tableData = []
        res.returnData.data.forEach(item => {
          let startTime = this.$moment(item.startTime).format("YYYY-MM-DD")
          let startTimeShow = this.$moment(item.startTime).format("MM月DD日")
          let indx = tableData.findIndex(it => it.startTime == startTime)
          item.stautsRect = 0
          item.pWidth = 50
          if (item.taskStatus == 1 || item.taskStatus == 0) {
            item.stautsRect = 0
          } else if (item.taskStatus == 2) {
            if (item.redScore - item.blueScore > 0) {
              item.stautsRect = 1
            } else if (item.redScore - item.blueScore < 0) {
              item.stautsRect = 2
            } else if (item.redScore - item.blueScore == 0) {
              item.stautsRect = 3
            }
            console.log(Math.max(item.redScore, item.blueScore))
            item.pWidth = this.$utils.getTextWidth(Math.max(item.redScore, item.blueScore).toString(), '24px DS-Digital') + 10
          }
          if (indx == -1) {
            tableData.push({ startTime: startTime, startTimeShow: startTimeShow, list: [item] })
          } else {
            tableData.splice(indx, 1, { startTime: startTime, startTimeShow: startTimeShow, list: [...tableData[indx].list, item] })
          }
          // console.log(process.env)
          item.drillUrl = `${process.env.VUE_APP_H5_URL}/drillDetail?taskId=${item.shareId}`;
        })
        this.tableData = tableData

        console.log(this.tableData)
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    setUrl: function (item, index) {
      console.log(item);
      this.$nextTick(() => {
        document.getElementById('qrcode' + index).innerHTML = '';
        new QRCode(document.getElementById('qrcode' + index), {
          // width: 94, //#宽度
          // height: 94, //#高度
          width: 200, //#宽度
          height: 200, //#高度
          text: item.drillUrl // #二维码链接
        });
      });
    },
    // 删除
    delItem (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelUser(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 删除请求
    async postDelUser (item) {
      try {
        let res = await drillApi.delTask({ id: item.id })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    // 清除定时器
    clearIntervalOne () {
      clearInterval(this.timer)
      this.timer = null
    }

  },
  beforeDestroy () {
    this.clearIntervalOne()
  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/drillList.scss";
</style>
