var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "basicsForm",
      staticClass: "form",
      attrs: {
        model: _vm.basicsForm,
        rules: _vm.basicsFormRules,
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "演练主题：", prop: "keyword" },
        },
        [
          _c("el-input", {
            staticClass: "wp-input",
            attrs: { maxlength: "100", placeholder: "请填写演练主题" },
            model: {
              value: _vm.basicsForm.keyword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.basicsForm,
                  "keyword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicsForm.keyword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "URL地址：", prop: "url" },
        },
        [
          _c("el-input", {
            staticClass: "wp-input",
            attrs: { maxlength: "2000", placeholder: "请输入微博链接" },
            model: {
              value: _vm.basicsForm.url,
              callback: function ($$v) {
                _vm.$set(
                  _vm.basicsForm,
                  "url",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicsForm.url",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex-CB" }, [
        _c(
          "div",
          { staticClass: "half-item" },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item-content",
                attrs: { label: "开始时间：", prop: "startTime" },
              },
              [
                _c(
                  "div",
                  { staticClass: "wp-date-picker" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm",
                        format: "yyyy-MM-dd HH:mm",
                        "prefix-icon": "noicon",
                        type: "datetime",
                        clearable: "",
                        placeholder: "请选择开始时间",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.basicsForm.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicsForm, "startTime", $$v)
                        },
                        expression: "basicsForm.startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "half-item" },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item-content",
                attrs: { label: "演练时长：", prop: "duration" },
              },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "4",
                      placeholder: "演练时长不能小于5分钟",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.resetNumDrillSetting("duration", 5, 30, true)
                      },
                    },
                    model: {
                      value: _vm.basicsForm.duration,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.basicsForm,
                          "duration",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "basicsForm.duration",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticStyle: {
                          color: "#333333",
                          "margin-right": "10px",
                        },
                        attrs: { slot: "suffix" },
                        slot: "suffix",
                      },
                      [_vm._v("分钟")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex-CB" }, [
        _c(
          "div",
          { staticClass: "half-item" },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item-content",
                attrs: { label: "热评数量：", prop: "hotNum" },
              },
              [
                _c("el-input", {
                  staticClass: "wp-input",
                  attrs: { maxlength: "4", placeholder: "请输入热评数量" },
                  on: {
                    change: function ($event) {
                      return _vm.resetNumDrillSetting("hotNum", 30, 100, true)
                    },
                  },
                  model: {
                    value: _vm.basicsForm.hotNum,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.basicsForm,
                        "hotNum",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "basicsForm.hotNum",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "half-item" },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item-content",
                attrs: { label: "胜方积分：", prop: "winnerScore" },
              },
              [
                _c("el-input", {
                  staticClass: "wp-input",
                  attrs: {
                    maxlength: "4",
                    placeholder: "请输入胜方获得的积分",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.resetNumDrillSetting(
                        "winnerScore",
                        0,
                        100,
                        false
                      )
                    },
                  },
                  model: {
                    value: _vm.basicsForm.winnerScore,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.basicsForm,
                        "winnerScore",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "basicsForm.winnerScore",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex-CB" }, [
        _c(
          "div",
          { staticClass: "half-item" },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item-content",
                attrs: { label: "参与积分：", prop: "joinScore" },
              },
              [
                _c("el-input", {
                  staticClass: "wp-input",
                  attrs: {
                    maxlength: "4",
                    placeholder: "请输入参与获得的积分",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.resetNumDrillSetting(
                        "joinScore",
                        0,
                        100,
                        false
                      )
                    },
                  },
                  model: {
                    value: _vm.basicsForm.joinScore,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.basicsForm,
                        "joinScore",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "basicsForm.joinScore",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }