var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-question-mark" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm.content,
            placement: _vm.placement,
            "visible-arrow": false,
            "popper-class": "tooltip",
          },
        },
        [_c("i", { staticClass: "icon-style", class: _vm.iconClass })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }