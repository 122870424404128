var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-drill-dialog scoreRules" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-body" }, [
            _c("div", {
              staticClass: "close-btn",
              on: { click: _vm.handleClose },
            }),
            _c("div", { staticClass: "dialog-body-content" }, [
              _c("div", { staticClass: "dialog-top flex-center" }, [
                _c("div", { staticClass: "title" }, [_vm._v("计分规则")]),
              ]),
              _c("div", { staticClass: "dialog-mid" }, [
                _vm._v("个人得分=热评得分+评论得分+转发得分"),
              ]),
              _c("div", { staticClass: "dialog-bot" }, [
                _c("div", { staticClass: "desc-item" }, [
                  _c("div", { staticClass: "desc-title flex-Acenter" }, [
                    _c("span", { staticClass: "dot" }),
                    _vm._v("热评得分 "),
                  ]),
                  _c("p", [
                    _vm._v(
                      "评论区前5条热评，分别获得15、10、7、5、3分，其它热评加1分"
                    ),
                  ]),
                  _c("p", [
                    _vm._v("热评数量：评论区前n条评论，创建演练时可设置该数值"),
                  ]),
                ]),
                _c("div", { staticClass: "desc-item" }, [
                  _c("div", { staticClass: "desc-title flex-Acenter" }, [
                    _c("span", { staticClass: "dot" }),
                    _vm._v("评论得分 "),
                  ]),
                  _c("p", [_vm._v("评论数量×0.1（单人可多次评论）")]),
                ]),
                _c("div", { staticClass: "desc-item" }, [
                  _c("div", { staticClass: "desc-title flex-Acenter" }, [
                    _c("span", { staticClass: "dot" }),
                    _vm._v("转发得分 "),
                  ]),
                  _c("p", [
                    _vm._v(
                      "微博转发1条0.1分（多次转发只算一次），评论转发不得分"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "desc-item" }, [
                  _c("div", { staticClass: "desc-title flex-Acenter" }, [
                    _c("span", { staticClass: "dot" }),
                    _vm._v("扣分规则 "),
                  ]),
                  _c("p", [
                    _vm._v("缺1人响应扣0.1分 （未响应包括“未评论或未转发”）"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }