import Vue from 'vue'
import WpConfirm from './index.vue'

const ConfirmBox = Vue.extend(WpConfirm);
WpConfirm.install = (title, options) => {
	if (typeof title === 'object') {
		options = title;
		title = '提示';
	} else if (title === undefined) {
		title = '提示';
	}

	options = Object.assign({
		title: title
	}, options);

	let instance = new ConfirmBox({
		data: options
	}).$mount();

	document.body.appendChild(instance.$el);

	return instance.confirm();
};

//页面调用示例
// showConfirm() {
// 	this.$wpConfirm('删除', {
// 		tips: "确定删除吗？",
// 		content: "删除后数据不可恢复",
// 		yesBtnText: '登录',
// 		isShowCancelBtn: true, //是否显示取消按钮
// 		cancelBtnText: '否',
// 	}).then(() => {
// 		//点登录
// 		console.log("确认")
// 	}).catch(() => {
// 		//点取消
// 		console.log("取消")
// 	});
// },
// showConfirm2() {
// 	this.$wpConfirm('重置密码', {
// 		tips: "确定重置密码吗？",
// 		content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
// 		yesBtnText: '登录',
// 		isShowCancelBtn: true, //是否显示取消按钮
// 		cancelBtnText: '否',
// 	}).then(() => {
// 		//点登录
// 		console.log("确认")
// 	}).catch(() => {
// 		//点取消
// 		console.log("取消")
// 	});
// }
export default WpConfirm
