<template>
  <wp-dialog @cancel="cancel" :title="isEidt ? '编辑优评上报' : '优评上报'" @determine="determine" width="680px" height="643px" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="createModel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform" @submit.native.prevent>

        <el-form-item prop="taskName" label="优评时段：">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.taskName" placeholder="请输入优评时段" clearable @keyup.enter.native="determine" readonly></el-input>
        </el-form-item>
        <el-form-item prop="userId" label="人员账号：">
          <el-select class="wp-select" style="width:100%" v-model="ruleForm.userId" clearable filterable placeholder="请选择人员账号" @keyup.enter.native="determine" @change="changePersonnel">
            <el-option v-for="item in userList" :key="item.userId" :label="item.loginName" :value="item.userId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="userName" label="人员姓名：">
          <el-input class="wp-input" v-model.trim="ruleForm.userName" placeholder="请输入人员姓名" readonly></el-input>
        </el-form-item>
        <el-form-item label="任务标题：" prop="title">
          <el-input class="wp-input" maxlength="100" v-model.trim="ruleForm.title" placeholder="请输入任务标题" clearable @keyup.enter.native="determine"></el-input>

        </el-form-item>
        <el-form-item label="任务链接：" prop="url">
          <el-input placeholder="请输入任务链接" v-model.trim="ruleForm.url" maxlength="2000" @keyup.enter.native="determine">
          </el-input>
        </el-form-item>
        <el-form-item label="评论内容：" prop="content" @keyup.enter.native="determine">
          <el-input type="textarea" class="remarks" maxlength="1000" placeholder="请输入评论内容" v-model="ruleForm.content" resize="none">
          </el-input>
        </el-form-item>
        <el-form-item label="截图/附件：" prop="fileJson">
          <div class="upload-box">
            <UploadFile ref="upload"   :fileList="fileJson" :imageMaxNum="5" :maxVideo="0" placeholder="请上传jpg、jpeg、png格式文件" :videoMaxNum="0" :fileType="['jpg', 'jpeg', 'png']" key="img" @fileChange="fileChange" @changeUpload="changeUpload" accept=".jpg, .jpeg, .png"></UploadFile>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { validURL } from '@/utils/validate.js';
import UploadFile from '@/views/components/uploadFileClipboard'
import {insertEexcellentArticle,updateEexcellentArticle,excellentList,getExcellent,userListByGroupId} from '@/api/excellentReport.js'
export default {
  name: 'createModel',
  components: { UploadFile },
  data () {

    const validateURL = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入有效任务链接'));
      } else if (!validURL(value)) {
        callback(new Error('请输入有效任务链接'));
      } else {
        callback();
      }
    };
    return {
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        taskId: '',
        taskName: '',
        userId: '',
        userName: '',
        title: '',
        url: '',
        content: '',
        fileJson: '',
      },
      rules: {
        taskName: [
          { required: true, message: '请输入优评时段', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '请选择人员账号', trigger: ['blur','change'] }
        ],
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入任务标题', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入有效任务链接', trigger: 'blur' },
          { validator: validateURL, trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入评论内容', trigger: 'blur' }
        ],
        fileJson: [
          { required: true, message: '请上传截图/附件', trigger: 'blur' }
        ]
      },
      isPosting: false,
      isEidt: false,
      userList: [],
      excellentObjDetail: {},
      fileJson:[],
      isUpload:false
      
    };
  },
  props: {
    excellentObj: {
      type: Object,
      default: () => { }
    },
    createId: {
      type: Number | String,
      default:''
    },
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 初始化数据
    initDate () {
      if (this.excellentObj.id) {
        this.isEidt = true
        
        this.getDetail()
      } else {
        this.getExcellentList()
        this.getUserListByGroupId()
      }
    },
    cancel () {
      this.handleClose()
    },
    determine () {
      if(this.isUpload) return  this.$showMessage({
          type: 'error',
          msg: '文件正在上传中'
        })
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let query = {
        ...this.ruleForm
      }
      try {
        let api = insertEexcellentArticle
        if (this.isEidt) {
          query.id = this.excellentObj.id
          api = updateEexcellentArticle
        }else{
          query.createId = this.createId
        }
        const res = await api(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {

        console.log(error)
      } finally {
        this.isPosting = false
      }
    },
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },

    /** 文件上 传成功回调 */
    fileChange (fileList) {
      console.log(fileList);
      this.ruleForm.fileJson = fileList
    },
    // 选择人员账号
    changePersonnel (val) {
      if(val){
        const item = this.userList.find(it => it.userId == val)
        this.ruleForm.userName = item.userName
      }else{
        this.ruleForm.userName = ''
      }
     
    },
    // 优评分类
    async getExcellentList () {
      try {
        const res = await excellentList({createId:this.createId})
        if (Number(res.returnCode) !== 200) return
        const returnData = res.returnData
        this.$nextTick(() => {
          this.ruleForm.taskId = returnData[0].id
          this.ruleForm.taskName = returnData[0].name
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 优评获取组员列表
    async getUserListByGroupId () {
      try {
        const res = await userListByGroupId({userId:this.createId})
        if (Number(res.returnCode) !== 200) return
        this.userList = res.returnData
        if (this.isEidt) {
          this.$nextTick(() => {
            this.ruleForm.userId = this.excellentObjDetail.userId
            this.ruleForm.userName = this.excellentObjDetail.userName
          })

        }
      } catch (error) {
        console.log(error)
      }
    },
    // 单个查询
    async getDetail () {
      try {
        const res = await getExcellent({ id: this.excellentObj.id })
        if (Number(res.returnCode) !== 200) return
        const returnData = res.returnData
        this.excellentObjDetail = returnData
        this.getUserListByGroupId()
        this.$nextTick(() => {
          this.ruleForm.taskId = returnData.taskId
          this.ruleForm.taskName = returnData.taskName
          this.ruleForm.title = returnData.title
          this.ruleForm.url = returnData.url
          this.ruleForm.content = returnData.content
          this.ruleForm.fileJson = JSON.parse(returnData?.fileJson) || []
          this.fileJson = JSON.parse(returnData?.fileJson) || []
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.$nextTick(()=>{
          this.$refs?.ruleForm?.resetFields()
          })
          this.initDate();
        } else {
          this.$refs.upload.resetFile()
          this.$reset('ruleForm', ['rules'])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.createModel {
  padding: 40px;

  .model-top {
    display: flex;
    justify-content: flex-end;
  }

  .init-pass {
    font-size: 14px;
    font-weight: 400;
    color: #333333;

    > span {
      color: #f35454;
    }
  }

  .ruleform {
    margin-top: 30px;
    padding: 0px;
  }
}
/deep/.remarks.el-textarea {
  .el-textarea__inner {
    height: 110px;
    padding-bottom: 40px;
  }
}

.upload-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-box {
  width: 100%;
  display: block;
}
</style>