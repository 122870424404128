<template>
  <div class="page404 flex-column-center">
    <img src="~@/assets/images/404.png" alt="">
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  name: 'panel',
  components: {},
  data () {
    return {

    };
  },
  props: {},
  created () {

  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    // ...mapMutations(['SET_REMINDNUM']),

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
.page404 {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}
</style>
