<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <el-breadcrumb style="margin: 20px 24px 0" separator-class="el-icon-arrow-right" class="wp-breadcrumb">
        <el-breadcrumb-item :to="{ path: '/reportedReview' }">上报审核</el-breadcrumb-item>
        <el-breadcrumb-item>审核详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="main">
        <ContentCard :title="topicObj.title" slotValue="row-content">
          <template v-slot:row-content>
            <div class="key">
              专题编号：<span class="value">{{ topicObj.topicNum }}</span>
            </div>
            <div class="key">
              专题分类：<span class="value">{{ topicObj.topicTypeName }}</span>
            </div>
            <div class="key">
              专题积分：<span class="score">{{
                topicObj.score == -999 ? '按平台' : topicObj.score
              }}</span>
            </div>
          </template>
        </ContentCard>

        <ContentCard class="mt-16" slotValue="vertical-content" :className="getClassName">
          <template v-slot:vertical-content>
            <div class="item" v-if="articleObj.title">
              <div class="key">作品标题：</div>
              <p class="value">{{ articleObj.title }}</p>
            </div>

            <div class="work-content" v-if="articleObj.content">
              <div class="key">作品内容：</div>
              <p class="value">
                {{ articleObj.content }}
              </p>
            </div>

            <div class="item" v-if="articleObj.url">
              <div class="key">作品链接：</div>
              <p class="url"  @click.stop="$utils.download(articleObj.url)">
                {{ articleObj.url }}
              </p>
            </div>

            <div class="row-content">
              <div class="key" v-if="articleObj.media">
                媒体平台：<span class="value">{{ articleObj.media }}</span>
              </div>
              <div class="key" v-if="articleObj.publishType">
                发布类型：<span class="value">{{
                  articleObj.publishType
                }}</span>
              </div>
              <div class="key" v-if="articleObj.authorType">
                作者类别：<span class="value">{{ articleObj.authorType }}</span>
              </div>
              <div class="key" v-if="articleObj.author">
                作者名称：<span class="value">{{ articleObj.author }}</span>
              </div>
              <div class="key" v-if="articleObj.netName">
                网名/昵称：<span class="value">{{ articleObj.netName }}</span>
              </div>
            </div>

            <div class="row-content">
              <div class="key" v-if="articleObj.readNum">
                阅读数量：<span class="score">{{ articleObj.readNum }}万</span>
              </div>
              <div class="key" v-if="articleObj.reprint">
                转发数量：<span class="score">{{ articleObj.reprint }}</span>
              </div>
            </div>

            <div class="file-list" v-if="articleObj.fileJson && articleObj.fileJson.length">
              <div class="key">截图/附件：</div>
              <File :fileJson="articleObj.fileJson"></File>
            </div>
          </template>
        </ContentCard>

        <div class="wp-card mt-16 footer">
          <div class="head">
            <div class="title">审核意见</div>
            <div class="score-box">
              <div>
                奖励积分：
                <span class="score">{{ articleObj.totalScore }}</span>
              </div>
              <el-popover ref="popoverDetail" @hide="hidePopover(articleObj)" placement="top" trigger="click" width="220">
                <el-input-number @input.native="changeScore($event)" :min="0" :precision="1" :max="10000" size="small" v-model="articleObj.totalScore" style="margin-bottom: 5px"></el-input-number>
                <el-button @click="editScore(articleObj)" style="margin-left: 6px" type="primary" size="small">确定</el-button>
                <div slot="reference" @click="clickEditScore(articleObj)" style="margin-left: 24px" class="wp-blue wp-pointer">
                  改分
                </div>
              </el-popover>
            </div>
          </div>
          <div class="content" v-if="articleObj.articleStatus == 1">
            <el-input type="textarea" :rows="3" placeholder="请输入审核意见" maxlength="50" v-model="rejectContent">
            </el-input>
          </div>
          <div class="comment" v-else>
            <div class="user">
              <el-avatar style="width: 32px; height: 32px" :src="headUrl"></el-avatar>
              <div class="user-name">{{ rejectData.createName || '' }}</div>
              <div class="publish-time">
                {{ $moment(rejectData.createTime).format('YYYY-MM-DD HH:mm') }}
              </div>
            </div>
            <div class="detail-content">
              {{ rejectData.resonRemark }}
            </div>
          </div>

          <div class="bottom">
            <div v-if="articleObj.articleStatus == 1">
              <el-button class="btn" size="medium" type="danger" @click="handlerReject">驳回</el-button>
            </div>
            <div v-else></div>

            <div>
              <el-button key="up" v-if="acticleIdx != 0" @click="up" class="wp-button" size="medium">上一条</el-button>

              <el-button key="down" v-if="acticleIdx != articleIds.length - 1" @click="down" class="wp-button" size="medium">下一条</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import reportedApi from '@/api/reported.js'
import File from '@/views/components/fileDetail'
import ContentCard from '@/views/components/contentCard'
export default {
  components: { ContentCard, File },
  data () {
    return {
      rejectContent: "", // 驳回内容
      scoreNum: 0, // 分数// 质量评级列表
      gradeList: [
        { id: 3, name: "优秀", stars: 3 },
        { id: 2, name: "良好", stars: 2 },
        { id: 1, name: "合格", stars: 1 },
        { id: -1, name: "不合格", stars: -1 },
        { id: 0, label: "未评级", star: 0 },
      ],
      isDetail: false,
      detailId: "", // 专题Id
      topicObj: {}, // 专题详情
      articleObj: {}, // 文章详情
      articleRow: {}, // 文章详情(拷贝)
      rejectData: {}, // 驳回详情
      acticleIdx: 0, // 文章索引
      articleIds: [], // 文章列表
      videoVisible: false, // 播放视频弹窗
      headUrl: require("@/assets/images/touxiang.png")
    }
  },
  computed:{
    getClassName(){
      return (this.articleObj.parmPush && this.articleObj.parmPush==1) ? 'showPushImg':''
    }
  },
  created () {
    if (this.$route.query.id) {
      const { id, acticleIdx } = this.$route.query
      this.detailId = id
      this.acticleIdx = +acticleIdx
      console.log(id, +acticleIdx);

      // 逐条审核(上一条,下一条)
      this.getArticleIds(JSON.parse(this.$route.query.params))
      // 详情
      this.getDetail()
    }
  },

  watch: {
    downFlag: {
      handler (newVal) {
        console.log(newVal);
      }
    }
  },

  methods: {
    changeScore (e) {
      console.log(e.target.value)
      if ((e.target.value.indexOf('.') >= 0)) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 2)
      }
      console.log(e.target.value)
    },

    /** 获取文章Id列表 */
    async getArticleIds (params) {
      const res = await reportedApi.topicInfoArticleIds(params)
      if (res.returnCode == 200) {
        this.articleIds = res.returnData?.articleIds || []
      }
    },

    // 上一条
    up () {
      if (this.acticleIdx !== 0) {
        this.acticleIdx -= 1
        this.detailId = this.articleIds[this.acticleIdx]
        this.getDetail()
      }
    },

    // 下一条
    down () {
      if (this.acticleIdx != this.articleIds.length - 1) {
        this.acticleIdx += 1
        this.detailId = this.articleIds[this.acticleIdx]
        this.getDetail()
      }
    },

    /** 审核详情 */
    async getDetail () {
      this.rejectContent = ""
      console.log(this.articleIds, this.detailId, this.acticleIdx);

      const res = await reportedApi.getArticleDetail({ articleId: this.detailId })
      if (res.returnCode == 200) {
        const data = res.returnData

        console.log(data);
        // 专题详情
        this.topicObj = data.wpTopicMain
        // 文章详情
        this.articleObj = data.wpTopicArticle

        this.articleObj.fileJson = JSON.parse(this.articleObj.fileJson) || []

        // 驳回详情
        this.rejectData = data?.verify || {}
      }
    },

    /** 驳回 */
    async handlerReject () {
      if (this.rejectContent) {
        const query = {
          articleId: this.detailId,
          rejectMsg: this.rejectContent
        }
        const res = await reportedApi.rejectArticle(query)
        if (res.returnCode == 200) {
          this.$showMessage({ type: 'success', msg: '操作成功' })

          this.down()
          this.getDetail()
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      } else {
        this.$showMessage({ type: 'warning', msg: "请输入审核意见" })
      }
    },

    // 点击改分图标
    clickEditScore (item) {
      item.isSubmit = false
      this.articleRow = JSON.parse(JSON.stringify(item))
    },

    // 隐藏弹窗回调
    hidePopover (item) {
      console.log(item.isSubmit, this.articleRow.totalScore)
      // 未点击确定按钮,则将该分数恢复至编辑前
      if (item.isSubmit) {
        item.isSubmit = false
      } else {
        item.totalScore = this.articleRow.totalScore
      }
    },

    // 修改评分 
    async editScore (obj) {
      const query = {
        topicArticleId: this.detailId,
        rewardScore: obj.totalScore
      }
      const res = await reportedApi.updateScore(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', msg: '操作成功' })
        this.$refs.popoverDetail.doClose()
        obj.isSubmit = true
        console.log(obj)
        this.getDetail()
      }
    }
    
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>