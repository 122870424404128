import { render, staticRenderFns } from "./changePassword.vue?vue&type=template&id=13ac0d98&scoped=true&"
import script from "./changePassword.vue?vue&type=script&lang=js&"
export * from "./changePassword.vue?vue&type=script&lang=js&"
import style0 from "./changePassword.vue?vue&type=style&index=0&id=13ac0d98&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ac0d98",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wulijuan\\code\\code20220621\\wp-web-new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13ac0d98')) {
      api.createRecord('13ac0d98', component.options)
    } else {
      api.reload('13ac0d98', component.options)
    }
    module.hot.accept("./changePassword.vue?vue&type=template&id=13ac0d98&scoped=true&", function () {
      api.rerender('13ac0d98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/components/changePassword.vue"
export default component.exports