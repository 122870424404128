<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <TabsCard
          :tabs="tabs"
          :activeTab="activeTab"
          @handlerTabs="handlerTabs"
          v-if="isCanAccessExcellentReview"
        >
          <el-tab-pane label="投稿审核" name="taskReview">
            <SearchCard
              title=""
              @handlerSearch="handlerSearch"
              class="peopleSearch"
            >
              <template v-slot:searchContent>
                <TaskTimeSelect
                  :activeType="searchParams.timeType"
                  @timeSelect="timeSelect"
                ></TaskTimeSelect>

                <wpLoadSelect
                  class="search-w392 wp-select align-top"
                  v-model="searchParams.taskId"
                  :data="taskList"
                  :page="taskObj.page"
                  dictLabel="title"
                  dictValue="taskId"
                  dictTime="createTime"
                  :hasMore="taskObj.more"
                  :request="getData"
                  @clearTitle="() => (taskObj.title = '')"
                  placeholder="请选择任务"
                ></wpLoadSelect>

                <el-input
                  class="search-w190 wp-input"
                  v-model.trim="searchParams.createName"
                  clearable
                  placeholder="请输入人员姓名"
                >
                </el-input>

                <el-cascader
                  ref="cascaderRef"
                  class="search-w190 wp-cascader align-top"
                  :options="groupList"
                  @change="changeCascader"
                  :props="defaultProps"
                  collapse-tags
                  placeholder="请选择所在分组"
                  clearable
                >
                </el-cascader>

                <el-select
                  class="search-w190 wp-select"
                  v-model="searchParams.stars"
                  placeholder="请选择质量评级"
                  collapse-tags
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in gradeList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                  <el-option label="未评级" value="0"></el-option>
                </el-select>

                <el-input
                  class="search-w190 wp-input"
                  v-model.trim="searchParams.content"
                  placeholder="请输入转发/评论"
                  clearable
                >
                </el-input>

                <el-select
                  class="search-w190 wp-select"
                  v-model="searchParams.articleStatus"
                  placeholder="请选择审核状态"
                  clearable
                >
                  <el-option
                    v-for="item in $globalData.REVIEWSTATUS"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </SearchCard>
          </el-tab-pane>
          <el-tab-pane label="优评审核" name="excellentReview"> </el-tab-pane>
        </TabsCard>
        <SearchCard v-else title="投稿审核" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <TaskTimeSelect
              :activeType="searchParams.timeType"
              @timeSelect="timeSelect"
            ></TaskTimeSelect>

            <wpLoadSelect
              class="search-w392 wp-select align-top"
              v-model="searchParams.taskId"
              :data="taskList"
              :page="taskObj.page"
              dictLabel="title"
              dictValue="taskId"
              dictTime="createTime"
              :hasMore="taskObj.more"
              :request="getData"
              @clearTitle="() => (taskObj.title = '')"
              placeholder="请选择任务"
            ></wpLoadSelect>

            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.createName"
              clearable
              placeholder="请输入人员姓名"
            >
            </el-input>

            <el-cascader
              ref="cascaderRef"
              class="search-w190 wp-cascader align-top"
              :options="groupList"
              @change="changeCascader"
              :props="defaultProps"
              collapse-tags
              placeholder="请选择所在分组"
              clearable
            >
            </el-cascader>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.stars"
              placeholder="请选择质量评级"
              collapse-tags
              clearable
              multiple
            >
              <el-option
                v-for="item in gradeList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
              <el-option label="未评级" value="0"></el-option>
            </el-select>

            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.content"
              placeholder="请输入转发/评论"
              clearable
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.articleStatus"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="flex">
              <!-- 勾选了数据时 -->
              <el-popover
                v-show="multipleSelection && multipleSelection.length"
                placement="bottom"
                :visible-arrow="false"
                width="350px"
                trigger="click"
                popper-class="rate-popper"
                :tabindex="4"
              >
                <div class="popover-content">
                  <div
                    class="grade-text"
                    v-for="item in gradeList"
                    :key="item.id"
                    @click="batchRate(item.star)"
                  >
                    {{ item.label }}
                  </div>
                  |
                  <el-button
                    type="text"
                    style="margin-left: 10px; color: #ff5959"
                    @click="batchRate('0')"
                    >清空
                  </el-button>
                </div>
                <el-button
                  class="wp-button"
                  slot="reference"
                  type="primary"
                  @click="clickBatchBtn"
                  >批量评级</el-button
                >
              </el-popover>

              <!-- 未勾选数据 -->
              <el-button
                class="wp-button"
                type="primary"
                @click="clickBatchBtn"
                v-show="!multipleSelection || multipleSelection.length == 0"
                >批量评级</el-button
              >

              <div class="export-box">
                <el-popover
                  popper-class="export-popover"
                  ref="export-popover"
                  placement="bottom"
                  trigger="click"
                >
                  <div class="export-list">
                    <div class="export-item wp-pointer" @click="collectExport">
                      汇总导出
                    </div>
                    <div class="export-item wp-pointer" @click="workExport">
                      作品导出
                    </div>
                  </div>
                  <el-button
                    style="width: 90px"
                    slot="reference"
                    class="wp-button"
                    size="medium"
                  >
                    导出<i class="icon-zhankai el-icon--right"></i
                  ></el-button>
                </el-popover>
              </div>
            </div>

            <div class="fs-14 flex">
              <div>
                提交总量：<span class="wp-blue">{{ total || 0 }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{
                  summariesData.score || 0
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table
              :data="tableData"
              stripe
              v-if="tableData.length !== 0"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
            >
              <el-table-column align="center" type="selection" width="55">
              </el-table-column>
              <el-table-column
                align="center"
                type="index"
                label="序号"
                max-width="70"
                min-width="30"
              >
              </el-table-column>
              <el-table-column max-width="20" min-width="5"> </el-table-column>
              <el-table-column
                align="center"
                prop="fileJson"
                max-width="150"
                min-width="100"
                label="截图/附件"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="
                      goDetail(scope.row.taskArticleId, scope.$index)
                    "
                    style="cursor: pointer"
                  >
                    <p
                      v-if="JSON.parse(scope.row.fileJson).length"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="20" min-width="5"> </el-table-column>
              <el-table-column
                align="left"
                prop="content"
                label="转发/评论"
                max-width="220"
                min-width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-popover
                    placement="top"
                    :visible-arrow="false"
                    width="350px"
                    popper-class="rate-popper"
                    trigger="click"
                  >
                    <div class="popover-content">
                      <div
                        class="grade-text"
                        v-for="item in gradeList"
                        :key="item.id"
                        @click="rateChange(scope.row, item.star)"
                      >
                        {{ item.label }}
                      </div>
                      |
                      <el-button
                        type="text"
                        style="margin-left: 13px; color: #ff5959"
                        @click="rateChange(scope.row, '0')"
                        >清空
                      </el-button>
                    </div>
                    <div slot="reference" class="textOverOneLine wp-pointer">
                      {{ scope.row.content || '-' }}
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column max-width="50" min-width="20"> </el-table-column>
              <el-table-column
                align="left"
                prop="createName"
                max-width="120"
                min-width="100"
                show-overflow-tooltip
                label="人员姓名"
              >
              </el-table-column>
              <el-table-column width="10"> </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="提交时间"
                width="150"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                max-width="130"
                min-width="100"
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
              </el-table-column>
              <el-table-column align="center" prop="stars" label="质量星级">
                <template slot-scope="scope">
                  <div v-if="scope.row.stars == 0">-</div>
                  <div v-else-if="scope.row.stars == 3">优秀</div>
                  <div v-else-if="scope.row.stars == 2">良好</div>
                  <div v-else-if="scope.row.stars == 1">合格</div>
                  <div v-else>不合格</div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div>
                    <div
                      class="flex-center"
                      v-if="scope.row.articleStatus == 1"
                    >
                      <div class="success-circle"></div>
                      <div class="success-color">已通过</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">已驳回</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <div class="step flex-center">
                    <div class="wp-action-icon">
                      <el-popover
                        :ref="`popover-${scope.$index}`"
                        placement="top"
                        trigger="click"
                        width="220"
                      >
                        <el-input-number
                          @input.native="changeScore($event)"
                          :min="0"
                          :precision="1"
                          :max="100"
                          size="small"
                          v-model="editRow.totalScore"
                          style="margin-bottom: 5px"
                        >
                        </el-input-number>
                        <el-button
                          @click="
                            submitScore(editRow, `popover-${scope.$index}`)
                          "
                          style="margin-left: 6px"
                          type="primary"
                          size="small"
                          >确定</el-button
                        >
                        <el-tooltip
                          class="item"
                          slot="reference"
                          :visible-arrow="false"
                          popper-class="wp-btn-tooltip"
                          effect="dark"
                          content="改分"
                          placement="top"
                        >
                          <el-button
                            @click="edit(scope.row)"
                            type="text"
                            icon="icon-bianji1"
                            class="wp-btn-icon-big"
                          >
                          </el-button>
                        </el-tooltip>
                      </el-popover>
                    </div>

                    <el-tooltip
                      class="item"
                      :visible-arrow="false"
                      popper-class="wp-btn-tooltip"
                      effect="dark"
                      content="驳回"
                      placement="top"
                    >
                      <RejectCause
                        :key="scope.row.taskArticleId"
                        v-if="scope.row.articleStatus == 1"
                        popoverType="add"
                        @handlerReject="handlerReject($event, scope.row)"
                      >
                        <i class="icon-bohui wp-blue wp-pointer fs-20"></i>
                      </RejectCause>
                    </el-tooltip>

                    <RejectCause
                      v-if="scope.row.articleStatus !== 1"
                      popoverType="detail"
                      :rejectObj="rejectObj"
                    >
                      <i
                        class="icon-bohui wp-pointer fs-20"
                        @mouseenter="getReject(scope.row.taskArticleId)"
                        style="color: #ccc"
                      ></i>
                    </RejectCause>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <div ref="hideDiv"></div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import reportedApi from '@/api/reported.js'
import { resetNumPointOne } from '@/utils/validate.js';
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import RejectCause from '@/views/components/rejectCause'
import TabsCard from '@/views/components/tabsCard'
import { mapGetters } from 'vuex';
export default {
  name: 'taskReview',
  components: { SearchCard, TaskTimeSelect, RejectCause, TabsCard },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      taskList: [], // 任务下拉框列表
      // 查询条件
      searchParams: {
        page: 1,
        limit: 30,
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 所选任务ID
        createName: "", // 人员姓名
        groupIds: [], // 组
        timeType: 1, // 任务时间类型
        stars: [], // 质量评级
        content: "", // 转发/评论
        articleStatus: "", // 审核状态
        timeType: 2
      },
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupList: [], // 分组树
      gradeList: this.$globalData.STARLIST,
      tableData: [],
      total: 0,
      editRow: {}, // 表格行内信息
      multipleSelection: [],
      exportType: "", // 所选导出类型
      rejectObj: {}, // 驳回信息
      qeuryData: {}, // 深拷贝后的搜素条件
      summariesData: {}, // 表格统计数据
      activeTab: "taskReview", // 所选tab
      tabs: [
        { label: '投稿审核', name: "taskReview" },
        { label: '优评审核', name: "excellentReview" }
      ],
    };
  },
  computed: {
    ...mapGetters(['sysUserInfo']),
    isCanAccessExcellentReview () {
      return Number(this.sysUserInfo.sysCustOption.accessExcellentReview) == 1
    }
  },
  created () {
    // 获取审核列表
    this.getReviewList()
    // 获取分组树
    this.getGroupTree()
    // 获取表格统计信息
    this.getSummaries()
  },

  methods: {
    changeScore (e) {
      console.log(e.target.value)
      if ((e.target.value.indexOf('.') >= 0)) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 2)
      }
      console.log(e.target.value)
    },

    resetNumPoints (e) {
      console.log(resetNumPointOne(e, 0, 100));
      // e = resetNumPointOne(e, 0, 100)
    },

    /** 汇总导出 */
    async collectExport (event) {
      if (this.total > 30000) return this.$showMessage({
        type: 'warning',
        msg: '最多导出30000条，请分批导出'
      })
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.summaryArticleExport(this.searchParams)
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    /** 作品导出 */
    async workExport (event) {
      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await taskApi.taskArticleExport(this.searchParams)
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }

    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskList = [...this.taskList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    // 获取表格统计信息
    async getSummaries () {
      const res = await taskApi.getSummaries(this.searchParams)
      if (res.returnCode == 200) {
        this.summariesData = res.returnData
      }
    },

    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },

    /** 获取分组树 */
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },

    /** 批量评级回调 */
    async batchRate (stars) {
      // 文章Id列表
      const articleIds = this.multipleSelection.reduce((prev, curr) => {
        prev.push(curr.taskArticleId)
        return prev
      }, [])
      let query = {
        articleIds,
        stars
      }
      const res = await taskApi.updateStarsBatch(query)
      if (res.returnCode == 200) {
        // 取消勾选并清空数组
        this.$refs.multipleTable.clearSelection()
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    /** 质量评级 */
    async rateChange (item, stars) {
      const query = {
        articleId: item.taskArticleId,
        stars
      }
      const res = await taskApi.updateStars(query)
      if (res.returnCode == 200) {
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    /** 审核列表 */
    async getReviewList () {

      if (this.loading) return
      this.loading = true
      try {
        const res = await taskApi.articleList(this.searchParams)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData
          this.tableData = data?.data || []
          this.total = data?.totalCount || 0
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 获取驳回信息
    async getReject (articleId) {
      const res = await taskApi.getVerify({ articleId })
      if (res.returnCode == 200) {
        this.rejectObj = res.returnData
      }
    },

    /** 提交驳回信息回调 */
    async handlerReject (rejectMsg, item) {
      const query = {
        rejectMsg,
        articleId: item.taskArticleId
      }
      console.log(query);
      const res = await taskApi.rejectArticle(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', msg: '操作成功' })
        this.getReviewList()
        this.getSummaries()
      }
    },

    // 导出
    handlerExport (type) {
      this.exportType = type
      this.$refs['export-popover'].doClose()
    },

    // 批量质量评级
    clickBatchBtn () {
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        return this.$showMessage({
          type: 'error',
          msg: '请选择要操作的数据'
        })
      }
    },

    // 搜索回调
    handlerSearch (data) {
      this.searchParams = { ...this.searchParams, ...data }
      this.searchParams.page = 1
      this.getReviewList()
      this.getSummaries()
      console.log(this.searchParams)
    },

    // 编辑分数
    edit (item) {
      this.editRow = JSON.parse(JSON.stringify(item))
    },

    // 详情
    goDetail (articleId, idx) {
      const acticleIdx = (this.searchParams.page - 1) * this.searchParams.limit + idx
      this.$router.push({
        path: 'taskReviewDetail',
        query: {
          articleId,
          acticleIdx,
          params: JSON.stringify(this.searchParams)
        }
      })
    },

    // 提交编辑分数
    async submitScore (item, index) {
      let query = {
        articleId: item.taskArticleId,
        rewardScore: item.totalScore
      }
      const res = await taskApi.updateScore(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', message: '操作成功' })
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
        this.getSummaries()
      }
    },

    // 时间筛选
    timeSelect (data) {
      console.log(data)
      this.searchParams = { ...this.searchParams, ...data }
    },

    // 表格勾选回调
    handleSelectionChange (arr) {
      console.log(arr);
      this.multipleSelection = arr
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getReviewList()
    },
    handlerTabs (name) {
      if (name != "taskReview") {
        this.$router.push('/excellentReportManage')
      }
    }
  },
};
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}

.export-box {
  margin-left: 12px;
}
</style>
<style scoped lang="scss">
@import './index.scss';
</style>
