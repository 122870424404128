var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.tabsList.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.tabsList,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "优评列表" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "wp-date-picker search-w392" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "default-time": ["00:00:00", "23:59:59"],
                                type: "datetimerange",
                                "prefix-icon": "",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                clearable: "",
                                "range-separator": "-",
                                "start-placeholder": "请选择开始时间",
                                "end-placeholder": "请选择结束时间",
                              },
                              on: { change: _vm.changeTimeSection },
                              model: {
                                value: _vm.timeSection,
                                callback: function ($$v) {
                                  _vm.timeSection = $$v
                                },
                                expression: "timeSection",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择评选时段",
                            },
                            on: { change: _vm.changeTaskId },
                            model: {
                              value: _vm.searchParams.taskId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "taskId", $$v)
                              },
                              expression: "searchParams.taskId",
                            },
                          },
                          _vm._l(_vm.excellents, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                        _c("el-input", {
                          staticClass: "search-w190 wp-input",
                          attrs: {
                            placeholder: "请输入任务标题",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "title",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchParams.title",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "search-w190 wp-input",
                          attrs: {
                            placeholder: "请输入人员姓名",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.userName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchParams.userName",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: {
                              clearable: "",
                              placeholder: "请选择优评结果",
                            },
                            model: {
                              value: _vm.searchParams.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "status", $$v)
                              },
                              expression: "searchParams.status",
                            },
                          },
                          _vm._l(
                            _vm.$globalData.EXCELLENT_REVIEW_STATUS,
                            function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 上报数量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "wp-button wp-btn-icon",
                            attrs: { size: "medium", icon: "icon-daochu" },
                            on: { click: _vm.exportFile },
                          },
                          [_vm._v("导出 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "preventReClick",
                                rawName: "v-preventReClick",
                              },
                            ],
                            staticClass: "wp-button wp-btn-icon",
                            attrs: {
                              type: "primary",
                              icon: "icon-tianjia",
                              size: "medium",
                            },
                            on: { click: _vm.handlerUpload },
                          },
                          [_vm._v(" 优评上报 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  "min-width": "80",
                                  label: "序号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "taskName",
                                  "min-width": "120",
                                  label: "评选时段",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "title",
                                  label: "任务标题",
                                  "min-width": "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                tabindex: scope.$index,
                                                disabled:
                                                  !_vm.isShowTooltipWidth,
                                                content: scope.row.title,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "textOverOneLine active-color",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.visibilityChangeWidth(
                                                        $event
                                                      )
                                                    },
                                                    click: function ($event) {
                                                      return _vm.goDetail(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(scope.row.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  945873762
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "fileJson",
                                  width: "150",
                                  label: "截图",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "active-color",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _vm.$utils.isImg(
                                                scope.row.fileJson
                                              )
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            "visible-arrow": false,
                                                            trigger: "hover",
                                                            "popper-class":
                                                              "img-popper",
                                                            tabindex: 3,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "scaled-image",
                                                            attrs: {
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                              fit: "contain",
                                                            },
                                                          }),
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "22px",
                                                              height: "22px",
                                                            },
                                                            attrs: {
                                                              slot: "reference",
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "22px",
                                                      height: "22px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                    },
                                                    slot: "reference",
                                                  }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1834746096
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "content",
                                  label: "评论内容",
                                  "min-width": "120",
                                  "max-width": "130",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "userName",
                                  label: "姓名",
                                  "min-width": "120",
                                  "max-width": "130",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "loginName",
                                  label: "账号",
                                  "min-width": "120",
                                  "max-width": "130",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "status",
                                  label: "评选结果",
                                  "min-width": "90",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.status ==
                                          _vm.$globalData.EXCELLENT_STATUS
                                            .EXCELLENT
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "success-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "success-color",
                                                    },
                                                    [_vm._v("优评")]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "disabled-color",
                                                    },
                                                    [_vm._v("-")]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1858103246
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "createTime",
                                  label: "上报时间",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  fixed: "right",
                                  width: "90",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "编辑",
                                                        placement: "top",
                                                        "visible-arrow": false,
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        class: !scope.row.edit
                                                          ? "no-drop"
                                                          : "",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-bianji1",
                                                          disabled:
                                                            !scope.row.edit,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editItem(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "删除",
                                                        placement: "top",
                                                        "visible-arrow": false,
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        class: !scope.row.edit
                                                          ? "no-drop"
                                                          : "",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-shanchu",
                                                          disabled:
                                                            !scope.row.edit,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delItem(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1360562785
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("ExcellentDialog", {
        ref: "excellentDialog",
        attrs: { excellentObj: _vm.excellentObj, createId: _vm.userId },
        on: { success: _vm.handlerSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }