var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "420px",
        smallHeight: "170px",
        size: "small",
        dialogVisible: _vm.dialogVisible,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "addGroup" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleform",
              attrs: { model: _vm.ruleForm, "label-width": "0px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "commonName",
                    label: "",
                    rules: {
                      required: true,
                      message: _vm.placeholder,
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: _vm.placeholder,
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.commonName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "commonName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.commonName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }