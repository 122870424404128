<template>
  <div class="progressDataList">
    <ul>
      <li class="progress-li" v-for="item in dataList" :key="item.id">
        <el-popover placement="top" popper-class="progress-popover" trigger="hover" :visible-arrow="false">
          <div>
            <div class="flex-Acenter">
              <div class="progress-popover-left">
                <span class="dot"></span>
              </div>
              <div class="progress-popover-right">
                <div class="title">{{item.name}}</div>
              </div>
            </div>
            <div class="flex-Acenter popover-row ">
              <div class="progress-popover-left">
              </div>
              <div class="progress-popover-right">
                <p>投稿数量 {{item.total}}</p>
              </div>
            </div>
            <div class="flex-Acenter popover-row">
              <div class="progress-popover-left">
              </div>
              <div class="progress-popover-right">
                <p>投稿站点占比 {{item.num}}%</p>
              </div>
            </div>

          </div>
          <div slot="reference" class="progress-li-box">
            <div class="progress-name textOverOneLine">{{ item.name }}</div>
            <div class="progress-bar">
              <el-progress :percentage="item.num" color="#0064E6" :stroke-width="6"></el-progress>
            </div>
          </div>
        </el-popover>
      </li>
    </ul>
    <div class="wp-noData" v-if="dataList.length == 0">
      <img src="~@/assets/images/noData.png" alt="">
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progressDataList',
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {

    };
  },
  mounted () {

  },
  beforeDestroy () {

  },
  computed: {

  },
  methods: {

  },
  watch: {
    // count: {
    // 	handler: function(val, oldval) {
    // 		if (val) {
    // 			this.resizeW()
    // 		}
    // 	},
    // 	deep: true //对象内部的属性监听，也叫深度监听
    // }
  },
};
</script>

<style scoped lang="scss">
.progressDataList {
  padding: 8px;
  width: 100%;
  .progress-li {
    width: 100%;
    margin-top: 17px;
    .progress-li-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &:first-child {
      margin-top: 0px;
    }
    .progress-name {
      flex-shrink: 0;
      // min-width: 60px;
      width: 60px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      margin-right: 8px;
    }

    .progress-bar {
      flex: 1;
    }
  }
}
</style>
<style  lang="scss">
.progress-popover {
  // width: 185px;
  // height: 106px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(3, 109, 255, 0.2);
  border-radius: 2px;
  padding: 18px;
  width: auto !important;
  .popover-row {
    margin-top: 14px;
  }
  .progress-popover-left {
    flex-shrink: 0;
    margin-right: 9px;
    width: 9px;
    height: 8px;
    .dot {
      display: block;
      width: 8px;
      height: 8px;
      background: #00a5ff;
      border-radius: 50%;
    }
  }
  .progress-popover-right {
    flex-shrink: 0;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
    p {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
  }
}
</style>
