var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-CB" }, [
      _c("div", { staticClass: "flex-Acenter fs-14" }, [
        _c("div", [
          _vm._v(" 任务数量："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.taskNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 完成人数："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.completeNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 奖励积分："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.score || 0)),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: { size: "medium", icon: "icon-daochu" },
              on: { click: _vm.exportFile },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "wp-table mt-20" },
      [
        _vm.taskList.length !== 0
          ? _c(
              "el-table",
              {
                attrs: {
                  data: _vm.taskList,
                  stripe: "",
                  sortable: "custom",
                  "default-sort": _vm.sortObj,
                },
                on: { "sort-change": _vm.sortChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "taskNumber",
                    label: "任务编号",
                    "max-width": "100",
                    "min-width": "80",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    prop: "title",
                    label: "任务标题",
                    "max-width": "250",
                    "min-width": "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "30", "min-width": "10" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    prop: "url",
                    label: "任务链接",
                    "max-width": "200",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.url
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "wp-blue wp-pointer textOverOneLine",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$utils.download(
                                          scope.row.url
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.url) + " ")]
                                )
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1891364519
                  ),
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "40", "min-width": "20" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    prop: "modeStyle",
                    label: "任务方式",
                    "max-width": "150",
                    "min-width": "90",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "20", "min-width": "10" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "createTime",
                    label: "发布时间",
                    "max-width": "200",
                    "min-width": "150",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$moment(scope.row.createTime)
                                      .format("YYYY-MM-DD HH:mm")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2503685475
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "completeNum",
                    label: "完成人数",
                  },
                }),
                _vm.searchParams.timeType == 2
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "completionRate",
                        label: "完成率",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.completionRate) + "%"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3143357017
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    sortable: "",
                    prop: "workNum",
                    label: "作品数量",
                    "show-overflow-tooltip": "",
                    width: "92",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "10", "min-width": "5" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    sortable: "",
                    prop: "totalScore",
                    label: "奖励积分",
                    "show-overflow-tooltip": "",
                    width: "92",
                  },
                }),
                _c("el-table-column", { attrs: { width: "10" } }),
              ],
              1
            )
          : _vm._e(),
        _vm.tableData.length == 0 && !_vm.loading
          ? _c("div", { staticClass: "wp-noData" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/noData.png"), alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }