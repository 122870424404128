var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: "剪切板",
        width: "680px",
        height: "436px",
        dialogVisible: _vm.dialogVisible,
        modalAppendToBody: false,
        modal: false,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "clipboardView" },
        [
          _c("quill-editor", {
            ref: "myQuillEditor",
            staticStyle: { height: "370px", width: "618px" },
            attrs: { options: _vm.editorOption },
            on: {
              blur: function ($event) {
                return _vm.onEditorBlur($event)
              },
              focus: function ($event) {
                return _vm.onEditorFocus($event)
              },
              change: function ($event) {
                return _vm.onEditorChange($event)
              },
            },
            model: {
              value: _vm.messagecontent,
              callback: function ($$v) {
                _vm.messagecontent = $$v
              },
              expression: "messagecontent",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }