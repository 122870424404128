<template>
  <div class="wp-card">
    <div class="tabs">
      <el-tabs v-model="activeName">
        <slot></slot>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      default: "",
    }
  },
  data () {
    return {
      activeName: "",
      list: []
    }
  },
  created () {
    this.activeName = this.activeTab || this.tabs?.[0].name
  },
  methods: {

  },
  watch: {
    activeName: {
      handler (newVal, oldVal) {
        this.$emit('handlerTabs', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>