import global from "./global"
// import utils from './utils'
let watermark = {}
/**
 * 水印
 * @param str1 文字1
 * @param str2 文字2
 * @param documentId 水印参考此DOM元素的位置和大小
 * @returns {string} 返回水印DOM的id
 */
let setWatermark = (str1, str2, documentId) => {
  let id = '1.23452384164.123412415'
  if (document.getElementById(id) !== null) {
    setTimeout(() => {
      document.getElementById(documentId).removeChild(document.getElementById(id))
    }, 10)
  }

  let can = document.createElement('canvas')
  // can.width = 250
  // can.height = 160
  can.width = 200
  can.height = 150

  let cans = can.getContext('2d')
  cans.rotate(320 * Math.PI / 180)
  cans.font = '16px Vedana'
  cans.fillStyle = 'rgba(0, 0, 0, 0.04)'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  // cans.fillText(str1 + ' ' + str2, -70, can.height - 20)

  cans.fillText(str2, -40, can.height / 2)
  cans.fillText(str1, -40, can.height / 2 - 20)


  let div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '0px'
  div.style.position = 'absolute'
  div.style.zIndex = '99999'
  div.style.width = (document.getElementById(documentId).clientWidth) + 'px';
  setTimeout(() => {
    div.style.height = document.getElementById(documentId).scrollHeight + 'px';
  }, 800)
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  document.getElementById(documentId).appendChild(div)
  return id
}

// 该方法只允许调用一次
const set = (str1, str2, documentId) => {
  if (!global.watermark) {
    return
  }
  if (document.getElementById(documentId) === null) {
    return
  }
  let id = setWatermark(str1, str2, documentId)

  let timeId = setInterval(() => {
    if (document.getElementById(documentId) === null) {
      clearInterval(timeId)
      return
    }
    if (document.getElementById(id) === null) {
      setWatermark(str1, str2, documentId)
    }
  }, 500)
  window.onresize = () => {
    if (document.getElementById(documentId) === null) {
      return
    }
    setWatermark(str1, str2, documentId)
  }
}

// 传入domId获取水印所需信息
watermark.initWatermark = async (userName, mobile, domId) => {
  // try {
  //   const userInfo = utils.getUserInfo()
  //   const res = await utils.post('/user/view', {
  //     userId: userInfo.returnData.userId
  //   })
  //   if (res.returnCode == '100') {
  //     // 添加水印
  //     set(res.returnData.userName, res.returnData.loginName, domId)
  //   }
  // } catch (err) {
  //   // console.log(err, '水印')
  //   // 添加水印
  // }
  set(userName, mobile, domId)
}

watermark.set = set
export default watermark
