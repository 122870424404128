<template>
  <wp-dialog
    v-if="dialogVisible"
    @cancel="cancel"
    :title="title"
    @determine="determine"
    width="680px"
    height="477px"
    :dialogVisible="dialogVisible"
    @handleClose="handleClose"
    :cancelText="cancelText"
    :determineText="determineText"
    :showDefaultFooter="step == 1"
    :showSlotFooter="step == 2"
  >
    <div class="createModel">
      <div v-show="step == 1" class="stepOne">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleform"
          @submit.native.prevent
        >
          <el-form-item prop="title" label="公告标题">
            <el-input
              class="wp-input"
              maxlength="50"
              v-model.trim="ruleForm.title"
              placeholder="请输入公告标题"
              clearable
              @keyup.enter.native="determine"
            ></el-input>
          </el-form-item>
          <el-form-item label="公告内容" prop="content">
            <el-input
              type="textarea"
              class="remarks"
              maxlength="500"
              placeholder="请输入公告内容"
              v-model="ruleForm.content"
              resize="none"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="上传附件" class="upload-form-item">
            <div class="upload-box">
              <UploadFile
                ref="upload"
                key="img"
                :fileList="fileList"
                @fileChange="fileChange"
                @changeUpload="changeUpload"
              ></UploadFile>
            </div>
          </el-form-item>
          <el-form-item label="弹窗强提醒:">
            <el-switch
              v-model="ruleForm.remindStatus"
              active-color="#0064E6"
              inactive-color="#CCCCCC"
              :active-value="1"
              :inactive-value="0"
              @change="changeRemindStatus"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="提醒时间:"
            required
            :error="errorMessage"
            show-message
            v-show="ruleForm.remindStatus == 1"
          >
            <div class="flex-Acenter">
              <div
                class="wp-date-picker"
                style="width: 500px; margin-right: 13px"
              >
                <el-date-picker
                  @change="changeTime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  v-model="ruleForm.remindTime"
                  size="small"
                  prefix-icon=""
                  clearable
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间"
                  :picker-options="pickerOptions"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </div>
              <div
                class="addYj"
                :class="{ active: isYj }"
                @click="changeRemindTime"
              >
                永久
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="提醒频率："
            required
            v-show="ruleForm.remindStatus == 1"
          >
            <el-radio-group v-model="ruleForm.remindNum">
              <el-radio label="-1">每次登录</el-radio>
              <el-radio label="1">仅一次</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="step == 2" class="stepTwo">
        <div class="stepTwo-box">
          <div class="stepTwo-box-left">
            <div class="box-top box-top-left">
              <p>选择组</p>
            </div>
            <div class="box-content">
              <el-scrollbar style="height: 100%">
                <div>
                  <el-tree
                    ref="tree"
                    :data="asideList"
                    show-checkbox
                    node-key="id"
                    :default-expanded-keys="expandedKeys"
                    :props="defaultProps"
                    @check="checkTree"
                  >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <div class="node-labe textOverOneLine">
                        {{ node.label }}
                      </div>
                    </span>
                  </el-tree>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <div class="stepTwo-box-right">
            <div class="box-top">
              <p>已选组</p>
              <el-button type="text" icon="el-icon-delete" @click="delAllNode"
                >清空所有</el-button
              >
            </div>
            <div class="box-content">
              <el-scrollbar style="height: 100%">
                <ul class="box-list">
                  <li v-for="item in rightList" :key="item.id">
                    <p class="textOverOneLine">{{ item.name }}</p>
                    <img
                      src="~@/assets/images/guanbi.png"
                      alt=""
                      @click="delNode(item)"
                    />
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template slot="slotFooter">
      <el-button @click.stop="cancel" class="wp-button">{{ cancelText }}
        </el-button>
        <el-button class="wp-button" type="primary" v-preventReClick  @click.stop="determine">{{ determineText }}
        </el-button>
        <el-checkbox v-model="sendMsg" style="margin-left: 25px">
            短信通知（余量：<span class="wp-blue">{{ messageNum }}</span>
            ）</el-checkbox
          >
    </template>
  </wp-dialog>
</template>

<script>
import { insert, update, getMessage, remindExists } from '@/api/messageCenter.js';
import * as personnelTeamApi from '@/api/personnelTeam.js'
import UploadFile from '@/views/messageCenter/components/uploadFile'
import taskApi from '@/api/task.js'
import reportedApi from '@/api/reported'
export default {
  name: 'createModel',
  components: { UploadFile },
  data () {
    var validateTrim = (rule, value, callback) => {
      if (value.toString().trim() === '') {
        callback(new Error('请输入公告内容'));
      } else {
        callback();
      }
    };
    return {
      expandedKeys: [],
      rightList: [],
      step: 1,// 步骤
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        title: "",
        content: "",
        fileJson: "",
        remindStatus: 0,
        remindNum: '-1',
        remindTime: [],
        remindStartTime: '',
        remindEndTime: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入公告内容', trigger: 'blur' }, { validator: validateTrim, trigger: 'blur' }
        ],
      },
      loadingTree: false,
      asideList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      newEidtObj: {},
      isUpload: false,
      fileList: [],
      name1: '0',
      isYj: false,
      errorMessage: '',
      isRemindExists: false,
      longEndTime: '9999-12-30 23:59',
      pickerOptions: {},
      messageNum:0,
      sendMsg:false
    };
  },
  props: {
    eidtObj: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {
    this.setPickerOptions()
  },
  computed: {
    determineText () {

      return this.step == 1 ? '下一步' : '发布'
    },
    cancelText () {
      return this.step == 1 ? '取消' : '上一步'
    },
    title () {
      return this.step == 1 ? (this.isEidt ? '编辑公告' : '发布公告') : '接收组'
    }
  },
  methods: {
     /** 获取短信余量 */
     async getSmsNum () {
      const res = await reportedApi.getSmsNum({})
      if (res.returnCode == 200) {
        this.messageNum = res.returnData
      }
    },
    // 选择时间不能是过去的时间
    setPickerOptions () {
      let startDate = new Date() - 24 * 60 * 60 * 1000
      this.$nextTick(() => {
        this.pickerOptions = {
          disabledDate (time) {
            return time.getTime() < startDate
          }
        }
      })
    },
    validStepOne () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (!this.otherValid()) return
          this.step = 2
          if (this.isEidt) {
            this.$nextTick(() => {
              this.setCheckedKeys([...this.newEidtObj.userIdList])
              this.getCheckedNodes()
            })
          } else {
            this.setCheckedKeys([])
            this.getCheckedNodes()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    validStepTwo () {
      if (this.rightList.length == 0) return this.$showMessage({
        type: 'error',
        msg: '请选择接收组'
      })
      this.postAdd()
    },
    cancel () {
      if (this.step == 1) {
        this.dialogVisible = false
      } else if (this.step == 2) {
        this.step = 1
        this.setPickerOptions()
      }
    },
    determine () {
      if (this.step == 1) {
        this.validStepOne()
      } else if (this.step == 2) {
        // 请选择接收组
        this.validStepTwo()
      }

    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
       // 获取短信余量
      this.getSmsNum()
      if (this.eidtObj.msgId) {
        this.isEidt = true
        this.getDetail()
      } else {
        this.getAllPeopleTree()
      }
      this.getRemindExists()
    },
    async getDetail () {
      let query = { msgId: this.eidtObj.msgId }
      try {
        let res = await getMessage(query)
        if (Number(res.returnCode) !== 200) return
        this.newEidtObj = res.returnData
        this.setInitValue()
        this.getAllPeopleTree()
      } catch (error) {
        console.log(error)
      }
    },
    setInitValue () {
      this.ruleForm.title = this.newEidtObj.title
      this.ruleForm.content = this.newEidtObj.content
      this.ruleForm.fileJson = this.newEidtObj.fileJson ? this.newEidtObj.fileJson : ''
      this.fileList = this.newEidtObj.fileJson ? JSON.parse(this.newEidtObj.fileJson) : []
      this.ruleForm.remindStatus = Number(this.newEidtObj.remindStatus)
      this.ruleForm.remindStartTime = this.newEidtObj.remindStartTime
      this.ruleForm.remindEndTime = this.newEidtObj.remindEndTime
      if (this.ruleForm.remindStatus == 1) {
        this.ruleForm.remindTime = [this.newEidtObj.remindStartTime, this.newEidtObj.remindEndTime]
        // 永久时间判断 起始时间小于等于当前时间，结束时间等于永久的结束的时间
        this.setYj(this.newEidtObj.remindStartTime, this.newEidtObj.remindEndTime)
        this.ruleForm.remindNum = this.newEidtObj.remindNum.toString()
      }
    },
    checkTree (node, data) {
      this.getRightList(data.checkedNodes)
    },
    // 取消 勾选
    delNode (item) {
      this.rightList.splice(this.rightList.findIndex(it => item.id == it.id), 1)
      this.setCheckedKeys(this.rightList.map(it => it.id))
    },
    // 获取已勾选的 节点
    getCheckedNodes () {
      console.log(this.$refs.tree.getCheckedNodes());
      this.getRightList(this.$refs.tree.getCheckedNodes())
    },
    // 生成右侧列表
    getRightList (array) {
      this.rightList = array.filter(item => !item.children || item.children.length == 0)
      console.log(this.rightList)
    },
    // 设置 勾选的节点
    setCheckedKeys (array) {
      // array = [3]
      console.log(array)
      console.log(this.$refs.tree)
      this.$refs.tree.setCheckedKeys(array);
    },
    //清空所有的节点
    delAllNode () {
      this.setCheckedKeys([])
      this.rightList = []
    },
    // 获取侧边栏列表
    async getAsideList () {
      let query = { groupName: '' }
      if (this.loadingTree) return
      this.loadingTree = true
      try {
        let res = await personnelTeamApi.listTree(query)
        this.loadingTree = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.forEach((element) => {
          element.level = 1
          if (element.sysGroupList && element.sysGroupList.length > 0) {
            // console.log(element)
            element.isleaf = false
            element.sysGroupList.forEach(it => {
              // console
              it.level = 2
              it.isleaf = true
            })
          } else {
            element.isleaf = true
          }
        });
        // console.log(res.returnData)
        this.asideList = res.returnData

      } catch (error) {
        this.loadingTree = false
        console.log(error)
      }
    },
    async postAdd () {
      let query = {
        ...this.ruleForm,
        userIdList: this.rightList.map(it => { return { id: it.id, pid: it.pid } }),
        sendMsg:this.sendMsg
      }
      delete query.remindTime
      if (this.ruleForm.remindStatu == 0) {
        // 关闭了强提醒 去掉提示时间和 频次
        delete query.remindStartTime
        delete query.remindEndTime
        delete query.remindNum
      }
      console.log(query)
      let res = null
      try {
        if (this.isEidt) {
          query.msgId = this.eidtObj.msgId
          res = await update(query)
        } else {
          res = await insert(query)
        }
        if (Number(res.returnCode) == 10086) {
          this.$showMessage({
            type: 'success',
            msg: '发布成功（短信余量不足，短信未发送）'
          })
          this.$emit('success')
           this.handleClose()
           return
        }
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
            type: 'success',
            msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        console.log(error)
      }
    },
    /** 文件上传成功回调 */
    fileChange (fileList) {
      console.log(fileList);
      this.ruleForm.fileJson = JSON.stringify(fileList)
    },
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },
    /** 获取全部人员列表 */
    async getAllPeopleTree () {
      if (this.loadingTree) return
      this.loadingTree = true
      try {
        const res = await taskApi.groupUserTree({})
        this.loadingTree = false
        if (res.returnCode == 200) {
          this.asideList = res.returnData

          // 默认展开
          this.expandedKeys = Array.of(res.returnData.find(item => item.name === '全部')?.id || '')
        }
      } catch (error) {
        console.log(error);
        this.loadingTree = false
      }
    },
    // 选择永久的时间段
    changeRemindTime () {
      this.isYj = true
      this.errorMessage = ''
      this.ruleForm.remindStartTime = this.$moment().format("YYYY-MM-DD HH:mm")
      this.ruleForm.remindEndTime = this.longEndTime
      this.ruleForm.remindTime = [this.ruleForm.remindStartTime, this.ruleForm.remindEndTime]
    },
    // 改变时间
    changeTime (val) {
      console.log(val, this.ruleForm.remindTime);
      this.ruleForm.remindStartTime = val?.[0] || ''
      this.ruleForm.remindEndTime = val?.[1] || ''
      this.isYj = false
      this.errorMessage = ''
      if (!val || val.length == 0) {
        this.ruleForm.remindStartTime = ''
        this.ruleForm.remindEndTime = ''
      } else {
        this.setYj(val[0], val[1])
      }
    },
    // 验证强提醒
    otherValid () {
      // 不是强提醒不用验证
      if (this.ruleForm.remindStatus == 0) return true
      // 是强提醒
      // 验证选择时间
      if (!this.ruleForm.remindTime || (this.ruleForm.remindTime && this.ruleForm.remindTime.length == 0)) {
        this.errorMessage = '请选择提醒时间'
        return false
      }

      let endTime = ''
      if (this.ruleForm?.remindTime?.length) {
        endTime = new Date(this.ruleForm.remindTime[1]).getTime()
      }
      // 有强提醒 且 结束时间不能选择过去的时间
      let currentTime = new Date().getTime()

      if (this.ruleForm.remindStatus == 1 && endTime < currentTime) {
        this.$showMessage({
          type: 'error',
          msg: '提醒时间的结束时间不能小于当前时间'
        })
        return false
      }

      console.log(this.ruleForm);
      if (this.ruleForm.remindStartTime === this.ruleForm.remindEndTime) {
        this.$showMessage({
          type: 'error',
          msg: '提醒时间的结束时间须大于开始时间'
        })

        return false
      }
      return true
    },
    // 验证之前是否已有强提醒
    changeRemindStatus (callback) {
      let that = this
      if (callback == 1 && this.isRemindExists) {
        // 加入已存在强提醒才会提示
        this.$wpConfirm('提示', {
          tips: "已存在强提醒弹窗，确定设置该通知为强提醒吗",
          content: "点击确定上次的强提醒通知将关闭",
          yesBtnText: '确定',
          isShowCancelBtn: true, //是否显示取消按钮
          cancelBtnText: '取消',
        }).then(() => {
          that.setPickerOptions()
        }).catch(() => {
          that.ruleForm.remindStatus = 0
        });
      } else if (callback == 1) {
        that.setPickerOptions()
      }
    },
    //判断是否存在强提醒
    async getRemindExists () {
      let query = {}
      if (this.eidtObj.msgId) { query.id = this.eidtObj.msgId }
      try {
        const res = await remindExists(query)
        if (res.returnCode != 200) return
        this.isRemindExists = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    // 设置永久按钮是否高亮
    setYj (remindStartTime, remindEndTime) {
      if (new Date(remindStartTime).getTime() <= new Date().getTime() && new Date(this.longEndTime).getTime() == new Date(remindEndTime).getTime()) {
        this.isYj = true
      }
    }

  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', ['rules'])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.createModel {
  width: 100%;
  height: 477px;
}

.stepOne {
  padding: 50px 24px 50px 24px;
}

.stepTwo {
  padding: 24px 24px 24px 24px;
  height: 100%;
  width: 100%;
  height: 100%;

  .stepTwo-box {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid #dddddd;

    /*no */
    .stepTwo-box-left {
      width: 50%;
      height: 100%;
      border-right: 1px solid #dddddd;

      /*no */
      /deep/.el-tree {
        .node-labe {
          width: 154px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          line-height: 20px;
        }
      }
    }

    .box-top {
      box-sizing: border-box;
      border-bottom: 1px solid #dddddd;
      /*no */
      height: 40px;
      padding: 0px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }

    .box-top-left {
    }

    .stepTwo-box-right {
      width: 50%;
      height: 100%;

      .box-list {
        padding: 12px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 36px;
          background: rgba(3, 109, 255, 0.03);
          border-radius: 1px;
          padding: 0px 12px;

          > p {
            width: 70%;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }

          > img {
            cursor: pointer;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .box-content {
      height: calc(100% - 40px);
    }
  }
}

/deep/.remarks.el-textarea {
  .el-textarea__inner {
    height: 110px;
  }
}

.upload-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-box {
  width: 100%;
  display: block;
}

.addYj {
  width: 76px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 34px; /*no */
  text-align: center;
  cursor: pointer;
  &.active {
    background-color: #0064e6;
    color: #fff;
    border-color: #0064e6;
  }
}
</style>