<template>
  <wp-dialog @cancel="cancel" :title="isEidt ? '编辑模型' : '创建模型'" @determine="determine" width="680px" height="430px" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="createModel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform" @submit.native.prevent>

        <el-form-item prop="url" label="链接模型：">
          <el-input class="wp-input" maxlength="2000" v-model.trim="ruleForm.url" placeholder="请输入链接模型" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item label="正则表达式：">
          <el-input class="wp-input" maxlength="1000" v-model.trim="ruleForm.regularContent" placeholder="多个中间用yunmu分隔" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item prop="taskStation" label="任务站点：">
          <el-select filterable @change="changeTaskStation" class="wp-select" style="width: 100%;" v-model="ruleForm.taskStation" clearable placeholder="请选择任务站点" @keyup.enter.native="determine">
            <el-option v-for="item in taskStationList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taskStyle" label="任务方式：">
          <el-select filterable class="wp-select" style="width: 100%;" v-model="ruleForm.taskStyle" clearable placeholder="请选择任务方式" @keyup.enter.native="determine">
            <el-option v-for="item in taskTypeList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="completeStyle" label="完成方式：">
          <el-select filterable class="wp-select" style="width: 100%;" v-model="ruleForm.completeStyle" clearable placeholder="请选择完成方式" @keyup.enter.native="determine">
            <el-option v-for="item in completeStyleList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" class="remarks" placeholder="请输入备注" v-model.trim="ruleForm.remark" maxlength="50" resize="none">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { validURL } from '@/utils/validate.js';
import { sysDictList, taskStyleList, insertSysModelConfig, updateSysModelConfig, getSysModelConfig } from '@/api/systemSettings.js';
export default {
  name: 'createModel',
  components: {},
  data () {
    var validateUrl = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入链接模型'));
      } else if (!validURL(value)) {
        callback(new Error('请输入正确的格式'));
      } else {
        callback();
      }
    };
    return {
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        url: "",
        regularContent: "",
        taskStation: "",
        taskStyle: "",
        completeStyle: "",
        remark: ""
      },
      rules: {
        url: [
          { required: true, message: '请输入链接模型', trigger: 'blur' },
          // { validator: validateUrl, trigger: 'blur' }
        ],
        taskStation: [
          { required: true, message: '选择任务站点', trigger: 'change' }
        ],
        taskStyle: [
          { required: true, message: '请选择任务方式', trigger: 'change' },
        ],
        completeStyle: [
          { required: true, message: '请选择完成方式', trigger: 'change' }
        ]
      },
      taskStationList: [],
      completeStyleList: [],
      taskTypeList: [],
      newModelObj: {},// 从接口获取的单个详情
      isPosting: false

    };
  },
  props: {
    modelObj: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.modelObj.modelConfigId) {
        this.isEidt = true
        this.getDetail()
      } else {
        this.getSysDictList('taskStation')
        this.getSysDictList('completeStyle')
      }

    },
    // 获取单个的详情
    async getDetail () {
      try {
        let query = {
          modelConfigId: this.modelObj.modelConfigId
        }
        let res = await getSysModelConfig(query)
        if (Number(res.returnCode) !== 200) return
        this.newModelObj = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },
    // 设置初始值
    setInitValue () {
      this.ruleForm.url = this.newModelObj.url
      this.ruleForm.remark = this.newModelObj.remark
      this.ruleForm.regularContent = this.newModelObj.regularContent
      this.getSysDictList('taskStation')
      this.getSysDictList('completeStyle')
    },
    // 获取任务站点 短信渠道
    async getSysDictList (dataType) {
      let query = {
        dataType: dataType
      }
      try {
        let res = await sysDictList(query)
        if (Number(res.returnCode) !== 200) return
        if (dataType == 'taskStation') {
          this.taskStationList = res.returnData.filter(item => item.dataKey != '附件' && item.dataKey != '微信视频号')
          if (this.isEidt) {
            this.$nextTick(() => {
              this.ruleForm.taskStation = this.newModelObj.taskStation
              this.changeTaskStation()
            })
          }
        } else if (dataType == 'completeStyle') {
          this.completeStyleList = res.returnData
          if (this.isEidt) {
            this.$nextTick(() => {
              this.ruleForm.completeStyle = this.newModelObj.completeStyle
            })
          }
        }

      } catch (error) {
        console.log(error)
      }
    },
    // 任务站点 改变时  获取 任务方式
    async changeTaskStation () {
      this.taskTypeList = []
      this.ruleForm.taskStyle = ''
      let query = {
        taskStationId: this.ruleForm.taskStation,
        taskType: 1
      }
      try {
        let res = await taskStyleList(query)
        if (Number(res.returnCode) !== 200) return
        this.taskTypeList = res.returnData
        if (this.isEidt) {
          this.$nextTick(() => {
            this.ruleForm.taskStyle = this.newModelObj.taskStyle
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 添加 或者编辑
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true

      let query = {
        ...this.ruleForm
      }
      let res = null
      try {
        if (this.isEidt) {
          query.modelConfigId = this.modelObj.modelConfigId
          res = await updateSysModelConfig(query)
        } else {
          res = await insertSysModelConfig(query)
        }
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },

  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', ['rules'])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.createModel {
  padding: 40px;
}

/deep/.remarks.el-textarea {
  .el-textarea__inner {
    height: 112px;
  }
}
</style>
