<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          上报数量：<span class="wp-blue">{{
            statisticsData.articleNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-button
          @click="exportFile"
          class="wp-button wp-btn-icon"
          size="medium"
          icon="icon-daochu"
          >导出</el-button
        >
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table
        :data="tableData"
        stripe
        sortable="custom"
        @sort-change="sortChange"
        :default-sort="sortObj"
        v-if="tableData.length !== 0"
      >
        <el-table-column width="20"> </el-table-column>
        <el-table-column
          align="center"
          type="index"
          label="序号"
          max-width="70"
          min-width="30"
        >
        </el-table-column>
        <el-table-column max-width="100" min-width="40"> </el-table-column>
        <el-table-column
          align="left"
          min-width="120"
          show-overflow-tooltip
          prop="userName"
          label="人员姓名"
        >
        </el-table-column>
        <el-table-column max-width="120" min-width="50"> </el-table-column>
        <el-table-column
          min-width="120"
          align="left"
          prop="groupName"
          label="所在分组"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row.parentName">
              {{ scope.row.parentName + '-' + scope.row.groupName }}
            </div>
            <div v-else>{{ scope.row.groupName }}</div>
          </template>
        </el-table-column>
        <el-table-column max-width="70" min-width="50"> </el-table-column>
        <el-table-column
          min-width="150"
          align="left"
          prop="custName"
          label="所在单位"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column max-width="65" min-width="20"> </el-table-column>
        <el-table-column
          min-width="90"
          align="center"
          sortable
          prop="workNum"
          label="上报数量"
        >
        </el-table-column>
        <el-table-column width="70"> </el-table-column>
        <el-table-column
          align="center"
          sortable
          prop="score"
          label="奖励积分"
          min-width="100"
        >
        </el-table-column>
        <el-table-column width="20"> </el-table-column>
      </el-table>
      <div class="wp-noData" v-if="tableData.length == 0">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      sortObj: {}
    }
  },
  created () {

  },
  methods: {
    // 导出
    exportFile (event) {
      this.$emit("exportFile", { event, api: 'memberStatisticsExport' })
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }

      this.$emit("sortChange", { orderField: prop, sort: order })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>