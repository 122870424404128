<template>
  <el-form
    class="form"
    :model="basicsForm"
    :rules="basicsFormRules"
    ref="basicsForm"
    label-width="100px"
  >
    <el-form-item class="item-content" label="任务分类：" prop="taskTypeId">
      <el-select
        class="wp-select task-w726"
        v-model="basicsForm.taskTypeId"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in taskClassify"
          :key="item.taskTypeId"
          :label="item.taskTypeName"
          :value="item.taskTypeId"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item class="item-content" label="任务类型：" prop="taskStyle">
      <div class="flex">
        <div
          class="type-box"
          :class="basicsForm.taskStyle == item.dataValue ? 'active-type' : ''"
          v-for="item in taskTypeList"
          :key="item.dataValue"
          @click="handlerTaskType(item)"
        >
          {{ item.dataKey }}
        </div>
      </div>
    </el-form-item>

    <el-form-item
      class="item-content"
      label="任务链接："
      prop="url"
      v-if="dictKey == '链接类'"
    >
      <el-input
        @change="changeUrl"
        disabled
        class="wp-input task-w726"
        v-model="basicsForm.url"
        placeholder="请输入任务链接"
      ></el-input>
    </el-form-item>

    <el-form-item
      v-if="dictKey == '图片视频类'"
      label="图片视频："
      prop="fileJson"
      :required="dictKey == '图片视频类'"
    >
      <UploadFile
        :noDelete="true"
        key="image"
        accept=".jpg, .jpeg, .png, .mp4"
        placeholder="请上传jpg、jpeg、png、mp4格式文件"
        :fileList="basicsForm.fileJson"
        @fileChange="fileChange"
        :fileType="['jpg', 'jpeg', 'png', 'mp4']"
      ></UploadFile>
    </el-form-item>

    <el-form-item class="item-content" label="任务标题：" prop="title">
      <el-input
        class="wp-input task-w726"
        disabled
        v-model="basicsForm.title"
        :placeholder="
          dictKey == '视频号类'
            ? '请输入任务标题（例：视频标题）'
            : '请输入任务标题'
        "
      ></el-input>
    </el-form-item>

    <el-form-item
      v-if="dictKey == '视频号类'"
      class="item-content"
      label="视频号："
      prop="author"
    >
      <el-input
        class="wp-input task-w726"
        disabled
        v-model="basicsForm.author"
        placeholder="请输入视频号"
      ></el-input>
    </el-form-item>

    <el-form-item label="任务要求：" prop="content">
      <el-input
        class="task-w726 textarea"
        type="textarea"
        maxlength="1000"
        :rows="6"
        v-model="basicsForm.content"
        placeholder="请输入任务要求和口径"
      ></el-input>
    </el-form-item>

    <QquickLanguage
      style="margin-left: 100px; margin-top: 22px; margin-bottom: 10px"
      @addQuick="addQuick"
      @clickShortcut="clickShortcut"
      @removeQuick="removeQuick"
      :shortcutList="shortcutList"
    ></QquickLanguage>

    <el-form-item
      v-if="dictKey == '视频号类'"
      label="目标图片："
      prop="fileJson"
      :rules="[{ required: false }]"
    >
      <UploadFile
        :noDelete="true"
        key="video"
        placeholder="请上传jpg、jpeg、png格式文件"
        @fileChange="fileChange"
        :fileList="basicsForm.fileJson"
        :fileType="['jpg', 'jpeg', 'png']"
        :no-delete="true"
      ></UploadFile>
    </el-form-item>
  </el-form>
</template>

<script>
import taskApi from '@/api/task.js'

import UploadFile from '@/views/components/uploadFile'
import QquickLanguage from '@/views/components/quickLanguage'
export default {
  components: { QquickLanguage, UploadFile },
  props: {
    taskTypeId: [String, Number],
    // 表单数据
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // 快捷语列表
      shortcutList: [],
      basicsForm: {
        taskStyle: "", // 任务类型
        taskTypeId: "", // 任务分类Id
        fileJson: "", // 任务类型
        title: "", // 任务标题
        content: "", // 任务要求
        author: "", // 视频号
        url: "", // 任务链接
      },
      imageUrl: "",
      basicsFormRules: {
        taskTypeId: [{ required: true, trigger: ["blur", "change"], message: "请选择任务分类" }],
        taskStyle: [{ required: true, trigger: "blur", message: "请选择任务类型" }],
        fileJson: [{ required: true, trigger: "blur", message: "请上传jpg、jpeg、png、mp4格式文件" }],
        title: [{ required: true, trigger: "blur", message: "请输入任务标题" }],
        url: [{ required: true, trigger: "blur", message: "请输入任务链接" }],
        content: [{ required: true, trigger: ["blur", "change"], message: "请填写任务要求" }],
        author: [{ required: true, trigger: "blur", message: "请输入视频号" }],
      },
      taskClassify: [], // 任务分类列表
      taskTypeList: [],
      dictKey: "", // 分类名称
    }
  },
  watch: {
    formData: {
      handler (newVal) {
        const fileJson = newVal.fileJson
        // 获取任务类型
        this.getTaskType()

        this.$nextTick(() => {
          this.basicsForm = { ...newVal }
          this.basicsForm.fileJson = fileJson && JSON.parse(fileJson) || []
          // 任务分类默认置空
          this.basicsForm.taskTypeId = ""
          this.basicsForm.taskStyle = newVal.taskStyle
        })
      },
      deep: true
    }
  },
  created () {
    // 获取任务分类
    this.getTaskClassify()
    // 获取任务类型
    this.getTaskType()
    // 获取快捷语
    this.getQuickLanguage()
  },

  methods: {
    /** 文件上传成功回调 */
    fileChange (fileList) {
      this.basicsForm.fileJson = fileList
      console.log(fileList);
    },

    // 点击快捷语
    clickShortcut (remark) {
      console.log(remark);
      this.$set(this.basicsForm, 'content', remark)
    },

    // 监听链接输入框
    async changeUrl () {
      if (this.urlFlag) {
        const res = await taskApi.getWebsite({ url: this.basicsForm.url })
        if (res.returnCode == 200) {
          const ret = res?.returnData

          // 站点名称
          this.basicsForm.websiteName = ret?.sysDict?.dataKey
          // 站点Id
          this.basicsForm.websiteId = ret?.sysDict?.dataValue
          // 任务方式列表(Vuex)
          this.$emit("getStyleList", ret?.styleList)

          if ((ret.website ?? '') != '') {
            // 任务标题
            this.$set(this.basicsForm, 'title', ret?.website?.title)
          } else {
            this.$showMessage({ type: 'warning', msg: '未获取到文章标题，请手动填写' })
          }
        }
      }
    },

    // 任务分类
    async getTaskClassify () {
      const res = await taskApi.taskTypeList({ taskTypeName: "", adminFlag: true })
      if (res.returnCode == 200) {
        this.taskClassify = res.returnData || []

        console.log(this.taskTypeId);
        if (+this.taskTypeId) {
          this.basicsForm.taskTypeId = +this.taskTypeId
        }
      }
    },

    // 获取任务类型
    async getTaskType () {
      const res = await taskApi.dictList({ dataType: "taskType" })
      if (res.returnCode == 200) {
        this.taskTypeList = res.returnData

        this.$nextTick(() => {
          this.dictKey = res.returnData.find(item => item.dataValue == this.basicsForm.taskStyle)?.dataKey
        })
        console.log(this.dictKey)
      }
    },

    // 获取快捷语列表
    async getQuickLanguage () {
      const res = await taskApi.remarkList({ adminFlag: true })
      if (res.returnCode == 200) {
        this.shortcutList = res.returnData
      }
    },

    // 添加快捷语
    async addQuick (remark) {
      const res = await taskApi.remarkInsert({ remark, adminFlag: true })
      if (res.returnCode == 200) {
        this.getQuickLanguage()
      }
    },

    // 删除快捷语
    async removeQuick (item) {
      const { taskMainRemarkId } = item
      const res = await taskApi.remarkDelete({ taskMainRemarkId })
      if (res.returnCode == 200) {
        this.getQuickLanguage()
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    // 获取表单数据
    getFormData () {
      let flag = false
      this.$refs.basicsForm.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      if (flag) {
        return JSON.parse(JSON.stringify(this.basicsForm))
      }
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.basicsForm.resetFields()
    },

    // 任务类型点击回调
    handlerTaskType (item) {
      return false
    },

    /** 获取任务方式 */
    async getTaskStyleList (item) {
      if (item.dataKey == '链接类') {
        this.$emit("getStyleList", [])
        return false
      }

      // 站点列表
      const websiteList = JSON.parse(localStorage.getItem("websiteList"))
      console.log(websiteList);
      // 站点
      let website = ''

      if (item.dataKey == '图片视频类') {
        website = websiteList.find(item => item.dataKey == '图片视频')
        this.basicsForm.websiteName = website.dataKey
        this.basicsForm.websiteId = website.dataValue
      } else if (item.dataKey == '视频号类') {
        website = websiteList.find(item => item.dataKey == "微信视频号")
        this.basicsForm.websiteName = website.dataKey
        this.basicsForm.websiteId = website.dataValue
      }

      // 获取任务方式
      const res = await taskApi.taskStyleList({ taskType: item.dataValue, taskStationId: website.dictId })
      if (res.returnCode == 200) {
        this.$emit("getStyleList", res.returnData)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>