var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wp-card", class: _vm.className }, [
    _vm.title
      ? _c("div", { staticClass: "title textOverOneLine" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
    _vm.slotValue == "row-content"
      ? _c("div", { staticClass: "row-content" }, [_vm._t("row-content")], 2)
      : _c(
          "div",
          { staticClass: "vertical-content" },
          [_vm._t("vertical-content")],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }