var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.isEidt ? "编辑" : "添加",
        width: "680px",
        height: "430px",
        dialogVisible: _vm.dialogVisible,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "createModel" },
        [
          _c("div", { staticClass: "model-top" }, [
            _c("div", { staticClass: "init-pass" }, [
              _vm._v("初始密码："),
              _c("span", [_vm._v("abc123456")]),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleform",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "人员姓名：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入人员姓名",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "loginName", label: "登录账号：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入登录账号",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.loginName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "loginName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile", label: "手机号码：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "11",
                      placeholder: "请输入手机号码",
                      clearable: "",
                    },
                    on: { input: _vm.resetNumMobile },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "mobile",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注：" } },
                [
                  _c("el-input", {
                    staticClass: "remarks",
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注",
                      maxlength: "20",
                      resize: "none",
                    },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "remark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }