var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "video-box" },
      [
        _c("el-image", {
          staticClass: "video-img",
          attrs: { fit: "cover", src: _vm.baseURL + _vm.file.picId },
          on: { click: _vm.handleClickItem },
        }),
        _c("img", {
          staticClass: "play",
          attrs: { src: require("@/assets/images/bofang.png"), alt: "播放" },
          on: { click: _vm.playVideo },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.videoVisible,
            expression: "videoVisible",
          },
        ],
        staticClass: "wp-mask",
      },
      [
        _c("img", {
          staticClass: "close",
          attrs: { src: require("@/assets/images/guanbi.png"), alt: "关闭" },
          on: { click: _vm.hideMask },
        }),
        _c("video", {
          ref: "video",
          staticClass: "video-player",
          attrs: {
            controls: "",
            width: "250",
            src: _vm.videoUrl + _vm.file.id,
            type: "video/mp4",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }