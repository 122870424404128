var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _vm.isCanAccessExcellentReview
                ? _c(
                    "TabsCard",
                    {
                      attrs: { tabs: _vm.tabs, activeTab: _vm.activeTab },
                      on: { handlerTabs: _vm.handlerTabs },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "投稿审核", name: "taskReview" } },
                        [
                          _c("SearchCard", {
                            staticClass: "peopleSearch",
                            attrs: { title: "" },
                            on: { handlerSearch: _vm.handlerSearch },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "searchContent",
                                  fn: function () {
                                    return [
                                      _c("TaskTimeSelect", {
                                        attrs: {
                                          activeType: _vm.searchParams.timeType,
                                        },
                                        on: { timeSelect: _vm.timeSelect },
                                      }),
                                      _c("wpLoadSelect", {
                                        staticClass:
                                          "search-w392 wp-select align-top",
                                        attrs: {
                                          data: _vm.taskList,
                                          page: _vm.taskObj.page,
                                          dictLabel: "title",
                                          dictValue: "taskId",
                                          dictTime: "createTime",
                                          hasMore: _vm.taskObj.more,
                                          request: _vm.getData,
                                          placeholder: "请选择任务",
                                        },
                                        on: {
                                          clearTitle: function () {
                                            return (_vm.taskObj.title = "")
                                          },
                                        },
                                        model: {
                                          value: _vm.searchParams.taskId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchParams,
                                              "taskId",
                                              $$v
                                            )
                                          },
                                          expression: "searchParams.taskId",
                                        },
                                      }),
                                      _c("el-input", {
                                        staticClass: "search-w190 wp-input",
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入人员姓名",
                                        },
                                        model: {
                                          value: _vm.searchParams.createName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchParams,
                                              "createName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "searchParams.createName",
                                        },
                                      }),
                                      _c("el-cascader", {
                                        ref: "cascaderRef",
                                        staticClass:
                                          "search-w190 wp-cascader align-top",
                                        attrs: {
                                          options: _vm.groupList,
                                          props: _vm.defaultProps,
                                          "collapse-tags": "",
                                          placeholder: "请选择所在分组",
                                          clearable: "",
                                        },
                                        on: { change: _vm.changeCascader },
                                      }),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "search-w190 wp-select",
                                          attrs: {
                                            placeholder: "请选择质量评级",
                                            "collapse-tags": "",
                                            clearable: "",
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.searchParams.stars,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchParams,
                                                "stars",
                                                $$v
                                              )
                                            },
                                            expression: "searchParams.stars",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.gradeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          _c("el-option", {
                                            attrs: {
                                              label: "未评级",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                      _c("el-input", {
                                        staticClass: "search-w190 wp-input",
                                        attrs: {
                                          placeholder: "请输入转发/评论",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchParams.content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchParams,
                                              "content",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "searchParams.content",
                                        },
                                      }),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "search-w190 wp-select",
                                          attrs: {
                                            placeholder: "请选择审核状态",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.searchParams.articleStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchParams,
                                                "articleStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchParams.articleStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.$globalData.REVIEWSTATUS,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.label,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1867664505
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("el-tab-pane", {
                        attrs: { label: "优评审核", name: "excellentReview" },
                      }),
                    ],
                    1
                  )
                : _c("SearchCard", {
                    attrs: { title: "投稿审核" },
                    on: { handlerSearch: _vm.handlerSearch },
                    scopedSlots: _vm._u([
                      {
                        key: "searchContent",
                        fn: function () {
                          return [
                            _c("TaskTimeSelect", {
                              attrs: { activeType: _vm.searchParams.timeType },
                              on: { timeSelect: _vm.timeSelect },
                            }),
                            _c("wpLoadSelect", {
                              staticClass: "search-w392 wp-select align-top",
                              attrs: {
                                data: _vm.taskList,
                                page: _vm.taskObj.page,
                                dictLabel: "title",
                                dictValue: "taskId",
                                dictTime: "createTime",
                                hasMore: _vm.taskObj.more,
                                request: _vm.getData,
                                placeholder: "请选择任务",
                              },
                              on: {
                                clearTitle: function () {
                                  return (_vm.taskObj.title = "")
                                },
                              },
                              model: {
                                value: _vm.searchParams.taskId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchParams, "taskId", $$v)
                                },
                                expression: "searchParams.taskId",
                              },
                            }),
                            _c("el-input", {
                              staticClass: "search-w190 wp-input",
                              attrs: {
                                clearable: "",
                                placeholder: "请输入人员姓名",
                              },
                              model: {
                                value: _vm.searchParams.createName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchParams,
                                    "createName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchParams.createName",
                              },
                            }),
                            _c("el-cascader", {
                              ref: "cascaderRef",
                              staticClass: "search-w190 wp-cascader align-top",
                              attrs: {
                                options: _vm.groupList,
                                props: _vm.defaultProps,
                                "collapse-tags": "",
                                placeholder: "请选择所在分组",
                                clearable: "",
                              },
                              on: { change: _vm.changeCascader },
                            }),
                            _c(
                              "el-select",
                              {
                                staticClass: "search-w190 wp-select",
                                attrs: {
                                  placeholder: "请选择质量评级",
                                  "collapse-tags": "",
                                  clearable: "",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.searchParams.stars,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParams, "stars", $$v)
                                  },
                                  expression: "searchParams.stars",
                                },
                              },
                              [
                                _vm._l(_vm.gradeList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.id,
                                    },
                                  })
                                }),
                                _c("el-option", {
                                  attrs: { label: "未评级", value: "0" },
                                }),
                              ],
                              2
                            ),
                            _c("el-input", {
                              staticClass: "search-w190 wp-input",
                              attrs: {
                                placeholder: "请输入转发/评论",
                                clearable: "",
                              },
                              model: {
                                value: _vm.searchParams.content,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchParams,
                                    "content",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchParams.content",
                              },
                            }),
                            _c(
                              "el-select",
                              {
                                staticClass: "search-w190 wp-select",
                                attrs: {
                                  placeholder: "请选择审核状态",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchParams.articleStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParams,
                                      "articleStatus",
                                      $$v
                                    )
                                  },
                                  expression: "searchParams.articleStatus",
                                },
                              },
                              _vm._l(
                                _vm.$globalData.REVIEWSTATUS,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-popover",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.multipleSelection &&
                                  _vm.multipleSelection.length,
                                expression:
                                  "multipleSelection && multipleSelection.length",
                              },
                            ],
                            attrs: {
                              placement: "bottom",
                              "visible-arrow": false,
                              width: "350px",
                              trigger: "click",
                              "popper-class": "rate-popper",
                              tabindex: 4,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "popover-content" },
                              [
                                _vm._l(_vm.gradeList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "grade-text",
                                      on: {
                                        click: function ($event) {
                                          return _vm.batchRate(item.star)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                                _vm._v(" | "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      color: "#ff5959",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchRate("0")
                                      },
                                    },
                                  },
                                  [_vm._v("清空 ")]
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "wp-button",
                                attrs: { slot: "reference", type: "primary" },
                                on: { click: _vm.clickBatchBtn },
                                slot: "reference",
                              },
                              [_vm._v("批量评级")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.multipleSelection ||
                                  _vm.multipleSelection.length == 0,
                                expression:
                                  "!multipleSelection || multipleSelection.length == 0",
                              },
                            ],
                            staticClass: "wp-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.clickBatchBtn },
                          },
                          [_vm._v("批量评级")]
                        ),
                        _c(
                          "div",
                          { staticClass: "export-box" },
                          [
                            _c(
                              "el-popover",
                              {
                                ref: "export-popover",
                                attrs: {
                                  "popper-class": "export-popover",
                                  placement: "bottom",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("div", { staticClass: "export-list" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "export-item wp-pointer",
                                      on: { click: _vm.collectExport },
                                    },
                                    [_vm._v(" 汇总导出 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "export-item wp-pointer",
                                      on: { click: _vm.workExport },
                                    },
                                    [_vm._v(" 作品导出 ")]
                                  ),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "wp-button",
                                    staticStyle: { width: "90px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "medium",
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(" 导出"),
                                    _c("i", {
                                      staticClass:
                                        "icon-zhankai el-icon--right",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 提交总量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total || 0)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.summariesData.score || 0)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              attrs: { data: _vm.tableData, stripe: "" },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "selection",
                                  width: "55",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  "max-width": "70",
                                  "min-width": "30",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "20", "min-width": "5" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "fileJson",
                                  "max-width": "150",
                                  "min-width": "100",
                                  label: "截图/附件",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(
                                                    scope.row.taskArticleId,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              JSON.parse(scope.row.fileJson)
                                                .length
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            "visible-arrow": false,
                                                            trigger: "hover",
                                                            "popper-class":
                                                              "img-popper",
                                                            tabindex: 3,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "scaled-image",
                                                            attrs: {
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                              fit: "contain",
                                                            },
                                                          }),
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "22px",
                                                              height: "22px",
                                                            },
                                                            attrs: {
                                                              slot: "reference",
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  962668117
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { width: "20", "min-width": "5" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "content",
                                  label: "转发/评论",
                                  "max-width": "220",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top",
                                                "visible-arrow": false,
                                                width: "350px",
                                                "popper-class": "rate-popper",
                                                trigger: "click",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "popover-content",
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.gradeList,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "grade-text",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.rateChange(
                                                                scope.row,
                                                                item.star
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" | "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "13px",
                                                        color: "#ff5959",
                                                      },
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.rateChange(
                                                            scope.row,
                                                            "0"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("清空 ")]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "textOverOneLine wp-pointer",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.content || "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1025258542
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "20" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "createName",
                                  "max-width": "120",
                                  "min-width": "100",
                                  "show-overflow-tooltip": "",
                                  label: "人员姓名",
                                },
                              }),
                              _c("el-table-column", { attrs: { width: "10" } }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "createTime",
                                  label: "提交时间",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "max-width": "130",
                                  "min-width": "100",
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "stars",
                                  label: "质量星级",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.stars == 0
                                            ? _c("div", [_vm._v("-")])
                                            : scope.row.stars == 3
                                            ? _c("div", [_vm._v("优秀")])
                                            : scope.row.stars == 2
                                            ? _c("div", [_vm._v("良好")])
                                            : scope.row.stars == 1
                                            ? _c("div", [_vm._v("合格")])
                                            : _c("div", [_vm._v("不合格")]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  421807606
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "articleStatus",
                                  label: "审核状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            scope.row.articleStatus == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "success-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "success-color",
                                                      },
                                                      [_vm._v("已通过")]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "noreceive-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "noreceive-color",
                                                      },
                                                      [_vm._v("已驳回")]
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1979728929
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  fixed: "right",
                                  width: "110",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "step flex-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      ref:
                                                        "popover-" +
                                                        scope.$index,
                                                      attrs: {
                                                        placement: "top",
                                                        trigger: "click",
                                                        width: "220",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: {
                                                          min: 0,
                                                          precision: 1,
                                                          max: 100,
                                                          size: "small",
                                                        },
                                                        nativeOn: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeScore(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.editRow
                                                              .totalScore,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editRow,
                                                              "totalScore",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editRow.totalScore",
                                                        },
                                                      }),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "6px",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.submitScore(
                                                                _vm.editRow,
                                                                "popover-" +
                                                                  scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("确定")]
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            slot: "reference",
                                                            "visible-arrow": false,
                                                            "popper-class":
                                                              "wp-btn-tooltip",
                                                            effect: "dark",
                                                            content: "改分",
                                                            placement: "top",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            staticClass:
                                                              "wp-btn-icon-big",
                                                            attrs: {
                                                              type: "text",
                                                              icon: "icon-bianji1",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.edit(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    "visible-arrow": false,
                                                    "popper-class":
                                                      "wp-btn-tooltip",
                                                    effect: "dark",
                                                    content: "驳回",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  scope.row.articleStatus == 1
                                                    ? _c(
                                                        "RejectCause",
                                                        {
                                                          key: scope.row
                                                            .taskArticleId,
                                                          attrs: {
                                                            popoverType: "add",
                                                          },
                                                          on: {
                                                            handlerReject:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handlerReject(
                                                                  $event,
                                                                  scope.row
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-bohui wp-blue wp-pointer fs-20",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              scope.row.articleStatus !== 1
                                                ? _c(
                                                    "RejectCause",
                                                    {
                                                      attrs: {
                                                        popoverType: "detail",
                                                        rejectObj:
                                                          _vm.rejectObj,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-bohui wp-pointer fs-20",
                                                        staticStyle: {
                                                          color: "#ccc",
                                                        },
                                                        on: {
                                                          mouseenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.getReject(
                                                              scope.row
                                                                .taskArticleId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1259021746
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { ref: "hideDiv" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }