var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-view" }, [
    _c("div", { staticClass: "header-left flex-Acenter" }, [
      _vm.isMls
        ? _c("img", {
            attrs: { src: require("@/assets/images/mls/mlslogo.png"), alt: "" },
          })
        : !_vm.isMls
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/header/logo@2x.png"),
              alt: "",
            },
          })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "header-right" }, [
      _c("div", { staticClass: "admin-info" }, [
        _c("div", { staticClass: "name-info" }, [
          _vm._v(" " + _vm._s(_vm.userName) + " "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }