<template>
  <div :style="{ height: height }" class="wp-tree">
    <el-scrollbar style="height: 100%">
      <el-tree class="tree" :data="peopleList" show-checkbox :default-expand-all="false" node-key="id" :expand-on-click-node="false" :ref="treeRef" highlight-current :default-expanded-keys="defaultExpanded" :props="defaultProps" :filter-node-method="filterNode" @check="checkTree">
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span :class="
              node.level == 1
                ? 'title'
                : node.level == 2
                ? 'two-title'
                : node.level == 3
                ? 'three-title'
                : 'people-name'
            " class="textOverOneLine people-name">{{ data.name }}</span>

        </div>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '480px'
    },

    // 默认展开分组
    defaultExpanded: {
      type: Array,
      default: () => []
    },

    treeRef: {
      type: String,
      default: 'peopleRef'
    },

    // 人员列表
    peopleList: {
      type: Array,
      default: () => []
    },
    // 判断是否为常用人员列表
    type: {
      type: String,
      default: ''
    },
    // 禁止选中列表
    disabledList: {
      type: Array,
      default: () => []
    },
    searchContent: String, // 搜索内容
  },
  data () {
    return {
      stockList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    };
  },
  created () {
    console.log(this.defaultExpanded);
  },
  methods: {
    // 设置 勾选的节点
    setCheckedKeys (array) {
      this.$refs[this.treeRef].setCheckedKeys(array);
    },
    // 勾选树的事件
    checkTree (node, data) {
      console.log(node)
      this.getRightList(data.checkedNodes)
    },
    // 生成右侧列表
    getRightList (array) {
      let rightList = array.filter(item => !item.group)
      this.$emit("handleCheck", rightList)
    },
    //
    /** 查询 */
    filterNode (value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /** 获取选中节点 */
    getCurrentNode () {
      const peopleList = []
      const peopleData = this.$refs.peopleRef.getCheckedNodes()
      this.handlerPeopleList(peopleData, peopleList)
      return peopleList
    },
    // 清空
    resetChecked () {
      this.$refs[this.treeRef].setCheckedKeys([]);
    },
    /** 取出所有选中人员信息 */
    handlerPeopleList (arr, list) {
      arr.forEach(item => {
        if (!arr.group) {
          list.push(item)
        }
      })
    },

    /** 取当前组下人员Id */
    handlerChilren (list, arr) {
      list?.children.forEach(item => {
        if (item.group && item.children) {
          this.handlerChilren(item, arr)
        } else {
          arr.push(item.id)
        }
      })
    },
  },
  watch: {
    // 搜索内容
    searchContent: {
      handler (newVal, oldVal) {
        this.$refs[this.treeRef].filter(newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>