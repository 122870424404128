/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername (str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL (url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validTaskURL (url) {
  const reg = /^(https?|ftp):\/\//
  return reg.test(url)
}


/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail (email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString (str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray (arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

//验证电话号码

export function isPhone (str) {
  // 电话
  let phoneReg = /^[1][0,1,2,3,4,5,6,7,8,9][0-9]{9}$/
  if (str.toString().trim() === '') {
    return false
  }
  return phoneReg.test(str)
}
//验证英文和数字
export function isEnAndNum (str) {
  var numReg = /^[0-9a-zA-Z]+$/
  // let numReg = /[a-zA-Z0-9]/
  if (str.trim() === '') {
    return false
  }
  return numReg.test(str)
}


//验证=英文和数字和下划线
export function isEnAndNumAndUnderline (str) {
  var numReg = /^[0-9a-zA-Z_]+$/
  if (str.toString().trim() === '') {
    return false
  }
  return numReg.test(str)
}

// 限制输入框只能输入数字

export function resetNumber (str) { 
  let resetStr = str.toString().trim()
  resetStr = resetStr.replace(/[^\d]/g, '').replace(/\./g, '');
  let resetNum = resetStr
  return resetNum
}

// 限制输入框 最多输入{decimalPoints}个小数点
export function resetNumberDecimalPoints (str,decimalPoints) {
  console.log('str,decimalPoints',str,decimalPoints)
  let resetStr = str.toString().trim()
  resetStr = resetStr.replace(/[^\d+.]/g, '');
  function getDecimalPlaces(num) {  
    // 将数字转换为字符串  
    const numStr = num.toString();  
    // 查找小数点的位置  
    const decimalIndex = numStr.indexOf('.');  
    // 如果没有小数点，则小数位数为0  
    if (decimalIndex === -1) {  
      return 0;  
    }  
    // 计算小数部分的长度  
    const decimalPart = numStr.slice(decimalIndex + 1);  
    return decimalPart.length;  
  }  
  const decimalNumber = getDecimalPlaces(resetStr)
  if( decimalNumber == 0 || decimalNumber<= decimalPoints){
    return resetStr
  }
  // 保留指定位数的小数 且不进行四舍五入
  let multiple = [1]
  for(let i=0;i<decimalPoints;i++){
    multiple.push(0)
  }
  let strTemp =  parseFloat(resetStr) * Number(multiple.join('')) 
  return Math.floor(strTemp) / Number(multiple.join('')) ;  
}

// 限制输入框只能输入大于等于0的整数  返回处理后的数值

export function resetNum (str) {
  let resetStr = str.toString().trim()
  if (resetStr.length == 0) {
    return resetStr
  }
  resetStr = resetStr.replace(/[^\d]/g, '').replace(/\./g, '');
  let resetNum = Number(resetStr)
  return resetNum
}
// 限制输入框只能输入正整数
export function resetNumInt (str) {
  let resetStr = str.toString().trim()
  if (resetStr.length == 0) {
    return 1
  }
  resetStr = resetStr.replace(/[^\d]/g, '').replace(/\./g, '');
  let resetNum = parseInt(resetStr)
  if (resetNum == 0) resetNum = 1
  return resetNum
}
// 限制输入框只能输入0和正整数
export function resetNumzero (str) {
  let resetStr = str.toString().trim()
  if (resetStr.length == 0) {
    return 0
  }
  resetStr = resetStr.replace(/[^\d]/g, '').replace(/\./g, '');
  let resetNum = parseInt(resetStr)
  return resetNum
}


// 限制输入框 只能输入数字 小数点保留一位 <100
export function resetNumPointOne (str, min, max, initType = false) {
  if (+str >= +max) {
    return max
  }
  if (+str <= +min) {
    return min
  }
  let resetStr = str.toString().trim()
  if (resetStr.length == 0) {
    return min
  }
  let resetNumP = 0
  let resetNum = 0
  if (initType) {
    resetNum = parseInt(resetStr.replace(/[^\d]/g, '').replace(/\./g, ''))
  } else {
    resetNumP = parseInt(parseFloat(resetStr.replace(/[^\d.]/g, '')) * 10)
    resetNum = resetNumP / 10
    console.log(resetNum, max);
  }

  console.log(resetNum);
  if (isNaN(resetNum)) resetNum = min
  if (resetNum <= min) resetNum = min
  if (resetNum >= max) resetNum = max
  return resetNum
}

// 密码规则 let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,20}$/  
//请输入5-20位字母数字组合（区分大小写）
export function verifyPassword (str) {
  let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,20}$/
  let passStr = str.toString()
  return reg.test(passStr)
}
export const passwordLang = '请输入5-20位字母数字组合（区分大小写）'




//验证英文
export function isEn (str) {
  var numReg = /^[a-zA-Z]+$/
  if (str.trim() === '') {
    return false
  }
  return numReg.test(str)
}


export function deepClone (origin) {
  var isObject = any => typeof any == 'object' && any != null
  var isArray = any => Object.prototype.toString.call(any) === '[object Array]'
  if (!isObject(origin)) return origin
  var target = isArray(origin) ? [] : {}
  for (var prop in origin) {
    if (origin.hasOwnProperty(prop)) {
      var value = origin[prop]
      if (isObject(value)) {
        target[prop] = deepClone(value)
      } else {
        target[prop] = value
      }
    }
  }
  return target
}