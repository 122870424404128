<template>
  <div style="width: 100%">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="wpyUnitTabs.length > 1">
        <div class="title-header-left">
          <UnitTab
            :tabsList="wpyUnitTabs"
            :activeId="activeName"
            @handleClick="handleClick"
          ></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="我的上报" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <TaskTimeSelect
              ref="taskTimeRef"
              @timeSelect="timeSelect"
              :activeType="searchParams.timeType"
              :taskType="timeType"
            ></TaskTimeSelect>

            <el-input
              class="search-w392 wp-input"
              v-model="searchParams.title"
              placeholder="请输入专题名称"
              clearable
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.articleStatus"
              clearable
              placeholder="请选择审核状态"
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                上报总量：<span class="wp-blue">{{ total }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{ totalScore }}</span>
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="topicTitle"
                min-width="190"
                show-overflow-tooltip
                label="专题名称"
              >
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                min-width="120"
                v-if="isWpys"
                align="left"
                prop="custName"
                label="来源单位"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                min-width="120"
                align="left"
                prop="title"
                label="作品标题"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="goDetail(scope.row)"
                    style="cursor: pointer"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.title"
                      placement="top"
                      :disabled="!isShowTooltipWidth"
                      popper-class="wp-visibilityPopper"
                    >
                      <p
                        class="textOverOneLine"
                        @mouseenter="visibilityChangeWidth($event)"
                      >
                        {{ scope.row.title ? scope.row.title : '-' }}
                      </p>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="120"
                align="left"
                prop="url"
                label="作品链接"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="goDetail(scope.row)"
                    style="cursor: pointer"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.title"
                      placement="top"
                      :disabled="!isShowTooltipWidth"
                      popper-class="wp-visibilityPopper"
                    >
                      <p
                        class="textOverOneLine"
                        @mouseenter="visibilityChangeWidth($event)"
                      >
                        {{ scope.row.url ? scope.row.url : '-' }}
                      </p>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="截图/附件" min-width="100">
                <template slot-scope="scope">
                  <div @click="goDetail(scope.row)">
                    <p
                      v-if="
                        !scope.row.fileJson ||
                        JSON.parse(scope.row.fileJson).length == 0
                      "
                    >
                      -
                    </p>
                    <p
                      v-else-if="$utils.isImg(scope.row.fileJson)"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>

                    <img
                      v-else
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column max-width="50" min-width="10"> </el-table-column>
              <el-table-column
                min-width="110"
                show-overflow-tooltip
                align="left"
                prop="createName"
                label="人员姓名"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                align="center"
                prop="createTime"
                label="上报时间"
              >
                <template slot-scope="scope">
                  {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="80"
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.totalScore == '-999'
                      ? '按平台'
                      : scope.row.totalScore
                  }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="80"
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.articleStatus == 2">
                    <div class="noreceive-circle"></div>
                    <RejectCause
                      popoverType="detail"
                      :rejectObj="rejectObj"
                      :key="scope.row.topicArticleId"
                    >
                      <div
                        class="reject wp-point设置驳回信息er"
                        @mouseenter="setReject(scope.row)"
                      >
                        已驳回
                      </div>
                    </RejectCause>
                  </div>
                  <div v-else class="flex-center">
                    <div class="pass-circle"></div>
                    <div class="pass">已通过</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <el-button
                      @click="editWork(scope.row)"
                      :class="scope.row.topicStatus == 2 ? 'no-drop' : ''"
                      type="text"
                      icon="icon-bianji1"
                      class="wp-btn-icon-big"
                    >
                    </el-button>
                    <el-button
                      @click="removeWork(scope.row)"
                      :class="
                        scope.row.topicStatus == 2 ||
                        scope.row.articleStatus == 2
                          ? 'no-drop'
                          : ''
                      "
                      type="text"
                      icon="icon-shanchu"
                      class="wp-btn-icon-big"
                    >
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <task-dialog
      v-if="workVisible"
      title="编辑"
      width="680px"
      height="100%"
      maxHeight="530px"
      @cancel="cancel"
      @determine="submitEditForm"
      :dialogVisible.sync="workVisible"
      @handleClose="cancel"
    >
      <div class="word-dialog">
        <div class="reject-box" v-if="articleData.articleStatus == 2">
          <div class="fs-16 fw-bold">驳回原因：</div>
          <div class="reject-content fs-14">
            {{ verify.resonRemark }}
          </div>
        </div>

        <div class="wp-form">
          <el-form
            class="wp-form"
            :model="taskForm"
            :rules="taskFormRules"
            ref="workRef"
            label-width="95px"
          >
            <el-form-item
              class="item-content"
              label="作品标题："
              prop="title"
              v-if="setting.parmTitleShow"
              :rules="[
                {
                  required: setting.parmTitleMust,
                  trigger: 'blur',
                  message: '请输入作品标题',
                },
              ]"
            >
              <el-input
                class="wp-input w480"
                v-model.trim="taskForm.title"
                maxlength="100"
                placeholder="请输入作品标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmUrlShow"
              class="item-content isUrl"
              label="作品链接："
              prop="url"
              :rules="[
                {
                  required: setting.parmUrlMust && !urlDisabled,
                  trigger: 'blur',
                  message: '请输入作品链接',
                },
              ]"
            >
              <el-input
                @blur="changeUrl"
                maxlength="2000"
                class="wp-input w480"
                v-model.trim="taskForm.url"
                placeholder="请输入作品链接"
                :disabled="urlDisabled"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="作品内容："
              prop="content"
              v-if="setting.parmContentShow"
              :rules="[
                {
                  required: setting.parmContentMust,
                  trigger: 'blur',
                  message: '请输入作品内容',
                },
              ]"
            >
              <el-input
                class="w480 textarea"
                type="textarea"
                maxlength="1000"
                :rows="6"
                v-model.trim="taskForm.content"
                placeholder="请输入作品内容"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="媒体平台："
              v-if="setting.mediaShow && mediaList.length"
              prop="media"
              :rules="[
                {
                  required: setting.mediaMust,
                  trigger: 'change',
                  message: '请选择媒体平台',
                },
              ]"
            >
              <el-select
                @change="mediaChange"
                class="w480 wp-select"
                v-model="taskForm.media"
                filterable
                placeholder="请选择媒体平台"
              >
                <el-option
                  v-for="item in mediaList"
                  :key="item.custWebsiteId"
                  :label="item.name"
                  :value="item.custWebsiteId"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="setting.mediaShow && !mediaList.length"
              class="item-content"
              label="媒体平台："
              prop="media"
              :rules="[
                {
                  required: setting.mediaMust,
                  trigger: 'blur',
                  message: '请输入媒体平台',
                },
              ]"
            >
              <el-input
                maxlength="30"
                class="wp-input w480"
                v-model.trim="taskForm.media"
                placeholder="请输入媒体平台"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="发布类型："
              prop="publishType"
              v-if="setting.publishShow"
              :rules="[
                {
                  required: setting.publishMust,
                  trigger: 'change',
                  message: '请选择发布类型',
                },
              ]"
            >
              <el-select
                @change="changePublishType"
                class="w480 wp-select"
                v-model="taskForm.publishType"
                placeholder="请选择发布类型"
              >
                <el-option
                  v-for="item in publishList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="作者类型："
              prop="authorType"
              v-if="setting.authorTypeShow"
              :rules="[
                {
                  required: setting.authorTypeMust,
                  trigger: 'change',
                  message: '请选择作者类型',
                },
              ]"
            >
              <el-select
                class="w480 wp-select"
                v-model="taskForm.authorType"
                placeholder="请选择作者类型"
              >
                <el-option
                  v-for="item in authTypeList"
                  :key="item.dictId"
                  :label="item.dataKey"
                  :value="item.dataKey"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-row class="w580">
              <el-col :span="12">
                <el-form-item
                  label="作者姓名："
                  prop="author"
                  v-if="setting.authorShow"
                  :rules="[
                    {
                      required: setting.authorMust,
                      trigger: 'change',
                      message: '请输入作者姓名',
                    },
                  ]"
                >
                  <el-input
                    maxlength="30"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.author"
                    placeholder="请输入作者姓名"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="网名/昵称："
                  prop="netName"
                  v-if="setting.netNameShow"
                  :rules="[
                    {
                      required: setting.netNameMust,
                      trigger: 'change',
                      message: '请输入网名昵称',
                    },
                  ]"
                >
                  <el-input
                    maxlength="30"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.netName"
                    placeholder="请输入网名昵称"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row class="w580">
              <el-col :span="12">
                <el-form-item
                  label="阅读数量："
                  prop="readNum"
                  v-if="setting.readNumShow"
                  :rules="[
                    {
                      required: setting.readNumMust,
                      trigger: 'change',
                      message: '请输入阅读数量',
                    },
                  ]"
                >
                  <el-input
                    @input="resetReadNum"
                    maxlength="8"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.readNum"
                    placeholder="请输入阅读数量"
                  >
                    <i slot="suffix" class="unit">万</i>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="转载数量："
                  prop="reprint"
                  v-if="setting.reprintShow"
                  :rules="[
                    {
                      required: setting.reprintMust,
                      trigger: 'change',
                      message: '请输入转载数量',
                    },
                  ]"
                >
                  <el-input
                    @input="resetReprint"
                    @blur="reprintBlur"
                    maxlength="8"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.reprint"
                    placeholder="请输入转载数量"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              label="全网推送："
              v-if="setting.parmPushShow"
              :rules="[
                {
                  required: setting.parmPushMust,
                  trigger: 'change',
                  message: '请选择全网推送',
                },
              ]"
            >
              <el-radio-group
                v-model="taskForm.parmPush"
                @change="changeParmPush"
              >
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="setting.parmAttachShow"
              :rules="[
                {
                  required: setting.parmAttachMust,
                  trigger: 'blur',
                  message: '请提交截图/附件',
                },
              ]"
              label="截图/附件："
              prop="fileJson"
            >
              <UploadFile
                key="image"
                :fileList="taskForm.fileJson"
                @fileChange="fileChange"
                @changeUpload="changeUpload"
              ></UploadFile>
            </el-form-item>

            <el-form-item label="奖励积分：" required>
              <div>
                <span
                  >基础分:<span style="color: #faa548">{{
                    taskForm.baseScore
                  }}</span></span
                >
                <span v-if="rewardScore > 0"
                  >+ 转载分:<span style="color: #faa548">{{
                    rewardScore > 0 ? '+' + rewardScore : ''
                  }}</span></span
                >
                <span v-if="wpTopicPushScore > 0 && taskForm.parmPush == '1'"
                  >+ 推送分:<span style="color: #faa548">{{
                    wpTopicPushScore
                  }}</span></span
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </task-dialog>
  </div>
</template> 

<script>
import { validTaskURL, resetNum, resetNumberDecimalPoints } from '@/utils/validate.js';
import wpyReportedApi from '@/api/wpyReported.js'
import { mapGetters } from 'vuex';
import UploadFile from '@/views/components/reportedUpload'
import UnitTab from '../components/unitTab/unitTab.vue';
import RejectCause from '@/views/components/rejectCause'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { SearchCard, TaskTimeSelect, UnitTab, RejectCause, UploadFile },
  data () {
    return {
      isUpload: false,
      activeName: '',
      searchParams: {
        articleStatus: "", // 审核状态
        title: "", // 专题名称
        startTime: "", // 专题开始时间
        endTime: "",// 专题结束时间
        custId: "", // 单位Id
        timeType: 2,// 1作品时间 2专题时间
        page: 1,
        limit: 30
      },
      loading: false,
      publishList: [], // 发布类型
      authTypeList: [], // 作者类别
      mediaList: [], // 媒体平台
      timeType: [
        {
          name: "专题创建时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        }
      ],
      statusList: [],
      tableData: [],
      workVisible: false,
      total: 0, // 总条数
      totalScore: 0, // 总奖励积分
      taskForm: {
        taskName: '', // 任务名称
        title: '', // 任务标题
        url: '', // 作品链接
        fileJson: "", // 附件
        content: '', // 作品内容
        publishType: "", // 发布类型
        score: 0, // 分数
        parmPush: '0',// 全网推送
        baseScore: 0,
        readNum: '',// 全网推送
        reprint: 0,
        netName: '',
        author: "",
        authorType: '',
        media: ''
      },
      taskFormRules: {
        topicName: '', // 专题名称
        title: { required: true, trigger: 'blur', message: '请输入作品标题' },
        url: { required: true, trigger: 'blur', message: '请输入作品链接' },
        fileJson: { required: true, trigger: 'blur', message: '请提交文件' },
        content: { required: true, trigger: 'blur', message: '请输入作品内容' },
      },
      articleData: {}, // 作品信息
      setting: {}, // 高级设置
      rejectObj: {}, // 驳回原因
      // totalScore: "", // 表格总奖励积分
      verify: {},
      verifyUrl: true,
      publishTypeName: "", // 发布类型名称
      mediaName: "", // 媒体名称
      rewardScore: 0,
      rewardList: [],
      isShowTooltipWidth: false,
      wpTopicPushScore: 0,//基础分 // 推送分
      isPosting: false,
      mediaType: '',
      urlDisabled: false,
      articleId: ''
    }
  },
  mounted () {

    this.activeName = this.wpyUnitTabs[0].custId
    this.searchParams.custId = this.activeName
    // 获取我的上报
    this.getTopicArticleList()
  },

  computed: {
    ...mapGetters(['isWpys', 'wpyUnitTabs', 'sysCust']),
    // 是否赣州
    isGZ () {
      return this.sysCust.custId == 2
    }
  },
  watch: {
    /** 监听媒体平台 */
    'taskForm.media': {
      handler (newVal) {
        if (newVal && this.mediaList.length) {
          const custWebsite = this.mediaList.find(it => it.custWebsiteId == newVal)
          this.mediaType = custWebsite.mediaType || ''
        }

      },
    }
  },

  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },

    /** 跳转专题详情 */
    goDetail (item) {
      console.log(item);
      this.$router.push({
        path: '/articleDetail',
        query: {
          articleId: item.topicArticleId,
        }
      })
    },

    // 阅读数量
    resetReadNum (e) {
      this.taskForm.readNum = resetNumberDecimalPoints(e, 4)
    },

    // 转发数量
    resetReprint (e) {
      this.taskForm.reprint = resetNum(e)
    },

    // 监听链接输入框
    async changeUrl () {
      const index = await this.$refs['workRef'].fields.findIndex(item =>
        item?.$el?._prevClass?.indexOf("isUrl") !== -1
      )

      if (this.taskForm.url === '' && this.setting.parmUrlMust) {
        this.verifyUrl = await false
        this.$refs['workRef'].fields[index].validateMessage = await '请输入作品链接'
        this.$refs['workRef'].fields[index].validateState = await 'error'
      } else if (!validTaskURL(this.taskForm.url) && this.taskForm.url) {
        this.verifyUrl = await false
        this.$refs['workRef'].fields[index].validateMessage = await '请输入有效作品链接'
        this.$refs['workRef'].fields[index].validateState = await 'error'
      } else {
        this.verifyUrl = await true
        this.$refs['workRef'].fields[index].validateState = await 'success'

        console.log(this.taskForm);
        // 如果是按平台的任务,则获取平台信息
        if (this.taskForm.score == -999) {
          this.getPlatform(this.taskForm.url)
        }
        this.verifyDuplicateLinks()
      }
      console.log(this.verifyUrl);
    },

    /** 根据链接获取平台信息 */
    async getPlatform (url) {
      const res = await wpyReportedApi.urlToPlatform({ url, topicId: this.taskForm.topicId })
      if (res.returnCode == 200) {
        const id = res.returnData?.custWebsiteId
        this.mediaName = res.returnData?.name
        this.$set(this.taskForm, 'media', id)
        this.$set(this.taskForm, 'publishType', '')
        this.getPublishTypeList(id, 'edit')
      }
    },

    // 设置驳回信息
    setReject (item) {
      const obj = {
        createName: item.verityName,
        resonRemark: item.verityRemark,
        createTime: item.verityTime,
      }
      this.rejectObj = { ...obj }
    },

    /** 文件上传成功回调 */
    fileChange (fileList) {
      this.taskForm.fileJson = fileList
    },

    /** 初始化搜索条件 */
    initParams () {
      // 置空时间筛选框
      this.$refs.taskTimeRef.clearTime()
      // 恢复初始值
      this.searchParams.articleStatus = ""
      this.searchParams.title = ""
      this.searchParams.startTime = ""
      this.searchParams.endTime = ""
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.searchParams.timeSection = ""
      this.$set(this.searchParams, 'timeType', 2)
    },

    // 时间类型选择回调
    timeSelect (data) {
      delete data.timeSection
      this.searchParams = { ...this.searchParams, ...data }
    },

    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      // 获取作品列表
      this.getTopicArticleList()
    },

    /** 提交编辑表单 */
    submitEditForm () {

      if (this.verifyUrl) {
        if (this.isUpload) {
          this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
        } else {
          this.$refs.workRef.validate(async valid => {
            if (valid) {
              const query = { ...this.taskForm }
              query.media = this.mediaName || query.media
              query.publishType = this.publishTypeName || query.publishType
              query.topicArticleId = this.articleData.topicArticleId
              query.rewardScore = this.rewardScore
              query.pushScore = this.taskForm.parmPush == '1' ? this.wpTopicPushScore : 0
              if (query?.fileJson?.length) {
                query.fileJson = JSON.stringify(query.fileJson)
              }
              // const isNoSimila=  await this.verifyAuthorAndTitle()
              // if(!isNoSimila) return
              if (this.isPosting) return
              this.isPosting = true
              const res = await wpyReportedApi.updateTopicArticle(query)
              this.isPosting = false
              if (res.returnCode == 200) {
                this.cancel()
                // this.workVisible = false
                // this.$refs.workRef.resetFields()
                this.$showMessage({ type: 'success', msg: res.returnMsg })
                this.getTopicArticleList()
              } else {
                this.$showMessage({ type: 'error', msg: res.returnMsg })
              }
            } else {
              return false
            }
          })
        }
      }
    },

    /** tab点击回调 */
    handleClick (tab) {
      this.initParams()
      this.searchParams.custId = tab
      // 获取作品列表
      this.getTopicArticleList()
    },

    /** 我的上报 */
    async getTopicArticleList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyReportedApi.getTopicArticleList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
          this.totalScore = res.reportTotal.SCORE
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTopicArticleList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTopicArticleList()
    },

    /** 关闭弹窗回调 */
    cancel () {
      this.articleData = {}
      this.taskForm = {
        taskName: '', // 任务名称
        title: '', // 任务标题
        url: '', // 作品链接
        fileJson: "", // 附件
        content: '', // 作品内容
        publishType: "", // 发布类型
        score: 0, // 分数
        parmPush: '0',// 全网推送
        baseScore: 0,
        readNum: '',// 阅读数量
        reprint: 0,
        netName: '',
        author: "",
        authorType: '',
        media: ''
      }
      this.articleId = ''
      this.verifyUrl = true
      this.isUpload = false
      this.workVisible = false

      this.$refs.workRef.resetFields()
      this.$refs?.upload?.resetFile()
      this.rewardScore = 0
      this.mediaList = []
      this.publishList = []
      this.authTypeList = []
    },

    /** 编辑作品 */
    editWork (item) {
      if (item.topicStatus !== 2) {
        this.workVisible = true
        this.getWorkDetail(item.topicArticleId)
      }
    },

    /** 获取作品详情 */
    async getWorkDetail (articleId) {
      this.articleId = articleId
      const res = await wpyReportedApi.getTopicArticle({ articleId })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const articleData = ret.wpTopicArticle || {}
        // 作品信息
        this.articleData = { ...articleData }

        // 是否按平台
        this.articleData.score = ret?.wpTopicMain?.score
        // 任务信息
        this.taskData = ret.wpTopicMain || {}
        // 驳回信息
        this.verify = ret.verify || {}
        // 分数回显
        this.articleData.baseScore = this.articleData?.baseScore || 0




        // this.taskForm = { ...this.articleData }
        this.taskForm.taskName = this.articleData?.taskName
        this.taskForm.title = this.articleData?.title
        this.taskForm.url = this.articleData?.url
        this.taskForm.fileJson = this.articleData?.fileJson
        this.taskForm.content = this.articleData?.content
        this.taskForm.publishType = this.articleData?.publishType
        this.taskForm.score = this.articleData?.score
        this.taskForm.parmPush = this.articleData?.parmPush
        this.taskForm.baseScore = this.articleData?.baseScore
        this.taskForm.readNum = this.articleData?.readNum
        this.taskForm.reprint = this.articleData?.reprint
        this.taskForm.netName = this.articleData?.netName
        this.taskForm.author = this.articleData?.author
        this.taskForm.authorType = this.articleData?.authorType
        this.taskForm.media = this.articleData?.media

        // 任务方式
        this.taskForm.modeStyle = [this.taskForm.modeStyle]

        // 附件
        this.taskForm.fileJson = JSON.parse(this.taskForm?.fileJson) || []

        // 初始化 基础分，转载分，推送分
        // 基础分不按平台就是文章的基础分
        this.taskForm.baseScore = this.articleData?.baseScore
        if (this.articleData.score == -999) {
          this.rewardScore = this.articleData.rewardScore
          this.wpTopicPushScore = this.articleData?.pushScore ?? 0
        }

        // 高级设置
        this.setSetting(this.taskData)
        this.getReward()
      }
    },

    // 选择发布类型回调
    changePublishType (value) {
      console.log('changePublishType', value);
      const item = this.publishList.find(item => item.id == value)
      console.log('item', item);
      this.publishTypeName = item?.typeName || ""
      if (this.taskData.score == -999) {
        // 按平台
        this.taskForm.baseScore = item?.score || 0
      } else {
        // 不按平台 又是编辑的 取 文章的基础分
        this.taskForm.baseScore = this.articleData?.baseScore || 0
      }
      this.setReprint()
    },
    // 媒体平台选中回调
    mediaChange (id) {
      const custWebsite = this.mediaList.find(it => it.custWebsiteId == id)
      // this.mediaType = custWebsite.mediaType || ''
      this.mediaName = custWebsite?.name
      // 获取发布类型
      this.getPublishTypeList(id)
      this.changeMedia()
    },

    // 获取媒体平台列表
    async getMediaList (type) {
      console.log(this.articleData);
      const res = await wpyReportedApi.getWebsiteList({ custId: this.articleData.custId })
      if (res.returnCode == 200) {
        this.mediaList = res?.returnData || []
        console.log('获取媒体平台列表type', type)
        if (this.articleData?.media && type == 'edit' && this.mediaList.length) {
          this.mediaName = this.articleData?.media
          const item = this.mediaList.find(item => item.name == this.articleData.media)
          this.getPublishTypeList(item.custWebsiteId, type)
          this.$set(this.taskForm, 'media', item.custWebsiteId)
          this.changeMedia()
        } else {
          this.getPublishTypeList('', type)
        }
      }
    },

    // 发布类型
    async getPublishTypeList (custWebsiteId, type) {
      console.log('getPublishTypeList', custWebsiteId, type);
      const res = await wpyReportedApi.getPublishTypeList({ custWebsiteId })
      if (res.returnCode == 200) {
        this.publishList = res.returnData

        console.log(type);
        if (type == 'edit') {
          this.publishTypeName = this.taskForm.publishType
          const item = res.returnData.find(item => item.typeName == this.taskForm.publishType)
          this.$set(this.taskForm, 'publishType', item.id)
        } else {
          // 媒体平台选中 后 触发到这里
          const item = res.returnData.find(item => item.defaultValue)
          if (item.id) {
            this.publishTypeName = item.typeName
            this.taskForm.baseScore = item.score
            this.$set(this.taskForm, 'publishType', item.id)
          } else {
            this.publishTypeName = ""
            this.taskForm.baseScore = 0
            this.$set(this.taskForm, 'publishType', "")
          }
          this.setReprint()
        }
      }
    },

    // 作者类别
    async getAuthType () {
      const res = await wpyReportedApi.topicAuthorTypeList({})
      if (res.returnCode == 200) {
        this.authTypeList = res?.returnData || []
      }
    },

    // 设置作品提交的高级设置
    setSetting (task) {
      // 显示标题
      this.setting.parmTitleShow = task.parmTitle.charAt(0) == '1';
      // 标题必填
      this.setting.parmTitleMust = task.parmTitle.charAt(1) == '1';

      // 显示链接
      this.setting.parmUrlShow = task.parmUrl.charAt(0) == '1';
      // 链接必填
      this.setting.parmUrlMust = task.parmUrl.charAt(1) == '1';

      // 显示内容
      this.setting.parmContentShow = task.parmContent.charAt(0) == '1';
      // 内容必填
      this.setting.parmContentMust = task.parmContent.charAt(1) == '1';

      // 显示平台
      this.setting.mediaShow = task.parmMedia.charAt(0) == '1';
      // 平台必填
      this.setting.mediaMust = task.parmMedia.charAt(1) == '1';
      if (this.setting.mediaShow && this.taskData.score == -999) {
        // 获取媒体平台列表
        this.getMediaList('edit')
      }

      // 显示发布类型
      this.setting.publishShow = task.parmPublishType.charAt(0) == '1';
      // 发布类型必填
      this.setting.publishMust = task.parmPublishType.charAt(1) == '1';
      if (this.setting.publishShow && this.taskData.score != -999) {
        this.getPublishTypeList('', 'edit')
      }

      // 显示作者类型
      this.setting.authorTypeShow = task.parmAuthorType.charAt(0) == '1';
      // 作者类型必填
      this.setting.authorTypeMust = task.parmAuthorType.charAt(1) == '1';
      // 获取作者类型
      if (this.setting.authorTypeShow) {
        // 获取作者类型
        this.getAuthType()
      }

      // 显示作者姓名
      this.setting.authorShow = task.parmAuthor.charAt(0) == '1';
      // 作者姓名必填
      this.setting.authorMust = task.parmAuthor.charAt(1) == '1';

      // 显示 网名/昵称
      this.setting.netNameShow = task.parmNetName.charAt(0) == '1';
      // 网名/昵称 必填
      this.setting.netNameMust = task.parmNetName.charAt(1) == '1';

      // 显示阅读数量
      this.setting.readNumShow = task.parmReadNum.charAt(0) == '1';
      // 阅读数量必填
      this.setting.readNumMust = task.parmReadNum.charAt(1) == '1';

      // 显示转载数量
      this.setting.reprintShow = task.parmReprint.charAt(0) == '1';
      // 转载数量必填
      this.setting.reprintMust = task.parmReprint.charAt(1) == '1';
      //全网推送
      this.setting.parmPushShow = task.parmPush ? task.parmPush.charAt(0) == '1' : false;
      this.setting.parmPushMust = task.parmPush ? task.parmPush.charAt(1) == '1' : false;
      // 显示附件
      this.setting.parmAttachShow = task.parmAttach.charAt(0) == '1';
      // 附件必填
      this.setting.parmAttachMust = task.parmAttach.charAt(1) == '1';
    },

    /** 删除 */
    removeWork (item) {
      if (item.articleStatus !== 2 && item.topicStatus !== 2) {
        this.$wpConfirm('提示', {
          tips: "确定删除吗？",
          content: "删除后数据不可恢复",
          isShowCancelBtn: true, //是否显示取消按钮
        }).then(async () => {
          const res = await wpyReportedApi.deleteTaskArticle({ topicArticleId: item.topicArticleId })
          if (res.returnCode == 200) {
            this.$showMessage({ type: 'success', msg: res.returnMsg })
            this.getTopicArticleList()
          } else {
            this.$showMessage({ type: 'error', msg: res.returnMsg })
          }
        }).catch(() => {
          console.log("取消")
        });
      }
    },
    // 转载数量 鼠标失焦事件
    reprintBlur () {
      this.setReprint()
      this.setScores(1)
    },
    setReprint () {
      this.rewardScore = 0
      // 针对于“积分按平台”的场景
      if (this.articleData.score != -999) return
      this.setWpTopicPushScore()

      if (!this.taskForm.reprint) return
      if (this.taskForm.reprint.toString().trim() == '' || this.taskForm.reprint <= 0) return
      // 将taskForm.reprint数量传给 后端 请求接口后 ，后端给 奖励分值
      // 奖励分值 大于 0 则显示  小于等于则不显示
      // 从集合中获取对应的奖励分
      console.log('setReprint====');


      if (this.mediaType) {
        console.log('this.mediaType', this.mediaType);
        // 赣州指定的媒体平台 单独有奖励分
        const mediaReward = this.rewardList.find(it => it.mediaType == this.mediaType)
        if (mediaReward && this.taskForm.reprint >= mediaReward.start && this.taskForm.reprint <= mediaReward.end) {
          this.rewardScore = mediaReward.score
        }
      } else {
        this.rewardList.filter(it => !it.mediaType).forEach(it => {
          if (this.taskForm.reprint >= it.start && this.taskForm.reprint <= it.end) {
            this.rewardScore = it.score
          }
        })
        console.log('this.taskForm.reprint', this.taskForm.reprint);
      }
    },
    // 获取 奖励分的 数组集合
    async getReward () {
      try {
        const res = await wpyReportedApi.getReward({ custId: this.articleData.custId })
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.rewardList = ret ? ret : []
        }

      } catch (error) {
        this.loading = false
      }
    },
    // 转载数量 鼠标失焦或者发布类型改变 计算基础分与奖励分
    setScores (type = 1) {
      // 针对于“积分按平台”的场景
      if (this.articleData.score != -999) return
      if (type == 1) {
        this.changePublishType(this.taskForm.publishType)
      } else if (type == 2) {
        this.setReprint()
      }
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    // 全网推送修改时
    changeParmPush (val) {
      if (val == 1) {
        this.setWpTopicPushScore()
      }
    },
    // 重置全网推送分
    setWpTopicPushScore () {
      // 按平台才有全网推送分
      if (this.taskForm.score !== -999) return
      this.setBaseScore()
      if (this.taskForm.parmPush == 1) {
        this.wpTopicPushScore = this.taskData?.wpTopicPushScore ?? 0
      }
    },
    //重置基础分
    setBaseScore () {
      if (this.taskForm.score !== -999) return
      // 按平台时  平台改变与 转载分 以及 全网推送改变 才重置基础分
      if (this.taskForm.publishType) {
        // 选了发布类型的情况
        const item = this.publishList.find(item => item.id == this.taskForm.publishType)
        this.taskForm.baseScore = item?.score || 0
      } else {
        this.taskForm.baseScore = 0
      }
    },
    // 是赣州，且是按平台的，选择的是微信视频号的，清空链接并禁用链接
    changeMedia () {
      if (!this.isGZ) return
      if (this.taskForm.score !== -999) return
      if (this.taskForm.media) {
        let mediaObj = this.mediaList.find(it => it.custWebsiteId == this.taskForm.media)
        if (mediaObj.name.indexOf('微信视频号') !== -1) {
          this.taskForm.url = ''
          this.urlDisabled = true
          this.verifyUrl = true
          this.$refs['workRef'].clearValidate()
        } else {
          this.urlDisabled = false
        }
      } else {
        this.urlDisabled = false
      }
    },
    //校验链接重复性
    async verifyDuplicateLinks () {
      if (!this.isGZ) return
      if (this.taskForm.url === '') return
      try {
        const res = await wpyReportedApi.existUrl({ id: this.articleId, url: this.taskForm.url })
        if (Number(res.returnCode) == 200) return
        if (Number(res.returnCode) != 80004) return
        const index = await this.$refs['workRef'].fields.findIndex(item =>
          item?.$el?._prevClass?.indexOf("isUrl") !== -1
        )
        this.verifyUrl = false
        this.$refs['workRef'].fields[index].validateMessage = await res.returnMsg
        this.$refs['workRef'].fields[index].validateState = await 'error'
      } catch (error) {

      } finally {
      }
    },
    //相似数据
    verifyAuthorAndTitle () {
      if (!this.isGZ) return true
      const query = {
        id: this.articleId,
        title: this.taskForm.title, //标题
        author: this.taskForm.author //作者
      }
      return new Promise((resolve, reject) => {
        wpyReportedApi.similar(query).then(res => {
          if (Number(res.returnCode) == 200) return resolve(true)
          this.$showMessage({ type: 'warning', msg: res.returnMsg })
          resolve(false)
        }).catch(err => {
          reject(true)
        })
      })
    }

  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>