var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.wpyUnitTabs.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.wpyUnitTabs,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "作品统计" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("TaskTimeSelect", {
                          ref: "taskTimeRef",
                          attrs: {
                            days: 365,
                            activeType: _vm.searchParams.timeType,
                            taskType: _vm.timeType,
                          },
                          on: { timeSelect: _vm.timeSelect },
                        }),
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: {
                            placeholder: "请输入任务标题",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "title",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchParams.title",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 任务数量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 作品数量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.statInfo.articleNum || 0)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.statInfo.score || 0)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "taskNumber",
                                  "min-width": "80",
                                  label: "任务编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  "min-width": "250",
                                  prop: "title",
                                  label: "任务标题",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "20", "max-width": "80" },
                              }),
                              _vm.isWpys
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "left",
                                      "min-width": "150",
                                      prop: "custName",
                                      "show-overflow-tooltip": "",
                                      label: "来源单位",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: { "max-width": "80", "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "150",
                                  align: "center",
                                  prop: "createTime",
                                  label: "发布时间",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "workNum",
                                  label: "作品数量",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }