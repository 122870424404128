var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "peopleInformation" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c("div", { staticClass: "layout-main" }, [
            _c(
              "div",
              [
                _c(
                  "el-breadcrumb",
                  {
                    staticClass: "wp-breadcrumb",
                    attrs: { "separator-class": "el-icon-arrow-right" },
                  },
                  [
                    _c(
                      "el-breadcrumb-item",
                      { attrs: { to: { name: "teamlist" } } },
                      [_vm._v("人员队伍")]
                    ),
                    _c("el-breadcrumb-item", [_vm._v("个人信息")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [_vm._v("个人信息 ")]),
              _c("div", { staticClass: "layout-content" }, [
                _c("div", { staticClass: "layout-content-top" }, [
                  _c("div", { staticClass: "user-avtar" }, [
                    _vm.userObj.sex == 1
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/nan.png"),
                            alt: "",
                          },
                        })
                      : _vm.userObj.sex == 2
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/nv.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "user-info" }, [
                    _c("p", { staticClass: "user-name" }, [
                      _vm._v(_vm._s(_vm.userObj.userName)),
                    ]),
                    _c("p", { staticClass: "user-phone" }, [
                      _vm._v(_vm._s(_vm.userObj.mobile)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "star" },
                      [
                        _c("el-rate", {
                          attrs: {
                            disabled: "",
                            colors: ["#FAA548", "#FAA548", "#FAA548"],
                            "void-color": "#D8D8D8",
                          },
                          model: {
                            value: _vm.userObj.starLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.userObj, "starLevel", $$v)
                            },
                            expression: "userObj.starLevel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "info-row" }, [
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("积分总数："),
                        ]),
                        _c("p", { staticClass: "info-item-text" }, [
                          _vm._v(_vm._s(_vm.allUserObj.totalScore)),
                        ]),
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("本月积分排名："),
                        ]),
                        _c("p", { staticClass: "info-item-text  " }, [
                          _vm._v(_vm._s(_vm.allUserObj.monthScoreRanking)),
                        ]),
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("最后登录时间："),
                        ]),
                        _c("p", { staticClass: "info-item-text  " }, [
                          _vm._v(
                            _vm._s(
                              _vm.userObj.loginTime
                                ? _vm.userObj.loginTime
                                : "暂未登录"
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("注册时间："),
                        ]),
                        _c("p", { staticClass: "info-item-text  " }, [
                          _vm._v(
                            _vm._s(
                              _vm.userObj.createTime &&
                                _vm
                                  .$moment(
                                    _vm.$utils.resetTime(_vm.userObj.createTime)
                                  )
                                  .format("YYYY-MM-DD HH:mm")
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "layout-content-bot" }, [
                  _c("div", { staticClass: "info-row" }, [
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("登录账号："),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.userObj.loginName,
                              placement: "top",
                              disabled: !_vm.isShowTooltipWidth,
                              tabindex: 3,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "info-item-text textOverOneLine",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.visibilityChangeWidth($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.userObj.loginName))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("所在分组："),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.sysGroup.groupName,
                              placement: "top",
                              disabled: !_vm.isShowTooltipWidth,
                              tabindex: 2,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "info-item-text  textOverOneLine",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.visibilityChangeWidth($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.sysGroup.groupName))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "info-row" }, [
                    _c("div", { staticClass: "info-item" }, [
                      _c("span", { staticClass: "info-item-label" }, [
                        _vm._v("人员角色："),
                      ]),
                      _c(
                        "p",
                        { staticClass: "info-item-text textOverOneLine" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$globalData.roleNames[_vm.userObj.roleId]
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("单位名称："),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.userObj.custName,
                              placement: "top",
                              disabled: !_vm.isShowTooltipWidth,
                              tabindex: 1,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "info-item-text textOverOneLine",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.visibilityChangeWidth($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.userObj.custName))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "info-row" }, [
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("开户银行："),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.userObj.bankName,
                              placement: "top",
                              disabled: !_vm.isShowTooltipWidth,
                              tabindex: 6,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "info-item-text textOverOneLine",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.visibilityChangeWidth($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.userObj.bankName))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "info-item" }, [
                      _c("span", { staticClass: "info-item-label" }, [
                        _vm._v("银行账号："),
                      ]),
                      _c(
                        "p",
                        { staticClass: "info-item-text textOverOneLine" },
                        [_vm._v(_vm._s(_vm.userObj.bankCode))]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-row" }, [
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _c("span", { staticClass: "info-item-label" }, [
                          _vm._v("开户行行号："),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.userObj.openBankCode,
                              placement: "top",
                              disabled: !_vm.isShowTooltipWidth,
                              tabindex: 7,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "info-item-text textOverOneLine",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.visibilityChangeWidth($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.userObj.openBankCode))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm.srcList.length > 0
              ? _c("div", { staticClass: "layout-block wp-moudle-style" }, [
                  _c("div", { staticClass: "layout-title" }, [
                    _vm._v("档案材料 "),
                  ]),
                  _c("div", { staticClass: "layout-content" }, [
                    _c(
                      "ul",
                      { staticClass: "img-box" },
                      [
                        _vm._l(_vm.srcList, function (item, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  fit: "cover",
                                  src: item,
                                  "preview-src-list":
                                    _vm.$utils.arrayRearrangement(
                                      index,
                                      _vm.srcList
                                    ),
                                },
                                on: { click: _vm.handleClickItem },
                              }),
                            ],
                            1
                          )
                        }),
                        _vm._l(5 - _vm.srcList.length, function (item, index) {
                          return _c("li", { key: index + 10 })
                        }),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [_vm._v("媒体账号 ")]),
              _c("div", { staticClass: "layout-content" }, [
                _c(
                  "div",
                  { staticClass: "layout-content-bot" },
                  _vm._l(_vm.mediaList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "info-row" },
                      _vm._l(item, function (it) {
                        return _c(
                          "div",
                          { key: it.userMediaId, staticClass: "info-item" },
                          [
                            _c("span", { staticClass: "info-item-label" }, [
                              _vm._v(_vm._s(it.mediaName) + "："),
                            ]),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: it.nickName,
                                  placement: "top",
                                  disabled: !_vm.isShowTooltipWidth,
                                  tabindex: it.userMediaId,
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "info-item-text textOverOneLine",
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.visibilityChangeWidth($event)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(it.nickName))]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm.sysUserInfo.sysCustOption.dutyState == 1 &&
            _vm.childUserlist.length > 0
              ? _c("div", { staticClass: "layout-block wp-moudle-style" }, [
                  _c("div", { staticClass: "layout-title" }, [
                    _vm._v("值班账号 "),
                  ]),
                  _c("div", { staticClass: "layout-content" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "wp-table",
                        attrs: {
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-text": "正在加载",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.childUserlist, stripe: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                width: "70",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "60",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "userName",
                                "min-width": "200",
                                label: "人员姓名",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "loginName",
                                label: "登录账号",
                                "min-width": "200",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "mobile",
                                label: "手机号码",
                                "min-width": "200",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "remark",
                                label: "备注",
                                "min-width": "100",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.childUserlist.length == 0 && !_vm.loading
                          ? _c("div", { staticClass: "wp-noData" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/noData.png"),
                                  alt: "",
                                },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [_vm._v("汇总信息 ")]),
              _c("div", { staticClass: "layout-content" }, [
                _c("div", { staticClass: "summary-box" }, [
                  _c("div", { staticClass: "right-item right-item-after" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.personSummary.articleTaskMainCount)),
                      ]),
                    ]),
                    _c("span", [_vm._v("任务总量")]),
                  ]),
                  _c("div", { staticClass: "right-item right-item-after" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.personSummary.articleTopicMainCount)),
                      ]),
                    ]),
                    _c("span", [_vm._v("上报总量")]),
                  ]),
                  _c("div", { staticClass: "right-item right-item-after" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.personSummary.articleDrillMainCount)),
                      ]),
                    ]),
                    _c("span", [_vm._v("演练总量")]),
                  ]),
                  _c("div", { staticClass: "right-item right-item-after" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [_vm._v(_vm._s(_vm.personSummary.totalScore))]),
                    ]),
                    _c("span", [_vm._v("积分总数")]),
                  ]),
                  _c("div", { staticClass: "right-item right-item-after" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [_vm._v(_vm._s(_vm.personSummary.yearScore))]),
                    ]),
                    _c("span", [_vm._v("本年积分总数")]),
                  ]),
                  _c("div", { staticClass: "right-item" }, [
                    _c("div", { staticClass: "right-item-text" }, [
                      _c("p", [_vm._v(_vm._s(_vm.personSummary.monthScore))]),
                    ]),
                    _c("span", [_vm._v("本月积分总数")]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "layout-mid3 " }, [
              _c("div", { staticClass: "wp-moudle-style mid3-item" }, [
                _c(
                  "div",
                  { staticClass: "item-title home-title item-title-flex" },
                  [
                    _c("div", [_vm._v("任务完成率")]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "wp-button-group wp-button-group-left",
                                class: { active: _vm.days == 30 },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskDays(30)
                                  },
                                },
                              },
                              [_vm._v(" 近1月 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "wp-button-group wp-button-group-left",
                                class: { active: _vm.days == 180 },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskDays(180)
                                  },
                                },
                              },
                              [_vm._v("近6月 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "wp-button-group",
                                class: { active: _vm.days == 365 },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskDays(365)
                                  },
                                },
                              },
                              [_vm._v("近1年 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "item-content" },
                  [
                    _c("pie-ratio-chart", {
                      ref: "pieRatioChart",
                      attrs: { pieData: _vm.taskPieData },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "wp-moudle-style mid3-item" }, [
                _c("div", { staticClass: "item-title home-title" }, [
                  _vm._v("任务完成量"),
                ]),
                _c(
                  "div",
                  { staticClass: "item-content" },
                  [
                    _c("bar-chart", {
                      ref: "barChart",
                      attrs: {
                        id: "barChart",
                        xAxisData: _vm.taskXAxisData,
                        yAxisData: _vm.taskYAxisData,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }