<template>
  <wp-dialog
    @cancel="handleClose"
    :title="classifyAddFlag ? '添加分类' : '编辑分类'"
    @determine="determine"
    width="420px"
    smallHeight="170px"
    size="small"
    :dialogVisible="dialogVisible"
    @handleClose="handleClose"
  >
    <div class="addGroup">
      <el-form
        :model="classifyForm"
        :rules="classifyRule"
        ref="classifyRef"
        label-width="0px"
      >
        <el-form-item prop="taskTypeName" label="">
          <el-input
            class="wp-input"
            maxlength="10"
            v-model.trim="classifyForm.taskTypeName"
            :placeholder="placeholder"
            clearable
            @keyup.enter.native="determine"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
export default {
  name: 'addClassify',
  components: {},
  props: {
    // 判断是否为新增
    classifyAddFlag: {
      type: Boolean,
      default: true
    },
    // 编辑回显数据
    formData: Object,
    placeholder: {
      type: String,
      default: '请输入分类名称'
    }
  },
  data () {
    return {
      dialogVisible: false, // 弹窗的显示与隐藏
      classifyForm: {
        taskTypeName: "" // 分类名称
      },
      classifyRule: {
        taskTypeName: { required: true, message: this.placeholder, trigger: 'blur' }
      }
    };
  },
  mounted () {

  },
  methods: {
    // 点击确认按钮
    determine () {
      this.$refs.classifyRef.validate((valid) => {
        if (valid) {
          this.$emit("submitForm", this.classifyForm)
        } else {
          return false;
        }
      });
    },

    // 关闭弹窗回调
    handleClose () {
      this.$refs.classifyRef.resetFields()
      this.dialogVisible = false
    },

    // 展开弹窗
    show () {
      this.dialogVisible = true
    },
  },

  watch: {
    formData: {
      handler (newVal, oldVal) {
        if (!this.classifyAddFlag) {
          this.$nextTick(() => {
            this.classifyForm.taskTypeName = newVal.taskTypeName
          })
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
</style>