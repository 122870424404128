var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                staticClass: "title-wp-card",
                attrs: { title: "上报审核" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("TaskTimeSelect", {
                          ref: "taskTime",
                          attrs: {
                            activeType: _vm.searchParams.timeType,
                            taskType: _vm.timeType,
                          },
                          on: { timeSelect: _vm.timeSelect },
                        }),
                        _c("wpLoadSelect", {
                          staticClass: "search-w392 wp-select align-top",
                          attrs: {
                            data: _vm.topicList,
                            page: _vm.topicObj.page,
                            dictLabel: "title",
                            dictValue: "topicId",
                            dictTime: "createTime",
                            hasMore: _vm.topicObj.more,
                            request: _vm.getData,
                            placeholder: "请输入专题名称",
                          },
                          on: {
                            clearTitle: function () {
                              return (_vm.topicObj.title = "")
                            },
                          },
                          model: {
                            value: _vm.searchParams.topicId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "topicId", $$v)
                            },
                            expression: "searchParams.topicId",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select wp-multiple",
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              filterable: "",
                              placeholder: "请选择专题分类",
                            },
                            model: {
                              value: _vm.searchParams.topicTypeIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "topicTypeIds", $$v)
                              },
                              expression: "searchParams.topicTypeIds",
                            },
                          },
                          _vm._l(_vm.classifyList, function (item) {
                            return _c("el-option", {
                              key: item.topicTypeId,
                              attrs: {
                                label: item.topicTypeName,
                                value: item.topicTypeId,
                              },
                            })
                          }),
                          1
                        ),
                        _c("el-cascader", {
                          ref: "cascaderRef",
                          staticClass: "search-w190 wp-cascader align-top",
                          attrs: {
                            options: _vm.groupList,
                            props: _vm.defaultProps,
                            "collapse-tags": "",
                            placeholder: "请选择所在分组",
                            clearable: "",
                          },
                          on: { change: _vm.changeCascader },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select align-top",
                            attrs: {
                              placeholder: "请选择审核状态",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchParams.articleStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "articleStatus", $$v)
                              },
                              expression: "searchParams.articleStatus",
                            },
                          },
                          _vm._l(_vm.$globalData.REVIEWSTATUS, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 上报总量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.countData.REPORTNUM)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.countData.SCORE)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "export-box" },
                      [
                        _c(
                          "el-popover",
                          {
                            ref: "export-popover",
                            attrs: {
                              "popper-class": "export-popover",
                              placement: "bottom",
                              trigger: "click",
                            },
                          },
                          [
                            _c("div", { staticClass: "export-list" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "export-item wp-pointer",
                                  on: { click: _vm.collectExport },
                                },
                                [_vm._v(" 汇总导出 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "export-item wp-pointer",
                                  on: { click: _vm.exportFile },
                                },
                                [_vm._v(" 作品导出 ")]
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticClass: "wp-button",
                                staticStyle: { width: "90px" },
                                attrs: { slot: "reference", size: "medium" },
                                slot: "reference",
                              },
                              [
                                _vm._v(" 导出"),
                                _c("i", {
                                  staticClass: "icon-zhankai el-icon--right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "40" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  align: "left",
                                  prop: "title",
                                  label: "作品标题",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(
                                                    scope.row.topicArticleId,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: scope.row.title,
                                                    placement: "top",
                                                    disabled:
                                                      !_vm.isShowTooltipWidth,
                                                    "popper-class":
                                                      "wp-visibilityPopper",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "textOverOneLine",
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.visibilityChangeWidth(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.title
                                                              ? scope.row.title
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  405275341
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  align: "left",
                                  prop: "url",
                                  label: "作品链接",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.$utils.download(
                                                    scope.row.url
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: scope.row.title,
                                                    placement: "top",
                                                    disabled:
                                                      !_vm.isShowTooltipWidth,
                                                    "popper-class":
                                                      "wp-visibilityPopper",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "textOverOneLine",
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.visibilityChangeWidth(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.url
                                                              ? scope.row.url
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1359949708
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "custName",
                                  width: "80",
                                  label: "截图/附件",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(
                                                    scope.row.topicArticleId,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              !scope.row.fileJson ||
                                              JSON.parse(scope.row.fileJson)
                                                .length == 0
                                                ? _c("p", [_vm._v(" - ")])
                                                : _vm.$utils.isImg(
                                                    scope.row.fileJson
                                                  )
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            "visible-arrow": false,
                                                            trigger: "hover",
                                                            "popper-class":
                                                              "img-popper",
                                                            tabindex: 3,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "scaled-image",
                                                            attrs: {
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                              fit: "contain",
                                                            },
                                                          }),
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "22px",
                                                              height: "22px",
                                                            },
                                                            attrs: {
                                                              slot: "reference",
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "22px",
                                                      height: "22px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                    },
                                                    slot: "reference",
                                                  }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2607154177
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "80" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                  align: "left",
                                  prop: "createName",
                                  label: "人员姓名",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "150",
                                  prop: "createTime",
                                  label: "上报时间",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$moment(scope.row.createTime)
                                                  .format("YYYY-MM-DD HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  676827023
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "articleStatus",
                                  label: "审核状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            scope.row.articleStatus == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "receive-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "receive-color",
                                                      },
                                                      [_vm._v("已通过")]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "noreceive-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "noreceive-color",
                                                      },
                                                      [_vm._v("已驳回")]
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3513324961
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "step flex-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      ref:
                                                        "popover-" +
                                                        scope.$index,
                                                      attrs: {
                                                        placement: "top",
                                                        trigger: "click",
                                                        width: "220",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: {
                                                          min: 0,
                                                          precision: 1,
                                                          max: 10000,
                                                          size: "small",
                                                        },
                                                        nativeOn: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeScore(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.editRow
                                                              .totalScore,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editRow,
                                                              "totalScore",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editRow.totalScore",
                                                        },
                                                      }),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "6px",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.submitScore(
                                                                _vm.editRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("确定")]
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            slot: "reference",
                                                            effect: "dark",
                                                            content: "改分",
                                                            "visible-arrow": false,
                                                            "popper-class":
                                                              "wp-btn-tooltip",
                                                            placement: "top",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            staticClass:
                                                              "wp-btn-icon-big",
                                                            attrs: {
                                                              type: "text",
                                                              icon: "icon-bianji1",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.edit(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "驳回",
                                                    placement: "top",
                                                    "visible-arrow": false,
                                                    "popper-class":
                                                      "wp-btn-tooltip",
                                                  },
                                                },
                                                [
                                                  scope.row.articleStatus == 1
                                                    ? _c(
                                                        "RejectCause",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入审核意见",
                                                            popoverType: "add",
                                                          },
                                                          on: {
                                                            handlerReject:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handlerReject(
                                                                  $event,
                                                                  scope.row
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-bohui wp-blue wp-pointer fs-20",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              scope.row.articleStatus !== 1
                                                ? _c(
                                                    "RejectCause",
                                                    {
                                                      key: scope.row
                                                        .topicArticleId,
                                                      attrs: {
                                                        popoverType: "detail",
                                                        rejectObj:
                                                          _vm.rejectObj,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-bohui wp-pointer fs-20",
                                                        staticStyle: {
                                                          color: "#ccc",
                                                        },
                                                        on: {
                                                          mouseenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.getReject(
                                                              scope.row
                                                                .topicArticleId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3548181613
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { ref: "hideDiv" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }