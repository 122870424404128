<template>
  <div class="teamlist main">
    <div class="main-top">
      <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
        <el-tab-pane label="人员队伍" name="personnelTeam">
        </el-tab-pane>
        <el-tab-pane label="常用人员" name="commonPersonnel" v-if="roleId != this.$ENUM.SYSROLE.GROUPLEADER">
        </el-tab-pane>
      </TabsCard>
    </div>
    <div class="main-bot  flex" v-if="activeTab == 'personnelTeam'">
      <TeamTable></TeamTable>
    </div>
    <div class="main-bot  flex" v-else-if="activeTab == 'commonPersonnel'">
      <CommonTable> </CommonTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TabsCard from '@/views/components/tabsCard'
import TeamTable from '@/views/personnelTeam/subTable/teamTable'
import CommonTable from '@/views/personnelTeam/subTable/commonTable'
export default {
  name: 'panel',
  components: {  TabsCard, TeamTable,CommonTable },
  data () {
    return {
      activeTab: "personnelTeam", // 所选tab
      tabs: [
        { label: '人员队伍', name: "personnelTeam" },
        { label: '常用人员', name: "commonPersonnel" }
      ]
    };
  },
  computed: {
    ...mapGetters(['sysUserInfo', 'roleId']),
  },
  props: {},
  created () {
  },
  mounted () {
  },
  methods: {
    handlerTabs (tab) {
      this.activeTab = tab
    }
  },
};
</script>
<style scoped lang="scss">
@import "./css/teamlist.scss";
</style>
