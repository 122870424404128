var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/reportedManage" } } },
                    [_vm._v("上报管理")]
                  ),
                  _c("el-breadcrumb-item", [_vm._v("专题详情")]),
                ],
                1
              ),
              _c("div", { staticClass: "wp-card mt-20 task-detail" }, [
                _c("div", { staticClass: "task-head" }, [
                  _vm.topicInfo.topicStatus == 1
                    ? _c("div", { staticClass: "task-state" }, [
                        _vm._v(" 已发布 "),
                      ])
                    : _c("div", { staticClass: "finsh-state" }, [
                        _vm._v("已结束"),
                      ]),
                  _c(
                    "div",
                    { staticClass: "textOverOneLine task-title fs-16 fw-bold" },
                    [_vm._v(" " + _vm._s(_vm.topicInfo.title) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "task-info mt-20 fs-14" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 专题编号："),
                      _c("span", [_vm._v(_vm._s(_vm.topicInfo.topicNum))]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 专题分类："),
                      _c("span", [_vm._v(_vm._s(_vm.topicInfo.topicTypeName))]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 创建时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(_vm.topicInfo.createTime)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "task-item flex-3 textOverOneLine" },
                      [
                        _vm._v(" 创建人："),
                        _c("span", [_vm._v(_vm._s(_vm.topicInfo.createName))]),
                      ]
                    ),
                    _c("div", { staticClass: "task-item" }),
                  ]),
                  _c("div", { staticClass: "mt-20 flex" }, [
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 开始时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(_vm.topicInfo.startTime)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 结束时间："),
                      _c("span", { staticClass: "orange" }, [
                        _vm._v(
                          _vm._s(
                            _vm.topicInfo.longItem
                              ? "不限"
                              : _vm
                                  .$moment(_vm.topicInfo.endTime)
                                  .format("YYYY-MM-DD HH:mm")
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item flex-3" }, [
                      _vm._v(" 专题积分："),
                      _c("span", { staticClass: "orange" }, [
                        _vm._v(
                          _vm._s(
                            _vm.topicInfo.score == -999
                              ? "按平台"
                              : _vm.topicInfo.score
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item" }),
                    _c("div", { staticClass: "task-item" }),
                  ]),
                  _c("div", { staticClass: "mt-20 task-item" }, [
                    _vm._v(" 专题描述："),
                    _c("span", { staticClass: "lh-24" }, [
                      _vm._v(_vm._s(_vm.topicInfo.content)),
                    ]),
                  ]),
                  _vm.topicInfo.fileJson && _vm.topicInfo.fileJson.length
                    ? _c(
                        "div",
                        { staticClass: "mt-20 task-item" },
                        [
                          _vm._v(" 专题附件： "),
                          _c("File", {
                            staticStyle: { "margin-top": "8px" },
                            attrs: { fileJson: _vm.topicInfo.fileJson },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "wp-card mt-16" }, [
                _c("div", { staticClass: "fs-16 fw-bold" }, [
                  _vm._v("汇总信息"),
                ]),
                _c("div", { staticClass: "num-list flex" }, [
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.summaryInfo.SEEDNUM))]),
                    _c("div", [_vm._v("发送人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.summaryInfo.JSTOTAL))]),
                    _c("div", [_vm._v("接收人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.summaryInfo.POPLENUM))]),
                    _c("div", [_vm._v("上报人数")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [_vm._v(_vm._s(_vm.summaryInfo.REPORTTOTAL))]),
                    _c("div", [_vm._v("上报总量")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.summaryInfo.completionRate)),
                      _c("span", { staticClass: "fs-14" }, [_vm._v("%")]),
                    ]),
                    _c("div", [_vm._v("完成率")]),
                  ]),
                  _c("div", { staticClass: "num-item" }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.summaryInfo.qualifiedRate)),
                      _c("span", { staticClass: "fs-14" }, [_vm._v("%")]),
                    ]),
                    _c("div", [_vm._v("合格率")]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "fs-16 fw-bold" }, [
                    _vm._v("队伍上报情况"),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.teamLoading,
                          expression: "teamLoading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.teamList.length
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.teamList, stripe: "" } },
                            [
                              _c("el-table-column", { attrs: { width: "50" } }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { width: "120" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "groupName",
                                  width: "240",
                                  "show-overflow-tooltip": "",
                                  label: "分组名称",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "textOverOneLine",
                                              class: scope.row.child
                                                ? "group-name"
                                                : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getPeopleDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.groupName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3844018365
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "seedNum",
                                  label: "发送人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "jsTotal",
                                  label: "接收人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "popleNum",
                                  label: "完成人数",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "wcRate",
                                  label: "完成率",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(scope.row.wcRate) + "%"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2859916297
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.teamList.length == 0 && !_vm.teamLoading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.teamTotal,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.teamCurrentChange },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB people-info" }, [
                    _c("div", { staticClass: "flex-Acenter" }, [
                      _c("div", { staticClass: "fs-16 fw-bold" }, [
                        _vm._v("人员上报情况"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "fs-14 wp-blue flex-Acenter export",
                          on: { click: _vm.exportFile },
                        },
                        [
                          _c("i", { staticClass: "icon-daochu" }),
                          _c("span", [_vm._v("导出")]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "is-finish flex" }, [
                      _c(
                        "div",
                        {
                          class: _vm.activeFinish == 1 ? " active-finish" : "",
                          on: {
                            click: function ($event) {
                              return _vm.totggleFinish(1)
                            },
                          },
                        },
                        [_vm._v(" 已完成 ")]
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.activeFinish == 0 ? " active-finish" : "",
                          on: {
                            click: function ($event) {
                              return _vm.totggleFinish(0)
                            },
                          },
                        },
                        [_vm._v(" 未完成 ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.peopleLoading,
                          expression: "peopleLoading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.activeFinish == 1
                        ? _c(
                            "div",
                            [
                              _vm.peopoleList.length
                                ? _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.peopoleList,
                                        stripe: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          type: "index",
                                          label: "序号",
                                          width: "70",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "fileJson",
                                          width: "150",
                                          label: "截图/附件",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm.$utils.isImg(
                                                    scope.row.fileJson
                                                  )
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-popover",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "right-start",
                                                                "visible-arrow": false,
                                                                trigger:
                                                                  "hover",
                                                                "popper-class":
                                                                  "img-popper",
                                                                tabindex: 3,
                                                              },
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "scaled-image",
                                                                attrs: {
                                                                  src: _vm.$utils.handlePreviewImg(
                                                                    scope.row
                                                                      .fileJson
                                                                  ),
                                                                  fit: "contain",
                                                                },
                                                              }),
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width: "22px",
                                                                  height:
                                                                    "22px",
                                                                },
                                                                attrs: {
                                                                  slot: "reference",
                                                                  src: _vm.$utils.handlePreviewImg(
                                                                    scope.row
                                                                      .fileJson
                                                                  ),
                                                                },
                                                                slot: "reference",
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c("img", {
                                                        staticStyle: {
                                                          width: "22px",
                                                          height: "22px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          src: _vm.$utils.handlePreviewImg(
                                                            scope.row.fileJson
                                                          ),
                                                        },
                                                        slot: "reference",
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3289323152
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          width: "150",
                                          "show-overflow-tooltip": "",
                                          prop: "createName",
                                          label: "人员姓名",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          prop: "groupName",
                                          label: "所在分组",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "createTime",
                                          width: "180",
                                          label: "上报时间",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .$moment(
                                                            scope.row.createTime
                                                          )
                                                          .format(
                                                            "YYYY-MM-DD HH:mm"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          676827023
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "totalScore",
                                          label: "奖励积分",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.totalScore ==
                                                          -999
                                                          ? "按平台"
                                                          : scope.row.totalScore
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1620228358
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "articleStatus",
                                          label: "审核状态",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.articleStatus !== 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "noreceive-circle",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "noreceive-color",
                                                            },
                                                            [_vm._v("已驳回")]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "receive-circle",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "receive-color",
                                                            },
                                                            [_vm._v("已通过")]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          607390252
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.articleStatus == 1
                                                    ? _c(
                                                        "RejectCause",
                                                        {
                                                          attrs: {
                                                            popoverType: "add",
                                                            placeholder:
                                                              "请输入审核意见",
                                                          },
                                                          on: {
                                                            handlerReject:
                                                              _vm.handlerReject,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "reject",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.topicArticleId =
                                                                      scope.row.topicArticleId
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 驳回 ")]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "RejectCause",
                                                        {
                                                          attrs: {
                                                            popoverType:
                                                              "detail",
                                                            rejectObj:
                                                              _vm.rejectObj,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "reject-disabled",
                                                              on: {
                                                                mouseenter:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getReject(
                                                                      scope.row
                                                                        .topicArticleId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 驳回 ")]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1084559598
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.activeFinish
                        ? _c(
                            "div",
                            [
                              _vm.peopoleList.length
                                ? _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.peopoleList,
                                        stripe: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          type: "index",
                                          label: "序号",
                                          width: "70",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: { width: "40" },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          width: "150",
                                          prop: "receiverName",
                                          label: "人员姓名",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          prop: "groupName",
                                          label: "所在分组",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          width: "200",
                                          "show-overflow-tooltip": "",
                                          prop: "custName",
                                          label: "所在单位",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "topicReceiverStatus",
                                          label: "接收状态",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row
                                                    .topicReceiverStatus == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "noreceive-circle",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "noreceive-color",
                                                            },
                                                            [_vm._v("未接收")]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "receive-circle",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "receive-color",
                                                            },
                                                            [_vm._v("已接收")]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2368227001
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "receiverTime",
                                          label: "接收时间",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (scope.row
                                                          .receiverTime &&
                                                          _vm
                                                            .$moment(
                                                              scope.row
                                                                .receiverTime
                                                            )
                                                            .format(
                                                              "YYYY-MM-DD HH:mm"
                                                            )) ||
                                                          "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1156123922
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "wp-blue wp-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.sendMessage(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 发送短信 ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          755772537
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.peopoleList.length == 0 &&
                                !_vm.peopleLoading,
                              expression:
                                "peopoleList.length == 0 && !peopleLoading",
                            },
                          ],
                          staticClass: "wp-noData mt-20",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/noData.png"),
                              alt: "",
                            },
                          }),
                          _c("p", [_vm._v("暂无数据")]),
                        ]
                      ),
                    ]
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      layout: "prev, pager, next",
                      total: _vm.peopleTotal,
                      "page-size": 10,
                    },
                    on: { "current-change": _vm.peopleCurrentChange },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "wp-card mt-16" }, [
                _c("div", { staticClass: "fs-16 fw-bold" }, [
                  _vm._v("上报趋势图"),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.trendLoading,
                        expression: "trendLoading",
                      },
                    ],
                    staticClass: "chart-box",
                    attrs: {
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-text": "正在加载",
                    },
                  },
                  [
                    _vm.yTrendData.length
                      ? _c("bar-chart", {
                          ref: "trendChart",
                          attrs: {
                            hoverTitle: "数量",
                            id: "trendChart",
                            xAxisData: _vm.xTrendData,
                            yAxisData: _vm.yTrendData,
                          },
                        })
                      : _vm._e(),
                    _vm.yTrendData.length == 0 && !_vm.trendLoading
                      ? _c("div", { staticClass: "wp-noData mt-20" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/noData.png"),
                              alt: "",
                            },
                          }),
                          _c("p", [_vm._v("暂无数据")]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "wp-dialog",
        {
          attrs: { size: "small", dialogVisible: _vm.messageVisible },
          on: {
            cancel: _vm.messageCancel,
            "update:dialogVisible": function ($event) {
              _vm.messageVisible = $event
            },
            "update:dialog-visible": function ($event) {
              _vm.messageVisible = $event
            },
            determine: _vm.confirmMessage,
            handleClose: _vm.messageCancel,
          },
        },
        [
          _c("div", { staticClass: "message-box" }, [
            _c("div", { staticClass: "fs-16 fw-bold" }, [
              _vm._v("确定发送短信吗？"),
            ]),
            _c("div", { staticClass: "message-text" }, [
              _vm._v("发送短信将会提醒接收人"),
            ]),
          ]),
        ]
      ),
      _c(
        "wp-dialog",
        {
          attrs: {
            dialogVisible: _vm.groupingVisible,
            showDefaultFooter: false,
            height: "550px",
            width: "820px",
            title: _vm.groupingName,
          },
          on: {
            cancel: _vm.groupingCancel,
            "update:dialogVisible": function ($event) {
              _vm.groupingVisible = $event
            },
            "update:dialog-visible": function ($event) {
              _vm.groupingVisible = $event
            },
            handleClose: _vm.groupingCancel,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.twoTeamLoading,
                    expression: "twoTeamLoading",
                  },
                ],
                staticClass: "grouping-box wp-table",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-text": "正在加载",
                },
              },
              [
                _vm.twoTeamList.length
                  ? _c(
                      "el-table",
                      { attrs: { data: _vm.twoTeamList, stripe: "" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "index",
                            label: "序号",
                            width: "70",
                          },
                        }),
                        _c("el-table-column", { attrs: { width: "20" } }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "groupName",
                            label: "分组名称",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "seedNum",
                            label: "发送人数",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "jsTotal",
                            label: "接收人数",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "popleNum",
                            label: "完成人数",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "wcRate",
                            label: "完成率",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.wcRate || 0) + "%"
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1781836089
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.twoTeamList.length == 0 && !_vm.twoTeamLoading
                  ? _c("div", { staticClass: "wp-noData mt-20" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/noData.png"),
                          alt: "",
                        },
                      }),
                      _c("p", [_vm._v("暂无数据")]),
                    ])
                  : _vm._e(),
                _c("wp-pagination", {
                  staticClass: "mt-16",
                  attrs: {
                    layout: "prev, pager, next",
                    total: _vm.twoTeamTotal,
                    "page-size": 10,
                  },
                  on: { "current-change": _vm.twoTeamCurrentChange },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }