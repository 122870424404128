<template>
  <div>
    <div>
      <div v-if="imageUrl.length" class="image-list">
        <div
          v-for="(item, index) in imageUrl"
          :key="item.id"
          class="image-item"
        >
          <img :src="baseURL + item.id" class="avatar" :title="item.name" />
          <img
            v-if="!noDelete"
            src="@/assets/images/close.png"
            class="icon-guanbi"
            @click="deleteImage(index)"
          />
        </div>
      </div>

      <div v-if="videoUrl.length" class="image-list">
        <div
          v-for="(item, index) in videoUrl"
          :key="item.id"
          class="image-item"
        >
          <img :src="baseURL + item.picId" class="avatar" :title="item.name" />
          <img
            v-if="!noDelete"
            src="@/assets/images/close.png"
            class="icon-guanbi"
            @click="deleteVideo(index)"
          />
        </div>
      </div>

      <el-upload
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        v-show="isShow"
        class="avatar-uploader"
        :http-request="httpRequest"
        :action="action"
        :accept="accept"
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <p v-if="caution" class="fs-14" style="color: #f35454">
        {{ caution }}
      </p>
    </div>

    <div class="file-placeholder mt-14" :class="className">
      {{ remark }}
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/api/personnelTeam.js'
export default {
  props: {
    className: String,
    placeholder: {
      type: String,
      default: "请上传jpg、jpeg、png、mp4格式文件"
    },
    caution: String,
    // 图片列表
    fileList: {
      type: [Array, String],
      default: () => []
    },
    // 支持上传格式
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png'
    },
    // 文件类型限制 传image则限制图片类型
    fileType: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png', 'mp4']
    },
    // 视频大小限制
    maxVideo: {
      type: Number,
      default: 200
    },
    // 图片单个大小限制
    maxImg: {
      type: Number,
      default: 20
    },
    // 可上传图片最大数量
    imageMaxNum: {
      type: Number,
      default: 10
    },
    // 可上传视频最大数量
    videoMaxNum: {
      type: Number,
      default: 1
    },
    // 不可删除
    noDelete: Boolean
  },
  data () {
    return {
      imageUrl: [], // 图片集合
      videoUrl: [], // 视频集合
      isShow: true,
      img: ['jpg', 'jpeg', 'png'],
      action: "/wpxt/file/upload",
      currentPlayer: "",
      remark: this.placeholder, // 提示语
      baseURL: this.$axios.defaults.baseURL + '/file/',
      loading: false,
      isReset: false
    };
  },
  watch: {
    // 回显传入的图片集合
    fileList: {
      handler (newVal) {
        console.log(newVal)
        if (newVal?.length) {
          this.loading = false
          const list = this.$utils.setFileFileJson(newVal)
          if (list[0].type == 'img') {
            this.remark = "请上传jpg、jpeg、png格式文件"
            this.imageUrl = newVal
          } else {
            this.videoUrl = newVal
          }
        }
      },
      deep: true,
      immediate: true
    },

    // 图片集合
    imageUrl: {
      handler (newVal) {
        console.log(newVal);
        if (newVal?.length > 0) {
          if (newVal?.length >= this.imageMaxNum) {
            this.isShow = false
          } else {
            this.isShow = true
          }
        }
      },
      deep: true,
      immediate: true
    },

    // 视频集合
    videoUrl: {
      handler (newVal) {
        console.log(newVal);
        if (newVal?.length > 0) {
          if (newVal?.length >= this.videoMaxNum) {
            this.isShow = false
          } else {
            this.isShow = true
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async httpRequest (option) {
      if (this.loading) return
      this.loading = true
      this.$emit('changeUpload', true)

      console.log(option)
      let fd = new FormData(); //参数的格式是formData格式的
      fd.append("file", option.file); //参数
      fd.append("id", '1'); //参数

      try {
        let res = await uploadFile(fd)
        this.loading = false
        this.$emit('changeUpload', false)

        if (this.isReset) {
          this.isReset = false
          return
        }

        // 上传成功
        // 上传成功
        if (res.returnCode == 200) {
          const ret = res.returnData

          const fileObj = {
            id: ret.fileId,
            name: ret.fileName,
            picId: ret?.picId
          }

          const lastStr = ret.fileName.substring(ret.fileName.lastIndexOf('.') + 1).toLowerCase()

          // 如果是图片,则添加至图片数组中
          if (this.img.indexOf(lastStr) != -1) {
            this.remark = "请上传jpg、jpeg、png格式文件"
            this.imageUrl.push(fileObj)
            this.$emit("fileChange", this.imageUrl)
          } else {
            this.videoUrl.push(fileObj)
            this.$emit("fileChange", this.videoUrl)
          }
        } else {
          this.$showMessage({
            type: 'error',
            msg: `该文件上传异常`
          })
        }

      } catch (error) {
        this.$emit('changeUpload', false)
        this.loading = false
        console.log(error)
      }
    },

    resetFile () {
      this.imageUrl = []
      this.videoUrl = []
      this.isShow = true
      this.loading = false
      this.isReset = true

      console.log(this.imageUrl, this.videoUrl);
      this.$emit("fileChange", [])
    },

    // 删除图片
    deleteImage (index) {
      console.log(index, 1);
      this.imageUrl.splice(index, 1)
      this.$emit("fileChange", this.imageUrl)

      if (!this.imageUrl.length) {
        this.remark = this.placeholder
      }
    },

    // 删除视频
    deleteVideo (index) {
      this.videoUrl.splice(index, 1)
      this.$emit("fileChange", this.videoUrl)

      if (!this.videoUrl.length) {
        this.isShow = true
        this.remark = this.placeholder
      }
    },

    /** 上传失败时的回调 */
    handleUploadError (err, file, fileList) {
      console.log(err, file, fileList)
    },

    /** 上传成功回调 */
    handleUploadSuccess (res, file) {
      // 上传成功
      if (res.returnCode == 200) {
        const ret = res.returnData

        const fileObj = {
          id: ret.fileId,
          name: ret.fileName,
          path: `/wpxt/file/${ret.fileId}`,
          type: 'img',
          picId: ret.fileId
        }

        console.log(res, file)

        const isImage = file.raw.type.indexOf('image') > -1
        // 如果是图片,则添加至图片数组中
        if (isImage) {
          this.remark = "请上传jpg、jpeg、png格式文件"
          this.imageUrl.push(fileObj)
        } else {
          fileObj.picId = ret.picId
          fileObj.path = `/wpxt/file/${ret.picId}`
          fileObj.type = 'video'
          this.videoUrl.push(fileObj)
        }

        console.log(fileObj, this.imageUrl, this.videoUrl)

        if (this.videoUrl.length > 0) {
          this.$emit("fileChange", this.videoUrl)
        } else {
          this.$emit("fileChange", this.imageUrl)
        }
      } else {
        this.$showMessage({ type: "error", msg: res.returnMsg })
      }

    },

    /** 上传前的回调 */
    handleBeforeUpload (file) {
      let name = file.name.substring(0,file.name.lastIndexOf('.'))
      if(name.length > 100){
        this.$showMessage({
            type: 'error',
            msg: '文件名过长'
          })
        return false
      }
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      let extension = true;

      console.log(file);

      console.log(this.fileType, testmsg)
      if (this.fileType.length > 0 && this.fileType.indexOf(testmsg) === -1) {
        extension = false;
      }

      console.log(extension);

      // 校验通过
      if (extension) {
        // 图片类型
        const isImage =
          testmsg == "png" ||
          testmsg == "jpg" ||
          testmsg == "jpeg";

        // 视频类型
        const isVideo = testmsg == "mp4"

        if (isImage) {
          const isMaxImg = file.size / 1024 / 1024 < this.maxImg;
          if (!isMaxImg) {
            this.$showMessage({ type: "warning", msg: `图片文件最大上传${this.maxImg}M` })
          }
          return isImage && isMaxImg

        } else if (isVideo) {
          // 已上传图片后,就只能上传图片类型
          if (this.imageUrl.length) {
            this.$showMessage({ type: 'warning', msg: '请上传jpg、jpeg、png格式图片' })
            return false
          } else {
            const isMaxVideo = file.size / 1024 / 1024 < this.maxVideo;
            // 校验视频大小
            if (!isMaxVideo) {
              this.$showMessage({ type: "warning", msg: `视频文件最大上传${this.maxVideo}M` })
            }
            return isVideo && isMaxVideo
          }
        } else {
          this.$showMessage({ type: "warning", msg: "格式不支持，请上传jpg、jpeg、png、mp4格式文件" })

          return false
        }
      } else {
        this.$showMessage({ type: "warning", msg: this.remark })
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .video-js .vjs-tech {
  object-fit: fill;
}
::v-deep .vjs-poster {
  background-size: cover;
}

.upload {
  width: 726px;
}

.flex-warp {
  display: flex;
  flex-wrap: wrap;
}

.image-list {
  display: inline;
  vertical-align: top;

  .image-item {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    .avatar {
      width: 96px;
      height: 96px;
      display: block;
      border-radius: 4px;
    }

    .icon-guanbi {
      cursor: pointer;
      position: absolute;
      top: -9px;
      right: -9px;
      width: 20px;
      height: 20px;
    }
  }
}

/deep/ .video-js {
  width: 400px;
  height: 200px;
}

.avatar-uploader {
  display: inline-block;
  vertical-align: top;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    width: 96px;
    height: 94px;
    line-height: 96px;
    font-size: 28px;
    color: #8c939d;
    border-radius: 4px;
    border: 1px dashed #dddddd;
    text-align: center;
  }
}

.mt-0 {
  margin-top: 0px !important;
}

.file-placeholder {
  height: 24px;
  color: #cccccc;
  font-size: 14px;
}
</style>