<template>
  <div class="main">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/taskManagement' }">投稿管理</el-breadcrumb-item>
      <el-breadcrumb-item>追加人员</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex-CB mt-16 wp-w100">
      <div class="people-list">
        <div class="people-tab">
          <div @click="activeTab = 1" :class="activeTab == 1 ? 'active-left-tab' : ''" class="tab-item">
            人员列表
          </div>
          <div @click="activeTab = 2" :class="activeTab == 2 ? 'active-right-tab' : ''" class="tab-item">
            常用人员
          </div>
        </div>

        <el-divider></el-divider>

        <div class="search-box">
          <div class="search-input">
            <el-input class="wp-input" v-model="searchContent" clearable placeholder="请输入搜索的内容">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>

        <div class="tree">
          <PeopleTtee :isEdit="true" ref="peopleRef" :defaultCheckedKeys.sync="activePeople"
            :defaultExpanded="defaultExpanded" :peopleList="peopleList" :searchContent="searchContent"
            @handleCheck="handleCheck" @addCommonUser="addCommonUser" v-show="activeTab == 1"></PeopleTtee>

          <!-- 常用人员 -->
          <PeopleTtee :isEdit="true" ref="commonRef" :defaultExpanded="defaultExpandedCommon" type="stock"
            :defaultCheckedKeys.sync="activeStockPeopleIds" :searchContent="searchContent" @handleCheck="handleCheck"
            @removeCommonUser="removeCommonUser" :peopleList="commonPeople" v-show="activeTab == 2" nodeKey="commonId"></PeopleTtee>
        </div>
      </div>

      <div class="select-people">
        <div class="flex-CB fs-14 head">
          <div>
            已选择分组或人员（
            <span class="wp-blue">{{ list.length }}</span> 人）
          </div>
          <div class="flex-Acenter">
            <!-- <div class="flex-Acenter clear wp-blue" @click="clearAll">
              <i class="icon-shanchu"></i>
              <div class="fs-14 wp-blue">清空所有</div>
            </div> -->
          </div>
        </div>

        <el-scrollbar class="people">
          <div class="list">
            <div class="list-item" v-for="(item, index) in list" :key="item.id">
              <el-tooltip class="item" effect="dark" :tabindex="index + 1000" :disabled="!isShowTooltipWidth"
                :content="item.name" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine wp-center flex-3">
                  {{ item.name }}
                </div>
              </el-tooltip>
              <div>{{ $utils.replacePhone(item.mobile) }}</div>
              <el-tooltip class="item" effect="dark" :tabindex="index + 1000" :disabled="!isShowTooltipWidth"
                :content="item.groupName" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine wp-center flex-3">
                  {{ item.groupName }}
                </div>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :tabindex="index" :disabled="!isShowTooltipWidth"
                :content="item.custName" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine" style="flex: 3">
                  {{ item.custName }}
                </div>
              </el-tooltip>
              <div class="icon">
                <i v-if="!item.disabled" @click="tableRemovePeople(item, index)" class="el-icon-error"></i>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <div class="wp-task-card mt-16 wp-center">
      <el-button @click="cancel" class="wp-button">取消</el-button>
      <el-button style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="submit">确定
      </el-button>
      <el-checkbox v-model="sendMsg" style="margin-left: 25px">
        短信通知（余量：<span class="wp-blue">{{ messageNum }}</span>
        ）</el-checkbox>
    </div>

    <div style="height: 16px"></div>
  </div>
</template>

<script>
import PeopleTtee from '@/views/components/peopleTree'
import { mapGetters } from 'vuex'
import taskApi from '@/api/task.js'
import reportedApi from '@/api/reported.js'
export default {
  components: { PeopleTtee },
  data () {
    return {
      activeTab: 1,
      searchContent: "",
      lastSend: "",
      list: [],
      peopleList: [], // 人员列表
      commonPeople: [], // 常用人员
      activePeople: [],
      activeStockPeople: [],
      activePeopleIds: [], // 选中人员Id集合
      sendMsg: false,
      taskId: "", // 任务Id
      userIdList: [], // 人员Id
      initIds: [], // 上一次选中的人员
      messageNum: false, // 短信余量
      defaultExpanded: [], // 一级分组Id
      isShowTooltipWidth: false,
      loading: false,
      activeStockPeopleIds:[],
      defaultExpandedCommon:[]
    }
  },

  async created () {
    if (this.$route.query.taskId) {
      // 任务Id
      this.taskId = this.$route.query.taskId


      // 获取人员列表
      await this.getAllPeopleTree()
      // 获取常用人员列表
      await this.getCommonUserTree()
      // 详情
      await this.getDetail()
      // 获取短信余量
      await this.getSmsNum()
      // 获取单位配置
      this.getConfig()
    }
  },

  watch: {
    // 切换tab 清空搜索内容
    activeTab: {
      handler (newVal, oldVal) {
        this.searchContent = ""
        // 获取常用人员列表
        this.getCommonUserTree(1)
      }
    },
    activeStockPeople:{
      handler (newVal, oldVal) {
         this.activeStockPeopleIds =[...new Set(this.getActiveStockPeopleIds(this.commonPeople))]  
      }
    }
  },

  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      console.log(event);
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    // 获取单位默认参数
    async getConfig () {
      const res = await taskApi.getConfigList({})
      if (res.returnCode == 200) {
        this.sendMsg = +res.returnData.taskSmsNotice?.[0].configValue ? true : false
      }
    },

    /** 获取短信余量 */
    async getSmsNum () {
      const res = await taskApi.getSmsNum({})
      if (res.returnCode == 200) {
        this.messageNum = res.returnData
      }
    },

    /** 清除所选人员 */
    clearAll () {
      this.list = this.list.filter(item => {
        if (item.disabled) {
          console.log(item);
          return item
        }
      })
      this.activePeople = this.list.map(item => item.id)
      this.activeStockPeople = this.activePeople
    },

    /** 获取详情 */
    async getDetail () {
      const res = await taskApi.taskReceiverList({ taskId: this.taskId })
      if (res.returnCode == 200) {
        const peopleIds = res.returnData.reduce((prev, curr) => {
          prev.push(curr.receiverId)
          return prev
        }, [])

        console.log(peopleIds);
        this.activePeople = await peopleIds
        this.activeStockPeople = await peopleIds
        this.initIds = await peopleIds
        await this.getList()
      }
    },

    /** 取消 */
    cancel () {
      this.$router.push('/taskManagement')
    },

    /** 提交 */
    async submit () {
      // 所选中人员信息列表
      const list = this.getUserIdList()
      // 去除初始选中人员
      const userIds = this.copare([...this.activePeople, ...this.initIds])
      // 已选中人员信息
      const userList = []
      list.forEach(item => {
        userIds.forEach(value => {
          if (item.id == value) {
            userList.push(item)
          }
        })
      })
      const query = {
        taskId: this.taskId,
        userList,
        sendMsg: this.sendMsg
      }
      console.log(query);
      if (userList.length) {
        try {
          if (this.loading) return
          this.loading = true
          const res = await taskApi.insertReceiverPeople(query)
          this.loading = false
          if (res.returnCode == 200 || res.returnCode == 10086) {
            if (res.returnCode == 10086) {
              this.$showMessage({ type: 'warning', msg: res.returnMsg })
            } else {
              this.$showMessage({ type: 'success', msg: '操作成功' })
            }
            this.$router.push("/taskManagement")
          }
        } catch (error) {
          this.loading = false
        }

      } else {
        this.$showMessage({ type: "error", msg: '请选择追加人员' })
      }
    },

    /** 表格中移除人员 */
    tableRemovePeople (item, index) {
      if (!item.disabled) {
        this.list.splice(index, 1)
        const idx = this.activePeople.findIndex(value => item.id == value)
        this.activePeople.splice(idx, 1)
      }
    },

    /** 获取全部人员列表 */
    async getAllPeopleTree () {
      const res = await taskApi.groupUserTree({ type: 1, id: this.taskId })
      if (res.returnCode == 200) {
        this.peopleList = res.returnData
        this.defaultExpanded = [res?.returnData[0]?.id]
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 获取常用人员列表 */
    async getCommonUserTree (flag = 0) {
      if (!flag) {
        this.$nextTick(() => {
          this.$refs.commonRef.setDefaultExpandedKeys()
        })
      }

      // 常用人员
      const res = await taskApi.commonUserTree({ type: 1, id: this.taskId })
      if (res.returnCode == 200) {
        this.commonPeople = res.returnData
        this.defaultExpandedCommon = [res?.returnData[0]?.commonId]
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 添加常用人员 */
    async addCommonUser (receiverIds) {
      const res = await taskApi.saveCommonUser({ receiverIds })
      if (res.returnCode == 200) {
        this.getCommonUserTree()
        this.$showMessage({ type: 'success', msg: '加入成功' })
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 移除常用人员 */
    async removeCommonUser (receiverIds) {
      const res = await taskApi.deleteCommonUser({ receiverIds })
      if (res.returnCode == 200) {
        this.getCommonUserTree()
        this.$showMessage({ type: 'success', msg: '移除成功' })
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 获取选中人员数据 */
    getUserIdList () {
      console.log(this.activePeopleIds)
      if (this.activePeopleIds) {
        let list = []
        return list = this.activePeopleIds.reduce((prev, curr) => {
          const obj = {}
          obj.id = curr.id
          obj.pid = curr.pid
          prev.push(obj)
          return prev
        }, [])
      } else {
        this.$showMessage({ type: 'err', message: '请选择人员' })
      }
    },

    /** 获取右侧人员列表信息 */
    getList () {
      this.$nextTick(() => {
        // 人员列表
        const peopleList = this.$refs.peopleRef.getCurrentNode().filter(item => {
          if (!item.group) {
            return item
          }
        })
        this.activePeopleIds = peopleList
        this.list = peopleList
      })
    },

    /** 人员列表 */
    async handleCheck (obj) {
      if (!obj.checked) {
        // 保留只出现一次的值
        const activePeople = this.copare([...obj.deleteId, ...this.activePeople])
        this.activePeople = activePeople
        this.activeStockPeople = activePeople
      } else {
        const arr = [...new Set([...obj.peopleIds, ...this.activePeople])]
        // 全部人员 已选中Id列表
        this.activePeople = arr
        // 常用人员 已选中Id列表
        this.activeStockPeople = arr
      }

      await this.getList()

      const num = await this.list.filter(item => !item.disabled)?.length || 0
      // 短信余量小于选中人员数量
      if (this.messageNum < num) {
        this.sendMsg = false
      }
    },

    // 只保留出现一次的值
    copare (arr) {
      console.log(arr);
      let list = []
      for (let i = 0; i < arr.length; i++) {
        let num = 0
        for (let j = 0; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            num++
          }
        }
        if (num <= 1) {
          list.push(arr[i])
        }
      }
      return list
    },
    // 根据选中的常用人员的ids集合，得出commonId集合
    getActiveStockPeopleIds(array){
      let newArray = []
      array.forEach(element => {
        if(this.activeStockPeople.indexOf(element.id) != -1){
          newArray.push(element.commonId)
        }
        if (element.children && element.children.length > 0) {
          // 子集不为空   继续遍历子集 并存储遍历后的子集
          newArray = [...newArray,...this.getActiveStockPeopleIds(element.children)]
        } 
      });
      return newArray
    }
  },
}
</script>

<style lang="scss" scoped>@import "./index.scss";</style>