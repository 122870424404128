var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "wp-task-card mt-16" },
      [
        _c("div", { staticClass: "card-title fs-16 fw-bold" }, [
          _vm._v("专题信息"),
        ]),
        _c("BasicsForm", {
          ref: "basicsForm",
          staticClass: "center",
          attrs: { topicTypeId: _vm.topicTypeId },
          on: { platformScore: _vm.changePlatformScore },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "wp-task-card mt-16",
        style: { height: _vm.foldFlag ? "264px" : "74px" },
      },
      [
        _c("div", { staticClass: "card-title flex-CB" }, [
          _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("高级选项")]),
          _c(
            "div",
            {
              staticClass: "wp-blue toggle",
              on: {
                click: function ($event) {
                  _vm.foldFlag = !_vm.foldFlag
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(!_vm.foldFlag ? "展开" : "收起"))]),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.foldFlag,
                    expression: "foldFlag",
                  },
                ],
                staticClass: "el-icon-arrow-up fs-14 wp-blue",
                staticStyle: { "margin-left": "6px" },
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.foldFlag,
                    expression: "!foldFlag",
                  },
                ],
                staticClass: "el-icon-arrow-down fs-14 wp-blue",
                staticStyle: { "margin-left": "6px" },
              }),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "footer",
            style: { display: _vm.foldFlag ? "block" : "none" },
          },
          [
            _c(
              "div",
              { staticClass: "layout-content" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "advanced-form",
                    attrs: { "label-width": "100px" },
                  },
                  _vm._l(_vm.advancedSetting2, function (item) {
                    return _c(
                      "el-form-item",
                      {
                        key: item.custConfigId,
                        attrs: { label: item.configName + "：" },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: item.isDisbled },
                            on: {
                              change: function ($event) {
                                return _vm.checkboxChangeShow2(item)
                              },
                            },
                            model: {
                              value: item.isShow,
                              callback: function ($$v) {
                                _vm.$set(item, "isShow", $$v)
                              },
                              expression: "item.isShow",
                            },
                          },
                          [_vm._v("显示 ")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: item.isDisbledRequired },
                            on: {
                              change: function ($event) {
                                return _vm.checkboxChangeRequired2(item)
                              },
                            },
                            model: {
                              value: item.isRequired,
                              callback: function ($$v) {
                                _vm.$set(item, "isRequired", $$v)
                              },
                              expression: "item.isRequired",
                            },
                          },
                          [_vm._v(" 必填 ")]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }