<template>
  <div class="homeAdmin">
    <div class="layout-content">
      <el-scrollbar style="height: 100%">
        <div class="home-admin-content">
          <!-- 数据总览 -->
          <div class="title-header">
            <div class="title-header-left home-title">数据总览</div>
            <div class="title-header-right">
              <div class="label">任务发布时间:</div>
              <div class="wp-date-picker" style="width: 312px">
                <el-date-picker
                  :disabled="isMLS"
                  v-model="releaseTimeValue"
                  @change="changeDaterange"
                  value-format="yyyy-MM-dd"
                  size="small"
                  prefix-icon=""
                  clearable
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>

          <div class="layout-mid">
            <div class="percent-w50">
              <div class="wp-moudle-style layout-mid-left flex-center">
                <div class="left-item flex-center">
                  <img src="~@/assets/images/home/zongRenShu.png" alt="" />
                  <div class="left-item-text">
                    <p>
                      {{
                        groupCountObj.allUserCount
                          ? groupCountObj.allUserCount
                          : 0
                      }}
                    </p>
                    <span>总人数</span>
                  </div>
                </div>
                <div class="left-item flex-center">
                  <img src="~@/assets/images/home/duiWu.png" alt="" />
                  <div class="left-item-text">
                    <p>
                      {{
                        groupCountObj.groupCount ? groupCountObj.groupCount : 0
                      }}
                    </p>
                    <span>队伍数</span>
                  </div>
                </div>
                <div class="left-item flex-center">
                  <img src="~@/assets/images/home/dengLuRenShu.png" alt="" />
                  <div class="left-item-text">
                    <p>
                      {{
                        groupCountObj.todayLoginUserCount
                          ? groupCountObj.todayLoginUserCount
                          : 0
                      }}
                    </p>
                    <span>今日登录人数</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="percent-w50-right">
              <div class="wp-moudle-style layout-mid-right">
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.taskNum
                          ? wpTaskDescribeObj.taskNum
                          : 0
                      }}
                    </p>
                    <span>投稿数</span>
                  </div>

                  <img src="~@/assets/images/home/luJing.png" alt="" />
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.totalScore
                          ? wpTaskDescribeObj.totalScore
                          : 0
                      }}
                    </p>
                    <span>投稿积分数</span>
                  </div>
                  <img src="~@/assets/images/home/luJing.png" alt="" />
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.completionNum
                          ? wpTaskDescribeObj.completionNum
                          : 0
                      }}
                    </p>
                    <span>完成人数</span>
                  </div>
                  <img src="~@/assets/images/home/luJing.png" alt="" />
                </div>
                <div class="right-item">
                  <div class="right-item-text">
                    <p v-if="isMLS">91.8%</p>
                    <p v-else>
                      {{
                        wpTaskDescribeObj.completionRate
                          ? wpTaskDescribeObj.completionRate
                          : 0
                      }}%
                    </p>
                    <span>完成率</span>
                  </div>
                  <img src="~@/assets/images/home/luJing.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 最新任务 -->
          <div class="new-task wp-moudle-style">
            <div class="home-title">最新投稿</div>
            <div
              class="wp-table new-task-table"
              v-loading="newTaskLoading"
              element-loading-spinner="el-icon-loading"
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column
                  header-align="left"
                  align="left"
                  width="20"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="任务标题"
                  header-align="left"
                  align="left"
                  min-width="150"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="websiteName"
                  label="任务站点"
                  align="center"
                  show-overflow-tooltip
                  min-width="120"
                >
                </el-table-column>
                <el-table-column
                  prop="modeStyleValue"
                  label="任务方式"
                  min-width="120"
                  align="left"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="seedNum"
                  label="发送人数"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="receiveNum"
                  label="接收人数"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="completeNum"
                  label="完成人数"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="完成情况"
                  min-width="120"
                  align="center"
                >
                  <template slot-scope="scope">
                    <a
                      href="javascript:;"
                      style="color: #0064e6"
                      @click="goTaskDetail(scope.row)"
                      >详情</a
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div
                class="wp-noData"
                v-if="tableData.length == 0 && !newTaskLoading"
              >
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
          <!-- 任务方式分布 任务分类占比 任务站点占比 -->
          <div class="layout-mid2">
            <div class="percent-w33-left">
              <div
                class="wp-moudle-style mid2-item mid2-item1"
                v-loading="itemLoading1"
                element-loading-spinner="el-icon-loading"
              >
                <div class="item-title home-title">投稿方式分布</div>
                <div class="item-content">
                  <bar-chart
                    ref="barChart"
                    hoverTitle="任务数量"
                    v-if="taskXAxisData.length > 0"
                    id="barChart"
                    :maxXAxisLength="2"
                    :xAxisData="taskXAxisData"
                    :yAxisData="taskYAxisData"
                  >
                  </bar-chart>
                  <div
                    class="wp-noData"
                    v-if="taskXAxisData.length == 0 && !itemLoading1"
                  >
                    <img src="~@/assets/images/noData.png" alt="" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="percent-w33-mid">
              <div
                class="wp-moudle-style mid2-item mid2-item1"
                v-loading="itemLoading2"
                element-loading-spinner="el-icon-loading"
              >
                <div class="item-title home-title">投稿分类占比</div>
                <div class="item-content pad20">
                  <pie-chart
                    ref="pieChart"
                    id="pieChart"
                    :pieData="taskPieData"
                  >
                  </pie-chart>
                </div>
              </div>
            </div>
            <div class="percent-w34-right">
              <div
                class="wp-moudle-style mid2-item"
                v-loading="itemLoading3"
                element-loading-spinner="el-icon-loading"
              >
                <div class="item-title home-title">投稿站点占比</div>
                <div class="item-content item-content-zhandian">
                  <progress-list :dataList="progressDataList"></progress-list>
                </div>
              </div>
            </div>
          </div>

          <!-- 队伍人数占比 星级占比 -->
          <div class="layout-mid3">
            <div class="percent-w50">
              <div
                class="wp-moudle-style mid3-item"
                v-loading="itemLoading4"
                element-loading-spinner="el-icon-loading"
              >
                <div class="item-title home-title">队伍人数占比</div>
                <div class="item-content pad20">
                  <nightingale-chart
                    ref="nightChart"
                    id="nightChart"
                    :pieData="taskPieData2"
                  >
                  </nightingale-chart>
                </div>
              </div>
            </div>
            <div class="percent-w50-right">
              <div
                class="wp-moudle-style mid3-item"
                v-loading="itemLoading5"
                element-loading-spinner="el-icon-loading"
              >
                <div class="item-title home-title">星级占比</div>
                <div class="item-content pad20">
                  <pie-big-chart
                    ref="pieBigChart"
                    id="pieBigChart"
                    :pieData="taskPieData3"
                  >
                  </pie-big-chart>
                </div>
              </div>
            </div>
          </div>
          <!-- 队伍积分排行 人员积分排行 -->
          <div class="layout-mid4">
            <div class="percent-w50">
              <div class="wp-moudle-style mid4-item">
                <div class="item-title home-title item-title-flex">
                  <div>队伍积分排行</div>
                  <div>
                    <el-button-group>
                      <el-button
                        size="small"
                        :disabled="itemLoading6"
                        class="wp-button-group wp-button-group-left"
                        :class="{ active: typeTaskId1 == 1 }"
                        @click="togTypeTaskId1(1)"
                      >
                        投稿类
                      </el-button>
                      <el-button
                        size="small"
                        :disabled="itemLoading6"
                        class="wp-button-group"
                        :class="{ active: typeTaskId1 == 2 }"
                        @click="togTypeTaskId1(2)"
                        >上报类
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
                <div class="item-content pad20">
                  <div
                    class="wp-table wp-table-border"
                    v-loading="itemLoading6"
                    element-loading-spinner="el-icon-loading"
                  >
                    <el-table :data="tableData2" stripe style="width: 100%">
                      <el-table-column
                        prop="date"
                        label="排行"
                        width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + jinPai + ');'"
                            v-if="scope.row.index == 1"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + yinPai + ');'"
                            v-else-if="scope.row.index == 2"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + dong + ');'"
                            v-else-if="scope.row.index == 3"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span style="color: #faa548" v-else>{{
                            scope.row.index
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label=""
                        align="left"
                        header-align="left"
                        min-width="80"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="groupName"
                        label="队伍"
                        align="left"
                        header-align="left"
                        min-width="100"
                      >
                        <template slot-scope="scope">
                          <div class="personnel-row">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="scope.row.groupName"
                              placement="top"
                              :disabled="scope.row.groupName.length <= 8"
                            >
                              <span>{{ scope.row.groupNameShort }}</span>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="score"
                        label="总积分"
                        align="center"
                        width="156"
                      >
                      </el-table-column>
                    </el-table>
                    <div
                      class="wp-noData"
                      v-if="tableData2.length == 0 && !itemLoading6"
                    >
                      <img src="~@/assets/images/noData.png" alt="" />
                      <p>暂无数据</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="percent-w50-right">
              <div class="wp-moudle-style mid4-item">
                <div class="item-title home-title item-title-flex">
                  <div>人员积分排行</div>
                  <div>
                    <el-button-group>
                      <el-button
                        size="small"
                        :disabled="itemLoading7"
                        class="wp-button-group wp-button-group-left"
                        :class="{ active: typeTaskId2 == 1 }"
                        @click="togTypeTaskId2(1)"
                      >
                        投稿类
                      </el-button>
                      <el-button
                        size="small"
                        :disabled="itemLoading7"
                        class="wp-button-group"
                        :class="{ active: typeTaskId2 == 2 }"
                        @click="togTypeTaskId2(2)"
                        >上报类
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
                <div class="item-content pad20">
                  <div
                    class="wp-table wp-table-border"
                    v-loading="itemLoading7"
                    element-loading-spinner="el-icon-loading"
                  >
                    <el-table :data="tableData3" stripe style="width: 100%">
                      <el-table-column
                        prop="date"
                        label="排行"
                        width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + jinPai + ');'"
                            v-if="scope.row.index == 1"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + yinPai + ');'"
                            v-else-if="scope.row.index == 2"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span
                            class="bgIndex"
                            :style="'background-image: url(' + dong + ');'"
                            v-else-if="scope.row.index == 3"
                          >
                            {{ scope.row.index }}
                          </span>
                          <span style="color: #faa548" v-else>{{
                            scope.row.index
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label=""
                        align="left"
                        header-align="left"
                        min-width="80"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="userName"
                        label="姓名"
                        align="left"
                        header-align="left"
                        min-width="100"
                      >
                        <template slot-scope="scope">
                          <div class="personnel-row">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="scope.row.userName"
                              placement="top"
                              :disabled="scope.row.userName.length <= 8"
                            >
                              <span>{{ scope.row.userNameShort }}</span>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="score"
                        label="总积分"
                        align="center"
                        width="156"
                      >
                      </el-table-column>
                    </el-table>
                    <div
                      class="wp-noData"
                      v-if="tableData3.length == 0 && !itemLoading7"
                    >
                      <img src="~@/assets/images/noData.png" alt="" />
                      <p>暂无数据</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BarChart from '@/components/echartsComponents/barChart.vue';
import PieChart from '@/components/echartsComponents/pieChart.vue';
import PieBigChart from '@/components/echartsComponents/pieBigChart.vue';
import NightingaleChart from '@/components/echartsComponents/nightingaleChart.vue';
import ProgressList from '@/views/homeAdmin/components/progressList.vue';
import * as homeAdminApi from '@/api/homeAdmin.js';
export default {
  name: 'panel',
  components: { BarChart, PieChart, ProgressList, NightingaleChart, PieBigChart },
  data () {
    return {
      releaseTimeValue: null,// 任务发布时间
      wpTaskDescribeObj: {},// 任务汇总 对象
      tableData: [],
      tableData2: [],
      tableData3: [],
      newTaskLoading: false,// 表格加载
      taskXAxisData: [],
      taskYAxisData: [],
      // taskXAxisData: ['转发微博', '转发朋友圈', '评论', '点赞', '举报', '其他'],
      // taskYAxisData: [60, 50, 90, 100, 50, 40],
      taskPieData: [

      ],
      taskPieData2: [
        // {
        // 	value: 335,
        // 	name: '微信长的名字长的名字长的名字长的名字长的名字长的名字2',
        // },

      ],
      taskPieData3: [
        // {
        // 	value: 335,
        // 	name: '微信长的名字长的名字长的名字长的名字长的名字长的名字17',
        // },
        // {

      ],
      progressDataList: [
        // { id: 1, num: 50, name: "转发微博" }

      ],
      itemLoading1: false,
      itemLoading2: false,
      itemLoading3: false,
      itemLoading4: false,
      itemLoading5: false,
      itemLoading6: false,
      itemLoading7: false,
      groupCountObj: {},// 人员信息
      jinPai: require('../../assets/images/home/jinPai.png'),
      yinPai: require('../../assets/images/home/yinPai.png'),
      dong: require('../../assets/images/home/dong.png'),
      typeTaskId1: 1,//1.任务类 2.上报类
      typeTaskId2: 1,//1.任务类 2.上报类
      isMLS: false,
    };
  },
  props: {},
  created () {
  },
  mounted () {
    console.log(this.userInfo)
    // < !--默认本年 -->
    this.releaseTimeValue = [this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'), this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')]
    if(this.isGZ){
      this.releaseTimeValue = [this.$moment('2023/01/01').format('YYYY-MM-DD 00:00:00'),this.$moment().format('YYYY-MM-DD 23:59:59')]
    }

    this.getGroupCount()
    this.changeDaterange()
    this.getWpTaskPageList()
    this.getUserStarLevelCount()
    this.getGroupUserCount()
    this.isMLS = this.userInfo?.custId == 37 ? true : false
  },
  computed: {
    ...mapGetters(['userInfo','sysCust']),
    // 是否赣州
    isGZ(){
      return this.sysCust.custId == 2
    }

  },
  methods: {
    // 任务发布时间更改
    changeDaterange () {
      // 获取 新的数据
      this.getWpTaskDescribe()
      this.getWpTaskAnalysisStyle()
      this.getWpTaskAnalysisType()
      this.getWpTaskAnalysisWebsite()
      this.getGroupSummary()
      this.getMemberSummary()
    },
    // 统计分析-汇总信息
    async getWpTaskDescribe () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      // console.log(query)
      try {
        let res = await homeAdminApi.wpTaskDescribe(query)
        if (res.returnCode != 200) return
        this.wpTaskDescribeObj = res.returnData
      } catch (error) {
        console.log(error);
      }
    },
    // 任务-列表
    async getWpTaskPageList () {
      // 展示为最新的5次任务，倒序排列。
      // 完成情况：点击“详情”，进入该任务的详情页。

      let query = {
        page: 1,
        limit: 5
      }
      if(this.isGZ){
        query.limit = 50
      }

      // console.log(query)
      this.newTaskLoading = true
      try {
        let res = await homeAdminApi.wpTaskPageList(query)
        this.newTaskLoading = false
        if (res.returnCode != 200) return
        res.returnData.data.forEach((item) => {
          item.modeStyleValue = item.modeStyleList.map(it => it.dictKey).join(',')
          item.seedNum = item.taskMainSituation?.seedNum
          item.receiveNum = item.taskMainSituation?.receiveNum
          item.completeNum = item.taskMainSituation?.completeNum
        })
        this.tableData = res.returnData.data
        if(this.isGZ){
          // 最新投稿处不显示GZ15401-15430任务
          let taskNumbers = []
          for(let i = 1;i<=30;i++){
            taskNumbers.push(`GZ154${i.toString().padStart(2,'0')}`)
          }
          console.log('taskNumbers',taskNumbers)
          this.tableData = res.returnData.data.filter(it=>taskNumbers.indexOf(it.taskNumber) == -1).slice(0, 5);  
        }

        console.log(this.tableData)
      } catch (error) {
        this.newTaskLoading = false
        console.log(error);
      }
    },
    // 任务详情
    goTaskDetail (item) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          taskId: this.$encrypt(item.taskId)
        }
      })
    },
    // 统计分析-任务方式
    async getWpTaskAnalysisStyle () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      // console.log(query)
      this.itemLoading1 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisStyle(query)
        this.itemLoading1 = false
        if (res.returnCode != 200) return
        // 截取前7个 
        let datas = res.returnData.splice(0, 7)
        // taskXAxisData
        this.taskXAxisData = datas.map(item => item.name)
        // taskYAxisData
        this.taskYAxisData = datas.map(item => item.total)
        this.$nextTick(() => {
          if (this.$refs.barChart) {
            this.$refs.barChart.setData()
          }

        })
      } catch (error) {
        this.itemLoading1 = false
        console.log(error);
      }
    },
    // 统计分析-分类
    async getWpTaskAnalysisType () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      // console.log(query)
      this.itemLoading2 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisType(query)
        this.itemLoading2 = false
        if (res.returnCode != 200) return
        this.taskPieData = res.returnData.map(item => { return { value: item.total, name: item.name } })
        this.$nextTick(() => {
          if (this.$refs.pieChart) {
            this.$refs.pieChart.setData()
          }

        })
      } catch (error) {
        this.itemLoading2 = false
        console.log(error);
      }
    },
    // 统计分析-站点
    async getWpTaskAnalysisWebsite () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      // console.log(query)
      this.itemLoading3 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisWebsite(query)
        this.itemLoading3 = false
        if (res.returnCode != 200) return
        const totalCount = eval(res.returnData.map(it => Number(it.total)).join("+"))
        // 计算百分比　截取前８个
        let progressDataList = []
        res.returnData.forEach((item, index) => {
          if (index <= 7) {
            let obj = { id: index, name: item.name, total: item.total, num: totalCount > 0 ? Number(this.$utils.changeDecimalToPercentage(item.total / totalCount)) : 0 }
            progressDataList.push(obj)
          }
        })
        this.progressDataList = progressDataList

      } catch (error) {
        this.itemLoading3 = false
        console.log(error);
      }
    },
    // 星级占比
    async getUserStarLevelCount () {
      this.itemLoading5 = true
      try {
        let res = await homeAdminApi.userStarLevelCount({})
        this.itemLoading5 = false
        if (res.returnCode != 200) return
        this.taskPieData3 = []
        this.taskPieData3 = res.returnData.map(item => { return { value: item.value, name: item.key } })
        if(this.isGZ){
          this.taskPieData3 = res.returnData.map(item => { 
            if(item.key == "零星"){
              item.value = 5
            }else{
              item.value =  item.value + 3
            }
            return { value: item.value, name: item.key } 
          
          })
        }

        this.$nextTick(() => {
          if (this.$refs.pieBigChart) {
            this.$refs.pieBigChart.setData()
          }
          // this.$refs.pieBigChart.setData()
        })
      } catch (error) {
        this.itemLoading5 = false
        console.log(error);
      }
    },
    // 队伍人数占比
    async getGroupUserCount () {
      this.itemLoading4 = true
      try {
        // type 1为数量；2为总分数；3为本年总分数；排序规则为倒序
        let res = await homeAdminApi.groupUserCount({ type: 1 })
        this.itemLoading4 = false
        if (res.returnCode != 200) return
        //只显示人员数量前17的组，剩下的组分到其他组。 人数为0的组不显示。
        this.taskPieData2 = []
        this.taskPieData2 = res.returnData.map(item => { return { value: item.userCount, name: item.groupName } })
        this.$nextTick(() => {
          if (this.$refs.nightChart) {
            this.$refs.nightChart.setData()
          }
        })
      } catch (error) {
        this.itemLoading4 = false
        console.log(error);
      }
    },
    // 获取首页所有用户，队伍数，今日登录人数
    async getGroupCount () {
      try {
        let res = await homeAdminApi.groupCount({})
        if (res.returnCode != 200) return
        this.groupCountObj = res.returnData
      } catch (error) {
        console.log(error);
      }
    },
    // 首页-分组积分汇总排行
    async getGroupSummary () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : '',
        type: this.typeTaskId1 //1投稿中心 2上报中心
      }
      this.itemLoading6 = true
      try {
        let res = await homeAdminApi.groupSummary(query)
        this.itemLoading6 = false
        if (res.returnCode != 200) return
        res.returnData.forEach(item => {
          item.groupNameShort = this.$utils.sliceMiddle(item.groupName)
        })
        this.tableData2 = res.returnData.map((item, index) => { return { ...item, index: index + 1 } }).splice(0, 10)
      } catch (error) {
        this.itemLoading6 = false
        console.log(error);
      }
    },
    // 首页-人员积分汇总排行
    async getMemberSummary () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : '',
        type: this.typeTaskId2 //1投稿中心 2上报中心
      }
      this.itemLoading7 = true
      try {
        let res = await homeAdminApi.memberSummary(query)
        this.itemLoading7 = false
        if (res.returnCode != 200) return
        res.returnData.forEach(item => {
          item.userNameShort = this.$utils.sliceMiddle(item.userName)
        })
        this.tableData3 = res.returnData.map((item, index) => { return { ...item, index: index + 1 } }).splice(0, 10)
      } catch (error) {
        this.itemLoading7 = false
        console.log(error);
      }
    },
    // 切换 分组积分汇总排行  类型 1投稿中心 2上报中心
    togTypeTaskId1 (id) {
      this.typeTaskId1 = id
      this.getGroupSummary()
    },
    // 切换 人员积分汇总排行  类型 1投稿中心 2上报中心
    togTypeTaskId2 (id) {
      this.typeTaskId2 = id
      this.getMemberSummary()
    }
  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import './css/homeAdmin.scss';
</style>
