<template>
  <wp-dialog @cancel="cancel" :title="title" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="commonName" label="" :rules="{ required: true, message: placeholder, trigger: 'blur' }">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.commonName" :placeholder="placeholder" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { wpCommonGroupInsert,updateCommonGroup } from '@/api/personnelTeam.js'
export default {
  name: 'addGroup',
  components: {},
  data () {
    return {
      title: "添加分组",// 添加分组，添加子分组，修改名称
      placeholder: '请输入分组名称',//输入分组名称,输入子分组名称
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        commonName: ""
      },
      rules: {
        
      },
      isPosting: false

    };
  },
  props: {
    currentData: {
      type: Object,
      default: ()=>{}
    },
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.currentData && this.currentData.commonId) {
        this.isEidt = true
        // 修改子分组名称
        this.title = "修改分组"
        this.placeholder = "请输入分组名称"
        this.ruleForm.commonName = this.currentData.commonName
      }  else {
        // 添加分组
        this.title = "添加分组"
        this.placeholder = "请输入分组名称"
      }
    },
    // 创建 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let query = {
        ...this.ruleForm
      }
      let res = null
      try {
        if (this.isEidt) {
          query.commonId = this.currentData.commonId
          res = await updateCommonGroup(query)
        } else {
          res = await wpCommonGroupInsert(query)
        }
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
</style>