<template>
  <div class="wp-w100">
    <div class="main">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="wp-breadcrumb"
      >
        <el-breadcrumb-item :to="{ path: '/reportedManage' }"
          >上报管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>编辑专题</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 基础设置 -->
      <div class="wp-task-card mt-16">
        <div class="card-title fs-16 fw-bold">专题信息</div>
        <BasicsForm
          :formData="formData"
          class="center"
          ref="basicsForm"
          @platformScore="changePlatformScore"
          @submitBasicsForm="submitBasicsForm"
        >
        </BasicsForm>
      </div>

      <!-- 高级选项 -->
      <div
        class="wp-task-card mt-16"
        :style="{ height: foldFlag ? '264px' : '74px' }"
      >
        <div class="card-title flex-CB">
          <div class="fs-16 fw-bold">高级选项</div>
          <div class="wp-blue toggle" @click="foldFlag = !foldFlag">
            <span>{{ !foldFlag ? '展开' : '收起' }}</span>
            <i
              v-show="foldFlag"
              class="el-icon-arrow-up fs-14 wp-blue"
              style="margin-left: 6px"
            ></i>
            <i
              v-show="!foldFlag"
              class="el-icon-arrow-down fs-14 wp-blue"
              style="margin-left: 6px"
            ></i>
          </div>
        </div>

        <div class="footer" :style="{ display: foldFlag ? 'block' : 'none' }">
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item
                :label="item.configName + '：'"
                v-for="item in advancedSetting2"
                :key="item.custConfigId"
              >
                <el-checkbox
                  v-model="item.isShow"
                  :disabled="item.isDisbled"
                  @change="checkboxChangeShow2(item)"
                  >显示
                </el-checkbox>
                <el-checkbox
                  v-model="item.isRequired"
                  :disabled="item.isDisbledRequired"
                  @change="checkboxChangeRequired2(item)"
                  >必填
                </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="wp-task-card mt-16 wp-center">
        <el-button @click="cancel" class="wp-button">取消</el-button>
        <el-button
          style="margin-left: 24px"
          class="wp-button"
          v-preventReClick
          type="primary"
          @click="editToptic"
          >确定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import reportedApi from '@/api/reported'

import BasicsForm from './form/basicsForm' // 基础设置
export default {
  components: { BasicsForm },
  data () {
    return {
      checked: false,
      foldFlag: true,
      advancedSetting2: [], // 高级设置
      topicId: "", // 专题Id
      formData: {}, // 表单数据
      configData: {},
      oldScore: "",
      lastScore: ""
    }
  },
  created () {
    console.log(this.$route.query.topicId)
    if (this.$route.query.topicId) {
      this.topicId = this.$route.query.topicId
      this.getDetail()
    }

  },
  methods: {
    // 编辑专题
    async editToptic () {
      const formData = { ...this.$refs['basicsForm'].getFormData() }
      if (formData.timeSection) {
        console.log(formData);
        if (new Date(formData.endTime).getTime() < Date.now()) return this.$showMessage({ type: 'warning', msg: '专题结束时间不能小于当前时间', duration: 2000 })

        //上报中心-高级设置
        this.advancedSetting2.forEach(item => {
          item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
        })

        this.advancedSetting2.forEach(value => {

          switch (value.configName) {
            case "作品标题":
              formData.parmTitle = value.configValue
              break;
            case "作品内容":
              formData.parmContent = value.configValue
              break;
            case "作品链接":
              formData.parmUrl = value.configValue
              break;
            case "媒体平台":
              formData.parmMedia = value.configValue
              break;
            case "发布类型":
              formData.parmPublishType = value.configValue
              break;
            case "作者类别":
              formData.parmAuthorType = value.configValue
              break;
            case "作者姓名":
              formData.parmAuthor = value.configValue
              break;
            case "网名/昵称":
              formData.parmNetName = value.configValue
              break;
            case "阅读数量":
              formData.parmReadNum = value.configValue
              break;
            case "转载数量":
              formData.parmReprint = value.configValue
              break;
            case "全网推送":
              formData.parmPush = value.configValue
              break;
            case "附件/截图":
              formData.parmAttach = value.configValue
              break;
            default:
              break;
          }
        })

        if (formData?.fileJson?.length) {
          formData.fileJson = JSON.stringify(formData.fileJson)
        }

        const res = await reportedApi.updateTopic(formData)
        if (res.returnCode == 200) {
          this.$showMessage({ type: 'success', msg: '编辑成功' })
          this.$router.push('/reportedManage')
        }
      }
    },

    // 返回
    cancel () {
      this.$router.push('/reportedManage')
    },

    // 专题详情
    async getDetail () {
      const res = await reportedApi.topicDetail({ topicId: this.topicId })
      if (res.returnCode == 200) {
        // 专题信息
        const data = res.returnData?.wpTopicMain
        const total = res.returnData?.total

        this.oldScore = data.score

        // 如果是普通积分并且已经提交了作品,就不能选择按平台
        if (data.score !== -999 && total?.REPORTTOTAL) {
          data.isDisbledPlatform = true
        }

        console.log(data.isDisbledPlatform);
        this.formData = { ...data }
        this.lastScore = data.score

        const configObj = [
          {
            // 标题
            configKey: 'parmTitle',
            parmTitle: data.parmTitle,
            configName: '作品标题',
          },
          {
            // 内容
            configKey: 'parmContent',
            parmContent: data.parmContent,
            configName: "作品内容"
          },
          {
            // 链接
            configKey: 'parmUrl',
            parmUrl: data.parmUrl,
            configName: "作品链接"
          },
          {
            // 媒体平台
            configKey: 'parmMedia',
            parmMedia: data.parmMedia,
            configName: "媒体平台"
          },
          {
            // 发布类型
            configKey: 'parmPublishType',
            parmPublishType: data.parmPublishType,
            configName: "发布类型"
          },
          {
            // 作者类别
            configKey: 'parmAuthorType',
            parmAuthorType: data.parmAuthorType,
            configName: "作者类别"
          },
          {
            // 作者姓名
            configKey: 'parmAuthor',
            parmAuthor: data.parmAuthor,
            configName: "作者姓名"
          },
          {
            // 网名/昵称
            configKey: 'parmNetName',
            parmNetName: data.parmNetName,
            configName: "网名/昵称"
          },
          {
            // 阅读数量
            configKey: 'parmReadNum',
            parmReadNum: data.parmReadNum,
            configName: "阅读数量"
          },
          {
            // 转载数量
            configKey: 'parmReprint',
            parmReprint: data.parmReprint,
            configName: "转载数量"
          },
          {
            // 全网推送
            configKey: 'parmPush',
            parmPush: data.parmPush,
            configName: "全网推送"
          },
          {
            // 截图/附件
            configKey: 'parmAttach',
            parmAttach: data.parmAttach,
            configName: '附件/截图'
          },
        ]

        this.configData = configObj
        this.setConfigValue(configObj)
      }
    },

    /** 专题积分选择按平台 */
    changePlatformScore (score) {
      const settingName = ['作品标题', '作品链接', '附件/截图', '媒体平台', '发布类型', '作者类别', '作者姓名', '网名/昵称', '阅读数量', '转载数量']

      let flag = 0

      this.advancedSetting2.forEach((item, index) => {
        const obj = { ...item }
        item.isDisbled = false
        item.isDisbledRequired = false
        item.isShow = false
        item.isRequired = false

        settingName.forEach(value => {
          // 选择的按平台
          if (score == -999) {
            if (this.oldScore == -999) {
              flag = 1
            } else {
              if (item.configName == value) {
                if (item.configName == '媒体平台' || item.configName == '发布类型') {
                  item.isDisbled = true
                  item.isDisbledRequired = true
                } else if (item.configName == '附件/截图') {
                  item.isDisbled = true
                }
                item.isShow = true
                item.isRequired = true
              }
            }
          } else {
            // 编辑前为按平台,从按平台切换到普通积分
            if (this.oldScore == -999) {
              if (item.configName == '附件/截图') {
                item.isDisbled = true
                item.isDisbledRequired = false
                item.isShow = true
                item.isRequired = true
              } else {
                item.isDisbled = false
                item.isDisbledRequired = false
                item.isShow = false
                item.isRequired = false
              }
            } else {
              // 从按平台切换到普通积分
              if (this.lastScore == -999) {
                flag = 1
              } else { // 普通积分切换,无需修改
                item.isDisbled = obj.isDisbled
                item.isDisbledRequired = obj.isDisbledRequired
                item.isShow = obj.isShow
                item.isRequired = obj.isRequired
              }
            }
          }
        })
      })

      console.log(score);
      this.lastScore = score

      if (flag) {
        this.setConfigValue(this.configData)
      }
    },

    // 高级设置
    setConfigValue (data) {
      console.log(data);
      //作品标题 
      let advancedSetting2 = []
      data.forEach(item => {
        let isDisbled = false
        let isDisbledRequired = false

        if (this.lastScore == -999) {
          if (item.configName == '媒体平台' || item.configName == '发布类型') {
            isDisbled = true
            isDisbledRequired = true
          } else if (item.configName == '附件/截图') {
            isDisbled = true
          }
        } else {
          if (item.configName == '附件/截图') {
            isDisbled = true
            isDisbledRequired = false
          }
        }
        if (item[item.configKey]) {
          advancedSetting2.push({ configName: item.configName, isShow: item[item.configKey].toString().charAt(0) == '1', isRequired: item[item.configKey].toString().charAt(1) == '1', isDisbled, isDisbledRequired })
        }
      })
      this.advancedSetting2 = advancedSetting2
    },

    // 上报中心 高级设置 改变显示 
    checkboxChangeShow2 (item) {
      this.advancedSetting2.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },

    // 上报中心 高级设置 改变必填 
    checkboxChangeRequired2 () {
      this.advancedSetting2.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },

    // 基础设置表单
    submitBasicsForm (basicsForm) {
      console.log(basicsForm)
    },

    // 任务设置表单
    submitTaskForm (taskForm) {
      console.log(taskForm);
    },

    // 获取表单数据
    getFormData () {
      const formData = { ...this.$refs['basicsForm'].getFormData() }
      //上报中心-高级设置
      this.advancedSetting2.forEach(item => {
        item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
      })
      this.advancedSetting2.forEach(value => {
        if (value.configName == "作品标题") {
          formData.parmTitle = value.configValue
        } else if (value.configName == "作品内容") {
          formData.parmContent = value.configValue
        } else if (value.configName == "作品链接") {
          formData.parmUrl = value.configValue
        } else {
          formData.parmAttach = value.configValue
        }
      })
      return formData
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>