var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { width: "100%", height: _vm.boxHeight + "px" },
      attrs: { id: "j-barrage-content" },
      on: {
        click: function ($event) {
          _vm.showToolsBox = false
        },
      },
    },
    [
      _c("div", {
        style: { "--width": _vm.width, height: _vm.boxHeight },
        attrs: { id: "full-barrage-content" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }