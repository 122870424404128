<template>
  <div class="wp-w100">
    <el-scrollbar style="height: 100%">
      <div class="main">
        <SearchCard title="转发统计" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker v-model="searchParams.timeSection" type="daterange" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" clearable>
              </el-date-picker>
            </div>

            <wpLoadSelect class="search-w392 wp-select align-top" v-model="searchParams.taskId" :data="taskSelectList" :page="taskObj.page" dictLabel="title" dictValue="taskId" dictTime="createTime" :hasMore="taskObj.more" :request="getData" @clearTitle="() => (taskObj.title = '')" placeholder="请选择任务"></wpLoadSelect>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="flex-Acenter fs-14">
              <div>
                转发总量：<span class="wp-blue">{{ transpondTotal }}</span>
              </div>
              <div class="ml-30">
                完成总量：<span class="wp-blue">{{ finishTotal }}</span>
              </div>
            </div>
            <div>
              <el-button @click="exportFile" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu">导出
              </el-button>
            </div>
          </div>

          <div class="wp-table mt-20">
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column align="center" type="index" label="序号" width="70">
              </el-table-column>
              <el-table-column width="80"> </el-table-column>
              <el-table-column align="left" prop="custName" label="单位名称">
              </el-table-column>
              <el-table-column align="center" prop="taskNum" label="任务转发量">
              </el-table-column>
              <el-table-column align="center" prop="workNum" label="作品完成量">
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <!-- <wp-pagination
            class="mt-21"
            :total="total"
            :current-page="searchParams.page"
            :page-size="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination> -->
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import SearchCard from '@/views/components/searchCard'
export default {
  name: "transpondStatistics",
  components: { SearchCard },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      taskSelectList: [], // 任务下拉框列表

      // 查询条件
      searchParams: {
        timeSection: [],
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 所选任务ID
        page: 1, // 页码
        limit: 100, // 页大小
      },
      total: 0, // 总条数
      // 任务列表
      taskList: [],
      tableData: [],
      summariesInfo: {}, // 统计信息
      queryData: {}, // 深拷贝后的搜索条件
      transpondTotal: 0,
      finishTotal: 0,
      isExporting: false
    }
  },
  created () {
    // 获取表格数据
    this.getTableData()
    // 获取表格统计信息
    this.getSummaries()
  },
  methods: {
    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskSelectList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      console.log(this.taskObj)
      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskSelectList = [...this.taskSelectList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    /** 获取表格统计信息 */
    async getSummaries () {
      const res = await taskApi.getSummaries(this.queryData)
      if (res.returnCode == 200) {
        this.summariesInfo = res.returnData
      }
    },

    /** 获取表格数据 */
    async getTableData () {
      let transpondTotal = 0
      let finishTotal = 0
      this.queryData = {
        ...this.searchParams
      }
      delete this.queryData.timeSection

      const res = await taskApi.forwardStatistics(this.queryData)
      if (res.returnCode == 200) {
        const ret = res.returnData
        this.tableData = ret.data
        this.total = ret.totalCount || 0
        ret.data.forEach(item => {
          transpondTotal += +item.taskNum || 0
          finishTotal += +item.workNum || 0
        })

        this.transpondTotal = transpondTotal
        this.finishTotal = finishTotal
      }
    },

    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      this.searchParams.limit = 100
      this.getTableData()
    },

    // 导出
    async exportFile (event) {
      const query = { ...this.searchParams }
      delete query.timeSection
      if (this.isExporting) return
      this.isExporting = true
      try {
        const res = await taskApi.forwardExport(query)
        this.isExporting = false
        if (res.returnCode == 200) {

          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }

    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.getTableData()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    }
  },
  watch: {
    'searchParams.timeSection': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.searchParams.startTime = newVal[0]
          this.searchParams.endTime = newVal[1]
        } else {
          this.searchParams.startTime = ""
          this.searchParams.endTime = ""
        }
      },
      deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>