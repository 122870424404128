<template>
  <div style="width: 100%">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="tabsList.length > 1">
        <div class="title-header-left">
          <UnitTab :tabsList="tabsList" :activeId="activeName" @handleClick="handleClick"></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="阅评中心" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker :default-time="['00:00:00', '23:59:59']" @change="changeTimeSection" type="datetimerange" v-model="timeSection" prefix-icon="" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" clearable range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
              </el-date-picker>
            </div>
            <el-input class="search-w392 wp-input" v-model="searchParams.title" clearable placeholder="请输入标题">
            </el-input>
            <el-select class="search-w190 wp-select" v-model="searchParams.status" clearable placeholder="请选择状态">
              <el-option v-for="item in $globalData.REVIEW_CENTER_STATUS" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">

            </div>
            <div class="flex">
              <el-button @click="handlerUpload" class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium" v-preventReClick>
                阅评上报
              </el-button>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column align="center" width="50" label="序号" type="index">

              </el-table-column>

              <el-table-column align="left" prop="title" label="标题" min-width="95" show-overflow-tooltip>
              
              </el-table-column>
              <el-table-column align="center" label="稿件" min-width="70">
                <template slot-scope="scope">

                  <img style="width: 22px; height: 22px" class="wp-pointer" @click="$utils.download($axios.defaults.baseURL + '/file/'+scope.row.fileJson[0].id)" :src="$utils.handlePreviewImg(scope.row.fileJson)" />
                </template>
              </el-table-column>
              <el-table-column min-width="70" align="center" label="终稿">
                <template slot-scope="scope">
                  <span v-if="scope.row.fileJsonEnd == ''">-</span>
                  <img v-else style="width: 22px; height: 22px" class="wp-pointer" @click="$utils.download($axios.defaults.baseURL + '/file/'+scope.row.fileJsonEnd[0].id)" :src="$utils.handlePreviewImg(scope.row.fileJsonEnd)" />
                </template>
              </el-table-column>

              <el-table-column min-width="70" align="center" prop="words" label="字数">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ scope.row.words?scope.row.words:'-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="70" align="center" prop="royalties" label="稿酬">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ scope.row.royalties?scope.row.royalties:'-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="120" align="center" prop="createTime" label="提交时间">
                <template slot-scope="scope">
                  <div class="textOverOneLine">
                    {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="status" label="状态" min-width="70" max-width="100">
                <template slot-scope="scope">
                  <!-- 0删除 1未读 2已读 3采用 -->
                  <div>
                    <div class="flex-center" v-if="scope.row.status == 3">
                      <div class="success-circle"></div>
                      <div class="success-color">已采用</div>
                    </div>
                    <div class="flex-center" v-else-if="scope.row.status == 2">
                      <div class="disabled-circle"></div>
                      <div class="disabled-color">已读</div>
                    </div>
                    <div class="flex-center" v-else-if="scope.row.status == 1">
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">未读</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right" width="90">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div class="wp-action-icon">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" :visible-arrow="false" popper-class="wp-btn-tooltip">
                        <el-button @click="editItem(scope.row)" type="text" icon="icon-bianji1" class="wp-btn-icon-big" :class="!scope.row.edit ? 'no-drop' : ''" :disabled="!scope.row.edit">
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div class="wp-action-icon">
                      <el-tooltip class="item" effect="dark" content="删除" placement="top" :visible-arrow="false" popper-class="wp-btn-tooltip">
                        <el-button @click="delItem(scope.row)" type="text" icon="icon-shanchu" class="wp-btn-icon-big" :class="!scope.row.del ? 'no-drop' : ''" :disabled="!scope.row.del">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
    <UploadFiles ref="uploadFiles" :userObj="userObj" :reviewObj="reviewObj" @success="reviewSuccess" @close="reviewObj={}"></UploadFiles>
  </div>
</template> 

<script>
import { mapGetters } from 'vuex';

import UnitTab from '../components/unitTab/unitTab.vue';
import SearchCard from '@/views/components/searchCard'
import UploadFiles from '../components/uploadFiles'

import { pageListWpReviewArticle, deleteWpReviewArticle, checkReview } from '@/api/review.js'
export default {
  components: { SearchCard, UnitTab, UploadFiles },
  data () {
    return {
      activeName: '',
      searchParams: {
        title: "", // 阅评名称
        page: 1, // 页码
        limit: 30, // 页大小
        custId: "", // 单位Id
        custName: '',
        createName: '',//用户名
        status: "",//状态 1未读 2已读 3采用
        groupIds: [],//组ID数组
        startTime: '',
        endTime: ''
      },
      timeSection: '',
      total: 0,
      tableData: [],
      loading: false,
      userObj: {},
      reviewObj: {},
      editloading: false
    }
  },

  mounted () {
    // 假如有多个 但是未更新缓存的情况下 数据还是会出问题
    this.activeName = this.tabsList.length == 1? this.sysCust.custId:this.tabsList[0].custId
    this.searchParams.custId = this.activeName
    this.userObj = this.tabsList.length == 1? this.userInfo : this.tabsList.find(it => this.activeName == it.custId)
    // 获取阅评列表
    this.getPageList()
  },
  computed: {
    ...mapGetters(['isWpys', 'reviewCustList','sysCust','userInfo']),
    tabsList () {
      let tabs = this.reviewCustList.map(item => { return { ...item, custId: item.custId.toString(), userId: item.userId.toString(), label: item.custName, news: 0 } })
      return tabs
    },
  },

  methods: {
    /** 阅评列表 */
    async getPageList () {
      if (this.loading) return
      this.loading = true
      try {
        const res = await pageListWpReviewArticle({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.total = ret.totalCount
          ret.data.forEach(element => {
            element.fileJson = JSON.parse(element.fileJson)
            element.fileJsonEnd = element.fileJsonEnd ? JSON.parse(element.fileJsonEnd) : ''
          });
          this.tableData = ret.data
        }
      } catch (error) {
        this.loading = false
      }
    },

    /** tab点击回调 */
    handleClick (tab) {
      this.userObj = this.tabsList.find(it => tab == it.custId)
      // 恢复初始值
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.searchParams.custId = tab
      this.searchParams.title = ""
      this.searchParams.custName = ""
      this.searchParams.createName = ""
      this.searchParams.status = ""
      this.searchParams.startTime = ""
      this.searchParams.endTime = ""
      this.timeSection = ""
      // 获取阅评列表
      this.getPageList()
    },

    /** 点击搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getPageList()
    },
    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getPageList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getPageList()
    },
    delItem (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postDelItem(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelItem (item) {
      try {
        const res = await deleteWpReviewArticle({ reviewTitleId: item.reviewTitleId })
        this.loading = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.loading = false
      }
    },
    handlerUpload () {
      this.$refs.uploadFiles.show()
    },
    refresh () {
      this.searchParams.page = 1
      this.getPageList()
    },
    changeTimeSection (newVal) {
      if (newVal) {
        this.searchParams.startTime = newVal[0]
        this.searchParams.endTime = newVal[1]
      } else {
        this.timeSection = ""
        this.searchParams.startTime = ""
        this.searchParams.endTime = ""
      }
    },
    async editItem (item) {
      if (this.editloading) return
      this.editloading = true
      try {
        const res = await checkReview({ reviewTitleId: item.reviewTitleId })
        this.editloading = false
        if (Number(res.returnCode) !== 200) {
          if (Number(res.returnCode) == 80021) {
            // 阅评已采用或超过24小时，无法操作
            this.refresh()
          }
          return this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.reviewObj = item
        this.$refs.uploadFiles.show()
      } catch (error) {
        this.editloading = false
      }
    },
    reviewSuccess () {
      this.reviewObj = {}
      this.refresh()
    }

  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>