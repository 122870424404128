var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wp-card steps" }, [
    _c(
      "div",
      { staticClass: "steps-box" },
      [
        _c(
          "el-steps",
          {
            ref: "UI",
            style: { width: _vm.stepsWidth },
            attrs: { "align-center": "", space: 175, active: _vm.active },
          },
          _vm._l(_vm.stepsList, function (item) {
            return _c("el-step", { key: item, attrs: { title: item } })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }