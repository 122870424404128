<template>
  <div class="wp-card steps">
    <div class="steps-box">
      <el-steps
        :style="{ width: stepsWidth }"
        ref="UI"
        align-center
        :space="175"
        :active="active"
      >
        <el-step v-for="item in stepsList" :key="item" :title="item"></el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中
    active: {
      type: Number,
      default: 1
    },
    // 列表
    stepsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      stepsWidth: 0
    }
  },
  created () {
    // 动态计算steps组件宽度
    this.stepsWidth = this.stepsList.length * 175 + 'px'
  },

  mounted () {

  },
  methods: {

  },

}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
<style scoped>
</style>