<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/drillList' }">投稿演练</el-breadcrumb-item>
          <el-breadcrumb-item>{{isEidt?'编辑':'创建'}}演练</el-breadcrumb-item>
        </el-breadcrumb>

        <Steps class="mt-16" :active="active" :stepsList="stepsList"></Steps>
        <OneStep :taskDetial="taskDetial" v-show="active == 1" ref="oneStep" :isEidt="isEidt"></OneStep>
        <TwoStep v-show="active == 2 || active == 3" ref="twoStep" @changeUser="changeUser" :active="active" :selRedUserIds="selRedUserIds" :selBlueUserIds="selBlueUserIds"></TwoStep>
        <!-- <ThreeStep :timeCount="timeCount" v-show="active == 3"> </ThreeStep> -->
        <FourStep v-show="active == 4" :timeCount="timeCount"></FourStep>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 1">
          <el-button @click="oneCancel" class="wp-button">取消</el-button>
          <el-button style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="nextStep">下一步
          </el-button>
        </div>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 2">
          <el-button @click="twoCancel" class="wp-button">上一步</el-button>
          <el-button style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="nextSteptwo">下一步
          </el-button>
        </div>
        <div class="wp-task-card mt-16 wp-center" v-show="active == 3">
          <el-button @click="threeCancel" class="wp-button">上一步</el-button>
          <el-button style="margin-left: 24px" class="wp-button" v-preventReClick="1000" type="primary" @click="nextStepThree">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as  drillApi from '@/api/drill.js'
import Steps from '@/views/components/steps'
import OneStep from './steps/oneStep'
import TwoStep from './steps/twoStep'
import FourStep from './steps/fourStep'
export default {
  components: { Steps, OneStep, TwoStep, FourStep },
  data () {
    return {
      stepsList: ["创建演练", "选择红方人员", "选择蓝方人员", "发布成功"], // 步骤条列表
      active: 1,
      drllTaskMain: {},// 演练任务基础设置
      selRedUserIds: [],// 红方人员
      selBlueUserIds: [],// 蓝方人员
      type: 1,
      timeCount: 0,
      isEidt: false,
      taskId: '',// 任务id
      oldStartTime: '', // 编辑状态的 之前的开始时间
      taskDetial: {},
      isAdding: false // 正在提交中
    }
  },

  computed: {
    // ...mapGetters(['sysUserInfo'])
  },

  mounted () {
  },

  created () {
    if (this.$route.query.id) {
      this.isEidt = true
      this.taskId = this.$decrypt(this.$route.query.id)
      this.getDetailTask()
    }
  },
  methods: {
    // 下一步
    nextStep () {
      const drllTaskMain = this.$refs.oneStep.getFormData()
      // console.log(drllTaskMain);
      if (drllTaskMain) {
        this.active = 2
        this.drllTaskMain = drllTaskMain
      } else {
        // console.log(drllTaskMain)
      }
      // this.active = 2
    },
    // 验证第二步到第三步
    nextSteptwo () {
      this.selRedUserIds = this.$refs.twoStep.getFormData().map(it => it.id)
      if (this.selRedUserIds.length > 0) {
        this.active = 3
      }
      // this.active = 3
    },
    // 验证第三步到第四步
    nextStepThree () {
      this.selBlueUserIds = this.$refs.twoStep.getFormData().map(it => it.id)
      if (this.selBlueUserIds.length > 0) {
        // this.active = 4
        this.submitTask()
      }
      // this.active = 4
    },
    // 第一步取消回调
    oneCancel () {
      this.goListpage()
    },
    // 第二步取消回调
    twoCancel () {
      // 如果是从列表进来的,则直接返回至列表页
      this.active = 1
    },
    // 第三步取消回调
    threeCancel () {
      this.active = 2
    },
    // 选择人员 更改后  存值
    changeUser (list) {
      if (this.active == 2) {
        this.selRedUserIds = list.map(it => it.id)
      } else if (this.active == 3) {
        this.selBlueUserIds = list.map(it => it.id)
      }
    },
    resetTime (time) {
      return time.replace(/-/g, ' / ')
    },
    // 提交任务
    async submitTask () {

      // 判断开始时间与现在的时间比较 新增的 开始时间必须大于当前的时间
      // 编辑的 开始前5分钟前不允许编辑时间往前调整。
      if (new Date(this.resetTime(this.drllTaskMain.startTime)).getTime() < new Date().getTime()) {
        return this.$showMessage({ type: 'error', msg: '演练开始时间须大于当前时间' })
      }
      // 编辑的 判断之前的开始时间 与现在的时间 如果小于5分钟则不允许往前调整
      if (this.isEidt) {
        // 编辑前的开始时间与现在的时间差
        let diff = new Date(this.resetTime(this.oldStartTime)).getTime() - new Date().getTime()
        // 编辑后的开始时间与编辑前的开始时间时间差
        let dissTime = new Date(this.resetTime(this.drllTaskMain.startTime)).getTime() - new Date(this.resetTime(this.oldStartTime)).getTime()
        if (diff <= 0) {
          // 演练已结束
          // 返回到列表页
          this.$showMessage({ type: 'error', msg: '演练已结束' })
          return this.goListpage()
        } else if (diff > 0 && diff < 5 * 60 * 1000 && dissTime < 0) {
          return this.$showMessage({ type: 'error', msg: '开始前5分钟前不允许编辑时间往前调整' })
        }
      }

      // 判断红蓝方的 人员选择 情况
      if (this.selRedUserIds.length == 0) {
        return this.$showMessage({ type: 'error', msg: '请选择红方演练成员' })
      }
      if (this.selBlueUserIds.length == 0) {
        return this.$showMessage({ type: 'error', msg: '请选择蓝方演练成员' })
      }
      // 提交
      if (this.isAdding) return
      this.isAdding = true
      try {
        let query = {
          ...this.drllTaskMain,
          selRedUserIds: this.selRedUserIds,
          selBlueUserIds: this.selBlueUserIds
        }
        let res
        if (this.isEidt) {
          query.id = this.taskId
          res = await drillApi.editTask(query)
        } else {
          res = await drillApi.saveTask(query)
        }
        this.isAdding = false
        if (res.returnCode != 200) return this.$showMessage({ type: 'error', msg: res.returnMsg })
        this.timeCount = 3
        this.active = 4
      } catch (error) {
        this.isAdding = false
      }
    },
    // 回到列表页
    goListpage () {
      this.$router.push({ name: 'drillList' })
    },
    // 获取详情
    async getDetailTask () {
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      if (res.returnCode != 200) return this.$showMessage({ type: 'error', msg: res.returnMsg })
      console.log(res.returnData)
      this.taskDetial = res.returnData.taskDetial
      this.oldStartTime = res.returnData.taskDetial.startTime
      this.selRedUserIds = res.returnData.redUserIds
      this.selBlueUserIds = res.returnData.blueUserIds
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>