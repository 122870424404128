<template>
  <el-form class="form" :model="taskForm" :rules="taskFormRules" ref="taskForm" label-width="100px">
    <el-form-item class="item-content" label="任务方式：" prop="modeStyle">
      <el-select class="wp-select task-w726" v-model="taskForm.modeStyle" multiple :multiple-limit="3" placeholder="请选择任务方式">
        <el-option v-for="item in taskWayList" :key="item.dictId" :label="item.dataKey" :value="item.dataValue">
        </el-option>
      </el-select>

      <wp-question-mark style="margin-left: 6px" content="支持多选，多种方式完成其中一种即可"></wp-question-mark>
    </el-form-item>
    <el-form-item class="item-content" label="任务时间：" prop="timeSection">
      <div class="wp-date-picker-single task-w436">
        <el-date-picker readonly prefix-icon="" value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.startTime" type="datetime" placeholder="请选择开始时间">
        </el-date-picker>
        <span class="el-range-separator">-</span>
        <el-date-picker prefix-icon="" default-time="23:59:59"  value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.endTime" type="datetime" placeholder="请选择结束时间" :picker-options="pickerOptionsDatetime" @change="changeDatetime">
        </el-date-picker>
      </div>

      <div v-for="item in configObj.timeList" :key="item.custConfigId" class="time-box" :class="taskForm.timeRangeType == item.configName ? 'active-text' : ''" @click="handlerTimeSection(item)">
        {{ item.configName }}
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="任务积分：" prop="score">
      <div class="flex">
        <div class="task-box" :class="
            !customScore && taskForm.score == item.configValue
              ? 'active-text'
              : ''
          " v-for="item in configObj.scoreList" :key="item.configValue" @click="fastScore(item.configValue)">
          {{ item.configValue }}
        </div>

        <el-input @change="verifyScore" maxlength="4" :class="customScore ? 'custom-text' : ''" class="custom-input wp-input" v-model="customScore" placeholder="自定义"></el-input>
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="字数要求：" prop="minWord">
      <div class="flex">
        <div class="task-box" :class="
            !customWord && taskForm.minWord == item.configValue
              ? 'active-text'
              : ''
          " v-for="item in configObj.taskWordsRequire" :key="item.configValue" @click="fastWord(item.configValue)">
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input @change="verifyWord" maxlength="4" :class="customWord ? 'custom-text' : ''" class="custom-input wp-input" v-model="customWord" placeholder="自定义"></el-input>
      </div>
      <wp-question-mark style="margin-left: 6px" content="设置转发或评论时最少数字要求"></wp-question-mark>
    </el-form-item>

    <el-form-item class="item-content" label="单人上限：" prop="maxArticle">
      <div class="flex">
        <div class="task-box" :class="
            !customArticle && taskForm.maxArticle == item.configValue
              ? 'active-text'
              : ''
          " v-for="item in configObj.taskSingleLimit" :key="item.configValue" @click="fastArticle(item.configValue)">
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input @change="verifyArticle" maxlength="4" :class="customArticle ? 'custom-text' : ''" class="custom-input wp-input" v-model="customArticle" placeholder="自定义"></el-input>
      </div>
      <wp-question-mark style="margin-left: 6px" content="设置当前任务单个人员最多能提交的次数"></wp-question-mark>
    </el-form-item>
  </el-form>
</template>

<script>
import { resetNumPointOne } from '@/utils/validate.js';
export default {
  props: {
    // 任务方式列表
    taskStyleList: {
      type: Array,
      default: () => []
    },
    configObj: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      taskForm: {
        modeStyle: "", // 任务方式
        timeSection: "", // 时间区间
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        score: "", // 任务积分
        minWord: "", // 字数要求
        maxArticle: "", // 单人上限
        timeRangeType: "", // 时间快捷项
      },
      customScore: "", // 自定义任务积分
      customWord: "", // 自定义字数要求
      customArticle: "", // 自定义单人上限
      activeTime: "",
      taskWayList: [], // 任务方式
      timeList: [],
      imageUrl: "",
      taskFormRules: {
        modeStyle: [{ required: true, trigger: "blur", message: "请选择任务方式" }],
        timeSection: [{ required: true, trigger: "blur", message: "请选择起止时间" }],
        score: [{ required: true, trigger: "change", message: "请选择任务积分" }],
        minWord: [{ required: true, trigger: ["blur", "change"], message: "请选择字数" }],
        maxArticle: [{ required: true, trigger: ["blur", "change"], message: "请选择单人上限" }],
      },
      classifyList: [], // 任务分类列表
      pickerOptionsDatetime:{
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  watch: {
    // 任务方式列表
    taskStyleList: {
      handler (newVal) {
        console.log(newVal)
        this.taskWayList = newVal
        this.$set(this.taskForm, 'modeStyle', [])
      },
      deep: true
    },
    'taskForm.modeStyle': {
      handler (newVal) {
        console.log(newVal);
      }
    },
    // 任务配置项
    configObj: {
      handler (newVal) {
        // 拼接时间快捷项名称
        newVal.timeList.forEach(item => {
          if (item.configUnitStr) {
            item.configName = item?.configValue + item?.configUnitStr
          }
        })

        // 设置时间默认选中项
        const timeItem = newVal.timeList.find(item => item.configCheck)
        this.taskForm.timeRangeType = timeItem.configName

        this.handlerTimeSection(timeItem)

        // 设置默认选中积分
        this.taskForm.score = newVal.scoreList.find(item => item.configCheck).configValue

        // 设置默认字数要求
        this.taskForm.minWord = newVal.taskWordsRequire.find(item => item.configCheck).configValue

        // 设置默认单人上限
        this.taskForm.maxArticle = newVal.taskSingleLimit.find(item => item.configCheck).configValue

        this.configObj = newVal
      },
      deep: true
    }
  },
  mounted(){
    this.initDatetime()
  },
  methods: {
    // 校验自定义分数
    verifyScore (e) {
      this.taskForm.score = resetNumPointOne(e, 0, 100)
      this.customScore = this.taskForm.score
    },

    // 积分快捷选项回调
    fastScore (score) {
      this.customScore = ""
      this.taskForm.score = score
      this.$refs['taskForm'].fields[2].validateState = 'success'
    },

    // 校验自定义字数
    verifyWord (e) {
      this.taskForm.minWord = resetNumPointOne(e, 1, 100)
      this.customWord = this.taskForm.minWord
    },

    // 字数要求快捷选项回调
    fastWord (minWord) {
      this.taskForm.minWord = minWord
      this.customWord = ""
      this.$refs['taskForm'].fields[3].validateState = 'success'
    },

    // 校验单人上限
    verifyArticle (e) {
      this.taskForm.maxArticle = resetNumPointOne(e, 1, 100)
      this.customArticle = this.taskForm.maxArticle
    },

    // 字数要求快捷选项回调
    fastArticle (maxArticle) {
      this.taskForm.maxArticle = maxArticle
      this.customArticle = ""
      this.$refs['taskForm'].fields[4].validateState = 'success'
    },


    // 选择快捷项回调
    handlerTimeSection (item) {
      console.log(item);
      if (item.configName == '月底') {
        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().endOf('month').format('yyyy-MM-DD HH:mm:ss')

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm.startTime
        this.taskForm.timeSection[1] = this.taskForm.endTime
      } else {
        let unit = ""
        if (item.configUnit == 1) {
          unit = "hours"
        } else {
          unit = "days"
        }

        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().add(+item.configValue, unit).format("YYYY-MM-DD HH:mm:ss")

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm.startTime
        this.taskForm.timeSection[1] = this.taskForm.endTime
      }

      // 时间快捷项名称
      this.taskForm.timeRangeType = item.configName

      this.$refs['taskForm'].fields[1].validateState = 'success'
    },

    // 清除表单校验
    clearValidate () {
      this.$refs.taskForm.clearValidate()
    },

    // 提交表单
    getFormData () {
      let flag = false
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      console.log(flag);
      if (flag) {
        this.taskForm.pass = true
        return this.taskForm
      } else {
        return { pass: false }
      }
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.taskForm.resetFields()
    },
    initDatetime(){
      this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
    },
    changeDatetime(val){
      console.log(val)
      this.taskForm.timeRangeType = ""
      this.$forceUpdate()
      if (val) {
        // 开始时间限制为当前时间
        let startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        let endTime = val
        if (this.$moment(endTime).diff(this.$moment(startTime)) < 0) {
          endTime = startTime
        }
        this.taskForm.timeSection = [startTime, endTime]
        this.taskForm.startTime = startTime
        this.taskForm.endTime = endTime
      } else {
        this.taskForm.timeSection = []
        // this.taskForm.startTime = ""
        this.taskForm.endTime = ""
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>