<template>
  <div class="main flex teamlist">
    <AsideList
      :asideList="asideList"
      @searchTree="searchTree"
      @handlerItem="handlerItem"
      @handlerAsideItem="handlerAsideItem"
      @addGroup="addGroup"
      @nodeClick="nodeClick"
      :loading="loadingTree"
      v-if="roleId != 3"
      ref="asideList"
    ></AsideList>

    <div class="ml-16 search-card" :class="{ groupLeader: roleId == 3 }">
      <el-scrollbar style="height: 100%">
        <SearchCard :title="groupName" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.userName"
              clearable
              placeholder="请输入人员姓名"
            >
            </el-input>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.loginName"
              clearable
              placeholder="请输入登录账号"
            >
            </el-input>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.mobile"
              @input="resetNumMobile"
              clearable
              placeholder="请输入手机号码"
              maxlength="11"
            >
            </el-input>
            <el-input
              class="search-w392 wp-input"
              v-model.trim="searchQuery.custName"
              clearable
              placeholder="请输入单位名称"
            >
            </el-input>
            <el-select
              filterable
              class="search-w190 wp-select"
              v-model="searchQuery.starLevel"
              clearable
              placeholder="请选择星级等级"
            >
              <el-option
                v-for="item in userStarsList"
                :key="item.dataValue"
                :label="item.dataKey"
                :value="item.dataValue"
              >
              </el-option>
            </el-select>
            <el-select
              filterable
              class="search-w190 wp-select"
              v-model="searchQuery.status"
              clearable
              placeholder="请选择账号状态"
            >
              <el-option
                v-for="item in $globalData.accountStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex-Acenter">
              <div style="margin-right: 30px">
                组员人数：<span class="wp-blue">{{ memberCount }}</span>
              </div>
              <div>
                组长人数：<span class="wp-blue">{{ groupCount }}</span>
              </div>
              <el-button class="wp-button-text wp-btn-icon" type="text" icon="icon-chang" style="margin-left:16px;" @click="batchSet" v-if="roleId != this.$ENUM.SYSROLE.GROUPLEADER"> 批量设为常用
              </el-button>
            </div>
            <div class="flex" v-if="roleId != 3">
              <el-button
                @click="importUser"
                class="wp-button wp-btn-icon"
                size="medium"
                icon="icon-daoru"
                v-preventReClick
                >导入
              </el-button>
              <el-button
                class="wp-button wp-btn-icon"
                size="medium"
                icon=" icon-daochu"
                v-preventReClick
                @click="exportFile"
                >导出
              </el-button>
              <!-- 该分组下面没有子分组-->
              <el-button
                class="wp-button wp-btn-icon"
                type="primary"
                icon="icon-tianjia"
                size="medium"
                v-preventReClick
                @click="toAddPeople"
              >
                添加人员
              </el-button>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <!-- 序号、角色、人员姓名、登录账号、手机号码、所在分组、单位名称、最后登录时间、星级等级、账号状态、操作 -->
            <el-table :data="tableData" stripe v-if="tableData.length !== 0" ref="multipleTable" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50" :selectable="selectable" align="center">
              </el-table-column>
              <el-table-column
                align="center"
                type="index"
                label="序号"
                min-width="40"
              >
              </el-table-column>
              <!-- <el-table-column align="center" prop="roleName" label="角色" width="150" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column align="left" label="人员姓名" min-width="100">
                <template slot-scope="scope">
                  <div class="personnel-row" @click="showDetail(scope.row)">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.userName"
                      placement="top"
                      :disabled="!isShowTooltipWidth"
                      popper-class="wp-visibilityPopper"
                    >
                      <p
                        class="textOverOneLine"
                        @mouseenter="visibilityChangeWidth($event)"
                      >
                        {{ scope.row.userName }}
                      </p>
                    </el-tooltip>

                    <el-tag
                      effect="plain"
                      size="mini"
                      v-if="scope.row.roleId == 3"
                      >组长</el-tag
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                label="登录账号"
                min-width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="personnel-row" @click="showDetail(scope.row)">
                    <p class="textOverOneLine p140">
                      {{ scope.row.loginName }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                prop="mobile"
                label="手机号码"
                min-width="110"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="groupName"
                label="所在分组"
                min-width="120"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column align="left" prop="commonGroupStr" label="常用分组" min-width="120" show-overflow-tooltip> </el-table-column>
              <el-table-column
                align="left"
                prop="custName"
                label="单位名称"
                min-width="120"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="loginTime"
                label="最后登录时间"
                min-width="160"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="starLevelStr"
                label="星级等级"
                min-width="80"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="账号状态"
                min-width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      :disabled="roleId == 3"
                      v-model="scope.row.status"
                      @change="changeAccountStatus($event, scope.row)"
                      :active-value="1"
                      :inactive-value="2"
                    >
                    </el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                min-width="150"
                align="center"
                v-if="roleId != 3"
              >
                <template slot-scope="scope">
                  <div class="flex-center">
                    <el-tooltip
                      popper-class="wp-btn-tooltip"
                      :visible-arrow="false"
                      effect="dark"
                      content="编辑"
                      placement="top"
                      :enterable="false"
                    >
                      <el-button
                        type="text"
                        icon="icon-bianji1"
                        class="wp-btn-icon-big"
                        @click="eidtUser(scope.row)"
                      >
                      </el-button>
                    </el-tooltip>
                    <el-tooltip popper-class="wp-btn-tooltip" :visible-arrow="false" effect="dark" content="设置常用" placement="top" :enterable="false">
                      <el-button type="text" icon="icon-chang" class="wp-btn-icon-big" @click="setCommonUse(scope.row)" :disabled="(scope.row.commonGroups && scope.row.commonGroups.length > 0) || (scope.row.status != 1)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="重置密码"
                      placement="top"
                      popper-class="wp-btn-tooltip"
                      :visible-arrow="false"
                      :enterable="false"
                    >
                      <el-button
                        type="text"
                        icon="icon-chongzhimima"
                        class="wp-btn-icon-big"
                        @click="resetPassword(scope.row)"
                      >
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除"
                      placement="top"
                      popper-class="wp-btn-tooltip"
                      :visible-arrow="false"
                      :enterable="false"
                    >
                      <el-button
                        type="text"
                        icon="icon-shanchu"
                        class="wp-btn-icon-big"
                        @click="delUser(scope.row)"
                      >
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :currentPage="query.page"
          >
          </wp-pagination>
        </div>
      </el-scrollbar>
    </div>
    <add-group
      ref="addGroup"
      :parentID="parentID"
      :currentID="currentID"
      @success="getAsideList"
    ></add-group>
    <import-user
      ref="importUser"
      :groupId="nodeObj.groupId"
      @success="refresh"
    ></import-user>
    <CommonUse ref="commonUse" @success="refresh" :userIds="userIds" :userList="userList"></CommonUse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// 非湘江新区侧边栏
import AsideList from '@/views/personnelTeam/components/asideList'

import AddGroup from '@/views/personnelTeam/components/addGroup.vue'
import ImportUser from '@/views/personnelTeam/components/importUser.vue'
import SearchCard from '@/views/components/searchCard'
import * as personnelTeamApi from '@/api/personnelTeam.js'
import { sysDictList } from '@/api/systemSettings.js'
import { resetNum } from '@/utils/validate.js';
import CommonUse from '@/views/personnelTeam/components/commonUse.vue'
export default {
  name: 'panel',
  components: { AsideList, SearchCard, AddGroup, ImportUser,CommonUse },
  data () {
    return {
      loading: false,
      loadingTree: false,
      asideList: [], // 侧边栏专题列表
      searchQuery: {
        userName: '',
        loginName: '',
        mobile: '',
        custName: "",
        starLevel: "",
        status: ''
      },
      status: 2,
      tableData: [],
      parentID: '',
      currentID: '',
      groupId: '', // 当前选中的树节点
      groupName: '全部', //右侧的上方的节点名称
      parentId: '', //上级分组ID
      query: {
        page: 1,
        limit: 30,
        userName: '',
        loginName: '',
        mobile: '',
        custName: "",
        starLevel: "",
        status: ''
      },
      total: 0,
      // 组员人数。组长人数
      memberCount: 0,
      groupCount: 0,
      searchTreeQuery: {
        groupName: ''
      },
      nodeObj: {},
      allObj: { groupId: '', groupName: '全部', isAddUser: false },
      userStarsList: [],// 星级下拉列表
      isShowTooltipWidth: false,
      xjxqFlag: false,
      multipleSelection:[],
      userIds:[],
      userList:[],// 选中的用户
    };
  },
  computed: {
    ...mapGetters(['sysUserInfo', 'roleId'])
  },
  watch: {
    'tableData': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.multipleSelection = []
        }
      }
    }
  },
  props: {},
  created () {
  

  },
  mounted () {
    console.log(this.sysUserInfo);
    // 是否为湘江新区账号
    this.xjxqFlag = this.sysUserInfo?.xjxqFlag
    // 		组长只拥有查看本组组员信息功能，不能添加、、编辑、导入、导出个人信息。可以复用“管理员端”该模板进行修改，区别如下：
    // 1）隐藏左侧分组列区域。
    // 2）隐藏“添加人员”“导入”“导出”。
    // 3）点击操作“账号启用禁用”时提示“您的账号没有操作权限”。
    // 4）列表区隐藏“操作列”。
    this.getSysDictList()
    if (this.roleId == 3) {
      this.groupId = this.sysUserInfo.sysGroup.groupId
      this.groupName = this.sysUserInfo.sysGroup.groupName
      // this.parentId = this.sysUserInfo.sysGroup.parentId
      this.refresh()
    } else {
      this.getAsideList(1)
      this.refresh()
    }
  },
  methods: {
    // 搜索树
    searchTree (data) {
      this.searchTreeQuery.groupName = data
      this.getAsideList()
    },
    // 个人信息
    showDetail (item) {
      this.$router.push({ name: 'peopleInformation', query: { userId: this.$encrypt(item.userId) } })
    },
    // 添加人员
    async toAddPeople () {
      // 判断是否超出人数上限
      let flag = await this.checkMaxMember()
      if (!flag) return
      // 定位在无下级分组的一级分组，点击添加人员
      // 2.所在分组自动选择该分组
      // 将符合条件的分组id带过去
      // nodeObj
      if (this.nodeObj.isAddUser) {
        this.$router.push({ name: 'addPeople', query: { groupId: this.$encrypt(this.nodeObj.groupId) } })
      } else {
        this.$router.push({ name: 'addPeople' })
      }
      // this.$router.push({ name: 'addPeople', query: { groupId: this.$encrypt(this.groupId) } })
    },
    // 导入 
    async importUser () {
      let flag = await this.checkMaxMember()
      console.log(flag)
      if (!flag) return
      // 2）导入弹窗仅当选中某具体分组时才会弹出，当分组选中“全部”或者“含子分组的一级组”时提示“导入请先选择分组”
      if (!this.nodeObj.isAddUser) return this.$showMessage({
        type: 'error',
        msg: '导入请先选择分组'
      })
      this.$refs.importUser.show()
    },
    checkMaxMember () {
      let that = this
      return new Promise((resolve, reject) => {
        personnelTeamApi.checkAddUser({}).then(res => {
          if (Number(res.returnCode) !== 200) {
            that.$showMessage({
              type: 'error',
              msg: res.returnMsg
            })
            return resolve(false)
          }
          resolve(true)
          // else if (res.returnData.totalCount >= that.sysUserInfo.sysCust.maxMember) {
          //   that.$showMessage({
          //     type: 'error',
          //     msg: '添加人员已达上限'
          //   })
          //   resolve(false)
          // } 
          // else {
          //   resolve(true)
          // }
        }).catch(error => {
          reject(false)
        })
      })
    },
    // 左侧树节点 点击后的事件
    nodeClick (data) {
      // 重置选中的树节点id
      let newData = JSON.parse(JSON.stringify(data))
      if (data.groupId.toString().length == 0) {
        // 选中全部
        newData = JSON.parse(JSON.stringify(this.allObj))
      }
      this.groupId = newData.groupId
      // 重置右侧的上方的节点名称
      this.groupName = newData.groupName
      this.nodeObj = newData
      this.refresh()

    },
    // 添加分组
    addGroup () {
      this.parentID = ''
      this.currentID = ''
      this.$refs.addGroup.show()
    },
    // 创建任务
    addTask () {
      this.$router.push("/addTask")
    },

    // 批量任务
    batchTask () {
      this.$router.push("/batchTask")
    },
    // 获取侧边栏列表
    async getAsideList (frequency) {
      let query = { groupName: this.searchTreeQuery.groupName }
      if (this.loadingTree) return
      this.loadingTree = true
      try {
        let res = await personnelTeamApi.listTree(query)
        this.loadingTree = false
        if (Number(res.returnCode) !== 200) return
        let flag = false // 当前选中的节点是否在树中
        res.returnData.forEach((element, ind) => {
          if (element.groupId == this.groupId) {
            flag = true
          }
          // 上一个id 下一个id
          element.preGroupId = res.returnData[ind - 1]?.groupId || ''
          element.nextGroupId = res.returnData[ind + 1]?.groupId || ''
          // 是否显示上移和下移的按钮 默认显示
          element.isShiftUp = true
          element.isShiftDown = true
          if (ind == 0) { element.isShiftUp = false }
          let tail = res.returnData.findIndex(it => it.operate == 1) !== -1 ? 2 : 1
          if (ind == res.returnData.length - 2) { element.isShiftDown = false }
          // isHaveUser 为true 不显示 添加子分组按钮 因为下面有分组 默认不显示
          element.isHaveUser = true
          // 是否能添加用户
          element.isAddUser = false
          // 分组下面没有用户就能 添加子分组
          if (element.userCount == 0) {
            element.isHaveUser = false
          }
          // 没有子分组 且  userCount 为0 才能添加子分组
          if (!element.sysGroupList || element.sysGroupList?.length == 0) {
            // 没有子分组
            element.isAddUser = true
          } else {
            element.sysGroupList.forEach((item, index) => {
              if (item.groupId == this.groupId) {
                flag = true
              }
              item.isHaveUser = true
              item.isAddUser = true
              // 上一个id 下一个id
              item.preGroupId = element.sysGroupList[index - 1]?.groupId || ''
              item.nextGroupId = element.sysGroupList[index + 1]?.groupId || ''
              // 是否显示上移和下移的按钮
              item.isShiftUp = true
              item.isShiftDown = true
              if (index == 0) { item.isShiftUp = false }
              if (index == element.sysGroupList.length - 1) { item.isShiftDown = false }
            })
          }
        });
        console.log(res.returnData);
        this.asideList = res.returnData
       // 页面首次加载 设置默认选中的节点 并展开父节点
       if (frequency == 1 && this.groupId) {
          this.$nextTick(() => {
            this.$refs.asideList.setCurrentKey(this.groupId)
            if(this.nodeObj && this.nodeObj.parentId){
              this.$refs.asideList.setDefaultExpandedKeys(this.nodeObj.parentId)
            }
          })
        }else if (flag && this.groupId) {
          this.$nextTick(() => {
            this.$refs.asideList.setCheck(true)
          })
        }
      } catch (error) {
        this.loadingTree = false
        console.log(error)
      }
    },
    // 导出
    async exportFile (event) {
      // console.log(event)
      let query = {
        groupId: this.groupId,
        ...this.query,
        userIds:this.multipleSelection.map(it=>it.userId)
      }
      if (this.isExporting) return
      this.isExporting = true
      try {
        let res = await personnelTeamApi.exportUser(query)
        this.isExporting = false
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        // this.$showMessage({
        //   type: 'success',
        //   msg: res.returnMsg
        // })
        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } catch (error) {
        this.isExporting = false
        console.log(error)
      }

    },
    // 点击侧边栏单项数据
    handlerAsideItem (item) {
      // this.title = item.name
      // console.log(item)

    },
    // 对侧边栏单项数据进行操作
    handlerItem (data) {
      console.log(data)
      if (data.type == 'edit') {
        // 修改名称
        this.parentID = data.parentId?.toString()
        this.currentID = data.groupId.toString()
        this.$refs.addGroup.show()
      } else if (data.type == 'add') {
        // 添加子分组
        this.parentID = data.groupId.toString()
        this.currentID = ''
        this.$refs.addGroup.show()
      } else if (data.type == 'remove') {
        // 删除
        this.delTreeNode(data)
      } else if (data.type == 'shiftUp') {
        // 上移
        this.sortTree({ groupId: data.groupId, operateGroupId: data.preGroupId })
      } else if (data.type == 'shiftDown') {
        // 下移
        this.sortTree({ groupId: data.groupId, operateGroupId: data.nextGroupId })
      }
    },
    async sortTree (query) {
      console.log(query)
      try {
        let res = await personnelTeamApi.updateSortValue(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    // 删除树
    delTreeNode (data) {
      // 当组下有人员时弹窗提示“确定删除吗？（删除后组下人员会全部被删除）”
      let tips = '删除后不可恢复'
      if (data.userCount && data.userCount > 0) {
        tips = "删除后组下人员会全部被删除"
      }
      // 确定删除吗？（删除后不可恢复）
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: tips,
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postDelTreeNode(data)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelTreeNode (data) {
      try {
        let res = await personnelTeamApi.deleteSysGroup({ groupId: data.groupId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        if (data.groupId == this.groupId) {
          // 设置默认选中的节点
          this.$nextTick(() => {
            this.$refs.asideList.setCheck(false)
          })
        }
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      // 获取接口
      this.getUserPageList()
    },
    // 搜索查询
    handlerSearch () {
      this.query = { ...this.query, ...this.searchQuery }
      this.refresh()
    },
    // 刷新用户列表
    refresh () {
      // 页码重置为1
      this.query.page = 1
      // 获取接口
      this.getUserPageList()
    },
    // 用户状态改变
    changeAccountStatus: function (callback, item) {
      console.log(item)
      let that = this
      if (callback == 1) {
        //开启
        this.postAccountStatus(item, '1')
      } else {
        this.$wpConfirm('提示', {
          tips: "确定禁用该账号吗？",
          content: "禁用账号将无法登录",
          yesBtnText: '确定',
          isShowCancelBtn: true, //是否显示取消按钮
          cancelBtnText: '取消',
        }).then(() => {
          that.postAccountStatus(item, '2')
        }).catch(() => {
          let params = {
            ...that.tableData[that.tableData.findIndex(it => {
              return it.userId == item.userId
            })],
            status: 1
          }
          that.$set(that.tableData, that.tableData.findIndex(it => {
            return it.userId == item.userId
          }), params)
          that.$forceUpdate()
        });
      }
    },
    //用户启用、禁用
    async postAccountStatus (item, status) {
      let query = {
        userId: item.userId,
        status: status
      };
      try {
        let res = await personnelTeamApi.disabledSysUser(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 重置密码
    resetPassword (item) {
      this.$wpConfirm('重置密码', {
        tips: "确定重置密码吗？",
        content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postResetPassword(item)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postResetPassword (item) {
      try {
        let res = await personnelTeamApi.resetPass({ userId: item.userId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        // this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    delUser (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelUser(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelUser (item) {
      try {
        let res = await personnelTeamApi.deleteSysUser({ userId: item.userId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        // 假如用户列表原本只有一个用户，那么删除后要刷新树，但是要是别的管理员也删除了呢？暂时都刷新树吧
        this.refresh()

        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    // 获取用户列表
    async getUserPageList () {
      let query = {
        groupId: this.groupId,
        ...this.query,
        // roleId: this.roleId,
        parentId: this.parentId
      }
      if (this.loading) return
      this.loading = true
      try {
        let res = await personnelTeamApi.userPageList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(item => {
          item.roleName = this.$globalData.roleNames[item.roleId.toString()]
          // item.starLevelStr = this.$globalData.starRatingList.filter(it => it.value == item.starLevel)[0].label
          item.starLevelStr = this.userStarsList.filter(it => it.dataValue == item.starLevel)[0].dataKey
          if (item.starLevel == 0) {
            item.starLevelStr = "-"
          }
          if (new Date(this.$utils.resetTime(item.loginTime)).getTime() == new Date(this.$utils.resetTime(item.createTime)).getTime()) {
            item.loginTime = '暂未登录'
          } else {
            item.loginTime = this.$moment(item.loginTime).format("YYYY-MM-DD HH:mm")
          }
          item.commonGroupStr = ( item.commonGroups && item.commonGroups.length > 0 ) ? item.commonGroups.map(i=>i.commonGroupName).join(','):'-'
        })
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
        // 组员人数。组长人数
        this.memberCount = res.returnData.memberCount
        this.groupCount = res.returnData.groupCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 编辑用户
    eidtUser (item) {
      this.$router.push({ name: 'addPeople', query: { userId: this.$encrypt(item.userId) } })
    },
    // 获取星级下拉选项
    async getSysDictList () {
      try {
        let res = await sysDictList({ "dataType": "userStars" })
        if (Number(res.returnCode) !== 200) return
        this.userStarsList = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    // 手机号重置 值
    resetNumMobile () {
      this.searchQuery.mobile = resetNum(this.searchQuery.mobile);
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    // 勾选事件
    handleSelectionChange (val) {
      this.multipleSelection = val
      // this.multipleSelection = val.map(i => i.userId)
      // console.log(this.multipleSelection)
    },
    // 批量设为常用
    batchSet () {
      // 批量设为常用
      if (this.multipleSelection.length == 0) return this.$showMessage({
        type: 'warning',
        msg: '请先勾选人员!'
      })
      this.userList = this.multipleSelection.map(it=>{return {userId:it.userId,commonGroups:it.commonGroups}})
      this.$refs.commonUse.show()
    },
    // 设置为常用
    setCommonUse (row) {
      this.userIds = [row.userId]
      this.userList = []
      this.$refs.commonUse.show()
    },
     // 是否可勾选
     selectable (row, index) {
      if (row.status == 1) {//区转发
        return true;
      } else {
        return  false
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import './index.scss';
</style>
