<template>
  <div class="echarts-div" :id="id"></div>
</template>

<script>
// import echarts from "echarts";

export default {
  // mixins: [resize],
  props: {
    xAxisData: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: "chart"
    },
    yAxisData: {
      type: Array,
      default: () => []
    },
    hoverTitle: {
      type: String,
      default: "任务完成量"
    },
    maxXAxisLength: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      myChart: null
    };
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  computed: {

  },
  methods: {
    setData () {
      if (this.myChart) {
        this.setOption()
      } else {
        this.initChart()
      }
    },
    remFontSize: function (rem) {
      var fontSize = document.documentElement.style.fontSize;
      return Math.floor(rem * fontSize.replace('px', ''));
    },
    resizeW () {
      this.myChart.resize()
    },
    initChart: function () {
      if (!document.getElementById(this.id)) return;
      this.myChart = this.$echarts.init(document.getElementById(this.id));
      this.setOption();
      window.addEventListener('resize', () => {
        if (this.myChart) {
          this.myChart.resize();
        }
      });
    },
    setOption: function () {
      let that = this
      let option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 0px 5px 0px rgba(3,109,255,0.2000)',
          padding: 18,
          textStyle: {
            color: '#333333'
          },
          formatter: function (params) {
            // console.log(params)
            return `<div style="background-color:${params[0].color};display:inline-block;width: 8px;height: 8px;border-radius:50%;margin-right: 4px;vertical-align: middle;"></div><span style="color:#333333;font-size: 16px;font-weight: bold;">${params[0].axisValue}</span> <br/> 
            <div style="margin-top: 12px;"> <div style="display:inline-block;width: 8px;height: 8px;margin-right: 4px;"></div>${that.hoverTitle}: ${params[0].value} </div > `
          }
          // axisPointer: {
          //   type: 'shadow'
          // }
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisLabel: {
            // rotate: 20,
            interval: 0,
            show: true,
            textStyle: {
              color: '#666666',
              fontSize: this.remFontSize(0.083333)
            },
            formatter: function (value) {
              //  当body 宽度少于 1680 时 两个字点点
              // return value
              if (that.maxXAxisLength && window.innerWidth < 1680 && value.length > that.maxXAxisLength) {
                return value.slice(0, that.maxXAxisLength) + '...'
              } else {
                return value
              }
              // var valueDetal = value.split('-').join('');
              // var ret = ''; //拼接加\n返回的类目项
              // var maxLength = 3; //每项显示文字个数
              // var valLength = valueDetal.length; //X轴类目项的文字个数
              // var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              // if (rowN > 1) {
              //   for (var i = 0; i < rowN; i++) {
              //     var temp = ''; //每次截取的字符串
              //     var start = i * maxLength; //开始截取的位置
              //     var end = start + maxLength; //结束截取的位置
              //     //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
              //     temp = valueDetal.substring(start, end) + '\n';
              //     ret += temp; //凭借最终的字符串
              //   }
              //   return ret;
              // } else {
              //   return valueDetal;
              // }
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#666666',
              fontSize: this.remFontSize(0.06944)
            }
          },
          // splitNumber:4,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#EFEFEF',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: this.remFontSize(0.111111) + '',
            itemStyle: {
              color: '#0064E6',
              borderRadius: 2,
              shadowBlur: 5,
              shadowColor: '#6AABFF',
              shadowOffsetX: 0,
              shadowOffsetY: 0
              //box-shadow: 0px 0px 5px 0px #6AABFF;
            },
            label: {
              show: false,
              position: 'top',
              formatter: '{c}',
              color: '#fff',
              fontSize: this.remFontSize(0.083333)
            },
            data: this.yAxisData
          }
        ]
      };
      if (!this.myChart) {
        return;
      }
      this.myChart.setOption(option);
    },
  },
  watch: {
    'xAxisData': {
      handler: function (val, oldval) {
        if (val) {
          this.initChart()
        }
      },
      deep: true //对象内部的属性监听，也叫深度监听
    },
  },
};
</script>

<style scoped>
.chartsClass {
  padding-left: 1.2rem;
}
</style>
