var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "wpConfirm",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "show-close": false,
        width: "420px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "header-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", { staticClass: "el-dialog__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("div", [
            _c("i", {
              staticClass: "el-dialog__close el-icon el-icon-close",
              on: { click: _vm.closeAction },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "dialog-body" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _c("p", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tips))]),
          _c("div", {
            staticClass: "texts",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.isShowCancelBtn
            ? _c(
                "el-button",
                {
                  staticClass: "wp-button",
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleAction("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.cancelBtnText) + " ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "wp-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleAction("yes")
                },
              },
            },
            [_vm._v(_vm._s(_vm.yesBtnText) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }