var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-CB mt-16" }, [
    _c(
      "div",
      { staticClass: "people-list" },
      [
        _c("div", { staticClass: "search-box" }, [
          _c(
            "div",
            { staticClass: "search-input" },
            [
              _c(
                "el-input",
                {
                  staticClass: "wp-input",
                  attrs: { clearable: "", placeholder: "请输入搜索的内容" },
                  model: {
                    value: _vm.searchContent,
                    callback: function ($$v) {
                      _vm.searchContent = $$v
                    },
                    expression: "searchContent",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon icon-sousuo",
                    staticStyle: { "font-size": "20px" },
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("PeopleTtee", {
          ref: "peopleRef",
          attrs: {
            defaultExpanded: _vm.defaultExpanded,
            defaultCheckedKeys: _vm.activePeople,
            peopleList: _vm.peopleList,
            searchContent: _vm.searchContent,
          },
          on: {
            "update:defaultCheckedKeys": function ($event) {
              _vm.activePeople = $event
            },
            "update:default-checked-keys": function ($event) {
              _vm.activePeople = $event
            },
            handleCheck: _vm.handleCheck,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "select-people" }, [
      _c("div", { staticClass: "flex-CB fs-14 head" }, [
        _c("div", [
          _vm._v(" " + _vm._s(_vm.type == 1 ? "红方" : "蓝方") + "成员（ "),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.list.length)),
          ]),
          _vm._v(" 人） "),
        ]),
        _c("div", { staticClass: "flex-Acenter" }, [
          _c(
            "div",
            {
              staticClass: "flex-Acenter clear wp-blue",
              on: { click: _vm.clearAll },
            },
            [
              _c("i", { staticClass: "icon-shanchu" }),
              _c("div", { staticClass: "fs-14 wp-blue" }, [_vm._v("清空所有")]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
            _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "list-item" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          tabindex: index,
                          disabled: !_vm.isShowTooltipWidth,
                          content: item.name,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "textOverOneLine wp-center",
                            staticStyle: { flex: "3" },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.visibilityChangeWidth($event)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { flex: "3" } }, [
                      _vm._v(_vm._s(_vm.$utils.replacePhone(item.mobile))),
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          tabindex: index,
                          disabled: !_vm.isShowTooltipWidth,
                          content: item.groupName,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "textOverOneLine wp-center",
                            staticStyle: { flex: "3" },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.visibilityChangeWidth($event)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.groupName) + " ")]
                        ),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          tabindex: index + 1000,
                          disabled: !_vm.isShowTooltipWidth,
                          content: item.custName,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "textOverOneLine",
                            staticStyle: { flex: "3" },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.visibilityChangeWidth($event)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.custName) + " ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            return _vm.tableRemovePeople(item, index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-error" })]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }