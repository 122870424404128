<!--第三方登录路由-->
<template>
  <div></div>
</template>

<script>
import verifyLoginMixin from '@/components/mixin/verifyLogin.js';
export default {
  name: 'Outside',
  mixins: [verifyLoginMixin],
  beforeCreate () {
  },
  created () {
    this.isLogin()
  },
  methods: {
    doLogin: async function () {
      let that = this
      let query = {
        hostId: this.$route.query.i,
      };
      this.$store.dispatch('Postlogin', query).then(res => {
        if (Number(res.returnCode) !== 200) {
          that.$router.push('/login')
          return that.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        let currRoleId = res.returnData.roleId
        // 角色是超级管理员则直接跳转到 单位管理，没有切换账号页面
        // 判断是否是多角色，是多角色，需要跳转到切换登录页面
        if (currRoleId == 1) {
          this.$router.push({
            name: 'unitManagList'
          })
        } else {
          //判断是哪种角色的用户
          // 角色ID；1超级管理员；2管理员；3组长；4网评员
          // 不是多角色，跳转到该用户的首页
          if (currRoleId == 2) {
            this.$router.push({
              name: 'homeAdmin'
            })
          } else {
            this.$router.push({
              name: 'home'
            })
          }
        }
      })
    },
    // // 验证是否已登录 已登录就跳转 没有登录就清除缓存
    async isLogin(){
      try{
        const res = await this.getInfo()
        if(!res) return this.doLogin()
      }catch(err){
        console.log(err)
        this.doLogin()
      } 
    }
  }
};
</script>

<style scoped></style>
