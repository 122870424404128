<template>
  <el-form
    class="form"
    :model="basicsForm"
    :rules="basicsFormRules"
    ref="basicsFormRef"
    label-width="100px"
  >
    <el-form-item class="item-content" label="专题分类：" prop="topicTypeId">
      <el-select
        class="wp-select task-w726"
        v-model="basicsForm.topicTypeId"
        placeholder="请选择专题分类"
      >
        <el-option
          v-for="item in classifyList"
          :key="item.topicTypeId"
          :label="item.topicTypeName"
          :value="item.topicTypeId"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item class="item-content" label="专题名称：" prop="title">
      <el-input
        class="wp-input task-w726"
        v-model="basicsForm.title"
        placeholder="请输入专题名称"
      ></el-input>
    </el-form-item>

    <el-form-item class="item-content" label="专题积分：" prop="score">
      <div class="flex score">
        <div class="flex-CB task-w726">
          <el-input
            @input="scoreChange"
            @change="verifyScore"
            :class="customScore ? 'custom-text' : ''"
            class="wp-input task-w338"
            v-model.trim="customScore"
            placeholder="请输入专题积分"
            maxlength="6"
          ></el-input>

          <div
            v-for="item in scoreList"
            :key="item.custConfigId"
            class="score-item"
            @click="fastScore(item)"
            :class="{
              disabled: isDisbledPlatform && item.configValue == -999,
              'active-score':
                scoreId &&
                (customScore == '按平台' ? -999 : customScore) ==
                  item.configValue,
            }"
          >
            {{ item.configValue == -999 ? '按平台' : item.configValue }}
          </div>
        </div>

        <wp-question-mark
          class="question"
          content="按平台是根据单位指定平台获取对应的积分奖励，例如：央广网、央视网转载A积分、新浪新闻、搜狐新闻转载B积分，平台不同所得积分也不同。"
        ></wp-question-mark>
      </div>
    </el-form-item>
    <el-form-item class="item-content" label="起止时间：" prop="timeSection">
      <div class="flex-CB task-w726">
        <div class="wp-date-picker-single task-w436">
        <el-date-picker readonly prefix-icon="" value-format="yyyy-MM-dd HH:mm:ss" clearable
         v-model="basicsForm.startTime" type="datetime" placeholder="请选择开始时间">
        </el-date-picker>
        <span class="el-range-separator">-</span>
        <el-date-picker prefix-icon="" default-time="23:59:59"  
        value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="basicsForm.endTime" 
        type="datetime" placeholder="请选择结束时间" :picker-options="pickerOptionsDatetime" @change="changeDatetime">
        </el-date-picker>
      </div>

        <div
          v-for="item in timeList"
          :key="item.configValue"
          class="time-item"
          :class="
            basicsForm.timeRangeType == item.configName ? 'active-time' : ''
          "
          @click="handlerTimeSection(item)"
        >
          {{ item.configName }}
        </div>
      </div>
    </el-form-item>

    <el-form-item label="专题描述：" prop="content">
      <el-input
        class="task-w726 textarea"
        type="textarea"
        maxlength="1000"
        :rows="6"
        v-model="basicsForm.content"
        placeholder="请输入专题描述"
      ></el-input>
    </el-form-item>

    <el-form-item label="专题附件：">
      <UploadFile
        :caution="false"
        style="margin-top: 3px"
        key="image"
        :fileList="basicsForm.fileJson"
        @fileChange="fileChange"
        @changeUpload="changeUpload"
      ></UploadFile>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex';
import reportedApi from '@/api/reported'
import UploadFile from '@/views/components/reportedUpload'
import { resetNumPointOne } from '@/utils/validate.js';
export default {
  components: { UploadFile },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      basicsForm: {
        topicTypeId: "", // 专题分类
        fileJson: "", // 文件
        content: "", // 专题描述
        title: "", // 专题名称
        score: "", // 专题积分
        timeSection: "", // 专题起止时间
        longItem: "", // 专题是否长期
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        timeRangeType: "", // 时间快捷项类型
      },
      // 专题积分列表
      scoreList: [],
      // 起止时间
      timeList: [],
      imageUrl: "",
      basicsFormRules: {
        topicTypeId: [{ required: true, trigger: "blur", message: "请选择专题分类" }],
        // fileJson: [{ required: true, trigger: "blur", message: "请上传jpg、jpeg、png、mp4格式文件" }],
        timeSection: [{ required: true, trigger: "blur", message: "请选择起止时间" }],
        title: [{ required: true, trigger: "blur", message: "请输入专题名称" }],
        content: [{ required: true, trigger: "blur", message: "请填写专题描述" }],
        score: [{ required: true, trigger: ["blur", "change"], message: "请输入专题积分" }],
      },
      classifyList: [], // 任务分类列表
      timeSectionId: "",
      customScore: "",
      isDisbledPlatform: false,
      scoreId: "",
      isUpload: false,
      startTimeTemp:'',
      pickerOptionsDatetime:{
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },

  created () {
    // 获取分类列表
    this.getClassifyList()
  },

  computed: {
    ...mapGetters(['sysCust'])
  },

  watch: {
    formData: {
      handler (newVal) {
        if (newVal) {
          console.log(newVal);
          this.basicsForm = { ...newVal }
          this.customScore = newVal.score == -999 ? '按平台' : newVal.score
          this.basicsForm.fileJson = JSON.parse(newVal.fileJson)
          this.setTimeSection(newVal)
          // 获取单位默认配置
          this.getConfigList()
          // 获取单位配置网站列表
          this.getWebsiteList()
        }
      },
      deep: true,
    }
  },
  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      this.isUpload = isUpload
    },

    /** 获取单位配置网站列表 */
    async getWebsiteList () {
      try {
        const res = await reportedApi.getWebsiteList({ custId: this.sysCust.custId })

        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.isDisbledPlatform = res.returnData.length > 0 ? false : true

        console.log(this.isDisbledPlatform, this.basicsForm.isDisbledPlatform);
        if (this.basicsForm.isDisbledPlatform) {
          this.isDisbledPlatform = this.basicsForm.isDisbledPlatform
        }
      } catch (error) {
        console.log(error)
      }
    },

    /** 文件上传成功回调 */
    fileChange (fileList) {
      this.basicsForm.fileJson = fileList
    },

    // 处理时间快捷项回显
    setTimeSection (formData) {
      console.log(formData)
      if (formData.timeRangeType == "不限") {
        this.startTimeTemp = this.basicsForm.startTime
        this.$set(this.basicsForm, 'timeSection', [this.basicsForm.startTime, this.basicsForm.endTime])
        this.$set(this.basicsForm, 'startTime',this.basicsForm.startTime)
        this.$set(this.basicsForm, 'endTime',this.basicsForm.endTime)
        
        console.log(this.basicsForm);
      } else {
        this.$nextTick(() => {
          this.startTimeTemp = formData.startTime
          this.$set(this.basicsForm, 'timeSection', [formData.startTime, formData.endTime])
          this.$set(this.basicsForm, 'startTime',formData.startTime)
          this.$set(this.basicsForm, 'endTime',formData.endTime)
          console.log(this.basicsForm);
        })
      }
    },


    // 获取表单数据
    getFormData () {
      if (this.isUpload) {
        this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
      } else {
        let flag = false
        this.$refs.basicsFormRef.validate(valid => {
          if (valid) {
            flag = true
          } else {
            flag = false
          }
        })
        if (flag) {
          return this.basicsForm
        }
      }
    },

    // 校验自定义分数
    verifyScore (e) {
      this.basicsForm.score = resetNumPointOne(e, 0, 10000)
      this.$emit("platformScore", this.basicsForm.score)
      this.customScore = this.basicsForm.score
    },

    scoreChange () {
      this.scoreId = ""
    },


    // 积分快捷选项回调
    fastScore (item) {
      const score = item.configValue
      if (this.isDisbledPlatform && score == -999) return
      this.scoreId = item.custConfigId
      this.customScore = score == -999 ? '按平台' : score
      this.basicsForm.score = score
      this.$refs['basicsFormRef'].fields[2].validateState = 'success'
      this.$emit("platformScore", score)
    },

    // 获取分类列表
    async getClassifyList () {
      const res = await reportedApi.topicTypeList({})
      if (res.returnCode == 200) {
        this.classifyList = res.returnData || []
      }
    },

    // 获取单位默认参数
    async getConfigList () {
      const res = await reportedApi.getConfigList({})
      if (res.returnCode == 200) {
        const data = res.returnData

        // 积分列表
        this.scoreList = data.subjectScore || []

        console.log(this.basicsForm.score);
        const scoreValue = this.scoreList.find(it => it.configValue == this.basicsForm.score) || {}

        // 没匹配中就是手动输入的积分
        if (!scoreValue?.custConfigId) {
          this.customScore = this.basicsForm.score
        } else {
          this.scoreId = scoreValue.custConfigId
        }

        // 起止时间快捷选项
        this.timeList = data.subjectStartEndTime
        // 起止时间拼接名称
        data.subjectStartEndTime[0].configName = data.subjectStartEndTime[0].configValue + data.subjectStartEndTime[0].configUnitStr
      }
    },

    // 选择快捷项回调
    handlerTimeSection (item) {
      console.log(item);
      if (item.configName == '不限') {
        this.basicsForm.longItem = 1
        // 当前时间
        this.basicsForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.startTimeTemp = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.basicsForm.endTime = '9999-01-01 00:00:00'
        this.$set(this.basicsForm, 'timeSection', [this.basicsForm.startTime, this.basicsForm.endTime])
      } else if (item.configName == '月底') {
        this.basicsForm.longItem = 0
        // 当前时间
        this.basicsForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.startTimeTemp = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.basicsForm.endTime = this.$moment().endOf('month').format('yyyy-MM-DD HH:mm:ss')
        this.$set(this.basicsForm, 'timeSection', [])
        this.$set(this.basicsForm, 'timeSection', [this.basicsForm.startTime, this.basicsForm.endTime])
      } else {
        this.basicsForm.longItem = 0
        let unit = ""
        if (item.configUnit == 1) {
          unit = "hours"
        } else {
          unit = "days"
        }
        // 当前时间
        this.basicsForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.startTimeTemp = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.basicsForm.endTime = this.$moment().add(+item.configValue, unit).format("YYYY-MM-DD HH:mm:ss")
        this.$set(this.basicsForm, 'timeSection', [])
        this.$set(this.basicsForm, 'timeSection', [this.basicsForm.startTime, this.basicsForm.endTime])
      }

      // 选中项类型
      this.basicsForm.timeRangeType = item.configName
    },

    // 提交表单
    submitForm () {
      // 任务积分
      this.basicsForm.score = this.customScore == '按平台' ? -999 : this.customScore
      this.$refs.basicsForm.validate(valid => {
        if (valid) {
          console.log('校验成功')
          this.$emit("submitBasicsForm", this.basicsForm)
        } else {
          console.log('校验失败')
        }
      })
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.basicsForm.resetFields()
    },
    changeDatetime(val){
      // 所选时间类型
      this.basicsForm.timeRangeType = ""
      this.basicsForm.longItem = 0
      if (val) {
        let startTime = this.startTimeTemp
        let endTime = val
        if(this.$moment(endTime).diff(this.$moment(startTime)) < 0){
          endTime= startTime
        }
        this.basicsForm.timeSection = [startTime,endTime]
        this.basicsForm.startTime =startTime
        this.basicsForm.endTime = endTime
      } else {
        this.basicsForm.timeSection = []
        this.basicsForm.endTime = ""
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>