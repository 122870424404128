<template>
  <div class="addPeople">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
            <el-breadcrumb-item :to="{ name: 'teamlist' }">人员队伍</el-breadcrumb-item>
            <el-breadcrumb-item>{{
              isEidt ? '编辑人员' : '添加人员'
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 个人信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-block-top">
            <div class="layout-title">个人信息</div>
            <!-- 编辑 -->
            <div v-if="isEidt">
              <el-button class="wp-button red-btn" size="medium" @click="resetPassword">重置密码</el-button>
            </div>
            <!-- 添加 -->
            <div class="init-pass" v-else>初始密码：<span>abc123456</span></div>
          </div>

          <div class="layout-content">
            <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="100px" class="ruleform">
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="人员姓名：" prop="userName">
                    <el-input class="wp-input" v-model.trim="ruleForm1.userName" clearable placeholder="请输入人员姓名" maxlength="20">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <!-- 默认“男”，筛选项包括“男、女” -->
                  <el-form-item label="人员性别：" prop="sex">
                    <el-radio-group v-model="ruleForm1.sex">
                      <el-radio :label="item.value" v-for="item in $globalData.genderList" :key="item.value">
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="登录账号：" prop="loginName">
                    <el-input class="wp-input" v-model.trim="ruleForm1.loginName" clearable placeholder="请输入登录账号" maxlength="20">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="手机号码：" prop="mobile">
                    <el-input class="wp-input" v-model.trim="ruleForm1.mobile" clearable placeholder="请输入手机号码" maxlength="11" @input="resetNumMobile">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <!-- 筛选项包括“组员、组长” -->
                  <el-form-item label="人员角色：" prop="roleId">
                    <el-select class="wp-select" filterable style="width: 100%" v-model="ruleForm1.roleId" clearable placeholder="请选择人员角色">
                      <el-option v-for="item in $globalData.roleList" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="所在分组：" prop="groupId">
                    <el-cascader class="wp-select" placeholder="请选择所在分组" v-model="ruleForm1.groupId" :options="asideList" :props="props" clearable @change="changeGroup"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="单位名称：" prop="custName">
                    <el-input class="wp-input" v-model.trim="ruleForm1.custName" clearable placeholder="请输入单位名称" maxlength="20">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item class="wp-input" label="设为常用：">
                    <el-select class="wp-select" style="width: 100%" v-model="ruleForm1.commonGroupIds" clearable placeholder="请选择常用人员所在分组" multiple collapse-tags>
                      <el-option v-for="item in commonGroupList" :key="item.commonId" :label="item.commonName" :value="item.commonId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <!-- 自媒体账号 -->
        <div class="layout-block wp-moudle-style">
          <div class="mask" @click="isShowRuleForm2 = !isShowRuleForm2"></div>
          <div class="layout-block-top">
            <div class="layout-title">自媒体账号</div>
            <div class="show-btn">
              {{ isShowRuleForm2 ? '收起' : '展开' }}
              <i :class="{
                  'el-icon-arrow-up': isShowRuleForm2,
                  'el-icon-arrow-down': !isShowRuleForm2,
                }"></i>
            </div>
          </div>
          <el-collapse-transition>
            <div class="layout-content" v-show="isShowRuleForm2">
              <el-form ref="ruleForm2" label-width="100px" class="ruleform">
                <el-row type="flex" justify="space-between" v-for="(item, index) in mediaList" :key="index">
                  <el-col :span="11" v-for="it in item" :key="it.userMediaId">
                    <el-form-item :label="it.mediaName + '：'">
                      <el-input class="wp-input" v-model.trim="it.nickName" clearable placeholder="请输入账号昵称" maxlength="20" :disabled="it.bindStatus == 1">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>

        <!-- 档案材料 -->
        <div class="layout-block wp-moudle-style">
          <div class="mask" @click="isShowRuleForm3 = !isShowRuleForm3"></div>
          <div class="layout-block-top">
            <div class="layout-title">档案材料</div>
            <div class="show-btn">
              {{ isShowRuleForm3 ? '收起' : '展开' }}
              <i :class="{
                  'el-icon-arrow-up': isShowRuleForm3,
                  'el-icon-arrow-down': !isShowRuleForm3,
                }"></i>
            </div>
          </div>
          <el-collapse-transition>
            <div class="layout-content" v-show="isShowRuleForm3">
              <el-form label-width="100px" class="ruleform">
                <el-form-item label="档案材料：">
                  <FileUpload ref="fileUpload" :datas="dataList" :fileType="fileType" :limit="5" :size="5" @change-file-list="changeFileList"></FileUpload>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>

        <!-- 银行信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="mask" @click="isShowRuleForm4 = !isShowRuleForm4"></div>
          <div class="layout-block-top">
            <div class="layout-title">银行信息</div>
            <div class="show-btn">
              {{ isShowRuleForm4 ? '收起' : '展开' }}
              <i :class="{
                  'el-icon-arrow-up': isShowRuleForm4,
                  'el-icon-arrow-down': !isShowRuleForm4,
                }"></i>
            </div>
          </div>
          <el-collapse-transition>
            <div class="layout-content" v-show="isShowRuleForm4">
              <el-form :model="ruleForm3" ref="ruleForm3" label-width="100px" class="ruleform">
                <el-row type="flex" justify="space-between">
                  <el-col :span="11">
                    <el-form-item label="开户银行：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.bankName" clearable placeholder="请输入开户银行" maxlength="20">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="银行账号：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.bankCode" clearable placeholder="请输入银行账号" maxlength="20" @input="resetNumBankCode">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="11">
                    <el-form-item label="开户行行号：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.openBankCode" clearable placeholder="请输入开户行行号" maxlength="100">
                      </el-input>
                    </el-form-item>
                  </el-col>

                </el-row>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>

        <!-- 值班账号 -->
        <div class="layout-block wp-moudle-style" v-if="sysUserInfo.sysCustOption.dutyState == 1">
          <div class="layout-block-top">
            <div class="layout-title">值班账号</div>
            <div>
              <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-tianjia" type="primary" @click="addAccount" v-if="childUserlist.length < 10">添加账号
              </el-button>
            </div>
          </div>

          <div class="layout-content-account">
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="childUserlist" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="70" align="center">
                </el-table-column>
                <el-table-column min-width="60" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" label="人员姓名" min-width="200" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="loginName" label="登录账号" min-width="200" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号码" min-width="200" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150" align="center">
                  <template slot-scope="scope">
                    <div class="flex-center">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big" @click="eidtAccount(scope.row)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="重置密码" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-chongzhimima" class="wp-btn-icon-big" @click="resetPasswordItem(scope.row)" :disabled="!scope.row.userId">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="删除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big" @click="delItem(scope.row)">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="childUserlist.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="layout-block wp-moudle-style btn-block">
          <el-button class="wp-button" size="medium" @click="goback">取消</el-button>
          <el-button class="wp-button" size="medium" v-preventReClick type="primary" @click="validateFrom">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
    <add-account ref="addAccount" @success="addAccountItem" :accountItem="accountItem" :childUserlist="childUserlist" :loginUser="ruleForm1" :isEidtUser="isEidt">
    </add-account>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { resetNum, isPhone, isEnAndNumAndUnderline, resetNumber } from '@/utils/validate.js';
import FileUpload from '@/views/personnelTeam/components/FileUploadUser.vue';
import AddAccount from '@/views/personnelTeam/components/addAccount.vue';
import * as personnelTeamApi from '@/api/personnelTeam.js'
export default {
  name: 'createUnits',
  components: { FileUpload, AddAccount },
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback('请输入手机号码');
      } else if (!isPhone(value)) {
        callback("请输入有效手机号码");
      } else {
        callback();
      }
    };
    var validateLoginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录账号'));
      } else if (!isEnAndNumAndUnderline(value)) {
        callback(new Error('请输入英文或数字'));
      } else {
        callback();
      }
    };
    return {
      isShowRuleForm2: false,
      isShowRuleForm3: false,
      isShowRuleForm4: false,
      fileType: ['jpg', 'jpeg', 'png'],
      dataList: [],
      fileData: [],
      loading: false,
      ruleForm1: {
        userName: "",
        sex: "1",
        loginName: "",
        mobile: "",
        roleId: '4',
        groupId: null,
        custName: "",
        commonGroupIds:[]
      },
      rules1: {
        sex: [
          { required: true, message: '请选择人员性别', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateLoginName, trigger: 'blur' }
        ],
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
        roleId: [
          { required: true, message: '请选择人员角色', trigger: 'change' }
        ],
        groupId: [
          { required: true, message: '请选择所在分组', trigger: 'change' }
        ],
        custName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ]
      },
      isEidt: false,
      ruleForm3: {
        bankName: '',
        bankCode: '',
        openBankCode: ''
      },
      props: {
        value: 'groupId',
        label: 'groupName',
        children: 'sysGroupList'
      },
      childUserlist: [], // 值班账号列表
      asideList: [], // 分组树
      mediaList: [], // 添加的时候 的 媒体列表
      accountItem: {},// 编辑的时候的 值班账号对象
      userObj: {},// 编辑用户的对象
      userId: '',// 编辑用户的id，
      allUserObj: {},// 所有的用户信息
      defaultGroupId: '', // 默认选中的分组
      isPosting: false,// 是否正在提交中
      commonGroupList:[] // 常用人员分组
    };
  },
  props: {},
  created () {
    if (this.$route.query.userId) {
      this.isEidt = true
      this.userId = this.$decrypt(this.$route.query.userId)
      this.getDetail()
    } else {
      if (this.$route.query.groupId) {
        this.defaultGroupId = this.$decrypt(this.$route.query.groupId)
      }
      this.wpCommonGroupList()
      this.getAsideList()
      this.initData()
    }

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['sysUserInfo'])
  },
  methods: {
    initData () {
      if (!this.isEidt) {
        this.getUserMediaList()
      }
    },
    // 获取用户信息
    async getDetail () {
      try {
        let res = await personnelTeamApi.getSysUser({ userId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.userObj = res.returnData.sysUser
        this.allUserObj = res.returnData
        this.setInitValue()
        this.getAsideList()
        this.wpCommonGroupList()
      } catch (error) {
        console.log(error)
      }

    },
    // 设置初始值
    setInitValue () {
      this.ruleForm1.userName = this.userObj.userName
      this.ruleForm1.sex = this.userObj.sex?.toString()
      this.ruleForm1.loginName = this.userObj.loginName
      this.ruleForm1.mobile = this.userObj.mobile
      this.ruleForm1.roleId = this.userObj.roleId?.toString()
      this.ruleForm1.custName = this.userObj.custName?.toString()
      this.ruleForm3.bankName = this.userObj.bankName
      this.ruleForm3.bankCode = this.userObj.bankCode
      this.ruleForm3.openBankCode = this.userObj.openBankCode
      // 文件的回显
      // archive  this.userObj.archive
      this.dataList = this.userObj.archive ? JSON.parse(this.userObj.archive) : []
      this.fileData = this.userObj.archive ? JSON.parse(this.userObj.archive) : []

      /**
       * 优先获取最新的APP绑定账号昵称。
        * 若为自动获取已绑定的账号，置灰不可编辑；若为手动填写的可进行编辑修改 *
       **/
      this.mediaList = this.$utils.supGroup(this.allUserObj.sysUserMediaList, 2)
      let childUserlist = []
      this.allUserObj.sysUserList.forEach(item => {
        childUserlist.push({ ...item, id: item.userId })
      })
      this.childUserlist = childUserlist
      console.log(this.childUserlist)


    },
    // 添加时  获取媒体平台
    async getUserMediaList () {
      try {
        let res = await personnelTeamApi.getUserMediaList({})
        if (Number(res.returnCode) !== 200) return
        this.mediaList = this.$utils.supGroup(res.returnData, 2)
      } catch (error) {
        console.log(error)
      }
    },
    validateFrom () {
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //文件上传 文件改变
    changeFileList: function (fileList) {
      console.log(fileList)
      this.fileData = fileList
      // this.reportForm.fileInfo = fileList;
    },
    // 重置密码
    resetPassword () {
      this.$wpConfirm('重置密码', {
        tips: "确定重置密码吗？",
        content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postResetPassword(this.userObj)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 重置密码
    resetPasswordItem (item) {
      this.$wpConfirm('重置密码', {
        tips: "确定重置密码吗？",
        content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postResetPassword(item)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postResetPassword (item) {
      try {
        let res = await personnelTeamApi.resetPass({ userId: item.userId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        // this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    // 删除值班账号
    delItem (item) {
      this.$wpConfirm('提示', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postDelItem(item)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 删除 账号
    postDelItem (item) {
      let index = this.childUserlist.findIndex(it => it.id == item.id)
      if (index != -1) {
        this.childUserlist.splice(index, 1)
        this.$showMessage({
          type: 'success',
          msg: '操作成功'
        })
      } else {
        this.$showMessage({
          type: 'error',
          msg: '操作失败'
        })
      }
    },
    // 添加值班账号
    addAccount () {
      this.accountItem = {}
      this.$refs.addAccount.show()
    },
    // 编辑值班账号
    eidtAccount (item) {
      this.accountItem = item
      this.$refs.addAccount.show()
    },
    // 获取侧边栏列表
    async getAsideList () {
      let query = { groupName: '' }
      try {
        let res = await personnelTeamApi.listTree(query)
        if (Number(res.returnCode) !== 200) return
        this.asideList = res.returnData
        this.$nextTick(() => {
          if (this.isEidt || this.defaultGroupId) {
            let defaultGroupId = this.userObj?.groupId || this.defaultGroupId
            defaultGroupId = Number(defaultGroupId)
            res.returnData.forEach(item => {
              if (defaultGroupId == item.groupId) {
                this.ruleForm1.groupId = [defaultGroupId]
                console.log(this.ruleForm1.groupId)
              }
              if (item.sysGroupList && item.sysGroupList.length > 0) {
                item.sysGroupList.forEach(it => {
                  if (defaultGroupId == it.groupId) {
                    this.ruleForm1.groupId = [item.groupId, defaultGroupId]
                  }
                })
              }
            })
          }
        })

      } catch (error) {
        console.log(error)
      }
    },
    // 添加或者编辑值班账号
    addAccountItem (data) {
      // console.log(data)
      let index = this.childUserlist.findIndex(it => it.id == data.id)
      // console.log(index)
      if (index != -1) {
        this.childUserlist.splice(index, 1, data)
      } else {
        this.childUserlist = [...this.childUserlist, data]
      }
      console.log(this.childUserlist)
    },
    goback () {
      this.$router.push('/teamlist')
    },
    // 添加或者编辑用户
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let userMediaList = []
      this.mediaList.forEach(item => {
        userMediaList.push(...item)
      })

      let query = {
        ...this.ruleForm1,
        ...this.ruleForm3,
        archive: JSON.stringify(this.fileData),
        groupId: this.ruleForm1.groupId[this.ruleForm1.groupId.length - 1],
        userMediaList: userMediaList,
        childUserList: this.childUserlist,
        isweb: true
      }

      let res = null
      try {
        if (this.isEidt) {
          query.userId = this.userId
          query.custId = this.allUserObj.sysCust.custId
          res = await personnelTeamApi.updateSysUser(query)
        } else {
          res = await personnelTeamApi.insertSysUser(query)
        }
        this.isPosting = false

        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: '操作成功'
        })
        this.goback()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    changeGroup () {
      console.log(this.ruleForm1.groupId)
    },
    // 手机号重置 值
    resetNumMobile () {
      this.ruleForm1.mobile = resetNum(this.ruleForm1.mobile);
    },
    // 银行账号 只能输入数字
    resetNumBankCode () {
      this.ruleForm3.bankCode = resetNumber(this.ruleForm3.bankCode);
    },
      // 获取常用人员列表
      async wpCommonGroupList(){
      try {
        let res = await personnelTeamApi.wpCommonGroupList({})
        if (Number(res.returnCode) !== 200) return
        this.commonGroupList = res.returnData.map(it=>{return {commonId:it.commonId,commonName:it.commonName}})
        this.$nextTick(() => {
          if (this.isEidt) {
            this.ruleForm1.commonGroupIds = this.userObj.commonGroups ? this.userObj.commonGroups.map(i=>i.commonGroupId):[]
          }
        })
      } catch (error) {
        console.log('wpCommonGroupList',error)
      }
    }

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/addPeople.scss";
</style>
