var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mt-16 flex",
      style: { height: _vm.foldFlag ? "1280px" : "1180px" },
    },
    [
      _c(
        "div",
        { staticClass: "task-aside" },
        [
          _c(
            "div",
            { staticClass: "head-btn fs-14", on: { click: _vm.inStep } },
            [
              _c("span", [_vm._v("一键同步")]),
              _c("wp-question-mark", {
                staticClass: "question-mark",
                attrs: {
                  content:
                    "将当前链接下的任务信息更新到所有链接对应的信息项上（除任务标题和任务链接以外）",
                },
              }),
            ],
            1
          ),
          _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
            _c(
              "div",
              { staticClass: "task-list" },
              _vm._l(_vm.taskInfoArr, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "task-item fs-14",
                    class: _vm.activeTask == index ? "active_item" : "",
                    on: {
                      click: function ($event) {
                        return _vm.clickTaskTitle(item, index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "index" }, [
                      _vm._v(" " + _vm._s(index + 1) + " "),
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          tabindex: item.id,
                          disabled: !_vm.isShowTooltipWidth,
                          content: item.basicsForm.title,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title textOverOneLine",
                            class: item.error ? "red" : "",
                            on: {
                              mouseenter: function ($event) {
                                return _vm.visibilityChangeWidth($event)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.basicsForm.title) + " ")]
                        ),
                      ]
                    ),
                    _c("i", {
                      staticClass: "icon-guanbi",
                      on: {
                        click: function (event) {
                          return _vm.removeItem(event, index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "content ml-16" }, [
        _c(
          "div",
          { staticClass: "wp-task-card" },
          [
            _c("div", { staticClass: "card-title fs-16 fw-bold" }, [
              _vm._v("基础设置"),
            ]),
            _c("BasicsForm", {
              ref: "basicsFormRef",
              staticClass: "center",
              attrs: { formData: _vm.taskInfo.basicsForm },
              on: {
                updateTitle: _vm.updateTitle,
                getStyleList: _vm.getStyleList,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wp-task-card mt-16" },
          [
            _c("div", { staticClass: "card-title flex-CB" }, [
              _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("任务设置")]),
              _c(
                "div",
                { staticClass: "flex-Acenter instancy-task" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "isUrgent",
                      model: {
                        value: _vm.taskInfo.isUrgent,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskInfo, "isUrgent", $$v)
                        },
                        expression: "taskInfo.isUrgent",
                      },
                    },
                    [_vm._v("紧急任务")]
                  ),
                  _c("wp-question-mark", {
                    staticStyle: { "margin-left": "6px" },
                    attrs: { content: "任务发出后APP会强提醒30秒" },
                  }),
                ],
                1
              ),
            ]),
            _c("TaskForm", {
              ref: "taskFormRef",
              attrs: {
                configObj: _vm.configObj,
                formData: _vm.taskInfo.taskForm,
                taskStyleList: _vm.taskStyleList,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "wp-task-card mt-16",
            style: { height: _vm.foldFlag ? "174px" : "74px" },
          },
          [
            _c("div", { staticClass: "card-title flex-CB" }, [
              _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("高级选项")]),
              _c(
                "div",
                {
                  staticClass: "wp-blue toggle",
                  on: {
                    click: function ($event) {
                      _vm.foldFlag = !_vm.foldFlag
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(!_vm.foldFlag ? "展开" : "收起"))]),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.foldFlag,
                        expression: "foldFlag",
                      },
                    ],
                    staticClass: "el-icon-arrow-up fs-14 wp-blue",
                    staticStyle: { "margin-left": "6px" },
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.foldFlag,
                        expression: "!foldFlag",
                      },
                    ],
                    staticClass: "el-icon-arrow-down fs-14 wp-blue",
                    staticStyle: { "margin-left": "6px" },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.foldFlag,
                    expression: "foldFlag",
                  },
                ],
                staticClass: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "layout-content" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "advanced-form",
                        attrs: { "label-width": "100px" },
                      },
                      _vm._l(_vm.taskInfo.advancedSetting, function (item) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.custConfigId,
                            attrs: { label: item.configName + "：" },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: item.isDisbled },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkboxChangeShow(item)
                                  },
                                },
                                model: {
                                  value: item.isShow,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isShow", $$v)
                                  },
                                  expression: "item.isShow",
                                },
                              },
                              [_vm._v("显示 ")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: item.isDisbled },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkboxChangeRequired(item)
                                  },
                                },
                                model: {
                                  value: item.isRequired,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isRequired", $$v)
                                  },
                                  expression: "item.isRequired",
                                },
                              },
                              [_vm._v("必填 ")]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "wp-btn-card mt-16 wp-center" },
          [
            _c(
              "el-button",
              { staticClass: "wp-button", on: { click: _vm.cancel } },
              [_vm._v("上一步")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "wp-button",
                staticStyle: { "margin-left": "24px" },
                attrs: { type: "primary" },
                on: { click: _vm.nextStep },
              },
              [_vm._v("下一步 ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }