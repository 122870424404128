import store from '@/store'


export default {
  watch: {

  },
  beforeMount () {

  },
  beforeDestroy () {

  },
  mounted () {

  },
  methods: {
    // 验证是否已登录 已登录就跳转 没有登录就清除缓存
    getInfo () {
      let that = this
      return new Promise((resolve, reject) => {
        this.$store.dispatch('GetInfo').then(res => {
          // 清除之后 好跳转
          that.$store.dispatch('ClearUser')
          if (Number(res.returnCode) !== 200) return resolve(false)
          if (res.returnData.firstLogin) {
            // 首次登录 清除token
            that.$store.dispatch('LogOut')
            that.$utils.clearCookie()
            sessionStorage.removeItem("notif")
            // 如果当前不在登录页则跳转登录页
            if (that.$route.path != "/login") {
              that.$router.push({
                name: 'login'
              })
            }
            return resolve(false)
          }
          let currRoleId = res.returnData.sysUser.roleId
          this.goToPage(currRoleId)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 不同的角色的，页面跳转
    goToPage (currRoleId) {
      console.log('goToPage', currRoleId)
      const query = this.$route.query
      if (currRoleId == 1) {
        this.$router.push({
          name: 'unitManagList'
        })
      } else if (currRoleId == 2) {
        console.log(query);
        // 湘江新区一键发网评
        if (query.type == 1) {
          this.$router.push({
            name: 'addTask',
            query: {
              storeId: query?.id
            }
          })
        } else {
          this.$router.push({
            name: 'homeAdmin'
          })
        }
      } else {
        this.$router.push({
          name: 'home'
        })
      }
    }
  }
}
