<template>
  <div class="switchLogin">

    <div class="switchLoginBox">
      <div class="select-box">
        <div class="select-title">请选择进入系统身份</div>
        <div class="select-items">
          <div class="arrow-block-box" v-if="carouselData.length > 1">
            <div class="left-arrow arrow-block" @click="goNext(-1)">
              <i class="icon-leftarrow"></i>
            </div>
          </div>

          <div class="center-carousel">
            <el-carousel ref="leftCarousel" :autoplay="false" :interval="5000" arrow="never" indicator-position="none">
              <el-carousel-item v-for="(item, index) in carouselData" :key="index">
                <!-- // 角色ID；1超级管理员；2管理员；3组长；4网评员 -->
                <ul>
                  <li class="li-box" v-for="it in item" :key="it.id">
                    <div class="select-it" @click="goToIndex(1, it)" v-preventReClick
                      v-if="it.roleId == 1 || it.roleId == 2">
                      <img src="~@/assets/images/login/guanliyuan.png" alt="" />
                      <p class="role-name">管理员</p>
                      <p class="bottom-info textOverOneLine">{{ it.aliasName }}-{{ it.userName }}</p>
                    </div>
                    <div class="select-it" @click="goToIndex(4, it)" v-preventReClick
                      v-if="it.roleId == 3 || it.roleId == 4">
                      <img src="~@/assets/images/login/wangpingyuan.png" alt="" />
                      <p class="role-name">投稿员</p>
                      <p class="bottom-info textOverOneLine">{{ it.aliasName }}-{{ it.userName }}</p>
                    </div>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="arrow-block-box" v-if="carouselData.length > 1">
            <div class="right-arrow arrow-block" @click="goNext(1)"><i class="icon-rightarrow"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ParticlesView></ParticlesView>
    <change-password ref="changePassword" @success="refreshData"></change-password>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import ChangePassword from '@/views/login/components/changePassword';
import ParticlesView from '@/views/login/components/particles.vue';
export default {
  name: 'switchLogin',
  components: { ParticlesView, ChangePassword },
  data () {
    return {
      carouselData: [],
      isLoding: false
    };
  },
  props: {},
  created () {
    sessionStorage.removeItem("notif")
    this.getUserList()
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['userList'])
  },
  methods: {
    // 点击 轮播界面的 上一页 下一页
    goNext: function (type) {
      if (type == -1) {
        this.$refs.leftCarousel.prev();
      } else if (type == 1) {
        this.$refs.leftCarousel.next();
      }
    },
    //将数组分成几组 array原数组，subGroupLength 一个数组的长度
    supGroup: function (array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    goToIndex: async function (roleType, item) {
      if (this.isLoding) return
      this.isLoding = true
      let query = {
        userId: item.userId,
        source: 'web'
      }
      let that = this
      this.$store.dispatch('LoginChangeUser', query).then(res => {
        // 
        if (Number(res.returnCode) !== 200) {
          that.isLoding = false
          that.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
          that.$store.dispatch('FedLogOut').then(() => {
            that.$router.push({
              name: 'login'
            })
          })
          return
        }
        // 第一次登录必须修改密码
        if (res.returnData.firstLogin) {
          that.isLoding = false
          return this.$refs.changePassword.show()
        }
        // that.$store.dispatch('GetInfo').then(res1 => {
        // 	console.log()
        // 	if (Number(res1.returnCode) !== 200) return this.$showMessage({
        // 		type: 'error',
        // 		msg: res1.returnMsg
        // 	})

        // })
        if (roleType == 1) {
          that.$router.push({
            name: 'homeAdmin'
          })
        } else {
          that.$router.push({
            name: 'home'
          })
        }
      })



    },
    // 修改密码成功
    refreshData () {
      let that = this
      //先登出 再 去登录页
      this.$store.dispatch('LogOut').then(() => {
        that.$router.push({ name: 'login' })
      }).catch(err => {
        console.log(err)
      })
    },
    getUserList () {
      let that = this
       if(this.userList.length > 0){
        that.carouselData = that.supGroup(that.userList.filter(it => it.roleId != 1), 4)
        return
      } 
      // 假如没有获取到用户
      this.$store.dispatch('GetUserList').then(result => {
        // console.log(result)
        if (Number(result.returnCode) !== 200) {
          return false
        }
        that.carouselData = that.supGroup(that.userList.filter(it => it.roleId != 1), 4)
      })
    }
  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import "./css/switchLogin.scss";
</style>
