<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <el-breadcrumb style="margin: 20px 24px 0" separator-class="el-icon-arrow-right" class="wp-breadcrumb">
        <el-breadcrumb-item :to="{ path: '/taskReview' }">投稿审核</el-breadcrumb-item>
        <el-breadcrumb-item>作品详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="main">
        <ContentCard :title="taskDetail.title" slotValue="row-content">
          <template v-slot:row-content>
            <div class="key">
              任务编号：<span class="value">{{ taskDetail.taskNumber }}</span>
            </div>
            <div class="key">
              任务分类：<span class="value">{{ taskDetail.taskStyleStr }}</span>
            </div>
            <div class="key">
              任务站点：<span class="value">{{ taskDetail.websiteName }}</span>
            </div>
            <div class="key">
              任务方式：<span class="value">{{ taskDetail.taskWay }}</span>
            </div>
            <div class="key">
              任务积分：<span class="score">{{ taskDetail.score }}</span>
            </div>
          </template>
        </ContentCard>

        <ContentCard class="mt-16" slotValue="vertical-content">
          <template v-slot:vertical-content>
            <div class="item" v-if="articleDetail.title">
              <div class="key">作品标题：</div>
              <p class="value">{{ articleDetail.title }}</p>
            </div>

            <div class="item mt-12 flex" v-if="articleDetail.url">
              <div class="key" style="min-width: 73px">作品链接：</div>
              <p class="url textOverOneLine" @click="$utils.download(articleDetail.url)">
                {{ articleDetail.url }}
              </p>
            </div>

            <div class="work-content" v-if="articleDetail.content">
              <div class="key">作品内容：</div>
              <p class="value">
                {{ articleDetail.content }}
              </p>
            </div>

            <div class="file-list" v-if="articleDetail.fileJson && articleDetail.fileJson.length">
              <div class="key">截图/附件：</div>
              <File :fileJson="articleDetail.fileJson"></File>
            </div>
          </template>
        </ContentCard>

        <div class="wp-card mt-16 footer">
          <div class="head">
            <div class="title">审核意见</div>
            <div class="score-box">
              <div>
                奖励积分：
                <span class="score">{{ articleDetail.rewardScore }}</span>
              </div>
              <el-popover ref="popoverDetailRef" @hide="hidePopover(articleDetail)" placement="top" trigger="click" width="220">
                <el-input-number @input.native="changeScore($event)" :min="0" :precision="1" :max="100" size="small" v-model="articleDetail.rewardScore" style="margin-bottom: 5px"></el-input-number>
                <el-button @click="submitScore(articleDetail, 'popoverDetailRef')" style="margin-left: 6px" type="primary" size="small">确定</el-button>
                <i slot="reference" @click="editScore(articleDetail)" class="icon-bianji1"></i>
              </el-popover>
            </div>
          </div>
          <div class="content" v-if="articleDetail.articleStatus == 1">
            <el-input type="textarea" :rows="3" placeholder="请输入审核意见" maxlength="50" v-model="rejectContent">
            </el-input>
          </div>
          <div class="comment" v-else>
            <div class="user">
              <el-avatar style="width: 32px; height: 32px" :src="headUrl"></el-avatar>
              <div class="user-name">{{ rejectDetail.createName }}</div>
              <div class="publish-time">{{ rejectDetail.createTime }}</div>
            </div>
            <div class="detail-content">
              {{ rejectDetail.resonRemark }}
            </div>
          </div>

          <div class="bottom">
            <div class="flex" v-if="articleDetail.articleStatus == 1">
              <el-button class="btn" size="medium" type="danger" @click="handlerReject">驳回</el-button>

              <el-popover placement="top" :visible-arrow="false" width="350px" trigger="click">
                <div class="popover-content">
                  <div class="grade-text" v-for="item in $globalData.STARLIST" :key="item.id" :class="
                      item.star == articleDetail.stars ? 'active-star' : ''
                    " @click="rateChange(item.star)">
                    {{ item.label }}
                  </div>
                  |
                  <el-button type="text" style="margin-left: 13px; color: #ff5959" @click="rateChange('0')">清空
                  </el-button>
                </div>
                <el-button v-show="articleDetail.stars == 0" style="margin-left: 20px" slot="reference" class="btn" size="medium" type="primary">质量评级</el-button>
              </el-popover>

              <div class="rate" style="margin-left: 20px" v-show="articleDetail.stars !== 0">
                质量评级：<span class="rate-text">{{
                  articleDetail.grade
                }}</span>
                <!-- 勾选了数据时 -->
                <el-popover placement="top" :visible-arrow="false" width="350px" trigger="click">
                  <div class="popover-content">
                    <div class="grade-text" v-for="item in $globalData.STARLIST" :key="item.id" @click="rateChange(item.star)">
                      {{ item.label }}
                    </div>
                    |
                    <el-button type="text" style="margin-left: 13px; color: #ff5959" @click="rateChange('0')">清空
                    </el-button>
                  </div>
                  <i slot="reference" class="icon-bianji1"></i>
                </el-popover>
              </div>
            </div>

            <div v-else>
              <div class="rate">
                质量评级：<span class="rate-text">{{
                  articleDetail.grade
                }}</span>
                <!-- 勾选了数据时 -->
                <el-popover placement="top" :visible-arrow="false" width="350px" trigger="click">
                  <div class="popover-content">
                    <div class="grade-text" v-for="item in $globalData.STARLIST" :key="item.id" @click="rateChange(item.star)">
                      {{ item.label }}
                    </div>
                    |
                    <el-button type="text" style="margin-left: 13px; color: #ff5959" @click="rateChange('0')">清空
                    </el-button>
                  </div>
                  <i slot="reference" class="icon-bianji1"></i>
                </el-popover>
              </div>
            </div>

            <div>
              <el-button @click="flipOver('pre')" class="wp-button" size="medium">上一条</el-button>
              <el-button @click="flipOver('next')" class="wp-button" size="medium">下一条</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div ref="hideDiv"></div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import File from '@/views/components/fileDetail'
import ContentCard from '@/views/components/contentCard'
export default {
  components: { ContentCard, File },
  data () {
    return {
      rejectContent: "", // 驳回内容
      textarea: "",
      scoreNum: 0, // 分数// 质量评级列表
      isDetail: false,
      articleDetail: {}, // 文章详情
      taskDetail: {}, // 任务详情
      rejectDetail: {}, // 驳回详情
      editRow: {},
      articleId: "", // 文章Id
      searchParams: {},
      preIds: [], // 当前数据上面的id列表
      nextIds: [], // 当前数据下面的id列表 
      headUrl: require("@/assets/images/touxiang.png")
    }
  },
  created () {
    console.log(this.$route.query)
    if (this.$route.query.articleId) {
      this.articleId = this.$route.query.articleId
      this.searchParams = JSON.parse(this.$route.query.params)
      this.getDetail()
    }
  },
  watch: {
    'articleDetail.stars': {
      handler (newVal) {
        console.log(newVal);
        if (this.articleDetail.stars == 3) {
          this.$set(this.articleDetail, 'grade', '优秀')
        } else if (this.articleDetail.stars == 2) {
          this.$set(this.articleDetail, 'grade', '良好')
        } else if (this.articleDetail.stars == 1) {
          this.$set(this.articleDetail, 'grade', '合格')
        } else if (this.articleDetail.stars == -1) {
          this.$set(this.articleDetail, 'grade', '不合格')
        } else {
          this.$set(this.articleDetail, 'grade', '未评级')
        }

        console.log(this.articleDetail.grade);
      },
    }
  },
  methods: {
    changeScore (e) {
      console.log(e.target.value)
      if ((e.target.value.indexOf('.') >= 0)) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 2)
      }
      console.log(e.target.value)
    },


    // 详情
    async getDetail () {
      const res = await taskApi.getArticleDetail({ articleId: this.articleId })
      if (res.returnCode == 200) {
        const data = res.returnData

        // 文章详情
        this.articleDetail = data?.wpTaskArticle || {}

        this.articleDetail.fileJson = JSON.parse(this.articleDetail.fileJson) || []

        // 任务详情
        this.taskDetail = data?.wpTaskMain || {}

        // 任务方式
        this.taskDetail.taskWay = data.wpTaskMain.modeStyleList.map(item => item.dictKey).join(',')

        if (this.articleDetail.stars == 3) {
          this.$set(this.articleDetail, 'grade', '优秀')
        } else if (this.articleDetail.stars == 2) {
          this.$set(this.articleDetail, 'grade', '良好')
        } else if (this.articleDetail.stars == 1) {
          this.$set(this.articleDetail, 'grade', '合格')
        } else if (this.articleDetail.stars == -1) {
          this.$set(this.articleDetail, 'grade', '不合格')
        } else {
          this.$set(this.articleDetail, 'grade', '未评级')
        }

        console.log(this.articleDetail.fileJson);
        // 驳回详情
        this.rejectDetail = data?.verify || {}
      }
    },

    /** 驳回 */
    async handlerReject () {
      if (this.rejectContent) {
        const query = {
          articleId: this.articleId,
          rejectMsg: this.rejectContent
        }
        const res = await taskApi.rejectArticle(query)
        if (res.returnCode == 200) {
          this.rejectContent = ""
          this.$showMessage({ type: 'success', msg: '操作成功' })
          this.getDetail()
          this.flipOver('next', true)
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      } else {
        this.$showMessage({ type: 'warning', msg: "请输入审核意见" })
      }
    },

    // 质量评级
    async rateChange (stars) {
      const query = {
        articleId: this.articleId,
        stars
      }
      const res = await taskApi.updateStars(query)
      if (res.returnCode == 200) {
        this.articleDetail.stars = stars
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
      }
    },

    // 隐藏弹窗回调
    hidePopover (item) {
      console.log(item.isSubmit);
      // 未点击确定按钮,则将该分数恢复至编辑前
      if (!item.isSubmit) {
        item.rewardScore = this.editRow.rewardScore
      } else {
        item.isSubmit = false
      }
    },

    handleClickItem () {
      this.$utils.hideImg()
    },

    // 提交编辑分数
    async submitScore (item, index) {
      console.log(item);
      let query = {
        articleId: item.taskArticleId,
        rewardScore: item.rewardScore
      }
      const res = await taskApi.updateScore(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', message: '操作成功' })
        // 手动关闭弹出框
        this.$refs[index].doClose()
        // 修改提交按钮状态
        item.isSubmit = true
      }
    },

    // 修改评分 
    editScore (item) {
      this.editRow = JSON.parse(JSON.stringify(item))
    },

    // 翻页
    async flipOver (mode, msgFlag) {
      const query = JSON.parse(JSON.stringify(this.searchParams))
      query.pageMode = mode
      query.taskArticleId = this.articleId
      const res = await taskApi.taskInfoArticleIds(query)
      if (res.returnCode == 200) {
        if (res?.returnData?.data?.length <= 1) {
          if (!msgFlag) {
            if (mode == 'next') {
              this.$showMessage({ type: "warning", msg: '已是最后一条' })
            } else {
              this.$showMessage({ type: "warning", msg: '已是第一条' })
            }
          }
        } else {
          this.articleId = res?.returnData?.data?.[1]
          console.log(this.articleId);
          this.getDetail()
        }

      } else {
        this.$showMessage({ type: "error", msg: res.returnMsg })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>