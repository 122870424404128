var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messageCenter" },
    [
      _c("div", { staticClass: "title-header" }, [
        _c("div", { staticClass: "title-header-left" }, [_vm._v(" 消息中心 ")]),
        _c(
          "div",
          { staticClass: "title-header-right flex-Acenter" },
          [
            _c(
              "el-input",
              {
                staticClass: "search-w190 wp-input",
                staticStyle: { "margin-right": "14px" },
                attrs: { placeholder: "请输入搜索的内容" },
                on: { change: _vm.searchData },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchData.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              },
              [
                _c("i", {
                  staticClass: "wp-input-icon icon-sousuo",
                  staticStyle: { "font-size": "24px" },
                  attrs: { slot: "prefix" },
                  slot: "prefix",
                }),
              ]
            ),
            _c(
              "el-button",
              {
                staticClass: "wp-button wp-btn-icon",
                attrs: {
                  type: "primary",
                  icon: "icon-tianjia",
                  size: "medium",
                },
                on: { click: _vm.showAddMessage },
              },
              [_vm._v("发布公告 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "layout-content" },
        [
          _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
            _c("div", { staticClass: "home-admin-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "wp-table",
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-text": "正在加载",
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "message-list" },
                    _vm._l(_vm.tableData, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: item.msgId,
                          staticClass: "wp-moudle-style",
                          class: { active: item.remindStatus == 1 },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.title,
                                placement: "top",
                                disabled: !_vm.isShowTooltipWidth,
                                "popper-class": "wp-visibilityPopper",
                                tabindex: index + 1000,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title textOverOneLine flex-Acenter",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.visibilityChangeWidth($event)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.remindStatus == 1,
                                        expression: "item.remindStatus == 1",
                                      },
                                    ],
                                    staticClass: "tixi",
                                    attrs: {
                                      src: require("@/assets/images/tixi.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(_vm._s(item.title)),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.content,
                                placement: "top",
                                disabled: !_vm.isShowTooltip,
                                "popper-class": "wp-visibilityPopper",
                                tabindex: index,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "introduction textOverTwoLine",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.visibilityChange($event)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "action-row" }, [
                            _c("div", { staticClass: "action-row-left" }, [
                              _c("p", [
                                _vm._v(
                                  "发布人：" +
                                    _vm._s(item.custName) +
                                    "-" +
                                    _vm._s(item.createName) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "时间：" +
                                    _vm._s(
                                      _vm
                                        .$moment(item.createTime)
                                        .format("YYYY-MM-DD HH:mm")
                                    )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "action-row-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "编辑",
                                      placement: "top",
                                      "popper-class": "wp-btn-tooltip",
                                      "visible-arrow": false,
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass:
                                        "wp-btn-icon-big wp-btn-icon-big-grey",
                                      attrs: {
                                        type: "text",
                                        icon: "icon-bianji1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.eidtMsg(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: " item",
                                    attrs: {
                                      effect: "dark",
                                      content: "删除",
                                      placement: "top",
                                      "popper-class": "wp-btn-tooltip",
                                      "visible-arrow": false,
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass:
                                        "wp-btn-icon-big wp-btn-icon-big-grey",
                                      attrs: {
                                        type: "text",
                                        icon: "icon-shanchu",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delMsg(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.tableData.length == 0 && !_vm.loading
                    ? _c("div", { staticClass: "wp-noData" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/noData.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "page-row" },
                [
                  _c("wp-pagination", {
                    attrs: { total: _vm.total, currentPage: _vm.query.page },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("add-message", {
        ref: "addMessage",
        attrs: { eidtObj: _vm.msgObj },
        on: { success: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }