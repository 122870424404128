<template>
  <div class="main flex">
    <AsideList
      ref="asideRef"
      :asideList="asideList"
      keyId="taskTypeId"
      label="taskTypeName"
      @handlerItem="handlerAsideItem"
      @handlerAsideSearch="handlerAsideSearch"
      @getTitle="getTitle"
      :loading="asideLoading"
    ></AsideList>

    <div class="ml-16 right-content">
      <el-scrollbar style="height: 100%">
        <SearchCard
          @handlerSearch="handlerSearch"
          class="search-card"
          :title="title"
        >
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker
                v-model="searchParams.timeSection"
                prefix-icon=""
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                clearable
                type="daterange"
                range-separator="-"
                start-placeholder="请选择开始时间"
                end-placeholder="请选择结束时间"
              >
              </el-date-picker>
            </div>

            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.taskNumber"
              clearable
              placeholder="请输入任务编号"
            >
            </el-input>

            <el-input
              class="search-w392 wp-input"
              v-model.trim="searchParams.title"
              clearable
              placeholder="请输入任务标题"
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.taskStyle"
              clearable
              placeholder="请选择任务类型"
            >
              <el-option
                v-for="item in taskTypeList"
                :key="item.dictId"
                :label="item.dataKey"
                :value="item.dataValue"
              >
              </el-option>
            </el-select>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.websiteId"
              clearable
              placeholder="请选择任务站点"
            >
              <el-option
                v-for="item in websiteList"
                :key="item.dictId"
                :label="item.dataKey"
                :value="item.dataValue"
              >
              </el-option>
            </el-select>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.modeStyle"
              clearable
              placeholder="请选择任务方式"
            >
              <el-option
                v-for="item in modeStyleList"
                :key="item.dictId"
                :label="item.dataKey"
                :value="item.dataValue"
              >
              </el-option>
            </el-select>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.taskStatus"
              clearable
              placeholder="请选择任务状态"
            >
              <el-option
                v-for="item in $globalData.TOPICSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16 table">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                任务数量：<span class="wp-blue">{{ taskTotal }}</span>
              </div>
            </div>
            <div class="flex">
              <el-button
                @click="exportFile"
                class="wp-button wp-btn-icon"
                size="medium"
                icon="icon-daochu"
                v-preventReClick="1500"
                >导出</el-button
              >
              <el-button
                @click="batchTask"
                class="wp-button wp-btn-icon"
                size="medium"
                icon="icon-piliang"
                >批量任务</el-button
              >
              <el-button
                @click="addTask"
                class="wp-button wp-btn-icon"
                type="primary"
                icon="icon-tianjia"
                size="medium"
                >创建任务
              </el-button>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="tableLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="taskList" stripe v-if="taskList.length !== 0">
              <el-table-column
                align="center"
                prop="taskNumber"
                label="任务编号"
                width="150"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="title"
                label="任务标题"
                width="320"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div
                    class="textOverOneLine active-color"
                    @click="goDetail(scope.row.taskId)"
                  >
                    {{ scope.row.title }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="websiteName"
                label="任务站点"
                width="160"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="dictKey"
                label="任务方式"
                width="130"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="seedNum"
                label="发送人数"
                width="100"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="receiveNum"
                label="接收人数"
                width="100"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="completeNum"
                label="完成人数"
                width="100"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="completionRate"
                label="完成率"
                width="100"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.completionRate }}%</div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="score"
                label="任务积分"
                width="100"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="createName"
                label="发布人"
                width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="发布时间"
                width="200"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="taskStatus"
                label="任务状态"
                width="120"
              >
                <template slot-scope="scope">
                  <div>
                    <div class="flex-center" v-if="scope.row.taskStatus == 1">
                      <div class="receive-circle"></div>
                      <div class="receive-color">已发布</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="disabled-circle"></div>
                      <div class="disabled-color">已结束</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                label="操作"
                width="110"
              >
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div class="wp-action-icon">
                      <el-tooltip
                        class="item"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                        effect="dark"
                        content="编辑"
                        placement="top"
                      >
                        <el-button
                          type="text"
                          @click="editTask(scope.row)"
                          icon="icon-bianji1"
                          class="wp-btn-icon-big"
                          :class="scope.row.taskStatus == 2 ? 'no-drop' : ''"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>

                    <el-tooltip
                      class="item"
                      :visible-arrow="false"
                      popper-class="wp-btn-tooltip"
                      effect="dark"
                      content="更多"
                      placement="top"
                    >
                      <el-dropdown
                        placement="bottom-end"
                        trigger="click"
                        class="wp-dropdown wp-action-icon"
                        @command="handleMoreEvent"
                      >
                        <i class="el-icon-more wp-blue"></i>
                        <el-dropdown-menu
                          slot="dropdown"
                          :visibleArrow="false"
                          class="wp-dropdown-menu"
                        >
                          <el-dropdown-item
                            v-if="scope.row.taskStatus == 1"
                            class="icon-style"
                            icon="icon-zhuijia"
                            :command="['add', scope.row]"
                            >追加人员</el-dropdown-item
                          >
                          <el-dropdown-item
                            v-if="scope.row.taskStatus == 1"
                            class="icon-style"
                            icon="icon-duanxin"
                            :command="['send', scope.row]"
                            >发送短信</el-dropdown-item
                          >
                          <el-dropdown-item
                            v-if="scope.row.taskStatus == 1"
                            class="icon-style"
                            icon="icon-jieshu"
                            :command="['over', scope.row]"
                            >结束任务</el-dropdown-item
                          >
                          <el-dropdown-item
                            icon="icon-shanchu"
                            class="icon-style"
                            :command="['remove', scope.row]"
                            >删除任务</el-dropdown-item
                          >
                          <el-dropdown-item
                            icon="icon-jietu"
                            class="icon-style"
                            :command="['export', scope.row]"
                            >截图导出</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="wp-noData" v-if="taskList.length == 0 && !tableLoading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="taskTotal"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </el-scrollbar>
    </div>

    <ClassifyDialog
      @submitForm="submitForm"
      :classifyAddFlag="classifyAddFlag"
      :formData="asideItem"
      ref="classifyRef"
    >
    </ClassifyDialog>
  </div>
</template>

<script>
import AsideList from '@/views/components/asideList'
import SearchCard from '@/views/components/searchCard'
import ClassifyDialog from './classifyDialog' // 分类弹窗

import taskApi from '@/api/task.js'

export default {
  name: 'panel',
  components: { AsideList, SearchCard, ClassifyDialog },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      taskSelectList: [], // 任务下拉框列表

      asideList: [], // 侧边栏分类列表
      asideLoading: false,
      title: "全部", // 标题

      searchParams: {
        page: 1,
        limit: 30,
        timeSection: "", // 时间段
        taskNumber: "", // 任务编号
        title: "", // 任务名称
        taskStyle: "", // 任务类型
        websiteId: "", // 任务站点
        modeStyle: "", // 任务方式
        taskStatus: "", // 任务状态
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 任务Id
        taskTypeId: "", // 任务分类Id
      },

      // 任务列表
      taskList: [],
      taskTotal: 0, // 总条数
      tableLoading: false,

      classifyAddFlag: true,
      taskTypeName: "", // 分类搜索内容
      asideItem: {}, // 单条分类数据

      taskTypeList: [], // 任务类型列表
      websiteList: [], // 任务站点列表
      modeStyleList: [], // 任务方式列表
      isPosting:false
    };
  },
  watch: {
    'searchParams.timeSection': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.searchParams.startTime = newVal[0]
          this.searchParams.endTime = newVal[1]
        } else {
          this.searchParams.startTime = ""
          this.searchParams.endTime = ""
        }
      }
    }
  },
  created () {
    // 获取分类侧边栏列表
    this.getAsideList()
    // 获取任务列表
    this.getTaskList()
    // 获取字典表数据
    this.getDictList()
  },

  methods: {
    /** 清空加载数据 */
    clearLoadData () {
      console.log('失去焦点');
      /** 任务下拉列表清空 */
      this.taskSelectList = []
      this.taskObj.title = ""
      this.taskObj.page = 1
      this.taskObj.more = false

      this.getData()
    },

    // 编辑任务
    editTask (item) {
      if (item.taskStatus == 1) {
        this.$router.push({
          path: '/editTask',
          query: {
            taskId: item.taskId
          }
        })
      }
    },

    // 传入给load-select组件的函数
    async getData (data) {
      console.log(data, '调用了');
      // 自定义内容搜索时,清空原数据列表
      if (data?.clear) {
        this.taskSelectList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData
        // 任务列表
        this.taskSelectList = [...this.taskSelectList, ...ret.data]

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    // 获取字典表数据
    async getDictList () {
      // 任务类型
      const res = await taskApi.dictList({ dataType: "taskType" })
      if (res.returnCode == 200) {
        this.taskTypeList = res.returnData
      }

      // 任务站点
      const ret = await taskApi.dictList({ dataType: "taskStation" })
      if (ret.returnCode == 200) {
        this.websiteList = ret.returnData

        // 存储任务站点
        localStorage.setItem("websiteList", JSON.stringify(ret.returnData))
      }

      // 任务方式
      const data = await taskApi.dictList({ dataType: "modelStyle" })
      if (data.returnCode == 200) {
        this.modeStyleList = data.returnData
      }
    },

    // 获取任务列表
    async getTaskList () {
      if (this.tableLoading) return
      this.tableLoading = true

      try {
        const res = await taskApi.taskList({ ...this.searchParams })
        this.tableLoading = false
        if (res.returnCode == 200) {
          const { totalCount, data } = res.returnData
          this.taskList = data.map(item => {
            // 任务方式
            item.dictKey = item.modeStyleList && item.modeStyleList.map(it => it.dictKey).join(',')
            item = { ...item, ...item.taskMainSituation, ...item.modeStyleList }
            return item
          })
          this.taskTotal = totalCount
        }
      } catch (error) {
        this.tableLoading = false
      }
    },

    // 分类搜索回调
    handlerAsideSearch (taskTypeName) {
      this.taskTypeName = taskTypeName
      this.getAsideList()
    },

    // 分类弹框提交回调
    async submitForm (formData) {
      const { taskTypeName } = formData
      const taskTypeId = this.asideItem?.taskTypeId
      if(this.isPosting) return
         this.isPosting = true
      // 判断是否为新增或编辑
      const res = await (this.classifyAddFlag ? taskApi.taskTypeInsert({ taskTypeName }) : taskApi.taskTypeUpdate({ taskTypeName, taskTypeId, status: 1 }))
      this.isPosting = false
      if (res.returnCode == 200) {
        this.getAsideList()
        this.title = taskTypeName
        this.$refs.classifyRef.handleClose()
      } else {
        this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
      }
    },

    // 创建任务
    addTask () {
      this.$router.push({
        path: "/addTask",
        query: {
          taskTypeId: this.searchParams.taskTypeId
        }
      })
    },

    // 任务详情
    goDetail (id) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          taskId: this.$encrypt(id)
        }
      })
    },

    // 批量任务
    batchTask () {
      this.$router.push("/batchTask")
    },

    // 获取分类列表
    async getAsideList () {
      if (this.asideLoading) return
      this.asideLoading = true

      try {
        let query = {}
        if (this.taskTypeName) {
          query = {
            taskTypeName: this.taskTypeName
          }
        }

        const res = await taskApi.taskTypeList(query)
        this.asideLoading = false
        if (res.returnCode == 200) {
          this.asideList = res.returnData
        }
      } catch (error) {
        this.asideLoading = false
      }
    },

    // 导出
    async exportFile (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.exportTaskList({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },

    // 更多按钮操作回调
    handlerAsideItem (obj) {
      console.log(obj);
      this.asideItem = obj

      switch (obj.type) {
        case 'add':
          this.classifyAddFlag = true
          this.$refs.classifyRef.show()
          break;
        case 'edit':
          this.classifyAddFlag = false
          this.$refs.classifyRef.show()
          break;
        case 'remove':
          this.removeClassify()
          break;
        case 'shiftUp':
          this.handlerMove()
          break;
        case 'shiftDown':
          this.handlerMove()
          break;
        default:
          break;
      }
    },

    // 删除分类
    async removeClassify () {
      const taskTypeId = this.asideItem?.taskTypeId
      const res = await taskApi.taskTypeVerifyRemove({ taskTypeId })
      if (res.returnCode == 200) {
        // 分类下的任务数量
        if (res.returnData.taskCount == 0) {
          this.$wpConfirm('提示', {
            tips: "确定删除吗？",
            content: "删除后数据不可恢复",
            isShowCancelBtn: true, //是否显示取消按钮
          }).then(async () => {
            const ret = await taskApi.taskTypeRemove({ taskTypeId })
            // 删除成功,默认勾选全部,重新获取数据
            if (ret.returnCode == 200) {
              this.$refs.asideRef.clickAll()
              this.getAsideList()
            } else {
              this.$showMessage({ type: "error", msg: res.returnMsg })
            }
          }).catch(() => {
            console.log("取消")
          });
        } else {
          // 如果分类下有任务,则进行提示
          this.$wpConfirm('提示', {
            tips: "确定删除吗？",
            content: "分类下任务可在全部中查看",
            isShowCancelBtn: true,
          }).then(async () => {
            const ret = await taskApi.taskTypeRemove({ taskTypeId })
            // 删除成功,默认勾选全部,重新获取数据
            if (ret.returnCode == 200) {
              this.$refs.asideRef.clickAll()
              this.getAsideList()
            } else {
              this.$showMessage({ type: "error", msg: res.returnMsg })
            }
          }).catch(() => {
            //点取消
            console.log("取消")
          });
        }

      }
    },

    // 上移下移回调
    async handlerMove () {
      const { moveId, movedId } = this.asideItem
      const res = await taskApi.taskTypeMoveOrder({ moveId, movedId })
      if (res.returnCode == 200) {
        this.getAsideList()
      } else {
        this.$showMessage({
          type: "error",
          msg: res.returnMsg
        })
      }
    },

    handlerSearch () {
      this.searchParams.page = 1
      this.getTaskList()
    },

    // 点击侧边栏单项数据
    getTitle (item) {
      this.title = item.taskTypeName
      this.searchParams.page = 1
      this.searchParams.taskTypeId = item.taskTypeId

      this.getTaskList()
    },

    // 对侧边栏单项数据进行操作
    handleMoreEvent (arr) {
      const type = arr[0]
      const item = arr[1]

      const query = {
        taskId: item.taskId,
      }


      switch (type) {
        // 追加人员
        case 'add':
          this.$router.push({
            path: '/addTaskPeople',
            query
          })
          break;
        case 'send':
          // 发送短信
          this.sendNote(item)
          break
        // 删除任务
        case 'remove':
          this.removeTask(item)
          break
        // 结束任务
        case 'over':
          this.overTask(item)
          break
        // 截图导出
        case 'export':
          this.exportWabeiFile(item, event)
          break
      }
    },

    /** 截图导出 */
    async exportWabeiFile (item, event) {
      const res = await taskApi.taskArticleWord({ taskId: item.taskId })
      if (res.returnCode == 200) {

        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } else {
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
      }
    },

    /** 发送短信 */
    async sendNote (item) {
      this.$wpConfirm('提示', {
        tips: "确定发送短信吗？",
        content: "发送短信将会提醒接收人",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        const res = await taskApi.sendNote({ taskId: item.taskId })
        if (res.returnCode == 200 || res.returnCode == 10087) {
          if (res.returnCode == 10087) {
            this.$showMessage({ type: 'warning', msg: res.returnMsg })
          } else {
            this.$showMessage({ type: 'success', msg: '发送成功' })
          }
        }
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },

    /** 删除任务 */
    removeTask (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        const res = await taskApi.deleteTask({ taskId: item.taskId })
        if (res.returnCode == 200) {
          this.getTaskList()
          this.$showMessage({ type: 'success', msg: res.returnMsg })
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },

    /** 结束任务 */
    overTask (item) {
      this.$wpConfirm('提示', {
        tips: "确定结束该任务吗?",
        content: "结束后将不可再继续提交作品",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        const res = await taskApi.downStatus({ taskId: item.taskId })
        if (res.returnCode == 200) {
          this.getTaskList()
          this.$showMessage({ type: 'success', msg: res.returnMsg })
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      }).catch(() => {
        console.log("取消")
      });
    },

    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTaskList()
    },

    handleCurrentChange (page) {
      this.searchParams.page = page
      this.getTaskList()
    }
  },
};
</script>
<style scoped lang="scss">
@import './index.scss';
</style>
