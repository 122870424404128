<template>
  <div class="addUser">
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
        <el-breadcrumb-item :to="{ name: 'unitManagList' }">单位管理</el-breadcrumb-item>
        <el-breadcrumb-item @click.native="goBack">用户管理（{{ custName }}）</el-breadcrumb-item>
        <el-breadcrumb-item>{{ isEidt ? '编辑' : '创建' }}人员</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="layout-block wp-moudle-style">
      <div class="layout-title">
        <p class="title-name">{{ isEidt ? '编辑' : '创建' }}人员</p>
        <p class="mima" v-if="!isEidt">初始密码：<span>abc123456</span></p>
      </div>
      <div class="layout-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform">

          <el-form-item prop="userName" label="人员姓名：">
            <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.userName" placeholder="请输入人员姓名" clearable @keyup.enter.native="determine"></el-input>
          </el-form-item>
          <el-form-item prop="loginName" label="登录账号：">
            <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.loginName" placeholder="请输入登录账号" clearable @keyup.enter.native="determine"></el-input>
          </el-form-item>
          <el-form-item prop="mobile" label="手机号码：">
            <el-input class="wp-input" maxlength="11" v-model.trim="ruleForm.mobile" @input="resetNumMobile" placeholder="请输入手机号码" clearable @keyup.enter.native="determine"></el-input>
          </el-form-item>
          <el-form-item prop="status" label="启用状态：">
            <el-select class="wp-select" style="width: 100%;" v-model="ruleForm.status" clearable placeholder="请选择启用状态" @keyup.enter.native="determine" @change="changeStatus">
              <el-option v-for="item in $globalData.userEnableStateList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="wp-button" @click="goBack">取消
            </el-button>
            <el-button class="wp-button" v-preventReClick type="primary" @click="determine">确定
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import { isPhone, isEnAndNumAndUnderline, resetNum } from '@/utils/validate.js';
import { insertAdmin, updateAdmin, getUserAdmin } from '@/api/systemSettings.js';
export default {
  name: 'addUser',
  components: {},
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!isPhone(value)) {
        callback(new Error('请输入11位有效手机号码'));
      } else {
        callback();
      }
    };
    var validateLoginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录账号'));
      } else if (!isEnAndNumAndUnderline(value)) {
        callback(new Error('请输入英文或数字'));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        userName: "",
        loginName: "",
        mobile: "",
        status: "1"
      },
      rules: {
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateLoginName, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择启用状态', trigger: 'change' }
        ]
      },
      isEidt: false,
      custId: "",// 单位id
      userId: "",// 用户id
      custName: "",// 单位名称
      userinfo: {},// 用户信息
      isPosting: false
    };
  },
  props: {},
  created () {
    if (this.$route.query._custId && this.$route.query._custName) {
      this.custId = this.$decrypt(this.$route.query._custId)
      this.custName = this.$decrypt(this.$route.query._custName)
      if (this.$route.query._id) {
        // 编辑
        this.isEidt = true
        this.userId = this.$decrypt(this.$route.query._id)
        // 获取用户信息
        this.getUserAdminOne()
      }
    } else {
      this.goBack()
    }
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack () {
      // this.$router.push({ name: "userManagList" })
      // this.$router.go(-1)
      this.$router.push({ name: 'userManagList', query: { _id: this.$encrypt(this.custId), _custName: this.$encrypt(this.custName) } })
    },
    // 创建用户 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let query = {
        ...this.ruleForm
      }
      let res = null
      query.custId = this.custId
      // console.log(query)
      // return
      try {
        if (this.isEidt) {
          query.userId = this.userId
          res = await updateAdmin(query)
        } else {
          res = await insertAdmin(query)
        }
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.goBack()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    // 获取用户信息
    async getUserAdminOne () {
      try {
        let res = await getUserAdmin({ userId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.userinfo = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },
    setInitValue () {
      this.ruleForm.userName = this.userinfo.userName
      this.ruleForm.loginName = this.userinfo.loginName
      this.ruleForm.mobile = this.userinfo.mobile
      this.ruleForm.status = this.userinfo.status?.toString()
    },
    // 手机号重置 值
    resetNumMobile () {
      this.ruleForm.mobile = resetNum(this.ruleForm.mobile);
    },
    // 禁用需要提示
    changeStatus (val) {
      // console.log(val)
      let that = this
      if (Number(val) == 2) {
        this.$wpConfirm('提示', {
          tips: "禁用账号将无法登录",
          content: "确定禁用该账号吗？",
          yesBtnText: '确定',
          isShowCancelBtn: true, //是否显示取消按钮
          cancelBtnText: '取消',
        }).then(() => {
          console.log("确认")
        }).catch(() => {
          console.log("取消")
          that.ruleForm.status = '1'
        });
      }

    }

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {

    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/addUser.scss";
</style>
