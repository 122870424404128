<template>
  <div class="main">
    <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
      <el-tab-pane label="组员统计" name="people"> </el-tab-pane>

      <el-tab-pane label="作品明细" name="workDetails">
        <SearchCard
          @handlerSearch="handlerSearch"
          title=""
          class="title-wp-card"
        >
          <template v-slot:searchContent>
            <TaskTimeSelect
              ref="timeRef"
              :activeType="searchParams.timeType"
              @timeSelect="timeSelect"
            ></TaskTimeSelect>
            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.localType"
              placeholder="请选择任务来源"
              v-if="roleId == $ENUM.SYSROLE.TEAMADMIN"
              @change="changeLocalType"
            >
              <el-option
                v-for="item in $globalData.TASKSOURCE"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <wpLoadSelect
              class="search-w392 wp-select align-top"
              v-model="searchParams.taskId"
              :data="taskList"
              :page="taskObj.page"
              dictLabel="title"
              dictValue="taskId"
              dictTime="createTime"
              :hasMore="taskObj.more"
              :request="getData"
              @clearTitle="() => (taskObj.title = '')"
              placeholder="请选择任务"
            ></wpLoadSelect>

            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.createName"
              clearable
              placeholder="请输入人员姓名"
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.articleStatus"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>
      </el-tab-pane>
    </TabsCard>

    <div class="wp-card mt-16">
      <div class="flex-CB">
        <div class="flex">
          <div class="export-box">
            <el-popover
              popper-class="export-popover"
              ref="export-popover"
              placement="bottom"
              trigger="click"
            >
              <div class="export-list">
                <div class="export-item wp-pointer" @click="collectExport">
                  汇总导出
                </div>
                <div class="export-item wp-pointer" @click="workExport">
                  作品导出
                </div>
              </div>
              <el-button
                style="width: 90px"
                slot="reference"
                class="wp-button"
                size="medium"
              >
                导出<i class="icon-zhankai el-icon--right"></i
              ></el-button>
            </el-popover>
          </div>
        </div>

        <div class="fs-14 flex">
          <div>
            提交总量：<span class="wp-blue">{{ total || 0 }}</span>
          </div>
          <div class="ml-30">
            奖励积分：<span class="wp-blue">{{
              summariesData.score || 0
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="wp-table mt-20"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="正在加载"
      >
        <el-table
          :data="tableData"
          stripe
          v-if="tableData.length !== 0"
          ref="multipleTable"
        >
          <el-table-column align="center" type="index" label="序号" width="90">
          </el-table-column>
          <el-table-column max-width="20" min-width="5"> </el-table-column>
          <el-table-column
            align="center"
            prop="fileJson"
            max-width="150"
            min-width="100"
            label="截图/附件"
          >
            <template slot-scope="scope">
              <div
                @click.stop="goDetail(scope.row.taskArticleId, scope.$index)"
                style="cursor: pointer"
              >
                <p
                  v-if="JSON.parse(scope.row.fileJson).length"
                  style="cursor: pointer"
                >
                  <el-popover
                    placement="right-start"
                    :visible-arrow="false"
                    trigger="hover"
                    popper-class="img-popper"
                    :tabindex="3"
                  >
                    <el-image
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      class="scaled-image"
                      fit="contain"
                    ></el-image>
                    <img
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </el-popover>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="20" min-width="5"> </el-table-column>
          <el-table-column max-width="50" min-width="20"> </el-table-column>
          <el-table-column
            align="left"
            prop="createName"
            max-width="120"
            min-width="100"
            show-overflow-tooltip
            label="人员姓名"
          >
          </el-table-column>
          <el-table-column width="10"> </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="提交时间"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            max-width="130"
            min-width="100"
            align="center"
            prop="totalScore"
            label="奖励积分"
          >
          </el-table-column>
          <el-table-column align="center" prop="articleStatus" label="审核状态">
            <template slot-scope="scope">
              <div>
                <div class="flex-center" v-if="scope.row.articleStatus == 1">
                  <div class="success-circle"></div>
                  <div class="success-color">已通过</div>
                </div>
                <div class="flex-center" v-else>
                  <div class="noreceive-circle"></div>
                  <div class="noreceive-color">已驳回</div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData.length == 0 && !loading">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>

      <wp-pagination
        class="mt-21"
        :total="total"
        :currentPage="searchParams.page"
        :pageSize="searchParams.limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></wp-pagination>
    </div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import reportedApi from '@/api/reported.js'
import { resetNumPointOne } from '@/utils/validate.js';
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import TabsCard from '@/views/components/tabsCard'
import { mapGetters } from 'vuex';
export default {
  name: 'taskReview',
  components: { SearchCard, TaskTimeSelect, TabsCard },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
        order: 'taskId'
      },
      taskList: [], // 任务下拉框列表
      // 查询条件
      searchParams: {
        page: 1,
        limit: 30,
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 所选任务ID
        createName: "", // 人员姓名
        articleStatus: "", // 审核状态
        timeType: 2,
        localType: 1
      },
      tableData: [],
      total: 0,
      editRow: {}, // 表格行内信息
      multipleSelection: [],
      exportType: "", // 所选导出类型
      rejectObj: {}, // 驳回信息
      qeuryData: {}, // 深拷贝后的搜素条件
      summariesData: {}, // 表格统计数据
      roleDisabled: false,
      activeTab: 'workDetails',
      tabs: [
        { label: '组员统计', name: "people" },
        { label: '作品明细', name: "workDetails" }
      ],
      queryData: {},
      isExporting2: false,
      isExporting: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['roleId', 'sysUserInfo']),
  },
  created () {
  },
  mounted () {
    this.setCache()
  },
  methods: {
    changeScore (e) {
      console.log(e.target.value)
      if ((e.target.value.indexOf('.') >= 0)) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 2)
      }
      console.log(e.target.value)
    },

    resetNumPoints (e) {
      console.log(resetNumPointOne(e, 0, 100));
      // e = resetNumPointOne(e, 0, 100)
    },
    /** 汇总导出 */
    async collectExport (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.summaryArticleExport(this.queryData)
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    /** 作品导出 */
    async workExport (event) {
      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await taskApi.taskArticleExport(this.queryData)
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }

    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskList = []
        this.taskObj = { ...this.taskObj, ...data }
      }
      // 市办任务
      if (this.roleId == this.$ENUM.SYSROLE.TEAMADMIN) {
        this.taskObj = { ...this.taskObj, localType: this.searchParams.localType }
      } else if (this.taskObj.localType) {
        delete this.taskObj.localType
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskList = [...this.taskList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    // 获取表格统计信息
    async getSummaries () {
      const res = await taskApi.getSummaries({ ...this.searchParams, modelName: "groupInfo" })
      if (res.returnCode == 200) {
        this.summariesData = res.returnData
      }
    },


    /** 获取分组树 */
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },

    /** 批量评级回调 */
    async batchRate (stars) {
      // 文章Id列表
      const articleIds = this.multipleSelection.reduce((prev, curr) => {
        prev.push(curr.taskArticleId)
        return prev
      }, [])
      let query = {
        articleIds,
        stars
      }
      const res = await taskApi.updateStarsBatch(query)
      if (res.returnCode == 200) {
        // 取消勾选并清空数组
        this.$refs.multipleTable.clearSelection()
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    /** 质量评级 */
    async rateChange (item, stars) {
      const query = {
        articleId: item.taskArticleId,
        stars
      }
      const res = await taskApi.updateStars(query)
      if (res.returnCode == 200) {
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    /** 审核列表 */
    async getReviewList () {
      if (this.loading) return
      this.loading = true
      this.cache()
      try {

        const res = await taskApi.articleList(this.searchParams)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData
          this.tableData = data?.data || []
          this.total = data?.totalCount || 0
          this.setRoleDisabled()
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 获取驳回信息
    async getReject (articleId) {
      const res = await taskApi.getVerify({ articleId })
      if (res.returnCode == 200) {
        this.rejectObj = res.returnData
      }
    },

    /** 提交驳回信息回调 */
    async handlerReject (rejectMsg, item) {
      const query = {
        rejectMsg,
        articleId: item.taskArticleId
      }
      console.log(query);
      const res = await taskApi.rejectArticle(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', msg: '操作成功' })
        this.getReviewList()
      }
    },

    // 导出
    handlerExport (type) {
      this.exportType = type
      this.$refs['export-popover'].doClose()
    },

    // 批量质量评级
    clickBatchBtn () {
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        return this.$showMessage({
          type: 'error',
          msg: '请选择要操作的数据'
        })
      }
    },

    // 搜索回调
    handlerSearch (data) {
      this.searchParams = { ...this.searchParams, ...data }
      this.searchParams.page = 1
      this.getReviewList()
      this.getSummaries()
      console.log(this.searchParams)
    },

    // 编辑分数
    edit (item) {
      this.editRow = JSON.parse(JSON.stringify(item))
    },

    // 详情
    goDetail (articleId) {
      this.$router.push({
        path: '/leaderTaskArticleDetail',
        query: {
          articleId,
        }
      })
    },
    // 提交编辑分数
    async submitScore (item, index) {
      let query = {
        articleId: item.taskArticleId,
        rewardScore: item.totalScore
      }
      const res = await taskApi.updateScore(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', message: '操作成功' })
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    // 时间筛选
    timeSelect (data) {
      console.log(data)
      this.searchParams = { ...this.searchParams, ...data }
    },


    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getReviewList()
    },
    // 设置 市办任务的 按钮 禁用
    setRoleDisabled () {
      let flag = false
      if (this.roleId == this.$ENUM.SYSROLE.TEAMADMIN && this.searchParams.localType == 2) {
        // 组管理员，全部-市办任务
        flag = true
      }
      this.roleDisabled = flag
    },
    changeLocalType () {
      this.taskList = []
      this.taskObj.more = true
      this.taskObj.title = ''
      this.searchParams.taskId = ''
      this.taskObj.page = 1
      this.getData({ page: 1 })
    },
    /** tab切换 */
    handlerTabs (name) {
      if (name !== 'workDetails') {
        this.$router.push('/crewStatistics')
      }
    },
    // 缓存筛选项
    cache () {
      this.queryData = JSON.parse(JSON.stringify(this.searchParams))
    },
    // 设置缓存值
    setCache () {
      this.$nextTick(() => {
        // 获取审核列表
        this.getReviewList()
        // 获取分组树
        this.getGroupTree()
        // 获取表格统计信息
        this.getSummaries()
      })
    }
  },
};
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}

.export-box {
  margin-left: 12px;
}

.title-wp-card {
  padding: 0;
}
</style>
<!-- <style scoped lang="scss">@import "./index.scss";</style> -->
