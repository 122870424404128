<template>
  <el-scrollbar style="height: 100%">
    <div class="wp-tree">
      <el-tree
        class="tree"
        :data="peopleList"
        show-checkbox
        :default-expand-all="false"
        :node-key="nodeKey"
        :expand-on-click-node="false"
        :ref="treeRef"
        highlight-current
        :default-expanded-keys="defaultExpandedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :props="defaultProps"
        :filter-node-method="filterNode"
        @check="handleCheckClick"
        @check-change="handleCheckChange"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span
            :class="
              node.level == 1
                ? 'title'
                : node.level == 2
                ? 'two-title'
                : node.level == 3
                ? 'three-title'
                : 'people-name'
            "
            class="textOverOneLine people-name"
            >{{ data.name }}</span
          >

          <template v-if="!isEdit">
            <!-- 全部人员的加入常用 -->
            <!-- 批量 -->
            <!-- <span
              class="batch"
              v-if="data.pid == 0 && type !== 'stock'"
              @click="batchAppend"
              >加入常用
            </span> -->
            <!-- 单独 -->
            <!-- <span
              class="often"
              v-if="!data.group && type !== 'stock'"
              @click="append(node, data)"
              >加入常用
            </span> -->

            <!-- 常用人员移除 -->
            <!-- 批量 -->
            <!-- <span
              class="batch stock"
              v-if="data.pid == 0 && type == 'stock'"
              @click="batchRemovePeople"
              >移除
            </span> -->
            <!-- 单独 -->
            <!-- <span
              class="often stock"
              v-if="!data.group && type == 'stock'"
              @click="removePeople(node, data)"
              >移除
            </span> -->
          </template>
        </div>
      </el-tree>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '480px'
    },

    // 默认展开分组
    defaultExpanded: {
      type: Array,
      default: () => []
    },

    treeRef: {
      type: String,
      default: 'peopleRef'
    },

    // 人员列表
    peopleList: {
      type: Array,
      default: []
    },

    // 判断是否为常用人员列表
    type: {
      type: String,
      default: ''
    },

    // 默认选中节点
    defaultCheckedKeys: {
      type: Array,
      default: () => []
    },

    // 禁止选中列表
    disabledList: {
      type: Array,
      default: () => []
    },
    searchContent: String, // 搜索内容

    isEdit: {
      type: Boolean,
      default: false
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'name'
        }
      }
    },
  },

  data () {
    return {
      stockList: [],
      // defaultProps: {
      //   children: 'children',
      //   label: 'name',
      //   // disabled: this.setDisabled
      // },
      defaultExpandedKeys: []
    };
  },

  created () {

  },

  methods: {
    setDefaultExpandedKeys () {
      // 获取树形组件实例
      let tree = this.$refs[this.treeRef];
      let allNodes = tree.store._getAllNodes();
      let defaultExpandedKeys = [];
      // 将展开的保存起来
      allNodes.forEach(node => {
        node.expanded && defaultExpandedKeys.push(node.data[this.nodeKey]);
      });
      if (!defaultExpandedKeys.length) {
        defaultExpandedKeys = this.defaultExpanded
      }
      console.log(defaultExpandedKeys);
      this.defaultExpandedKeys = defaultExpandedKeys;
    },


    setDisabled (data, node) {
      console.log(data, node)
      console.log(this.disabledList)
      return this.disabledList.indexOf(data.id) != -1;
    },

    // 批量加入
    batchAppend () {
      const peopleId = this.$refs[this.treeRef].getCheckedKeys()
      if (peopleId?.length) {
        this.$emit("addCommonUser", peopleId)
      } else {
        this.$showMessage({ type: 'warning', msg: '请先勾选' })
      }
    },

    // 批量移除
    batchRemovePeople () {
      const peopleId = this.$refs[this.treeRef].getCheckedKeys()
      if (peopleId?.length) {
        this.$wpConfirm('提示', {
          tips: "确定移除吗？",
          content: "",
          yesBtnText: '确定',
          isShowCancelBtn: true, //是否显示取消按钮
          cancelBtnText: '取消',
        }).then(() => {
          this.$emit("removeCommonUser", peopleId)
        }).catch(() => {

        });
      } else {
        this.$showMessage({ type: 'warning', msg: '请先勾选' })
      }
    },

    // 加入常用人员
    append (node, data) {
      const peopleId = []
      peopleId.push(data.id)
      this.$emit("addCommonUser", peopleId)
    },

    // 移除常用人员
    removePeople (node, data) {
      const peopleId = []
      peopleId.push(data.id)

      this.$wpConfirm('提示', {
        tips: "确定移除吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.$emit("removeCommonUser", peopleId)
      }).catch(() => {

      });
    },

    // 选中当前复选框
    handleCheckChange (data, checked) {
      if (!data.disabled) {
        data.checked = checked
      }
      // data.checked = checked
    },

    /** 查询 */
    filterNode (value, data) {
      console.log(value, data);
      if (!value) return true;
      if (!data.group) return data.name.indexOf(value) !== -1;
    },

    /** 去除重复值 */
    copare (arr) {
      let list = []
      for (let i = 0; i < arr.length; i++) {
        let num = 0
        for (let j = 0; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            num++
          }
        }
        if (num <= 1) {
          list.push(arr[i])
        }
      }
      return list
    },

    /** 获取选中节点 */
    getCurrentNode () {
      this.$refs[this.treeRef].setCheckedKeys(this.defaultCheckedKeys)
      const peopleList = []
      // 设置默认选中节点
      const peopleData = this.$refs.peopleRef.getCheckedNodes()
      this.handlerPeopleList(peopleData, peopleList)
      return peopleList
    },

    // 复选框被点击时的回调
    handleCheckClick (data) {
      console.log(data)
      // 人员列表
      let peopleList = []
      // 人员Id
      let peopleIds = []
      // 取消勾选的人员Id
      let deleteId = ""
      // 当前点击复选框选中状态
      const checked = data.checked

      // 如果勾选的是组
      if (data.group) {
        let ids = []

        // 取当前组下人员Id
        this.handlerChilren(data, ids)

        if (!checked) {
          deleteId = ids
        } else {
          peopleIds = ids
        }

      } else {
        peopleIds = this.$refs[this.treeRef].getCheckedNodes().reduce((prev, current) => {
          if (!current.group) {
            prev.push(current.id)
          }
          return prev
        }, [])

        // 如果取消选中,则在人员列表中,删除该id
        if (!checked) {
          deleteId = []
          deleteId.push(data.id)
        }
      }

      this.$emit("handleCheck", { peopleList, peopleIds, deleteId, checked })
    },

    // 清空
    resetChecked () {
      this.$refs[this.treeRef].setCheckedKeys([]);
    },

    /** 取出所有选中人员信息 */
    handlerPeopleList (arr, list) {
      arr.forEach(item => {
        if (!arr.group) {
          list.push(item)
        }
      })
    },

    /** 取当前组下人员Id */
    handlerChilren (list, arr) {
      list?.children.forEach(item => {
        if (item.group && item.children) {
          this.handlerChilren(item, arr)
        } else {
          arr.push(item.id)
        }
      })
    },
  },

  watch: {
    // 默认选中
    defaultCheckedKeys: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        console.log(newVal);
        this.$nextTick(() => {
          this.stockList = newVal
          // 设置默认选中节点
          this.$refs[this.treeRef].setCheckedKeys(newVal)
        })
      }
    },

    // 搜索内容
    searchContent: {
      handler (newVal, oldVal) {
        this.$refs[this.treeRef].filter(newVal)
      }
    },

    defaultExpanded: {
      handler (newVal, oldVal) {
        console.log(newVal);
        if (newVal) {
          this.defaultExpandedKeys = newVal
        }
      },
      immediate: true,
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>