<template>
  <div>
    <!-- 基础设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title fs-16 fw-bold">专题信息</div>
      <BasicsForm
        :topicTypeId="topicTypeId"
        class="center"
        ref="basicsForm"
        @platformScore="changePlatformScore"
      >
      </BasicsForm>
    </div>

    <!-- 高级选项 -->
    <div
      class="wp-task-card mt-16"
      :style="{ height: foldFlag ? '264px' : '74px' }"
    >
      <div class="card-title flex-CB">
        <div class="fs-16 fw-bold">高级选项</div>
        <div class="wp-blue toggle" @click="foldFlag = !foldFlag">
          <span>{{ !foldFlag ? '展开' : '收起' }}</span>
          <i
            v-show="foldFlag"
            class="el-icon-arrow-up fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
          <i
            v-show="!foldFlag"
            class="el-icon-arrow-down fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
        </div>
      </div>

      <div class="footer" :style="{ display: foldFlag ? 'block' : 'none' }">
        <div class="layout-content">
          <el-form label-width="100px" class="advanced-form">
            <el-form-item
              :label="item.configName + '：'"
              v-for="item in advancedSetting2"
              :key="item.custConfigId"
            >
              <el-checkbox
                v-model="item.isShow"
                :disabled="item.isDisbled"
                @change="checkboxChangeShow2(item)"
                >显示
              </el-checkbox>
              <el-checkbox
                v-model="item.isRequired"
                :disabled="item.isDisbledRequired"
                @change="checkboxChangeRequired2(item)"
              >
                必填
              </el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reportedApi from '@/api/reported'

import BasicsForm from './form/basicsForm' // 基础设置
export default {
  components: { BasicsForm },
  props: {
    topicTypeId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      checked: false,
      foldFlag: false,
      advancedSetting2: [], // 高级设置
      configData: {}, // 单位高级设置初始值
      oldScore: "", // 默认配置分数
      lastScore: ""
    }
  },
  created () {
    this.getConfig()
  },
  methods: {
    /** 获取单位配置 */
    async getConfig () {
      const res = await reportedApi.getConfigList({})
      if (res.returnCode == 200) {
        const data = res.returnData
        this.configData = data

        this.$emit("note", data?.subjectSmsNotice?.[0]?.configValue)

        // 高级设置
        this.setConfigValue(data)
      }
    },

    /** 专题积分选择按平台 
     * @parma {num} Number 是否是第一次调用
    */
    changePlatformScore (score, num) {
      const settingName = ['作品标题', '作品链接', '附件/截图', '媒体平台', '发布类型', '作者类别', '作者姓名', '网名/昵称', '阅读数量', '转载数量']

      // 保存第一次的分数
      if (num) {
        this.oldScore = score
      }

      let flag = 0

      this.advancedSetting2.forEach((item, index) => {
        const obj = { ...item }
        item.isDisbled = false
        item.isShow = false
        item.isRequired = false
        item.isDisbledRequired = false

        settingName.forEach(value => {
          // 选择的按平台
          if (score == -999) {
            if (item.configName == value) {
              if (item.configName == '媒体平台' || item.configName == '发布类型') {
                item.isDisbled = true
                item.isDisbledRequired = true
              } else if (item.configName == '附件/截图') {
                item.isDisbled = true
              }
              item.isShow = true
              item.isRequired = true
            }
          } else {
            console.log(this.lastScore);
            // 默认配置为按平台,从按平台切换到普通积分
            // 默认设置为普通积分
            // 从按平台切换到普通积分
            if (this.lastScore == -999) {
              if (this.oldScore == -999) {
                if (item.configName == '附件/截图') {
                  item.isDisbled = true
                  item.isDisbledRequired = false
                  item.isShow = true
                  item.isRequired = true
                } else {
                  item.isDisbled = false
                  item.isDisbledRequired = false
                  item.isShow = false
                  item.isRequired = false
                }
              } else {
                flag = 1
              }
            } else { // 普通积分切换,无需修改
              item.isDisbledRequired = obj.isDisbledRequired
              item.isDisbled = obj.isDisbled
              item.isShow = obj.isShow
              item.isRequired = obj.isRequired
            }
          }
        })
      })

      if (flag) {
        this.setConfigValue(this.configData)
      }

      this.lastScore = score
    },

    // 高级设置
    setConfigValue (data) {
      console.log(data)
      // 上报中心-高级设置
      let advancedSetting2 = []

      // 作品标题 
      let subjectArticleTitle = data.subjectArticleTitle[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectArticleTitle[0], isShow: subjectArticleTitle.charAt(0) == '1', isRequired: subjectArticleTitle.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      //作品内容 
      let subjectArticleContent = data.subjectArticleContent[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectArticleContent[0], isShow: subjectArticleContent.charAt(0) == '1', isRequired: subjectArticleContent.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 作品链接 
      let subjectArticleUrl = data.subjectArticleUrl[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectArticleUrl[0], isShow: subjectArticleUrl.charAt(0) == '1', isRequired: subjectArticleUrl.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 媒体平台 
      let subjectMediaPlatform = data.subjectMediaPlatform[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectMediaPlatform[0], isShow: subjectMediaPlatform.charAt(0) == '1', isRequired: subjectMediaPlatform.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 发布类型 
      let subjectPublishType = data.subjectPublishType[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectPublishType[0], isShow: subjectPublishType.charAt(0) == '1', isRequired: subjectPublishType.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 作者类别 
      let subjectAuthorType = data.subjectAuthorType[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectAuthorType[0], isShow: subjectAuthorType.charAt(0) == '1', isRequired: subjectAuthorType.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 作者姓名 
      let subjectAuthorName = data.subjectAuthorName[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectAuthorName[0], isShow: subjectAuthorName.charAt(0) == '1', isRequired: subjectAuthorName.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 网名/昵称
      let subjectNickName = data.subjectNickName[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectNickName[0], isShow: subjectNickName.charAt(0) == '1', isRequired: subjectNickName.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 阅读数量
      let subjectReadNum = data.subjectReadNum[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectReadNum[0], isShow: subjectReadNum.charAt(0) == '1', isRequired: subjectReadNum.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 转载数量
      let subjectReprintNum = data.subjectReprintNum[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectReprintNum[0], isShow: subjectReprintNum.charAt(0) == '1', isRequired: subjectReprintNum.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      // 全网推送 
      if (data.subjectPush) {
        let subjectPush = data.subjectPush[0].configValue?.toString()
        advancedSetting2.push({ ...data.subjectPush[0], isShow: subjectPush.charAt(0) == '1', isRequired: subjectPush.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      }
      // 附件/截图 
      let subjectArticleFile = data.subjectArticleFile[0].configValue.toString()
      advancedSetting2.push({ ...data.subjectArticleFile[0], isShow: subjectArticleFile.charAt(0) == '1', isRequired: subjectArticleFile.charAt(1) == '1', isDisbled: true, isDisbledRequired: false })

      this.advancedSetting2 = advancedSetting2
    },

    // 上报中心 高级设置 改变显示 
    checkboxChangeShow2 (item) {
      this.advancedSetting2.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },

    // 上报中心 高级设置 改变必填 
    checkboxChangeRequired2 () {
      this.advancedSetting2.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },

    // 获取表单数据
    getFormData () {
      const formData = this.$refs['basicsForm'].getFormData()
      if (formData) {
        //上报中心-高级设置
        this.advancedSetting2.forEach(item => {
          item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
        })
        this.advancedSetting2.forEach(value => {

          switch (value.configName) {
            case "作品标题":
              formData.parmTitle = value.configValue
              break;
            case "作品内容":
              formData.parmContent = value.configValue
              break;
            case "作品链接":
              formData.parmUrl = value.configValue
              break;
            case "媒体平台":
              formData.parmMedia = value.configValue
              break;
            case "发布类型":
              formData.parmPublishType = value.configValue
              break;
            case "作者类别":
              formData.parmAuthorType = value.configValue
              break;
            case "作者姓名":
              formData.parmAuthor = value.configValue
              break;
            case "网名/昵称":
              formData.parmNetName = value.configValue
              break;
            case "阅读数量":
              formData.parmReadNum = value.configValue
              break;
            case "转载数量":
              formData.parmReprint = value.configValue
              break;
            case "全网推送":
              formData.parmPush = value.configValue
              break;
            case "附件/截图":
              formData.parmAttach = value.configValue
              break;
            default:
              break;
          }
        })
        return formData
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>