<template>
  <div>
    <SearchContent @handlerSearch="handlerSearch">
      <template v-slot:searchContent>
        <TaskTimeSelect
          :days="30"
          :activeType="searchParams.timeType"
          :taskType="timeType"
          @timeSelect="timeSelect"
          ref="taskTime"
        >
        </TaskTimeSelect>

        <el-select
          class="search-w190 wp-select"
          v-model="searchParams.taskTypeId"
          clearable
          placeholder="请选择任务分类"
        >
          <el-option
            v-for="item in taskTypeList"
            :key="item.taskTypeId"
            :label="item.taskTypeName"
            :value="item.taskTypeId"
          >
          </el-option>
        </el-select>
      </template>
    </SearchContent>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'

import SearchContent from '@/views/components/searchContent'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { TaskTimeSelect, SearchContent },
  data () {
    return {
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskTypeId: "", // 任务分类Id
        timeType: 2
      },
      // 时间类型
      timeType: [
        {
          name: "任务发布时间",
          id: 2
        }
      ],
      // 任务列表
      taskTypeList: [],
    }
  },
  created () {
    // 获取任务分类
    this.getTaskTypeList()
  },
  methods: {
    // 清空搜索区域
    clearParams () {
      for (const key in this.searchParams) {
        this.searchParams[key] = ""
      }
      // 清空时间选择器
      this.$refs.taskTime.clearTime()
    },

    // 获取分类列表
    async getTaskTypeList () {
      let query = {}

      const res = await taskApi.taskTypeList(query)
      if (res.returnCode == 200) {
        this.taskTypeList = res.returnData
      }
    },

    // 获取筛选数据
    getParams () {
      this.searchParams.timeType = 2
      return this.searchParams
    },

    // 搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.$emit("changeSearch", this.searchParams)
    },

    // 选择时间回调
    timeSelect (time) {
      this.searchParams.startTime = time.startTime
      this.searchParams.endTime = time.endTime
      this.searchParams.timeType = time.timeType
    }
  },
}
</script>

<style lang="scss" scoped>
</style>