<template>
  <wp-dialog  :closeOnclickModal="true" :modal="false" @cancel="cancel" title="任务明细" width="820px" height="600px" :dialogVisible="dialogVisible" @handleClose="handleClose" :showDefaultFooter="false">
    <div class="createModel">
      <div class="flex-CB">
        <div class="flex-Acenter fs-14">
          <div>
            任务总量：<span class="wp-blue">{{
            total
          }}</span>
          </div>
        </div>
        <div>
          <el-button style="width: 90px" class="wp-button" size="medium" @click="detailExport">
            导出</el-button>
        </div>
      </div>
      <div class="wp-table mt-20" v-loading="loading">
        <el-table :data="tableData" stripe sortable="custom" v-if="tableData.length !== 0">
          <el-table-column prop="taskNumber" align="center" label="任务编号" max-width="120">
          </el-table-column>
          <el-table-column prop="taskTitle" align="left" label="任务名称" min-width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="websiteName" align="center" label="任务站点" max-width="100" min-width="145" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="发布时间" min-width="120">
            <template slot-scope="scope">
              {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="taskFinshNum" label="完成情况" max-width="100" min-width="100">
            <template slot-scope="scope">
              <div class="flex-center" v-if="scope.row.finish == 1">
                <div class="receive-circle"></div>
                <div class="receive-color">已完成</div>
              </div>
              <div class="flex-center" v-else>
                <div class="noreceive-circle"></div>
                <div class="noreceive-color">未完成</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData.length == 0 && !loading">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <wp-pagination class="mt-21" :total="total" :pageSize="query.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
      </wp-pagination>
    </div>
  </wp-dialog>
</template>

<script>
import taskApi from '@/api/task.js'
export default {
  name: 'taskDetails',
  components: {},
  data () {
    return {
      dialogVisible: false,
      loading: false,
      tableData: [],
      query: {
        limit: 10,
        page: 1
      },
      total: 0,
      isExporting: false

    };
  },
  props: {
    searchParams: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 初始化数据
    initDate () {
      this.refresh()
    },
    cancel () {
      this.handleClose()
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      // 获取接口
      this.getData()
    },
    refresh () {
      this.query.page = 1
      this.getData()
    },
    async getData () {
      let query = {
        ...this.searchParams,
        ...this.query
      }
      try {
        if (this.loading) return
        this.loading = true
        const res = await taskApi.taskMemberPage(query)
        this.loading = false
        if (res.returnCode != 200) return
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
      }
    },
    // 导出
    async detailExport (event) {
      let query = {
        ...this.searchParams,
        ...this.query
      }
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.taskMemberPageExport(query)
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }

    },

  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.createModel {
  padding: 20px 40px;

  .model-top {
    display: flex;
    justify-content: flex-end;
  }

  .init-pass {
    font-size: 14px;
    font-weight: 400;
    color: #333333;

    > span {
      color: #f35454;
    }
  }

  .ruleform {
    margin-top: 30px;
    padding: 0px 60px 0px 60px;
  }
}

/deep/.remarks.el-textarea {
  .el-textarea__inner {
    height: 83px;
  }
}
</style>