<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          上报人数：<span class="wp-blue">{{
            statisticsData.peopleNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          上报数量：<span class="wp-blue">{{
            statisticsData.articleNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-button
          @click="exportFile"
          class="wp-button wp-btn-icon"
          size="medium"
          icon="icon-daochu"
          >导出</el-button
        >
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table
        :data="tableData"
        stripe
        sortable="custom"
        @sort-change="sortChange"
        :default-sort="sortObj"
        v-if="tableData.length !== 0"
      >
        <el-table-column width="30">
          <template slot-scope="scope">
            <div class="product_tip" v-if="scope.row.longItem">
              <div class="product_tip_bg"></div>
              <p>长期</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="topicNum"
          label="专题编号"
          width="130"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.topicNum }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="30"> </el-table-column>
        <el-table-column
          align="left"
          prop="title"
          label="专题名称"
          min-width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          min-width="150"
          label="创建时间"
        >
          <template slot-scope="scope">
            <div>
              {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="memberNum" label="上报人数">
        </el-table-column>
        <el-table-column
          min-width="90"
          align="center"
          sortable
          prop="workNum"
          label="上报数量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          min-width="90"
          sortable
          prop="score"
          label="奖励积分"
        >
          <template slot-scope="scope">
            {{ scope.row.score == -999 ? '按平台' : scope.row.score }}
          </template>
        </el-table-column>
      </el-table>
      <div class="wp-noData" v-if="tableData.length == 0">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      sortObj: {}
    }
  },
  created () {

  },
  methods: {
    // 导出
    exportFile (event) {
      this.$emit("exportFile", { event, api: 'exportTopicStatisticsExcel' })
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }

      this.$emit("sortChange", { orderField: prop, sort: order })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>