var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        showDefaultFooter: false,
        showSlotFooter: true,
        title: "通知公告",
        width: "680px",
        height: "440px",
        dialogVisible: _vm.dialogVisible,
      },
      on: { handleClose: _vm.handleClose },
    },
    [
      _c("div", { staticClass: "strongReminder" }, [
        _c("div", { staticClass: "pre-box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.noticeObj.title)),
          ]),
          _c("div", { staticClass: "pre-value" }, [
            _vm._v(" " + _vm._s(_vm.noticeObj.content) + " "),
          ]),
          _c(
            "div",
            { staticClass: "file" },
            [
              _c("File", {
                staticStyle: { "margin-top": "10px" },
                attrs: { fileJson: _vm.fileArr },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "slotFooter" },
        [
          _vm.countdowning
            ? _c("span", { staticClass: "countText" }, [
                _vm._v(_vm._s(_vm.remainingTime) + "秒"),
              ])
            : _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" },
                  ],
                  staticClass: "wp-button",
                  attrs: { type: "primary", size: "medium" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.determine.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("我知道了 ")]
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }