<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          完成总量：<span class="wp-blue">{{
            statisticsData.completeNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-popover popper-class="export-popover" ref="export-popover" placement="bottom" trigger="click">
          <div class="export-list">
            <div class="export-item wp-pointer" @click="detailExport">
              明细导出
            </div>
            <div class="export-item wp-pointer" @click="exportFile">
              分月统计
            </div>
          </div>
          <el-button style="width: 90px" slot="reference" class="wp-button" size="medium">导出<i class="icon-zhankai el-icon--right"></i></el-button>
        </el-popover>
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table :data="tableData" stripe sortable="custom" @sort-change="sortChange" :default-sort="sortObj" v-if="tableData.length !== 0">
        <el-table-column width="10"> </el-table-column>
        <el-table-column align="center" type="index" label="序号" max-width="70" min-width="40">
        </el-table-column>
        <el-table-column max-width="50" min-width="15"> </el-table-column>
        <el-table-column align="left" prop="userName" label="人员姓名" max-width="180" min-width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column width="10"> </el-table-column>
        <el-table-column align="left" prop="groupName" label="所在分组" max-width="250" min-width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="textOverOneLine">
              {{
                `${scope.row.parentName ? scope.row.parentName + '-' : ''}${
                  scope.row.groupName
                }`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column max-width="30" min-width="10"> </el-table-column>
        <el-table-column align="left" prop="custName" label="所在单位" max-width="220" min-width="145" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="taskNum" label="任务总数" max-width="160" min-width="100">
          <template slot-scope="scope">
            <div class="wp-pointer wp-blue" @click.stop="showTaskDetails(scope.row)">
              {{
                scope.row.taskNum
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="taskFinshNum" label="完成数量" max-width="160" min-width="100">
        </el-table-column>
        <el-table-column max-width="160" min-width="100" v-if="searchParams.timeType == 2" align="center" prop="completionRate" label="完成率">
          <template slot-scope="scope">
            <div>{{ scope.row.completionRate }}%</div>
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" sortable prop="workNum" label="作品数量">
        </el-table-column>
        <el-table-column align="center" sortable prop="score" min-width="100" label="奖励积分">
        </el-table-column>
        <el-table-column align="center" sortable prop="excellentScore" min-width="100" label="优评积分" v-if="isCanAccessExcellentReview">
        </el-table-column>
        <el-table-column align="center" sortable prop="sumScore" min-width="100" label="总积分" v-if="isCanAccessExcellentReview">
        </el-table-column>
      </el-table>

      <div class="wp-noData" v-if="tableData.length == 0 && !loading">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <TaskDetails ref="taskDetails" :searchParams="taskDetailsQuery"></TaskDetails>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import TaskDetails from '@/views/task/taskStatistics/components/taskDetails'
import { mapGetters } from 'vuex';
export default {
  components:{TaskDetails},
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    },
    searchParams: {
      type: Object,
      default: () => { }
    },
    loading: false
  },
  watch: {

  },
  data () {
    return {
      parentParams: {},
      sortObj: {},
      taskDetailsQuery:{}
    }
  },
  created () {

  },
  computed: {
    ...mapGetters(['sysUserInfo']),
    isCanAccessExcellentReview(){
      return Number(this.sysUserInfo.sysCustOption.accessExcellentReview) == 1
    }
  },
  methods: {
    // 明细导出
    async detailExport (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.memberStatisticsExport({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }

    },

    // 分月导出
    async exportFile (event) {
      if (!this.searchParams.startTime || !this.searchParams.endTime) {
        return this.$showMessage({ type: 'warning', msg: "请先选择时间段" })
      }

      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await taskApi.memberMonthExport({ ...this.searchParams })
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }
      this.$emit("sortChange", { orderField: prop, sort: order })
    },
     // 任务明细
     showTaskDetails(row){
      this.taskDetailsQuery = {
        userId: row.userId, //用户ID
        timeType: this.searchParams.timeType, //1文章提交时间2任务时间
        startTime: this.searchParams.startTime, //搜索开始时间yyyy-MM-dd
        endTime:this.searchParams.endTime, //搜索结束时间yyyy-MM-dd
        taskId: this.searchParams.taskId, //任务ID
        localType: this.searchParams.localType //1本组任务2市办任务(组管理员使用)
      }
      this.$refs.taskDetails.show()
    }
  },
}
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}
</style>
