<template>
  <div class="wp-w100">
    <el-scrollbar ref="scroll" class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/taskManagement' }"
            >投稿管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>批量任务</el-breadcrumb-item>
        </el-breadcrumb>

        <Steps class="mt-16" :active="active" :stepsList="stepsList"></Steps>

        <OneStep ref="oneStep" class="mt-16" v-show="active == 1"></OneStep>

        <TwoStep
          ref="twoStep"
          @inStepTask="inStepTask"
          :taskInfoList="taskInfoList"
          :updateTaskInfoList="updateTaskInfoList"
          :configObj="configObj"
          @twoCancel="twoCancel"
          @twoNextStep="twoNextStep"
          v-show="active == 2"
        ></TwoStep>

        <ThreeStep
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="正在发布"
          ref="threeStep"
          v-show="active == 3"
          @changeUser="changeUser"
        ></ThreeStep>

        <FourStep
          :messageFlag="messageFlag"
          :timeCount="timeCount"
          v-show="active == 4"
        ></FourStep>

        <div class="wp-btn-card mt-16 wp-center" v-show="active == 1">
          <el-button @click="cancel" class="wp-button">取消</el-button>
          <el-button
            style="margin-left: 24px"
            class="wp-button"
            v-preventReClick
            type="primary"
            @click="nextStep"
            >下一步
          </el-button>
        </div>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 3">
          <el-button @click="cancel" class="wp-button">上一步</el-button>
          <el-button
            style="margin-left: 24px"
            class="wp-button"
            v-preventReClick
            type="primary"
            @click="submitBatchTask"
            >确定
          </el-button>
          <el-checkbox v-model="sendMsg" style="margin-left: 25px">
            短信通知（余量：<span class="wp-blue">{{ messageNum }}</span>
            ）</el-checkbox
          >
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import { mapGetters } from 'vuex'
import Steps from '@/views/components/steps'
import OneStep from './steps/oneStep'
import TwoStep from './steps/twoStep'
import ThreeStep from './steps/threeStep'
import FourStep from './steps/fourStep'
export default {
  components: { Steps, OneStep, TwoStep, ThreeStep, FourStep },
  data () {
    return {
      stepsList: ["填写任务链接", "设置任务要求", "选择人员", "发布成功"], // 步骤条列表
      active: 1,
      sendMsg: false, // 短信通知
      advancedSetting: [], // 高级设置
      taskInfoList: [], // 任务信息列表'
      configObj: {}, // 任务配置
      defaultData: {},
      updateTaskInfoList: [],
      resultObj: [], // 处理后的任务信息
      messageNum: 0, // 短信余量
      timeCount: 0,
      messageFlag: true,
      loading: false
    }
  },

  watch: {
    active: {
      handler (newVal) {
        this.$refs.scroll.wrap.scrollTop = 0
      }
    }
  },

  async created () {
    // 获取单位配置
    await this.getConfig()
    // 获取短信余量
    this.getSmsNum()
    // await this.nextStep()
  },
  methods: {
    /** 监听选中人员 */
    changeUser (userList) {
      // 短信余量小于选中人员数量
      if (this.messageNum < userList.length) {
        this.sendMsg = false
      }
    },

    /** 获取短信余量 */
    async getSmsNum () {
      const res = await taskApi.getSmsNum({})
      if (res.returnCode == 200) {
        this.messageNum = res.returnData
      }
    },

    // 获取单位默认参数
    async getConfig () {
      const res = await taskApi.getConfigList({})
      if (res.returnCode == 200) {
        const data = res.returnData

        // 积分列表
        const scoreList = data.taskScore || []
        // 字数要求
        const taskWordsRequire = data.taskWordsRequire || []
        // 单人上限
        const taskSingleLimit = data.taskSingleLimit || []
        // 起止时间快捷选项
        const timeList = data.taskTime

        // 默认分数
        const score = data.taskScore.find(item => item.configCheck).configValue

        // 设置默认字数要求
        const minWord = data.taskWordsRequire.find(item => item.configCheck).configValue

        // 设置默认单人上限
        const maxArticle = data.taskSingleLimit.find(item => item.configCheck).configValue

        // 设置时间默认选中项
        const timeItem = data.taskTime.find(item => item.configCheck)
        const timeRangeType = timeItem.configName

        // 默认选中项
        this.defaultData = {
          score,
          minWord,
          maxArticle,
          timeItem,
          timeRangeType
        }

        // 配置项列表
        this.configObj = {
          scoreList,
          taskWordsRequire,
          taskSingleLimit,
          timeList
        }

        this.sendMsg = +data.taskSmsNotice?.[0]?.configValue ? true : false

        // 高级设置
        this.setConfigValue(data)
      }
    },

    // 同步任务
    inStepTask (list) {
      console.log(list);
      this.updateTaskInfoList = list
    },

    // 高级设置
    setConfigValue (data) {
      //作品标题 taskArticleTitle
      let advancedSetting = []
      let taskArticleTitle = data.taskArticleTitle[0].configValue.toString()
      advancedSetting.push({ ...data.taskArticleTitle[0], isShow: taskArticleTitle.charAt(0) == '1', isRequired: taskArticleTitle.charAt(1) == '1', isDisbled: false })

      //作品内容 taskAarticleContent
      let taskAarticleContent = data.taskAarticleContent[0].configValue.toString()
      advancedSetting.push({ ...data.taskAarticleContent[0], isShow: taskAarticleContent.charAt(0) == '1', isRequired: taskAarticleContent.charAt(1) == '1', isDisbled: false })

      //作品链接 taskArticleUrl	
      let taskArticleUrl = data.taskArticleUrl[0].configValue.toString()
      advancedSetting.push({ ...data.taskArticleUrl[0], isShow: taskArticleUrl.charAt(0) == '1', isRequired: taskArticleUrl.charAt(1) == '1', isDisbled: false })

      //附件/截图 taskArticleFile
      let taskArticleFile = data.taskArticleFile[0].configValue.toString()
      advancedSetting.push({ ...data.taskArticleFile[0], isShow: taskArticleFile.charAt(0) == '1', isRequired: taskArticleFile.charAt(1) == '1', isDisbled: true })

      this.advancedSetting = advancedSetting
    },

    // 下一步
    async nextStep () {
      if (this.active == 1) {
        const urls = this.$refs.oneStep.batchTaskRule() || []
        if (urls.length) {
          const res = await taskApi.getWebsiteBatch({ urls })
          if (res.returnCode == 200) {
            const ret = res.returnData

            const { score, minWord, maxArticle, timeRangeType, timeItem } = this.defaultData

            // 未获取到标题的链接索引
            let errIndex = ""

            // 表单列表
            const formList = []

            ret.forEach((item, index) => {
              // 未获取到标题,记录索引
              if (!item?.website?.title) {
                errIndex += index + 1 + ','
              }

              let taskWayIds = []

              // 任务方式Id列表
              item?.styleList.forEach(value => {
                taskWayIds.push(value.dataValue)
              })

              // 测试一键同步时放开
              // if (index == 0) {
              //   taskWayIds = [1, 2, 3]
              //   item.sysDict.dataValue = 1
              // } else if (index == 1) {
              //   taskWayIds = [2, 3, 6]
              //   item.sysDict.dataValue = 2
              // } else {
              //   taskWayIds = [4, 2, 8]
              //   item.sysDict.dataValue = 3
              // }

              const taskInfo = {
                isUrgent: false, // 任务是否紧急

                // 基础设置
                basicsForm: {
                  taskTypeId: "", // 任务分类Id
                  title: item?.website?.title || "", // 任务标题
                  taskStyle: "1", // 任务类型
                  url: item.url.trim(), // 任务链接
                  content: "", // 任务要求
                  websiteName: item.sysDict?.dataKey || "", // 站点名称
                  websiteId: item.sysDict?.dataValue || "", // 站点字典Value
                },
                // 任务设置
                taskForm: {
                  modeStyle: "", // 任务方式
                  taskWayList: item?.styleList || [], // 任务方式列表
                  taskWayIds,
                  timeSection: [], // 任务起止时间区间
                  startTime: "", // 任务开始时间
                  endTime: "", // 任务结束时间
                  score, // 任务积分
                  minWord, // 字数要求
                  maxArticle, // 单人上限
                  timeRangeType, // 默认选中时间
                  timeItem,
                  customScore: "", // 自定义积分
                  customWord: "", // 自定义字数要求
                  customArticle: "", // 自定义单人上限
                },
                // 高级设置
                advancedSetting: JSON.parse(JSON.stringify(this.advancedSetting)),
              }

              formList.push(taskInfo)
            })

            this.taskInfoList = formList
            errIndex = errIndex.substring(0, [errIndex.length - 1])

            if (errIndex) {
              this.$showMessage({ type: "warning", msg: `第${errIndex}条获取标题失败,请手动输入` })
            }

            this.active = 2
          }
        }
      } else {
        this.active = 4
      }
    },

    // 填写任务信息 - 下一步
    twoNextStep (arr) {
      if (arr.length) {
        const resultObj = {
          wpTaskMains: []
        }
        arr.forEach(item => {
          delete item.taskForm.taskWayList

          // 任务方式
          item.taskForm.modeStyle = item.taskForm.modeStyle.join(',')
          // 是否紧急
          item.basicsForm.isUrgent = item.isUrgent

          // 高级设置
          item.advancedSetting.forEach(item => {
            item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
          })

          // 高级设置
          item.advancedSetting.forEach(value => {
            if (value.configName == "作品标题") {
              item.taskForm.parmTitle = value.configValue
            } else if (value.configName == "作品内容") {
              item.taskForm.parmContent = value.configValue
            } else if (value.configName == "作品链接") {
              item.taskForm.parmUrl = value.configValue
            } else {
              item.taskForm.parmAttach = value.configValue
            }
          })
          resultObj.wpTaskMains.push({ ...item.basicsForm, ...item.taskForm })
        })

        this.resultObj = resultObj
        this.active = 3
      } else {
        return false
      }

    },

    // 填写任务信息 - 取消
    twoCancel () {
      this.active = 1
    },

    // 返回上一页
    cancel () {
      if (this.active == 1) {
        this.$router.push('/taskManagement')
      } else {
        this.active = 2
      }
    },

    /** 提交批量任务 */
    async submitBatchTask () {
      if (this.loading) return
      const userList = this.$refs.threeStep.getFormData()

      if (userList?.length) {
        this.loading = true

        this.resultObj.sendMsg = this.sendMsg
        this.resultObj.userList = userList

        try {
          const res = await taskApi.insertBatch(this.resultObj)
          this.loading = false
          if (res.returnCode == 200 || res.returnCode == 10086) {
            this.messageFlag = true
            if (res.returnCode == 10086) {
              this.messageFlag = false
            }
            this.timeCount = 2000
            this.active = 4
            // this.$showMessage({ type: 'success', msg: res.returnMsg })
          } else {
            this.$showMessage({ type: 'error', msg: res.returnMsg })
          }
        } catch (error) {
          this.loading = false
        }
      } else {
        this.$showMessage({ type: 'error', msg: "请选择人员" })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>