var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "asideList" }, [
    _c("div", { staticClass: "head" }, [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-input",
            {
              staticClass: "search-w176 wp-input",
              attrs: { clearable: "", placeholder: "请输入队伍名称" },
              model: {
                value: _vm.searchContent,
                callback: function ($$v) {
                  _vm.searchContent = $$v
                },
                expression: "searchContent",
              },
            },
            [
              _c("i", {
                staticClass: "wp-input-icon icon-sousuo",
                staticStyle: { "font-size": "20px" },
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "complete flex-CB wp-pointer",
          class: { active_item: !_vm.checkId },
        },
        [
          _c(
            "div",
            {
              staticClass: "fs-16 fw-bold wp-pointer",
              on: { click: _vm.getAll },
            },
            [_vm._v("全部")]
          ),
          _c("div", [
            !_vm.xjxqFlag
              ? _c("i", {
                  staticClass: "el-input__icon icon-tianjia fs-20",
                  on: { click: _vm.addGroup },
                })
              : _vm._e(),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "list-box",
        attrs: {
          "element-loading-spinner": "el-icon-loading",
          "element-loading-text": "正在加载",
        },
      },
      [
        _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
          _c(
            "div",
            { staticClass: "list" },
            [
              _c("el-tree", {
                ref: "treeNodeKey",
                attrs: {
                  "filter-node-method": _vm.filterNode,
                  data: _vm.treeData,
                  "node-key": "groupId",
                  props: _vm.defaultProps,
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  "expand-on-click-node": false,
                },
                on: {
                  "node-click": _vm.nodeClick,
                  "node-expand": _vm.nodeExpand,
                  "node-collapse": _vm.nodeCollapse,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "span",
                        { staticClass: "custom-tree-node" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: node.label,
                                placement: "top",
                                disabled: !_vm.isShowTooltipWidth,
                                tabindex: node.id,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "node-labe textOverOneLine",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.visibilityChangeWidth($event)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(node.label) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "wp-dropdown",
                                  attrs: {
                                    placement: "bottom-end",
                                    trigger: "click",
                                  },
                                  on: { command: _vm.handlerItem },
                                },
                                [
                                  _c("div", { staticClass: "icon" }, [
                                    data.operate != 1
                                      ? _c("i", {
                                          staticClass:
                                            "el-input_icon el-icon-more",
                                        })
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "wp-dropdown-menu",
                                      attrs: {
                                        slot: "dropdown",
                                        visibleArrow: false,
                                      },
                                      slot: "dropdown",
                                    },
                                    [
                                      !data.isHaveUser && data.operate != 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: ["add", data],
                                                icon: "icon-fenzu",
                                              },
                                            },
                                            [_vm._v(" 添加子分组 ")]
                                          )
                                        : _vm._e(),
                                      data.operate != 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: ["edit", data],
                                                icon: "icon-bianji1",
                                              },
                                            },
                                            [_vm._v("修改名称")]
                                          )
                                        : _vm._e(),
                                      data.operate != 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: ["remove", data],
                                                icon: "icon-shanchu",
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      data.isShiftUp && data.operate != 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: ["shiftUp", data],
                                                icon: "icon-shangyi",
                                              },
                                            },
                                            [_vm._v("上移 ")]
                                          )
                                        : _vm._e(),
                                      data.isShiftDown && data.operate != 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: ["shiftDown", data],
                                                icon: "icon-xiayi",
                                              },
                                            },
                                            [_vm._v("下移 ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }