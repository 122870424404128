<template>
  <el-form class="form" :model="basicsForm" :rules="basicsFormRules" ref="basicsForm" label-width="100px">
    <el-form-item class="item-content" label="演练主题：" prop="keyword">
      <el-input maxlength="100" class="wp-input" v-model.trim="basicsForm.keyword" placeholder="请填写演练主题"></el-input>
    </el-form-item>
    <el-form-item class="item-content" label="URL地址：" prop="url">
      <el-input maxlength="2000" class="wp-input" v-model.trim="basicsForm.url" placeholder="请输入微博链接"></el-input>
    </el-form-item>
    <div class="flex-CB">
      <div class="half-item">
        <el-form-item class="item-content" label="开始时间：" prop="startTime">
          <div class="wp-date-picker">
            <el-date-picker value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" prefix-icon="noicon" v-model="basicsForm.startTime" type="datetime" clearable placeholder="请选择开始时间" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </el-form-item>
      </div>
      <div class="half-item">
        <el-form-item class="item-content" label="演练时长：" prop="duration">
          <el-input maxlength="4" class="wp-input" v-model.trim="basicsForm.duration" placeholder="演练时长不能小于5分钟" @change="resetNumDrillSetting('duration',5,30,true)">
            <i slot="suffix" style="color:#333333;margin-right: 10px;">分钟</i>
          </el-input>
        </el-form-item>
      </div>
    </div>
    <div class="flex-CB">
      <div class="half-item">
        <el-form-item class="item-content" label="热评数量：" prop="hotNum">
          <el-input maxlength="4" class="wp-input" v-model.trim="basicsForm.hotNum" placeholder="请输入热评数量" @change="resetNumDrillSetting('hotNum',30,100,true)"></el-input>
        </el-form-item>
      </div>
      <div class="half-item">
        <el-form-item class="item-content" label="胜方积分：" prop="winnerScore">
          <el-input maxlength="4" class="wp-input" v-model.trim="basicsForm.winnerScore" placeholder="请输入胜方获得的积分" @change="resetNumDrillSetting('winnerScore',0,100,false)"></el-input>
        </el-form-item>
      </div>
    </div>
    <div class="flex-CB">
      <div class="half-item">
        <el-form-item class="item-content" label="参与积分：" prop="joinScore">
          <el-input maxlength="4" class="wp-input" v-model.trim="basicsForm.joinScore" placeholder="请输入参与获得的积分" @change="resetNumDrillSetting('joinScore',0,100,false)"></el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import taskApi from '@/api/task.js'
import { validURL, resetNumPointOne } from '@/utils/validate.js';
import { sysCustConfigList } from '@/api/systemSettings.js';
export default {
  components: {},
  props: {
    taskDetial: {
      type: Object,
      default: () => { }
    },
    isEidt: {
      type: Boolean,
      default: false
    },
  },
  data () {
    var validateUrl = (rule, value, callback) => {
      console.log(value);
      let domains = value.split('/');
      let domain = ''
      if (domains[2]) {
        domain = domains[2];
      }
      if (value === '') {
        callback(new Error('请输入微博链接'));
      } else if (!validURL(value) || domain.indexOf('weibo') == -1) {
        callback(new Error('请输入有效的微博链接'));
      } else {
        callback();
      }
    };
    return {
      basicsForm: {
        keyword: '',//	演练主题	
        url: '',//链接	
        startTime: '',//开始时间yyyy-MM-dd HH:mm:ss	
        duration: 0,//持续时间	
        hotNum: 0,//热评数量	
        winnerScore: 0,//胜方积分	
        joinScore: 0,//参与积分
      },
      basicsFormRules: {
        keyword: [{ required: true, trigger: ["blur"], message: "请填写演练主题" }],
        url: [{ required: true, trigger: "blur", message: "请输入微博链接" },
        { validator: validateUrl, trigger: "blur" }
          // 需要校验链接是否是属于微博链接。不支持其他平台链接。
          // 2）链接不合法/平台和链接不匹配：链接不正确，请输入有效的微博链接。
        ],
        //         开始时间只能是任务创建时间之后的时间（前面的时间不可选）。                  
        // 时间格式：yyyy-mm-dd hh:mm 
        // 开始前5分钟前不允许编辑时间往前调整。
        // 未来可能会提出需要限制一个时间段不能重复创建演练的需要，本次设计时候需要考虑这种场景。便于后期拓展。 
        startTime: [{ required: true, trigger: "blur", message: "请选择开始时间" }],
        //         数值格式，5<=n<=30。不在区间范围的默认变为最接近的极值。
        // （例如：输入4变为5）
        duration: [{ required: true, trigger: "blur", message: "演练时长不能小于5分钟" }],
        //         数值格式，30<= n <= 100。不在区间范围的默认变为最接近的极值。
        // （例如：输入4变为5）
        hotNum: [
          { required: true, trigger: "blur", message: "热评数量不能小于30条" },
        ],
        // 数值格式，n>=0,支持一位小数，胜方参与人员获得该积分。该积分会计入积分总数。
        winnerScore: [{ required: true, trigger: "blur", message: "请输入胜方获得的积分" }],
        // 数值格式，n>=0,支持一位小数，参与人员无论输赢均可获得该积分。该积分会计入积分总数。
        joinScore: [{ required: true, trigger: "blur", message: "请输入参与获得的积分" }],
      },
      pickerOptions: {},
      oldStartTime: '',
    }
  },

  created () {
    if (this.isEidt) {
      this.setFormItem()
    } else {
      this.getSysCustConfigList()
    }
  },
  mounted () {
    this.setPickerOptions()

  },
  watch: {
    taskDetial: {
      handler (val, oldval) {
        if (val) {
          this.setFormItem()
        }
      },
      deep: true
    }
  },
  methods: {
    // 演练时间限制
    setPickerOptions () {
      // 开始时间只能是任务创建时间之后的时间（前面的时间不可选）
      // 如果是编辑,开始前5分钟前不允许编辑时间往前调整 
      // let time1 = new Date()
      // let time1 = this.oldStartTime ? new Date(this.oldStartTime) : new Date()
      // let startDate = time1
      let startDate = new Date() - 24 * 60 * 60 * 1000
      this.$nextTick(() => {
        this.pickerOptions = {
          // selectableRange: (() => {
          //   let data = time1;
          //   let hour = data.getHours();
          //   let minute = data.getMinutes();
          //   let second = data.getSeconds();
          //   if (this.isEidt) {
          //     minute = data.getMinutes() - 5;
          //   }
          //   // minute = data.getMinutes() - 5;
          //   return [`${hour}:${minute}:${second} - 23:59:59`]
          // })(),
          disabledDate (time) {
            return time.getTime() < startDate
          }
        }
      })
    },
    // 投稿演练 数值 
    resetNumDrillSetting (prop, min, max, initType) {
      this.basicsForm[prop] = resetNumPointOne(this.basicsForm[prop], min, max, initType)
    },
    // 清除表单校验
    clearValidate () {
      this.$refs.basicsForm.clearValidate()
    },
    // 获取表单数据
    getFormData () {
      let flag = false
      console.log(this.$refs.basicsForm);
      this.$refs.basicsForm.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })

      if (flag) {
        const query = JSON.parse(JSON.stringify(this.basicsForm))
        return query
      }
    },
    // 清除表单数据项
    resetFields () {
      this.$refs.basicsForm.resetFields()
    },
    // 获取参数配置
    async getSysCustConfigList () {
      try {
        let res = await sysCustConfigList({})
        if (Number(res.returnCode) !== 200) return
        let sysCustConfig = res.returnData
        this.basicsForm.duration = sysCustConfig.drillTime[0].configValue
        this.basicsForm.hotNum = sysCustConfig.hotCommentNum[0].configValue
        this.basicsForm.winnerScore = sysCustConfig.winScore[0].configValue
        this.basicsForm.joinScore = sysCustConfig.participateScore[0].configValue
      } catch (error) {
        console.log(error)
      }
    },
    setFormItem () {
      this.$nextTick(() => {
        this.basicsForm.keyword = this.taskDetial.keyword
        this.basicsForm.url = this.taskDetial.url
        this.basicsForm.startTime = this.taskDetial.startTime
        this.oldStartTime = this.taskDetial.startTime
        this.basicsForm.duration = this.taskDetial.duration
        this.basicsForm.hotNum = this.taskDetial.hotNum
        this.basicsForm.winnerScore = this.taskDetial.winnerScore
        this.basicsForm.joinScore = this.taskDetial.joinScore
        this.setPickerOptions()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>