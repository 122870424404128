var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progressDataList" }, [
    _c(
      "ul",
      _vm._l(_vm.dataList, function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "progress-li" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "top",
                  "popper-class": "progress-popover",
                  trigger: "hover",
                  "visible-arrow": false,
                },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "flex-Acenter" }, [
                    _c("div", { staticClass: "progress-popover-left" }, [
                      _c("span", { staticClass: "dot" }),
                    ]),
                    _c("div", { staticClass: "progress-popover-right" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-Acenter popover-row " }, [
                    _c("div", { staticClass: "progress-popover-left" }),
                    _c("div", { staticClass: "progress-popover-right" }, [
                      _c("p", [_vm._v("投稿数量 " + _vm._s(item.total))]),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-Acenter popover-row" }, [
                    _c("div", { staticClass: "progress-popover-left" }),
                    _c("div", { staticClass: "progress-popover-right" }, [
                      _c("p", [
                        _vm._v("投稿站点占比 " + _vm._s(item.num) + "%"),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-li-box",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "progress-name textOverOneLine" },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _c(
                      "div",
                      { staticClass: "progress-bar" },
                      [
                        _c("el-progress", {
                          attrs: {
                            percentage: item.num,
                            color: "#0064E6",
                            "stroke-width": 6,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _vm.dataList.length == 0
      ? _c("div", { staticClass: "wp-noData" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/noData.png"), alt: "" },
          }),
          _c("p", [_vm._v("暂无数据")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }