var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.isEidt ? "编辑优评上报" : "优评上报",
        width: "680px",
        height: "643px",
        dialogVisible: _vm.dialogVisible,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "createModel" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleform",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "taskName", label: "优评时段：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入优评时段",
                      clearable: "",
                      readonly: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.taskName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "taskName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "userId", label: "人员账号：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "wp-select",
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择人员账号",
                      },
                      on: { change: _vm.changePersonnel },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.determine.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "userId", $$v)
                        },
                        expression: "ruleForm.userId",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.loginName, value: item.userId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "人员姓名：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: { placeholder: "请输入人员姓名", readonly: "" },
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务标题：", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "100",
                      placeholder: "请输入任务标题",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务链接：", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务链接", maxlength: "2000" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.url,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "url",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "评论内容：", prop: "content" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.determine.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "remarks",
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      placeholder: "请输入评论内容",
                      resize: "none",
                    },
                    model: {
                      value: _vm.ruleForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "content", $$v)
                      },
                      expression: "ruleForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "截图/附件：", prop: "fileJson" } },
                [
                  _c(
                    "div",
                    { staticClass: "upload-box" },
                    [
                      _c("UploadFile", {
                        key: "img",
                        ref: "upload",
                        attrs: {
                          fileList: _vm.fileJson,
                          imageMaxNum: 5,
                          maxVideo: 0,
                          placeholder: "请上传jpg、jpeg、png格式文件",
                          videoMaxNum: 0,
                          fileType: ["jpg", "jpeg", "png"],
                          accept: ".jpg, .jpeg, .png",
                        },
                        on: {
                          fileChange: _vm.fileChange,
                          changeUpload: _vm.changeUpload,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }