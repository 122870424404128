<template>
  <div class="header-view">
    <div class="header-left flex-Acenter">
      <img src="~@/assets/images/mls/mlslogo.png" alt="" v-if="isMls" />
      <img
        src="~@/assets/images/header/logo@2x.png"
        alt=""
        v-else-if="!isMls"
      />
      <!-- <i class="icon-logo"></i>
      <p>云评智慧投稿系统</p> -->
    </div>

    <div class="header-right">
      <el-popover
        v-if="xjxqFlag"
        popper-class="app-popover"
        placement="bottom"
        trigger="hover"
        :visible-arrow="false"
      >
        <div class="popover-content">
          <img :src="AndroidUrl" class="code" />
          <p style="margin-top: 4px">安卓端</p>
        </div>

        <img
          class="app-img"
          slot="reference"
          src="~@/assets/images/header/app.png"
          alt=""
        />
      </el-popover>

      <div class="news-info" v-if="(roleId == 3 || roleId == 4) && !!token">
        <el-popover
          placement="bottom"
          :visible-arrow="false"
          trigger="manual"
          v-model="visible"
          popper-class="xiaoxi-popper"
        >
          <div class="xiaoxi-content">
            您有一条新的消息
            <img
              src="~@/assets/images/header/guanBi.png"
              @click="visible = !visible"
              alt=""
            />
          </div>
          <div
            class="news"
            :class="{ 'news-before': xiaoxiCount > 0 }"
            slot="reference"
            @click="toMessageCenter"
          >
            <img src="~@/assets/images/header/xiaoXi.png" alt="" />
          </div>
        </el-popover>
      </div>

      <div class="news-info" v-if="roleId == 2 && !!token">
        <div class="news" slot="reference" @click="toMessageCenter">
          <img src="~@/assets/images/header/xiaoXi.png" alt="" />
        </div>
      </div>

      <div class="download-info" v-if="(roleId == 2 || roleId == 3) && !!token">
        <el-popover
          placement="bottom-end"
          trigger="click"
          :visible-arrow="false"
          v-model="visible2"
          popper-class="xiaoxi-popper"
        >
          <download-list ref="downloadList" :isShow="visible2"></download-list>
          <div
            class="download"
            :class="{ 'download-before': isShowRed }"
            slot="reference"
            @click="changeIsShowRed"
          >
            <img src="~@/assets/images/header/xiaZai.png" alt="" id="xiaZai" />
          </div>
        </el-popover>

        <el-popover
          placement="bottom"
          :visible-arrow="false"
          trigger="manual"
          v-model="visible3"
          popper-class="xiaoxi-popper"
        >
          <div class="xiaoxi-content">
            您有新的下载任务
            <img
              src="~@/assets/images/header/guanBi.png"
              @click="changeIsShowTip"
              alt=""
            />
          </div>
          <div class="news" slot="reference"></div>
        </el-popover>
      </div>

      <div class="admin-info" v-if="!!token">
        <div class="avtar-img" v-if="roleId == 1 || roleId == 2">
          <img src="~@/assets/images/header/adminAvtar@2x.png" alt="" />
        </div>
        <div class="avtar-img" v-else>
          <img src="~@/assets/images/header/wpAvtar.png" alt="" />
        </div>
        <div class="name-info">
          {{ sysCust.aliasName ? sysCust.aliasName : sysCust.custName }}-{{
            userInfo.userName
          }}
        </div>
        <div class="switch">
          <a
            href="javascript:;"
            @click="toSwitchLogin"
            v-if="userList.length > 1 && roleId != 1"
            >切换</a
          >
        </div>
      </div>

      <div class="login-out" v-if="!!token">
        <el-popover
          placement="bottom"
          trigger="hover"
          :visible-arrow="false"
          popper-class="xiaoxi-popper"
        >
          <div class="xiaoxi-content">
            <a href="javascript:;" class="out-btn" @click="loginOut"
              ><i class="icon-tuichu"></i> 退出</a
            >
          </div>
          <div class="out-img" slot="reference">
            <img src="~@/assets/images/header/tuichu@2x.png" alt="" />
          </div>
        </el-popover>
      </div>

      <div class="login-btn-box" v-else>
        <el-button type="text" class="login-btn" @click="toLogin"
          >登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadList from './components/downloadList.vue'
import { count } from '@/api/messageCenter.js';
import { isMls } from '@/settings.js';
export default {
  name: 'header-view',
  components: { DownloadList },
  data () {
    return {
      AndroidUrl: process.env.VUE_APP_ANDROID_URL,
      isMls: isMls,
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      xiaoxiCount: 0, // 消息数量
      xjxqFlag: false, // 湘江新区
    };
  },
  props: {},
  created () {

  },
  mounted () {
    // 只有组长和组员才需要显示红点和 未接收消息
    if (this.roleId == 3 || this.roleId == 4) {
      this.getCount()
    }

    this.xjxqFlag = this.sysUserInfo?.xjxqFlag

    // 切换用户 初始化 不显示下载的红点
    this.$store.commit('app/SET_ISSHOWRRED', false)
  },
  computed: {
    ...mapGetters(['userList', 'userInfo', 'roleId', 'token', 'isShowRed', 'isShowTip', 'userId', 'sysUserInfo', 'sysCust'])
  },
  methods: {
    changeIsShowTip () {
      this.$store.commit('app/SET_ISSHOWTIP', false)
    },
    // 隐藏红点和 提示
    changeIsShowRed () {
      // this.visible2 = !this.visible2
      this.$store.commit('app/SET_ISSHOWRRED', false)
    },
    // ...mapMutations(['SET_REMINDNUM']),
    showXiaoXi () {
      this.visible = true
    },
    loginOut () {
      this.$wpConfirm('提示', {
        tips: "确定退出系统吗？",
        content: "点击确认将退出系统",
        yesBtnText: '确认',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postLoginOut()
      }).catch(() => {
        console.log("取消")
      });
    },
    toLogin () {
      this.$router.push({ name: 'login' })
    },
    postLoginOut () {
      let that = this
      this.$store.dispatch('LogOut').then(() => {
        that.$router.push({ name: 'login' })
      }).catch(() => {
        that.$router.push({ name: 'login' })
      })
    },
    toMessageCenter () {
      this.xiaoxiCount = 0
      this.visible = false
      // 判断是网评员 还是 管理员 网评员
      if (this.userInfo.roleId == 1 || this.userInfo.roleId == 2) {
        // 管理员
        this.$router.push({ name: 'messageCenter' })
      } else {
        // 网评员
        this.$router.push({ name: 'wpMessageCenter' })
      }
      // setTimeout(() => {
      //   this.getCount()
      // }, 10000)
    },
    toSwitchLogin () {
      // this.$router.push({ name: 'switchLogin' })
      window.location.href = '/switchLogin'
    },
    async getCount () {
      try {
        let res = await count({})
        if (Number(res.returnCode) !== 200) return
        this.xiaoxiCount = res.returnData
        if (res.returnData > 0) {
          setTimeout(() => {
            this.showXiaoXi()
          }, 500)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },

  },
  beforeDestroy () {

  },
  watch: {
    // 是否显示红点
    isShowRed: {
      handler (val, oldval) {
        console.log(val)
        if (val) {
          this.$nextTick(() => {
            this.visible3 = true
          })
        } else {
          this.visible3 = false
        }
      },
      deep: true
    },
    isShowTip: {
      handler (val, oldval) {
        console.log(val)
        if (val) {
          this.$nextTick(() => {
            this.visible3 = true
          })
        } else {
          this.visible3 = false
        }
      },
      deep: true
    },
    $route: {
      handler (val, oldval) {
        console.log()
        if (val && val.path != "/wpMessageCenter") {
          if (this.roleId == 3 || this.roleId == 4) {
            this.getCount()
          }
        }
      },
      deep: true
    }
    // userId: {
    //   handler (val, oldval) {
    //     console.log("海边了")
    //     console.log(val)
    //     this.$nextTick(() => {
    //       this.changeIsShowRed()
    //     })

    //   },
    //   deep: true
    // },
  }
};
</script>
<style scoped lang="scss">
@import './css/header.scss';
</style>
<style lang="scss">
.el-popover.el-popper.app-popover {
  min-width: 100px;
  background-color: #ffffff;
  border: solid 1px #c0ccfb;
  padding: 6px 10px;

  .popover-content {
    text-align: center;

    .code {
      width: 76px;
      height: 76px;
    }
  }
}

.xiaoxi-popper.el-popover {
  width: auto;
  padding: 12px 18px;
  min-width: 100px;
  z-index: 1000 !important;

  .xiaoxi-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    img {
      cursor: pointer;
      width: 16px;
      margin-left: 20px;
    }

    .out-btn {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      display: flex;
      align-items: center;
    }
    .icon-tuichu {
      font-size: 20px;
    }
  }
}
</style>
