<template>
  <div class="wp-w100">
    <el-scrollbar ref="scroll" class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/taskList' }">投稿列表</el-breadcrumb-item>
          <el-breadcrumb-item>转发任务</el-breadcrumb-item>
        </el-breadcrumb>

        <Steps class="mt-16" :active="active" :stepsList="stepsList"></Steps>
        <OneStep :taskTypeId="taskTypeId" v-show="active == 1" ref="oneStep"></OneStep>
        <TwoStep v-show="active == 2" ref="twoStep" @changeUser="changeUser"></TwoStep>
        <ThreeStep :messageFlag="messageFlag" v-show="active == 3" :timeCount="timeCount">
        </ThreeStep>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 1">
          <el-button @click="oneCancel" class="wp-button">取消</el-button>
          <el-button v-if="this.queryData.id" style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="submitTask">确定
          </el-button>
          <el-button v-else style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="nextStep">下一步
          </el-button>
        </div>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 2">
          <el-button @click="twoCancel" class="wp-button">上一步</el-button>
          <el-button style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="submitTask">确定
          </el-button>
          <el-checkbox v-model="taskData.sendMsg" style="margin-left: 25px">
            短信通知（余量：<span class="wp-blue">{{ messageNum }}</span>
            ）</el-checkbox>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import taskApi from '@/api/task.js'
import Steps from '@/views/components/steps'
import OneStep from './steps/oneStep' // 基础设置
import TwoStep from './steps/twoStep'
import ThreeStep from './steps/threeStep'
export default {
  components: { Steps, OneStep, TwoStep, ThreeStep },
  data () {
    return {
      stepsList: ["创建任务", "选择人员", "发布成功"], // 步骤条列表
      active: 1,
      queryData: {}, // 从列表带入的数据
      // 专题数据
      taskData: {
        wpTaskMain: {},
        userList: [],
        sendMsg: false, // 发送短信
      },
      taskTypeId: "",
      timeCount: 0,
      messageNum: 0, // 短信余量
      messageFlag: true,
      loading:false
    }
  },

  watch: {
    active: {
      handler (newVal) {
        this.$refs.scroll.wrap.scrollTop = 0
      }
    }
  },

  created () {
    // 任务分类Id
    this.taskTypeId = this.$route.query?.taskTypeId || ""

    // 获取短信余量
    this.forwardConfig()
  },

  methods: {
    /** 监听选中人员 */
    changeUser (userList) {
      // 短信余量小于选中人员数量
      if (this.messageNum < userList.length) {
        this.taskData.sendMsg = false
      }
    },

    /** 获取短信余量 */
    async forwardConfig () {
      const res = await taskApi.forwardConfig({})
      if (res.returnCode == 200) {
        const data = res.returnData
        // 短信余量
        this.messageNum = data?.sysCustOption?.smsNum || 0

        // 是否默认勾选
        this.taskData.sendMsg = +data?.sysCustConfig?.taskSmsNotice?.[0]?.configValue ? true : false

        this.$nextTick(() => {
          this.$refs.oneStep.getConfig(data?.sysCustConfig)
        })
      }
    },

    // 下一步
    nextStep () {
      const wpTaskMain = this.$refs.oneStep.getFormData()
      console.log(wpTaskMain);
      if (wpTaskMain) {
        this.active = 2
        this.taskData.wpTaskMain = wpTaskMain
      } else {
        console.log(wpTaskMain)
      }
    },

    // 第一步取消回调
    oneCancel () {
      this.$router.go(-1)
    },

    // 第二步取消回调
    twoCancel () {
      // 如果是从列表进来的,则直接返回至列表页
      this.active = 1
    },

    // 提交任务
    async submitTask () {
      const userList = this.$refs.twoStep.getFormData()
      if (userList?.length) {
        this.taskData.userList = userList
        this.taskData.adminFlag = true

        if (this.taskData.wpTaskMain.fileJson?.length) {
          this.taskData.wpTaskMain.fileJson = JSON.stringify(this.taskData.wpTaskMain.fileJson)
        }

        console.log(this.taskData);
        if (this.loading) return
          this.loading = true
        const res = await taskApi.taskMainInsert(this.taskData)
        this.loading = false
        if (res.returnCode == 200 || res.returnCode == 10086) {
          this.messageFlag = true
          if (res.returnCode == 10086) {
            this.messageFlag = false
          }
          this.timeCount = 2000
          this.active = 3
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      } else {
        this.$showMessage({ type: 'warning', msg: '请先选择接收人员' })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>