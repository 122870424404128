var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "basicsForm",
      staticClass: "form",
      attrs: {
        model: _vm.basicsForm,
        rules: _vm.basicsFormRules,
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务分类：", prop: "taskTypeId" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "wp-select task-w726",
              attrs: { placeholder: "请选择分类名称" },
              model: {
                value: _vm.basicsForm.taskTypeId,
                callback: function ($$v) {
                  _vm.$set(_vm.basicsForm, "taskTypeId", $$v)
                },
                expression: "basicsForm.taskTypeId",
              },
            },
            _vm._l(_vm.taskClassify, function (item) {
              return _c("el-option", {
                key: item.taskTypeId,
                attrs: { label: item.taskTypeName, value: item.taskTypeId },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务类型：", prop: "taskStyle" },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.taskTypeList, function (item) {
              return _c(
                "div",
                {
                  key: item.dataValue,
                  staticClass: "type-box",
                  class:
                    _vm.basicsForm.taskStyle == item.dataValue
                      ? "active-type"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.handlerTaskType(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.dataKey) + " ")]
              )
            }),
            0
          ),
        ]
      ),
      _vm.dictKey == "链接类"
        ? _c(
            "el-form-item",
            {
              staticClass: "item-content",
              attrs: { label: "任务链接：", prop: "url" },
            },
            [
              _c("el-input", {
                staticClass: "wp-input task-w726",
                attrs: { maxlength: "2000", placeholder: "请输入任务链接" },
                on: { blur: _vm.changeUrl },
                model: {
                  value: _vm.basicsForm.url,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.basicsForm,
                      "url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "basicsForm.url",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dictKey == "图片视频类"
        ? _c(
            "el-form-item",
            { attrs: { label: "图片视频：", prop: "fileJson" } },
            [
              _c("UploadFile", {
                key: "img",
                attrs: { className: "mt-0", accept: ".jpg, .jpeg, .png, .mp4" },
                on: {
                  fileChange: _vm.fileChange,
                  changeUpload: _vm.changeUpload,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务标题：", prop: "title" },
        },
        [
          _c("el-input", {
            staticClass: "wp-input task-w726",
            attrs: {
              maxlength: "100",
              placeholder:
                _vm.dictKey == "视频号类"
                  ? "请输入任务标题（例：视频标题）"
                  : "请输入任务标题",
            },
            model: {
              value: _vm.basicsForm.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.basicsForm,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicsForm.title",
            },
          }),
        ],
        1
      ),
      _vm.dictKey == "视频号类"
        ? _c(
            "el-form-item",
            {
              staticClass: "item-content",
              attrs: { label: "视频号：", prop: "author" },
            },
            [
              _c("el-input", {
                staticClass: "wp-input task-w726",
                attrs: { maxlength: "50", placeholder: "请输入视频号" },
                model: {
                  value: _vm.basicsForm.author,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.basicsForm,
                      "author",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "basicsForm.author",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "任务要求：", prop: "content" } },
        [
          _c("el-input", {
            staticClass: "task-w726 textarea",
            attrs: {
              type: "textarea",
              maxlength: "1000",
              rows: 6,
              placeholder: "请输入任务要求和口径",
            },
            model: {
              value: _vm.basicsForm.content,
              callback: function ($$v) {
                _vm.$set(
                  _vm.basicsForm,
                  "content",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicsForm.content",
            },
          }),
        ],
        1
      ),
      _c("QuickLanguage", {
        ref: "quick",
        staticStyle: {
          "margin-left": "100px",
          "margin-top": "22px",
          "margin-bottom": "10px",
        },
        attrs: { shortcutList: _vm.shortcutList },
        on: {
          addQuick: _vm.addQuick,
          clickShortcut: _vm.clickShortcut,
          removeQuick: _vm.removeQuick,
        },
      }),
      _vm.dictKey == "视频号类"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "目标图片：",
                prop: "fileJson",
                rules: [{ required: false }],
              },
            },
            [
              _c("UploadFile", {
                key: "video",
                attrs: {
                  className: "mt-0",
                  fileType: ["jpg", "jpeg", "png"],
                  placeholder: "请上传jpg、jpeg、png格式文件",
                },
                on: {
                  fileChange: _vm.fileChange,
                  changeUpload: _vm.changeUpload,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticStyle: { "margin-top": "22px" },
          attrs: { label: "任务备注：" },
        },
        [
          _c("el-input", {
            staticClass: "task-w726 textarea task-notes",
            attrs: {
              type: "textarea",
              maxlength: "500",
              resize: "none",
              placeholder: "请输入任务任务备注",
            },
            model: {
              value: _vm.basicsForm.remark,
              callback: function ($$v) {
                _vm.$set(
                  _vm.basicsForm,
                  "remark",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicsForm.remark",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }