var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.wpyUnitTabs.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.wpyUnitTabs,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "我的上报" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("TaskTimeSelect", {
                          ref: "taskTimeRef",
                          attrs: {
                            activeType: _vm.searchParams.timeType,
                            taskType: _vm.timeType,
                          },
                          on: { timeSelect: _vm.timeSelect },
                        }),
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: {
                            placeholder: "请输入专题名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "title", $$v)
                            },
                            expression: "searchParams.title",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: {
                              clearable: "",
                              placeholder: "请选择审核状态",
                            },
                            model: {
                              value: _vm.searchParams.articleStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "articleStatus", $$v)
                              },
                              expression: "searchParams.articleStatus",
                            },
                          },
                          _vm._l(_vm.$globalData.REVIEWSTATUS, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 上报总量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.totalScore)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "topicTitle",
                                  "min-width": "190",
                                  "show-overflow-tooltip": "",
                                  label: "专题名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "10" },
                              }),
                              _vm.isWpys
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": "120",
                                      align: "left",
                                      prop: "custName",
                                      label: "来源单位",
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  align: "left",
                                  prop: "title",
                                  label: "作品标题",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: scope.row.title,
                                                    placement: "top",
                                                    disabled:
                                                      !_vm.isShowTooltipWidth,
                                                    "popper-class":
                                                      "wp-visibilityPopper",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "textOverOneLine",
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.visibilityChangeWidth(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.title
                                                              ? scope.row.title
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1049486297
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  align: "left",
                                  prop: "url",
                                  label: "作品链接",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.goDetail(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: scope.row.title,
                                                    placement: "top",
                                                    disabled:
                                                      !_vm.isShowTooltipWidth,
                                                    "popper-class":
                                                      "wp-visibilityPopper",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "textOverOneLine",
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.visibilityChangeWidth(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.url
                                                              ? scope.row.url
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3798850649
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "截图/附件",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goDetail(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              !scope.row.fileJson ||
                                              JSON.parse(scope.row.fileJson)
                                                .length == 0
                                                ? _c("p", [_vm._v(" - ")])
                                                : _vm.$utils.isImg(
                                                    scope.row.fileJson
                                                  )
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            "visible-arrow": false,
                                                            trigger: "hover",
                                                            "popper-class":
                                                              "img-popper",
                                                            tabindex: 3,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "scaled-image",
                                                            attrs: {
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                              fit: "contain",
                                                            },
                                                          }),
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "22px",
                                                              height: "22px",
                                                            },
                                                            attrs: {
                                                              slot: "reference",
                                                              src: _vm.$utils.handlePreviewImg(
                                                                scope.row
                                                                  .fileJson
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "22px",
                                                      height: "22px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                    },
                                                    slot: "reference",
                                                  }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1079884099
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "110",
                                  "show-overflow-tooltip": "",
                                  align: "left",
                                  prop: "createName",
                                  label: "人员姓名",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "150",
                                  align: "center",
                                  prop: "createTime",
                                  label: "上报时间",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$moment(scope.row.createTime)
                                                  .format("YYYY-MM-DD HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  676827023
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "80",
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.totalScore == "-999"
                                                  ? "按平台"
                                                  : scope.row.totalScore
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3152593062
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "80",
                                  align: "center",
                                  prop: "articleStatus",
                                  label: "审核状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.articleStatus == 2
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "noreceive-circle",
                                                  }),
                                                  _c(
                                                    "RejectCause",
                                                    {
                                                      key: scope.row
                                                        .topicArticleId,
                                                      attrs: {
                                                        popoverType: "detail",
                                                        rejectObj:
                                                          _vm.rejectObj,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reject wp-point设置驳回信息er",
                                                          on: {
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.setReject(
                                                                  scope.row
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [_vm._v(" 已驳回 ")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass: "pass-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "pass" },
                                                    [_vm._v("已通过")]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3114667217
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex-center" },
                                            [
                                              _c("el-button", {
                                                staticClass: "wp-btn-icon-big",
                                                class:
                                                  scope.row.topicStatus == 2
                                                    ? "no-drop"
                                                    : "",
                                                attrs: {
                                                  type: "text",
                                                  icon: "icon-bianji1",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editWork(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("el-button", {
                                                staticClass: "wp-btn-icon-big",
                                                class:
                                                  scope.row.topicStatus == 2 ||
                                                  scope.row.articleStatus == 2
                                                    ? "no-drop"
                                                    : "",
                                                attrs: {
                                                  type: "text",
                                                  icon: "icon-shanchu",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeWork(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1716813724
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.workVisible
        ? _c(
            "task-dialog",
            {
              attrs: {
                title: "编辑",
                width: "680px",
                height: "100%",
                maxHeight: "530px",
                dialogVisible: _vm.workVisible,
              },
              on: {
                cancel: _vm.cancel,
                determine: _vm.submitEditForm,
                "update:dialogVisible": function ($event) {
                  _vm.workVisible = $event
                },
                "update:dialog-visible": function ($event) {
                  _vm.workVisible = $event
                },
                handleClose: _vm.cancel,
              },
            },
            [
              _c("div", { staticClass: "word-dialog" }, [
                _vm.articleData.articleStatus == 2
                  ? _c("div", { staticClass: "reject-box" }, [
                      _c("div", { staticClass: "fs-16 fw-bold" }, [
                        _vm._v("驳回原因："),
                      ]),
                      _c("div", { staticClass: "reject-content fs-14" }, [
                        _vm._v(" " + _vm._s(_vm.verify.resonRemark) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "wp-form" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "workRef",
                        staticClass: "wp-form",
                        attrs: {
                          model: _vm.taskForm,
                          rules: _vm.taskFormRules,
                          "label-width": "95px",
                        },
                      },
                      [
                        _vm.setting.parmTitleShow
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content",
                                attrs: {
                                  label: "作品标题：",
                                  prop: "title",
                                  rules: [
                                    {
                                      required: _vm.setting.parmTitleMust,
                                      trigger: "blur",
                                      message: "请输入作品标题",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "100",
                                    placeholder: "请输入作品标题",
                                  },
                                  model: {
                                    value: _vm.taskForm.title,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "title",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.title",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmUrlShow
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content isUrl",
                                attrs: {
                                  label: "作品链接：",
                                  prop: "url",
                                  rules: [
                                    {
                                      required:
                                        _vm.setting.parmUrlMust &&
                                        !_vm.urlDisabled,
                                      trigger: "blur",
                                      message: "请输入作品链接",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "2000",
                                    placeholder: "请输入作品链接",
                                    disabled: _vm.urlDisabled,
                                  },
                                  on: { blur: _vm.changeUrl },
                                  model: {
                                    value: _vm.taskForm.url,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "url",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.url",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmContentShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "作品内容：",
                                  prop: "content",
                                  rules: [
                                    {
                                      required: _vm.setting.parmContentMust,
                                      trigger: "blur",
                                      message: "请输入作品内容",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "w480 textarea",
                                  attrs: {
                                    type: "textarea",
                                    maxlength: "1000",
                                    rows: 6,
                                    placeholder: "请输入作品内容",
                                  },
                                  model: {
                                    value: _vm.taskForm.content,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "content",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.content",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.mediaShow && _vm.mediaList.length
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "媒体平台：",
                                  prop: "media",
                                  rules: [
                                    {
                                      required: _vm.setting.mediaMust,
                                      trigger: "change",
                                      message: "请选择媒体平台",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择媒体平台",
                                    },
                                    on: { change: _vm.mediaChange },
                                    model: {
                                      value: _vm.taskForm.media,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "media", $$v)
                                      },
                                      expression: "taskForm.media",
                                    },
                                  },
                                  _vm._l(_vm.mediaList, function (item) {
                                    return _c("el-option", {
                                      key: item.custWebsiteId,
                                      attrs: {
                                        label: item.name,
                                        value: item.custWebsiteId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.mediaShow && !_vm.mediaList.length
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content",
                                attrs: {
                                  label: "媒体平台：",
                                  prop: "media",
                                  rules: [
                                    {
                                      required: _vm.setting.mediaMust,
                                      trigger: "blur",
                                      message: "请输入媒体平台",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "30",
                                    placeholder: "请输入媒体平台",
                                  },
                                  model: {
                                    value: _vm.taskForm.media,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "media",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.media",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.publishShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "发布类型：",
                                  prop: "publishType",
                                  rules: [
                                    {
                                      required: _vm.setting.publishMust,
                                      trigger: "change",
                                      message: "请选择发布类型",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: { placeholder: "请选择发布类型" },
                                    on: { change: _vm.changePublishType },
                                    model: {
                                      value: _vm.taskForm.publishType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskForm,
                                          "publishType",
                                          $$v
                                        )
                                      },
                                      expression: "taskForm.publishType",
                                    },
                                  },
                                  _vm._l(_vm.publishList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.typeName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.authorTypeShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "作者类型：",
                                  prop: "authorType",
                                  rules: [
                                    {
                                      required: _vm.setting.authorTypeMust,
                                      trigger: "change",
                                      message: "请选择作者类型",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: { placeholder: "请选择作者类型" },
                                    model: {
                                      value: _vm.taskForm.authorType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskForm,
                                          "authorType",
                                          $$v
                                        )
                                      },
                                      expression: "taskForm.authorType",
                                    },
                                  },
                                  _vm._l(_vm.authTypeList, function (item) {
                                    return _c("el-option", {
                                      key: item.dictId,
                                      attrs: {
                                        label: item.dataKey,
                                        value: item.dataKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          { staticClass: "w580" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.authorShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作者姓名：",
                                          prop: "author",
                                          rules: [
                                            {
                                              required: _vm.setting.authorMust,
                                              trigger: "change",
                                              message: "请输入作者姓名",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "30",
                                            placeholder: "请输入作者姓名",
                                          },
                                          model: {
                                            value: _vm.taskForm.author,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "author",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.author",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.netNameShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "网名/昵称：",
                                          prop: "netName",
                                          rules: [
                                            {
                                              required: _vm.setting.netNameMust,
                                              trigger: "change",
                                              message: "请输入网名昵称",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "30",
                                            placeholder: "请输入网名昵称",
                                          },
                                          model: {
                                            value: _vm.taskForm.netName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "netName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.netName",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "w580" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.readNumShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "阅读数量：",
                                          prop: "readNum",
                                          rules: [
                                            {
                                              required: _vm.setting.readNumMust,
                                              trigger: "change",
                                              message: "请输入阅读数量",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "wp-input form-w200",
                                            attrs: {
                                              maxlength: "8",
                                              placeholder: "请输入阅读数量",
                                            },
                                            on: { input: _vm.resetReadNum },
                                            model: {
                                              value: _vm.taskForm.readNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.taskForm,
                                                  "readNum",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "taskForm.readNum",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "unit",
                                                attrs: { slot: "suffix" },
                                                slot: "suffix",
                                              },
                                              [_vm._v("万")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.reprintShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "转载数量：",
                                          prop: "reprint",
                                          rules: [
                                            {
                                              required: _vm.setting.reprintMust,
                                              trigger: "change",
                                              message: "请输入转载数量",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "8",
                                            placeholder: "请输入转载数量",
                                          },
                                          on: {
                                            input: _vm.resetReprint,
                                            blur: _vm.reprintBlur,
                                          },
                                          model: {
                                            value: _vm.taskForm.reprint,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "reprint",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.reprint",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.setting.parmPushShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "全网推送：",
                                  rules: [
                                    {
                                      required: _vm.setting.parmPushMust,
                                      trigger: "change",
                                      message: "请选择全网推送",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.changeParmPush },
                                    model: {
                                      value: _vm.taskForm.parmPush,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "parmPush", $$v)
                                      },
                                      expression: "taskForm.parmPush",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmAttachShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  rules: [
                                    {
                                      required: _vm.setting.parmAttachMust,
                                      trigger: "blur",
                                      message: "请提交截图/附件",
                                    },
                                  ],
                                  label: "截图/附件：",
                                  prop: "fileJson",
                                },
                              },
                              [
                                _c("UploadFile", {
                                  key: "image",
                                  attrs: { fileList: _vm.taskForm.fileJson },
                                  on: {
                                    fileChange: _vm.fileChange,
                                    changeUpload: _vm.changeUpload,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "奖励积分：", required: "" } },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v("基础分:"),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#faa548" } },
                                  [_vm._v(_vm._s(_vm.taskForm.baseScore))]
                                ),
                              ]),
                              _vm.rewardScore > 0
                                ? _c("span", [
                                    _vm._v("+ 转载分:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.rewardScore > 0
                                              ? "+" + _vm.rewardScore
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.wpTopicPushScore > 0 &&
                              _vm.taskForm.parmPush == "1"
                                ? _c("span", [
                                    _vm._v("+ 推送分:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [_vm._v(_vm._s(_vm.wpTopicPushScore))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }