var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uploadFile" }, [
    _c(
      "div",
      [
        _vm.fileArr.length
          ? _c(
              "div",
              { staticClass: "image-list" },
              _vm._l(_vm.fileArr, function (item, index) {
                return _c("div", { key: item.id, staticClass: "image-item" }, [
                  item.type == "img"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.baseURL + item.id, title: item.name },
                      })
                    : item.type == "mp4"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.baseURL + item.picId,
                          title: item.name,
                        },
                      })
                    : item.type == "xls"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.$globalData.fileImgs.xls,
                          title: item.name,
                        },
                      })
                    : item.type == "word"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.$globalData.fileImgs.word,
                          title: item.name,
                        },
                      })
                    : item.type == "zip"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.$globalData.fileImgs.zip,
                          title: item.name,
                        },
                      })
                    : item.type == "pdf"
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.$globalData.fileImgs.pdf,
                          title: item.name,
                        },
                      })
                    : _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: require("@/assets/images/file/zip.png"),
                          title: item.name,
                        },
                      }),
                  _c("img", {
                    staticClass: "icon-guanbi",
                    attrs: { src: require("@/assets/images/close.png") },
                    on: {
                      click: function ($event) {
                        return _vm.deleteFile(index)
                      },
                    },
                  }),
                ])
              }),
              0
            )
          : _vm._e(),
        _c(
          "el-upload",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShow,
                expression: "isShow",
              },
            ],
            staticClass: "avatar-uploader",
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "http-request": _vm.httpRequest,
              action: _vm.action,
              accept: _vm.accept,
              "show-file-list": false,
              "before-upload": _vm.handleBeforeUpload,
            },
          },
          [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
        ),
        _c(
          "p",
          { staticClass: "file-placeholder mt-0", class: _vm.className },
          [_vm._v(" " + _vm._s(_vm.remark) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }