<template>
  <div class="changePassword">
    <div class="layout-block wp-moudle-style">
      <div class="layout-title">修改密码</div>
      <div class="layout-content">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform">
          <el-form-item label="">
            <!-- 该账号所在实际单位名称（非个人信息填写的单位）。 -->
            <div class="user-name">{{  sysCust.custName  }}-{{  $globalData.roleNames[roleId.toString()]  }}</div>
          </el-form-item>
          <el-form-item prop="oldPass" label="当前密码：">
            <el-input class="wp-input" type="password" v-model.trim="ruleForm.oldPass" show-password placeholder="请输入旧密码" clearable @keyup.enter.native="determine"></el-input>
          </el-form-item>
          <el-form-item prop="newPass" label="新的密码：">
            <el-input class="wp-input" type="password" v-model.trim="ruleForm.newPass" show-password placeholder="请输入5-20位字母数字组合（区分大小写）" clearable @keyup.enter.native="determine"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="确认密码：">
            <el-input class="wp-input" type="password" v-model.trim="ruleForm.checkPass" show-password placeholder="请输入5-20位字母数字组合（区分大小写）" clearable @keyup.enter.native="determine">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="wp-button" v-preventReClick type="primary" @click="determine">确定
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { verifyPassword, passwordLang } from '@/utils/validate.js';
import { editPwd } from '@/api/login.js';
export default {
  name: 'changePassword',
  components: {},
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error('两次密码不一致，请重新输入!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPass: "",
        newPass: '',
        checkPass: '',
      },
      rules: {
        oldPass: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' }, { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' }, { validator: validatePass2, trigger: 'blur' }
        ],
      }
    };
  },
  props: {},
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['userInfo', 'roleId', 'sysCust'])
  },
  methods: {
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 修改密码
    async postAdd () {
      let query = {
        ...this.ruleForm
      }
      delete query.checkPass
      try {
        let res = await editPwd(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
      } catch (error) {
        console.log(error)
      }
    },

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {

    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/changePassword.scss";
</style>
