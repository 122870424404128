<template>
  <wp-dialog @cancel="cancel" title="上传文件" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="importUser" v-loading.fullscreen.lock="fullscreenLoading">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item  label="" :error="errorMsg">
          <ReviewUpload ref="reviewUpload" @fileChange="fileChange" :fileList="fileArr"> </ReviewUpload>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { finalizeReview,getWpReviewArticle } from '@/api/review.js'
import ReviewUpload from '@/views/components/reviewUpload'
export default {
  components: { ReviewUpload },
  name: 'importUser',
  data () {
    return {
      errorMsg: '',
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
      },
      rules: {
      },
      uploadList: [],
      isUploading: false,// 是否正在上传
      msgobj: {},
      formData: null,
      fullscreenLoading: false,
      isShowAllError: true,
      size:50,
      fileList: [],
      fileArr: [],
      isPosting:false
    };
  },
  props: {
    reviewObj: {
      type:Object,
      default:()=>{}
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.errorMsg = ''
      if (this.fileList.length == 0) {
        return this.errorMsg = '请上传word文件'
      }
      this.postAdd()
    },
    handleClose () {
      this.dialogVisible = false
      this.fileList = []
      this.$emit('close')
      this.$refs.reviewUpload.resetFile()
    },
    show () {
      this.dialogVisible = true
    }, initDate () {
      this.getWpReviewArticle()
    },
    async postAdd () {
      if(this.isPosting) return
      this.isPosting = true
      let query = {
        reviewArticleId: this.reviewObj.reviewTitleId,
        fileJsonEnd: JSON.stringify(this.fileList)
      }
      try {
        this.fullscreenLoading = true
        let api = finalizeReview
        let res = await api(query)
        this.fullscreenLoading = false
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        this.fullscreenLoading = false
      }
    },
    fileChange (fileList) {
      console.log('fileList', fileList)
      this.fileList = JSON.parse(JSON.stringify(fileList))
    },
    async getWpReviewArticle(){
      try {
        let api = getWpReviewArticle
        let res = await api({reviewTitleId: this.reviewObj.reviewTitleId})
        this.fullscreenLoading = false
        if (Number(res.returnCode) !== 200){
          this.setInitData(this.reviewObj)
          return 
        }
        this.setInitData(res.returnData)
      } catch (error) {
        this.fullscreenLoading = false
      }
    },
    setInitData(reviewObj){
       let fileJsonEnd = JSON.parse(reviewObj.fileJsonEnd)
       this.fileArr = fileJsonEnd?fileJsonEnd:[]
       this.fileList =fileJsonEnd?fileJsonEnd:[]
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.importUser {
  padding:30px 18px 0px 18px;

  .download {
    text-align: right;

    a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }

  .ruleform {
    margin-top: 8px;
    padding: 20px 20px 0px 20px;
  }

  .input-row {
    display: flex;
    align-items: center;

    .input-item {
      display: flex;
      align-items: center;
      width: 220px;
      height: 36px; /*no*/
      border-radius: 4px;
      border: 1px solid #dddddd;
      padding-left: 8px;

      // border: 1px solid #F35454;
      .file-item {
        display: flex;
        align-items: center;
        width: 122px;
        height: 24px; /*no*/
        background: #f5f5f5;
        border-radius: 4px;
        padding-left: 4px;

        p {
          width: 84px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-right: 10px;
        }

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }

    .wp-button {
      margin-left: 12px;
    }
  }

  .tips {
    display: flex;
    align-items: center;

    > P {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-right: 14px;
    }

    .redColor {
      color: #f35454;
    }

    > a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }
}
</style>