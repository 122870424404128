var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.classifyAddFlag ? "添加分类" : "修改名称",
        width: "420px",
        smallHeight: "170px",
        size: "small",
        dialogVisible: _vm.dialogVisible,
      },
      on: {
        cancel: _vm.handleClose,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "addGroup" },
        [
          _c(
            "el-form",
            {
              ref: "classifyRef",
              attrs: {
                model: _vm.classifyForm,
                rules: _vm.classifyRule,
                "label-width": "0px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "classifyName", label: "" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "10",
                      placeholder: _vm.placeholder,
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.classifyForm.classifyName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.classifyForm,
                          "classifyName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "classifyForm.classifyName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }