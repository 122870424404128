var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-CB" }, [
      _c("div", { staticClass: "flex-Acenter fs-14" }, [
        _c("div", [
          _vm._v(" 任务数量："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.taskNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 作品数量："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.articleNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 奖励积分："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.score || 0)),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: { size: "medium", icon: "icon-daochu" },
              on: { click: _vm.exportFile },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "wp-table mt-20" },
      [
        _vm.tableData.length !== 0
          ? _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  stripe: "",
                  sortable: "custom",
                  "default-sort": _vm.sortObj,
                },
                on: { "sort-change": _vm.sortChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    label: "序号",
                    width: "70",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "taskTypeName",
                    label: "分类名称",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "taskNum",
                    label: "任务数量",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "completeNum",
                    label: "完成人数",
                  },
                }),
                _vm.searchParams.timeType == 2
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "completionRate",
                        label: "完成率",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.completionRate) + "%"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3143357017
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    sortable: "",
                    prop: "workNum",
                    label: "作品数量",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    sortable: "",
                    prop: "totalScore",
                    label: "奖励积分",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.tableData.length == 0 && !_vm.loading
          ? _c("div", { staticClass: "wp-noData" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/noData.png"), alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }