var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-view" }, [
    _c("div", { staticClass: "header-left flex-Acenter" }, [
      _vm.isMls
        ? _c("img", {
            attrs: { src: require("@/assets/images/mls/mlslogo.png"), alt: "" },
          })
        : !_vm.isMls
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/header/logo@2x.png"),
              alt: "",
            },
          })
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "header-right" },
      [
        _vm.xjxqFlag
          ? _c(
              "el-popover",
              {
                attrs: {
                  "popper-class": "app-popover",
                  placement: "bottom",
                  trigger: "hover",
                  "visible-arrow": false,
                },
              },
              [
                _c("div", { staticClass: "popover-content" }, [
                  _c("img", {
                    staticClass: "code",
                    attrs: { src: _vm.AndroidUrl },
                  }),
                  _c("p", { staticStyle: { "margin-top": "4px" } }, [
                    _vm._v("安卓端"),
                  ]),
                ]),
                _c("img", {
                  staticClass: "app-img",
                  attrs: {
                    slot: "reference",
                    src: require("@/assets/images/header/app.png"),
                    alt: "",
                  },
                  slot: "reference",
                }),
              ]
            )
          : _vm._e(),
        (_vm.roleId == 3 || _vm.roleId == 4) && !!_vm.token
          ? _c(
              "div",
              { staticClass: "news-info" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      "visible-arrow": false,
                      trigger: "manual",
                      "popper-class": "xiaoxi-popper",
                    },
                    model: {
                      value: _vm.visible,
                      callback: function ($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible",
                    },
                  },
                  [
                    _c("div", { staticClass: "xiaoxi-content" }, [
                      _vm._v(" 您有一条新的消息 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/header/guanBi.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.visible = !_vm.visible
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "news",
                        class: { "news-before": _vm.xiaoxiCount > 0 },
                        attrs: { slot: "reference" },
                        on: { click: _vm.toMessageCenter },
                        slot: "reference",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/header/xiaoXi.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.roleId == 2 && !!_vm.token
          ? _c("div", { staticClass: "news-info" }, [
              _c(
                "div",
                {
                  staticClass: "news",
                  attrs: { slot: "reference" },
                  on: { click: _vm.toMessageCenter },
                  slot: "reference",
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/header/xiaoXi.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        (_vm.roleId == 2 || _vm.roleId == 3) && !!_vm.token
          ? _c(
              "div",
              { staticClass: "download-info" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-end",
                      trigger: "click",
                      "visible-arrow": false,
                      "popper-class": "xiaoxi-popper",
                    },
                    model: {
                      value: _vm.visible2,
                      callback: function ($$v) {
                        _vm.visible2 = $$v
                      },
                      expression: "visible2",
                    },
                  },
                  [
                    _c("download-list", {
                      ref: "downloadList",
                      attrs: { isShow: _vm.visible2 },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "download",
                        class: { "download-before": _vm.isShowRed },
                        attrs: { slot: "reference" },
                        on: { click: _vm.changeIsShowRed },
                        slot: "reference",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/header/xiaZai.png"),
                            alt: "",
                            id: "xiaZai",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      "visible-arrow": false,
                      trigger: "manual",
                      "popper-class": "xiaoxi-popper",
                    },
                    model: {
                      value: _vm.visible3,
                      callback: function ($$v) {
                        _vm.visible3 = $$v
                      },
                      expression: "visible3",
                    },
                  },
                  [
                    _c("div", { staticClass: "xiaoxi-content" }, [
                      _vm._v(" 您有新的下载任务 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/header/guanBi.png"),
                          alt: "",
                        },
                        on: { click: _vm.changeIsShowTip },
                      }),
                    ]),
                    _c("div", {
                      staticClass: "news",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        !!_vm.token
          ? _c("div", { staticClass: "admin-info" }, [
              _vm.roleId == 1 || _vm.roleId == 2
                ? _c("div", { staticClass: "avtar-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/header/adminAvtar@2x.png"),
                        alt: "",
                      },
                    }),
                  ])
                : _c("div", { staticClass: "avtar-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/header/wpAvtar.png"),
                        alt: "",
                      },
                    }),
                  ]),
              _c("div", { staticClass: "name-info" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.sysCust.aliasName
                        ? _vm.sysCust.aliasName
                        : _vm.sysCust.custName
                    ) +
                    "-" +
                    _vm._s(_vm.userInfo.userName) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "switch" }, [
                _vm.userList.length > 1 && _vm.roleId != 1
                  ? _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.toSwitchLogin },
                      },
                      [_vm._v("切换")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        !!_vm.token
          ? _c(
              "div",
              { staticClass: "login-out" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      trigger: "hover",
                      "visible-arrow": false,
                      "popper-class": "xiaoxi-popper",
                    },
                  },
                  [
                    _c("div", { staticClass: "xiaoxi-content" }, [
                      _c(
                        "a",
                        {
                          staticClass: "out-btn",
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.loginOut },
                        },
                        [
                          _c("i", { staticClass: "icon-tuichu" }),
                          _vm._v(" 退出"),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "out-img",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/header/tuichu@2x.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "login-btn-box" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { type: "text" },
                    on: { click: _vm.toLogin },
                  },
                  [_vm._v("登录")]
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }