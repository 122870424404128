import { post, get, getImg } from '@/utils/requestTwo'

// 任务-获取上次勾选人员
export function historyReceiver (query) {
	return post(`/drill/task/historyReceiver`, query, false)
}

// 任务-新增
export function saveTask (query) {
	return post(`/drill/task/saveTask`, query, false)
}

// 任务-编辑
export function editTask (query) {
	return post(`/drill/task/editTask`, query, false)
}

// 任务-列表
export function pageTask (query) {
	return post(`/drill/task/pageTask`, query, false)
}
// 任务-删除(加密)
export function delTask (query) {
	return post(`/drill/task/delTask`, query)
}

// 任务-详情(加密)
export function detailTask (query) {
	return post(`/drill/task/detailTask`, query)
}

// 任务-接收人员列表
export function pageReceive (query) {
	return post(`/drill/task/pageReceive`, query, false)
}
//任务-修改接收人员微博昵称
export function editUserMediaNickName (query) {
	return post(`/media/account/editUserMediaNickName`, query, false)
}
// 任务-删除接收人员(加密)
export function removeReceive (query) {
	return post(`/drill/task/removeReceive`, query)
}
// 任务-追加人员
export function addReceiver (query) {
	return post(`/drill/task/addReceiver`, query, false)
}

// 任务-接收人员-导出
export function receiverExport (query) {
	return post(`/drill/task/receiverExport`, query, false)
}

//  演练任务详情接口

// 热评列表
export function articleList (query) {
	return post(`/drill/article/list`, query)
}


//热评排行
export function getHotCommentRankingList (query) {
	return post(`/drill/article/getHotCommentRankingList`, query)
}
//热评排行new
// export function getHotCommentRankingListByOrderNum (query) {
// 	return post(`/drill/article/getHotCommentRankingListByOrderNum`, query)
// }
//实时评论
export function comment (query) {
	return post(`/drill/article/comment`, query)
}

//比分统计
export function taskDetail (query) {
	return post(`/drill/article/taskDetail`, query)
}

//热评列表的总记录数
export function articleCount (query) {
	return post(`/drill/article/count`, query)
}

//任务-回放-APP、web(加密)
export function taskHistory (query) {
	return post(`/drill/task/taskHistory`, query)
}








