<template>
  <div class="wp-w100">
    <el-scrollbar style="height: 100%">
      <div class="main">
        <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
          <el-tab-pane label="人员汇总" name="people">
            <!-- 人员汇总 -->
            <PeopleSearch :groupList="groupList" :classifyList="classifyList" @changeSearch="changeSearch" v-if="activeTab == 'people'" ref="people"></PeopleSearch>
          </el-tab-pane>

          <el-tab-pane label="分组汇总" name="group">
            <!-- 分组汇总 -->
            <GroupSearch :groupList="groupList" :classifyList="classifyList" @changeSearch="changeSearch" v-if="activeTab == 'group'" ref="group"></GroupSearch>
          </el-tab-pane>

          <el-tab-pane label="专题汇总" name="special">
            <!-- 专题汇总 -->
            <SpecialSearch :groupList="groupList" :classifyList="classifyList" @changeSearch="changeSearch" v-if="activeTab == 'special'" ref="special"></SpecialSearch>
          </el-tab-pane>
        </TabsCard>

        <div class="wp-card mt-16">
          <div v-loading="loadObj[tableApi]" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <!-- 人员汇总 -->
            <PeopleTable v-show="activeTab == 'people'" ref="peopleRef" @exportFile="exportFile" @sortChange="sortChange" :tableData="tableObj['peopleStatistics']" :statisticsData="statisticsDataObj['peopleStatistics']" :loading="loadObj['peopleStatistics']"></PeopleTable>
            <!-- 分组汇总 -->
            <GroupTable v-show="activeTab == 'group'" ref="groupRef" @exportFile="exportFile" @sortChange="sortChange" :tableData="tableObj['groupStatisticsList']" :statisticsData="statisticsDataObj['groupStatisticsList']" :loading="loadObj['groupStatisticsList']"></GroupTable>
            <!-- 专题汇总 -->
            <SpecialTable v-show="activeTab == 'special'" ref="specialRef" @exportFile="exportFile" @sortChange="sortChange" :tableData="tableObj['topicStatisticsList']" :statisticsData="statisticsDataObj['topicStatisticsList']" :loading="loadObj['topicStatisticsList']"></SpecialTable>
          </div>

          <wp-pagination class="mt-21" :total="totalObj[tableApi]" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import reportedApi from '@/api/reported'
import reportedStaApi from '@/api/reportedStatistics.js'
import TabsCard from '@/views/components/tabsCard'

import PeopleSearch from './subSearch/peopleSearch'
import GroupSearch from './subSearch/groupSearch'
import SpecialSearch from './subSearch/specialSearch'

import PeopleTable from './subTable/peopleTable'
import GroupTable from './subTable/groupTable'
import SpecialTable from './subTable/specialTable'

export default {
  name: 'panel',
  components: { TabsCard, PeopleSearch, GroupSearch, SpecialSearch, PeopleTable, GroupTable, SpecialTable, },
  data () {
    return {
      tabs: [
        { label: '人员汇总', name: "people" },
        { label: '分组汇总', name: "group" },
        { label: '专题汇总', name: "special" },
      ],
      activeTab: "people", // 所选tab
      searchParams: {
        page: 1, // 页码
        limit: 30, // 页大小
      },
      statisticsData: {}, // 统计数据
      total: 100,
      tableData: [], // 任务列表
      groupList: [], // 分组列表
      classifyList: [], // 分类列表
      tableApi: 'peopleStatistics', // 根据点击的tab 切换请求地址
      loading: false,
      tableObj: {
        'peopleStatistics': [],
        'groupStatisticsList': [],
        'topicStatisticsList': []
      },
      loadObj: {
        'peopleStatistics': false,
        'groupStatisticsList': false,
        'topicStatisticsList': false
      },
      totalObj: {
        'peopleStatistics': 0,
        'groupStatisticsList': 0,
        'topicStatisticsList': 0
      },
      statisticsDataObj: {
        'peopleStatistics': {},
        'groupStatisticsList': {},
        'topicStatisticsList': {}
      }
    };
  },

  watch: {
    'searchParams.timeSection': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.searchParams.startTime = newVal[0]
          this.searchParams.endTime = newVal[1]
        } else {
          this.searchParams.timeSection = ""
          this.searchParams.startTime = ""
          this.searchParams.endTime = ""
        }

      }
    }
  },

  mounted () {
    // 获取分组列表
    this.getGroupTree()
    // 获取专题分类列表
    this.getClassifyList()
  },

  methods: {
    // 获取专题分类列表
    async getClassifyList () {
      const res = await reportedApi.topicTypeList({ topicTypeName: "" })
      if (res.returnCode == 200) {
        this.classifyList = res.returnData
      }
    },

    // 导出
    async exportFile (obj) {
      try {
        if (this.isExporting) return
        this.isExporting = true

        const res = await reportedStaApi[obj.api]({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(obj.event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },

    // 获取分组树
    async getGroupTree () {
      const res = await reportedStaApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },

    // 搜索回调
    changeSearch (params) {
      this.searchParams = { ...this.searchParams, ...params }
      this.getTableData()
    },

    // 列表排序回调
    sortChange (sortObj) {
      this.searchParams = { ...this.searchParams, ...sortObj }
      this.getTableData()
    },

    // tabs点击回调
    handlerTabs (tab) {
      this.isExporting = false
      this.activeTab = tab
      this.searchParams = {}

      if (tab == 'people') {
        this.tableApi = 'peopleStatistics'
      } else if (tab == 'group') {
        this.tableApi = 'groupStatisticsList'
      } else {
        this.tableApi = 'topicStatisticsList'
      }

      this.$nextTick(async () => {
        // 清空筛选条件
        this.$refs[tab + 'Ref'].clearSort()
        // 清空上一次的搜索条件
        await this.$refs[this.activeTab].clearParams()
        // 清空数据
        this.searchParams = await { ...this.$refs[tab].getParams() }

        // 置空表格
        this.tableData = []
        // 设置默认值
        this.searchParams.page = 1
        this.searchParams.limit = 30
        await this.getTableData()
      })
    },

    // 获取统计数据
    async getTableData (type) {
      // if (this.loading) return
      // this.loading = true
      let tableApi = this.tableApi
      if (this.loadObj[tableApi]) return
      this.loadObj[tableApi] = true
      try {
        const res = await reportedStaApi[tableApi](this.searchParams)
        // this.loading = false
        this.loadObj[tableApi] = false
        if (res.returnCode == 200) {
          const { summaryVo, page } = res?.returnData
          // this.tableData = page?.data || []
          // this.statisticsData = summaryVo || {}
          // this.total = page?.totalCount || 0
          this.tableObj[tableApi] = page?.data || []
          this.statisticsDataObj[tableApi] = summaryVo || {}
          this.totalObj[tableApi] = page?.totalCount || 0
        }
      } catch (error) {
        // this.loading = false
        this.loadObj[tableApi] = false
      }
    },

    // 页大小回调
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTableData()
    },

    // 页码回调
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    },
  },
};
</script>
<style scoped lang="scss">
@import "index.scss";
</style>
