<template>
  <div class="wpMessageCenter">
    <div class="title-header">
      <div class="title-header-left">
        <unit-tab
          ref="unitTab"
          :tabsList="tabsList"
          :activeId="activeName"
          @handleClick="handleClick"
          v-show="tabsList.length > 1"
        >
        </unit-tab>
      </div>
      <div class="title-header-right">
        <el-input
          placeholder="请输入搜索的内容"
          @change="searchData"
          v-model="title"
          class="search-w190 wp-input"
          style="margin-right: 14px"
          @keyup.enter.native="searchData"
        >
          <i
            slot="prefix"
            class="wp-input-icon icon-sousuo"
            style="font-size: 24px"
          ></i>
        </el-input>
      </div>
    </div>
    <div class="layout-content">
      <el-scrollbar style="height: 100%">
        <div class="home-admin-content">
          <div
            class="wp-table"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <ul class="message-list">
              <li
                class="wp-moudle-style wp-pointer"
                v-for="(item, index) in tableData"
                :key="item.msgId"
                @click="showDetial(item)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.title"
                  placement="top"
                  :disabled="!isShowTooltipWidth"
                  popper-class="wp-visibilityPopper"
                  :tabindex="index + 1000"
                >
                  <div
                    class="title textOverOneLine"
                    @mouseenter="visibilityChangeWidth($event)"
                  >
                    {{ item.title }}
                  </div>
                </el-tooltip>
                <!-- <el-tooltip class="item" effect="dark" :content="item.content" placement="top" :disabled="!isShowTooltip" popper-class="wp-visibilityPopper" :tabindex="index">
                  <div class="introduction textOverTwoLine" @mouseenter="visibilityChange($event)">
                    {{  item.content  }}
                  </div>
                </el-tooltip> -->
                <div
                  class="introduction wp-pointer-color textOverTwoLine"
                  @mouseenter="visibilityChange($event)"
                >
                  {{ item.content }}
                </div>
                <div class="action-row">
                  <div class="action-row-left">
                    <p>发布人：{{ item.custName }}-{{ item.createName }}</p>
                    <p>
                      时间：{{
                        $moment(item.createTime).format('YYYY-MM-DD HH:mm')
                      }}
                    </p>
                  </div>
                  <div class="action-row-right"></div>
                </div>
              </li>
            </ul>
            <div class="wp-noData" v-if="tableData.length == 0">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
          <div class="page-row">
            <wp-pagination
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </wp-pagination>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <MessageDetail ref="messageDetail" :eidtObj="msgObj"></MessageDetail>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import UnitTab from '@/components/unitTab/unitTab.vue';
import { pageList, custCount } from '@/api/messageCenter.js';
import { mapGetters, mapState, mapMutations } from 'vuex';
import MessageDetail from './components/messageDetail.vue';
export default {
  name: 'wpMessageCenter',
  components: { UnitTab, MessageDetail },
  data () {
    return {
      title: '',
      total: 0,
      tableData: [],
      loading: false,
      query: {
        title: '',
        page: 1,
        limit: 30
      },
      msgObj: {},
      //tabsList: [{ id: '1', label: '第1个单位', news: 1 }, { id: '2', label: '第2个单位', news: 1 }, { id: '3', label: '第3个单位', news: 0 }],
      activeName: '',
      tabsList: [],
      receiverId: '',
      onceCount: 0,
      isShowTooltip: true,
      isShowTooltipWidth: true,
    };
  },
  props: {},
  created () {

    this.getCustCount()
  },
  mounted () {


  },
  computed: {
    ...mapGetters(['userList']),
    // tabsList () {
    // 	let tabs = this.userList.filter(it => it.roleId == 3 || it.roleId == 4).map(item => { return { id: item.sysCust.custId.toString(), label: item.sysCust.custName } })
    // 	return tabs
    // }
  },
  methods: {
    // ...mapMutations(['SET_REMINDNUM']),
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      this.getPageList()
    },
    handleClick (tab) {
      console.log(tab);
      this.receiverId = tab
      // 获取当前选中的单位
      this.refresh()

    },
    // 获取列表
    async getPageList () {
      let query = {
        ...this.query,
        receiverId: this.receiverId
      }
      console.log(query)
      if (this.loading) return
      try {
        this.loading = true
        let res = await pageList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        // res.returnData.data.forEach(element => {
        // 	element.endTime = this.$moment(element.endTime).format("YYYY-MM-DD")
        // 	element.createTime = this.$moment(element.createTime).format("YYYY-MM-DD")
        // });
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
        this.getCustCount()
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 获取各单位的 新消息
    async getCustCount () {
      try {
        let res = await custCount({})
        if (Number(res.returnCode) !== 200) return
        let tabs = this.userList.filter(it => it.roleId == 3 || it.roleId == 4).map(item => { return { id: item.userId.toString(), custId: item.sysCust.custId, label: item.sysCust.aliasName } })
        tabs.forEach((item, index) => {
          item.news = 0
          res.returnData.forEach((element) => {
            if (item.custId == element.custId && element.count > 0) {
              item.news = 1
            }
          });
          // if (index == 0) {
          // 	item.news = 0
          // }
        })
        this.tabsList = tabs
        console.log(this.tabsList)
        this.$nextTick(() => {
          // 首次进入
          if (this.onceCount == 0) {
            this.activeName = this.tabsList[0].id
            this.receiverId = this.tabsList[0].id
            this.onceCount = 1
            // 获取当前选中的单位
            this.refresh()
          }

        })
      } catch (error) {
        console.log(error)
      }
    },
    // 搜索
    searchData () {
      this.query.title = this.title
      this.refresh()
    },
    // 刷新列表
    refresh () {
      this.query.page = 1
      this.getPageList()
    },
    visibilityChange (event) {
      this.isShowTooltip = this.$utils.visibilityChange(event, 'height')
    },
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    showDetial (item) {
      this.msgObj = item
      this.$refs.messageDetail.show()
    }

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import './css/wpMessageCenter.scss';
</style>
