var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-CB" }, [
      _c("div", { staticClass: "flex-Acenter fs-14" }, [
        _c("div", [
          _vm._v(" 上报数量："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.articleNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 奖励积分："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.score || 0)),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: { size: "medium", icon: "icon-daochu" },
              on: { click: _vm.exportFile },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "wp-table mt-20" },
      [
        _vm.tableData.length !== 0
          ? _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  stripe: "",
                  sortable: "custom",
                  "default-sort": _vm.sortObj,
                },
                on: { "sort-change": _vm.sortChange },
              },
              [
                _c("el-table-column", { attrs: { width: "20" } }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    label: "序号",
                    "max-width": "70",
                    "min-width": "30",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "100", "min-width": "40" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                    prop: "userName",
                    label: "人员姓名",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "120", "min-width": "50" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    align: "left",
                    prop: "groupName",
                    label: "所在分组",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.parentName
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.parentName +
                                          "-" +
                                          scope.row.groupName
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(_vm._s(scope.row.groupName)),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1177862801
                  ),
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "70", "min-width": "50" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "150",
                    align: "left",
                    prop: "custName",
                    label: "所在单位",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "max-width": "65", "min-width": "20" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "90",
                    align: "center",
                    sortable: "",
                    prop: "workNum",
                    label: "上报数量",
                  },
                }),
                _c("el-table-column", { attrs: { width: "70" } }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    sortable: "",
                    prop: "score",
                    label: "奖励积分",
                    "min-width": "100",
                  },
                }),
                _c("el-table-column", { attrs: { width: "20" } }),
              ],
              1
            )
          : _vm._e(),
        _vm.tableData.length == 0
          ? _c("div", { staticClass: "wp-noData" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/noData.png"), alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }