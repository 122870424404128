var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wp-dialog",
    {
      attrs: {
        title: _vm.isEidt ? "编辑" : "设为常用",
        width: "420px",
        smallHeight: "170px",
        size: "small",
        dialogVisible: _vm.dialogVisible,
      },
      on: {
        cancel: _vm.cancel,
        determine: _vm.determine,
        handleClose: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "addGroup" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleform",
              attrs: { model: _vm.ruleForm, "label-width": "100px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "commonGroupIds",
                    label: "设为常用：",
                    rules: {
                      required: true,
                      message: _vm.placeholder,
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "wp-select",
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择所在分组",
                        multiple: "",
                        "collapse-tags": "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.determine.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.commonGroupIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "commonGroupIds", $$v)
                        },
                        expression: "ruleForm.commonGroupIds",
                      },
                    },
                    _vm._l(_vm.commonGroupList, function (item) {
                      return _c("el-option", {
                        key: item.commonId,
                        attrs: { label: item.commonName, value: item.commonId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }