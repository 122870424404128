<template>
  <div class="main">
    <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
      <el-tab-pane label="组员统计" name="people"> </el-tab-pane>

      <el-tab-pane label="作品明细" name="workDetails">
        <SearchCard
          @handlerSearch="handlerSearch"
          title=""
          class="title-wp-card"
        >
          <template v-slot:searchContent>
            <TaskTimeSelect
              :activeType="searchParams.timeType"
              :taskType="timeType"
              ref="taskTime"
              @timeSelect="timeSelect"
            ></TaskTimeSelect>
            <wpLoadSelect
              class="search-w392 wp-select align-top"
              v-model="searchParams.topicId"
              :data="topicList"
              :page="topicObj.page"
              dictLabel="title"
              dictValue="topicId"
              dictTime="createTime"
              :hasMore="topicObj.more"
              :request="getData"
              @clearTitle="() => (topicObj.title = '')"
              placeholder="请输入专题名称"
            ></wpLoadSelect>
            <el-select
              class="search-w190 wp-select wp-multiple"
              v-model="searchParams.topicTypeIds"
              multiple
              collapse-tags
              filterable
              placeholder="请选择专题分类"
            >
              <el-option
                v-for="item in classifyList"
                :key="item.topicTypeId"
                :label="item.topicTypeName"
                :value="item.topicTypeId"
              >
              </el-option>
            </el-select>

            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.userName"
              clearable
              placeholder="请输入人员姓名"
            >
            </el-input>

            <el-select
              class="search-w190 wp-select align-top"
              v-model="searchParams.articleStatus"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>
      </el-tab-pane>
    </TabsCard>

    <div class="wp-card mt-16">
      <div class="flex-CB">
        <div class="fs-14 flex">
          <div>
            投稿总量：<span class="wp-blue">{{ countData.REPORTNUM }}</span>
          </div>
          <div class="ml-30">
            奖励积分：<span class="wp-blue">{{ countData.SCORE }}</span>
          </div>
        </div>

        <div class="export-box">
          <el-popover
            popper-class="export-popover"
            ref="export-popover"
            placement="bottom"
            trigger="click"
          >
            <div class="export-list">
              <div class="export-item wp-pointer" @click="collectExport">
                汇总导出
              </div>
              <div class="export-item wp-pointer" @click="exportFile">
                作品导出
              </div>
            </div>
            <el-button
              style="width: 90px"
              slot="reference"
              class="wp-button"
              size="medium"
            >
              导出<i class="icon-zhankai el-icon--right"></i
            ></el-button>
          </el-popover>
        </div>
      </div>

      <div
        class="wp-table mt-20"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="正在加载"
      >
        <el-table :data="tableData" stripe v-if="tableData.length !== 0">
          <el-table-column align="center" type="index" label="序号" width="90">
          </el-table-column>
          <el-table-column min-width="40"> </el-table-column>
          <el-table-column
            align="center"
            prop="custName"
            width="80"
            label="截图/附件"
          >
            <template slot-scope="scope">
              <div @click.stop="goDetail(scope.row)" style="cursor: pointer">
                <p
                  v-if="$utils.isImg(scope.row.fileJson)"
                  style="cursor: pointer"
                >
                  <el-popover
                    placement="right-start"
                    :visible-arrow="false"
                    trigger="hover"
                    popper-class="img-popper"
                    :tabindex="3"
                  >
                    <el-image
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      class="scaled-image"
                      fit="contain"
                    ></el-image>
                    <img
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </el-popover>
                </p>

                <img
                  v-else
                  style="width: 22px; height: 22px"
                  slot="reference"
                  :src="$utils.handlePreviewImg(scope.row.fileJson)"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="80"> </el-table-column>
          <el-table-column
            min-width="120"
            show-overflow-tooltip
            align="left"
            prop="createName"
            label="人员姓名"
          >
          </el-table-column>
          <el-table-column min-width="10"> </el-table-column>
          <el-table-column
            align="center"
            width="150"
            prop="createTime"
            label="上报时间"
          >
            <template slot-scope="scope">
              {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column min-width="10"> </el-table-column>
          <el-table-column
            max-width="130"
            min-width="100"
            align="center"
            prop="totalScore"
            label="奖励积分"
          >
          </el-table-column>
          <el-table-column align="center" prop="articleStatus" label="审核状态">
            <template slot-scope="scope">
              <div>
                <div class="flex-center" v-if="scope.row.articleStatus == 1">
                  <div class="receive-circle"></div>
                  <div class="receive-color">已通过</div>
                </div>
                <div class="flex-center" v-else>
                  <div class="noreceive-circle"></div>
                  <div class="noreceive-color">已驳回</div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData.length == 0 && !loading">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>

      <wp-pagination
        class="mt-21"
        :total="total"
        :currentPage="searchParams.page"
        :pageSize="searchParams.limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></wp-pagination>
    </div>
  </div>
</template>

<script>
import reportedApi from '@/api/reported'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import TabsCard from '@/views/components/tabsCard'
import { mapGetters } from 'vuex';
export default {
  name: 'taskReview',
  components: { SearchCard, TaskTimeSelect, TabsCard },
  data () {
    return {
      topicObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 专题名称
        limit: 10, // 页大小
        sort: 2,
      },
      topicList: [], // 专题下拉框列表
      // 查询条件
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        topicId: "", // 所选任务ID
        userName: "", // 人员姓名
        timeType: 2, // 任务时间类型
        articleStatus: "",
        page: 1,
        limit: 30,
        topicTypeIds: [],
        orderType: "desc"
      },
      classifyList: [],
      timeType: [
        {
          name: "专题创建时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        }
      ],
      tableData: [],
      loading: false,
      total: 0,
      countData: {}, // 提交总数,奖励积分
      editRow: {}, // 表格行内信息
      multipleSelection: [],
      exportType: "", // 所选导出类型
      rejectObj: {}, // 驳回信息
      qeuryData: {}, // 深拷贝后的搜素条件
      summariesData: {}, // 表格统计数据
      roleDisabled: false,
      activeTab: 'workDetails',
      tabs: [
        { label: '组员统计', name: "people" },
        { label: '作品明细', name: "workDetails" }
      ],
      queryData: {}
    };
  },
  computed: {
    ...mapGetters(['roleId', 'sysUserInfo']),
  },
  mounted () {
    // 获取审核列表
    this.getReviewList()
    // 获取专题分类
    this.getClassifyList()
  },
  methods: {
    // 详情
    goDetail (item) {
      console.log('item', item)
      this.$router.push({
        path: '/leaderTopicWorkDetail',
        query: {
          articleId: item.topicArticleId
        }
      })
    },
    /** tab切换 */
    handlerTabs (name) {
      if (name != 'workDetails') {
        this.$router.push('/crewReportedStatistics')
      }
    },
    async getClassifyList () {
      const res = await reportedApi.topicTypeList({ topicTypeName: "" })
      if (res.returnCode == 200) {
        this.classifyList = res.returnData
      }
    },
    // 获取审核列表
    async getReviewList () {
      if (this.loading) return
      this.loading = true
      try {
        this.queryData = JSON.parse(JSON.stringify(this.searchParams))
        const res = await reportedApi.peopleReportedList(this.queryData)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData

          this.tableData = data.data
          this.countData = res.total
          this.total = data.totalCount
        }
      } catch (error) {
        this.loading = false
      }
    },
    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.topicList = []
        this.topicObj = { ...this.topicObj, ...data }
      }

      const res = await reportedApi.topicSelectList({ ...this.topicObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 专题列表
        this.topicList = [...this.topicList, ...ret.data].reduce((prev, curr) => {
          obj[curr.topicId] ? '' : obj[curr.topicId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.topicObj.page < ret.totalPageNum) {
          this.topicObj.page++
          this.topicObj.more = true
        } else {
          this.topicObj.more = false
        }
      }
    },
    /** 汇总导出 */
    async collectExport (event) {
      const query = { ...this.queryData }
      query.orderType = 'asc'

      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await reportedApi.topicInfoArticleExport(query)
        this.isExporting2 = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }
    },

    /** 导出 */
    async exportFile (event) {
      this.queryData.exportParam = 2

      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await reportedApi.topicArticleExport(this.queryData)
        this.isExporting = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    // 时间类型选择回调
    timeSelect (data) {
      console.log(data)
      this.searchParams = { ...this.searchParams, ...data }
    },
    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 表格勾选回调
    handleSelectionChange (arr) {
      this.multipleSelection = arr
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getReviewList()
    },
  },
};
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}

.export-box {
  margin-left: 12px;
}

.title-wp-card {
  padding: 0;
}
</style>
<!-- <style scoped lang="scss">@import "./index.scss";</style> -->
