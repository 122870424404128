<template>
  <div class="siteConfigList">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <SearchCard title="全局配置" @handlerSearch="handlerSearch" class="title-card">
          <template v-slot:searchContent>
            <el-select filterable @change="changeTaskStation" class="search-w190 wp-select" v-model="searchQuery.taskStation" clearable placeholder="请选择任务站点">
              <el-option v-for="item in taskStationList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
              </el-option>
            </el-select>
            <el-select filterable class="search-w190 wp-select" v-model="searchQuery.taskStyle" clearable placeholder="请选择任务方式">
              <el-option v-for="item in taskTypeList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
              </el-option>
            </el-select>
            <el-select filterable class="search-w190 wp-select" v-model="searchQuery.completeStyle" clearable placeholder="请选择完成方式">
              <el-option v-for="item in completeStyleList" :key="item.dictId" :label="item.dataKey" :value="item.dictId">
              </el-option>
            </el-select>
          </template>
        </SearchCard>
        <div class="layout-block wp-moudle-style layout-block-bottom">
          <div class="action-row">
            <div class="count"></div>
            <div>
              <el-button class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium" @click="createModel">创建模型
              </el-button>
            </div>
          </div>
          <div class="table-box">
            <!-- 序号、链接模型、任务站点、任务方式、完成方式、备注、编辑时间、编辑人、操作”   -->
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="70" align="center">
                </el-table-column>
                <el-table-column prop="url" label="链接模型" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="taskStationName" label="任务站点" width="120" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="taskStyleName" label="任务方式" width="150" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="completeStyleName" label="完成方式" width="120" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="updateTime" label="编辑时间" width="150" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="updateName" label="编辑人" min-width="100" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150" align="center">
                  <template slot-scope="scope">
                    <div class="flex-center">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big" @click="eidtModel(scope.row)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="删除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big" @click="delModel(scope.row)">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div class="pagination-row">
              <wp-pagination :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
              </wp-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- createModel -->
    <create-model ref="createModel" @success="refresh" :modelObj="modelObj"></create-model>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchCard from '@/views/components/searchCard'
import CreateModel from './components/createModel.vue'
import { sysDictList, taskStyleList, sysModelConfigList, deleteSysModelConfig } from '@/api/systemSettings.js';
export default {
  name: 'siteConfigList',
  components: { SearchCard, CreateModel },
  data () {
    return {
      completeStyleList: [],
      taskStationList: [],
      taskTypeList: [],
      datetimeValue: null,
      loading: false,
      tableData: [],
      total: 0,
      searchQuery: {
        taskStation: "",
        taskStyle: "",
        completeStyle: ""
      },
      query: {
        page: 1,
        limit: 30,
        taskStation: "",
        taskStyle: "",
        completeStyle: ""
      },
      modelObj: {}
    };
  },
  props: {},
  created () {
    this.initData()
  },
  mounted () {
    this.refresh()
  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    // 获取数据
    initData () {
      this.getSysDictList('taskStation')
      this.getSysDictList('completeStyle')
    },
    // 获取任务站点 短信渠道
    async getSysDictList (dataType) {
      let query = {
        dataType: dataType
      }
      try {
        let res = await sysDictList(query)
        if (Number(res.returnCode) !== 200) return
        if (dataType == 'taskStation') {
          this.taskStationList = res.returnData.filter(item => item.dataKey != '附件' && item.dataKey != '微信视频号')

        } else if (dataType == 'completeStyle') {
          this.completeStyleList = res.returnData

        }

      } catch (error) {
        console.log(error)
      }
    },
    // 任务站点 改变时  获取 任务方式
    async changeTaskStation () {
      this.taskTypeList = []
      this.searchQuery.taskStyle = ''
      if (!this.searchQuery.taskStation) return
      let query = {
        taskStationId: this.searchQuery.taskStation,
        taskType: 1
      }
      try {
        let res = await taskStyleList(query)
        if (Number(res.returnCode) !== 200) return
        this.taskTypeList = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    delModel (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除该模型吗？",
        content: "删除后该类链接将不能自动完成",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postDel(item)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDel (item) {
      try {
        let res = await deleteSysModelConfig(item.modelConfigId)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 编辑
    eidtModel (item) {
      this.modelObj = item
      this.$refs.createModel.show()
    },

    handleSizeChange (val) {
      console.log(val)
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      console.log(val)
      this.query.page = val
      this.getSysModelConfigList()
    },
    // 更新列表
    refresh () {
      this.query.page = 1
      this.getSysModelConfigList()
    },
    // 搜索
    handlerSearch () {
      this.query = {
        ...this.query,
        ...this.searchQuery
      }
      this.refresh()
    },
    //创建模型
    createModel () {
      this.modelObj = {}
      this.$refs.createModel.show()
    },
    // 获取模型列表
    async getSysModelConfigList () {
      let query = {
        ...this.query
      }
      if (this.loading) return
      this.loading = true
      try {
        let res = await sysModelConfigList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(element => {
          element.updateTime = this.$moment(element.updateTime).format("YYYY-MM-DD HH:mm")
        });
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/siteConfigList.scss";
</style>
