import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Layout from '@/layout/panel/panel.vue'
import LayoutTwo from '@/layout/panel/panelTwo.vue'
import Outside from '@/views/thirdLogin/Outside'
import newOutSide from '@/views/thirdLogin/newOutSide'
import ThirdLogin from '@/views/thirdLogin/ThirdLogin'
import XjxqLogin from '@/views/thirdLogin/xjxqLogin'
import LayoutThree from '@/layout/panel/panelThree.vue'


const originalPush = Router.prototype.push
// console.log(originalPush)
if (originalPush) {
	Router.prototype.push = function push (location, onResolve, onReject) {
		if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
		return originalPush.call(this, location)?.catch(err => err)
	}
}



/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
		roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
		title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
		icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
		breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
	}
 */


//公共路由
export const constantRoutes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue'),
		hidden: true
	},
	{
		path: '/switchLogin',
		name: 'switchLogin',
		component: () => import('@/views/login/switchLogin.vue'),
		hidden: true
	},
	
	{
		path: '/forgetPassword',
		name: 'panelTwo',
		component: LayoutTwo,
		hidden: true,
		children: [{
			path: '/forgetPassword',
			name: 'forgetPassword',
			component: () => import('@/views/login/forgetPassword.vue'),
			hidden: true,
			meta: {
				title: "忘记密码",
				icon: "home",
				noCache: false,
				pPath: '',
				tab: ['forgetPassword']
			}
		}]
	},
	{
		path: '/noPermission',
		name: 'panelThree',
		component: LayoutThree,
		hidden: true,
		children: [
		{
			path: '/noPermission',
			name: 'noPermission',
			component: () => import('@/views/login/noPermission.vue'),
			hidden: true,
			meta: {
				title: "无权限",
				icon: "home",
				noCache: false,
				pPath: '',
				tab: ['noPermission']
			}
		}]
	},
	{
		path: '/outsite', //第三方登录
		component: Outside,
		name: 'Outside',
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/newOutSide', // 政务云第三方登录
		component: newOutSide,
		name: 'newOutSide',
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/ThirdLogin', //第三方登录
		component: ThirdLogin,
		name: 'ThirdLogin',
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/xjxqLogin', // 湘江新区第三方登录
		component: XjxqLogin,
		name: 'xjxqLogin',
		hidden: true,
		meta: {
			requireAuth: false
		}
	},
	{
		path: '/wpUi',
		name: 'wpUi',
		component: () => import('@/views/wpUi.vue'),
		hidden: true
	},
	{
		path: '/404',
		name: 'page404',
		component: () => import('@/views/page404.vue'),
		hidden: true
	},

	

]

// 身份：超级管理员1， 管理员2，网评员3，网评组长4，值班账号5  
// 单位：路由是按照角色动态的，路由里面有属性  在点击时 判断是否有权限，没有权限，提示没有权限，并返回到首页
const allIdentityIds = [1, 2, 3, 4, 5]

// 可见菜单（后端返回 可见路由的名称，根据名称生成菜单）
// 可访问菜单（后端返回可访问的路由名称表，在跳转前做判断）
// 考虑还有内页的 顶部 菜单的可见 与 路由 结构

// 路由名称一定要包含在path中 且唯一
export const asyncRoutes = [
	{
		path: '/',
		name: 'panel',
		hidden: false,
		component: Layout,
		redirect: "/homeAdmin",
		meta: {
			title: "首页",// 管理员的首页
			icon: "shoye",
			noCache: false,
			pPath: '',
			tab: ['homeAdmin']
		},
		children: [{
			path: '/homeAdmin', // 管理员的首页
			name: 'homeAdmin',
			component: () => import('@/views/homeAdmin/homeAdmin.vue'),
			hidden: false,
			meta: {
				title: "首页",
				icon: "shoye",
				noCache: false,
				pPath: '',
				tab: ['homeAdmin']
			}
		}]
	},
	{
		path: '/',
		name: 'panel',
		hidden: false,
		component: Layout,
		redirect: "/home",
		meta: {
			title: "首页总览",
			icon: "shoye",
			noCache: false,
			pPath: '',
			tab: ['home']
		},
		children: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/home/home.vue'),
			hidden: false,
			meta: {
				title: "首页总览",
				icon: "shoye",
				noCache: false,
				pPath: '',
				tab: ['home']
			}
		}
		]
	},
	// 消息中心
	{
		path: '/messageCenter',
		name: 'messageCenter',
		hidden: true,
		component: Layout,
		// redirect: "/home",
		meta: {
			title: "消息中心",
			icon: "shoye",
			noCache: false,
			pPath: '',
			tab: ['messageCenter']
		},
		children: [{
			path: '/messageCenter',
			name: 'messageCenter',
			component: () => import('@/views/messageCenter/messageCenter.vue'),
			hidden: false,
			meta: {
				title: "消息中心",
				icon: "shoye",
				noCache: false,
				pPath: '',
				tab: ['messageCenter']
			}
		}
		]
	},
	// 网评员 消息中心
	{
		path: '/wpMessageCenter',
		name: 'wpMessageCenter',
		hidden: true,
		component: Layout,
		// redirect: "/home",
		meta: {
			title: "消息中心",
			icon: "shoye",
			noCache: false,
			pPath: '',
			tab: ['wpMessageCenter']
		},
		children: [{
			path: '/wpMessageCenter',
			name: 'wpMessageCenter',
			component: () => import('@/views/wpyMessageCenter/wpMessageCenter.vue'),
			hidden: false,
			meta: {
				title: "消息中心",
				icon: "shoye",
				noCache: false,
				pPath: '',
				tab: ['wpMessageCenter']
			}
		}
		]
	},
	// 人员队伍
	{
		path: '/teamlist',
		name: 'teamlist',
		hidden: false,
		component: Layout,
		// redirect: "/teamlist",
		meta: {
			title: "人员队伍",
			icon: "renyuanduiwu",
			noCache: false,
			pPath: '',
			tab: ['teamlist']
		},
		children: [{
			path: '/teamlist',
			name: 'teamlist',
			component: () => import('@/views/personnelTeam/teamlist.vue'),
			hidden: true,
			meta: {
				title: "人员队伍",
				icon: "renyuanduiwu",
				noCache: false,
				pPath: '/teamlist',
				tab: ['teamlist']
			}
		},
		{
			path: '/addPeople',
			name: 'addPeople',
			component: () => import('@/views/personnelTeam/addPeople.vue'),
			hidden: true,
			meta: {
				title: "添加人员",
				icon: "renyuanduiwu",
				noCache: false,
				pPath: '/teamlist',
				tab: ['addPeople']
			}
		},
		{
			path: '/peopleInformation',
			name: 'peopleInformation',
			component: () => import('@/views/personnelTeam/peopleInformation.vue'),
			hidden: true,
			meta: {
				title: "个人信息",
				icon: "renyuanduiwu",
				noCache: false,
				pPath: '/teamlist',
				tab: ['peopleInformation']
			}
		}
		]
	},
	// 管理员-投稿中心
	{
		path: '/taskManagement',
		name: 'panel',
		hidden: false,
		component: Layout,
		//redirect: "/taskManagement",
		meta: {
			title: "投稿中心",
			icon: "renwuzhongxin",
			noCache: false,
			pPath: '/taskManagement',
			tab: ['taskManagement']
		},
		children: [
			{
				path: '/taskManagement', // 
				name: 'taskManagement',
				component: () => import('@/views/task/taskManagement'),
				hidden: false,
				meta: {
					title: "投稿管理",
					icon: "",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/taskDetail', // 投稿管理 - 任务详情
				name: 'taskDetail',
				component: () => import('@/views/task/taskManagement/taskDetail'),
				hidden: true,
				meta: {
					title: "任务详情",
					icon: "",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/addTask', // 投稿管理-创建任务
				name: 'addTask',
				component: () => import('@/views/task/taskManagement/addTask'),
				hidden: true,
				meta: {
					title: "创建任务",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/editTask', // 投稿管理-编辑任务
				name: 'editTask',
				component: () => import('@/views/task/taskManagement/editTask'),
				hidden: true,
				meta: {
					title: "编辑任务",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/addTaskPeople', // 投稿管理-追加人员
				name: 'addTaskPeople',
				component: () => import('@/views/task/taskManagement/addToPeople'),
				hidden: true,
				meta: {
					title: "追加人员",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/batchTask', // 投稿管理-批量任务
				name: 'batchTask',
				component: () => import('@/views/task/taskManagement/batchTask'),
				hidden: true,
				meta: {
					title: "批量任务",
					noCache: false,
					pPath: '/taskManagement',
					tab: ['taskManagement']
				}
			},
			{
				path: '/taskReview', //管理员 投稿审核
				name: 'taskReview',
				component: () => import('@/views/task/taskReview'),
				hidden: false,
				meta: {
					title: "投稿审核",
					icon: "",
					noCache: false,
					pPath: '/taskReview',
					tab: ['taskReview']
				},
			},
			{
				path: '/taskReviewDetail', // 投稿审核-详情
				name: 'taskReviewDetail',
				component: () => import('@/views/task/taskReview/detail'),
				hidden: true,
				meta: {
					title: "投稿审核-详情",
					noCache: false,
					pPath: '/taskReview',
					tab: ['taskReview']
				}
			},
			{
				path: '/taskStatistics',
				name: 'taskStatistics',
				component: () => import('@/views/task/taskStatistics'),
				hidden: false,
				meta: {
					title: "投稿统计",
					icon: "",
					pPath: "/taskStatistics",
					tab: ['taskStatistics']
				}
			},
			{
				path: '/transpondStatistics',
				name: 'transpondStatistics',
				component: () => import('@/views/task/transpondStatistics'),
				hidden: false,
				meta: {
					title: "转发统计",
					icon: "",
					pPath: "/transpondStatistics",
					tab: ['transpondStatistics']
				}
			},
			{
				path: '/taskAnalyse',
				name: 'taskAnalyse',
				component: () => import('@/views/task/taskAnalyse/taskAnalyse.vue'),
				hidden: false,
				meta: {
					title: "投稿分析",
					icon: "",
					pPath: "/taskAnalyse",
					tab: ['taskAnalyse']
				}
			},
			{
				path: '/excellentReportManage', // 
				name: 'excellentReportManage',
				component: () => import('@/views/task/excellentReport'),
				hidden: true,
				meta: {
					title: "优评审核",
					icon: "",
					noCache: false,
					pPath: '/taskReview',
					tab: ['excellentReportManage']
				}
			},
			{
				path: '/excellentDetailManage', // 
				name: 'excellentDetailManage',
				component: () => import('@/views/task/excellentReport/detail'),
				hidden: true,
				meta: {
					title: "优评审核详情",
					icon: "",
					noCache: false,
					pPath: '/taskReview',
					tab: ['excellentDetailManage']
				}
			}
		]
	},
	// 管理员-上报中心
	{
		path: '/reported',
		name: 'reported',
		hidden: false,
		component: Layout,
		// redirect: "/reported",
		meta: {
			title: "上报中心",
			icon: "shangbaozhongxin",
			noCache: false,
			pPath: '/reported',
			tab: ['reportedManage']
		},
		children: [
			{
				path: '/reportedManage',
				name: 'reportedManage',
				component: () => import('@/views/reported/manage'),
				hidden: false,
				meta: {
					title: "上报管理",
					icon: "",
					noCache: false,
					pPath: '/reportedManage',
					tab: ['reportedManage']
				}
			},
			{
				path: '/reportedDetail',
				name: 'reportedDetail',
				component: () => import('@/views/reported/manage/detail'),
				hidden: true,
				meta: {
					title: "上报详情",
					icon: "",
					noCache: false,
					pPath: '/reportedManage',
					tab: ['reportedDetail']
				}
			},
			{
				path: '/addSpecial',
				name: 'addSpecial',
				component: () => import('@/views/reported/manage/addSpecial'),
				hidden: true,
				meta: {
					title: "创建专题",
					icon: "",
					noCache: false,
					pPath: '/reportedManage',
					tab: ['addSpecial']
				}
			},
			{
				path: '/editTopic',
				name: 'editTopic',
				component: () => import('@/views/reported/manage/editTopic'),
				hidden: true,
				meta: {
					title: "编辑专题",
					icon: "",
					noCache: false,
					pPath: '/reportedManage',
					tab: ['editTopic']
				}
			},
			{
				path: '/addToPeople',
				name: 'addToPeople',
				component: () => import('@/views/reported/manage/addToPeople'),
				hidden: true,
				meta: {
					title: "追加人员",
					icon: "",
					noCache: false,
					pPath: '/reportedManage',
					tab: ['addToPeople']
				}
			},
			{
				path: '/reportedReview',
				name: 'reportedReview',
				component: () => import('@/views/reported/review'),
				hidden: false,
				meta: {
					title: "上报审核",
					icon: "",
					noCache: false,
					pPath: '/reportedReview',
					tab: ['reportedReview']
				}
			},
			{
				path: '/reportedReviewDetail', // 上报审核详情
				name: 'reportedReviewDetail',
				component: () => import('@/views/reported/review/detail'),
				hidden: true,
				meta: {
					title: "上报详情",
					icon: "",
					noCache: false,
					pPath: '/reportedReview',
					tab: ['reportedReviewDetail']
				}
			},
			{
				path: '/reportedStatistics',
				name: 'reportedStatistics',
				component: () => import('@/views/reported/statistics'),
				hidden: false,
				meta: {
					title: "上报统计",
					icon: "",
					noCache: true,
					pPath: '/reportedStatistics',
					tab: ['reportedStatistics']
				}
			},
		]
	},
	// 管理员-阅评中心
	{
		path: '/review',
		name: 'review', //路由tab 要和 name 一致
		hidden: false,
		component: Layout,
		// redirect: "/reported",
		meta: {
			title: "阅评中心",
			icon: "review",
			noCache: false,
			pPath: '/review',
			tab: ['review']
		},
		children: [
			{
				path: '/review',
				name: 'review',
				component: () => import('@/views/review/manage'),
				hidden: false,
				meta: {
					title: "阅评中心",
					icon: "review",
					noCache: false,
					pPath: '/review',
					tab: ['review']
				}
			}
		]
	},

	// 网评员-投稿中心
	{
		path: '/taskList',
		name: 'taskList',
		hidden: false,
		component: Layout,
		meta: {
			title: "投稿中心",
			icon: "renwuzhongxin",
			noCache: false,
			pPath: '/taskList',
			tab: ['taskList']
		},
		children: [
			{
				path: '/taskList', // 网评员-任务列表
				name: 'taskList',
				component: () => import('@/views/taskCenter/taskList'),
				hidden: false,
				meta: {
					title: "投稿列表",
					icon: "",
					noCache: false,
					pPath: '/taskList',
					tab: ['taskList']
				}
			},
			{
				path: '/taskCenterDetail', // 网评员-任务详情
				name: 'taskCenterDetail',
				component: () => import('@/views/taskCenter/taskList/taskDetail'),
				hidden: true,
				meta: {
					title: "网评员任务详情",
					icon: "",
					noCache: false,
					pPath: '/taskList',
					tab: ['taskCenterDetail']
				}
			},
			{
				path: '/taskCenterTranspondTask', // 网评员-转发任务
				name: 'taskCenterTranspondTask',
				component: () => import('@/views/taskCenter/taskList/transpondTask'),
				hidden: true,
				meta: {
					title: "网评员转发任务",
					icon: "",
					noCache: false,
					pPath: '/taskList',
					tab: ['taskCenterTranspondTask']
				}
			},
			{
				path: '/myWork', // 网评员-我的作品
				name: 'myWork',
				component: () => import('@/views/taskCenter/myWork'),
				hidden: false,
				meta: {
					title: "我的作品",
					icon: "",
					noCache: false,
					pPath: '/myWork',
					tab: ['myWork']
				}
			},
			{
				path: '/workDetail', // 网评员-作品详情
				name: 'workDetail',
				component: () => import('@/views/taskCenter/myWork/detail'),
				hidden: true,
				meta: {
					title: "作品详情",
					icon: "",
					noCache: false,
					pPath: '/myWork',
					tab: ['workDetail']
				}
			},
			{
				path: '/wordStatistics', // 网评员-作品统计
				name: 'wordStatistics',
				component: () => import('@/views/taskCenter/wordStatistics'),
				hidden: false,
				meta: {
					title: "作品统计",
					icon: "",
					noCache: false,
					pPath: '/wordStatistics',
					tab: ['wordStatistics']
				}
			},
			{
				path: '/crewStatistics', // 
				name: 'crewStatistics',
				component: () => import('@/views/taskCenter/crewStatistics'),
				hidden: false,
				meta: {
					title: "组员统计",
					icon: "",
					noCache: false,
					pPath: '/crewStatistics',
					tab: ['crewStatistics']
				}
			},
			{
				path: '/crewWorkDetails', // 
				name: 'crewWorkDetails',
				component: () => import('@/views/taskCenter/crewStatistics/workDetails'),
				hidden: true,
				meta: {
					title: "作品明细",
					icon: "",
					noCache: false,
					pPath: '/crewStatistics',
					tab: ['crewWorkDetails']
				}
			},
			{
				path: '/leaderTaskArticleDetail', // 组长-组员的作品详情
				name: 'leaderTaskArticleDetail',
				component: () => import('@/views/taskCenter/myWork/detail'),
				hidden: true,
				meta: {
					title: "作品详情",
					icon: "",
					noCache: false,
					pPath: '/crewStatistics',
					tab: ['leaderTaskArticleDetail']
				}
			},
			{
				path: '/excellentReport', // 
				name: 'excellentReport',
				component: () => import('@/views/taskCenter/excellentReport'),
				hidden: false,
				meta: {
					title: "优评上报",
					icon: "",
					noCache: false,
					pPath: '/excellentReport',
					tab: ['excellentReport']
				}
			},
			{
				path: '/excellentDetail', // 
				name: 'excellentDetail',
				component: () => import('@/views/taskCenter/excellentReport/detail'),
				hidden: true,
				meta: {
					title: "优评详情",
					icon: "",
					noCache: false,
					pPath: '/excellentReport',
					tab: ['excellentDetail']
				}
			}
		]
	},
	// 网评员-上报中心
	{
		path: '/reportedList',
		name: 'reportedList',
		hidden: false,
		component: Layout,
		meta: {
			title: "上报中心",
			icon: "shangbaozhongxin",
			noCache: false,
			pPath: '/reportedList',
			tab: ['specialList']
		},
		children: [
			{
				path: '/specialList',
				name: 'specialList',
				component: () => import('@/views/reportedCenter/specialList'),
				hidden: false,
				meta: {
					title: "专题列表",
					icon: "",
					noCache: false,
					pPath: '/specialList',
					tab: ['specialList']
				}
			},
			{
				path: '/specialDetail',
				name: 'specialDetail',
				component: () => import('@/views/reportedCenter/specialList/detail'),
				hidden: true,
				meta: {
					title: "专题详情",
					icon: "",
					noCache: false,
					pPath: '/specialList',
					tab: ['specialDetail']
				}
			},
			{
				path: '/myReported',
				name: 'myReported',
				component: () => import('@/views/reportedCenter/myReported'),
				hidden: false,
				meta: {
					title: "我的上报",
					icon: "",
					noCache: false,
					pPath: '/myReported',
					tab: ['myReported']
				}
			},
			{
				path: '/articleDetail',
				name: 'articleDetail',
				component: () => import('@/views/reportedCenter/myReported/detail'),
				hidden: true,
				meta: {
					title: "上报详情",
					icon: "",
					noCache: false,
					pPath: '/myReported',
					tab: ['articleDetail']
				}
			},
			{
				path: '/reportedCenterStatistics',
				name: 'reportedCenterStatistics',
				component: () => import('@/views/reportedCenter/reportedStatistics'),
				hidden: false,
				meta: {
					title: "上报统计",
					icon: "",
					noCache: false,
					pPath: '/reportedCenterStatistics',
					tab: ['reportedCenterStatistics']
				}
			},
			{
				path: '/crewReportedStatistics',
				name: 'crewReportedStatistics',
				component: () => import('@/views/reportedCenter/crewReportedStatistics'),
				hidden: false,
				meta: {
					title: "组员统计",
					icon: "",
					noCache: false,
					pPath: '/crewReportedStatistics',
					tab: ['crewReportedStatistics']
				}
			},
			{
				path: '/crewReportedWorkDetails', // 
				name: 'crewReportedWorkDetails',
				component: () => import('@/views/reportedCenter/crewReportedStatistics/workDetails'),
				hidden: true,
				meta: {
					title: "作品明细",
					icon: "",
					noCache: false,
					pPath: '/crewReportedStatistics',
					tab: ['crewReportedWorkDetails']
				}
			},
			{
				path: '/leaderTopicWorkDetail',
				name: 'leaderTopicWorkDetail',
				component: () => import('@/views/reportedCenter/myReported/detail'),
				hidden: true,
				meta: {
					title: "上报详情",
					icon: "",
					noCache: false,
					pPath: '/crewReportedStatistics',
					tab: ['leaderTopicWorkDetail']
				}
			},
		]
	},
	// 网评员-阅评中心
	{
		path: '/reviewCenter',
		name: 'reviewCenter', //路由tab 要和 name 一致
		hidden: false,
		component: Layout,
		// redirect: "/reported",
		meta: {
			title: "阅评中心",
			icon: "review",
			noCache: false,
			pPath: '/reviewCenter',
			tab: ['reviewCenter']
		},
		children: [
			{
				path: '/reviewCenter',
				name: 'reviewCenter',
				component: () => import('@/views/reviewCenter/manage'),
				hidden: false,
				meta: {
					title: "阅评中心",
					icon: "review",
					noCache: false,
					pPath: '/reviewCenter',
					tab: ['reviewCenter']
				}
			}
		]
	},
	

	// 投稿演练
	{
		path: '/drillList',
		name: 'drillList',
		hidden: false,
		component: Layout,
		// redirect: "/teamlist",
		meta: {
			title: "投稿演练",
			icon: "zhihuiyanlian",
			noCache: false,
			pPath: '',
			tab: ['drillList']
		},
		children: [{
			path: '/drillList',
			name: 'drillList',
			component: () => import('@/views/drill/drillList.vue'),
			hidden: false,
			meta: {
				title: "投稿演练",
				icon: "",
				noCache: false,
				pPath: '/drillList',
				tab: ['drillList']
			}
		},
		{
			path: '/addDrill',
			name: 'addDrill',
			component: () => import('@/views/drill/addDrill/index.vue'),
			hidden: true,
			meta: {
				title: "创建演练",
				icon: "",
				noCache: false,
				pPath: '/drillList',
				tab: ['drillList']
			}
		},
		{
			path: '/drillMembers',
			name: 'drillMembers',
			component: () => import('@/views/drill/drillMembers.vue'),
			hidden: true,
			meta: {
				title: "成员列表",
				icon: "",
				noCache: false,
				pPath: '/drillList',
				tab: ['drillList']
			}
		},
		{
			path: '/appendMembers',
			name: 'appendMembers',
			component: () => import('@/views/drill/appendMembers/index.vue'),
			hidden: true,
			meta: {
				title: "追加成员",
				icon: "",
				noCache: false,
				pPath: '/drillList',
				tab: ['drillList']
			}
		},
		]
	},
	{
		path: '/detailHeader',
		name: 'detailHeader',
		redirect: "/drillDetail",
		component: () => import('@/views/drill/components/detailHeader.vue'),
		hidden: true,
		children: [{
			path: '/drillDetail',
			name: 'drillDetail',
			component: () => import('@/views/drill/drillDetail.vue'),
			hidden: true,
			meta: {
				title: "演练详情",
				icon: "",
				noCache: false,
				pPath: '',
				tab: ['drillDetail']
			}
		}, {
			path: '/bulletChat',
			name: 'bulletChat',
			component: () => import('@/views/drill/bulletChat.vue'),
			hidden: true,
			meta: {
				title: "演练弹幕",
				icon: "",
				noCache: false,
				pPath: '',
				tab: ['bulletChat']
			}
		}]
	},
	//  演练回放 共用一套 ui
	{
		path: '/detailHeaderPlayback',
		name: 'detailHeaderPlayback',
		redirect: "/drillDetailPlayback",
		component: () => import('@/views/drill/components/detailHeader.vue'),
		hidden: true,
		children: [{
			path: '/drillDetailPlayback',
			name: 'drillDetailPlayback',
			component: () => import('@/views/drill/drillDetail.vue'),
			hidden: true,
			meta: {
				title: "演练详情",
				icon: "",
				noCache: false,
				flag: "playback",
				pPath: '',
				tab: ['drillDetailPlayback']
			}
		}, {
			path: '/bulletChatPlayback',
			name: 'bulletChatPlayback',
			component: () => import('@/views/drill/bulletChat.vue'),
			hidden: true,
			meta: {
				title: "演练弹幕",
				flag: "playback",
				icon: "",
				noCache: false,
				pPath: '',
				tab: ['bulletChatPlayback']
			}
		}]
	},
	// 网评员投稿演练
	{
		path: '/wpydrillList',
		name: 'wpydrillList',
		hidden: false,
		component: Layout,
		// redirect: "/teamlist",
		meta: {
			title: "投稿演练",
			icon: "zhihuiyanlian",
			noCache: false,
			pPath: '',
			tab: ['wpydrillList']
		},
		children: [{
			path: '/wpydrillList',
			name: 'wpydrillList',
			component: () => import('@/views/drill/wpydrillList.vue'),
			hidden: false,
			meta: {
				title: "投稿演练",
				icon: "",
				noCache: false,
				pPath: '/wpydrillList',
				tab: ['wpydrillList']
			}
		}
		]
	},
	// 积分中心
	{
		path: '/integralMall',
		name: 'integralMall',
		hidden: false,
		component: Layout,
		// redirect: "/teamlist",
		meta: {
			title: "积分中心",
			icon: "jifen",
			noCache: false,
			pPath: '',
			tab: ['integralMall']
		},
		children: [{
			path: '/integralMall',
			name: '/integralMall',
			component: () => import('@/views/integralMall/productList.vue'),
			hidden: true,
			meta: {
				title: "积分中心",
				icon: "",
				noCache: false,
				pPath: '/integralMall',
				tab: ['integralMall']
			}
		},
		]
	},
	// 系统设置模块
	{
		path: '/systemSettings',
		name: 'systemSettings',
		hidden: false,
		component: Layout,
		//redirect: "/personalInformation",
		meta: {
			title: "系统设置",
			icon: "shezhi",
			noCache: false,
			pPath: '/personalInformation',
			tab: ['systemSettings']
		},
		children: [{
			path: '/personalInformation', // 
			name: 'personalInformation',
			component: () => import('@/views/systemSettings/personalInformation/personalInformation.vue'),
			hidden: false,
			meta: {
				title: "个人信息",
				icon: "",
				noCache: false,
				pPath: '/personalInformation',
				tab: ['personalInformation']
			}
		},
		{
			path: '/leaderPersonalInformation', // 
			name: 'leaderPersonalInformation',
			component: () => import('@/views/systemSettings/personalInformation/leaderPersonalInformation.vue'),
			hidden: false,
			meta: {
				title: "个人信息",//组长
				icon: "",
				noCache: false,
				pPath: '/leaderPersonalInformation',
				tab: ['leaderPersonalInformation']
			}
		},
		{
			path: '/changePassword', // 
			name: 'changePassword',
			component: () => import('@/views/systemSettings/changePassword/changePassword.vue'),
			hidden: false,
			meta: {
				title: "修改密码",
				icon: "",
				noCache: false,
				pPath: '/changePassword',
				tab: ['changePassword']
			}
		},
		{
			path: '/parameterConfig', // 
			name: 'parameterConfig',
			component: () => import('@/views/systemSettings/parameterConfig/parameterConfig.vue'),
			hidden: false,
			meta: {
				title: "参数配置",
				icon: "",
				noCache: false,
				pPath: '/parameterConfig',
				tab: ['parameterConfig']
			}
		},
		{
			path: '/unitManagList', // 
			name: 'unitManagList',
			component: () => import('@/views/systemSettings/unitManag/unitManagList.vue'),
			hidden: false,
			meta: {
				title: "单位管理",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['unitManagList']
			}
		},
		{
			path: '/createUnits', // 
			name: 'createUnits',
			component: () => import('@/views/systemSettings/unitManag/createUnits.vue'),
			hidden: true,
			meta: {
				title: "创建单位",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['createUnits']
			}
		},
		{
			path: '/userManagList', // 
			name: 'userManagList',
			component: () => import('@/views/systemSettings/unitManag/userManagList.vue'),
			hidden: true,
			meta: {
				title: "用户管理",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['userManagList']
			}
		},
		{
			path: '/addUser', // 
			name: 'addUser',
			component: () => import('@/views/systemSettings/unitManag/addUser.vue'),
			hidden: true,
			meta: {
				title: "创建用户",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['addUser']
			}
		},
		{
			path: '/smsManagList', // 
			name: 'smsManagList',
			component: () => import('@/views/systemSettings/unitManag/smsManagList.vue'),
			hidden: true,
			meta: {
				title: "短信管理-充值记录",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['smsManagList']
			}
		},
		{
			path: '/smsLogList', // 
			name: 'smsLogList',
			component: () => import('@/views/systemSettings/unitManag/smsLogList.vue'),
			hidden: true,
			meta: {
				title: "短信管理-短信日志",
				icon: "",
				noCache: false,
				pPath: '/unitManagList',
				tab: ['smsLogList']
			}
		},
		{
			path: '/siteConfigList', // 
			name: 'siteConfigList',
			component: () => import('@/views/systemSettings/siteConfigList/siteConfigList.vue'),
			hidden: false,
			meta: {
				title: "全局配置",
				icon: "",
				noCache: false,
				pPath: '/siteConfigList',
				tab: ['siteConfigList']
			}
		},
		]
	},
	// 超级管理员 
	{
		path: '/', redirect: '/unitManagList', hidden: true, meta: {
			title: "单位管理",
			icon: "",
			noCache: false,
			pPath: '/unitManagList',
			tab: ['unitManagList']
		}
	},
	// 404 page must be placed at the end !!!
	{
		path: '*', redirect: '/404', hidden: true, meta: {
			title: "404",
			icon: "shoye",
			noCache: false,
			pPath: '',
			tab: ['404']
		}
	}
]

export default new Router({
	mode: 'history', // 去掉url中的#
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})
