<template>
  <div class="forgetPassword">
    <div class="from-box">
      <div class="forget-title">忘记密码</div>
      <div class="forget-step">
        <el-steps :active="active" align-center>
          <el-step title="确认账户"></el-step>
          <el-step title="手机验证"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
      </div>

      <div class="step-box">
        <div v-if="active == 0">
          <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" class="ruleform1">
            <el-form-item prop="username">
              <el-input v-model.trim="ruleForm1.username" placeholder="请输入登录账号" clearable @keyup.enter.native="next2" maxLength="20">
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <div class="verification-div">
                <el-input v-model.trim="ruleForm1.captcha" placeholder="请输入验证码" class="verification-input" clearable :maxlength="4" @keyup.enter.native="next2">
                </el-input>
                <!--  -->
                <div class="verification-img"> <img :src="kaptchaUrl" @click="updateCode" title="重新加载"></div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="next2" class="next2-btn" v-preventReClick>下一步</el-button>
            </el-form-item>
          </el-form>

        </div>
        <div v-if="active == 1">
          <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" class="ruleform2">
            <el-form-item>
              <div class="phone-num">当前手机号{{  mobile  }}</div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="verification-div">
                <el-input v-model.trim="ruleForm2.code" placeholder="请输入验证码" class="verification-input" clearable :maxlength="6" @keyup.enter.native="next3" @input="resetNum">
                </el-input>
                <!--  -->
                <el-button v-if="!countdowning" type="primary" class="verification-btn" v-preventReClick @click="postSendMsg">
                  发送验证码</el-button>
                <el-button v-else type="primary" class="verification-btn">{{  remainingTime  }}s</el-button>
                <!-- <div class="verification-img"> <img src="~@/assets/images/home/dong.png" alt=""></div> -->
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="next3" class="next2-btn" v-preventReClick>下一步</el-button>
            </el-form-item>
            <el-form-item>
              <div class="tip">手机换号了？试试联系<span>{{  customerServicePhone  }}</span></div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="active == 2">
          <el-form :model="ruleForm3" :rules="rules3" ref="ruleForm3" class="ruleform3">
            <el-form-item prop="pass">
              <el-input type="password" v-model.trim="ruleForm3.pass" show-password placeholder="请输入5-20位字母数字组合（区分大小写）" clearable @keyup.enter.native="next4"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input type="password" v-model.trim="ruleForm3.checkPass" show-password placeholder="请重新输入新密码" clearable @keyup.enter.native="next4"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="next4" class="next2-btn" v-preventReClick>确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEnAndNumAndUnderline, resetNum, verifyPassword, passwordLang } from '@/utils/validate.js';
import { captchaImg, findMobile, sendFind, checkFindCode, getBackPass } from '@/api/login.js';
export default {
  name: 'forgetPassword',
  components: {},
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else {
        if (this.ruleForm3.checkPass !== '') {
          this.$refs.ruleForm3.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else if (value !== this.ruleForm3.pass) {
        callback(new Error('两次密码不一致，请重新输入!'));
      } else {
        callback();
      }
    };
    var validateLoginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录账号'));
      } else if (!isEnAndNumAndUnderline(value)) {
        callback(new Error('请输入英文或数字'));
      } else {
        callback();
      }
    };
    return {
      kaptchaUrl: '',
      active: 0,
      customerServicePhone: '',
      ruleForm1: {
        username: '',
        captcha: ''
      },
      rules1: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateLoginName, trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      ruleForm2: {
        code: ''
      },
      rules2: {
        code: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ]
      },
      ruleForm3: {
        pass: '',
        checkPass: '',
      },
      rules3: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      token: '',
      countdowning: false,
      remainingTime: 120,
      timer: null,
      postSendMsging: false
    };
  },
  props: {},
  created () {
    this.updateCode()
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 重置验证码数据
    resetNum () {
      this.ruleForm2.code = resetNum(this.ruleForm2.code);
    },
    /*获取验证码*/
    updateCode () {
      captchaImg().then(res => {
        // console.log(res)
        this.token = res.headers.token
        this.kaptchaUrl = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      })
    },
    next () {
      if (this.active++ > 2) this.active = 0;
    },
    next2 () {
      // 验证第一步成功后 跳转到下一步
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          this.postNext2()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 第一步的请求
    async postNext2 () {
      let query = {
        ...this.ruleForm1,
        token: this.token
      }
      try {
        let res = await findMobile(query)
        if (Number(res.returnCode) != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.mobile = res.returnData.mobile
        this.customerServicePhone = res.returnData.customerServicePhone
        this.active++
      } catch (error) {
        console.log(error);
      }
    },
    next3 () {
      this.$refs['ruleForm2'].validate((valid) => {
        if (valid) {
          this.postNext3()
          // this.active++
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async postNext3 () {
      let query = {
        username: this.ruleForm1.username,
        code: this.ruleForm2.code
      }
      try {
        let res = await checkFindCode(query)
        if (Number(res.returnCode) != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.active++
      } catch (error) {
        console.log(error);
      }
    },
    next4 () {
      this.$refs['ruleForm3'].validate((valid) => {
        if (valid) {
          this.postNext4()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async postNext4 () {
      let query = {
        username: this.ruleForm1.username,
        code: this.ruleForm2.code,
        newPass: this.ruleForm3.pass
      }
      try {
        let res = await getBackPass(query)
        if (Number(res.returnCode) != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$router.push({ name: 'login' })
      } catch (error) {
        console.log(error);
      }
    },
    // 发送短信的请求
    async postSendMsg () {
      if (this.postSendMsging) return
      this.postSendMsging = true
      let query = {
        username: this.ruleForm1.username
      }
      this.countdowning = true
      // console.log(query)
      try {
        let res = await sendFind(query)
        this.postSendMsging = false
        if (Number(res.returnCode) != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.remainingTime = 120
        //4.进入倒计时
        this.countDown();
      } catch (error) {
        console.log(error);
      }
    },
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    countDown: function () {
      that.clearTimer()
      let that = this;
      that.timer = setInterval(() => {
        if (that.remainingTime <= 0) {
          that.remainingTime = 120;
          that.clearTimer()
          that.countdowning = false;
          that.mobile = ''
        } else {
          that.remainingTime--;
        }
      }, 1000);
    },
  },
  beforeDestroy () {
    this.clearTimer()
  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/forgetPassword.scss";
</style>
