var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basicsForm" },
    [
      _c(
        "el-form",
        {
          ref: "basicsForm",
          attrs: {
            model: _vm.basicsForm,
            rules: _vm.basicsFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-content",
              attrs: { label: "任务分类：", prop: "taskTypeId" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "wp-select task-w726",
                  attrs: { placeholder: "请选择任务分类" },
                  model: {
                    value: _vm.basicsForm.taskTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.basicsForm, "taskTypeId", $$v)
                    },
                    expression: "basicsForm.taskTypeId",
                  },
                },
                _vm._l(_vm.taskClassifyList, function (item) {
                  return _c("el-option", {
                    key: item.taskTypeId,
                    attrs: { label: item.taskTypeName, value: item.taskTypeId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-content",
              attrs: { label: "任务链接：", prop: "url" },
            },
            [
              _c("el-input", {
                staticClass: "wp-input task-w726",
                attrs: { maxlength: "2000", placeholder: "请输入任务链接" },
                on: { change: _vm.changeUrl },
                model: {
                  value: _vm.basicsForm.url,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.basicsForm,
                      "url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "basicsForm.url",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-content",
              attrs: { label: "任务标题：", prop: "title" },
            },
            [
              _c("el-input", {
                staticClass: "wp-input task-w726",
                attrs: { placeholder: "请输入任务标题" },
                on: { input: _vm.updateTitle },
                model: {
                  value: _vm.basicsForm.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.basicsForm,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "basicsForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "任务要求：", prop: "content" } },
            [
              _c("el-input", {
                staticClass: "task-w726 textarea",
                attrs: {
                  type: "textarea",
                  maxlength: "1000",
                  rows: 6,
                  placeholder: "请输入任务要求和口径",
                },
                model: {
                  value: _vm.basicsForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.basicsForm, "content", $$v)
                  },
                  expression: "basicsForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "QquickLanguage",
            {
              ref: "quickRef",
              staticStyle: { "margin-left": "100px", "margin-top": "22px" },
              attrs: { shortcutList: _vm.shortcutList },
              on: {
                addQuick: _vm.addQuick,
                clickShortcut: _vm.clickShortcut,
                removeQuick: _vm.removeQuick,
              },
            },
            [
              _vm.shortcutList.length < 3
                ? _c("div", { staticClass: "add-shortcut" }, [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _c("div", [_vm._v("添加快捷语")]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "22px" },
              attrs: { label: "任务备注：" },
            },
            [
              _c("el-input", {
                staticClass: "task-w726 textarea task-notes",
                attrs: {
                  type: "textarea",
                  maxlength: "500",
                  resize: "none",
                  placeholder: "请输入任务任务备注",
                },
                model: {
                  value: _vm.basicsForm.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.basicsForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "basicsForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }