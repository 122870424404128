var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "flex file-list" },
    _vm._l(_vm.fileList, function (item, index) {
      return _c(
        "li",
        { key: item.id, staticClass: "file-item" },
        [
          item.type == "img"
            ? _c("el-image", {
                key: item.id,
                staticClass: "w100",
                attrs: {
                  src: _vm.baseURL + item.id,
                  "preview-src-list": _vm.$utils.arrayRearrangement(
                    index,
                    _vm.imgList
                  ),
                },
              })
            : item.type == "xls"
            ? _c("img", {
                staticClass: "w100",
                attrs: { src: _vm.$globalData.fileImgs.xls, title: item.name },
                on: {
                  click: function ($event) {
                    return _vm.download(item.id)
                  },
                },
              })
            : item.type == "word"
            ? _c("img", {
                staticClass: "w100",
                attrs: { src: _vm.$globalData.fileImgs.word, title: item.name },
                on: {
                  click: function ($event) {
                    return _vm.download(item.id)
                  },
                },
              })
            : item.type == "zip"
            ? _c("img", {
                staticClass: "w100",
                attrs: { src: _vm.$globalData.fileImgs.zip, title: item.name },
                on: {
                  click: function ($event) {
                    return _vm.download(item.id)
                  },
                },
              })
            : item.type == "pdf"
            ? _c("img", {
                staticClass: "w100",
                attrs: { src: _vm.$globalData.fileImgs.pdf, title: item.name },
                on: {
                  click: function ($event) {
                    return _vm.download(item.id)
                  },
                },
              })
            : _vm._e(),
          item.type == "mp4"
            ? _c("Video", { attrs: { file: item } })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }