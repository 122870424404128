<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          任务数量：<span class="wp-blue">{{
            statisticsData.taskNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          作品数量：<span class="wp-blue">{{
            statisticsData.articleNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-button @click="exportFile" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu">导出</el-button>
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table :data="tableData" stripe sortable="custom" @sort-change="sortChange" :default-sort="sortObj" v-if="tableData.length !== 0">
        <el-table-column align="center" type="index" label="序号" width="70">
        </el-table-column>
        <el-table-column align="center" prop="taskTypeName" label="分类名称">
        </el-table-column>
        <el-table-column align="center" prop="taskNum" label="任务数量">
        </el-table-column>
        <el-table-column align="center" prop="completeNum" label="完成人数">
        </el-table-column>
        <el-table-column v-if="searchParams.timeType == 2" align="center" prop="completionRate" label="完成率">
          <template slot-scope="scope">
            <div>{{ scope.row.completionRate }}%</div>
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="workNum" label="作品数量">
        </el-table-column>
        <el-table-column align="center" sortable prop="totalScore" label="奖励积分">
        </el-table-column>
      </el-table>

      <div class="wp-noData" v-if="tableData.length == 0 && !loading">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    },
    searchParams: {
      type: Object,
      default: () => { }
    },
    loading: false
  },
  data () {
    return {
      sortObj: {}
    }
  },
  methods: {
    // 导出
    async exportFile (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.typeStatisticsExport({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }

      this.$emit("sortChange", { orderField: prop, sort: order })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>