<template>
  <div class="forgetPassword">
    <div class="noPermission flex-column-center">
      <img src="~@/assets/images/noPermission.png" alt="">
      <p class="tip">暂无访问权限</p>
      <p class="tip2">请联系管理员在属地媒体监管系统创建{{userName}}用户</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'noPermission',
  components: {},
  data () {
    return {
      userName: ''
    };
  },
  props: {},
  created () {
    this.userName  = this.$route.query.userName ?this.$route.query.userName:''
  },
  mounted () {

  },
  computed: {
  },
  methods: {

  },
  beforeDestroy () {
    this.clearTimer()
  },
  watch: {

  }
};
</script>
<style scoped lang="scss">
@import "./css/forgetPassword.scss";
.noPermission {
  img {
    width: 248px;
    height: 175px;
  }
  .tip {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
  .tip2 {
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
}
</style>
