<template>
  <div class="wp-card">
    <div class="card-title">{{ title }}</div>
    <div class="searchContent">
      <slot name="searchContent"></slot>
      <el-button @click="handlerSearch" class="wp-button wp-btn-icon" type="primary" icon="icon-sousuo" size="medium">搜索
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 卡片标题
    title: {
      type: String,
      required: true
    },
  },
  data () {
    return {

    }
  },
  methods: {
    handlerSearch () {
      this.$emit("handlerSearch")
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>