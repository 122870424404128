<template>
  <div>
    <div class="video-box" v-for="(item, index) in fileJson" :key="index">
      <el-image
        @click="handleClickItem"
        class="video-img"
        fit="cover"
        :src="item.path"
      >
      </el-image>
      <img
        @click="playVideo"
        class="play"
        src="~@/assets/images/bofang.png"
        alt="播放"
        style="width: 40px; height: 40px"
      />
    </div>

    <!-- 播放视频弹窗 -->
    <div class="wp-mask" v-show="videoVisible">
      <img
        @click="hideMask"
        src="~@/assets/images/guanbi.png"
        class="close"
        alt="关闭"
      />

      <video
        ref="video"
        class="video-player"
        controls
        width="250"
        :src="`/wpxt/file/ios/download/${fileJson[0].id}`"
        type="video/mp4"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WpVideo',
  props: {
    fileJson: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      videoVisible: false,
    }
  },
  methods: {
    /** 点击播放按钮回调 */
    playVideo () {
      this.videoVisible = true
    },

    handleClickItem () {
      this.$utils.hideImg()
    },

    /** 关闭遮罩层 */
    hideMask () {
      this.videoVisible = false
      this.$refs.video.pause()
    },
  },
}
</script>

<style lang="scss" scoped>
.video-box {
  margin-top: 8px;
}
</style>