<template>
  <wp-dialog @cancel="cancel" :title="isEidt?'编辑':'设为常用'" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="commonGroupIds" label="设为常用：" :rules="{ required: true, message: placeholder, trigger: 'blur' }">
          <el-select class="wp-select" filterable style="width: 100%" v-model="ruleForm.commonGroupIds" clearable placeholder="请选择所在分组" @keyup.enter.native="determine" multiple collapse-tags>
            <el-option v-for="item in commonGroupList" :key="item.commonId" :label="item.commonName" :value="item.commonId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { insertBatchUserAndGroup, insertBatchUser, wpCommonGroupList, updateCommonGroupUser } from '@/api/personnelTeam.js'
export default {
  name: 'addGroup',
  components: {},
  data () {
    return {
      title: "添加分组",// 添加分组，添加子分组，修改名称
      placeholder: '请选择所在分组',//输入分组名称,输入子分组名称
      dialogVisible: false,
      ruleForm: {
        commonGroupIds: []
      },
      rules: {
        // commonGroupIds: [
        //   { required: true, message: this.placeholder, trigger: 'blur' }
        // ]
      },
      groupinfo: {},
      isPosting: false,
      commonGroupList: []
    };
  },
  props: {
    parentID: {
      type: String,
      default: ''
    },
    currentID: {
      type: String,
      default: ''
    },
    userIds: {
      type: Array,
      default: () => []
    },
    userList: {
      type: Array,
      default: () => []
    },
    isEidt: {
      type: Boolean,
      default: () => false
    },
    isUpdateCommonGroup: { // 是否改组
      type: Boolean,
      default: () => false
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      this.wpCommonGroupList()
    },
    // 创建 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      // 改组
      if (this.isUpdateCommonGroup) return this.postUpdateBatch()
      //批量的
      if (this.userList.length > 0) return this.postAddBatch()

      // 单个的新增
      let query = {
        commonGroupIds: this.ruleForm.commonGroupIds,
        userId: this.userIds[0]
      }
      let res = null
      try {
        // 单个新增
        res = await insertBatchUser(query)
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    // 改组
    async postUpdateBatch () {
      let query = {
        commonGroupIds: this.ruleForm.commonGroupIds,
        userIds: this.userIds
      }
      try {
        const res = await updateCommonGroupUser(query)
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    // 获取常用人员分组
    async wpCommonGroupList () {
      try {
        let res = await wpCommonGroupList({})
        if (Number(res.returnCode) !== 200) return
        this.commonGroupList = res.returnData.map(it => { return { commonId: it.commonId, commonName: it.commonName } })
        this.$nextTick(() => {
          if (this.isEidt) {
            // this.ruleForm1.commonGroups = this.userObj.commonGroups ? this.userObj.commonGroups:[]
          }
        })
      } catch (error) {
        console.log('wpCommonGroupList', error)
      }
    },
    // 批量添加常用人员
    async postAddBatch () {
      let query = {
        commonGroupIds: this.ruleForm.commonGroupIds,
        users: this.userList
      }
      try {
        const res = await insertBatchUserAndGroup(query)
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate()
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
/deep/.el-select {
  .el-tag {
    display: flex;
    align-items: center;
    .el-select__tags-text {
      max-width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}
</style>