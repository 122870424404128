var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personalInformation" }, [
    _c("div", { staticClass: "layout-block wp-moudle-style" }, [
      _c("div", { staticClass: "layout-title" }, [_vm._v("个人信息")]),
      _c(
        "div",
        { staticClass: "layout-content" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm1",
              staticClass: "ruleform1",
              attrs: {
                model: _vm.ruleForm1,
                rules: _vm.rules1,
                "label-width": "100px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "登录账号：" } }, [
                _c("div", { staticClass: "login-name" }, [
                  _vm._v(_vm._s(_vm.sysUserInfo.sysUser.loginName)),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "人员姓名：" } },
                [
                  _c("div", { staticClass: "login-name" }, [
                    _vm._v(_vm._s(_vm.ruleForm1.userName)),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile", label: "手机号码：" } },
                [
                  _c("div", { staticClass: "verification-div" }, [
                    _c("div", { staticClass: "login-name" }, [
                      _vm._v(_vm._s(_vm.ruleForm1.mobile)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }