var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgetPassword" }, [
    _c("div", { staticClass: "noPermission flex-column-center" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/noPermission.png"), alt: "" },
      }),
      _c("p", { staticClass: "tip" }, [_vm._v("暂无访问权限")]),
      _c("p", { staticClass: "tip2" }, [
        _vm._v(
          "请联系管理员在属地媒体监管系统创建" + _vm._s(_vm.userName) + "用户"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }