var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.wpyUnitTabs.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.wpyUnitTabs,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "投稿列表" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: {
                            placeholder: "请输入任务标题",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "title", $$v)
                            },
                            expression: "searchParams.title",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "search-w190 wp-input",
                          attrs: {
                            placeholder: "请输入任务编号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.taskNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "taskNumber", $$v)
                            },
                            expression: "searchParams.taskNumber",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 任务数量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "taskNumber",
                                  "min-width": "80",
                                  label: "任务编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "10" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "title",
                                  label: "任务标题",
                                  "min-width": "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                tabindex: scope.$index,
                                                disabled:
                                                  !_vm.isShowTooltipWidth,
                                                content: scope.row.title,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "textOverOneLine active-color",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.visibilityChangeWidth(
                                                        $event
                                                      )
                                                    },
                                                    click: function ($event) {
                                                      return _vm.goDetail(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(scope.row.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  945873762
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "max-width": "50", "min-width": "10" },
                              }),
                              _vm.isWpys
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "left",
                                      prop: "custName",
                                      label: "来源单位",
                                      "min-width": "120",
                                      "max-width": "130",
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "websiteName",
                                  label: "任务站点",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "10", "max-width": "50" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "modeStyleValue",
                                  "show-overflow-tooltip": "",
                                  label: "任务方式",
                                  "min-width": "100",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "createTime",
                                  label: "发布时间",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "endTime",
                                  label: "结束时间",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(scope.row.endTime)
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1443035752
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "receiverStatus",
                                  label: "接收状态",
                                  "min-width": "90",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.receiverStatus == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "noreceive-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "noreceive-color",
                                                    },
                                                    [_vm._v("未接收")]
                                                  ),
                                                ]
                                              )
                                            : scope.row.receiverStatus == 2
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "receive-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "receive-color",
                                                    },
                                                    [_vm._v("已接收")]
                                                  ),
                                                ]
                                              )
                                            : scope.row.receiverStatus == 3
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "disabled-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "disabled-color",
                                                    },
                                                    [_vm._v("已完成")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  205283382
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }