var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "taskForm",
      staticClass: "taskForm",
      attrs: {
        model: _vm.taskForm,
        rules: _vm.taskFormRules,
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务方式：", prop: "modeStyle" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "wp-select task-w726",
              attrs: {
                multiple: "",
                "multiple-limit": 3,
                placeholder: "请选择任务方式",
              },
              model: {
                value: _vm.taskForm.modeStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.taskForm, "modeStyle", $$v)
                },
                expression: "taskForm.modeStyle",
              },
            },
            _vm._l(_vm.taskWayList, function (item) {
              return _c("el-option", {
                key: item.dictId,
                attrs: { label: item.dataKey, value: item.dataValue },
              })
            }),
            1
          ),
          _c("wp-question-mark", {
            staticStyle: { "margin-left": "6px" },
            attrs: { content: "支持多选，多种方式完成其中一种即可" },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务时间：", prop: "timeSection" },
        },
        [
          _c("div", { staticClass: "wp-date-picker task-w436" }, [
            _c(
              "div",
              { staticClass: "wp-date-picker-single task-w436" },
              [
                _c("el-date-picker", {
                  attrs: {
                    readonly: "",
                    "prefix-icon": "",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    clearable: "",
                    type: "datetime",
                    placeholder: "请选择开始时间",
                  },
                  model: {
                    value: _vm.taskForm.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.taskForm, "startTime", $$v)
                    },
                    expression: "taskForm.startTime",
                  },
                }),
                _c("span", { staticClass: "el-range-separator" }, [
                  _vm._v("-"),
                ]),
                _c("el-date-picker", {
                  attrs: {
                    "prefix-icon": "",
                    "default-time": "23:59:59",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    clearable: "",
                    type: "datetime",
                    placeholder: "请选择结束时间",
                    "picker-options": _vm.pickerOptionsDatetime,
                  },
                  on: { change: _vm.changeDatetime },
                  model: {
                    value: _vm.taskForm.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.taskForm, "endTime", $$v)
                    },
                    expression: "taskForm.endTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._l(_vm.configList.timeList, function (item) {
            return _c(
              "div",
              {
                key: item.custConfigId,
                staticClass: "time-box",
                class:
                  _vm.taskForm.timeRangeType == item.configName
                    ? "active-text"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.handlerTimeSection(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.configName) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "任务积分：", prop: "score" },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm._l(_vm.configList.scoreList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.configValue,
                    staticClass: "task-box",
                    class:
                      !_vm.taskForm.customScore &&
                      _vm.taskForm.score == item.configValue
                        ? "active-text"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.fastScore(item.configValue)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.configValue) + " ")]
                )
              }),
              _c("el-input", {
                staticClass: "custom-input wp-input",
                class: _vm.taskForm.customScore ? "custom-text" : "",
                attrs: { maxlength: "4", placeholder: "自定义" },
                on: { change: _vm.verifyScore },
                model: {
                  value: _vm.taskForm.customScore,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskForm, "customScore", $$v)
                  },
                  expression: "taskForm.customScore",
                },
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "字数要求：", prop: "minWord" },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm._l(_vm.configList.taskWordsRequire, function (item) {
                return _c(
                  "div",
                  {
                    key: item.configValue,
                    staticClass: "task-box",
                    class:
                      !_vm.taskForm.customWord &&
                      _vm.taskForm.minWord == item.configValue
                        ? "active-text"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.fastWord(item.configValue)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.configValue == "9999" ? "不限" : item.configValue
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              _c("el-input", {
                staticClass: "custom-input wp-input",
                class: _vm.taskForm.customWord ? "custom-text" : "",
                attrs: { placeholder: "自定义" },
                on: { input: _vm.verifyWord },
                model: {
                  value: _vm.taskForm.customWord,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskForm, "customWord", $$v)
                  },
                  expression: "taskForm.customWord",
                },
              }),
            ],
            2
          ),
          _c("wp-question-mark", {
            staticStyle: { "margin-left": "10px" },
            attrs: { content: "设置转发或评论时最少数字要求" },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "单人上限：", prop: "maxArticle" },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm._l(_vm.configList.taskSingleLimit, function (item) {
                return _c(
                  "div",
                  {
                    key: item.configValue,
                    staticClass: "task-box",
                    class:
                      !_vm.taskForm.customArticle &&
                      _vm.taskForm.maxArticle == item.configValue
                        ? "active-text"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.fastArticle(item.configValue)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.configValue == "9999" ? "不限" : item.configValue
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              _c("el-input", {
                staticClass: "custom-input wp-input",
                class: _vm.taskForm.customArticle ? "custom-text" : "",
                attrs: { placeholder: "自定义" },
                on: { input: _vm.verifyArticle },
                model: {
                  value: _vm.taskForm.customArticle,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskForm, "customArticle", $$v)
                  },
                  expression: "taskForm.customArticle",
                },
              }),
            ],
            2
          ),
          _c("wp-question-mark", {
            staticStyle: { "margin-left": "10px" },
            attrs: { content: "设置当前任务单个人员最多能提交的次数" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }