<template>
  <el-form
    class="taskForm"
    :model="taskForm"
    :rules="taskFormRules"
    ref="taskForm"
    label-width="100px"
  >
    <el-form-item class="item-content" label="任务方式：" prop="modeStyle">
      <el-select
        class="wp-select task-w726"
        multiple
        :multiple-limit="3"
        v-model="taskForm.modeStyle"
        placeholder="请选择任务方式"
      >
        <el-option
          v-for="item in taskWayList"
          :key="item.dictId"
          :label="item.dataKey"
          :value="item.dataValue"
        >
        </el-option>
      </el-select>
      <wp-question-mark
        style="margin-left: 6px"
        content="支持多选，多种方式完成其中一种即可"
      ></wp-question-mark>
    </el-form-item>

    <el-form-item class="item-content" label="任务时间：" prop="timeSection">
      <div class="wp-date-picker task-w436">
        <div class="wp-date-picker-single task-w436">
        <el-date-picker readonly prefix-icon="" value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.startTime" type="datetime" placeholder="请选择开始时间">
        </el-date-picker>
        <span class="el-range-separator">-</span>
        <el-date-picker prefix-icon="" default-time="23:59:59"  value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.endTime" type="datetime" placeholder="请选择结束时间" :picker-options="pickerOptionsDatetime" @change="changeDatetime">
        </el-date-picker>
      </div>
      </div>

      <div
        v-for="item in configList.timeList"
        :key="item.custConfigId"
        class="time-box"
        :class="taskForm.timeRangeType == item.configName ? 'active-text' : ''"
        @click="handlerTimeSection(item)"
      >
        {{ item.configName }}
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="任务积分：" prop="score">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !taskForm.customScore && taskForm.score == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configList.scoreList"
          :key="item.configValue"
          @click="fastScore(item.configValue)"
        >
          {{ item.configValue }}
        </div>

        <el-input
          :class="taskForm.customScore ? 'custom-text' : ''"
          @change="verifyScore"
          maxlength="4"
          class="custom-input wp-input"
          v-model="taskForm.customScore"
          placeholder="自定义"
        ></el-input>
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="字数要求：" prop="minWord">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !taskForm.customWord && taskForm.minWord == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configList.taskWordsRequire"
          :key="item.configValue"
          @click="fastWord(item.configValue)"
        >
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input
          :class="taskForm.customWord ? 'custom-text' : ''"
          @input="verifyWord"
          class="custom-input wp-input"
          v-model="taskForm.customWord"
          placeholder="自定义"
        ></el-input>
      </div>
      <wp-question-mark
        style="margin-left: 10px"
        content="设置转发或评论时最少数字要求"
      ></wp-question-mark>
    </el-form-item>

    <el-form-item class="item-content" label="单人上限：" prop="maxArticle">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !taskForm.customArticle && taskForm.maxArticle == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configList.taskSingleLimit"
          :key="item.configValue"
          @click="fastArticle(item.configValue)"
        >
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input
          :class="taskForm.customArticle ? 'custom-text' : ''"
          @input="verifyArticle"
          class="custom-input wp-input"
          v-model="taskForm.customArticle"
          placeholder="自定义"
        ></el-input>
      </div>
      <wp-question-mark
        style="margin-left: 10px"
        content="设置当前任务单个人员最多能提交的次数"
      ></wp-question-mark>
    </el-form-item>
  </el-form>
</template>

<script>
import { resetNumPointOne } from '@/utils/validate.js';
export default {
  props: {
    // 任务方式列表
    formData: {
      type: Object,
      default: () => { }
    },
    configObj: {
      type: Object,
      default: () => { }
    },
    taskStyleList: Array
  },
  watch: {
    // 任务方式列表
    taskStyleList: {
      handler (newVal) {
        this.taskForm.taskWayList = newVal
        // 任务方式Id列表
        this.taskForm.taskWayIds = newVal.map(item => {
          return item.dataValue
        })
        this.taskWayList = newVal

        // 置空原先选择的任务方式
        this.$set(this.taskForm, 'taskWayList', newVal)
        this.$set(this.taskForm, 'modeStyle', [])
      },
      deep: true
    },
    // 表单数据
    formData: {
      handler (newVal) {
        // 任务方式
        this.taskWayList = newVal.taskWayList

        this.taskForm = { ...newVal }

        // 有开始时间就不再获取新时间了
        if (newVal.startTime) {
          this.setTimeValue(newVal)
        } else {
          this.handlerTimeSection(newVal.timeItem)
        }
      },
      deep: true,
    },
    // 任务配置项
    configObj: {
      handler (newVal) {
        // 拼接时间快捷项名称
        newVal.timeList.forEach(item => {
          if (item.configUnitStr) {
            item.configName = item?.configValue + item?.configUnitStr
          }
        })

        this.$nextTick(() => {
          // 匹配任务积分
          const scoreValue = newVal.scoreList.findIndex(it => it.configValue == this.taskForm.score)
          if (scoreValue == -1) {
            this.taskForm.customScore = this.taskForm.score
          }

          // 匹配字数要求
          const wordValue = newVal.taskWordsRequire.findIndex(it => it.configValue == this.taskForm.minWord)
          if (wordValue == -1) {
            this.taskForm.customWord = this.taskForm.minWord
          }

          // 匹配单人上限
          const articleValue = newVal.taskSingleLimit.findIndex(it => it.configValue == this.taskForm.maxArticle)
          if (articleValue == -1) {
            this.taskForm.customArticle = this.taskForm.maxArticle
          }
        })

        // 配置列表
        this.configList = newVal
      },
      deep: true
    }
  },

  data () {
    return {
      taskForm: {
        modeStyle: [], // 任务方式
        timeSection: "", // 时间区间
        startTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"), // 开始时间
        endTime: "", // 结束时间
        score: "", // 任务积分
        minWord: "", // 字数要求
        maxArticle: "", // 单人上限
        timeRangeType: "", // 时间快捷项
      },
      activeTime: "",
      taskWayList: [], // 任务方式
      timeList: [],
      imageUrl: "",
      taskFormRules: {
        modeStyle: [{ required: true, trigger: "blur", message: "请选择任务方式" }],
        timeSection: [{ required: true, trigger: "blur", message: "请选择起止时间" }],
        score: [{ required: true, trigger: ["blur", "change"], message: "请选择任务积分" }],
        minWord: [{ required: true, trigger: ["blur", "change"], message: "请选择字数" }],
        maxArticle: [{ required: true, trigger: ["blur", "change"], message: "请选择单人上限" }],
      },
      classifyList: [], // 任务分类列表
      configList: [], // 配置列表
      pickerOptionsDatetime:{
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  created () {

  },

  methods: {
    // 校验自定义分数
    verifyScore (e) {
      this.taskForm.score = resetNumPointOne(e, 0, 100)
      this.taskForm.customScore = this.taskForm.score
    },

    // 积分快捷选项回调
    fastScore (score) {
      this.taskForm.score = score
      this.taskForm.customScore = ""
      this.$refs['taskForm'].fields[2].validateState = 'success'
    },

    // 校验自定义字数
    verifyWord (e) {
      if (e > 100) {
        this.taskForm.minWord = 100
      } else if (e < 0) {
        this.taskForm.minWord = 1
      } else {
        this.taskForm.minWord = e.replace(/[^\d]/g, '')
      }
      this.taskForm.customWord = this.taskForm.minWord
    },

    // 字数要求快捷选项回调
    fastWord (minWord) {
      this.taskForm.minWord = minWord
      this.taskForm.customWord = ""
      this.$refs['taskForm'].fields[3].validateState = 'success'
    },

    // 校验单人上限
    verifyArticle (e) {
      const value = e.replace(/[^\d]/g, '')
      if (value > 100) {
        this.taskForm.maxArticle = 100
      } else if (value < 0) {
        this.taskForm.maxArticle = 1
      } else {
        this.taskForm.maxArticle = value
      }
      this.taskForm.customArticle = this.taskForm.maxArticle
    },

    // 字数要求快捷选项回调
    fastArticle (maxArticle) {
      this.taskForm.maxArticle = maxArticle
      this.taskForm.customArticle = ""
      this.$refs['taskForm'].fields[4].validateState = 'success'
    },

    /** 设置任务时间 */
    setTimeValue (formData) {
      this.taskForm.timeRangeType = formData?.timeRangeType
      this.$nextTick(() => {
        this.$set(this.taskForm, 'timeSection', [formData.startTime, formData.endTime])
      })
    },

    // 选择快捷项回调
    handlerTimeSection (item) {
      if (item.configName == '月底') {
        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().endOf('month').format('yyyy-MM-DD HH:mm:ss')

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm.startTime
        this.taskForm.timeSection[1] = this.taskForm.endTime
      } else {
        let unit = ""
        if (item.configUnit == 1) {
          unit = "hours"
        } else {
          unit = "days"
        }

        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().add(+item.configValue, unit).format("YYYY-MM-DD HH:mm:ss")

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm?.startTime
        this.taskForm.timeSection[1] = this.taskForm?.endTime
      }

      this.$refs['taskForm'].fields[1].validateState = 'success'
      // 重置默认选中项
      this.taskForm.timeItem = item
      // 时间快捷项名称
      this.taskForm.timeRangeType = item.configName
    },

    // 提交表单
    getFormData () {
      return this.taskForm
    },

    // 清除表单校验
    clearValidate () {
      this.$refs.taskForm.clearValidate()
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.taskForm.resetFields()
    },
    changeDatetime(val){
      this.taskForm.timeRangeType = ""
      this.$forceUpdate()
      if (val) {
         // 开始时间限制为当前时间
        let startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        let endTime = val
        if(this.$moment(endTime).diff(this.$moment(startTime)) < 0){
          endTime= startTime
        }
        this.taskForm.timeSection = [startTime,endTime]
        this.taskForm.startTime =startTime
        this.taskForm.endTime = endTime
      } else {
        this.taskForm.timeSection = []
        this.taskForm.endTime = ""
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>