var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "wp-task-card mt-16" },
      [
        _c("div", { staticClass: "card-title fs-16 fw-bold" }, [
          _vm._v("基础设置"),
        ]),
        _c("BasicsForm", {
          ref: "basicsForm",
          staticClass: "center",
          attrs: { taskDetial: _vm.taskDetial, isEidt: _vm.isEidt },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }