<template>
  <div class="wp-drill-dialog resultView">
    <el-dialog
      title=""
      custom-class="drill-dialog"
      :visible.sync="dialogVisible"
      width="1324px"
      top="2vh"
      :before-close="handleClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="dialog-body">
        <div @click="handleClose" class="close-btn" v-if="!loading"></div>
        <div class="dialog-body-content" :class="bodyclasses" v-if="!loading">
          <div class="dialog-top flex-center">
            <div class="dialog-top-content flex-CB">
              <div class="dialog-top-item red-item">
                <div class="num-data flex-center flex-column-JC">
                  <div class="flex-EC label-value">
                    <p>{{ aTeamScore }}</p>
                  </div>
                  <p class="label">红方</p>
                </div>
              </div>
              <div class="dialog-top-mid"></div>
              <div class="dialog-top-item blue-item">
                <div class="num-data flex-center flex-column-JC">
                  <div class="flex-EC label-value">
                    <p>{{ bTeamScore }}</p>
                  </div>
                  <p class="label">蓝方</p>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-mid flex-CB">
            <div
              class="dialog-item flex-column"
              v-for="item in scoreList"
              :key="item.name"
            >
              <div class="item-title">{{ item.name }}</div>
              <div class="flex">
                <div class="label-item label-item-red">
                  <div class="num-data flex-center flex-column-JC">
                    <div class="flex-EC label-value">
                      <p>{{ item.aScore }}</p>
                    </div>
                    <p class="label">红方</p>
                  </div>
                </div>
                <div class="label-item label-item-blue">
                  <div class="num-data flex-center flex-column-JC">
                    <div class="flex-EC label-value">
                      <p>{{ item.bScore }}</p>
                    </div>
                    <p class="label">蓝方</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-btns flex-center">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="得分TOP5" name="1"></el-tab-pane>
              <el-tab-pane label="热评TOP5" name="2"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="table-block" v-if="activeName == '1'">
            <el-scrollbar style="height: 100%">
              <div
                class="drill-table"
                v-loading="itemLoading"
                element-loading-spinner="el-icon-loading"
              >
                <el-table :data="tableData" stripe style="width: 100%">
                  <el-table-column
                    prop="date"
                    label="排名"
                    align="center"
                    min-width="20"
                  >
                    <template slot-scope="scope">
                      <span
                        class="bgIndex"
                        :style="'background-image: url(' + jinPai + ');'"
                        v-if="scope.row.index == 1"
                      >
                        {{ scope.row.index }}
                      </span>
                      <span
                        class="bgIndex"
                        :style="'background-image: url(' + yinPai + ');'"
                        v-else-if="scope.row.index == 2"
                      >
                        {{ scope.row.index }}
                      </span>
                      <span
                        class="bgIndex"
                        :style="'background-image: url(' + dong + ');'"
                        v-else-if="scope.row.index == 3"
                      >
                        {{ scope.row.index }}
                      </span>
                      <span style="color: #ffc000" v-else>{{
                        scope.row.index
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="typeName"
                    label="队伍"
                    align="center"
                    min-width="20"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="nickName"
                    label="微博昵称"
                    align="center"
                    show-overflow-tooltip
                    min-width="20"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="realName"
                    label="姓名"
                    min-width="20"
                    align="center"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column label="得分" align="center" min-width="20">
                    <template slot-scope="scope">
                      <div class="score">{{ scope.row.score }}</div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="wp-noData" v-if="tableData.length == 0">
                  <img src="~@/assets/images/noData.png" alt="" />
                  <p>暂无内容</p>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="table-block" v-if="activeName == '2'">
            <el-scrollbar style="height: 100%">
              <div
                class="drill-list"
                v-loading="itemLoading"
                element-loading-spinner="el-icon-loading"
              >
                <div class="list-box">
                  <ul>
                    <li v-for="item in topComments" :key="item.id" class="flex">
                      <div class="li-left">
                        <el-image :src="item.imgUrl" alt="">
                          <div slot="error">
                            <img :src="$globalData.failAvatar" alt="" />
                          </div>
                        </el-image>
                      </div>
                      <div class="li-right">
                        <div class="li-right-top flex-CB">
                          <div class="title flex">
                            <p>{{ item.nickName }}</p>
                            <span
                              class="title-label red"
                              v-if="item.typeId == 1"
                              >红</span
                            >
                            <span
                              class="title-label blue"
                              v-if="item.typeId == 2"
                              >蓝</span
                            >
                          </div>
                          <div class="time">{{ item.createTime }}</div>
                        </div>
                        <div class="desc">
                          {{ item.content }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="wp-noData" v-if="topComments.length == 0">
                  <img src="~@/assets/images/noData.png" alt="" />
                  <p>暂无内容</p>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div v-if="loading" class="dalog-box">
          <div class="wrapper">
            <div class="block">
              <div class="countDown-img"></div>
              <div class="countDown-text">
                {{ timeNum }}
              </div>
            </div>
            <p>正在计算结果</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import * as  drillApi from '@/api/drill.js'
export default {
  name: 'resultView',
  components: {},
  data () {
    return {
      dialogVisible: false,
      activeName: '1',
      tableData: [],
      // tableData3: [{ index: 1 }, { index: 2 }, { index: 3 }, { index: 4 }, { index: 5 }],
      jinPai: require('../../../assets/images/drill/jin.png'),
      yinPai: require('../../../assets/images/drill/yin.png'),
      dong: require('../../../assets/images/drill/tong.png'),
      itemLoading: false,
      aTeamScore: 0,
      bTeamScore: 0,
      scoreList: [],
      topComments: [],// 热评
      victoryId: 0,// 0 平局，1红方，2蓝方
      loading: true,
      timeNum: 30,//进度条最终值
      timeNumTotal: 30,//进度条总值
      percentage: 0,
      loadingTimer: null
    };
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    endTime: {
      type: Number,
      default: 0
    },
    curSysDate: {
      type: Number,
      default: 0
    },
    isPlayback: {
      type: Boolean,
      default: false
    },
  },
  created () {

  },
  mounted () {

  },
  computed: {
    bodyclasses () {
      return {
        'red': this.victoryId == 1, 'blue': this.victoryId == 2, 'draw': this.victoryId == 0
      }
    }
  },
  methods: {
    show () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.$emit('handleClose')
    },
    handleClick (name) {
      console.log(name)
    },
    settimeNum (num) {
      this.timeNum = 30 - num
      // 测试
      // this.timeNum = 30
      // 如果还在倒计时的时间内就显示倒计时
      if (this.timeNum > 0) {
        this.loading = true
        this.clearIntervalLoading()
        this.loadingTimer = setInterval(() => {
          this.timeNum--;
          this.timeNum = this.timeNum <= 0 ? 0 : this.timeNum
          console.log(' this.timeNum', this.timeNum)
          this.percentage = Number(this.$utils.getPercent(30 - this.timeNum, 30));
          if (this.timeNum <= 0) {
            this.setValues();
            this.clearIntervalLoading()
          }
        }, 1000);
      } else {
        this.setValues();
      }
    },
    init () {
      // 服务器当前时间 - 任务结束时间
      let differtime = Math.floor((this.curSysDate - this.endTime) / 1000)
      this.settimeNum(differtime)
      //this.settimeNum(0)
    },
    clearIntervalLoading () {
      if (this.loadingTimer) {
        clearInterval(this.loadingTimer);
        this.loadingTimer = null;
      }
    },
    setValues () {
      this.loading = false
      // 获取比分
      this.getScore()
      // 获取评论数
      // 获取得分top5
      this.getHotCommentRankingList()
      this.getArticleList()
      // 获取 热评 top5
    },
    //获取比分统计
    async getScore () {
      const res = await drillApi.taskDetail({ taskId: this.taskId });
      if (res.returnCode != 200) return;
      let aTeam = res.returnData.aTeam
      let bTeam = res.returnData.bTeam
      this.setScore(aTeam, bTeam)
    },
    setScore (aTeam, bTeam) {
      this.aTeamScore = aTeam.score.toFixed(1);
      this.bTeamScore = bTeam.score.toFixed(1);
      if (this.aTeamScore - this.bTeamScore > 0) {
        this.victoryId = 1
      } else if (this.aTeamScore - this.bTeamScore < 0) {
        this.victoryId = 2
      } else if (this.aTeamScore - this.bTeamScore == 0) {
        this.victoryId = 0
      }
      // console.log(this.victoryId)
      let scoreList = [
        { name: "热评数", aScore: aTeam.hotComments, bScore: bTeam.hotComments },
        { name: "响应人数", aScore: aTeam.responses, bScore: bTeam.responses },
        { name: "评论数", aScore: aTeam.comments, bScore: bTeam.comments },
        { name: "转发数", aScore: aTeam.forward, bScore: bTeam.forward },
        { name: "点赞数", aScore: aTeam.thumbs, bScore: bTeam.thumbs }]
      this.scoreList = scoreList
    },
    //红蓝热评列表、弹幕列表、结算页热评Top5
    async getArticleList () {
      let query = {
        taskId: this.taskId,
        pageNo: 1,
        limit: 5,
        // type: '' //队伍 1红 2蓝 不传为全部
      }
      const res = await drillApi.articleList(query);
      if (res.returnCode != 200) return;
      this.topComments = this.$utils.handleAvatar(res.returnData.data)
    },
    //1.实时弹幕页中的红蓝Top3
    //2.结算弹窗中的得分TOP5
    async getHotCommentRankingList () {
      const res = await drillApi.getHotCommentRankingList({ taskId: this.taskId });
      if (res.returnCode != 200) return;
      this.tableData = res.returnData.splice(0, 5).map((item, index) => {
        return { ...item, index: index + 1, typeName: item.typeId == 1 ? '红队' : '蓝队' }
      })
    },


  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.init()
        } else {
          setTimeout(() => {
            this.$reset('', [''])
          }, 100)
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import '../css/resultView.scss';
</style>
