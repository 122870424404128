<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="wpyUnitTabs.length > 1">
        <div class="title-header-left">
          <UnitTab :tabsList="wpyUnitTabs" :activeId="activeName" @handleClick="handleClick"></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="投稿列表" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <el-input class="search-w392 wp-input" v-model="searchParams.title" placeholder="请输入任务标题" clearable></el-input>

            <el-input class="search-w190 wp-input" v-model="searchParams.taskNumber" placeholder="请输入任务编号" clearable></el-input>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                任务数量：<span class="wp-blue">{{ total }}</span>
              </div>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column align="center" prop="taskNumber" min-width="80" label="任务编号">
              </el-table-column>
              <el-table-column max-width="50" min-width="10"> </el-table-column>
              <el-table-column align="left" prop="title" label="任务标题" min-width="160">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :tabindex="scope.$index" :disabled="!isShowTooltipWidth" :content="scope.row.title" placement="top">
                    <div class="textOverOneLine active-color" @mouseenter="visibilityChangeWidth($event)" @click="goDetail(scope.row)">
                      {{ scope.row.title }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column max-width="50" min-width="10"> </el-table-column>
              <el-table-column v-if="isWpys" align="left" prop="custName" label="来源单位" min-width="120" max-width="130" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="websiteName" label="任务站点" min-width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column min-width="10" max-width="50"> </el-table-column>
              <el-table-column align="left" prop="modeStyleValue" show-overflow-tooltip label="任务方式" min-width="100">
              </el-table-column>
              <el-table-column align="center" prop="createTime" label="发布时间" min-width="150">
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="endTime" label="结束时间" min-width="150">
                <template slot-scope="scope">
                  <div>
                    {{ $moment(scope.row.endTime).format('YYYY-MM-DD HH:mm') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="receiverStatus" label="接收状态" min-width="90">
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.receiverStatus == 1">
                    <div class="noreceive-circle"></div>
                    <div class="noreceive-color">未接收</div>
                  </div>
                  <div class="flex-center" v-else-if="scope.row.receiverStatus == 2">
                    <div class="receive-circle"></div>
                    <div class="receive-color">已接收</div>
                  </div>
                  <div class="flex-center" v-else-if="scope.row.receiverStatus == 3">
                    <div class="disabled-circle"></div>
                    <div class="disabled-color">已完成</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import wpyTaskApi from '@/api/wpyTask.js'
import { mapGetters, mapState, mapMutations } from 'vuex';
import UnitTab from '../components/unitTab/unitTab.vue';

import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { SearchCard, TaskTimeSelect, UnitTab },
  data () {
    return {
      activeName: "0",
      total: 0,
      searchParams: {
        page: 1,
        limit: 30,
        title: "", // 任务标题
        taskNumber: "", // 任务编号
        custId: "", // 单位Id
      },
      tableData: [],
      isShowTooltipWidth: false,
      loading: false
    }
  },
  created () {

  },
  mounted () {
    this.activeName = this.wpyUnitTabs[0].custId
    this.searchParams.custId = this.activeName
    console.log(this.activeName);

    // 获取任务列表
    this.getTaskList()
  },
  computed: {
    ...mapGetters(['userList', 'userInfo', 'isWpys', 'wpyUnitTabs']),
  },
  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    /** tab点击回调 */
    handleClick (tab) {
      // 恢复初始值
      this.searchParams.taskNumber = ""
      this.searchParams.title = ""
      this.searchParams.page = 1
      this.searchParams.limit = 30

      this.searchParams.custId = tab
      // 获取任务列表
      this.getTaskList()
    },

    /** 搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getTaskList()
    },

    /** 获取任务列表 */
    async getTaskList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyTaskApi.getTaskList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data?.map(item => {
            item.modeStyleValue = item.modeStyleList.map(it => it.dictKey).join(',')
            // 已完成
            item.receiverStatus = item.isArticle ? 3 : item.receiverStatus
            return item
          })
          this.total = ret.totalCount
        }
      } catch (error) {
        this.loading = false
      }

    },

    /** 跳转详情 */
    goDetail (item) {
      this.$router.push({
        path: "/taskCenterDetail",
        query: {
          taskId: item.taskId,
          taskReceiverId: item.taskReceiverId
        }
      })
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTaskList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTaskList()
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>