<template>
  <div class="basicsForm">
    <el-form
      ref="basicsForm"
      :model="basicsForm"
      :rules="basicsFormRules"
      label-width="100px"
    >
      <el-form-item class="item-content" label="任务分类：" prop="taskTypeId">
        <el-select
          v-model="basicsForm.taskTypeId"
          placeholder="请选择任务分类"
          class="wp-select task-w726"
        >
          <el-option
            v-for="item in taskClassifyList"
            :key="item.taskTypeId"
            :label="item.taskTypeName"
            :value="item.taskTypeId"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="item-content" label="任务链接：" prop="url">
        <el-input
          @change="changeUrl"
          maxlength="2000"
          class="wp-input task-w726"
          v-model.trim="basicsForm.url"
          placeholder="请输入任务链接"
        ></el-input>
      </el-form-item>

      <el-form-item class="item-content" label="任务标题：" prop="title">
        <el-input
          @input="updateTitle"
          class="wp-input task-w726"
          v-model.trim="basicsForm.title"
          placeholder="请输入任务标题"
        ></el-input>
      </el-form-item>

      <el-form-item label="任务要求：" prop="content">
        <el-input
          class="task-w726 textarea"
          type="textarea"
          maxlength="1000"
          :rows="6"
          v-model="basicsForm.content"
          placeholder="请输入任务要求和口径"
        ></el-input>
      </el-form-item>

      <QquickLanguage
        ref="quickRef"
        @addQuick="addQuick"
        :shortcutList="shortcutList"
        @clickShortcut="clickShortcut"
        @removeQuick="removeQuick"
        style="margin-left: 100px; margin-top: 22px"
      >
        <div class="add-shortcut" v-if="shortcutList.length < 3">
          <i class="el-icon-plus"></i>
          <div>添加快捷语</div>
        </div>
      </QquickLanguage>
      <el-form-item label="任务备注：" style="margin-top: 22px;">
      <el-input
        class="task-w726 textarea task-notes"
        type="textarea"
        maxlength="500"
        v-model.trim="basicsForm.remark"
        resize="none"
        placeholder="请输入任务任务备注"
      ></el-input>
    </el-form-item>
    </el-form>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import { validTaskURL } from '@/utils/validate.js';
import QquickLanguage from '@/views/components/quickLanguage'
export default {
  components: { QquickLanguage },
  props: {
    formData: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    formData: {
      handler (newVal) {
        this.basicsForm = { ...newVal }
      },
      deep: true
    }
  },

  data () {
    var validateUrl = (rule, value, callback) => {
      if (value === '') {
        this.urlFlag = false
        callback(new Error('请输入任务链接'));
      } else if (!validTaskURL(value)) {
        this.urlFlag = false
        callback(new Error('请输入有效任务链接'));
      } else {
        this.urlFlag = true
        callback();
      }
    };

    return {
      basicsForm: {
        taskTypeId: "", // 分类名称
        title: "", // 任务标题
        url: "", // 任务链接
        content: "", // 任务要求
        remark:'' // 任务备注
      },
      urlFlag: false, // 链接是否通过校验
      shortcutList: [], // 快捷语列表
      basicsFormRules: {
        taskTypeId: [{ required: true, trigger: "blur", message: "请选择任务分类" }],
        title: [{ required: true, trigger: "blur", message: "请输入任务标题" }],
        url: [
          { required: true, trigger: "change", message: "请输入任务链接" },
          { validator: validateUrl, trigger: 'change' }
        ],
        content: [{ required: true, trigger: ["blur", "change"], message: "请填写任务要求" }],
      },
      taskClassifyList: [], // 任务分类列表
    }
  },

  created () {
    // 获取任务分类
    this.getTaskClassify()
    // 获取快捷语
    this.getQuickLanguage()
  },

  methods: {
    // 修改标题
    updateTitle () {
      this.$emit("updateTitle", this.basicsForm.title)
    },

    // 清除表单校验
    clearValidate () {
      this.$refs.basicsForm.clearValidate()
    },

    // 提交表单
    getFormData () {
      return this.basicsForm
    },

    // 监听链接输入框
    async changeUrl () {
      if (this.urlFlag) {
        const res = await taskApi.getWebsite({ url: this.basicsForm.url })
        if (res.returnCode == 200) {
          const ret = res?.returnData

          // 站点名称
          this.basicsForm.websiteName = ret?.sysDict?.dataKey
          // 站点Id
          this.basicsForm.websiteId = ret?.sysDict?.dataValue
          // 任务方式列表(Vuex)
          this.$emit("getStyleList", ret?.styleList)

          if ((ret.website ?? '') != '') {
            // 任务标题
            this.basicsForm.title = ret?.website?.title

            // 修改标题
            this.updateTitle()
          } else {
            this.$showMessage({ type: 'warning', msg: '未获取到文章标题，请手动填写' })
          }
        }
      }
    },

    /** 点击快捷语 */
    clickShortcut (remark) {
      this.$set(this.basicsForm, 'content', remark)
    },

    /** 获取任务分类 */
    async getTaskClassify () {
      const res = await taskApi.taskTypeList({})
      if (res.returnCode == 200) {
        // 任务分类列表
        this.taskClassifyList = res.returnData || []
      }
    },

    // 获取快捷语列表
    async getQuickLanguage () {
      const res = await taskApi.remarkList()
      if (res.returnCode == 200) {
        this.shortcutList = res.returnData
      }
    },

    // 添加快捷语
    async addQuick (remark) {
      const res = await taskApi.remarkInsert({ remark })
      if (res.returnCode == 200) {
        this.getQuickLanguage()
        this.$refs.quickRef.hide()
      }
    },

    // 删除快捷语
    async removeQuick (item) {
      const { taskMainRemarkId } = item
      const res = await taskApi.remarkDelete({ taskMainRemarkId })
      if (res.returnCode == 200) {
        this.getQuickLanguage()
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>