<template>
  <div class="sidebar-nav">
    <el-menu ref="menuRef" :default-active="defaultActive" router :default-openeds="defaultOpeneds" :collapse-transition="false" mode="vertical" @open="handleOpen" @close="handleClose" @select="menuSelect" active-text-color="#0064E6">
      <sidebar-item v-for="(route, index) in permission_routes" :key="route.path + index" :item="route" :base-path="route.path" />
    </el-menu>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import SidebarItem from '@/layout/Sidebar/SidebarItem.vue';
export default {
  name: 'panel',
  components: { SidebarItem },
  data () {
    return {
      defaultOpeneds: []
    };
  },
  props: {},
  created () {


  },
  mounted () {
    //侧边导航：管理员端（除“投稿中心”）模块均收起，投稿员端均展开
    if (this.roleId == 1) {// 超级管理员 展开
      this.defaultOpeneds = ['/systemSettings']
    } else
      if (this.roleId == 2) {
        this.defaultOpeneds = ['/taskManagement']
      } else {
        this.defaultOpeneds = this.permission_routes.map(it => it.path)
      }
    console.log('this.$refs.menuRef',this.$refs.menuRef)
  },
  computed: {
    ...mapGetters(['permission_routes', 'visible_tabs', 'roleId']),
    defaultActive () {
      const route = this.$route
      const { meta, path } = route
      if (meta.pPath && meta.pPath !== '/') {
        return meta.pPath
      }
      return path
    }
  },
  methods: {
    handleOpen () { },
    handleClose () { },
    //  菜单高亮
    menuSelect (index) {
      if (!this.visible_tabs.some(tab => index.includes(tab))) {
        this.$wpConfirm('提示', {
          tips: "暂未开通",
          content: "该菜单功能暂未开通",
          yesBtnText: '确认',
          isShowCancelBtn: false //是否显示取消按钮
        }).then(() => {
          console.log("确认")
        }).catch(() => {
          console.log("取消")
        });
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        console.log(val, oldval);
        if (val.name == 'login') return
        if (val.meta.pPath && val.meta.pPath !== '/') {
          this.menuSelect(val.meta.pPath);
        } else {
          this.menuSelect(val.path);
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.sidebar-nav {
  color: #666666;

  /deep/.el-menu-item {
    display: flex;
    align-items: center;

    .titleName {
      margin-left: 14px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }

    //给父元素加样式 （父元素超范围隐藏，正好把原图标的隐藏掉，显示阴影区域）
    overflow: hidden;

    .svg-icon {
      // filter: drop-shadow(#666666 80px 0);
      // transform: translateX(-80px);
    }

    &.is-active {
      color: #666666 !important;
    }
  }

  /deep/.el-submenu__title {
    display: flex;
    align-items: center;
    //给父元素加样式 （父元素超范围隐藏，正好把原图标的隐藏掉，显示阴影区域）
    overflow: hidden;

    .titleName {
      margin-left: 14px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }

    .svg-icon {
      // fill: #0064E6 !important;
      // stroke: #0064E6 !important;
      // filter: drop-shadow(#666666 80px 0);
      // transform: translateX(-80px);
    }
  }

  // 没有子节点的 高亮样式
  /deep/.el-menu {
    border-right: none;
    .el-menu-item.submenu-title-noDropdown {
      height: 50px;
    }
    .router-link-exact-active.router-link-active {
      .el-menu-item.submenu-title-noDropdown {
        color: #0064e6;
        background: rgba(3, 109, 255, 0.1);
        color: #0064e6 !important;
        position: relative;
        height: 50px;

        .titleName {
          color: #0064e6;
        }

        &::after {
          content: "";
          display: block;
          width: 3px; /*no */
          height: 50px;
          background: #0064e6;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }

    .el-menu-item.is-active.submenu-title-noDropdown {
      color: #0064e6;
      background: rgba(3, 109, 255, 0.1);
      color: #0064e6 !important;
      position: relative;
      height: 50px;
      .titleName {
        color: #0064e6;
      }
      &::after {
        content: "";
        display: block;
        width: 3px; /*no */
        height: 50px;
        background: #0064e6;
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }

  // 子节点的样式
  /deep/.nest-menu {
    .el-menu-item {
      .titleName {
        margin-left: 14px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  // 有子节点的 样式
  /deep/.el-submenu {
    .el-submenu__title {
      height: 50px;
    }

    .el-menu-item {
      width: 100%;
      padding-left: 45px !important;
      box-sizing: border-box;
    }
  }

  /deep/.el-submenu.is-active {
    .el-submenu__title {
      color: #0064e6 !important;
      position: relative;
      height: 50px;

      .titleName {
        color: #0064e6;
      }
    }
  }

  // hover 效果
  /deep/.el-menu-item:hover {
    background: rgba(3, 109, 255, 0.1);
    color: #0064e6 !important;
    .titleName {
      color: #0064e6 !important;
    }
  }
  /deep/.el-submenu__title:hover {
    background: rgba(3, 109, 255, 0.1);
    color: #0064e6 !important;
    .titleName {
      color: #0064e6 !important;
    }
  }

  // 子节点的高亮样式
  /deep/.nest-menu {
    .el-menu-item.is-active {
      .titleName {
        color: #0064e6 !important;
      }
      background: rgba(3, 109, 255, 0.1);
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 3px; /*no */
        height: 50px;
        background: #0064e6;
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }

    .router-link-exact-active.router-link-active {
      .el-menu-item.is-active {
        background: rgba(3, 109, 255, 0.1);
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 3px; /*no */
          height: 50px;
          background: #0064e6;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
      // .el-menu-item {
      //   background: rgba(3, 109, 255, 0.1);
      //   position: relative;
      //   &::after {
      //     content: "";
      //     display: block;
      //     width: 3px; /*no */
      //     height: 50px;
      //     background: #0064e6;
      //     position: absolute;
      //     right: 0px;
      //     top: 0px;
      //   }
      // }
    }
  }
  /deep/.el-submenu .el-menu-item {
    height: 50px;
    line-height: 50px;
    min-width: 0px;
  }
}
</style>
