<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/taskManagement' }"
            >投稿管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <div class="task-head">
            <div class="task-state" v-if="taskDetail.taskStatus == 1">
              已发布
            </div>
            <div class="finsh-state" v-else>已结束</div>
            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{ taskDetail.title }}
            </div>
          </div>

          <div class="task-info mt-20 fs-14">
            <div class="flex">
              <div class="task-item">
                任务编号：<span>{{ taskDetail.taskNumber }}</span>
              </div>
              <div class="task-item">
                任务类型：<span>{{ taskDetail.taskStyleStr }}</span>
              </div>
              <div class="task-item">
                任务站点：<span>{{ taskDetail.websiteName }}</span>
              </div>
              <div class="task-item" style="flex: 2">
                任务方式：<span>{{ taskDetail.dictKey }}</span>
              </div>
            </div>
            <div class="mt-20 flex">
              <div class="task-item">
                开始时间：<span>{{
                  $moment(taskDetail.startTime).format('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <div class="task-item">
                结束时间：<span class="orange">{{
                  $moment(taskDetail.endTime).format('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <div class="task-item">
                任务积分：<span class="orange">{{ taskDetail.score }}</span>
              </div>
              <div class="task-item">
                单人上限：<span>{{
                  taskDetail.maxArticle == 9999 ? '不限' : taskDetail.maxArticle
                }}</span>
              </div>
              <div class="task-item">
                字数要求：<span>{{
                  taskDetail.minWord == 9999 ? '不限' : taskDetail.minWord
                }}</span>
              </div>
            </div>
            <div class="mt-20 task-item flex" v-if="taskDetail.url">
              <div style="min-width: 73px">任务链接：</div>
              <div
                class="wp-blue textOverOneLine"
                @click="$utils.download(taskDetail.url)"
              >
                {{ taskDetail.url }}
              </div>
            </div>
            <div class="mt-20 task-item flex" v-if="taskDetail.author">
              <span class="key-name">视频号：</span
              ><span>{{ taskDetail.author }}</span>
            </div>
            <div class="mt-12 task-item" v-if="taskDetail.content">
              <div class="work-content">
                <div class="key">任务要求：</div>
                <p class="value">{{ taskDetail.content }}</p>
              </div>
            </div>
            <div class="mt-12 task-item" v-if="taskDetail.remark">
              <div class="work-content">
                <div class="key">任务备注：</div>
                <p class="value">{{ taskDetail.remark }}</p>
              </div>
            </div>

            <div
              class="mt-12 task-item"
              v-if="
                taskDetail.fileJson &&
                taskDetail.fileJson.length &&
                taskDetail.taskStyle == 2
              "
            >
              图片视频：
              <File
                style="margin-top: 8px"
                :fileJson="taskDetail.fileJson"
              ></File>
            </div>
            <div
              class="mt-12 task-item"
              v-else-if="
                taskDetail.fileJson &&
                taskDetail.fileJson.length &&
                taskDetail.taskStyle == 3
              "
            >
              目标图片：
              <File
                style="margin-top: 8px"
                :fileJson="taskDetail.fileJson"
              ></File>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="fs-16 fw-bold">汇总信息</div>

          <div class="num-list flex">
            <div class="num-item">
              <div>{{ taskDetail.seedNum }}</div>
              <div>发送人数</div>
            </div>
            <div class="num-item">
              <div>{{ taskDetail.receiveNum }}</div>
              <div>接收人数</div>
            </div>
            <div class="num-item">
              <div>{{ taskDetail.completeNum }}</div>
              <div>完成人数</div>
            </div>
            <div class="num-item">
              <div>{{ taskDetail.incomplete }}</div>
              <div>未完成人数</div>
            </div>
            <div class="num-item">
              <div>
                {{ taskDetail.completionRate || 0 }}<span class="fs-14">%</span>
              </div>
              <div>完成率</div>
            </div>
            <div class="num-item">
              <div>
                {{ taskDetail.passRate || 0 }}<span class="fs-14">%</span>
              </div>
              <div>合格率</div>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="fs-16 fw-bold">队伍完成情况</div>

          <div
            class="wp-table mt-20"
            v-loading="teamLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="teamList" stripe v-if="teamList.length">
              <el-table-column width="50"> </el-table-column>
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column width="120"> </el-table-column>
              <el-table-column
                align="left"
                prop="groupName"
                width="240"
                show-overflow-tooltip
                label="分组名称"
              >
                <template slot-scope="scope">
                  <div
                    class="textOverOneLine"
                    :class="scope.row.child ? 'group-name' : ''"
                    @click="getPeopleDetail(scope.row)"
                  >
                    {{ scope.row.groupName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="seedNum" label="发送人数">
              </el-table-column>
              <el-table-column align="center" prop="jsTotal" label="接收人数">
              </el-table-column>
              <el-table-column align="center" prop="popleNum" label="完成人数">
              </el-table-column>
              <el-table-column align="center" prop="wcRate" label="完成率">
                <template slot-scope="scope">
                  <div>{{ scope.row.wcRate }}%</div>
                </template>
              </el-table-column>
            </el-table>

            <div class="wp-noData" v-if="teamList.length == 0 && !teamLoading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            layout="prev, pager, next"
            :total="teamTotal"
            :page-size="10"
            @current-change="teamCurrentChange"
          ></wp-pagination>
        </div>

        <div class="wp-card mt-16">
          <div class="flex-CB people-info">
            <div class="flex-Acenter">
              <div class="fs-16 fw-bold">人员完成情况</div>
              <div
                class="fs-14 wp-blue flex-Acenter export"
                @click="exportFile"
              >
                <i class="icon-daochu"></i>
                <span>导出</span>
              </div>
            </div>
            <div class="is-finish flex">
              <div
                :class="activeFinish == 1 ? ' active-finish' : ''"
                @click="totggleFinish(1)"
              >
                已完成
              </div>
              <div
                :class="activeFinish == 0 ? ' active-finish' : ''"
                @click="totggleFinish(0)"
              >
                未完成
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="peopleLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <div v-show="activeFinish == 1">
              <el-table :data="peopoleList" stripe v-if="peopoleList.length">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="70"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileJson"
                  width="150"
                  label="截图/附件"
                >
                  <template slot-scope="scope">
                    <p
                      v-if="JSON.parse(scope.row.fileJson).length"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>

                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column min-width="30"> </el-table-column>
                <el-table-column
                  align="left"
                  prop="createName"
                  min-width="150"
                  show-overflow-tooltip
                  label="人员姓名"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  align="left"
                  prop="groupName"
                  show-overflow-tooltip
                  label="所在分组"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  align="center"
                  prop="createTime"
                  label="提交时间"
                >
                  <template slot-scope="scope">
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="totalScore"
                  label="奖励积分"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="articleStatus"
                  label="审核状态"
                >
                  <template slot-scope="scope">
                    <div>
                      <div
                        class="flex-center"
                        v-if="scope.row.articleStatus == 1"
                      >
                        <div class="success-circle"></div>
                        <div class="success-color">已通过</div>
                      </div>
                      <div class="flex-center" v-else>
                        <div class="noreceive-circle"></div>
                        <div class="noreceive-color">已驳回</div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <RejectCause
                      :key="scope.row.taskArticleId"
                      v-if="scope.row.articleStatus == 1"
                      popoverType="add"
                      @handlerReject="handlerReject($event, scope.row)"
                    >
                      <div class="reject">驳回</div>
                    </RejectCause>

                    <RejectCause
                      v-else
                      popoverType="detail"
                      :rejectObj="rejectObj"
                    >
                      <div
                        class="reject-disabled"
                        @mouseenter="getReject(scope.row.taskArticleId)"
                      >
                        驳回
                      </div>
                    </RejectCause>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div v-show="activeFinish == 0">
              <el-table :data="peopoleList" stripe v-if="peopoleList.length">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="70"
                >
                </el-table-column>
                <el-table-column width="40"> </el-table-column>
                <el-table-column
                  align="left"
                  width="150"
                  show-overflow-tooltip
                  prop="userName"
                  label="人员姓名"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="groupName"
                  show-overflow-tooltip
                  label="所在分组"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  width="200"
                  show-overflow-tooltip
                  prop="custName"
                  label="所在单位"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="taskReceiverStatus"
                  label="接收状态"
                >
                  <template slot-scope="scope">
                    <div
                      class="flex-center"
                      v-if="scope.row.taskReceiverStatus == 1"
                    >
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">未接收</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="receive-circle"></div>
                      <div class="receive-color">已接收</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="receiverTime"
                  label="接收时间"
                >
                  <template slot-scope="scope">
                    <div>
                      {{
                        (scope.row.receiverTime &&
                          $moment(scope.row.receiverTime).format(
                            'YYYY-MM-DD HH:mm'
                          )) ||
                        '-'
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div
                      class="wp-blue wp-pointer"
                      @click="sendMessage(scope.row)"
                    >
                      发送短信
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div
              class="wp-noData mt-20"
              v-if="peopoleList.length == 0 && !peopleLoading"
            >
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            layout="prev, pager, next"
            :total="peopleTotal"
            :page-size="10"
            @current-change="peopleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <wp-dialog
      @cancel="messageCancel"
      size="small"
      :dialogVisible.sync="messageVisible"
      @determine="confirmMessage"
      @handleClose="messageCancel"
    >
      <div class="message-box">
        <div class="fs-16 fw-bold">确定发送短信吗？</div>
        <div class="message-text">发送短信将会提醒接收人</div>
      </div>
    </wp-dialog>

    <!-- 二级队伍弹窗 -->
    <wp-dialog
      @cancel="groupingCancel"
      :dialogVisible.sync="groupingVisible"
      @handleClose="groupingCancel"
      :showSlotFooter="groupingStep == 2 && this.haveChild"
      :showDefaultFooter="false"
      height="550px"
      width="820px"
      :title="groupingName"
    >
      <div v-show="groupingStep == 1">
        <div
          class="grouping-box wp-table"
          v-loading="twoTeamLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="正在加载"
        >
          <el-table
            ref="twoTeamList"
            v-if="twoTeamList.length"
            :data="twoTeamList"
            stripe
            @row-click="rowClick"
            :row-style="rowStyle"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="70"
            >
            </el-table-column>
            <el-table-column width="20"> </el-table-column>
            <el-table-column align="left" prop="groupName" label="分组名称">
            </el-table-column>
            <el-table-column align="center" prop="seedNum" label="发送人数">
            </el-table-column>
            <el-table-column align="center" prop="jsTotal" label="接收人数">
            </el-table-column>
            <el-table-column align="center" prop="popleNum" label="完成人数">
            </el-table-column>
            <el-table-column align="center" prop="wcRate" label="完成率">
              <template slot-scope="scope">
                <div>{{ scope.row.wcRate }}%</div>
              </template>
            </el-table-column>
          </el-table>

          <div
            class="wp-noData mt-20"
            v-if="twoTeamList.length == 0 && !twoTeamLoading"
          >
            <img src="~@/assets/images/noData.png" alt="" />
            <p>暂无数据</p>
          </div>

          <wp-pagination
            class="mt-16"
            layout="prev, pager, next"
            :total="twoTeamTotal"
            :page-size="10"
            @current-change="twoTeamCurrentChange"
          ></wp-pagination>
        </div>
      </div>
      <div v-show="groupingStep == 2">
        <!-- 人员名称、截图/附件、提交时间、奖励积分、审核状态 -->
        <div
          class="grouping-box wp-table"
          v-loading="groupingStep2Loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="正在加载"
        >
          <el-table
            v-if="groupingStep2Data.length"
            :data="groupingStep2Data"
            stripe
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="70"
            >
            </el-table-column>
            <el-table-column
              align="left"
              prop="createName"
              show-overflow-tooltip
              label="人员名称"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="fileJson"
              width="150"
              label="截图/附件"
            >
              <template slot-scope="scope">
                <p
                  v-if="JSON.parse(scope.row.fileJson).length"
                  style="cursor: pointer"
                >
                  <el-popover
                    placement="right-start"
                    :visible-arrow="false"
                    trigger="hover"
                    popper-class="img-popper"
                    :tabindex="3"
                  >
                    <el-image
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      class="scaled-image"
                      fit="contain"
                    ></el-image>
                    <img
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </el-popover>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="createTime"
              label="提交时间"
            >
              <template slot-scope="scope">
                {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="totalScore" label="奖励积分">
            </el-table-column>
            <el-table-column
              align="center"
              min-width="150"
              prop="articleStatus"
              label="审核状态"
            >
              <template slot-scope="scope">
                <div>
                  <div class="flex-center" v-if="scope.row.articleStatus == 1">
                    <div class="success-circle"></div>
                    <div class="success-color">已通过</div>
                  </div>
                  <div class="flex-center" v-else>
                    <div class="noreceive-circle"></div>
                    <div class="noreceive-color">已驳回</div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div
            class="wp-noData mt-20"
            v-if="groupingStep2Data.length == 0 && !groupingStep2Loading"
          >
            <img src="~@/assets/images/noData.png" alt="" />
            <p>暂无数据</p>
          </div>

          <wp-pagination
            class="mt-16"
            layout="prev, pager, next"
            :total="groupingStep2Total"
            :page-size="10"
            @current-change="groupingStep2CurrentChange"
          ></wp-pagination>
        </div>
      </div>
      <template slot="slotFooter">
        <el-button
          class="wp-button"
          v-preventReClick
          size="medium"
          style="width: 120px"
          @click.stop="groupingStepCancel"
          >返回
        </el-button>
      </template>
    </wp-dialog>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import File from '@/views/components/fileDetail'
import RejectCause from '@/views/components/rejectCause'
export default {
  components: { RejectCause, File },
  data () {
    return {
      tableData: [],
      activeFinish: 1, // 1已完成 0未完成
      messageVisible: false, // 短信弹窗
      groupingVisible: false, // 分组弹窗
      groupingName: "", // 分组名称
      taskId: "", // 任务Id
      taskDetail: {}, // 任务详情

      teamList: [], // 队伍上报列表
      teamLoading: false,
      teamTotal: 0, // 队伍上报总条数
      teamPage: 1, // 队伍列表页码

      twoTeamList: [], // 二级队伍上报列表
      twoTeamLoading: false,
      twoTeamTotal: 0, // 二级队伍上报总条数
      twoTeamPage: 1, // 二级队伍列表页码
      parentId: "", // 一级队伍Id

      peopoleList: [], // 人员上报列表
      peopleLoading: false,
      peoplePage: 1, // 人员上报页码
      peopleTotal: 0, // 人员上报总条数

      rejectObj: {}, // 获取驳回信息
      topicArticleId: "", // 文章ID 
      peopleItem: {},// 人员信息

      videoUrl: "",
      groupingStep: 1,
      groupingStep2Data: [],
      groupingStep2Loading: false,
      groupingStep2Total: 0,
      groupingStep2Page: 1,
      receiverGroupId: '',
      haveChild: true,
      oneGroup: {}
    }
  },
  created () {
    const taskId = this.$route.query?.taskId || ''

    // 有专题id则获取详情
    if (taskId) {
      this.taskId = this.$decrypt(taskId)
      // 获取任务详情
      this.getTaskDetail()
      // 已完成人员列表
      this.getCompletePeopleList()
      // 队伍完成情况列表
      this.getTeamReportedDetail()
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.twoTeamList?.doLayout();
    })
  },

  methods: {
    rowStyle (row) {
      return {
        cursor: 'pointer'
      };
    },
    rowClick (row, column, event) {
      console.log('rowClick')
      this.receiverGroupId = row.groupId
      this.groupingStep2Page = 1
      this.getGroupingStep2Data()
      this.groupingStep = 2
      this.groupingName = row.groupName
    },
    // 获取二级队伍详情
    getPeopleDetail (item) {
      console.log('getPeopleDetail')
      this.oneGroup = item
      this.twoTeamPage = 1
      // 为true时,则代表有二级分组
      if (item.child) {
        this.haveChild = true
        this.parentId = item.groupId
        this.groupingName = item.groupName
        this.groupingStep = 1
        this.groupingVisible = true
        this.getTeamReportedDetail(item.groupId)
      } else {
        this.haveChild = false
        this.groupingStep = 2
        this.groupingVisible = true
        this.receiverGroupId = item.groupId
        this.groupingName = item.groupName
        this.groupingStep2Page = 1
        this.getGroupingStep2Data()
      }
    },

    // 二级队伍页码回调
    twoTeamCurrentChange (page) {
      this.twoTeamPage = page
      this.getTeamReportedDetail(this.parentId)
    },

    // 一级队伍页码回调
    teamCurrentChange (page) {
      this.teamPage = page
      this.getTeamReportedDetail()
    },

    // 获取驳回信息
    async getReject (articleId) {
      const res = await taskApi.getVerify({ articleId })
      if (res.returnCode == 200) {
        this.rejectObj = res.returnData
      }
    },

    /** 队伍完成情况 */
    async getTeamReportedDetail (parentId = "") {
      if (parentId) {
        if (this.twoTeamLoading) return
        this.twoTeamLoading = true
      } else {
        if (this.teamLoading) return
        this.teamLoading = true
      }

      try {
        // 根据 parentId 判断是否为二级队伍
        let query = {
          page: parentId ? this.twoTeamPage : this.teamPage,
          limit: 10,
          taskId: this.taskId,
          parentId
        }

        const res = await taskApi.teamMumberList(query)
        this.twoTeamLoading = false
        this.teamLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData

          if (parentId) {
            this.twoTeamList = data || []
            this.twoTeamTotal = totalCount
          } else {
            this.teamList = data || []
            this.teamTotal = totalCount
          }
        }
      } catch (error) {
        this.twoTeamLoading = false
        this.teamLoading = false
      }
    },

    handleClickItem () {
      this.$utils.hideImg()
    },

    /** 获取任务详情 */
    async getTaskDetail () {
      const res = await taskApi.taskDetail({ taskId: this.taskId })
      if (res.returnCode == 200) {
        const arr = []
        arr.push(res.returnData?.taskMain || {})

        // 任务详情数据
        this.taskDetail = arr.map(item => {
          console.log(item.fileJson);
          // 任务方式
          item.dictKey = item?.modeStyleList.map(it => it.dictKey).join(',')

          item = { ...item, ...item.taskMainSituation, ...item.modeStyleList, ...item.taskArticleSituation }
          return item
        })[0]

        this.taskDetail.fileJson = JSON.parse(this.taskDetail.fileJson) || []

      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    /** 已完成人员列表 */
    async getCompletePeopleList () {
      if (this.peopleLoading) return
      this.peopleLoading = true

      try {
        const query = {
          taskId: this.taskId,
          limit: 10,
          page: this.peoplePage,
          orderFiled: '2'
        }
        const res = await taskApi.articleList(query)
        this.peopleLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData
          this.peopoleList = data || []
          this.peopleTotal = totalCount
        } else {
          this.peopoleList = []
          this.peopleTotal = 0
        }
      } catch (error) {
        this.peopleLoading = false
      }
    },

    /** 未完成任务人员列表 */
    async getUndonePeopleList () {
      if (this.peopleLoading) return
      this.peopleLoading = true

      try {
        const query = {
          taskId: this.taskId,
          page: this.peoplePage,
          limit: 10,
          isArticle: 0
        }

        const res = await taskApi.receiverList(query)
        this.peopleLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData
          this.peopoleList = data
          this.peopleTotal = totalCount
        } else {
          this.peopoleList = []
          this.peopleTotal = 0
        }
      } catch (error) {
        this.peopleLoading = false
      }
    },

    /** 人员上报页码回调 */
    peopleCurrentChange (page) {
      this.peoplePage = page
      this.getPeopleList()
    },

    /** 导出 */
    async exportFile (event) {
      try {
        if (this.activeFinish) {
          if (this.isExporting) return
          this.isExporting = true
          const res = await taskApi.taskArticleWord({ taskId: this.taskId })
          this.isExporting = false
          if (res.returnCode == 200) {
            this.isExporting = false
            this.$store.dispatch('app/GetDownList')
            this.$utils.setBall(event)
          } else {
            if (Number(res.returnCode) == 1009) return this.$showMessage({
              type: 'warning',
              msg: '文件正在下载，请在下载中心查看'
            })
            this.$showMessage({
              type: 'error',
              msg: res.returnMsg
            })
          }
        } else {
          if (this.isExporting2) return
          this.isExporting2 = true
          const res = await taskApi.exportTaskReceiver({ taskId: this.taskId, isArticle: this.activeFinish })
          this.isExporting2 = false
          if (res.returnCode == 200) {
            this.isExporting2 = false
            this.$store.dispatch('app/GetDownList')
            this.$utils.setBall(event)
          } else {
            if (Number(res.returnCode) == 1009) return this.$showMessage({
              type: 'warning',
              msg: '文件正在下载，请在下载中心查看'
            })
            this.$showMessage({
              type: 'error',
              msg: res.returnMsg
            })
          }
        }
      } catch (error) {
        this.isExporting = false
        this.isExporting2 = false
      }
    },

    // 切换已完成未完成
    totggleFinish (flag) {
      this.activeFinish = flag
      // 修改页码
      this.peoplePage = 1
      this.getPeopleList()
    },

    getPeopleList () {
      this.peopoleList = []
      // 未完成
      if (!this.activeFinish) {
        this.getUndonePeopleList()
      } else {
        this.getCompletePeopleList()
      }
    },

    // 获取二级队伍详情
    getDetail (item) {
      this.groupingStep = 1
      this.groupingVisible = true
      this.groupingName = item.date
      console.log(item)
    },

    /** 提交驳回信息回调 */
    async handlerReject (rejectMsg, item) {
      const query = {
        rejectMsg,
        articleId: item.taskArticleId
      }
      console.log(query);
      const res = await taskApi.rejectArticle(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', msg: '操作成功' })
        this.getPeopleList()
      }
    },

    // 发送短信
    sendMessage (item) {
      this.peopleItem = item
      this.messageVisible = true
    },

    // 确认发送短信
    async confirmMessage () {
      const receiverId = []
      // 接收人Id
      receiverId.push(this.peopleItem.receiverId)
      const res = await taskApi.sendNote({ receiverId, taskId: this.peopleItem.taskId })
      // 发送短信成功关闭弹窗
      if (res.returnCode == 200 || res.returnCode == 10087) {
        this.messageVisible = false
        if (res.returnCode == 10087) {
          this.$showMessage({ type: 'warning', msg: res.returnMsg })
        } else {
          this.$showMessage({ type: 'success', msg: '发送成功' })
        }
      }
    },

    // 关闭短信弹窗或点击取消按钮
    messageCancel () {
      this.messageVisible = false
    },

    // 关闭分组弹窗或点击取消按钮
    groupingCancel () {
      this.groupingStep = 1
      this.groupingVisible = false
    },
    // 分组人员分页
    groupingStep2CurrentChange (page) {
      this.groupingStep2Page = page
      this.getGroupingStep2Data()
    },
    async getGroupingStep2Data () {
      if (this.groupingStep2Loading) return
      this.groupingStep2Loading = true

      try {
        const query = {
          taskId: this.taskId,
          page: this.groupingStep2Page,
          limit: 10,
          // localType: this.roleDisabled ? 2 : 1,
          orderFiled: '2',
          groupIds: [this.receiverGroupId]
        }
        const res = await taskApi.articleList(query)
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData
          this.groupingStep2Data = data
          this.groupingStep2Total = totalCount
        } else {
          this.groupingStep2Data = []
          this.groupingStep2Total = 0
        }
      } catch (error) {
      } finally {
        this.groupingStep2Loading = false
      }
    },
    groupingStepCancel () {
      this.groupingStep2Page = 1
      this.groupingName = this.oneGroup.groupName
      this.groupingStep = 1
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>