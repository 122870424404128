<template>
  <div class="personalInformation">
    <div class="layout-block wp-moudle-style">
      <div class="layout-title">个人信息</div>
      <div class="layout-content">
        <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="100px" class="ruleform1">
          <el-form-item label="登录账号：">
            <div class="login-name">{{ sysUserInfo.sysUser.loginName }}</div>
          </el-form-item>
          <el-form-item prop="userName" label="人员姓名：">
            <!-- <el-input v-model.trim="ruleForm1.userName" placeholder="请输入人员姓名" clearable :maxlength="20" @keyup.enter.native="determine">
            </el-input> -->
            <div class="login-name">{{ ruleForm1.userName }}</div>
          </el-form-item>
          <el-form-item prop="mobile" label="手机号码：">
            <div class="verification-div">
              <!-- <el-input v-model.trim="ruleForm1.mobile" @input="resetNumMobile" placeholder="请输入手机号码" clearable :maxlength="11" @keyup.enter.native="determine">
              </el-input> -->
              <div class="login-name">{{ ruleForm1.mobile }}</div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <el-button class="wp-button" v-preventReClick type="primary" @click="determine">确定
            </el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isPhone, resetNum } from '@/utils/validate.js';
import { editPersonInfo } from '@/api/systemSettings.js';
export default {
  name: 'personalInformation',
  components: {},
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!isPhone(value)) {
        callback(new Error('请输入11位有效手机号码'));
      } else {
        callback();
      }
    };
    return {
      ruleForm1: {
        userName: '',
        mobile: ''
      },
      rules1: {
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }
        ]
      },
    };
  },
  props: {},
  created () {

  },
  mounted () {
    this.initData()
  },
  computed: {
    ...mapGetters(['sysUserInfo'])
  },
  methods: {
    initData () {
      this.ruleForm1.userName = this.sysUserInfo.sysUser.userName
      this.ruleForm1.mobile = this.sysUserInfo.sysUser.mobile
    },
    determine () {
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 提交、
    async postAdd () {
      let query = {
        ...this.ruleForm1,
        isweb:true
      }
      try {
        let res = await editPersonInfo(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 手机号重置 值
    resetNumMobile () {
      this.ruleForm1.mobile = resetNum(this.ruleForm1.mobile);
    }

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {

    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/personalInformation.scss";
</style>
