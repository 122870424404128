<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
          <el-tab-pane label="组员统计" name="people">
            <SearchCard title="" @handlerSearch="handlerSearch" class="peopleSearch">
              <template v-slot:searchContent>
                <TaskTimeSelect :days="365" :activeType="searchParams.timeType" :taskType="timeType" @timeSelect="timeSelect" ref="timeRef"></TaskTimeSelect>

                <el-input class="search-w190 wp-input" v-model="searchParams.createName" placeholder="请输入人员姓名" clearable>
                </el-input>

                <el-input class="search-w392 wp-input" v-model="searchParams.custName" placeholder="请输入所在单位" clearable>
                </el-input>

                <wpLoadSelect class="search-w392 wp-select align-top" v-model="searchParams.taskId" :data="taskSelectList" :page="taskObj.page" dictLabel="title" dictValue="taskId" dictTime="createTime" :hasMore="taskObj.more" :request="getData" @clearTitle="() => (taskObj.title = '')" placeholder="请选择任务"></wpLoadSelect>
              </template>
            </SearchCard>
          </el-tab-pane>

          <el-tab-pane label="作品明细" name="workDetails">
          </el-tab-pane>

        </TabsCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="flex-Acenter fs-14">
              <div>
                完成总量：<span class="wp-blue">{{
                  statInfo.completeNum || 0
                }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{ statInfo.score || 0 }}</span>
              </div>
            </div>
            <div>
              <el-popover popper-class="export-popover" ref="export-popover" placement="bottom" trigger="click">
                <div class="export-list">
                  <div class="export-item wp-pointer" @click="detailExport">
                    明细导出
                  </div>
                  <div class="export-item wp-pointer" @click="exportFile">
                    分月统计
                  </div>
                </div>
                <el-button style="width: 90px" slot="reference" class="wp-button" size="medium">导出<i class="icon-zhankai el-icon--right"></i></el-button>
              </el-popover>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table :data="tableData" stripe sortable="custom" @sort-change="sortChange" v-if="tableData.length !== 0">
              <el-table-column align="center" type="index" label="序号" width="70">
              </el-table-column>
              <el-table-column min-width="30"> </el-table-column>
              <el-table-column align="left" prop="userName" label="人员姓名" min-width="120">
              </el-table-column>
              <el-table-column align="left" prop="custName" label="所在单位" min-width="200">
              </el-table-column>
              <el-table-column min-width="80" align="center" prop="taskNum" label="任务总数">
                <template slot-scope="scope">
                    <div class="wp-pointer wp-blue" @click.stop="showTaskDetails(scope.row)">
                      {{
                scope.row.taskNum
              }}
                    </div>
                  </template>
              </el-table-column>
              <el-table-column min-width="30"> </el-table-column>
              <el-table-column align="center" prop="taskFinshNum" label="完成数量">
              </el-table-column>
              <el-table-column min-width="30"> </el-table-column>
              <el-table-column align="center" prop="completionRate" label="完成率">
                <template slot-scope="scope">
                  <div>{{ scope.row.completionRate }}%</div>
                </template>
              </el-table-column>
              <el-table-column min-width="30"> </el-table-column>
              <el-table-column align="center" sortable prop="workNum" label="作品数量" min-width="120">
              </el-table-column>
              <el-table-column align="center" sortable prop="score" label="奖励积分" min-width="120">
              </el-table-column>
              <el-table-column align="center" sortable prop="excellentScore" min-width="100" label="优评积分" v-if="isCanAccessExcellentReview">
        </el-table-column>
        <el-table-column align="center" sortable prop="sumScore" min-width="100" label="总积分" v-if="isCanAccessExcellentReview">
        </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
    <TaskDetails ref="taskDetails" :searchParams="taskDetailsQuery"></TaskDetails>
  </div>
</template>

<script>
import wpyTaskApi from '@/api/wpyTask.js'
import taskApi from '@/api/task.js'
import TabsCard from '@/views/components/tabsCard'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import TaskDetails from '@/views/task/taskStatistics/components/taskDetails'
import { mapGetters } from 'vuex';
export default {
  components: { SearchCard, TaskTimeSelect, TabsCard,TaskDetails},
  data () {
    return {
      searchParams: {
        page: 1,
        limit: 30,
        timeType: 2, // 任务时间类型
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        createName: "", // 人员姓名
        custName: "", // 单位名称
        taskId: "", // 任务Id
        modelName: 'user',
      },
      loading: false,
      // 时间类型
      timeType: [
        {
          name: "作品提交时间",
          id: 1
        },
        {
          name: "任务发布时间",
          id: 2
        }
      ],
      tableData: [],
      total: 0, // 总条数

      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      taskSelectList: [], // 任务下拉框列表
      statInfo: {},
      activeTab: 'people',
      tabs: [
        { label: '组员统计', name: "people" },
        { label: '作品明细', name: "workDetails" }
      ],
      taskDetailsQuery:{}
    }
  },
  mounted () {
    this.$refs.timeRef.initTime()
    // 获取统计列表
    this.getTableData()
    // 获取统计表格信息
    this.getSummaries()
  },
  computed: {
    ...mapGetters(['sysUserInfo']),
    isCanAccessExcellentReview(){
      return Number(this.sysUserInfo.sysCustOption.accessExcellentReview) == 1
    }
  },
  methods: {
    /** 获取统计表格信息 */
    async getSummaries () {
      const res = await wpyTaskApi.getSummaries({ ...this.searchParams })
      if (res.returnCode == 200) {
        // 统计信息
        this.statInfo = res.returnData || {}
      }
    },

    // 选择时间回调
    timeSelect (time) {
      console.log(time);
      this.searchParams.timeType = time.timeType
      this.searchParams.startTime = time.startTime
      this.searchParams.endTime = time.endTime
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskSelectList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      console.log(this.taskObj)
      const res = await wpyTaskApi.getTaskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskSelectList = [...this.taskSelectList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    /** 组员统计 */
    async getTableData () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyTaskApi.memberStatisticsList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
        }
      } catch (error) {
        this.loading = false
      }

    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }
      // 排序的字段
      this.searchParams.orderField = prop
      // 顺序
      this.searchParams.sort = order
      this.getTableData()
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTableData()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    },

    /** 搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      // 获取转发统计列表数据
      this.getTableData()
      // 获取统计表格信息
      this.getSummaries()
    },

    // 明细导出
    async detailExport (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.memberStatisticsExport({ ...this.searchParams })
        this.isExporting = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    /** 导出 */
    async exportFile (event) {
      if (this.isExporting2) return
      this.isExporting2 = true
      try {
        const res = await taskApi.memberMonthExport(this.searchParams)
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }
    },
    /** tab切换 */
    handlerTabs (name) {
      if (name != 'people') {
        this.$router.push('/crewWorkDetails')
      }
    },
    // 任务明细
    showTaskDetails(row){
      this.taskDetailsQuery = {
        userId: row.userId, //用户ID
        timeType: this.searchParams.timeType, //1文章提交时间2任务时间
        startTime: this.searchParams.startTime, //搜索开始时间yyyy-MM-dd
        endTime:this.searchParams.endTime, //搜索结束时间yyyy-MM-dd
        taskId: this.searchParams.taskId, //任务ID
      }
      this.$refs.taskDetails.show()
    }
  },
}
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}
.peopleSearch {
    padding: 0px;
  }
</style>