<template>
  <div class="unitManagList">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <SearchCard title="单位管理" @handlerSearch="handlerSearch" class="title-card">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker v-model="searchQuery.datetimeValue" prefix-icon="" value-format="yyyy-MM-dd" clearable type="daterange" range-separator="-" start-placeholder="请选择到期时间" end-placeholder="请选择到期时间" @change="changeDaterange">
              </el-date-picker>
            </div>
            <el-input class="search-w392 wp-input" v-model.trim="searchQuery.custName" clearable placeholder="请输入单位名称">
            </el-input>

            <el-select class="search-w190 wp-select" v-model="searchQuery.systemType" clearable placeholder="请选择系统状态">
              <el-option v-for="item in $globalData.systemStateList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="search-w190 wp-select" v-model="searchQuery.status" clearable placeholder="请选择启用状态">
              <el-option v-for="item in $globalData.enableStateList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </SearchCard>
        <div class="layout-block wp-moudle-style layout-block-bottom">
          <div class="action-row">
            <div class="count">
              单位数量： <span>{{ total }}</span>
            </div>
            <div>
              <el-button class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium" @click="createUnits">创建单位
              </el-button>
            </div>
          </div>
          <div class="table-box">
            <!-- 信息列包括“序号、单位名称、上级单位、短信余量、人数上限、到期日期、创建日期、创建人、系统状态、启用状态、操作”   -->
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="70" align="center">
                </el-table-column>
                <el-table-column prop="custName" label="单位名称" min-width="180" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="parentCustName" label="上级单位" min-width="180" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="smsNum" label="短信余量" width="100" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="maxMember" label="人数上限" width="100" align="center">
                </el-table-column>
                <el-table-column prop="endTime" label="到期日期" width="150" align="center">
                </el-table-column>
                <el-table-column prop="createName" label="创建人" min-width="100" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期" width="150" align="center">
                </el-table-column>
                <el-table-column label="系统状态" width="100" align="center">
                  <template slot-scope="scope">
                    <span style="color: #0064e6" v-if="scope.row.systemType == 0">试用</span>
                    <span v-else>正式</span>
                  </template>
                </el-table-column>
                <el-table-column label="启用状态" width="100" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div class="enableState enableState1" v-if="scope.row.status == 1">
                      <span class="dot"></span> 启用
                    </div>
                    <div class="enableState enableState2" v-if="scope.row.status == 2">
                      <span class="dot"></span>禁用
                    </div>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150" align="center">
                  <template slot-scope="scope">
                    <div class="flex-center">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big" @click="toEidtUnits(scope.row)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="用户管理" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-yonghu" class="wp-btn-icon-big" @click="toUserManagList(scope.row)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="短信管理" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-duanxin" class="wp-btn-icon-big" @click="toSmsManagList(scope.row)">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div class="pagination-row">
              <wp-pagination :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
              </wp-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import { sysCustList } from '@/api/systemSettings.js';
import SearchCard from '@/views/components/searchCard'
export default {
  name: 'unitManagList',
  components: { SearchCard },
  data () {
    return {
      datetimeValue: null,
      loading: false,
      tableData: [],
      total: 0,
      searchQuery: {
        datetimeValue: null,
        custName: "",
        systemType: "",
        status: ""
      },
      query: {
        page: 1,
        limit: 30,
        custName: "",
        status: "",
        systemType: "",
        startDate: "",
        endDate: ""
      },

    };
  },
  props: {},
  created () {

  },
  mounted () {
    this.getSysCustList()
  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    changeDaterange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      console.log(val)
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      console.log(val)
      this.query.page = val
      this.getSysCustList()
    },
    // 更新列表
    refresh () {
      this.query.page = 1
      this.getSysCustList()
    },
    // 搜索
    handlerSearch () {
      // console.log(this.searchQuery.datetimeValue)
      let datetimeValue = this.searchQuery.datetimeValue ? this.searchQuery.datetimeValue : []
      this.query = {
        ...this.query,
        ...this.searchQuery,
        startDate: datetimeValue.length > 1 ? this.$moment(datetimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endDate: datetimeValue.length > 1 ? this.$moment(datetimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      delete this.query.datetimeValue
      this.refresh()
    },
    //创建单位
    createUnits () {
      this.$router.push({ name: 'createUnits' })
    },
    //去用户管理界面
    toUserManagList (item) {
      this.$router.push({ name: 'userManagList', query: { _id: this.$encrypt(item.custId), _custName: this.$encrypt(item.custName) } })
    },
    //去编辑单位
    toEidtUnits (item) {
      this.$router.push({ name: 'createUnits', query: { _id: this.$encrypt(item.custId) } })
    },
    //去短信管理-充值管理
    toSmsManagList (item) {
      this.$router.push({
        name: 'smsManagList',
        query: { _custId: this.$encrypt(item.custId), _custName: this.$encrypt(item.custName) }
      })
    },
    // 获取单位列表
    async getSysCustList () {
      let query = {
        ...this.query
      }
      if (this.loading) return
      try {
        this.loading = true
        let res = await sysCustList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(element => {
          element.endTime = this.$moment(element.endTime).format("YYYY-MM-DD")
          element.createTime = this.$moment(element.createTime).format("YYYY-MM-DD")
        });
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/unitManagList.scss";
</style>
