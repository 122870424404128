<template>
  <div class="wp-w100">
    <!-- 基础设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title fs-16 fw-bold">基础设置</div>
      <BasicsForm
        :formData="formData"
        @getStyleList="getStyleList"
        class="center"
        ref="basicsForm"
      ></BasicsForm>
    </div>

    <!-- 任务设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title flex-CB">
        <div class="fs-16 fw-bold">任务设置</div>
        <div class="flex-Acenter instancy-task">
          <el-checkbox class="isUrgent" v-model="isUrgent"
            >紧急任务</el-checkbox
          >
          <wp-question-mark
            style="margin-left: 6px"
            content="任务发出后APP会强提醒30秒"
          ></wp-question-mark>
        </div>
      </div>
      <TaskForm
        :formData="formData"
        :configObj="configObj"
        :taskStyleList="taskStyleList"
        ref="taskForm"
      ></TaskForm>
    </div>

    <!-- 高级选项 -->
    <div
      class="wp-task-card mt-16"
      :style="{ height: foldFlag ? '174px' : '74px' }"
    >
      <div class="card-title flex-CB">
        <div class="fs-16 fw-bold">高级选项</div>
        <div class="wp-blue toggle" @click="foldFlag = !foldFlag">
          <span>{{ !foldFlag ? '展开' : '收起' }}</span>
          <i
            v-show="foldFlag"
            class="el-icon-arrow-up fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
          <i
            v-show="!foldFlag"
            class="el-icon-arrow-down fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
        </div>
      </div>

      <div class="footer" :style="{ display: foldFlag ? 'block' : 'none' }">
        <div class="layout-content">
          <el-form label-width="100px" class="advanced-form">
            <el-form-item
              :label="item.configName + '：'"
              v-for="item in advancedSetting2"
              :key="item.custConfigId"
            >
              <el-checkbox
                v-model="item.isShow"
                :disabled="item.isDisbled"
                @change="checkboxChangeShow2(item)"
                >显示
              </el-checkbox>
              <el-checkbox
                v-model="item.isRequired"
                :disabled="item.isDisbled"
                @change="checkboxChangeRequired2(item)"
                >必填
              </el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicsForm from './form/basicsForm' // 基础设置
import TaskForm from './form/taskForm' // 任务设置

import taskApi from '@/api/task.js'
import wpyTaskApi from '@/api/wpyTask.js'
export default {
  components: { BasicsForm, TaskForm },
  data () {
    return {
      isUrgent: false, // 紧急任务
      foldFlag: false,
      taskStyleList: [], // 任务方式列表
      configObj: {}, // 任务配置项
      advancedSetting2: [], // 高级设置
      taskId: "", // 任务Id
      taskReceiverId: "", // 任务接收Id
      formData: {}, // 表单数据
      taskTypeId: "", // 任务分类Id
    }
  },
  created () {
    if (this.$route.query.taskId) {
      this.taskReceiverId = this.$route.query?.taskReceiverId || ""
      this.taskId = this.$route.query.taskId
      // 获取任务详情
      this.getTaskDetail()
      // 获取单位配置
      this.getConfig()
    }
  },
  methods: {
    /** 获取任务详情 */
    async getTaskDetail () {
      const res = await wpyTaskApi.getTaskDetail({ taskId: this.taskId, taskReceiverId: this.taskReceiverId })
      if (res.returnCode == 200) {
        // 任务信息
        const data = res.returnData

        this.isUrgent = data.taskMain.isUrgent ? true : false
        console.log(this.isUrgent);
        this.formData = data.taskMain

        // 任务方式列表
        this.taskStyleList = data.allStyleList || []
        console.log(this.formData);

        const configObj = {
          // 截图/附件
          parmAttach: data.taskMain?.parmAttach,
          attachName: '附件/截图',

          // 标题
          parmTitle: data.taskMain?.parmTitle,
          workTitle: '作品标题',

          // 内容
          parmContent: data.taskMain?.parmContent,
          workContent: "作品内容",

          // 链接
          parmUrl: data.taskMain?.parmUrl,
          workUrl: "作品链接",
        }

        this.setConfigValue(configObj)
      }
    },

    // 高级设置
    setConfigValue (data) {
      console.log(data);
      //作品标题 subjecArticleTitle
      let advancedSetting2 = []
      let subjecArticleTitle = data.parmTitle.toString()
      advancedSetting2.push({ configName: data.workTitle, isShow: subjecArticleTitle.charAt(0) == '1', isRequired: subjecArticleTitle.charAt(1) == '1', isDisbled: false })

      //作品内容 subjectArticleContent
      let subjectArticleContent = data.parmContent.toString()
      advancedSetting2.push({ configName: data.workContent, isShow: subjectArticleContent.charAt(0) == '1', isRequired: subjectArticleContent.charAt(1) == '1', isDisbled: false })

      //作品链接 subjectArticleUrl
      let subjectArticleUrl = data.parmUrl.toString()
      advancedSetting2.push({ configName: data.workUrl, isShow: subjectArticleUrl.charAt(0) == '1', isRequired: subjectArticleUrl.charAt(1) == '1', isDisbled: false })

      //附件/截图 subjectArticleFile
      let subjectArticleFile = data.parmAttach.toString()
      advancedSetting2.push({ configName: data.attachName, isShow: subjectArticleFile.charAt(0) == '1', isRequired: subjectArticleFile.charAt(1) == '1', isDisbled: true })
      this.advancedSetting2 = advancedSetting2
    },

    // 返回
    cancel () {
      this.$router.push('/taskManagement')
    },

    // 获取任务方式列表
    getStyleList (list) {
      this.taskStyleList = list || []
    },

    // 获取单位默认参数
    async getConfig (data) {
      const configObj = {}
      // 积分列表
      configObj.scoreList = data.taskScore || []
      // 字数要求
      configObj.taskWordsRequire = data.taskWordsRequire || []
      // 单人上限
      configObj.taskSingleLimit = data.taskSingleLimit || []
      // 起止时间快捷选项
      configObj.timeList = data.taskTime

      this.configObj = configObj
    },

    //  高级设置 改变显示 
    checkboxChangeShow2 (item) {
      this.advancedSetting2.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },

    //  高级设置 改变必填 
    checkboxChangeRequired2 () {
      this.advancedSetting2.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },

    // 获取表单数据及高级设置信息
    getFormData () {
      const basicsForm = JSON.parse(JSON.stringify(this.$refs['basicsForm'].getFormData()))
      const taskForm = JSON.parse(JSON.stringify(this.$refs['taskForm'].getFormData()))

      console.log("basicsForm", basicsForm);
      console.log("taskForm", taskForm);

      if (basicsForm && taskForm) {
        // 任务方式数组转字符串
        taskForm.modeStyle = taskForm.modeStyle.join(',')

        // 高级设置
        this.advancedSetting2.forEach(item => {
          item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
        })

        this.advancedSetting2.forEach(value => {
          if (value.configName == "作品标题") {
            taskForm.parmTitle = value.configValue
          } else if (value.configName == "作品内容") {
            taskForm.parmContent = value.configValue
          } else if (value.configName == "作品链接") {
            taskForm.parmUrl = value.configValue
          } else {
            taskForm.parmAttach = value.configValue
          }
        })

        const query = { ...basicsForm, ...taskForm }
        // 是否紧急
        query.isUrgent = this.isUrgent ? 1 : 0
        // 任务Id
        query.parentId = this.taskId

        return query
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>