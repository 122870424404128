var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addUser" }, [
    _c(
      "div",
      [
        _c(
          "el-breadcrumb",
          {
            staticClass: "wp-breadcrumb",
            attrs: { "separator-class": "el-icon-arrow-right" },
          },
          [
            _c(
              "el-breadcrumb-item",
              { attrs: { to: { name: "unitManagList" } } },
              [_vm._v("单位管理")]
            ),
            _c(
              "el-breadcrumb-item",
              {
                nativeOn: {
                  click: function ($event) {
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              },
              [_vm._v("用户管理（" + _vm._s(_vm.custName) + "）")]
            ),
            _c("el-breadcrumb-item", [
              _vm._v(_vm._s(_vm.isEidt ? "编辑" : "创建") + "人员"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "layout-block wp-moudle-style" }, [
      _c("div", { staticClass: "layout-title" }, [
        _c("p", { staticClass: "title-name" }, [
          _vm._v(_vm._s(_vm.isEidt ? "编辑" : "创建") + "人员"),
        ]),
        !_vm.isEidt
          ? _c("p", { staticClass: "mima" }, [
              _vm._v("初始密码："),
              _c("span", [_vm._v("abc123456")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "layout-content" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleform",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "人员姓名：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入人员姓名",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "loginName", label: "登录账号：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入登录账号",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.loginName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "loginName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile", label: "手机号码：" } },
                [
                  _c("el-input", {
                    staticClass: "wp-input",
                    attrs: {
                      maxlength: "11",
                      placeholder: "请输入手机号码",
                      clearable: "",
                    },
                    on: { input: _vm.resetNumMobile },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.determine.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "mobile",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "status", label: "启用状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "wp-select",
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "请选择启用状态" },
                      on: { change: _vm.changeStatus },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.determine.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    _vm._l(
                      _vm.$globalData.userEnableStateList,
                      function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { staticClass: "wp-button", on: { click: _vm.goBack } },
                    [_vm._v("取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "wp-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.determine },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }