var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "TabsCard",
                {
                  attrs: { tabs: _vm.tabs, activeTab: _vm.activeTab },
                  on: { handlerTabs: _vm.handlerTabs },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "组员统计", name: "people" } },
                    [
                      _c("SearchCard", {
                        staticClass: "peopleSearch",
                        attrs: { title: "" },
                        on: { handlerSearch: _vm.handlerSearch },
                        scopedSlots: _vm._u([
                          {
                            key: "searchContent",
                            fn: function () {
                              return [
                                _c("TaskTimeSelect", {
                                  ref: "timeRef",
                                  attrs: {
                                    days: 365,
                                    activeType: _vm.searchParams.timeType,
                                    taskType: _vm.timeType,
                                  },
                                  on: { timeSelect: _vm.timeSelect },
                                }),
                                _c("el-input", {
                                  staticClass: "search-w190 wp-input",
                                  attrs: {
                                    placeholder: "请输入人员姓名",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchParams.createName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchParams,
                                        "createName",
                                        $$v
                                      )
                                    },
                                    expression: "searchParams.createName",
                                  },
                                }),
                                _c("el-input", {
                                  staticClass: "search-w392 wp-input",
                                  attrs: {
                                    placeholder: "请输入所在单位",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchParams.custName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchParams,
                                        "custName",
                                        $$v
                                      )
                                    },
                                    expression: "searchParams.custName",
                                  },
                                }),
                                _c("wpLoadSelect", {
                                  staticClass:
                                    "search-w392 wp-select align-top",
                                  attrs: {
                                    data: _vm.taskSelectList,
                                    page: _vm.taskObj.page,
                                    dictLabel: "title",
                                    dictValue: "taskId",
                                    dictTime: "createTime",
                                    hasMore: _vm.taskObj.more,
                                    request: _vm.getData,
                                    placeholder: "请选择任务",
                                  },
                                  on: {
                                    clearTitle: function () {
                                      return (_vm.taskObj.title = "")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchParams.taskId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchParams, "taskId", $$v)
                                    },
                                    expression: "searchParams.taskId",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-tab-pane", {
                    attrs: { label: "作品明细", name: "workDetails" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "flex-Acenter fs-14" }, [
                      _c("div", [
                        _vm._v(" 完成总量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.statInfo.completeNum || 0)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.statInfo.score || 0)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-popover",
                          {
                            ref: "export-popover",
                            attrs: {
                              "popper-class": "export-popover",
                              placement: "bottom",
                              trigger: "click",
                            },
                          },
                          [
                            _c("div", { staticClass: "export-list" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "export-item wp-pointer",
                                  on: { click: _vm.detailExport },
                                },
                                [_vm._v(" 明细导出 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "export-item wp-pointer",
                                  on: { click: _vm.exportFile },
                                },
                                [_vm._v(" 分月统计 ")]
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticClass: "wp-button",
                                staticStyle: { width: "90px" },
                                attrs: { slot: "reference", size: "medium" },
                                slot: "reference",
                              },
                              [
                                _vm._v("导出"),
                                _c("i", {
                                  staticClass: "icon-zhankai el-icon--right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.tableData,
                                stripe: "",
                                sortable: "custom",
                              },
                              on: { "sort-change": _vm.sortChange },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "30" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "userName",
                                  label: "人员姓名",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "custName",
                                  label: "所在单位",
                                  "min-width": "200",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "80",
                                  align: "center",
                                  prop: "taskNum",
                                  label: "任务总数",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "wp-pointer wp-blue",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.showTaskDetails(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.taskNum) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  52096938
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "30" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "taskFinshNum",
                                  label: "完成数量",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "30" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "completionRate",
                                  label: "完成率",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(scope.row.completionRate) +
                                                "%"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3143357017
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { "min-width": "30" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  sortable: "",
                                  prop: "workNum",
                                  label: "作品数量",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  sortable: "",
                                  prop: "score",
                                  label: "奖励积分",
                                  "min-width": "120",
                                },
                              }),
                              _vm.isCanAccessExcellentReview
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      sortable: "",
                                      prop: "excellentScore",
                                      "min-width": "100",
                                      label: "优评积分",
                                    },
                                  })
                                : _vm._e(),
                              _vm.isCanAccessExcellentReview
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      sortable: "",
                                      prop: "sumScore",
                                      "min-width": "100",
                                      label: "总积分",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("TaskDetails", {
        ref: "taskDetails",
        attrs: { searchParams: _vm.taskDetailsQuery },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }