<!--第三方登录路由-->
<template>
  <div></div>
</template>

<script>
import verifyLoginMixin from '@/components/mixin/verifyLogin.js';
export default {
  name: 'Outside',
  mixins: [verifyLoginMixin],
  beforeCreate () {
  },
  created () {
    this.doLogin()
  },
  methods: {
    async doLogin () {
      let query = {
        hostId: this.$route.query.i,
      };

      console.log(query);
      await this.$store.dispatch('FedLogOut')
      const res = await this.$store.dispatch('XjxqLogin', query)
      if (Number(res.returnCode) !== 200) {
        this.$router.push('/login')
        return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
      }
      let currRoleId = res.returnData.roleId
      // 角色是超级管理员则直接跳转到 单位管理，没有切换账号页面
      // 判断是否是多角色，是多角色，需要跳转到切换登录页面
      this.goToPage(currRoleId)
    },

    // 验证是否已登录 已登录就跳转 没有登录就清除缓存
    async isLogin () {
      try {
        const res = await this.getInfo()
        if (!res) return this.doLogin()
      } catch (err) {
        console.log(err)
        this.doLogin()
      }
    }
  }
};
</script>

<style scoped></style>
