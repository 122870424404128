<template>
  <div class="drillDetail flex-EB">
    <div class="side-block w324 red-block">
      <div class="side-box red-box">
        <div class="bg-box">
          <div class="block-title flex-CB">
            <div>红方热评数（{{ redTotal }}）</div>
            <div @click="showPerson" class="wp-pointer">
              <i class="icon-chengyuancq"></i>
            </div>
          </div>
          <div class="block-content">
            <div class="list-box-mask" v-if="redTotal == 0">
              <div class="wp-noData">
                <img src="~@/assets/images/drill/redNoData.png" alt="" />
                <p>暂无内容</p>
              </div>
            </div>
            <div class="list-box-mask" v-else>
              <vue-seamless-scroll
                :data="aTeamList"
                class="content-list left-one"
                :class-option="optionBlueList"
              >
                <ul>
                  <li v-for="item in aTeamList" :key="item.id" class="flex">
                    <div class="li-left">
                      <div class="head-img">
                        <img
                          :src="item.imgUrl"
                          alt=""
                          @error="
                            () => {
                              item.imgUrl = $globalData.failAvatar;
                            }
                          "
                          referrerpolicy="no-referrer"
                        />
                      </div>
                    </div>
                    <div class="li-right">
                      <div class="title-text textOverOneLine">
                        {{ item.nickName }}
                      </div>
                      <div class="desc">
                        {{ item.content }}
                      </div>
                      <div class="infos flex-CB">
                        <div class="infos-left">
                          {{ item.fmtPublishTimeStr }}
                        </div>
                        <div class="infos-right flex-Acenter">
                          <div class="flex-CB">
                            <i class="icon-pinglun"></i>
                            <span>{{ item.commentCount }}</span>
                          </div>
                          <div class="flex-CB">
                            <i class="icon-dianzan"></i>
                            <span>{{ item.likeCount }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mid-block w352 flex-column">
      <div class="mid-top flex-CB flex-column">
        <div v-for="item in scoreList" :key="item.name" class="mode-item">
          <div class="mode-row flex-CB">
            <div class="mode-left mode-value">{{ item.aScore }}</div>
            <div class="mode-mid">
              <div class="mode-label flex-CB">
                <span>{{ item.name }}</span>
              </div>
              <el-progress
                :percentage="item.aScorePer"
                :class="item.className"
                :show-text="false"
                stroke-linecap="square"
                class="mode-progress"
                color="#DA032A"
              >
              </el-progress>
            </div>
            <div class="mode-right mode-value">{{ item.bScore }}</div>
          </div>
        </div>
      </div>
      <div class="mid-bot">
        <div class="mid-bot-title flex-CB">
          <div class="mid-bot-left flex-Acenter">
            <div class="sign"></div>
            <p>双方实时数据对比</p>
          </div>
          <div class="mid-bot-right flex-Acenter">
            <div class="dot-item flex-Acenter">
              <span class="dot"></span>红方
            </div>
            <div class="dot-item dot-item2 flex-Acenter">
              <span class="dot"></span>蓝方
            </div>
          </div>
        </div>
        <div class="mid-bot-content">
          <cate-chart
            ref="cateChart"
            id="cateChart"
            :xAxisData="xAxisData"
            :seriesData="seriesData"
            :seriesData2="seriesData2"
          >
          </cate-chart>
        </div>
      </div>
    </div>
    <div class="side-block w324 blue-block">
      <div class="side-box blue-box">
        <div class="bg-box">
          <div class="block-title flex-CB">
            <div>蓝方热评数（{{ blueTotal }}）</div>
            <div @click="showPerson" class="wp-pointer">
              <i class="icon-chengyuancq"></i>
            </div>
          </div>
          <div class="block-content">
            <div class="list-box-mask" v-if="blueTotal == 0">
              <div class="wp-noData">
                <img src="~@/assets/images/drill/blueNoData.png" alt="" />
                <p>暂无内容</p>
              </div>
            </div>
            <div class="list-box-mask" v-else>
              <vue-seamless-scroll
                :data="bTeamList"
                class="content-list left-one"
                :class-option="optionBlueList"
              >
                <ul>
                  <li v-for="item in bTeamList" :key="item.id" class="flex">
                    <div class="li-left">
                      <div class="head-img">
                        <img
                          :src="item.imgUrl"
                          alt=""
                          @error="
                            () => {
                              item.imgUrl = $globalData.failAvatar;
                            }
                          "
                          referrerpolicy="no-referrer"
                        />
                      </div>
                    </div>
                    <div class="li-right">
                      <div class="title-text textOverOneLine">
                        {{ item.nickName }}
                      </div>
                      <div class="desc">
                        {{ item.content }}
                      </div>
                      <div class="infos flex-CB">
                        <div class="infos-left">
                          {{ item.fmtPublishTimeStr }}
                        </div>
                        <div class="infos-right flex-Acenter">
                          <div class="flex-CB">
                            <i class="icon-pinglun"></i>
                            <span>{{ item.commentCount }}</span>
                          </div>
                          <div class="flex-CB">
                            <i class="icon-dianzan"></i>
                            <span>{{ item.likeCount }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
    <person-list
      ref="personList"
      :taskId="taskId"
      :isPlayback="isPlayback"
      :blueTeamUsers="blueTeamUsers"
      :redTeamUsers="redTeamUsers"
    ></person-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CateChart from '@/components/echartsComponents/cateChart';
import VueSeamlessScroll from 'vue-seamless-scroll';
import PersonList from '@/views/drill/components/personList.vue';
import * as  drillApi from '@/api/drill.js'
export default {
  name: 'drillDetail',
  components: { CateChart, VueSeamlessScroll, PersonList },
  data () {
    return {
      seriesData: [],
      seriesData2: [],
      xAxisData: [],
      taskId: '',// 任务id
      taskDetial: {},
      taskStatus: 0, //任务状态0未开始1进行中2已结束
      timerFour: null,
      scoreList: [],
      aTeamList: [],
      redTotal: 0,
      bTeamList: [],
      blueTotal: 0,
      isPlayback: false
    };
  },
  props: {},
  created () {
    if (this.$route.query.id) {
      this.taskId = this.$decrypt(this.$route.query.id)
      // 判断是不是演练回放
      if (this.$route.meta.flag && this.$route.meta.flag == 'playback') {
        this.isPlayback = true
      }
      this.getDetailTask()
    } else {
      return this.$router.push({ name: 'drillList' })
    }
    this.setScore()
    if (this.isPlayback) return
    let that = this
    window.addEventListener('resize', () => {
      that.getDetailTask()
    });
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['blueTeamUsers', 'redTeamUsers', 'lineList', 'detail', 'blueCommentList', 'redCommentList', 'frequency', 'roleId', 'visibleNum']),
    taskStatusP () {
      return this.$store.getters.taskStatus
    },
    optionBlueList () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true // 开启数据实时监控刷新dom
        // singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  methods: {
    // 展示人员列表
    showPerson () {
      // 网评员不可 查看成员
      if (this.roleId == 3 || this.roleId == 4) return
      this.$refs.personList?.show()
    },
    // 获取详情
    async getDetailTask () {
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      if (res.returnCode != 200) return
      this.taskDetial = res.returnData.taskDetial
      this.taskStatus = this.taskDetial.taskStatus
      if (this.isPlayback) return this.initPlayback()
      //任务状态0未开始1进行中2已结束
      if (this.taskStatus == 1) {
        this.setProgress()
      } else if (this.taskStatus == 2) {
        this.setEnd()
      }

    },
    // 进行中
    setProgress () {
      // 开启定时器 获取数据
      // 获取列表
      // 获取积分
      // 获取折线图
      // 获取比分
      let that = this
      that.getScore();
      that.getArticleList()
      this.getComment()
      if (that.timerFour) { this.clearIntervalFour() }
      that.timerFour = setInterval(() => {
        that.getScore();
        that.getArticleList()
        this.getComment()
      }, 10000);
    },
    // 结束
    setEnd () {
      this.clearIntervalFour()
      //获取一次
      this.getScore();
      this.getArticleList()
      this.getComment()
    },
    clearIntervalFour () {
      clearInterval(this.timerFour)
      this.timerFour = null
    },
    clearIntervalTwo () {
      clearInterval(this.timerTwo)
      this.timerTwo = null
    },
    //获取比分统计
    async getScore () {
      const res = await drillApi.taskDetail({ taskId: this.taskId });
      if (res.returnCode != 200) return;
      let aTeam = res.returnData.aTeam
      let bTeam = res.returnData.bTeam
      this.setScore(aTeam, bTeam)
    },
    // 设置比分
    setScore (aTeam, bTeam) {
      if (!aTeam && !bTeam) {
        return this.scoreList = [
          { name: "热评数", className: 'grey', aScore: 0, bScore: 0, aScorePer: 0 },
          { name: "响应人数", className: 'grey', aScore: 0, bScore: 0, aScorePer: 0 },
          { name: "评论数", className: 'grey', aScore: 0, bScore: 0, aScorePer: 0 },
          { name: "转发数", className: 'grey', aScore: 0, bScore: 0, aScorePer: 0 },
          { name: "点赞数", className: 'grey', aScore: 0, bScore: 0, aScorePer: 0 }
        ]
      }
      let scoreList = [
        { name: "热评数", className: this.setClassName(aTeam.hotComments, bTeam.hotComments), aScore: aTeam.hotComments, bScore: bTeam.hotComments, aScorePer: Number(this.$utils.getPercent(aTeam.hotComments, aTeam.hotComments + bTeam.hotComments)) },
        { name: "响应人数", className: this.setClassName(aTeam.responses, bTeam.responses), aScore: aTeam.responses, bScore: bTeam.responses, aScorePer: Number(this.$utils.getPercent(aTeam.responses, aTeam.responses + bTeam.responses)) },
        { name: "评论数", className: this.setClassName(aTeam.responses, bTeam.responses), aScore: aTeam.comments, bScore: bTeam.comments, aScorePer: Number(this.$utils.getPercent(aTeam.comments, aTeam.comments + bTeam.comments)) },
        { name: "转发数", className: this.setClassName(aTeam.forward, bTeam.forward), aScore: aTeam.forward, bScore: bTeam.forward, aScorePer: Number(this.$utils.getPercent(aTeam.forward, aTeam.forward + bTeam.forward)) },
        { name: "点赞数", className: this.setClassName(aTeam.thumbs, bTeam.thumbs), aScore: aTeam.thumbs, bScore: bTeam.thumbs, aScorePer: Number(this.$utils.getPercent(aTeam.thumbs, aTeam.thumbs + bTeam.thumbs)) }]
      this.scoreList = scoreList
    },
    setClassName (aScore, bScore) {
      if (aScore == 0 && bScore == 0) {
        return 'grey'
      }
      return 'blue'
    },
    //红蓝热评列表、弹幕列表、结算页热评Top5
    async getArticleList () {
      let query = {
        taskId: this.taskId,
        pageNo: 1,
        limit: 30,
        // type: type//队伍 1红 2蓝 不传为全部
      }
      const res = await drillApi.articleList(query);
      if (res.returnCode != 200) return;
      this.aTeamList = this.$utils.handleAvatar(res.returnData.data.filter(it => it.typeId == 1))
      this.redTotal = this.aTeamList.length
      this.bTeamList = this.$utils.handleAvatar(res.returnData.data.filter(it => it.typeId == 2))
      this.blueTotal = this.bTeamList.length
    },
    //实时评论
    async getComment () {
      let query = {
        taskId: this.taskId
      }
      const res = await drillApi.comment(query);
      if (res.returnCode != 200) return;
      let resultReadList = res.returnData.readList
      let resultBlueList = res.returnData.blueList
      this.setComment(resultReadList, resultBlueList)
    },
    // 设置实时评论
    setComment (resultReadListPram, resultBlueListPram) {
      let resultReadList = resultReadListPram;
      let readList = [0];
      resultReadList.forEach((item, index) => {
        if (index != 0 && index % 3 == 0) {
          let comments = 0;
          if (index == 3) {
            comments = Number(resultReadList[index - 3].value) + Number(resultReadList[index - 1].value) + Number(resultReadList[index - 2].value) + Number(item.value);
          } else {
            comments = Number(resultReadList[index - 1].value) + Number(resultReadList[index - 2].value) + Number(item.value);
          }
          readList.push(comments);
        }
      });
      let resultBlueList = resultBlueListPram;
      let blueList = [0];
      resultBlueList.forEach((item, index) => {
        // 每10s一个值，每3个值相加
        if (index != 0 && index % 3 == 0) {
          let comments = 0;
          if (index == 3) {
            comments = Number(resultBlueList[index - 3].value) + Number(resultBlueList[index - 1].value) + Number(resultBlueList[index - 2].value) + Number(item.value);
          } else {
            comments = Number(resultBlueList[index - 1].value) + Number(resultBlueList[index - 2].value) + Number(item.value);
          }
          // comments = Math.random(0, 100)
          blueList.push(comments);
        }
      });
      this.seriesData = blueList
      this.seriesData2 = readList
      //时间线的刻度 30s一个 去分割
      //获取 演练时长，然后去计算
      //this.taskInfo.duration
      let xAxisData = [];
      let totalS = (this.taskDetial.duration * 60) / 30;
      xAxisData.push(``);
      for (let i = 0; i < totalS; i++) {
        // xAxisData1.push(`${i + 1}0s`)
        xAxisData.push(``);
      }
      this.xAxisData = xAxisData;
      this.$nextTick(() => {
        this.$refs.cateChart?.setData()
      });
    },
    // 演练回放的处理逻辑
    initPlayback () {
      // 比分统计
      this.$nextTick(() => {
        if (this.detail.aTeam && this.detail.bTeam) {
          this.setScore(this.detail.aTeam, this.detail.bTeam)
        }
        // 获取热评列表
        this.aTeamList = this.$utils.handleAvatar(this.redCommentList?.data) || []

        this.redTotal = this.redCommentList?.data?.length || 0
        this.bTeamList = this.$utils.handleAvatar(this.blueCommentList?.data) || []
        this.blueTotal = this.blueCommentList?.data?.length || 0
        // 获取折线图
        if (this.lineList.readList && this.lineList.blueList) {
          this.setComment(this.lineList.readList, this.lineList.blueList)
        }

      })
    },

  },
  beforeDestroy () {
    this.clearIntervalFour()
  },
  watch: {
    taskStatusP: {
      handler (val, oldval) {
        if (val) {
          this.getDetailTask()
        }
      },
      deep: true
    },
    frequency: {
      handler (val, oldval) {
        if (val) {
          this.initPlayback()
        }
      },
      deep: true
    },
    // 页面的显示与隐藏监听
    visibleNum: {
      handler (val, oldval) {
        let that = this
        if (val && !this.isPlayback) {
          if (document.visibilityState === 'visible') {
            that.getDetailTask()
          }
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import './css/drillDetail.scss';
</style>
