var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-moudle-style tab-nav" },
    _vm._l(_vm.navList, function (item) {
      return _c(
        "p",
        {
          key: item.id,
          staticClass: "nav-item",
          class: { active: item.id == _vm.activeID },
          on: {
            click: function ($event) {
              return _vm.toItem(item)
            },
          },
        },
        [_vm._v(_vm._s(item.name) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }