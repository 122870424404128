var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-content" },
    [
      _c("div", { staticClass: "file-list" }, [
        _vm.fileList.length == 0
          ? _c("p", { staticClass: "placeholder-text" }, [
              _vm._v("请上传大小在5mb以内的jpg、jpeg、png格式图片"),
            ])
          : _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
              _c(
                "ul",
                { staticClass: "file-list-box" },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      ref: "uploadList",
                      refInFor: true,
                      staticClass: "textOverOneLine",
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: item.nameNo,
                            placement: "bottom",
                            disabled: !_vm.isShowTooltipWidth,
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "textOverOneLine",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.visibilityChangeWidth($event)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.nameNo))]
                          ),
                        ]
                      ),
                      _c("img", {
                        staticClass: "del",
                        attrs: {
                          src: require("@/assets/images/guanbi.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeFile(item, index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
      ]),
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploadList.length < _vm.limit,
              expression: "uploadList.length < limit",
            },
          ],
          ref: "_upload",
          staticClass: "upload-demo",
          attrs: {
            action: "$globalData.file.uploadUrl",
            "http-request": _vm.httpRequest,
            accept: _vm.accept,
            limit: _vm.limit,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.handleBeforeUpload,
            "on-error": _vm.handleError,
            "on-success": _vm.handleSuccess,
            "on-change": _vm.handleChange,
            "on-progress": _vm.handleProgress,
            "show-file-list": false,
            "file-list": _vm.uploadList,
            disabled: _vm.uploadList.length >= _vm.limit,
            multiple: "",
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: {
                icon: "icon-shanchuan",
                type: "primary",
                disabled: _vm.uploadList.length >= _vm.limit,
              },
            },
            [_vm._v("上传资料")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }