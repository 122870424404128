var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teamlist main" }, [
    _c(
      "div",
      { staticClass: "main-top" },
      [
        _c(
          "TabsCard",
          {
            attrs: { tabs: _vm.tabs, activeTab: _vm.activeTab },
            on: { handlerTabs: _vm.handlerTabs },
          },
          [
            _c("el-tab-pane", {
              attrs: { label: "人员队伍", name: "personnelTeam" },
            }),
            _vm.roleId != this.$ENUM.SYSROLE.GROUPLEADER
              ? _c("el-tab-pane", {
                  attrs: { label: "常用人员", name: "commonPersonnel" },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm.activeTab == "personnelTeam"
      ? _c("div", { staticClass: "main-bot  flex" }, [_c("TeamTable")], 1)
      : _vm.activeTab == "commonPersonnel"
      ? _c("div", { staticClass: "main-bot  flex" }, [_c("CommonTable")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }