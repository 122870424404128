<template>
  <div>
    <el-popover
      placement="top"
      width="520"
      @hide="hidePopover"
      popper-class="reject-popper"
      v-model="visible"
      trigger="click"
      v-if="popoverType === 'add'"
    >
      <el-input
        style="width: 472px"
        type="textarea"
        :rows="6"
        maxlength="50"
        v-model="value"
        :placeholder="placeholder"
      ></el-input>

      <div class="btn">
        <el-button
          class="wp-button"
          size="medium"
          type="text"
          @click="visible = false"
          >取消</el-button
        >
        <el-button
          class="wp-button"
          size="medium"
          type="primary"
          @click="handlerReject"
          >确定</el-button
        >
      </div>
      <div slot="reference">
        <slot></slot>
      </div>
    </el-popover>

    <el-popover
      placement="top"
      width="520"
      popper-class="reject-popper"
      trigger="hover"
      v-else
    >
      <div class="flex-CB">
        <div class="flex-Acenter">
          <el-avatar class="avatar" :src="headUrl"></el-avatar>
          <div class="createName textOverOneLine">
            {{ rejectData.createName }}
          </div>
        </div>
        <div class="time">
          {{ $moment(rejectData.createTime).format('YYYY-MM-DD HH:mm') }}
        </div>
      </div>
      <div class="reject-text">
        {{ rejectData.resonRemark }}
      </div>

      <div slot="reference">
        <slot></slot>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    popoverType: {
      type: String,
      default: 'add'
    },
    rejectObj: {
      type: Object,
      default: () => { }
    },
    placeholder: {
      type: String,
      default: '请输入驳回原因'
    }
  },
  data () {
    return {
      value: "",
      visible: false,
      rejectData: {},
      headUrl: require("@/assets/images/touxiang.png")
    }
  },
  methods: {
    // 提交驳回信息
    handlerReject () {
      if (this.value) {
        this.$emit('handlerReject', this.value)
        this.visible = false
      } else {
        this.$showMessage({ type: 'warning', msg: this.placeholder })
      }
    },

    // 打开前清除上一次的数据
    showPopover () {
      console.log('清除上一次的数据')
      this.rejectData = {}
    },

    // 隐藏时回调
    hidePopover () {
      this.value = ""
    }
  },
  watch: {
    rejectObj: {
      handler (newVal) {
        console.log('rejectObj')
        console.log(newVal);
        this.rejectData = { ...newVal }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">
.reject-popper {
  padding: 24px;
}
</style>