<template>
  <el-form
    class="form"
    :model="basicsForm"
    :rules="basicsFormRules"
    ref="basicsForm"
    label-width="100px"
  >
    <el-form-item class="item-content" label="任务分类：" prop="taskTypeId">
      <el-select
        class="wp-select task-w726"
        v-model="basicsForm.taskTypeId"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in taskClassify"
          :key="item.taskTypeId"
          :label="item.taskTypeName"
          :value="item.taskTypeId"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item class="item-content" label="任务类型：" prop="taskStyle">
      <div class="flex">
        <div
          class="type-box"
          :class="basicsForm.taskStyle == item.dataValue ? 'active-type' : ''"
          v-for="item in taskTypeList"
          :key="item.dataValue"
          @click="handlerTaskType(item)"
        >
          {{ item.dataKey }}
        </div>
      </div>
    </el-form-item>

    <el-form-item
      class="item-content"
      label="任务链接："
      prop="url"
      v-if="dictKey == '链接类'"
    >
      <el-input
        :disabled="basicsForm.isDisbled"
        @change="changeUrl"
        class="wp-input task-w726"
        maxlength="2000"
        v-model.trim="basicsForm.url"
        placeholder="请输入任务链接"
      ></el-input>
    </el-form-item>

    <el-form-item
      v-if="dictKey == '图片视频类'"
      label="图片视频："
      prop="fileJson"
      :required="dictKey == '图片视频类'"
    >
      <UploadFile
        key="image"
        accept=".jpg, .jpeg, .png, .mp4"
        placeholder="请上传jpg、jpeg、png、mp4格式文件"
        :fileList="basicsForm.fileJson"
        @fileChange="fileChange"
        @changeUpload="changeUpload"
        :fileType="['jpg', 'jpeg', 'png', 'mp4']"
      ></UploadFile>
    </el-form-item>

    <el-form-item class="item-content" label="任务标题：" prop="title">
      <el-input
        class="wp-input task-w726"
        v-model="basicsForm.title"
        :placeholder="
          dictKey == '视频号类'
            ? '请输入任务标题（例：视频标题）'
            : '请输入任务标题'
        "
      ></el-input>
    </el-form-item>

    <el-form-item
      v-if="dictKey == '视频号类'"
      class="item-content"
      label="视频号："
      prop="author"
    >
      <el-input
        class="wp-input task-w726"
        v-model="basicsForm.author"
        placeholder="请输入视频号"
      ></el-input>
    </el-form-item>

    <el-form-item label="任务要求：" prop="content">
      <el-input
        class="task-w726 textarea"
        type="textarea"
        maxlength="1000"
        :rows="6"
        v-model="basicsForm.content"
        placeholder="请输入任务要求和口径"
      ></el-input>
    </el-form-item>

    <QquickLanguage
      ref="quick"
      style="margin-left: 100px; margin-top: 22px; margin-bottom: 10px"
      @addQuick="addQuick"
      @clickShortcut="clickShortcut"
      @removeQuick="removeQuick"
      :shortcutList="shortcutList"
    ></QquickLanguage>

    <el-form-item
      v-if="dictKey == '视频号类'"
      label="目标图片："
      prop="fileJson"
      :rules="[{ required: false }]"
    >
      <UploadFile
        key="video"
        placeholder="请上传jpg、jpeg、png格式文件"
        @fileChange="fileChange"
        :fileList="basicsForm.fileJson"
        @changeUpload="changeUpload"
        :fileType="['jpg', 'jpeg', 'png']"
      ></UploadFile>
    </el-form-item>
    <el-form-item label="任务备注：" style="margin-top: 22px;">
      <el-input
        class="task-w726 textarea task-notes"
        type="textarea"
        maxlength="500"
        v-model.trim="basicsForm.remark"
        resize="none"
        placeholder="请输入任务任务备注"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import taskApi from '@/api/task.js'
import { validTaskURL } from '@/utils/validate.js';
import UploadFile from '@/views/components/uploadFile'
import QquickLanguage from '@/views/components/quickLanguage'
export default {
  components: { QquickLanguage, UploadFile },
  props: {
    taskTypeId: [String, Number],
    // 表单数据
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    var validateUrl = (rule, value, callback) => {
      console.log(value);
      if (value === '') {
        this.urlFlag = false
        callback(new Error('请输入任务链接'));
      } else if (!validTaskURL(value)) {
        this.urlFlag = false
        callback(new Error('请输入有效任务链接'));
      } else {
        this.urlFlag = true
        callback();
      }
      console.log(this.urlFlag);
    };
    return {
      // 快捷语列表
      shortcutList: [],
      basicsForm: {
        taskStyle: "", // 任务类型
        taskTypeId: "", // 任务分类Id
        fileJson: "", // 任务类型
        title: "", // 任务标题
        content: "", // 任务要求
        author: "", // 视频号
        url: "", // 任务链接
        remark:'' // 任务备注
      },
      urlFlag: false, // 链接是否通过校验
      imageUrl: "",
      basicsFormRules: {
        taskTypeId: [{ required: true, trigger: ["blur", "change"], message: "请选择任务分类" }],
        taskStyle: [{ required: true, trigger: "blur", message: "请选择任务类型" }],
        fileJson: [{ required: true, trigger: ["blur", "change"], message: "请上传jpg、jpeg、png、mp4格式文件" }],
        title: [{ required: true, trigger: "blur", message: "请输入任务标题" }],
        url: [
          { required: true, trigger: "change", message: "请输入任务链接" },
          { validator: validateUrl, trigger: 'change' }
        ],
        content: [{ required: true, trigger: ["blur", "change"], message: "请填写任务要求" }],
        author: [{ required: true, trigger: "blur", message: "请输入视频号" }],
      },
      taskClassify: [], // 任务分类列表
      taskTypeList: [],
      dictKey: "", // 分类名称
      isUpload: false
    }
  },
  watch: {
    formData: {
      handler (newVal) {
        // 获取任务类型
        this.getTaskType()
        // 获取任务分类
        this.getTaskClassify()

        this.$nextTick(() => {
          console.log(newVal);
          this.basicsForm = { ...newVal }
          this.basicsForm.fileJson = JSON.parse(newVal.fileJson)
        })
      },
      deep: true
    }
  },
  created () {
    // 获取快捷语
    this.getQuickLanguage()
  },

  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      this.isUpload = isUpload
    },

    /** 文件上传成功回调 */
    fileChange (fileList) {
      this.$set(this.basicsForm, 'fileJson', fileList)
      // this.basicsForm.fileJson = fileList
      // console.log(fileList);
    },

    // 点击快捷语
    clickShortcut (remark) {
      console.log(remark);
      this.$set(this.basicsForm, 'content', remark)
    },

    // 监听链接输入框
    async changeUrl () {
      if (this.urlFlag) {
        const res = await taskApi.getWebsite({ url: this.basicsForm.url })
        if (res.returnCode == 200) {
          const ret = res?.returnData

          // 站点名称
          this.basicsForm.websiteName = ret?.sysDict?.dataKey
          // 站点Id
          this.basicsForm.websiteId = ret?.sysDict?.dataValue

          if ((ret.website ?? '') != '') {
            // 任务标题
            this.$set(this.basicsForm, 'title', ret?.website?.title)
          } else {
            this.$showMessage({ type: 'warning', msg: '未获取到文章标题，请手动填写' })
          }

          // 任务方式列表(Vuex)
          this.$emit("getStyleList", ret?.styleList)
        }
      }
    },

    // 任务分类
    async getTaskClassify () {
      const res = await taskApi.taskTypeList({ taskTypeName: "" })
      if (res.returnCode == 200) {
        this.taskClassify = res.returnData || []

        const { taskTypeId, taskTypeName } = this.basicsForm
        console.log(taskTypeId, taskTypeName);
        const index = res.returnData.findIndex(it => it.taskTypeId == taskTypeId)
        // 返回的分类中如果没有当前分类,则添加到返回数组中
        if (index == -1) {
          this.taskClassify.unshift({ taskTypeName, taskTypeId })
        }

        console.log(this.taskClassify);

        if (+this.taskTypeId) {
          this.basicsForm.taskTypeId = +this.taskTypeId
        }
      }
    },

    // 获取任务类型
    async getTaskType () {
      const res = await taskApi.dictList({ dataType: "taskType" })
      if (res.returnCode == 200) {
        this.taskTypeList = res.returnData

        this.dictKey = res.returnData.find(item => item.dataValue == this.basicsForm.taskStyle).dataKey
        console.log(this.dictKey)
      }
    },

    // 获取快捷语列表
    async getQuickLanguage () {
      const res = await taskApi.remarkList()
      if (res.returnCode == 200) {
        this.shortcutList = res.returnData
      }
    },

    // 添加快捷语
    async addQuick (remark) {
      const res = await taskApi.remarkInsert({ remark })
      if (res.returnCode == 200) {
        this.$refs.quick.hide()
        this.getQuickLanguage()
      }
    },

    // 删除快捷语
    async removeQuick (item) {
      const { taskMainRemarkId } = item
      const res = await taskApi.remarkDelete({ taskMainRemarkId })
      if (res.returnCode == 200) {
        this.getQuickLanguage()
      } else {
        this.$showMessage({ type: 'error', msg: res.returnMsg })
      }
    },

    // 获取表单数据
    getFormData () {
      if (this.isUpload) {
        this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
      } else {
        console.log(this.basicsForm);
        let flag = false
        this.$refs.basicsForm.validate(valid => {
          if (valid) {
            flag = true
          } else {
            flag = false
          }
        })
        if (flag) {
          const query = JSON.parse(JSON.stringify(this.basicsForm))

          if (query?.fileJson?.length) {
            query.fileJson = JSON.stringify(query.fileJson)
          }
          query.pass = true

          return query
        } else {
          return { pass: false }
        }
      }
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.basicsForm.resetFields()
    },

    // 任务类型点击回调
    handlerTaskType (item) {
      if (this.basicsForm.isDisbled) return
      // 点击的任务类型非重复
      if (this.basicsForm.taskStyle !== item.dataValue) {
        // 保存任务分类Id
        const id = this.basicsForm.taskTypeId

        // 清空表单信息,保留任务分类
        this.$refs.basicsForm.resetFields()
        this.basicsForm = {}
        this.$set(this.basicsForm, 'taskTypeId', id)
        // 获取任务方式
        this.getTaskStyleList(item)

        this.isUpload = false

        // 任务类型Id
        this.basicsForm.taskStyle = item.dataValue
        // 任务类型名称
        this.dictKey = item.dataKey
      }
    },

    /** 获取任务方式 */
    async getTaskStyleList (item) {
      if (item.dataKey == '链接类') {
        this.$emit("getStyleList", [])
        return false
      }

      // 站点列表
      const websiteList = JSON.parse(localStorage.getItem("websiteList"))
      console.log(websiteList);
      // 站点
      let website = ''

      if (item.dataKey == '图片视频类') {
        website = websiteList.find(item => item.dataKey == '图片视频')
        this.basicsForm.websiteName = website.dataKey
        this.basicsForm.websiteId = website.dataValue
      } else if (item.dataKey == '视频号类') {
        website = websiteList.find(item => item.dataKey == "微信视频号")
        this.basicsForm.websiteName = website.dataKey
        this.basicsForm.websiteId = website.dataValue
      }

      // 获取任务方式
      const res = await taskApi.taskStyleList({ taskType: item.dataValue, taskStationId: website.dictId })
      if (res.returnCode == 200) {
        this.$emit("getStyleList", res.returnData)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>