var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "wp-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "680px",
            height: "477px",
            dialogVisible: _vm.dialogVisible,
            cancelText: _vm.cancelText,
            determineText: _vm.determineText,
            showDefaultFooter: _vm.step == 1,
            showSlotFooter: _vm.step == 2,
          },
          on: {
            cancel: _vm.cancel,
            determine: _vm.determine,
            handleClose: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "createModel" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step == 1,
                    expression: "step == 1",
                  },
                ],
                staticClass: "stepOne",
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "ruleform",
                    attrs: { model: _vm.ruleForm, rules: _vm.rules },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "title", label: "公告标题" } },
                      [
                        _c("el-input", {
                          staticClass: "wp-input",
                          attrs: {
                            maxlength: "50",
                            placeholder: "请输入公告标题",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.determine.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.ruleForm.title,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "title",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "公告内容", prop: "content" } },
                      [
                        _c("el-input", {
                          staticClass: "remarks",
                          attrs: {
                            type: "textarea",
                            maxlength: "500",
                            placeholder: "请输入公告内容",
                            resize: "none",
                          },
                          model: {
                            value: _vm.ruleForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "content", $$v)
                            },
                            expression: "ruleForm.content",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "upload-form-item",
                        attrs: { label: "上传附件" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "upload-box" },
                          [
                            _c("UploadFile", {
                              key: "img",
                              ref: "upload",
                              attrs: { fileList: _vm.fileList },
                              on: {
                                fileChange: _vm.fileChange,
                                changeUpload: _vm.changeUpload,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "弹窗强提醒:" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#0064E6",
                            "inactive-color": "#CCCCCC",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          on: { change: _vm.changeRemindStatus },
                          model: {
                            value: _vm.ruleForm.remindStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "remindStatus", $$v)
                            },
                            expression: "ruleForm.remindStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ruleForm.remindStatus == 1,
                            expression: "ruleForm.remindStatus == 1",
                          },
                        ],
                        attrs: {
                          label: "提醒时间:",
                          required: "",
                          error: _vm.errorMessage,
                          "show-message": "",
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-Acenter" }, [
                          _c(
                            "div",
                            {
                              staticClass: "wp-date-picker",
                              staticStyle: {
                                width: "500px",
                                "margin-right": "13px",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm",
                                  "value-format": "yyyy-MM-dd HH:mm",
                                  size: "small",
                                  "prefix-icon": "",
                                  clearable: "",
                                  type: "datetimerange",
                                  "range-separator": "-",
                                  "start-placeholder": "请选择开始时间",
                                  "end-placeholder": "请选择结束时间",
                                  "picker-options": _vm.pickerOptions,
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.ruleForm.remindTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "remindTime", $$v)
                                  },
                                  expression: "ruleForm.remindTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "addYj",
                              class: { active: _vm.isYj },
                              on: { click: _vm.changeRemindTime },
                            },
                            [_vm._v(" 永久 ")]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ruleForm.remindStatus == 1,
                            expression: "ruleForm.remindStatus == 1",
                          },
                        ],
                        attrs: { label: "提醒频率：", required: "" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.remindNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "remindNum", $$v)
                              },
                              expression: "ruleForm.remindNum",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "-1" } }, [
                              _vm._v("每次登录"),
                            ]),
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("仅一次"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step == 2,
                    expression: "step == 2",
                  },
                ],
                staticClass: "stepTwo",
              },
              [
                _c("div", { staticClass: "stepTwo-box" }, [
                  _c("div", { staticClass: "stepTwo-box-left" }, [
                    _c("div", { staticClass: "box-top box-top-left" }, [
                      _c("p", [_vm._v("选择组")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "box-content" },
                      [
                        _c(
                          "el-scrollbar",
                          { staticStyle: { height: "100%" } },
                          [
                            _c(
                              "div",
                              [
                                _c("el-tree", {
                                  ref: "tree",
                                  attrs: {
                                    data: _vm.asideList,
                                    "show-checkbox": "",
                                    "node-key": "id",
                                    "default-expanded-keys": _vm.expandedKeys,
                                    props: _vm.defaultProps,
                                  },
                                  on: { check: _vm.checkTree },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            { staticClass: "custom-tree-node" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-labe textOverOneLine",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(node.label) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2717317535
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "stepTwo-box-right" }, [
                    _c(
                      "div",
                      { staticClass: "box-top" },
                      [
                        _c("p", [_vm._v("已选组")]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: { click: _vm.delAllNode },
                          },
                          [_vm._v("清空所有")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box-content" },
                      [
                        _c(
                          "el-scrollbar",
                          { staticStyle: { height: "100%" } },
                          [
                            _c(
                              "ul",
                              { staticClass: "box-list" },
                              _vm._l(_vm.rightList, function (item) {
                                return _c("li", { key: item.id }, [
                                  _c("p", { staticClass: "textOverOneLine" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/guanbi.png"),
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delNode(item)
                                      },
                                    },
                                  }),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "template",
            { slot: "slotFooter" },
            [
              _c(
                "el-button",
                {
                  staticClass: "wp-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.cancelText) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" },
                  ],
                  staticClass: "wp-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.determine.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.determineText) + " ")]
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "25px" },
                  model: {
                    value: _vm.sendMsg,
                    callback: function ($$v) {
                      _vm.sendMsg = $$v
                    },
                    expression: "sendMsg",
                  },
                },
                [
                  _vm._v(" 短信通知（余量："),
                  _c("span", { staticClass: "wp-blue" }, [
                    _vm._v(_vm._s(_vm.messageNum)),
                  ]),
                  _vm._v(" ）"),
                ]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }