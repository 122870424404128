var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-drill-dialog notStarted" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-body" }, [
            _c("div", {
              staticClass: "close-btn",
              on: { click: _vm.handleClose },
            }),
            _c("div", { staticClass: "dialog-body-content " }, [
              _c("div", { staticClass: "flex-center" }, [
                _c("div", { staticClass: "time-down flex-column-center" }, [
                  _c("div", { staticClass: "mode-label flex-CB" }, [
                    _c("span", [_vm._v("开始倒计时")]),
                  ]),
                  _c("div", { staticClass: "count-down flex-Acenter" }, [
                    _c("p", { style: "width:" + _vm.pWidth + "px;" }, [
                      _vm._v(_vm._s(_vm.distanceTime.hh)),
                    ]),
                    _c("span", [_vm._v(":")]),
                    _c("p", { style: "width:" + _vm.pWidth + "px;" }, [
                      _vm._v(_vm._s(_vm.distanceTime.mm)),
                    ]),
                    _c("span", [_vm._v(":")]),
                    _c("p", { style: "width:" + _vm.pWidth + "px;" }, [
                      _vm._v(_vm._s(_vm.distanceTime.ss)),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex-center" }, [
                _c("div", { staticClass: "textOverOneLine title" }, [
                  _vm._v(" " + _vm._s(_vm.taskDetial.keyword) + " "),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }