import { post, get, getImg } from '@/utils/requestTwo'

// 公告查询
export function pageList (query) {
  return post(`/sysMessage/pageList`, query)
}

// 新增
export function insert (query) {
  return post(`/sysMessage/insert`, query, false)
}

// 修改
export function update (query) {
  return post(`/sysMessage/update`, query, false)
}

// 公告单个查询
export function getMessage (query) {
  return post(`/sysMessage/get`, query)
}

// 删除
export function deleteMessage (query) {
  return post(`/sysMessage/delete`, query)
}

// 接收人修改状态
export function updateReceiver (query) {
  return post(`/sysMessage/updateReceiver`, query)
}
//获取未读数量根据单位区分
export function custCount (query) {
  return post(`/sysMessage/custCount`, query)
}

//获取总未读数量
export function count (query) {
  return post(`/sysMessage/count`, query)
}

// 是否存在强提醒
export function remindExists (query) {
  return post(`/sysMessage/remindExists`, query,false)
}

// 查询强提醒列表
export function remindMsgList (query) {
  return post(`/sysMessage/remindMsgList`, query,false)
}



