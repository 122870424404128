<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          完成人数：<span class="wp-blue">{{
            statisticsData.completeNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          作品数量：<span class="wp-blue">{{
            statisticsData.articleNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-popover popper-class="export-popover" ref="export-popover" placement="bottom" trigger="click">
          <div class="export-list">
            <div class="export-item wp-pointer" @click="detailExport">
              明细导出
            </div>
            <div class="export-item wp-pointer" @click="mounthExport">
              分月统计
            </div>
          </div>
          <el-button style="width: 90px" slot="reference" class="wp-button" size="medium">导出<i class="icon-zhankai el-icon--right"></i></el-button>
        </el-popover>
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table :data="tableData" stripe sortable="custom" @sort-change="sortChange" :default-sort="sortObj" v-if="tableData.length !== 0">
        <el-table-column align="center" type="index" label="序号" width="70">
        </el-table-column>
        <el-table-column width="80"> </el-table-column>
        <el-table-column align="left" prop="groupName" label="分组名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="popleNum" label="完成人数">
        </el-table-column>
        <el-table-column v-if="searchParams.timeType == 2" align="center" prop="wcRate" label="完成率">
          <template slot-scope="scope">
            <div>{{ scope.row.wcRate }}%</div>
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="artcileCount" label="作品数量">
        </el-table-column>
        <el-table-column align="center" sortable prop="totalScore" label="奖励积分">
        </el-table-column>
      </el-table>

      <div class="wp-noData" v-if="tableData.length == 0 && !loading">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'

export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    },
    searchParams: {
      type: Object,
      default: () => { }
    },
    loading: false
  },
  data () {
    return {
      exportType: "", // 所选导出类型
      sortObj: {}
    }
  },
  created () {

  },
  methods: {
    // 导出
    async detailExport (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.teamMumberExport({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    // 分月导出
    async mounthExport (event) {
      if (!this.searchParams.startTime || !this.searchParams.endTime) {
        return this.$showMessage({ type: 'warning', msg: "请先选择时间段" })
      }

      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await taskApi.teamMonthExport({ ...this.searchParams })
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }
      this.$emit("sortChange", { orderField: prop, sort: order })
    }
  }
}
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}
</style>
