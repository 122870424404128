// 角色  1.管理员2.组管理员（管理员卡片） 3.组管理员（投稿员卡片）4.组长 5.组员
const SYSROLE = {
  SUPERADMIN: 1, // 1超级管理员,
	ADMIN: 2, // 2管理员,
	GROUPLEADER: 3, // 3组长
  TEAMMEMBERS: 4, // 4 组员/网评员
  TEAMADMIN: 5, // 5 组管理员（管理员卡片）
}

export default {
	SYSROLE
}