<template>
  <div>
    <div class="flex-CB">
      <div class="flex-Acenter fs-14">
        <div>
          任务数量：<span class="wp-blue">{{
            statisticsData.taskNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          完成人数：<span class="wp-blue">{{
            statisticsData.completeNum || 0
          }}</span>
        </div>
        <div class="ml-30">
          奖励积分：<span class="wp-blue">{{ statisticsData.score || 0 }}</span>
        </div>
      </div>
      <div>
        <el-button @click="exportFile" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu">导出</el-button>
      </div>
    </div>

    <div class="wp-table mt-20">
      <el-table :data="taskList" stripe sortable="custom" @sort-change="sortChange" :default-sort="sortObj" v-if="taskList.length !== 0">
        <el-table-column align="center" prop="taskNumber" label="任务编号" max-width="100" min-width="80">
        </el-table-column>
        <el-table-column align="left" prop="title" label="任务标题" max-width="250" min-width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column max-width="30" min-width="10"> </el-table-column>
        <el-table-column align="left" prop="url" label="任务链接" max-width="200" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.url" class="wp-blue wp-pointer textOverOneLine" @click="$utils.download(scope.row.url)">
              {{ scope.row.url }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column max-width="40" min-width="20"> </el-table-column>
        <el-table-column align="left" prop="modeStyle" label="任务方式" max-width="150" min-width="90" show-overflow-tooltip>
        </el-table-column>
        <el-table-column max-width="20" min-width="10"> </el-table-column>
        <el-table-column align="center" prop="createTime" label="发布时间" max-width="200" min-width="150">
          <template slot-scope="scope">
            <div>
              {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="completeNum" label="完成人数">
        </el-table-column>
        <el-table-column v-if="searchParams.timeType == 2" align="center" prop="completionRate" label="完成率">
          <template slot-scope="scope">
            <div>{{ scope.row.completionRate }}%</div>
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="workNum" label="作品数量" show-overflow-tooltip width="92">
        </el-table-column>
        <el-table-column max-width="10" min-width="5"> </el-table-column>
        <el-table-column align="center" sortable prop="totalScore" label="奖励积分" show-overflow-tooltip width="92">
        </el-table-column>
        <el-table-column width="10"> </el-table-column>
      </el-table>
      <div class="wp-noData" v-if="tableData.length == 0 && !loading">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    statisticsData: {
      type: Object,
      default: () => { }
    },
    searchParams: {
      type: Object,
      default: () => { }
    },
    loading: false
  },
  watch: {
    tableData: {
      handler (newVal) {
        this.taskList = newVal?.map(item => {
          // 任务方式
          item.modeStyle = item?.modeStyleList?.map(it => it.dictKey).join(",")
          return item
        })
      }
    }
  },
  data () {
    return {
      taskList: [],
      sortObj: {}
    }
  },
  methods: {
    // 导出
    async exportFile (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.taskStatisticsExport({ ...this.searchParams })
        this.isExporting = false
        if (res.returnCode == 200) {
          this.isExporting = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },

    // 清空sort
    clearSort () {
      this.sortObj = {}
    },

    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      this.sortObj = { prop, order }

      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }
      this.$emit("sortChange", { orderField: prop, sort: order })
    }
  }
}
</script>
