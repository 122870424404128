import { post, get, getImg } from '@/utils/requestTwo'

// 下载中心列表
export function downloadPageList (query) {
	return post(`/download/pageList`, query)
}

// 删除
export function deleteDownload (query) {
	return post(`/download/delete`, query)
}
//  删除 所有 deleteAll
export function deleteAllDownload (query) {
	return post(`/download/deleteAll`, query)
}

// 上传剪切板
export function fileUploadClipBoardImg (query) {
	return post(`/file/fileUploadClipBoardImg`, query, false)
}
