<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="wpyUnitTabs.length > 1">
        <div class="title-header-left">
          <UnitTab
            :tabsList="wpyUnitTabs"
            :activeId="activeName"
            @handleClick="handleClick"
          ></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="上报统计" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <TaskTimeSelect
              :days="365"
              :activeType="searchParams.timeType"
              :taskType="timeType"
              @timeSelect="timeSelect"
              ref="timeRef"
            ></TaskTimeSelect>

            <el-input
              class="search-w392 wp-input"
              v-model="searchParams.title"
              placeholder="请输入专题名称"
              clearable
            ></el-input>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                上报数量：<span class="wp-blue">{{
                  tableInfo.REPORTNUM || 0
                }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{
                  tableInfo.SCORE || 0
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                prop="topicNum"
                width="100"
                label="专题编号"
              >
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                align="left"
                prop="title"
                label="专题名称"
                min-width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                min-width="150"
                show-overflow-tooltip
                v-if="isWpys"
                align="left"
                prop="custName"
                label="来源单位"
              >
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                width="170"
                show-overflow-tooltip
                align="center"
                prop="createTime"
                label="创建时间"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column max-width="50" min-width="10"> </el-table-column>
              <el-table-column
                align="center"
                prop="reportNum"
                label="上报数量"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="score"
                label="奖励积分"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.score == -999 ? '按平台' : scope.row.score }}
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import wpyReportedApi from '@/api/wpyReported.js'
import { mapGetters, mapState, mapMutations } from 'vuex';

import UnitTab from '../components/unitTab/unitTab.vue';
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { SearchCard, TaskTimeSelect, UnitTab },
  data () {
    return {
      activeName: '',
      searchParams: {
        page: 1,
        limit: 30,
        timeType: 2, // 1 作品时间 2专题时间
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        title: "", // 标题
        custId: "", // 单位Id
      },
      timeType: [
        {
          name: "专题创建时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        },
      ],
      loading: false,
      tableData: [], // 列表
      total: 0, //　总条数
      tableInfo: {}, // 表格统计信息
    }
  },

  mounted () {
    this.$refs.timeRef.initTime()
    this.activeName = this.wpyUnitTabs[0].custId
    this.searchParams.custId = this.activeName

    // 获取上报统计列表
    this.getWpyReportSummary()
  },

  computed: {
    ...mapGetters(['isWpys', 'wpyUnitTabs']),
  },

  methods: {
    /** 上报统计 */
    async getWpyReportSummary () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyReportedApi.wpyReportSummary({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
          this.tableInfo = res?.reportTotal || {}
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 时间类型选择回调
    timeSelect (data) {
      this.searchParams = { ...this.searchParams, ...data }
    },

    /** 搜索按钮点击回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getWpyReportSummary()
    },

    /** tab点击回调 */
    handleClick (tab) {
      // 恢复初始值
      this.searchParams.topicNum = ""
      this.searchParams.title = ""
      this.searchParams.page = 1
      this.searchParams.limit = 30

      this.searchParams.custId = tab
      // 获取专题列表
      this.getWpyReportSummary()
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getWpyReportSummary()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getWpyReportSummary()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>