var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.wpyUnitTabs.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.wpyUnitTabs,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "我的作品" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("TaskTimeSelect", {
                          ref: "taskTimeRef",
                          attrs: {
                            activeType: _vm.searchParams.timeType,
                            taskType: _vm.timeType,
                          },
                          on: { timeSelect: _vm.timeSelect },
                        }),
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: {
                            placeholder: "请输入任务标题",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "title", $$v)
                            },
                            expression: "searchParams.title",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: {
                              clearable: "",
                              placeholder: "请选择审核状态",
                            },
                            model: {
                              value: _vm.searchParams.articleStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "articleStatus", $$v)
                              },
                              expression: "searchParams.articleStatus",
                            },
                          },
                          _vm._l(_vm.$globalData.REVIEWSTATUS, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }, [
                      _c("div", [
                        _vm._v(" 提交总量："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ml-30" }, [
                        _vm._v(" 奖励积分："),
                        _c("span", { staticClass: "wp-blue" }, [
                          _vm._v(_vm._s(_vm.totalScore || 0)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  "min-width": "50",
                                },
                              }),
                              _c("el-table-column", { attrs: { width: "20" } }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "taskTitle",
                                  "show-overflow-tooltip": "",
                                  "min-width": "150",
                                  label: "任务标题",
                                },
                              }),
                              _vm.isWpys
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "custName",
                                      label: "来源单位",
                                      "min-width": "150",
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "province",
                                  label: "截图/附件",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          JSON.parse(scope.row.fileJson).length
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.goDetail(
                                                        scope.row.taskArticleId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        "visible-arrow": false,
                                                        trigger: "hover",
                                                        "popper-class":
                                                          "img-popper",
                                                        tabindex: 3,
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass:
                                                          "scaled-image",
                                                        attrs: {
                                                          src: _vm.$utils.handlePreviewImg(
                                                            scope.row.fileJson
                                                          ),
                                                          fit: "contain",
                                                        },
                                                      }),
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "22px",
                                                          height: "22px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          src: _vm.$utils.handlePreviewImg(
                                                            scope.row.fileJson
                                                          ),
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2544266457
                                ),
                              }),
                              _c("el-table-column", { attrs: { width: "30" } }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "createName",
                                  label: "人员姓名",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "createTime",
                                  label: "提交时间",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "articleStatus",
                                  label: "审核状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.articleStatus == 2
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "noreceive-circle",
                                                  }),
                                                  _c(
                                                    "RejectCause",
                                                    {
                                                      key: scope.row
                                                        .taskArticleId,
                                                      attrs: {
                                                        popoverType: "detail",
                                                        rejectObj:
                                                          _vm.rejectObj,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "reject",
                                                          on: {
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.rejectObj =
                                                                  Object.assign(
                                                                    {},
                                                                    scope.row
                                                                      .wpTaskArticleVerify
                                                                  )
                                                              },
                                                          },
                                                        },
                                                        [_vm._v(" 已驳回 ")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass: "pass-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "pass" },
                                                    [_vm._v("已通过")]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2549996947
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex-center" },
                                            [
                                              _c("el-button", {
                                                staticClass: "wp-btn-icon-big",
                                                class:
                                                  scope.row.taskStatus == 2
                                                    ? "no-drop"
                                                    : "",
                                                attrs: {
                                                  type: "text",
                                                  icon: "icon-bianji1",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editWork(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("el-button", {
                                                staticClass: "wp-btn-icon-big",
                                                class:
                                                  scope.row.taskStatus == 2 ||
                                                  scope.row.articleStatus == 2
                                                    ? "no-drop"
                                                    : "",
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  icon: "icon-shanchu",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeWork(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2768339096
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "task-dialog",
        {
          attrs: {
            title: "编辑",
            width: "680px",
            height: "100%",
            dialogVisible: _vm.workVisible,
          },
          on: {
            cancel: _vm.cancel,
            determine: _vm.submitForm,
            "update:dialogVisible": function ($event) {
              _vm.workVisible = $event
            },
            "update:dialog-visible": function ($event) {
              _vm.workVisible = $event
            },
            handleClose: _vm.cancel,
          },
        },
        [
          _c("div", { staticClass: "word-dialog" }, [
            _vm.articleData.articleStatus == 2
              ? _c("div", { staticClass: "reject-box" }, [
                  _c("div", { staticClass: "fs-16 fw-bold" }, [
                    _vm._v("驳回原因："),
                  ]),
                  _c("div", { staticClass: "reject-content fs-14" }, [
                    _vm._v(" " + _vm._s(_vm.verify.resonRemark) + " "),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "wp-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "workRef",
                    staticClass: "wp-form",
                    attrs: {
                      model: _vm.workForm,
                      rules: _vm.workFormRules,
                      "label-width": "95px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "item-content",
                        attrs: {
                          label: "任务标题：",
                          "label-width": "95px",
                          prop: "taskName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "wp-input w480",
                          attrs: {
                            disabled: "",
                            placeholder: "请输入任务标题",
                          },
                          model: {
                            value: _vm.workForm.taskName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workForm,
                                "taskName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "workForm.taskName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.taskData.modeStyleList &&
                    _vm.taskData.modeStyleList.length !== 1
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "item-content",
                            attrs: {
                              label: "任务方式：",
                              "label-width": "95px",
                              prop: "modeStyle",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { max: 1 },
                                model: {
                                  value: _vm.workForm.modeStyle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workForm, "modeStyle", $$v)
                                  },
                                  expression: "workForm.modeStyle",
                                },
                              },
                              _vm._l(
                                _vm.taskData.modeStyleList,
                                function (item) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: item.dictValue,
                                      attrs: { label: item.dictValue },
                                    },
                                    [_vm._v(_vm._s(item.dictKey))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setting.parmTitleShow
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "item-content",
                            attrs: {
                              rules: [
                                {
                                  required: _vm.setting.parmTitleMust,
                                  trigger: "blur",
                                  message: "请输入作品标题",
                                },
                              ],
                              label: "作品标题：",
                              prop: "title",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "wp-input w480",
                              attrs: {
                                maxlength: "100",
                                placeholder: "请输入作品标题",
                              },
                              model: {
                                value: _vm.workForm.title,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workForm,
                                    "title",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "workForm.title",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setting.parmUrlShow
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "item-content isUrl",
                            attrs: {
                              rules: [
                                {
                                  required: _vm.setting.parmUrlMust,
                                  trigger: "blur",
                                  message: "请输入作品链接",
                                },
                              ],
                              label: "作品链接：",
                              prop: "url",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "wp-input w480",
                              attrs: {
                                maxlength: "2000",
                                placeholder: "请输入作品链接",
                              },
                              on: { blur: _vm.changeUrl },
                              model: {
                                value: _vm.workForm.url,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workForm,
                                    "url",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "workForm.url",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setting.parmContentShow
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "作品内容：",
                              prop: "content",
                              rules: [
                                {
                                  required: _vm.setting.parmContentMust,
                                  trigger: "blur",
                                  message: "请输入作品内容",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "w480 textarea",
                              attrs: {
                                type: "textarea",
                                maxlength: "1000",
                                rows: 6,
                                placeholder: "请输入任务内容",
                              },
                              model: {
                                value: _vm.workForm.content,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workForm,
                                    "content",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "workForm.content",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setting.parmAttachShow
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              rules: [
                                {
                                  required: _vm.setting.parmAttachMust,
                                  trigger: ["blur", "change"],
                                  message: "请上传文件",
                                },
                              ],
                              label: "截图/附件：",
                              prop: "fileJson",
                            },
                          },
                          [
                            _c("UploadFile", {
                              key: "image",
                              ref: "upload",
                              attrs: {
                                imageMaxNum: 5,
                                accept: ".jpg, .jpeg, .png, .mp4",
                                fileList: _vm.fileJson,
                              },
                              on: {
                                changeUpload: _vm.changeUpload,
                                fileChange: _vm.fileChange,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }