import { post, get, form } from '@/utils/requestTwo'

// 队伍新增
export function insert (query) {
	return post(`/sysGroup/insert`, query, false)
}

// 文件上传(不需要加密)
export function uploadFile (query) {
	return post(`/file/upload`, query, false)
}

// 分片上传--上传分片
export function splitUpload (query) {
	return post(`/file/split/upload`, query, false)
}

// 分片上传--合并分片文件
export function mergeFile (query) {
	return post(`/file/split/mergeFile`, query, false)
}

// 分片上传--文件是否上传过
export function getSplitUpload (query) {
	return get(`/file/split/upload`, query, false)
}

// 队伍修改
export function update (query) {
	return post(`/sysGroup/update`, query, false)
}

// 队伍删除
export function deleteSysGroup (query) {
	return post(`/sysGroup/delete`, query)
}
// 队伍单个查询
export function getGroup (query) {
	return post(`/sysGroup/get`, query)
}
// 分组树控件
export function listTree (query) {
	return post(`/sysGroup/listTree`, query, false)
}
// 队伍排序（只支持同级排序
export function updateSortValue (query) {
	return post(`/sysGroup/updateSortValue`, query)
}
//人员列表分页查询
export function userPageList (query) {
	return post(`/sysUser/userPageList`, query, false)
}

//用户重置密码
export function resetPass (query) {
	return post(`/sysUser/resetPass`, query)
}
//用户删除
export function deleteSysUser (query) {
	return post(`/sysUser/delete`, query)
}

//用户禁用
export function disabledSysUser (query) {
	return post(`/sysUser/disabled`, query)
}


//人员管理-用户新增（不需要加密
export function insertSysUser (query) {
	return post(`/sysUser/insert`, query, false)
}

//人员管理-用户修改（不需要加密_copy
export function updateSysUser (query) {
	return post(`/sysUser/update`, query, false)
}


//个人信息
export function getSysUser (query) {
	return post(`/sysUser/get`, query)
}
// 个人信息-汇总信息
export function personSummary (query) {
	return post(`/sysUser/personSummary`, query)
}

// 个人信息-任务完成率
export function taskCompleteRate (query) {
	return post(`/sysUser/taskCompleteRate`, query)
}
// 个人信息-任务完成数量
export function taskCompleteCount (query) {
	return post(`/sysUser/taskCompleteCount`, query)
}

//用户导入
export function importUser (query) {
	return form(`/sysUser/importUser`, query)
}

//用户导出
export function exportUser (query) {
	return post(`/sysUser/exportUser`, query, false)
}

//新增用户时获取自媒体集合，修改时不需要
export function getUserMediaList (query) {
	return post(`/sysUser/getUserMediaList`, query)
}
//校验值班账号（不需要加密
export function checkChildInsert (query) {
	return post(`/sysUser/checkChildInsert`, query, false)
}

//是否可以添加用户
export function checkAddUser (query) {
	return post(`/sysUser/checkAddUser`, query)
}

// 常用人员 - 新增分组
export function wpCommonGroupInsert (query) {
	return post(`/wpCommonGroup/insert`, query, false)
}
// 常用人员 -  新增分组
/**
 * "moveId": 1, //分组ID
 * "movedId": 2 //分组ID
 * 
 *  */
export function wpCommonGroupMoveOrder (query) {
	return post(`/wpCommonGroup/moveOrder`, query, false)
	
}
//常用人员 一级分组

export function wpCommonGroupList (query) {
	return post(`/wpCommonGroup/list`, query, false)
}
// 批量加入常用分组(用户)
export function insertBatchUserAndGroup (query) {
	return post(`/wpCommonGroup/insertBatchUserAndGroup`, query, false)
}
// 加入常用人员
export function insertBatchUser (query) {
	return post(`/wpCommonGroup/insertBatchUser`, query, false)
}

// 删除常用分组
export function deleteCommonGroup (query) {
	return post(`/wpCommonGroup/delete`, query, false)
}

// 编辑常用分组
export function updateCommonGroup (query) {
	return post(`/wpCommonGroup/update`, query, false)
}

// 常用人员列表
export function commonGrouppageUserList (query) {
	return post(`/wpCommonGroup/pageUserList`, query, false)
}
// 批量改组
export function updateCommonGroupUser (query) {
	return post(`/wpCommonGroup/updateCommonGroup`, query, false)
}

// 批量移除常用人员
export function removeBatchUser (query) {
	return post(`/wpCommonGroup/removeBatchUser`, query, false)
}