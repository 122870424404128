var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "TabsCard",
              {
                attrs: { tabs: _vm.tabs, activeTab: _vm.activeTab },
                on: { handlerTabs: _vm.handlerTabs },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "组员统计", name: "people" } },
                  [
                    _c("SearchCard", {
                      staticClass: "peopleSearch",
                      attrs: { title: "" },
                      on: { handlerSearch: _vm.handlerSearch },
                      scopedSlots: _vm._u([
                        {
                          key: "searchContent",
                          fn: function () {
                            return [
                              _c("TaskTimeSelect", {
                                ref: "timeRef",
                                attrs: {
                                  days: 365,
                                  activeType: _vm.searchParams.timeType,
                                  taskType: _vm.timeType,
                                },
                                on: { timeSelect: _vm.timeSelect },
                              }),
                              _c("el-input", {
                                staticClass: "search-w190 wp-input",
                                attrs: {
                                  placeholder: "请输入上报人姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchParams.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParams, "userName", $$v)
                                  },
                                  expression: "searchParams.userName",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "search-w190 wp-input",
                                attrs: {
                                  placeholder: "请输入所在单位",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchParams.custName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParams, "custName", $$v)
                                  },
                                  expression: "searchParams.custName",
                                },
                              }),
                              _c("wpLoadSelect", {
                                staticClass: "search-w392 wp-select align-top",
                                attrs: {
                                  data: _vm.topicList,
                                  page: _vm.topicObj.page,
                                  dictLabel: "title",
                                  dictValue: "topicId",
                                  dictTime: "createTime",
                                  hasMore: _vm.topicObj.more,
                                  request: _vm.getData,
                                  placeholder: "请输入专题名称",
                                },
                                on: {
                                  clearTitle: function () {
                                    return (_vm.topicObj.title = "")
                                  },
                                },
                                model: {
                                  value: _vm.searchParams.topicId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParams, "topicId", $$v)
                                  },
                                  expression: "searchParams.topicId",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-tab-pane", {
                  attrs: { label: "作品明细", name: "workDetails" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wp-card mt-16" },
              [
                _c("div", { staticClass: "flex-CB" }, [
                  _c("div", { staticClass: "flex-Acenter fs-14" }, [
                    _c("div", [
                      _vm._v(" 上报数量："),
                      _c("span", { staticClass: "wp-blue" }, [
                        _vm._v(_vm._s(_vm.infoData.articleNum || 0)),
                      ]),
                    ]),
                    _c("div", { staticClass: "ml-30" }, [
                      _vm._v(" 奖励积分："),
                      _c("span", { staticClass: "wp-blue" }, [
                        _vm._v(_vm._s(_vm.infoData.score || 0)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button wp-btn-icon",
                          attrs: { size: "medium", icon: "icon-daochu" },
                          on: { click: _vm.exportFile },
                        },
                        [_vm._v("导出 ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "wp-table mt-20",
                    attrs: {
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-text": "正在加载",
                    },
                  },
                  [
                    _vm.tableData.length !== 0
                      ? _c(
                          "el-table",
                          {
                            attrs: {
                              sortable: "custom",
                              data: _vm.tableData,
                              stripe: "",
                            },
                            on: { "sort-change": _vm.sortChange },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "index",
                                label: "序号",
                                width: "70",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { "min-width": "30" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "150",
                                "show-overflow-tooltip": "",
                                align: "left",
                                prop: "userName",
                                label: "人员姓名",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "150",
                                "show-overflow-tooltip": "",
                                align: "left",
                                prop: "custName",
                                label: "所在单位",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "120",
                                align: "center",
                                sortable: "",
                                prop: "workNum",
                                label: "上报数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "120",
                                align: "center",
                                sortable: "",
                                prop: "score",
                                label: "奖励积分",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tableData.length == 0 && !_vm.loading
                      ? _c("div", { staticClass: "wp-noData" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/noData.png"),
                              alt: "",
                            },
                          }),
                          _c("p", [_vm._v("暂无数据")]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("wp-pagination", {
                  staticClass: "mt-21",
                  attrs: {
                    total: _vm.total,
                    "current-page": _vm.searchParams.page,
                    "page-size": _vm.searchParams.limit,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }