<template>
  <div class="wp-w100">
    <el-scrollbar style="height: 100%">
      <div class="main">
        <TabsCard
          :tabs="tabs"
          :activeTab="activeTab"
          @handlerTabs="handlerTabs"
        >
          <el-tab-pane label="人员汇总" name="people">
            <!-- 人员汇总 -->
            <PeopleSearch
              v-if="activeTab == 'people'"
              :groupList="groupList"
              @changeSearch="changeSearch"
              ref="people"
            ></PeopleSearch>
          </el-tab-pane>

          <el-tab-pane label="分组汇总" name="group">
            <!-- 分组汇总 -->
            <GroupingSearch
              v-if="activeTab == 'group'"
              :groupList="groupList"
              @changeSearch="changeSearch"
              ref="group"
            ></GroupingSearch>
          </el-tab-pane>

          <el-tab-pane label="任务汇总" name="task">
            <!-- 任务汇总 -->
            <TaskSearch
              v-if="activeTab == 'task'"
              :groupList="groupList"
              @changeSearch="changeSearch"
              ref="task"
            ></TaskSearch>
          </el-tab-pane>

          <el-tab-pane label="分类汇总" name="classify">
            <!-- 分类汇总 -->
            <ClassifySearch
              v-if="activeTab == 'classify'"
              :groupList="groupList"
              @changeSearch="changeSearch"
              ref="classify"
            ></ClassifySearch>
          </el-tab-pane>
        </TabsCard>

        <div class="wp-card mt-16">
          <div
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <!-- 人员汇总 -->
            <PeopleTable
              v-show="activeTab == 'people'"
              ref="peopleRef"
              :searchParams="searchParams"
              @sortChange="sortChange"
              :tableData="tableObj['memberStatistics']"
              :statisticsData="statisticsData"
              :loading="loading"
            ></PeopleTable>
            <!-- 分组汇总 -->
            <GroupingTable
              v-show="activeTab == 'group'"
              ref="groupRef"
              :searchParams="searchParams"
              @sortChange="sortChange"
              :tableData="tableObj['teamMumberList']"
              :statisticsData="statisticsData"
              :loading="loading"
            ></GroupingTable>
            <!-- 任务汇总 -->
            <TaskTable
              v-show="activeTab == 'task'"
              ref="taskRef"
              :searchParams="searchParams"
              @sortChange="sortChange"
              :tableData="tableObj['taskStatistics']"
              :statisticsData="statisticsData"
              :loading="loading"
            ></TaskTable>
            <!-- 分类汇总 -->
            <ClassifyTable
              v-show="activeTab == 'classify'"
              ref="classifyRef"
              :searchParams="searchParams"
              @sortChange="sortChange"
              :tableData="tableObj['typeStatistics']"
              :statisticsData="statisticsData"
              :loading="loading"
            ></ClassifyTable>
          </div>

          <wp-pagination
            class="mt-21"
            :total="totalObj[tableApi]"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'

import TabsCard from '@/views/components/tabsCard'

import PeopleSearch from './subSearch/peopleSearch'
import GroupingSearch from './subSearch/groupingSearch'
import TaskSearch from './subSearch/taskSearch'
import ClassifySearch from './subSearch/classifySearch'

import PeopleTable from './subTable/peopleTable'
import GroupingTable from './subTable/groupingTable'
import TaskTable from './subTable/taskTable'
import ClassifyTable from './subTable/classifyTable'

export default {
  name: 'panel',
  components: { TabsCard, PeopleSearch, GroupingSearch, TaskSearch, ClassifySearch, PeopleTable, GroupingTable, TaskTable, ClassifyTable },
  data () {
    return {
      tabs: [
        { label: '人员汇总', name: "people" },
        { label: '分组汇总', name: "group" },
        { label: '任务汇总', name: "task" },
        { label: '分类汇总', name: "classify" }
      ],
      activeTab: "people", // 所选tab
      searchParams: {
        page: 1, // 页码
        limit: 30, // 页大小
      },
      total: 100,
      // 任务列表
      taskList: [], // 任务列表
      tableApi: "", // 根据点击的tab 切换请求地址
      tableData: [],
      statisticsData: {}, // 统计数据
      groupList: [], // 分组树
      taskType: 2,
      loading: false,
      tableObj: {
        'memberStatistics': [],
        'teamMumberList': [],
        'taskStatistics': [],
        'typeStatistics': []
      },
      loadObj: {
        'memberStatistics': false,
        'teamMumberList': false,
        'taskStatistics': false,
        'typeStatistics': false
      },
      totalObj: {
        'memberStatistics': 0,
        'teamMumberList': 0,
        'taskStatistics': 0,
        'typeStatistics': 0,
      }
    };
  },
  watch: {
    'searchParams.timeSection': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.searchParams.startTime = newVal[0]
          this.searchParams.endTime = newVal[1]
        } else {
          this.searchParams.timeSection = ""
          this.searchParams.startTime = ""
          this.searchParams.endTime = ""
        }

      }
    }
  },
  created () {
    // 获取分组树
    this.getGroupTree()
  },

  methods: {
    // 获取分组树
    async getGroupTree () {
      const res = await taskApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },

    // 搜索回调
    changeSearch (params) {
      this.searchParams = { ...this.searchParams, ...params }
      // 获取列表
      this.getTableData()
      // 获取表格统计
      this.getSummaries()
    },

    // 列表排序回调
    sortChange (sortObj) {
      this.searchParams = { ...this.searchParams, ...sortObj }
      this.getTableData()
    },

    /** tabs点击回调 */
    handlerTabs (tab) {
      this.loading = false

      this.activeTab = tab
      this.searchParams = {}

      if (tab == 'people') {
        this.tableApi = 'memberStatistics'
      } else if (tab == 'group') {
        this.tableApi = 'teamMumberList'
      } else if (tab == 'task') {
        this.tableApi = 'taskStatistics'
      } else {
        this.tableApi = 'typeStatistics'
      }

      this.$nextTick(async () => {
        // 清空筛选条件
        this.$refs[tab + 'Ref'].clearSort()
        // 清空上一次的搜索条件
        await this.$refs[this.activeTab].clearParams()

        // 获取搜索条件
        this.searchParams = await { ...this.$refs[tab].getParams() }

        // 置空表格
        this.tableData = []
        // 设置默认值
        this.searchParams.page = 1
        this.searchParams.limit = 30

        /** 获取列表数据 */
        await this.getTableData()
        /** 获取统计表格信息 */
        await this.getSummaries()
      })

    },

    /** 获取统计列表数据 */
    async getTableData () {
      // this.tableData = []
      let tableApi = this.tableApi
      if (this.loading) return
      this.loading = true
      this.tableObj[tableApi] = []

      try {
        const res = await taskApi[tableApi]({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res?.returnData
          this.tableObj[tableApi] = ret?.data || []
          this.totalObj[tableApi] = ret?.totalCount || 0
          this.total = ret?.totalCount || 0
        }
      } catch (error) {
        this.loading = false
      }
    },

    /** 表格统计信息 */
    async getSummaries () {
      if (this.activeTab == 'classify') {
        const res = await taskApi.getSummariesTaskType({ ...this.searchParams })
        if (res.returnCode == 200) {
          this.statisticsData = res.returnData
        }
      } else if (this.activeTab == 'group') {
        const res = await taskApi.getSummariesGroup({ ...this.searchParams })
        if (res.returnCode == 200) {
          this.statisticsData = res.returnData
        }
      } else {
        const res = await taskApi.getSummaries({ ...this.searchParams })
        if (res.returnCode == 200) {
          this.statisticsData = res.returnData
        }
      }

    },

    // 页大小回调
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTableData()
    },

    // 页码回调
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    },

    // 时间类型选择回调
    handlerSelect (obj) {
      this.searchParams = { ...this.searchParams, ...obj }
    },
  },
};
</script>
<style scoped lang="scss">
@import 'index.scss';
</style>
