<template>
  <!-- <div class="mt-16 flex" :style="{ height: foldFlag ? '1187px' : '1087px' }"> -->
    <div class="mt-16 flex" :style="{ height: foldFlag ? '1280px' : '1180px' }">
    <div class="task-aside">
      <div class="head-btn fs-14" @click="inStep">
        <span>一键同步</span>
        <wp-question-mark
          class="question-mark"
          content="将当前链接下的任务信息更新到所有链接对应的信息项上（除任务标题和任务链接以外）"
        ></wp-question-mark>
      </div>

      <el-scrollbar style="height: 100%">
        <div class="task-list">
          <div
            class="task-item fs-14"
            :class="activeTask == index ? 'active_item' : ''"
            v-for="(item, index) in taskInfoArr"
            :key="index"
            @click="clickTaskTitle(item, index)"
          >
            <div class="index">
              {{ index + 1 }}
            </div>

            <el-tooltip
              class="item"
              effect="dark"
              :tabindex="item.id"
              :disabled="!isShowTooltipWidth"
              :content="item.basicsForm.title"
              placement="top"
            >
              <div
                class="title textOverOneLine"
                @mouseenter="visibilityChangeWidth($event)"
                :class="item.error ? 'red' : ''"
              >
                {{ item.basicsForm.title }}
              </div>
            </el-tooltip>

            <i
              class="icon-guanbi"
              @click="(event) => removeItem(event, index)"
            ></i>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <div class="content ml-16">
      <!-- 基础设置 -->
      <div class="wp-task-card">
        <div class="card-title fs-16 fw-bold">基础设置</div>
        <BasicsForm
          :formData="taskInfo.basicsForm"
          @updateTitle="updateTitle"
          @getStyleList="getStyleList"
          class="center"
          ref="basicsFormRef"
        ></BasicsForm>
      </div>

      <!-- 任务设置 -->
      <div class="wp-task-card mt-16">
        <div class="card-title flex-CB">
          <div class="fs-16 fw-bold">任务设置</div>
          <div class="flex-Acenter instancy-task">
            <el-checkbox class="isUrgent" v-model="taskInfo.isUrgent"
              >紧急任务</el-checkbox
            >
            <wp-question-mark
              style="margin-left: 6px"
              content="任务发出后APP会强提醒30秒"
            ></wp-question-mark>
          </div>
        </div>
        <TaskForm
          :configObj="configObj"
          :formData="taskInfo.taskForm"
          ref="taskFormRef"
          :taskStyleList="taskStyleList"
        >
        </TaskForm>
      </div>

      <!-- 高级选项 -->
      <div
        class="wp-task-card mt-16"
        :style="{ height: foldFlag ? '174px' : '74px' }"
      >
        <div class="card-title flex-CB">
          <div class="fs-16 fw-bold">高级选项</div>
          <div class="wp-blue toggle" @click="foldFlag = !foldFlag">
            <span>{{ !foldFlag ? '展开' : '收起' }}</span>
            <i
              v-show="foldFlag"
              class="el-icon-arrow-up fs-14 wp-blue"
              style="margin-left: 6px"
            ></i>
            <i
              v-show="!foldFlag"
              class="el-icon-arrow-down fs-14 wp-blue"
              style="margin-left: 6px"
            ></i>
          </div>
        </div>

        <div class="footer" v-show="foldFlag">
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item
                :label="item.configName + '：'"
                v-for="item in taskInfo.advancedSetting"
                :key="item.custConfigId"
              >
                <el-checkbox
                  v-model="item.isShow"
                  :disabled="item.isDisbled"
                  @change="checkboxChangeShow(item)"
                  >显示
                </el-checkbox>
                <el-checkbox
                  v-model="item.isRequired"
                  :disabled="item.isDisbled"
                  @change="checkboxChangeRequired(item)"
                  >必填
                </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="wp-btn-card mt-16 wp-center">
        <el-button @click="cancel" class="wp-button">上一步</el-button>
        <el-button
          style="margin-left: 24px"
          class="wp-button"
          v-preventReClick
          type="primary"
          @click="nextStep"
          >下一步
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BasicsForm from './form/basicsForm'
import TaskForm from './form/taskForm'
export default {
  components: { BasicsForm, TaskForm },
  props: {
    // 任务信息列表
    taskInfoList: {
      type: Array,
      required: true
    },
    updateTaskInfoList: Array,
    // 任务配置
    configObj: {
      type: Object,
      required: true
    }
  },
  watch: {
    // 原任务数组
    taskInfoList: {
      handler (newVal) {
        // 任务数组
        this.taskInfoArr = newVal
        this.taskInfo = newVal[0]
        this.activeTask = 0
      },
    },
    // 同步后的任务数组
    updateTaskInfoList: {
      handler (newVal) {
        if (newVal.length) {
          this.taskInfoArr = newVal
          this.taskInfo = newVal[this.activeTask]
        }
      },
      deep: true,
      immediate: true,
    }
  },

  data () {
    return {
      taskList: [], // 任务标题
      activeTask: 0, // 左侧任务标题索引
      foldFlag: false,
      taskInfoArr: [], // 任务信息集合
      basicsForm: {}, // 基础信息
      taskInfo: "", // 任务信息
      taskStyleList: [], // 任务方式列表
      errIndex: [], // 未填写完整的索引
      isShowTooltipWidth: false
    }
  },

  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    // 获取任务方式列表
    getStyleList (list) {
      this.taskStyleList = list || []
    },


    /** 一键同步 */
    async inStep () {
      this.$wpConfirm('提示', {
        content: "是否将该条任务信息同步到其他链接任务信息？",
        isShowCancelBtn: true, //是否显示取消按钮
      }).then(async () => {
        // 设置任务信息
        await this.setTaskInfo()

        // 当前页信息集合
        let itemInfo = await this.taskInfoArr[this.activeTask]

        // 深拷贝一份任务信息列表
        const list = JSON.parse(JSON.stringify(this.taskInfoArr))

        let obj = {}
        let taskObj = {}

        // 不能同步的索引
        let noStepIndex = []

        list.forEach((item, index, arr) => {
          // 基础信息表单
          obj = JSON.parse(JSON.stringify(item.basicsForm))
          // 任务配置表单
          taskObj = JSON.parse(JSON.stringify(item.taskForm))

          // 任务站点相同,除了标题和链接,直接赋值
          if (item.basicsForm.websiteId == itemInfo.basicsForm.websiteId) {
            arr[index] = JSON.parse(JSON.stringify(itemInfo))
            arr[index].basicsForm.title = obj.title
            arr[index].basicsForm.url = obj.url
          } else {
            // 所选任务方式Id
            const modelIds = JSON.parse(JSON.stringify(itemInfo.taskForm.modeStyle))
            // 任务方式Id集合
            const modelList = JSON.parse(JSON.stringify(item.taskForm.taskWayIds))

            // 判断每条数据中的任务方式列表 是否包含 当前已选中的任务方式
            // 有则覆盖任务方式
            console.log(modelList, modelIds, this.isContained(modelList, modelIds));
            if (this.isContained(modelList, modelIds)) {
              arr[index] = JSON.parse(JSON.stringify(itemInfo))
              arr[index].basicsForm.title = obj.title
              arr[index].basicsForm.url = obj.url

              // 任务方式
              arr[index].taskForm.modeStyle = JSON.parse(JSON.stringify(itemInfo.taskForm.modeStyle))
              arr[index].taskForm.taskWayList = JSON.parse(JSON.stringify(item.taskForm.taskWayList))
              arr[index].taskForm.taskWayIds = JSON.parse(JSON.stringify(item.taskForm.taskWayIds))
            } else {
              noStepIndex.push(index + 1)

              arr[index] = JSON.parse(JSON.stringify(itemInfo))
              arr[index].basicsForm.title = obj.title
              arr[index].basicsForm.url = obj.url
              // 任务方式
              arr[index].taskForm.modeStyle = JSON.parse(JSON.stringify(item.taskForm.modeStyle))
              arr[index].taskForm.taskWayList = JSON.parse(JSON.stringify(item.taskForm.taskWayList))
              arr[index].taskForm.taskWayIds = JSON.parse(JSON.stringify(item.taskForm.taskWayIds))
            }

            arr[index].basicsForm.websiteId = obj.websiteId
            arr[index].basicsForm.websiteName = obj.websiteName
          }
        })

        if (noStepIndex?.length) {
          // 没填写完整的表单索引
          noStepIndex = noStepIndex.join(",")
          this.$showMessage({ type: 'warning', msg: `第${noStepIndex}条任务方式未同步，请手动选择` })
        }

        this.$emit("inStepTask", list)
      }).catch(() => {
        console.log("取消")
      });
    },

    // 定义函数
    isContained (arr, byArr) {
      if (!(arr instanceof Array) || !(byArr instanceof Array)) return false
      const len = byArr.length
      // 任务方式的长度 小于 选中任务方式的长度,直接返回false
      if (arr.length < len) return false
      for (let i = 0; i < len; i++) {
        // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
        if (!arr.includes(byArr[i])) return false;
      }
      // 遍历结束，返回true
      return true;
    },

    // 修改标题回调
    async updateTitle (title) {
      // 基础设置
      const basicsForm = this.$refs.basicsFormRef.getFormData()
      this.taskInfoArr[this.activeTask].basicsForm = basicsForm
      // 基础设置
      this.taskInfoArr[this.activeTask].basicsForm.title = title
    },

    // 下一步
    async nextStep () {
      await this.setTaskInfo()

      let errIndex = []

      // 判断表单是否填写完整
      this.taskInfoArr.forEach((item, index) => {
        if (item.basicsForm.taskTypeId == "" || item.basicsForm.title == "" || item.basicsForm.url == "" || item.basicsForm.content == "" || item.taskForm.modeStyle.length == 0 || item.taskForm.startTime == "" || item.taskForm.endTime == "" || item.taskForm.score == "" || item.taskForm.minWord == "" || item.taskForm.maxArticle == "") {
          errIndex.push(index + 1)
          this.errIndex.push(index)
        }
      })

      // 填写完整跳转下一页
      if (!errIndex.length) {
        this.errIndex = []
        this.taskInfoArr.forEach(item => {
          item.error = false
        })
        this.$refs.basicsFormRef.clearValidate()
        this.$refs.taskFormRef.clearValidate()
        this.$emit("twoNextStep", JSON.parse(JSON.stringify(this.taskInfoArr)))
      } else {
        const list = JSON.parse(JSON.stringify(this.taskInfoArr))
        list.forEach(item => {
          item.error = false
        })

        this.errIndex.forEach(item => {
          list[+item].error = true
        })
        this.taskInfoArr = list

        this.$showMessage({ type: 'warning', msg: `第${errIndex.join(",")}条信息填写不完整或填写错误` })
        this.$emit("twoNextStep", [])
      }
    },

    // 设置任务信息
    setTaskInfo () {
      // 基础设置
      const basicsForm = this.$refs.basicsFormRef.getFormData()
      this.taskInfoArr[this.activeTask].basicsForm = basicsForm

      // 任务设置
      const taskForm = this.$refs.taskFormRef.getFormData()
      this.taskInfoArr[this.activeTask].taskForm = taskForm

      // 是否紧急
      this.taskInfoArr[this.activeTask].isUrgent = this.taskInfo.isUrgent
    },

    // 点击任务标题
    async clickTaskTitle (item, index) {
      await this.setTaskInfo()

      // 清除校验
      this.$refs.basicsFormRef.clearValidate()
      this.$refs.taskFormRef.clearValidate()

      // 侧边栏索引
      this.activeTask = index
      // 重新赋值
      this.taskInfo = { ...item }
    },

    // 删除单项任务
    removeItem (event, index) {
      this.taskInfoArr.splice(index, 1)
      this.activeTask = 0
      event.stopPropagation();
    },

    //  高级设置 改变显示 
    checkboxChangeShow () {
      this.taskInfo.advancedSetting.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },

    //  高级设置 改变必填 
    checkboxChangeRequired () {
      this.taskInfo.advancedSetting.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },

    // 取消
    cancel () {
      // 清除校验
      this.$refs.basicsFormRef.clearValidate()
      this.$refs.taskFormRef.clearValidate()

      this.$emit("twoCancel")
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>