import {
	asyncRoutes,
	constantRoutes
} from '@/router'

const permission = {
	state: {
		routes: [],// 路由菜单
		addRoutes: [],
		visibleTabs: [] // 可见路由
	},
	mutations: {
		SET_ROUTES: (state, routes) => {
			state.addRoutes = routes
			state.routes = constantRoutes.concat(routes)
		},
		SET_VISIBLETABS: (state, visibleTabs) => {
			state.visibleTabs = visibleTabs
		}
	},
	actions: {
		// 生成路由
		GenerateRoutes ({
			commit
		}, roles) {
			return new Promise(resolve => {
				// console.log(roles)
				let accessedRoutes
				accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
				commit('SET_ROUTES', accessedRoutes)
				resolve(accessedRoutes)
			})
		},
		// 按路由标签 生成路由
		GenerateRoutesTab ({
			commit
		}, dataObj) {
			return new Promise(resolve => {
				// console.log(dataObj)
				let accessedRoutes = filterAsyncRoutesTab(asyncRoutes, dataObj.tabs)
				// accessedRoutes
				commit('SET_ROUTES', accessedRoutes)
				commit('SET_VISIBLETABS', dataObj.visibleTabs)
				resolve(accessedRoutes)
			})
		},

	}
}
//asyncRoutes
// 通过tab 过滤出有权限的
export function filterAsyncRoutesTab (routes, tabs) {
	const res = []
	// 遍历路由
	routes.forEach(route => {
		const tmp = {
			...route  // 子路由
		}
		if (hasPermissionTab(tabs, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutesTab(tmp.children, tabs)
			}
			res.push(tmp)
		}
	})

	return res
}

function hasPermissionTab (tabs, route) {
	// console.log(tabs)
	// console.log(route)
	if (route.meta && route.meta.tab) {
		return tabs.some(tab => route.meta.tab.includes(tab))
		//return tabs.some(tab => route.name.includes(tab))
	} else {
		return true
	}
}


export function filterAsyncRoutes (routes, roles) {
	const res = []

	routes.forEach(route => {
		const tmp = {
			...route
		}
		if (hasPermission(roles, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, roles)
			}
			res.push(tmp)
		}
	})

	return res
}

function hasPermission (roles, route) {
	if (route.meta && route.meta.roles) {
		return roles.some(role => route.meta.roles.includes(role))
	} else {
		return true
	}
}





export const loadView = (view) => { // 路由懒加载
	return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
