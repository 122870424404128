<template>
  <div class="wp-drill-dialog scoreRules">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="dialog-body">
        <div @click="handleClose" class="close-btn"></div>
        <div class="dialog-body-content">
          <div class="dialog-top flex-center">
            <div class="title">计分规则</div>
          </div>
          <div class="dialog-mid">个人得分=热评得分+评论得分+转发得分</div>
          <div class="dialog-bot">
            <div class="desc-item">
              <div class="desc-title flex-Acenter">
                <span class="dot"></span>热评得分
              </div>
              <p>评论区前5条热评，分别获得15、10、7、5、3分，其它热评加1分</p>
              <p>热评数量：评论区前n条评论，创建演练时可设置该数值</p>
            </div>
            <div class="desc-item">
              <div class="desc-title flex-Acenter">
                <span class="dot"></span>评论得分
              </div>
              <p>评论数量×0.1（单人可多次评论）</p>
            </div>
            <div class="desc-item">
              <div class="desc-title flex-Acenter">
                <span class="dot"></span>转发得分
              </div>
              <p>微博转发1条0.1分（多次转发只算一次），评论转发不得分</p>
            </div>
            <div class="desc-item">
              <div class="desc-title flex-Acenter">
                <span class="dot"></span>扣分规则
              </div>
              <p>缺1人响应扣0.1分 （未响应包括“未评论或未转发”）</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'personList',
  components: {},
  data () {
    return {
      dialogVisible: false
    };
  },
  props: {},
  created () {

  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    show () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    }

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
.scoreRules {
  /deep/.el-dialog__body {
    width: 900px;
    height: 628px;
  }

  .dialog-body-content {
    width: 100%;
    height: 100%;
    // height: 628px;
    background: url('~@/assets/images/drill/scoreBg.png') no-repeat center
      center;
    background-size: 100% 100%;
    padding: 50px 70px;
    // background-color: #1c5ac0;
  }

  .dialog-body {
    width: 100%;
    height: 100%;
    .dialog-top {
      .title {
        width: 288px;
        height: 98px;
        line-height: 98px;
        text-align: center;
        background: url('~@/assets/images/drill/scoreTile.png') no-repeat center
          center;
        background-size: 100% 100%;
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .dialog-mid {
      margin-top: 26px;
      width: 100%;
      height: 60px;
      background: rgba(255, 255, 255, 0.08);
      font-size: 24px;
      font-weight: bold;
      color: #ffc000;
      line-height: 60px;
      text-align: center;
    }
    .dialog-bot {
      margin-top: 32px;
      .desc-item {
        margin-top: 12px;
        .desc-title {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
          .dot {
            display: block;
            width: 10px;
            height: 10px;
            background: #ffc000;
            border-radius: 50%;
            margin-right: 6px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
