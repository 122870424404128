<template>
  <div class="unitTab">
    <el-tabs :value="activeId" @tab-click="handleClick" class="wp-tabs">
      <el-tab-pane :name="item.id" v-for="item in tabsList" :key="item.id">
        <span slot="label" :class="{ 'news-before': item.news == 1 }">
          {{ item.label }}</span
        >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  name: 'unitTab',
  components: {},
  data () {
    return {
      activeName: ""
    };
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String,
    }
  },
  created () {
    // this.activeName = this.activeId
  },
  mounted () {

  },
  computed: {

    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab.name)
      this.$emit('handleClick', tab.name)
    },

  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
.unitTab {
  /deep/.wp-tabs {
    line-height: 50px;

    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }
    }

    .el-tabs__header {
      margin: 0px;
    }

    .el-tabs__item {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      padding-right: 10px;

      &.is-active {
        color: #0064e6;
      }
    }

    .news-before {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #f35454;
        border-radius: 50%;
        top: 0px;
        right: -10px;
      }
    }
  }
}
</style>
