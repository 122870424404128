<template>
  <div class="flex-CB mt-16">
    <div class="people-list">
      <div class="search-box">
        <div class="search-input">
          <el-input class="wp-input" v-model="searchContent" clearable placeholder="请输入搜索的内容">
            <i slot="prefix" class="el-input__icon icon-sousuo" style="font-size: 20px"></i>
          </el-input>
        </div>
      </div>

      <PeopleTtee ref="peopleRef" :defaultExpanded="defaultExpanded" :defaultCheckedKeys.sync="activePeople" :peopleList="peopleList" :searchContent="searchContent" @handleCheck="handleCheck"></PeopleTtee>

    </div>

    <div class="select-people">
      <div class="flex-CB fs-14 head">
        <div>
          {{type == 1?'红方':'蓝方'}}成员（ <span class="wp-blue">{{ list.length }}</span> 人）
        </div>
        <div class="flex-Acenter">
          <el-checkbox v-model="lastSend" @change="changeCheckbox">上次{{type == 1?'红方':'蓝方'}}成员</el-checkbox>
          <div class="flex-Acenter clear wp-blue" @click="clearAll">
            <i class="icon-shanchu"></i>
            <div class="fs-14 wp-blue">清空所有</div>
          </div>
        </div>
      </div>

      <div class="list-box">
        <el-scrollbar style="height: 100%">
          <div class="list">
            <div class="list-item" v-for="(item, index) in list" :key="index">
              <el-tooltip class="item" effect="dark" :tabindex="index" :disabled="!isShowTooltipWidth" :content="item.name" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine wp-center" style="flex: 3">
                  {{ item.name }}
                </div>
              </el-tooltip>
              <div style="flex: 3">{{ $utils.replacePhone(item.mobile) }}</div>
              <el-tooltip class="item" effect="dark" :tabindex="index" :disabled="!isShowTooltipWidth" :content="item.groupName" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine wp-center" style="flex: 3">
                  {{ item.groupName }}
                </div>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :tabindex="index+1000" :disabled="!isShowTooltipWidth" :content="item.custName" placement="top">
                <div @mouseenter="visibilityChangeWidth($event)" class="textOverOneLine" style="flex: 3">
                  {{ item.custName }}
                </div>
              </el-tooltip>
              <div class="icon" @click="tableRemovePeople(item, index)">
                <i class="el-icon-error"></i>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import PeopleTtee from '@/views/drill/components/peopleTree'

import taskApi from '@/api/task.js'
import * as  drillApi from '@/api/drill.js'
export default {
  components: { PeopleTtee },
  data () {
    return {
      searchContent: "", // 树节点搜索内容
      lastSend: false, // 上次发送人员复选框
      list: [], // 右侧人员列表信息
      peopleList: [], // 人员列表
      activePeople: [], // 选中人员
      defaultExpanded: [],
      isShowTooltipWidth: false,
      type: 1
    }
  },
  created () {
    // 获取人员列表
    this.getAllPeopleTree()
  },
  props: {
    // type: {
    //   type: Number,
    //   default: 1 //所属队伍1红队2蓝队
    // },
    active: {
      type: Number,
      default: 2 // 当前的步骤数
    },
    selRedUserIds: {
      type: Array,
      default: () => []
    },
    selBlueUserIds: {
      type: Array,
      default: () => []
    },
  },
  mounted () {
    console.log(this.sysUserInfo);
  },

  watch: {
    active: {
      handler (val, oldval) {
        // console.log(val, oldval);
        if (val) {
          this.resetInit(val)
        }
      },
      deep: true
    }
  },
  methods: {
    resetInit (val) {
      this.searchContent = ''
      // console.log("=====================", val)
      let disabledList = []
      if (val == 2) {
        this.type = 1
        disabledList = this.selBlueUserIds
        this.setCheckedKeys(this.selRedUserIds)
        // 设置禁用的人员列表
      } else if (val == 3) {
        this.type = 2
        this.setCheckedKeys(this.selBlueUserIds)
        disabledList = this.selRedUserIds
      }
      this.lastSend = false
      let newArray = JSON.parse(JSON.stringify(this.peopleList))
      this.setDisableList(newArray, disabledList)
      this.peopleList = newArray
      console.log(this.peopleList)
      this.getList()
    },
    setDisableList (array, disabledList) {

      array.forEach(item => {
        item.disabled = false
        if (disabledList.indexOf(item.id) !== -1) {
          console.log(item.name)
          item.disabled = true
        }
        if (item.children && item.children.length > 0) {
          this.setDisableList(item.children, disabledList)
        }
      })
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    /** 表格中移除人员 */
    tableRemovePeople (item, index) {
      this.list.splice(index, 1)
      this.setCheckedKeys(this.list.map(it => it.id))
    },
    /** 获取全部人员列表 */
    async getAllPeopleTree () {
      const res = await taskApi.groupUserTree({})
      if (res.returnCode == 200) {
        this.peopleList = res.returnData
        this.defaultExpanded = [res?.returnData[0]?.id]
      }
    },
    /** 获取选中人员数据 */
    getFormData () {
      console.log(this.list)
      if (this.list?.length && this.list.length > 0) {
        return this.list
      } else {
        this.$showMessage({ type: 'error', msg: `请选择${this.type == 1 ? '红方' : '蓝方'}演练成员` })
        return []
      }
    },
    /** 清空所有已选中人员 */
    clearAll () {
      this.list = []
      this.lastSend = false
      this.setCheckedKeys([])
    },

    /** 勾选上次发送人员复选框回调 */
    async changeCheckbox (flag) {
      if (flag) {
        const res = await drillApi.historyReceiver({ type: this.type })
        if (res.returnCode == 200) {
          // 上次勾选人员信息
          const ids = res.returnData
          this.setCheckedKeys(ids)
          await this.getList()
        }
      }
    },
    // 设置树默认选中的
    setCheckedKeys (ids) {
      this.$refs.peopleRef.setCheckedKeys(ids)
      this.changeUser()
    },
    // 人员列表的 勾选框事件
    handleCheck (rightList) {
      this.list = rightList
      this.changeUser()
    },
    /** 获取右侧人员列表信息 */
    getList () {
      this.$nextTick(() => {
        // 人员列表
        const peopleList = this.$refs.peopleRef.getCurrentNode().filter(item => {
          if (!item.group) {
            return item
          }
        })
        console.log(peopleList);
        this.list = peopleList
        this.changeUser()
      })
    },
    // 每次人员更改后 都要将值存起来
    changeUser () {
      this.$emit('changeUser', this.list)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>