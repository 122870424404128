var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "title-header" }, [
      _c(
        "div",
        { staticClass: "title-header-left" },
        [
          _c("unit-tab", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabsList.length > 1,
                expression: "tabsList.length > 1",
              },
            ],
            attrs: { tabsList: _vm.tabsList, activeId: _vm.activeName },
            on: { handleClick: _vm.handleClick },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "title-header-right" }, [
        _c("div", { staticClass: "label" }, [_vm._v("任务发布时间:")]),
        _c(
          "div",
          { staticClass: "wp-date-picker", staticStyle: { width: "312px" } },
          [
            _c("el-date-picker", {
              attrs: {
                size: "small",
                "prefix-icon": "",
                clearable: "",
                type: "daterange",
                "range-separator": "-",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              on: { change: _vm.changeDaterange },
              model: {
                value: _vm.releaseTimeValue,
                callback: function ($$v) {
                  _vm.releaseTimeValue = $$v
                },
                expression: "releaseTimeValue",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "layout-content" },
      [
        _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
          _c("div", { staticClass: "home-admin-content" }, [
            _c("div", { staticClass: "layout-mid wp-moudle-style" }, [
              _c("div", { staticClass: "layout-mid-left" }, [
                _c("p", { staticClass: "wpy-name" }, [
                  _vm._v(_vm._s(_vm.userInfo.userName)),
                ]),
                _c(
                  "div",
                  { staticClass: "star" },
                  [
                    _c("el-rate", {
                      attrs: {
                        disabled: "",
                        colors: ["#FAA548", "#FAA548", "#FAA548"],
                        "void-color": "#D8D8D8",
                      },
                      model: {
                        value: _vm.userInfo.starLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.userInfo, "starLevel", $$v)
                        },
                        expression: "userInfo.starLevel",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "tongji" }, [
                  _c("div", { staticClass: "tongji-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/home/renwu.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        " 投稿数量（" +
                          _vm._s(
                            _vm.summaryReportTaskObj.taskNum
                              ? _vm.summaryReportTaskObj.taskNum
                              : 0
                          ) +
                          "） "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "tongji-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/home/shangbao.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        " 上报数量（" +
                          _vm._s(
                            _vm.summaryReport.topicNum
                              ? _vm.summaryReport.topicNum
                              : 0
                          ) +
                          "） "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "tongji-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/home/zhihui.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        " 演练数量（" +
                          _vm._s(
                            _vm.summaryReport.drillNum
                              ? _vm.summaryReport.drillNum
                              : 0
                          ) +
                          "） "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "layout-mid-right" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/home/ren.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "layout-mid2" }, [
              _c("div", { staticClass: "percent-w33-left" }, [
                _c("div", { staticClass: "wp-moudle-style mid2-item" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.summaryReportTaskObj.taskFinshNum
                              ? _vm.summaryReportTaskObj.taskFinshNum
                              : 0
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", [_vm._v("完成数量")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      staticStyle: { width: "70px" },
                      attrs: {
                        src: require("@/assets/images/home/wanchengshuliang.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "percent-w33-mid" }, [
                _c("div", { staticClass: "wp-moudle-style mid2-item" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.summaryReportTaskObj.ntaskFinshNum
                              ? _vm.summaryReportTaskObj.ntaskFinshNum
                              : 0
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", [_vm._v("未完成数量")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      staticStyle: { width: "70px" },
                      attrs: {
                        src: require("@/assets/images/home/weiwancheng.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "percent-w34-right" }, [
                _c("div", { staticClass: "wp-moudle-style mid2-item" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.summaryReportTaskObj.completionRate
                              ? _vm.summaryReportTaskObj.completionRate
                              : 0
                          ) +
                          "% "
                      ),
                    ]),
                    _c("span", [_vm._v("完成率")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      staticStyle: { width: "70px" },
                      attrs: {
                        src: require("@/assets/images/home/wanchenglv.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "layout-mid3" }, [
              _c("div", { staticClass: "percent-w50" }, [
                _c("div", { staticClass: "wp-moudle-style mid3-item" }, [
                  _c("div", { staticClass: "item-title home-title" }, [
                    _vm._v("积分情况"),
                  ]),
                  _c("div", { staticClass: "item-content" }, [
                    _c("div", { staticClass: "jifen-bg" }, [
                      _c("p", [_vm._v("总积分：")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.personSummary.totalScore
                              ? _vm.personSummary.totalScore
                              : 0
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "jifen-list" }, [
                      _c(
                        "div",
                        { staticClass: "jifen-item jifen-item-after" },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.personSummary.weekScore
                                    ? _vm.personSummary.weekScore
                                    : 0
                                ) +
                                " "
                            ),
                          ]),
                          _c("span", [_vm._v("本周积分")]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "jifen-item jifen-item-after" },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.personSummary.monthScore
                                    ? _vm.personSummary.monthScore
                                    : 0
                                ) +
                                " "
                            ),
                          ]),
                          _c("span", [_vm._v("本月积分")]),
                        ]
                      ),
                      _c("div", { staticClass: "jifen-item" }, [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.personSummary.yearScore
                                  ? _vm.personSummary.yearScore
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", [_vm._v("本年积分")]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "percent-w50-right" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.itemLoading1,
                        expression: "itemLoading1",
                      },
                    ],
                    staticClass: "wp-moudle-style mid3-item",
                    attrs: { "element-loading-spinner": "el-icon-loading" },
                  },
                  [
                    _c("div", { staticClass: "item-title home-title" }, [
                      _vm._v("任务完成量"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      [
                        _vm.taskXAxisData.length > 0
                          ? _c("bar-chart", {
                              ref: "barChart",
                              attrs: {
                                id: "barChart",
                                xAxisData: _vm.taskXAxisData,
                                yAxisData: _vm.taskYAxisData,
                              },
                            })
                          : _vm._e(),
                        _vm.taskXAxisData.length == 0 && !_vm.itemLoading1
                          ? _c("div", { staticClass: "wp-noData" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/noData.png"),
                                  alt: "",
                                },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "new-task wp-moudle-style" }, [
              _c("div", { staticClass: "home-title" }, [_vm._v("最新待办")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.newTaskLoading,
                      expression: "newTaskLoading",
                    },
                  ],
                  staticClass: "wp-table new-task-table",
                  attrs: { "element-loading-spinner": "el-icon-loading" },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, stripe: "" },
                      on: { "cell-click": _vm.handlerCellClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "typeName",
                          label: "来源",
                          width: "300",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "left", "min-width": "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "taskTitle",
                          label: "名称",
                          align: "left",
                          "show-overflow-tooltip": "",
                          "class-name": "wp-pointer-color",
                          "min-width": "250",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "left", "min-width": "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "时间",
                          "min-width": "150",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: "状态",
                          "min-width": "150",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 1 &&
                                (scope.row.type == 1 || scope.row.type == 2)
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#0064e6" } },
                                      [_vm._v("未接收")]
                                    )
                                  : _vm._e(),
                                scope.row.status == 2 &&
                                (scope.row.type == 1 || scope.row.type == 2)
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [_vm._v("已接收")]
                                    )
                                  : _vm._e(),
                                scope.row.status == 1 && scope.row.type == 3
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#0064e6" } },
                                      [_vm._v("未开始")]
                                    )
                                  : _vm._e(),
                                scope.row.status == 2 && scope.row.type == 3
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [_vm._v("进行中")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.tableData.length == 0 && !_vm.newTaskLoading
                    ? _c("div", { staticClass: "wp-noData" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/noData.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }