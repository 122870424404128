
const state = {
	isShowRed: false,  // 是否显示红点
	isShowTip: false,  // 是否显示提示消息
	taskStatus: 0 // 演练状态
}
const mutations = {
	SET_ISSHOWRRED: (state, isShowRed) => {
		state.isShowRed = isShowRed
	},
	SET_ISSHOWTIP: (state, isShowTip) => {
		state.isShowTip = isShowTip
	},
	SET_TASK_STATUS: (state, taskStatus) => {
		state.taskStatus = taskStatus
	}
}

const actions = {
	// 获取下载列表
	GetDownList ({
		commit
	}) {
		commit('SET_ISSHOWRRED', true)
		commit('SET_ISSHOWTIP', true)
	},
	// 设置演练状态
	SetTaskStatus ({
		commit
	}, taskStatus) {
		commit('SET_TASK_STATUS', taskStatus)
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
