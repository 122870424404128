var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex-CB" }, [
        _c("div", { staticClass: "flex-Acenter fs-14" }, [
          _c("div", [
            _vm._v(" 完成总量："),
            _c("span", { staticClass: "wp-blue" }, [
              _vm._v(_vm._s(_vm.statisticsData.completeNum || 0)),
            ]),
          ]),
          _c("div", { staticClass: "ml-30" }, [
            _vm._v(" 奖励积分："),
            _c("span", { staticClass: "wp-blue" }, [
              _vm._v(_vm._s(_vm.statisticsData.score || 0)),
            ]),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "el-popover",
              {
                ref: "export-popover",
                attrs: {
                  "popper-class": "export-popover",
                  placement: "bottom",
                  trigger: "click",
                },
              },
              [
                _c("div", { staticClass: "export-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "export-item wp-pointer",
                      on: { click: _vm.detailExport },
                    },
                    [_vm._v(" 明细导出 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "export-item wp-pointer",
                      on: { click: _vm.exportFile },
                    },
                    [_vm._v(" 分月统计 ")]
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "wp-button",
                    staticStyle: { width: "90px" },
                    attrs: { slot: "reference", size: "medium" },
                    slot: "reference",
                  },
                  [
                    _vm._v("导出"),
                    _c("i", { staticClass: "icon-zhankai el-icon--right" }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "wp-table mt-20" },
        [
          _vm.tableData.length !== 0
            ? _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    stripe: "",
                    sortable: "custom",
                    "default-sort": _vm.sortObj,
                  },
                  on: { "sort-change": _vm.sortChange },
                },
                [
                  _c("el-table-column", { attrs: { width: "10" } }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      type: "index",
                      label: "序号",
                      "max-width": "70",
                      "min-width": "40",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { "max-width": "50", "min-width": "15" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      prop: "userName",
                      label: "人员姓名",
                      "max-width": "180",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", { attrs: { width: "10" } }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      prop: "groupName",
                      label: "所在分组",
                      "max-width": "250",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "textOverOneLine" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "" +
                                        (scope.row.parentName
                                          ? scope.row.parentName + "-"
                                          : "") +
                                        scope.row.groupName
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2970808341
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { "max-width": "30", "min-width": "10" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      prop: "custName",
                      label: "所在单位",
                      "max-width": "220",
                      "min-width": "145",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "taskNum",
                      label: "任务总数",
                      "max-width": "160",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "wp-pointer wp-blue",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showTaskDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(scope.row.taskNum) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      52096938
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "taskFinshNum",
                      label: "完成数量",
                      "max-width": "160",
                      "min-width": "100",
                    },
                  }),
                  _vm.searchParams.timeType == 2
                    ? _c("el-table-column", {
                        attrs: {
                          "max-width": "160",
                          "min-width": "100",
                          align: "center",
                          prop: "completionRate",
                          label: "完成率",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.completionRate) + "%"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3143357017
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      sortable: "",
                      prop: "workNum",
                      label: "作品数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      sortable: "",
                      prop: "score",
                      "min-width": "100",
                      label: "奖励积分",
                    },
                  }),
                  _vm.isCanAccessExcellentReview
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          sortable: "",
                          prop: "excellentScore",
                          "min-width": "100",
                          label: "优评积分",
                        },
                      })
                    : _vm._e(),
                  _vm.isCanAccessExcellentReview
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          sortable: "",
                          prop: "sumScore",
                          "min-width": "100",
                          label: "总积分",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.tableData.length == 0 && !_vm.loading
            ? _c("div", { staticClass: "wp-noData" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/noData.png"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("TaskDetails", {
        ref: "taskDetails",
        attrs: { searchParams: _vm.taskDetailsQuery },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }