<template>
  <div class="leaderPersonalInformation">
    <el-scrollbar class="wp-scrollbar" style="height: 100%;">
      <div class="layout-main">
        <!-- 基础信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-block-top">
            <div class="layout-title">基础信息
            </div>
          </div>

          <div class="layout-content">
            <el-form :model="ruleForm1" ref="ruleForm1" label-width="100px" class="ruleform">
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="人员账号：">
                    <div class="item-value">
                      {{ sysUserInfo.sysUser.loginName }}
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="11">

                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="用户姓名：">
                    <div class="item-value">
                      {{ ruleForm1.userName }}
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="11">

                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="手机号码：">
                    <div class="item-value">
                      {{ ruleForm1.mobile }}
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="11">

                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item label="单位名称：">
                    <div class="item-value">
                      {{ sysUserInfo.sysCust.custName }}
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                </el-col>
              </el-row>

            </el-form>
          </div>
        </div>
        <!-- 自媒体账号 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-block-top">
            <div class="layout-title">自媒体账号
            </div>

          </div>
          <el-collapse-transition>
            <div class="layout-content">
              <el-form :model="ruleForm2" ref="ruleForm2" label-width="100px" class="ruleform">
                <el-row type="flex" justify="space-between" v-for="(item, index) in sysUserMediaList" :key="index">
                  <el-col :span="11" v-for="it in item" :key="it.userMediaId">
                    <el-form-item :label="it.mediaName + '：'">
                      <el-input class="wp-input" v-model.trim="it.nickName" clearable placeholder="请输入账号昵称" maxlength="20">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>

        <!-- 银行信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-block-top">
            <div class="layout-title">银行信息
            </div>

          </div>
          <el-collapse-transition>
            <div class="layout-content">
              <el-form :model="ruleForm3" ref="ruleForm3" label-width="100px" class="ruleform">
                <el-row type="flex" justify="space-between">
                  <el-col :span="11">
                    <el-form-item label="开户银行：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.bankName" clearable placeholder="请输入开户银行" maxlength="20">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="银行账号：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.bankCode" @input="resetNumBankCode" clearable placeholder="请输入银行账号" maxlength="20">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="11">
                    <el-form-item label="开户行行号：">
                      <el-input class="wp-input" v-model.trim="ruleForm3.openBankCode" clearable placeholder="请输入开户行行号" maxlength="100">
                      </el-input>
                    </el-form-item>
                  </el-col>
                 
                </el-row>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>
        <!-- 按钮 -->
        <div class="layout-block wp-moudle-style btn-block">
          <el-button class="wp-button" size="medium" v-preventReClick type="primary" @click="validateFrom">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {resetNum, resetNumber } from '@/utils/validate.js';
import { editPersonInfo } from '@/api/systemSettings.js';
export default {
  name: 'createUnits',
  components: {},
  data () {
   
    return {
      ruleForm1: {
        userName: "",
        mobile: ""
      },
      ruleForm2: {
      },
      ruleForm3: {
        bankName: '',
        bankCode: '',
        openBankCode:''
      },
      sysUserMediaList: []
    };
  },
  props: {},
  created () {
    this.initData()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['sysUserInfo'])
  },
  methods: {
    // 初始化数据
    initData () {
      this.ruleForm1.userName = this.sysUserInfo.sysUser.userName
      this.ruleForm1.mobile = this.sysUserInfo.sysUser.mobile
      // 1,微信	2,微信视频号	3,今日头条	4,网易新闻	5,搜狐新闻	6,凤凰新闻	7,腾讯新闻	8,澎湃新闻	9,新浪新闻	10,一点资讯	11,抖音	12,其他	13,"
      
      this.sysUserMediaList = JSON.parse(JSON.stringify(this.$utils.supGroup(this.sysUserInfo.sysUserMediaList, 2))) 
      this.ruleForm3.bankName = this.sysUserInfo.sysUser.bankName
      this.ruleForm3.bankCode = this.sysUserInfo.sysUser.bankCode
      this.ruleForm3.openBankCode = this.sysUserInfo.sysUser.openBankCode
    },
    validateFrom () {
      this.postAdd()
    },
    // 提交、
    async postAdd () {
      let userMediaList = []
      this.sysUserMediaList.forEach(it => {
        userMediaList.push(...it)
      })

      let query = {
        ...this.ruleForm1,
        ...this.ruleForm3,
        userMediaList: userMediaList,
        isweb:true
      }
      try {
        let res = await editPersonInfo(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$store.dispatch('GetInfo')
      } catch (error) {
        console.log(error)
      }
    },
    // 手机号重置 值
    resetNumMobile () {
      this.ruleForm1.mobile = resetNum(this.ruleForm1.mobile);
    },
    // 银行账号 只能输入数字
    resetNumBankCode () {
      this.ruleForm3.bankCode = resetNumber(this.ruleForm3.bankCode);
    }


  },
  beforeDestroy () {

  },
  watch: {
    
  }
};
</script>
<style scoped lang="scss">
@import "./css/leaderPersonalInformation.scss";
</style>
