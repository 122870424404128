var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switchLogin" },
    [
      _c("div", { staticClass: "switchLoginBox" }, [
        _c("div", { staticClass: "select-box" }, [
          _c("div", { staticClass: "select-title" }, [
            _vm._v("请选择进入系统身份"),
          ]),
          _c("div", { staticClass: "select-items" }, [
            _vm.carouselData.length > 1
              ? _c("div", { staticClass: "arrow-block-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "left-arrow arrow-block",
                      on: {
                        click: function ($event) {
                          return _vm.goNext(-1)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-leftarrow" })]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "center-carousel" },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "leftCarousel",
                    attrs: {
                      autoplay: false,
                      interval: 5000,
                      arrow: "never",
                      "indicator-position": "none",
                    },
                  },
                  _vm._l(_vm.carouselData, function (item, index) {
                    return _c("el-carousel-item", { key: index }, [
                      _c(
                        "ul",
                        _vm._l(item, function (it) {
                          return _c(
                            "li",
                            { key: it.id, staticClass: "li-box" },
                            [
                              it.roleId == 1 || it.roleId == 2
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "preventReClick",
                                          rawName: "v-preventReClick",
                                        },
                                      ],
                                      staticClass: "select-it",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToIndex(1, it)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/login/guanliyuan.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("p", { staticClass: "role-name" }, [
                                        _vm._v("管理员"),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "bottom-info textOverOneLine",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(it.aliasName) +
                                              "-" +
                                              _vm._s(it.userName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              it.roleId == 3 || it.roleId == 4
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "preventReClick",
                                          rawName: "v-preventReClick",
                                        },
                                      ],
                                      staticClass: "select-it",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToIndex(4, it)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/login/wangpingyuan.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("p", { staticClass: "role-name" }, [
                                        _vm._v("投稿员"),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "bottom-info textOverOneLine",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(it.aliasName) +
                                              "-" +
                                              _vm._s(it.userName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
            _vm.carouselData.length > 1
              ? _c("div", { staticClass: "arrow-block-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "right-arrow arrow-block",
                      on: {
                        click: function ($event) {
                          return _vm.goNext(1)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-rightarrow" })]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("ParticlesView"),
      _c("change-password", {
        ref: "changePassword",
        on: { success: _vm.refreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }