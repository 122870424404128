<template>
  <div>
    <!-- 基础设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title fs-16 fw-bold">基础设置</div>
      <BasicsForm class="center" ref="basicsForm" :taskDetial="taskDetial" :isEidt="isEidt"></BasicsForm>
    </div>

  </div>
</template>

<script>
import BasicsForm from './form/basicsForm' // 基础设置

import taskApi from '@/api/task.js'
export default {
  components: { BasicsForm },
  props: {
    taskDetial: {
      type: Object,
      default: () => { }
    },
    isEidt: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
    getFormData () {
      return this.$refs.basicsForm.getFormData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>