<template>
  <div id="app">
    <!-- <keep-alive exclude="login"> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
 
}
</script>
<style lang="scss">
body {
  font-family: "思源黑体 CN Regular" !important;
}
</style>
