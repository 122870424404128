import WpPagination from './wpPagination/index.js'
import WpDialog from './wpDialog/index.js'
import WpConfirm from './wpConfirm/index.js'
import WpQuestionMark from './wpQuestionMark/index.js'
import WpSearchContent from './wpSearchContent/index.js'
import WpLoadSelect from './wpLoadSelect/index.js'
import WpVideo from './wpVideo/index.js'
import TaskDialog from './taskDialog/index.js'

const components = [
  WpPagination,
  WpDialog,
  WpQuestionMark,
  WpSearchContent,
  WpLoadSelect,
  WpVideo,
  TaskDialog
];

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });

  Vue.prototype.$wpConfirm = WpConfirm.install;
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
export default {
  install,
  WpPagination,
  WpDialog,
  WpQuestionMark,
  WpSearchContent,
  WpLoadSelect,
  WpVideo,
  TaskDialog
}