<template>
  <div class="wp-card" :class="className">
    <div class="title textOverOneLine" v-if="title">{{ title }}</div>
    <div class="row-content" v-if="slotValue == 'row-content'">
      <slot name="row-content"> </slot>
    </div>
    <div class="vertical-content" v-else>
      <slot name="vertical-content"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    slotValue: {
      type: String,
      default: 'row-content'
    },
    className:{
      type: String,
      default: ""
    }
  },
  data () {
    return {

    }
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>