<template>
  <ul class="flex file-list">
    <li class="file-item" v-for="(item, index) in fileList" :key="item.id">
      <el-image v-if="item.type == 'img'" :key="item.id" class="w100" :src="baseURL + item.id"
        :preview-src-list="$utils.arrayRearrangement(index, imgList)">
      </el-image>
      <div v-else class="w100 flex-column-center" @click="download(item.id)">
        <img v-if="item.type == 'xls'"  :src="$globalData.fileImgs.xls" 
        :title="item.name" />
      <img v-else-if="item.type == 'word'"  :src="$globalData.fileImgs.word" 
        :title="item.name" />
      <img v-else-if="item.type == 'zip'" :src="$globalData.fileImgs.zip"
        :title="item.name" />
      <img v-else-if="item.type == 'pdf'"  :src="$globalData.fileImgs.pdf" 
        :title="item.name" />
      <img v-else-if="item.type == 'mp4'"  :src="$globalData.fileImgs.mp4"
        :title="item.name" />
      </div>
      
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  props: {
    fileJson: [String, Array]
  },

  data () {
    return {
      fileList: [],
      imgList: [],
      videoList: [],
      baseURL: this.$axios.defaults.baseURL + '/file/'
    }
  },

  methods: {
    download (URL) {
      this.$utils.download(this.baseURL + URL)
    }
  },

  watch: {
    fileJson: {
      handler (newVal) {
        console.log(newVal);
        this.fileList = []
        this.imgList = []
        this.videoList = []

        let list = []
        if (typeof newVal == 'string') {
          list = JSON.parse(newVal)
        } else {
          list = newVal
        }

        this.fileList = this.$utils.setFileFileJson(list)
        console.log('this.fileList',this.fileList)
        this.fileList.forEach(item => {
          if (item.type == 'img') {
            this.imgList.push(this.baseURL + item.id)
          }
        })

        this.videoList = this.fileList.filter(item => item.type == 'mp4')
        console.log(this.imgList, this.fileList);
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.w100 {
  cursor: pointer;
  width: 100%;
  height: 100%; 
}

.file-list {
  .file-item {
    width: 96px;
    height: 96px;
    background: #E5F0FF;
    border-radius: 4px;
  }
  img {
    display: block;
    width: 57px;
    height: 57px;
  }
}
</style>