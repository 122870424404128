<template>
  <wp-dialog
    :showDefaultFooter="false"
    :showSlotFooter="true"
    title="通知公告"
    width="680px"
    height="440px"
    :dialogVisible="dialogVisible"
    @handleClose="handleClose"
  >
    <div class="strongReminder">
      <div class="pre-box">
        <div class="title">{{ noticeObj.title }}</div>
        <div class="pre-value">
          {{ noticeObj.content }}
        </div>
        <div class="file">
          <File style="margin-top: 10px" :fileJson="fileArr"></File>
        </div>
      </div>
    </div>
    <template slot="slotFooter">
      <span v-if="countdowning" class="countText">{{ remainingTime }}秒</span>
      <el-button
        v-else
        class="wp-button"
        type="primary"
        v-preventReClick
        size="medium"
        @click.stop="determine"
        >我知道了
      </el-button>
    </template>
  </wp-dialog>
</template>

<script>
import File from '@/views/wpyMessageCenter/components/fileDetail'
export default {
  name: 'strongReminder',
  components: { File },
  data () {
    return {
      dialogVisible: false,
      determineText: '',
      timer: null,
      remainingTime: 3,
      countdowning: true,
      fileArr: []
    };
  },
  props: {
    noticeObj: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {

  },
  methods: {
    cancel () {
      // if (this.step == 1) {
      //   this.dialogVisible = false
      // } else if (this.step == 2) {
      //   this.step = 1
      // }
    },
    determine () {
      this.dialogVisible = false
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      this.fileArr = this.noticeObj.fileJson ? JSON.parse(this.noticeObj.fileJson) : []
      console.log(this.fileArr)
      this.countDown()
    },
    countDown: function () {
      let that = this;
      that.timer = setInterval(() => {
        if (that.remainingTime <= 1) {
          that.resetCountdowning()
        } else {
          that.remainingTime--;
        }
      }, 1000);
    },
    resetCountdowning () {
      this.remainingTime = 3;
      this.clearTimer()
      this.countdowning = false;
    },
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.resetCountdowning()
          this.$reset('', [''])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.strongReminder {
  .pre-box {
    padding: 20px 52px 76px 52px;
  }

  .title {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }

  .pre-value {
    margin-top: 20px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    // white-space: pre-wrap;
    /* CSS 2.1 */
    word-break: break-all;
    /* 文本行的任意字内断开 */
    word-wrap: break-word;
    /* IE */
    text-align: justify !important;
    text-align-last: left !important;
  }
}

.countText {
  font-size: 16px;
  color: #0064e6;
}

.file {
  margin-top: 10px;
}

.image-list {
  margin-top: 20px;

  .image-item {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    width: 96px;
    height: 96px;
    background: #e5f0ff;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    .avatar {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 4px;
      margin: auto;
    }
  }
}
</style>