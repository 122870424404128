
import {
	taskHistory
} from '@/api/drill'

const state = {
	taskStatus: 0,// 演练状态
	blueTeamUsers: [],
	redTeamUsers: [],
	allCommentList: [],
	lineList: [],
	detail: {},
	blueCommentList: {},
	redCommentList: {},
	frequency: 1,
	redTopList: [],
	blueTopList: [],
	isPlaybackEnd: false,
	visibleNum: 1 // 窗口显示和隐藏的监听
}
const mutations = {
	SET_TASK_STATUS: (state, taskStatus) => {
		state.taskStatus = taskStatus
	},
	SET_BLUE_TEAM_USERS: (state, blueTeamUsers) => {
		state.blueTeamUsers = blueTeamUsers
	},
	SET_RED_TEAM_USERS: (state, redTeamUsers) => {
		state.redTeamUsers = redTeamUsers
	},
	SET_ALL_COMMENT_LIST: (state, allCommentList) => {
		state.allCommentList = allCommentList
	},
	SET_LINE_LIST: (state, lineList) => {
		state.lineList = lineList
	},
	SET_DETAIL: (state, detail) => {
		state.detail = detail
	},
	SET_BLUE_COMMENT_LIST: (state, blueCommentList) => {
		state.blueCommentList = blueCommentList
	},
	SET_RED_COMMENT_LIST: (state, redCommentList) => {
		state.redCommentList = redCommentList
	},
	SET_FREQUENCY: (state, frequency) => {
		state.frequency += 1
	},
	SET_RED_TOP_LIST: (state, redTopList) => {
		state.redTopList = redTopList
	},
	SET_BLUE_TOP_LIST: (state, blueTopList) => {
		state.blueTopList = blueTopList
	},
	SET_IS_PLAYBACK_END: (state, isPlaybackEnd) => {
		state.isPlaybackEnd = isPlaybackEnd
	},
	SET_VISIBLE: (state, visible) => {
		state.visibleNum = state.visibleNum++
	}
}

const actions = {
	// 设置演练状态
	SetTaskStatus ({
		commit
	}, taskStatus) {
		commit('SET_TASK_STATUS', taskStatus)
	},
	// 获取历史数据
	TaskHistory ({
		commit
	}, query) {
		return new Promise((resolve, reject) => {
			taskHistory(query).then(res => {
				commit('SET_FREQUENCY', 2)
				if (Number(res.returnCode) !== 200) return resolve(res)
				if (JSON.stringify(res.returnData) == '{}' || !res.returnData) {
					return;
				}
				if (JSON.stringify(res.returnData.detail) !== '{}' && res.returnData.detail) {
					commit('SET_DETAIL', res.returnData.detail)
				}
				commit('SET_BLUE_TEAM_USERS', res.returnData.blueTeamUsers)
				commit('SET_RED_TEAM_USERS', res.returnData.redTeamUsers)
				commit('SET_ALL_COMMENT_LIST', res.returnData.allCommentList.data)
				commit('SET_LINE_LIST', res.returnData.lineList)
				commit('SET_BLUE_COMMENT_LIST', res.returnData.blueCommentList)
				commit('SET_RED_COMMENT_LIST', res.returnData.redCommentList)
				commit('SET_RED_TOP_LIST', res.returnData.redTopList)
				commit('SET_BLUE_TOP_LIST', res.returnData.blueTopList)
				// 回放结束
				if (!query.speed) {
					commit('SET_IS_PLAYBACK_END', true)
				} else {
					// commit('SET_IS_PLAYBACK_END', false)
				}
				resolve(res)
			}).catch(error => {
				reject(error)
			})
		})
	},
	DocumentVisible ({
		commit
	}) {
		commit('SET_VISIBLE', 2)
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
