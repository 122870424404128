<template>
  <div class="success mt-16">
    <div class="content">
      <div class="success-image">
        <el-image :src="url"></el-image>
      </div>
      <div class="fs-18 fw-bold mt-16">发布成功</div>
      <div class="fs-14 mt-16" v-if="messageFlag">
        <span class="wp-blue wp-pointer" @click="goTaskList">点击返回</span>
        列表界面
      </div>
      <div class="fs-14 mt-16" v-else>
        短信余量不足，短信未发送，
        <span class="wp-blue wp-pointer" @click="goTaskList">点击返回</span>
        列表界面
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否勾选发送短信并有短信余量
    messageFlag: {
      type: Boolean,
      default: true
    },
    timeCount: [Number, String]
  },
  data () {
    return {
      url: require('@/assets/images/chenggong.png')
    }
  },
  watch: {
    timeCount: {
      handler (newVal) {
        console.log(newVal);
        setTimeout(() => {
          this.$router.push("taskList")
        }, +newVal)
      }
    }
  },
  // timeCount: {
  //   handler (newVal) {
  //     console.log(newVal);
  //     setTimeout(() => {
  //       this.$router.push("taskManagement")
  //     }, 3000)
  //   }
  // },
  created () {

  },
  methods: {
    goTaskList () {
      this.$router.push("taskList")
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>