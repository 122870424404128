<template>
  <wp-dialog
    @cancel="cancel"
    :title="title"
    @determine="determine"
    width="680px"
    height="440px"
    :dialogVisible="dialogVisible"
    @handleClose="handleClose"
    :showDefaultFooter="false"
    :showSlotFooter="true"
  >
    <div class="messageDetail">
      <div class="title">
        {{ newEidtObj.title }}
      </div>
      <div class="detail">
        <!-- {{ newEidtObj.content }} -->
        <pre class="pre-value" v-html="newEidtObj.content"></pre>
      </div>

      <div class="file">
        <File style="margin-top: 10px" :fileJson="fileJson"></File>
      </div>
    </div>
    <template slot="slotFooter">
      <el-button
        class="wp-button"
        type="primary"
        v-preventReClick
        size="medium"
        @click.stop="determine"
        >我知道了
      </el-button>
    </template>
  </wp-dialog>
</template>

<script>
import { insert, update, getMessage } from '@/api/messageCenter.js';
import * as personnelTeamApi from '@/api/personnelTeam.js'
import File from '@/views/wpyMessageCenter/components/fileDetail'
export default {
  name: 'messageDetail',
  components: { File },
  data () {
    return {
      rightList: [],
      step: 1,// 步骤
      isEidt: false,
      dialogVisible: false,
      newEidtObj: {},
      fileJson: []
    };
  },
  props: {
    eidtObj: {
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    title () {
      return '通知公告'
    }
  },
  methods: {
    validStepOne () {
      // this.$refs['ruleForm'].validate((valid) => {
      //   if (valid) {
      //     this.step = 2
      //     if (this.isEidt) {
      //       this.$nextTick(() => {
      //         this.setCheckedKeys([...this.newEidtObj.groupIds])
      //         this.getCheckedNodes()
      //       })
      //     } else {
      //       this.setCheckedKeys([])
      //       this.getCheckedNodes()
      //     }
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    validStepTwo () {
      if (this.rightList.length == 0) return this.$showMessage({
        type: 'error',
        msg: '请选择接收组'
      })
      this.postAdd()
    },
    cancel () {
      // if (this.step == 1) {
      //   this.dialogVisible = false
      // } else if (this.step == 2) {
      //   this.step = 1
      // }
    },
    determine () {
      this.dialogVisible = false
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.eidtObj.msgId) {
        this.getDetail()
      }
    },
    async getDetail () {
      let query = { msgId: this.eidtObj.msgId }
      try {
        let res = await getMessage(query)
        if (Number(res.returnCode) !== 200) return
        this.newEidtObj = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },
    setInitValue () {
      this.fileJson = this.newEidtObj.fileJson ? JSON.parse(this.newEidtObj.fileJson) : []
    },

  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('', [''])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.messageDetail {
  width: 100%;
  padding: 24px;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }

  .pre-value {
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    white-space: pre-wrap;
    /* CSS 2.1 */
    word-break: break-word;
    /* 文本行的任意字内断开 */
    word-wrap: break-word;
    /* IE */
    text-align: justify !important;
    text-align-last: left !important;
  }

  .detail {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }

  .file {
    margin-top: 10px;
  }
}
</style>