<!--第三方登录路由-->
<template>
  <div></div>
</template>

<script>
export default {
  name: "newOutSide",
  created () {
    this.$router.push("/login");
  },

  methods: {

  }
};
</script>

<style scoped></style>
