var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "el-breadcrumb",
            {
              staticClass: "wp-breadcrumb",
              staticStyle: { margin: "20px 24px 0" },
              attrs: { "separator-class": "el-icon-arrow-right" },
            },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/reportedReview" } } },
                [_vm._v("上报审核")]
              ),
              _c("el-breadcrumb-item", [_vm._v("审核详情")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("ContentCard", {
                attrs: { title: _vm.topicObj.title, slotValue: "row-content" },
                scopedSlots: _vm._u([
                  {
                    key: "row-content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 专题编号："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.topicObj.topicNum)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 专题分类："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.topicObj.topicTypeName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 专题积分："),
                          _c("span", { staticClass: "score" }, [
                            _vm._v(
                              _vm._s(
                                _vm.topicObj.score == -999
                                  ? "按平台"
                                  : _vm.topicObj.score
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ContentCard", {
                staticClass: "mt-16",
                attrs: {
                  slotValue: "vertical-content",
                  className: _vm.getClassName,
                },
                scopedSlots: _vm._u([
                  {
                    key: "vertical-content",
                    fn: function () {
                      return [
                        _vm.articleObj.title
                          ? _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品标题："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.articleObj.title)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleObj.content
                          ? _c("div", { staticClass: "work-content" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品内容："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.articleObj.content) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleObj.url
                          ? _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品链接："),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "url",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$utils.download(
                                        _vm.articleObj.url
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.articleObj.url) + " ")]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "row-content" }, [
                          _vm.articleObj.media
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 媒体平台："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.articleObj.media)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.articleObj.publishType
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 发布类型："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.articleObj.publishType)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.articleObj.authorType
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 作者类别："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.articleObj.authorType)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.articleObj.author
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 作者名称："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.articleObj.author)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.articleObj.netName
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 网名/昵称："),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.articleObj.netName)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "row-content" }, [
                          _vm.articleObj.readNum
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 阅读数量："),
                                _c("span", { staticClass: "score" }, [
                                  _vm._v(_vm._s(_vm.articleObj.readNum) + "万"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.articleObj.reprint
                            ? _c("div", { staticClass: "key" }, [
                                _vm._v(" 转发数量："),
                                _c("span", { staticClass: "score" }, [
                                  _vm._v(_vm._s(_vm.articleObj.reprint)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.articleObj.fileJson &&
                        _vm.articleObj.fileJson.length
                          ? _c(
                              "div",
                              { staticClass: "file-list" },
                              [
                                _c("div", { staticClass: "key" }, [
                                  _vm._v("截图/附件："),
                                ]),
                                _c("File", {
                                  attrs: { fileJson: _vm.articleObj.fileJson },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("div", { staticClass: "wp-card mt-16 footer" }, [
                _c("div", { staticClass: "head" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("审核意见")]),
                  _c(
                    "div",
                    { staticClass: "score-box" },
                    [
                      _c("div", [
                        _vm._v(" 奖励积分： "),
                        _c("span", { staticClass: "score" }, [
                          _vm._v(_vm._s(_vm.articleObj.totalScore)),
                        ]),
                      ]),
                      _c(
                        "el-popover",
                        {
                          ref: "popoverDetail",
                          attrs: {
                            placement: "top",
                            trigger: "click",
                            width: "220",
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.hidePopover(_vm.articleObj)
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { "margin-bottom": "5px" },
                            attrs: {
                              min: 0,
                              precision: 1,
                              max: 10000,
                              size: "small",
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.changeScore($event)
                              },
                            },
                            model: {
                              value: _vm.articleObj.totalScore,
                              callback: function ($$v) {
                                _vm.$set(_vm.articleObj, "totalScore", $$v)
                              },
                              expression: "articleObj.totalScore",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "6px" },
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.editScore(_vm.articleObj)
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "wp-blue wp-pointer",
                              staticStyle: { "margin-left": "24px" },
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditScore(_vm.articleObj)
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v(" 改分 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.articleObj.articleStatus == 1
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入审核意见",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.rejectContent,
                            callback: function ($$v) {
                              _vm.rejectContent = $$v
                            },
                            expression: "rejectContent",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "comment" }, [
                      _c(
                        "div",
                        { staticClass: "user" },
                        [
                          _c("el-avatar", {
                            staticStyle: { width: "32px", height: "32px" },
                            attrs: { src: _vm.headUrl },
                          }),
                          _c("div", { staticClass: "user-name" }, [
                            _vm._v(_vm._s(_vm.rejectData.createName || "")),
                          ]),
                          _c("div", { staticClass: "publish-time" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$moment(_vm.rejectData.createTime)
                                    .format("YYYY-MM-DD HH:mm")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "detail-content" }, [
                        _vm._v(" " + _vm._s(_vm.rejectData.resonRemark) + " "),
                      ]),
                    ]),
                _c("div", { staticClass: "bottom" }, [
                  _vm.articleObj.articleStatus == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { size: "medium", type: "danger" },
                              on: { click: _vm.handlerReject },
                            },
                            [_vm._v("驳回")]
                          ),
                        ],
                        1
                      )
                    : _c("div"),
                  _c(
                    "div",
                    [
                      _vm.acticleIdx != 0
                        ? _c(
                            "el-button",
                            {
                              key: "up",
                              staticClass: "wp-button",
                              attrs: { size: "medium" },
                              on: { click: _vm.up },
                            },
                            [_vm._v("上一条")]
                          )
                        : _vm._e(),
                      _vm.acticleIdx != _vm.articleIds.length - 1
                        ? _c(
                            "el-button",
                            {
                              key: "down",
                              staticClass: "wp-button",
                              attrs: { size: "medium" },
                              on: { click: _vm.down },
                            },
                            [_vm._v("下一条")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }