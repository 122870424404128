<template>
  <wp-dialog @cancel="cancel" :title="isEidt ? '编辑' : '添加'" @determine="determine" width="680px" height="430px" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="createModel">
      <div class="model-top">
        <div class="init-pass">初始密码：<span>abc123456</span></div>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform" @submit.native.prevent>

        <el-form-item prop="userName" label="人员姓名：">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.userName" placeholder="请输入人员姓名" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item prop="loginName" label="登录账号：">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.loginName" placeholder="请输入登录账号" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item prop="mobile" label="手机号码：">
          <el-input class="wp-input" maxlength="11" v-model.trim="ruleForm.mobile" placeholder="请输入手机号码" clearable @keyup.enter.native="determine" @input="resetNumMobile"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" class="remarks" placeholder="请输入备注" v-model.trim="ruleForm.remark" maxlength="20" resize="none">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { resetNum, isPhone, isEnAndNumAndUnderline } from '@/utils/validate.js';
import { checkChildInsert } from '@/api/personnelTeam.js'
export default {
  name: 'createModel',
  components: {},
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback('请输入手机号码');
      } else if (!isPhone(value)) {
        callback("请输入有效手机号码");
      } else {
        callback();
      }
    };
    var validateLoginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录账号'));
      } else if (!isEnAndNumAndUnderline(value)) {
        callback(new Error('请输入英文或数字'));
      } else {
        callback();
      }
    };
    return {
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        id: '',
        userName: "",
        loginName: "",
        mobile: "",
        remark: ""
      },
      rules: {
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateLoginName, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }
        ]
      },
      isPosting: false

    };
  },
  props: {
    accountItem: {
      type: Object,
      default: () => { }
    },
    loginUser: {
      type: Object,
      default: () => { }
    },
    childUserlist: {
      type: Array,
      default: () => []
    },
    // 是否在编辑主账号
    isEidtUser: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 初始化数据
    initDate () {
      if (this.accountItem.id) {
        this.isEidt = true
        this.ruleForm = { ...this.accountItem }
      } else {
        this.ruleForm.id = new Date().getTime()
      }
    },
    cancel () {
      this.handleClose()
    },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // 先在前端校验是否重复 然后在后端校验是否重复，才能添加成功
          this.verifyfront()


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 校验前端
    verifyfront () {
      // 校验和主账号的名称及手机号
      if (this.ruleForm.loginName == this.loginUser.loginName) {
        return this.$showMessage({
          type: 'error',
          msg: '登录账号已存在，请重新输入'
        })
      }
      if (this.ruleForm.mobile == this.loginUser.mobile) {
        return this.$showMessage({
          type: 'error',
          msg: '手机号已存在，请重新输入'
        })
      }
      let userList = JSON.parse(JSON.stringify(this.childUserlist))
      // 校验和其他值班账号的名称及手机号 // 注意编辑状态
      if (this.isEidt) {
        userList = this.childUserlist.filter(it => it.id != this.ruleForm.id)
      }
      let tip = ''
      userList.forEach(item => {
        if (this.ruleForm.loginName == item.loginName) {
          tip = '登录账号已存在，请重新输入'
        }
        if (this.ruleForm.mobile == item.mobile) {
          tip = '手机号已存在，请重新输入'
        }
      })
      if (tip.length > 0) {
        return this.$showMessage({
          type: 'error',
          msg: tip
        })
      }
      // 校验和数据库的值班账号的名称及手机号
      this.postVerify()
    },
    async postVerify () {
      if (this.isPosting) return
      this.isPosting = true
      let data = this.ruleForm
      let newChildUserlist = JSON.parse(JSON.stringify(this.childUserlist))
      // console.log(data)
      let index = newChildUserlist.findIndex(it => it.id == data.id)
      // console.log(index)
      if (index != -1) {
        newChildUserlist.splice(index, 1, data)
      } else {
        newChildUserlist = [...newChildUserlist, data]
      }
      let query = {
        childUserList: newChildUserlist,
        loginName: this.loginUser.loginName,
        mobile: this.loginUser.mobile
      }
      if (this.isEidtUser && this.isEidt) {
        query.userId = this.accountItem.userId
      }
      try {
        let res = await checkChildInsert(query)
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.postAdd()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    postAdd () {
      // 区分添加 和 编辑
      // 整个 人员信息是添加状态的那么这里都是前端操作
      let query = {
        ...this.ruleForm
      }
      this.$emit('success', query)
      this.$showMessage({
        type: 'success',
        msg: '操作成功'
      })
      this.handleClose()
    },
    // 手机号重置 值
    resetNumMobile () {
      this.ruleForm.mobile = resetNum(this.ruleForm.mobile);
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', ['rules'])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.createModel {
  padding: 40px;

  .model-top {
    display: flex;
    justify-content: flex-end;
  }

  .init-pass {
    font-size: 14px;
    font-weight: 400;
    color: #333333;

    > span {
      color: #f35454;
    }
  }

  .ruleform {
    margin-top: 30px;
    padding: 0px 60px 0px 60px;
  }
}

/deep/.remarks.el-textarea {
  .el-textarea__inner {
    height: 83px;
  }
}
</style>