<template>
  <wp-dialog @cancel="handleClose" :title="classifyAddFlag ? '添加分类' : '修改名称'" @determine="determine" width="420px"
    smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="classifyForm" :rules="classifyRule" ref="classifyRef" label-width="0px">
        <el-form-item prop="classifyName" label="">
          <el-input class="wp-input" maxlength="10" v-model.trim="classifyForm.classifyName" :placeholder="placeholder"
            clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
export default {
  name: 'addClassify',
  components: {},
  props: {
    // 新增或编辑
    classifyAddFlag: {
      type: Boolean,
      default: true
    },
    // 编辑回显数据
    formData: Object,
    placeholder: {
      type: String,
      default: '请输入分类名称'
    }
  },
  data () {
    return {
      dialogVisible: false, // 弹窗的显示与隐藏
      classifyForm: {
        classifyName: "" // 分类名称
      },
      classifyRule: {
        classifyName: { required: true, message: this.placeholder, trigger: 'blur' }
      }
    };
  },

  methods: {
    determine () {
      this.$refs.classifyRef.validate((valid) => {
        if (valid) {
          this.$emit("submitForm", this.classifyForm)
        } else {
          return false;
        }
      });
    },

    handleClose () {
      this.$refs.classifyRef.resetFields()
      this.dialogVisible = false
    },

    show () {
      this.dialogVisible = true
    },
  },

  watch: {
    formData: {
      handler (newVal, oldVal) {
        if (!this.classifyAddFlag) {
          this.classifyForm.classifyName = newVal.topicTypeName
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
</style>