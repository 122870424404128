<template>
  <div class="drillMembers">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/drillList' }"
            >投稿演练</el-breadcrumb-item
          >
          <el-breadcrumb-item>查看成员</el-breadcrumb-item>
        </el-breadcrumb>

        <SearchCard
          :title="title"
          @handlerSearch="handlerSearch"
          class="title-card mt-16"
        >
          <template v-slot:searchContent>
            <el-select
              class="search-w190 wp-select"
              v-model="searchQuery.type"
              clearable
              placeholder="请选择队伍"
            >
              <el-option
                v-for="item in ranks"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.userName"
              clearable
              placeholder="请输入人员姓名"
            >
            </el-input>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.mobile"
              clearable
              placeholder="请输入手机号码"
            >
            </el-input>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchQuery.weiboName"
              clearable
              placeholder="请输入微博昵称"
            >
            </el-input>
            <el-cascader
              ref="cascaderRef"
              class="search-w190 wp-cascader"
              :options="groupArr"
              @change="changeCascader"
              :props="defaultProps"
              collapse-tags
              placeholder="请选择所在分组"
              clearable
            ></el-cascader>
          </template>
        </SearchCard>
        <div class="layout-block wp-moudle-style layout-block-bottom">
          <div class="action-row">
            <div class="flex">
              <div class="count">
                红方： <span>{{ selRedUserIdsTotal }}</span>
              </div>
              <div class="count">
                蓝方： <span>{{ selBlueUserIdsTotal }}</span>
              </div>
            </div>

            <div class="flex">
              <el-button
                class="wp-button wp-btn-icon mr-12"
                size="medium"
                icon="icon-daochu"
                v-preventReClick
                @click="exportFile"
                >导出
              </el-button>
              <el-dropdown @command="handlerCommand">
                <el-button type="primary" class="wp-button" size="medium">
                  追加成员<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">红方成员</el-dropdown-item>
                  <el-dropdown-item command="2">蓝方成员</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="table-box">
            <!-- 序号，队伍，人员姓名，微博昵称，手机号码，所在分组、操作”   -->
            <div
              class="wp-table"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-text="正在加载"
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column
                  type="index"
                  label="序号"
                  width="70"
                  align="center"
                >
                </el-table-column>
                <!-- <el-table-column min-width="60" align="left" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column label="队伍" min-width="120" align="center">
                  <template slot-scope="scope">
                    <div
                      class="enableState enableState1"
                      v-if="scope.row.type == 2"
                    >
                      <span class="dot"></span> 蓝方
                    </div>
                    <div
                      class="enableState enableState2"
                      v-if="scope.row.type == 1"
                    >
                      <span class="dot"></span>红方
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userName"
                  label="人员姓名"
                  min-width="120"
                  align="left"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="weiboName"
                  label="微博昵称"
                  min-width="120"
                  align="left"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="mobile"
                  label="手机号码"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  min-width="40"
                  align="left"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="groupName"
                  label="所在分组"
                  min-width="120"
                  align="left"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  fixed="right"
                  label="操作"
                  width="150"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="flex-center">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="编辑"
                        placement="top"
                        popper-class="wp-btn-tooltip"
                        :visible-arrow="false"
                        :enterable="false"
                      >
                        <el-button
                          type="text"
                          icon="icon-bianji1"
                          class="wp-btn-icon-big"
                          @click.stop="eidtItem(scope.row)"
                        >
                        </el-button>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="移除"
                        placement="top"
                        popper-class="wp-btn-tooltip"
                        :visible-arrow="false"
                        :enterable="false"
                      >
                        <el-button
                          type="text"
                          icon=" icon-shanchucy"
                          class="wp-btn-icon-big"
                          @click="delItem(scope.row)"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div class="pagination-row">
              <wp-pagination
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :currentPage="query.page"
              >
              </wp-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <eidt-nick ref="eidtNick" :member="member" @success="refresh"> </eidt-nick>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchCard from '@/views/components/searchCard'
import reportedApi from '@/api/reportedStatistics.js'
import * as  drillApi from '@/api/drill.js'
import EidtNick from '@/views/drill/components/eidtNick.vue'
export default {
  name: 'drillMembers',
  components: { SearchCard, EidtNick },
  data () {
    return {
      // 队伍1红队2蓝队
      ranks: [{ value: '1', label: '红队' }, { value: '2', label: '蓝队' }],
      datetimeValue: null,
      loading: false,
      tableData: [],
      total: 0,
      searchQuery: {
        type: '',
        userName: "",
        weiboName: "",
        mobile: '',
        groupIds: []
      },
      query: {
        page: 1,
        limit: 30,
        type: '',
        userName: "",
        weiboName: "",
        mobile: '',
        groupIds: []
      },
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupArr: [],
      selRedUserIdsTotal: 0,
      selBlueUserIdsTotal: 0,
      taskDetial: {},
      title: '',
      member: {},
      isExporting: false

    };
  },
  props: {},
  created () {
    if (this.$route.query.id) {
      this.taskId = this.$decrypt(this.$route.query.id)
      this.getDetailTask()
      this.getGroupTree()
      this.getPageList()
    } else {
      this.$router.push({ name: 'drillList' })
    }
  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    // 获取详情
    async getDetailTask () {
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      if (res.returnCode != 200) return this.$showMessage({ type: 'error', msg: res.returnMsg })
      // console.log(res.returnData)
      this.taskDetial = res.returnData.taskDetial
      this.title = res.returnData.taskDetial.keyword

    },
    // 获取分组树
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupArr = res.returnData
      }
    },
    // 所在分组选项更改
    changeCascader (val) {
      console.log(val)
      let groupIds = []
      val.forEach(element => {
        groupIds.push(element[element.length - 1])
      });
      this.searchQuery.groupIds = groupIds
    },
    // 导出
    async exportFile (event) {
      // console.log(event)
      let query = {
        taskId: this.taskId,
        type: this.query.type,
        userName: this.query.userName,
        weiboName: this.query.weiboName,
        mobile: this.query.mobile,
        groupIds: this.query.groupIds
      }
      if (this.isExporting) return
      this.isExporting = true
      try {
        let res = await drillApi.receiverExport(query)
        this.isExporting = false
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } catch (error) {
        this.isExporting = false
        console.log(error)
      }
    },
    // 添加成员
    handlerCommand (command) {
      this.$router.push({ name: 'appendMembers', query: { id: this.$encrypt(this.taskId), type: command } })
    },
    handleSizeChange (val) {
      console.log(val)
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      console.log(val)
      this.query.page = val
      this.getPageList()
    },
    // 更新列表
    refresh () {
      this.query.page = 1
      this.getPageList()
    },
    // 搜索查询
    handlerSearch () {
      this.query = { ...this.query, ...this.searchQuery }

      this.refresh()
    },
    // 获取列表
    async getPageList () {

      let query = {
        taskId: this.taskId,
        ...this.query
      }
      if (this.loading) return
      try {
        this.loading = true
        let res = await drillApi.pageReceive(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.page.data.forEach(item => {
          item.oldWeiboName = item.weiboName
          if (!item.weiboName) { item.weiboName = '-' }
        })
        this.tableData = res.returnData.page.data
        this.total = res.returnData.page.totalCount
        this.selRedUserIdsTotal = res.returnData.redCount
        this.selBlueUserIdsTotal = res.returnData.blueCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 删除
    delItem (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelUser(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelUser (item) {
      try {
        let res = await drillApi.removeReceive({ id: this.taskId, userId: item.userId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    // 编辑人员昵称
    eidtItem (item) {
      this.member = item
      this.$refs.eidtNick.show()
    }
  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import './css/drillMembers.scss';
</style>
