
<template>
  <div class="wp-moudle-style tab-nav">
    <p class="nav-item" :class="{ 'active': item.id == activeID }" @click="toItem(item)" v-for="item in navList"
      :key="item.id">{{ item.name
      }}
    </p>
    <!-- <p class="nav-item">短信日志</p> -->
  </div>
</template>

<script>
export default {
  name: 'tab-nav',
  components: {},
  data () {
    return {

    };
  },
  props: {
    navList: {
      type: Array,
      default: () => {
        return [
          { id: 1, name: "充值记录", isActive: true, pathName: "smsManagList" },
          { id: 2, name: "短信日志", isActive: true, pathName: "smsLogList" }
        ]
      }
    },
    activeID: {
      type: Number,
      default: 1
    },
    custId: {
      type: String,
      default: ''
    },
    custName: {
      type: String,
      default: ''
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    toItem (item) {
      this.$router.push({ name: item.pathName, query: { _custId: this.$encrypt(this.custId), _custName: this.$encrypt(this.custName) } })
    }
  },
  beforeDestroy () {

  },
  watch: {

  }
};
</script>
<style scoped lang="scss">
.tab-nav {
  margin-top: 14px;
  height: 50px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0px 30px;

  .nav-item {
    font-size: 16px;
    font-weight: bold;
    color: #999999;
    line-height: 50px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    position: relative;
    margin-right: 70px;

    &::after {
      display: blocK;
      content: '';
      position: absolute;
      width: 43px;
      height: 2px;
      /*no*/
      background-color: transparent;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.active {
      color: #0064E6;

      &::after {
        background-color: #0064E6;
      }
    }
  }
}
</style>

