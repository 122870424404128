<template>
  <div class="taskAnalyse">
    <div class="layout-content">
      <el-scrollbar style="height: 100%">
        <div class="home-admin-content">
          <!-- 任务分析 -->
          <SearchCard title="投稿分析" @handlerSearch="handlerSearch">
            <template v-slot:searchContent>
              <div class="wp-date-picker search-w392">
                <!-- 默认为上个月1日0点到现在 -->
                <el-date-picker v-model="searchQuery.datetimeValue" prefix-icon="" value-format="yyyy-MM-dd" clearable
                  type="daterange" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
              <wpLoadSelect class="search-w392 wp-select align-top" v-model="searchQuery.taskId" :data="taskList"
                :page="taskObj.page" dictLabel="title" dictValue="taskId" dictTime="createTime" :hasMore="taskObj.more"
                :request="getData" @clearTitle="() => (taskObj.title = '')" placeholder="请选择任务"></wpLoadSelect>
              <!-- <el-select class="search-w392 wp-select" v-model="searchQuery.taskId" clearable placeholder="请选择任务">
                <el-option v-for="item in taskList" :key="item.taskId" :label="item.title" :value="item.taskId">
                </el-option>
              </el-select> -->
              <el-select filterable class="search-w190 wp-select" v-model="searchQuery.taskTypeId" clearable
                placeholder="请选择任务分类">
                <el-option v-for="item in taskTypeList" :key="item.taskTypeId" :label="item.taskTypeName"
                  :value="item.taskTypeId">
                </el-option>
              </el-select>
            </template>
          </SearchCard>

          <!-- 汇总信息 -->
          <div class="layout-block wp-moudle-style">
            <div class="layout-title">汇总信息</div>
            <div class="layout-content">
              <div class="summary-box">
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.taskNum
                        ? wpTaskDescribeObj.taskNum
                        : 0
                      }}
                    </p>
                  </div>
                  <span>投稿总数</span>
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.totalScore
                        ? wpTaskDescribeObj.totalScore
                        : 0
                      }}
                    </p>
                  </div>
                  <span>积分总数</span>
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.completionNum
                        ? wpTaskDescribeObj.completionNum
                        : 0
                      }}
                    </p>
                  </div>
                  <span>完成人数</span>
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.ncompletionNum
                        ? wpTaskDescribeObj.ncompletionNum
                        : 0
                      }}
                    </p>
                  </div>
                  <span>未完成人数</span>
                </div>
                <div class="right-item right-item-after">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.completionRate
                        ? wpTaskDescribeObj.completionRate
                        : 0
                      }}%
                    </p>
                  </div>
                  <span>完成率</span>
                </div>
                <div class="right-item">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.passRate
                        ? wpTaskDescribeObj.passRate
                        : 0
                      }}%
                    </p>
                  </div>
                  <span>合格率</span>
                </div>
                <div class="right-item">
                  <div class="right-item-text">
                    <p>
                      {{
                        wpTaskDescribeObj.rejectRate
                        ? wpTaskDescribeObj.rejectRate
                        : 0
                      }}%
                    </p>
                  </div>
                  <span>驳回率</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 任务方式分布 任务分类占比 任务站点占比 -->
          <div class="layout-mid2">
            <div class="percent-w33-left">
              <div class="wp-moudle-style mid2-item" v-loading="itemLoading1" element-loading-spinner="el-icon-loading">
                <div class="item-title home-title item-title-flex">
                  <div>投稿方式分布</div>
                  <div>
                    <el-button-group>
                      <el-button size="small" :disabled="itemLoading1" class="wp-button-group wp-button-group-left"
                        :class="{ active: taskClassID == 1 }" @click="togTaskClassID(1)">
                        任务发布
                      </el-button>
                      <el-button size="small" :disabled="itemLoading1" class="wp-button-group"
                        :class="{ active: taskClassID == 2 }" @click="togTaskClassID(2)">任务完成
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
                <div class="item-content">
                  <bar-chart ref="barChart" v-if="taskXAxisData.length > 0" hoverTitle="任务数量" id="barChart"
                    :maxXAxisLength="2" :xAxisData="taskXAxisData" :yAxisData="taskYAxisData">
                  </bar-chart>
                  <div class="wp-noData" v-if="taskXAxisData.length == 0 && !itemLoading1">
                    <img src="~@/assets/images/noData.png" alt="" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="percent-w33-mid">
              <div class="wp-moudle-style mid2-item" v-loading="itemLoading2" element-loading-spinner="el-icon-loading">
                <div class="item-title home-title">投稿分类占比</div>
                <div class="item-content classify">
                  <pie-chart ref="pieChart" id="pieChart" :pieData="taskPieData">
                  </pie-chart>
                </div>
              </div>
            </div>
            <div class="percent-w34-right">
              <div class="wp-moudle-style mid2-item" v-loading="itemLoading3" element-loading-spinner="el-icon-loading">
                <div class="item-title home-title">投稿站点占比</div>
                <div class="item-content item-content-zhandian">
                  <progress-list :dataList="progressDataList"></progress-list>
                </div>
              </div>
            </div>
          </div>

          <!-- 平均响应排行 未完成人员 -->
          <div class="layout-mid4">
            <div class="percent-w50">
              <div class="wp-moudle-style mid4-item">
                <div class="item-title home-title item-title-flex">
                  <div>平均响应排行</div>
                  <div>
                    <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-daochu" type="primary" style="opacity:0"></el-button>
                  </div>
                </div>
                <div class="item-content">
                  <el-scrollbar style="height: 100%">
                    <div class="wp-table wp-table-border" v-loading="itemLoading4"
                      element-loading-spinner="el-icon-loading">
                      <el-table :data="tableData1" stripe style="width: 100%" v-if="tableData1.length > 0">
                        <el-table-column label="排行" width="100" align="center">
                          <template slot-scope="scope">
                            <span class="bgIndex" :style="'background-image: url(' + jinPai + ');'"
                              v-if="scope.row.index == 1">
                              {{ scope.row.index }}
                            </span>
                            <span class="bgIndex" :style="'background-image: url(' + yinPai + ');'"
                              v-else-if="scope.row.index == 2">
                              {{ scope.row.index }}
                            </span>
                            <span class="bgIndex" :style="'background-image: url(' + dong + ');'"
                              v-else-if="scope.row.index == 3">
                              {{ scope.row.index }}
                            </span>
                            <span style="color: #faa548" v-else>{{
                              scope.row.index
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column align="left" width="40">
                        </el-table-column>
                        <el-table-column prop="key" label="人员姓名" align="left" min-width="120" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="name" label="所在分组" align="left" header-align="left" min-width="120"
                          show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="totalStr" label="平均响应时间" min-width="120" show-overflow-tooltip
                          header-align="center" align="center">
                        </el-table-column>
                      </el-table>
                      <div class="wp-noData" v-if="tableData1.length == 0 && !itemLoading4">
                        <img src="~@/assets/images/noData.png" alt="" />
                        <p>暂无数据</p>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
            </div>
            <div class="percent-w50-right">
              <div class="wp-moudle-style mid4-item">
                <div class="item-title home-title item-title-flex">
                  <div>未完成人员</div>
                  <div>
                    <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-daochu" type="primary"  @click="userTaskExport">导出</el-button>
                  </div>
                </div>
                <div class="item-content">
                  <el-scrollbar style="height: 100%">
                    <div class="wp-table wp-table-border" v-loading="itemLoading5"
                      element-loading-spinner="el-icon-loading">
                      <el-table :data="tableData2" stripe style="width: 100%" v-if="tableData2.length > 0">
                        <el-table-column min-width="40" align="left" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="key" label="人员姓名" min-width="180" align="left" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="name" label="所在分组" align="left" header-align="left" width="170"
                          show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="total" label="未完成量" min-width="180" align="center" header-align="center"
                          show-overflow-tooltip>
                        </el-table-column>
                      </el-table>
                      <div class="wp-noData" v-if="tableData2.length == 0 && !itemLoading5">
                        <img src="~@/assets/images/noData.png" alt="" />
                        <p>暂无数据</p>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import BarChart from '@/components/echartsComponents/barChart.vue';
import PieChart from '@/components/echartsComponents/pieChart.vue';
import ProgressList from '@/views/homeAdmin/components/progressList.vue';
import SearchCard from '@/views/components/searchCard'
import taskApi from '@/api/task.js'
import * as homeAdminApi from '@/api/homeAdmin.js';
import { mapGetters } from 'vuex'
export default {
  name: 'taskAnalyse',
  components: { SearchCard, BarChart, PieChart, ProgressList },
  data () {
    return {
      searchQuery: {
        datetimeValue: null,
        taskId: "",
        taskTypeId: ""
      },
      query: {
        taskId: "",
        taskTypeId: "",
        startTime: "",
        endTime: ""
      },
      tableData1: [],
      tableData2: [],
      taskXAxisData: [],
      taskYAxisData: [],
      // taskXAxisData: ['转发微博'],
      // taskYAxisData: [60],
      taskPieData: [
        // {
        //   value: 335,
        //   name: '微信',
        // },
      ],
      progressDataList: [
        // { id: 1, num: 50, name: "转发微博" },
      ],
      itemLoading1: false,
      itemLoading2: false,
      itemLoading3: false,
      itemLoading4: false,
      itemLoading5: false,
      taskClassID: 1,//1.任务类 2.上报类
      taskTypeList: [],// 任务分类
      taskList: [],// 任务列表
      pickerOptions: {},
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      wpTaskDescribeObj: {},
      jinPai: require('../../../assets/images/home/jinPai.png'),
      yinPai: require('../../../assets/images/home/yinPai.png'),
      dong: require('../../../assets/images/home/dong.png'),
      isExporting: false
    };
  },
  props: {},
  created () {
    this.getTaskTypeList()
  },
  mounted () {
    this.setPickerOptions()
    // < !--默认为上个月1日0点到现在 -->
    this.searchQuery.datetimeValue = [this.$moment().add(-1, 'M').format("YYYY-MM-01"), this.$moment(new Date()).format("YYYY-MM-DD")]
    if(this.isGZ){
      this.searchQuery.datetimeValue[0] = this.$moment('2023/01/01').format('YYYY-MM-DD 00:00:00')
      this.searchQuery.datetimeValue[1] = this.$moment().format('YYYY-MM-DD 23:59:59')
     }

    this.handlerSearch()
  },
  computed: {
    ...mapGetters(['sysCust']),
    // 是否赣州
    isGZ(){
      return this.sysCust.custId == 2
    }

  },
  methods: {
    setPickerOptions () {
      // 可按添加时间段筛选查看，默认为空，可选到时间点。可选择近3年的时间（例：今天2022年，可以选到2019年1月1日）

      let threeYearAgo = new Date(`${new Date().getFullYear() - 3}/01/01`)
      let startDate = threeYearAgo.getTime()
      this.$nextTick(() => {
        this.pickerOptions = {
          disabledDate (time) {
            return time.getTime() < startDate
          }
        }

      })

    },
    // 加载所有的数据
    initData () {
      this.getWpTaskDescribe()
      this.getBarChartData()
      this.getWpTaskAnalysisType()
      this.getWpTaskAnalysisWebsite()
      this.getUserSubmitTime()
      this.getUserTask()
    },
    // 点击搜索按钮
    handlerSearch () {
      let datetimeValue = this.searchQuery.datetimeValue ? this.searchQuery.datetimeValue : []
      this.query = {
        ...this.query,
        ...this.searchQuery,
        startTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      delete this.query.datetimeValue
      this.initData()
    },
    // 任务分类-列表
    async getTaskTypeList () {
      try {
        let res = await taskApi.taskTypeList({})
        if (res.returnCode != 200) return
        this.taskTypeList = res.returnData
      } catch (error) {
        console.log(error);
      }
    },

    // 统计分析-汇总信息
    async getWpTaskDescribe () {
      // 假如起始时间是空的话 
      // 默认为上个月1日0点到现在。只可以选择近3年的时间（例：今天2022年，可以选到2019年1月1日）。
      let query = {
        ...this.query
      }
      // console.log(query)
      try {
        let res = await homeAdminApi.wpTaskDescribe(query)
        if (res.returnCode != 200) return
        this.wpTaskDescribeObj = res.returnData
      } catch (error) {
        console.log(error);
      }
    },
    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData
        let arraylist = res.returnData.data.map(it => { return { ...it, createTime: this.$moment(it.createTime).format('yyyy-MM-DD') } })
        // 专题列表
        this.taskList = [...this.taskList, ...arraylist]

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },
    // 统计分析-任务方式
    async getWpTaskAnalysisStyle () {
      // 默认本年 当releaseTimeValue 是空数组时
      let query = {
        ...this.query
      }
      this.itemLoading1 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisStyle(query)
        this.itemLoading1 = false
        if (res.returnCode != 200) return
        // 截取前7个 
        let datas = res.returnData.splice(0, 7)
        // taskXAxisData
        this.taskXAxisData = datas.map(item => item.name)
        // taskYAxisData
        this.taskYAxisData = datas.map(item => item.total)
        this.$nextTick(() => {
          if (this.$refs.barChart) {
            this.$refs.barChart.setData()
          }
        })
      } catch (error) {
        this.itemLoading1 = false
        console.log(error);
      }
    },
    // 统计分析-任务完成方式
    async getFinishStyle () {
      // 默认本年 当releaseTimeValue 是空数组时
      let query = {
        ...this.query
      }
      this.itemLoading1 = true
      try {
        let res = await taskApi.finishStyle(query)
        this.itemLoading1 = false
        if (res.returnCode != 200) return
        // 截取前7个 
        let datas = res.returnData.splice(0, 7)
        // taskXAxisData
        this.taskXAxisData = datas.map(item => item.name)
        // taskYAxisData
        this.taskYAxisData = datas.map(item => item.total)
        this.$nextTick(() => {
          if (this.$refs.barChart) {
            this.$refs.barChart.setData()
          }
        })

      } catch (error) {
        this.itemLoading1 = false
        console.log(error);
      }
    },
    // 切换任务方式的按钮
    togTaskClassID (taskClassID) {
      this.taskClassID = taskClassID
      this.getBarChartData()
    },
    // 获取柱状图的数据
    getBarChartData () {
      if (this.taskClassID == 1) { this.getWpTaskAnalysisStyle() } else {
        this.getFinishStyle()
      }
    },
    // 统计分析-分类
    async getWpTaskAnalysisType () {
      let query = {
        ...this.query
      }
      this.itemLoading2 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisType(query)
        this.itemLoading2 = false
        if (res.returnCode != 200) return
        this.taskPieData = res.returnData.map(item => { return { value: item.total, name: item.name } })
        this.$nextTick(() => {
          this.$refs.pieChart.setData()
        })
      } catch (error) {
        this.itemLoading2 = false
        console.log(error);
      }
    },
    // 统计分析-站点
    async getWpTaskAnalysisWebsite () {
      let query = {
        ...this.query
      }
      this.itemLoading3 = true
      try {
        let res = await homeAdminApi.wpTaskAnalysisWebsite(query)
        this.itemLoading3 = false
        if (res.returnCode != 200) return
        const totalCount = eval(res.returnData.map(it => Number(it.total)).join("+"))
        // 计算百分比　截取前８个
        let progressDataList = []
        res.returnData.forEach((item, index) => {
          if (index <= 7) {
            let obj = { id: index, name: item.name, total: item.total, num: totalCount > 0 ? Number(this.$utils.changeDecimalToPercentage(item.total / totalCount)) : 0 }
            progressDataList.push(obj)
          }
        })
        console.log(progressDataList);
        this.progressDataList = progressDataList
      } catch (error) {
        this.itemLoading3 = false
        console.log(error);
      }
    },
    // 统计分析-响应排行
    async getUserSubmitTime () {
      let query = {
        limit: 10,
        page: 1,
        ...this.query
      }
      this.itemLoading4 = true
      try {
        let res = await taskApi.userSubmitTime(query)
        this.itemLoading4 = false
        if (res.returnCode != 200) return
        //  3）只显示排行前10，按用时从短到长正序排列。
        // 4）<1分钟：显示1分钟；<1小时：显示xx分钟；<1天：显示xx小时；>1天：显示xx小时。（四舍五入取整）
        res.returnData.data.forEach((item, index) => {
          item.index = index + 1
          item.totalStr = ''
          if (item.total < 1) {
            item.totalStr = '1分钟'
          } else if (item.total < 60) {
            item.totalStr = `${Math.floor(item.total)}分钟`
          } else if (item.total > 60) {
            item.totalStr = ` ${Math.floor(item.total / 60)}小时`
          }
        })
        this.tableData1 = res.returnData.data
      } catch (error) {
        this.itemLoading4 = false
        console.log(error);
      }
    },
    // 统计分析-响应排行
    async getUserTask () {
      let query = {
        limit: 1000,
        page: 1,
        ...this.query
      }
      this.itemLoading5 = true
      try {
        let res = await taskApi.userTask(query)
        this.itemLoading5 = false
        if (res.returnCode != 200) return
        this.tableData2 = res.returnData.data
        console.log(this.tableData2)
      } catch (error) {
        this.itemLoading5 = false
        console.log(error);
      }
    },
    async userTaskExport (event) {
      let query = {
        limit: 1000,
        page: 1,
        ...this.query
      }
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await taskApi.userTaskExport(query)
        this.isExporting = false
        if (Number(res.returnCode) == 1009) return this.$showMessage({
          type: 'warning',
          msg: '文件正在下载，请在下载中心查看'
        })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$store.dispatch('app/GetDownList')
        this.$utils.setBall(event)
      } catch (error) {
        this.isExporting = false
      }
    }

  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">@import "./css/taskAnalyse.scss";</style>
