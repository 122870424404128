<template>
  <div>
    <!-- 基础设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title fs-16 fw-bold">基础设置</div>
      <BasicsForm
        :taskTypeId="taskTypeId"
        :storeId="storeId"
        @getStyleList="getStyleList"
        class="center"
        ref="basicsForm"
      ></BasicsForm>
    </div>

    <!-- 任务设置 -->
    <div class="wp-task-card mt-16">
      <div class="card-title flex-CB">
        <div class="fs-16 fw-bold">任务设置</div>
        <div class="flex-Acenter instancy-task">
          <el-checkbox class="isUrgent" v-model="isUrgent"
            >紧急任务</el-checkbox
          >
          <wp-question-mark
            style="margin-left: 6px"
            content="任务发出后APP会强提醒30秒"
          ></wp-question-mark>
        </div>
      </div>
      <TaskForm
        :configObj="configObj"
        :taskStyleList="taskStyleList"
        ref="taskForm"
      ></TaskForm>
    </div>

    <!-- 高级选项 -->
    <div
      class="wp-task-card mt-16"
      :style="{ height: foldFlag ? '174px' : '74px' }"
    >
      <div class="card-title flex-CB">
        <div class="fs-16 fw-bold">高级选项</div>
        <div class="wp-blue toggle" @click="foldFlag = !foldFlag">
          <span>{{ !foldFlag ? '展开' : '收起' }}</span>
          <i
            v-show="foldFlag"
            class="el-icon-arrow-up fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
          <i
            v-show="!foldFlag"
            class="el-icon-arrow-down fs-14 wp-blue"
            style="margin-left: 6px"
          ></i>
        </div>
      </div>

      <div class="footer" :style="{ display: foldFlag ? 'block' : 'none' }">
        <div class="layout-content">
          <el-form label-width="100px" class="advanced-form">
            <el-form-item
              :label="item.configName + '：'"
              v-for="item in advancedSetting2"
              :key="item.custConfigId"
            >
              <el-checkbox
                v-model="item.isShow"
                :disabled="item.isDisbled"
                @change="checkboxChangeShow2"
                >显示
              </el-checkbox>
              <el-checkbox
                v-model="item.isRequired"
                :disabled="item.isDisbled"
                @change="checkboxChangeRequired2"
                >必填
              </el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicsForm from './form/basicsForm' // 基础设置
import TaskForm from './form/taskForm' // 任务设置

import taskApi from '@/api/task.js'
export default {
  components: { BasicsForm, TaskForm },
  props: {
    taskTypeId: [Number, String],
    storeId: [Number, String]
  },
  data () {
    return {
      isUrgent: false, // 紧急任务
      foldFlag: false,
      taskStyleList: [], // 任务方式列表
      configObj: {}, // 任务配置项
      advancedSetting2: [], // 高级设置
    }
  },
  created () {
    console.log(this.taskTypeId);
    // 获取单位配置
    this.getConfig()
  },
  methods: {
    // 获取任务方式列表
    getStyleList (list) {
      console.log(list)
      this.taskStyleList = list || []
    },

    // 获取单位默认参数
    async getConfig () {
      const res = await taskApi.getConfigList({})
      if (res.returnCode == 200) {
        const data = res.returnData
        console.log(data)

        const configObj = {}
        // 积分列表
        configObj.scoreList = data.taskScore || []
        // 字数要求
        configObj.taskWordsRequire = data.taskWordsRequire || []
        // 单人上限
        configObj.taskSingleLimit = data.taskSingleLimit || []
        // 起止时间快捷选项
        configObj.timeList = data.taskTime

        this.configObj = configObj

        this.$emit("note", data.taskSmsNotice?.[0].configValue)

        // 高级设置
        this.setConfigValue(data)
      }
    },

    //  高级设置 改变显示 
    checkboxChangeShow2 () {
      this.advancedSetting2.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },

    //  高级设置 改变必填 
    checkboxChangeRequired2 () {
      this.advancedSetting2.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },

    // 高级设置
    setConfigValue (data) {
      //作品标题 taskArticleTitle
      let advancedSetting2 = []
      let taskArticleTitle = data.taskArticleTitle[0].configValue.toString()
      advancedSetting2.push({ ...data.taskArticleTitle[0], isShow: taskArticleTitle.charAt(0) == '1', isRequired: taskArticleTitle.charAt(1) == '1', isDisbled: false })

      //作品内容 taskAarticleContent
      let taskAarticleContent = data.taskAarticleContent[0].configValue.toString()
      advancedSetting2.push({ ...data.taskAarticleContent[0], isShow: taskAarticleContent.charAt(0) == '1', isRequired: taskAarticleContent.charAt(1) == '1', isDisbled: false })

      //作品链接 taskArticleUrl	
      let taskArticleUrl = data.taskArticleUrl[0].configValue.toString()
      advancedSetting2.push({ ...data.taskArticleUrl[0], isShow: taskArticleUrl.charAt(0) == '1', isRequired: taskArticleUrl.charAt(1) == '1', isDisbled: false })

      //附件/截图 taskArticleFile
      let taskArticleFile = data.taskArticleFile[0].configValue.toString()
      advancedSetting2.push({ ...data.taskArticleFile[0], isShow: taskArticleFile.charAt(0) == '1', isRequired: taskArticleFile.charAt(1) == '1', isDisbled: true })

      console.log(advancedSetting2)
      this.advancedSetting2 = advancedSetting2
    },

    // 点击下一步-获取表单数据
    getFormData () {
      const basicsForm = JSON.parse(JSON.stringify(this.$refs['basicsForm'].getFormData()))

      const taskForm = JSON.parse(JSON.stringify(this.$refs['taskForm'].getFormData()))

      if (basicsForm.pass && taskForm.pass) {
        taskForm.modeStyle = taskForm?.modeStyle?.join(',')
        // 是否为紧急任务
        basicsForm.isUrgent = this.isUrgent

        this.$refs['basicsForm'].clearValidate()
        this.$refs['taskForm'].clearValidate()

        // 高级设置
        this.advancedSetting2.forEach(item => {
          item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
        })

        this.advancedSetting2.forEach(value => {
          if (value.configName == "作品标题") {
            taskForm.parmTitle = value.configValue
          } else if (value.configName == "作品内容") {
            taskForm.parmContent = value.configValue
          } else if (value.configName == "作品链接") {
            taskForm.parmUrl = value.configValue
          } else {
            taskForm.parmAttach = value.configValue
          }
        })

        console.log({ ...basicsForm, ...taskForm })
        return { ...basicsForm, ...taskForm }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>