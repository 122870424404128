var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "el-breadcrumb",
            {
              staticClass: "wp-breadcrumb",
              staticStyle: { margin: "20px 24px 0" },
              attrs: { "separator-class": "el-icon-arrow-right" },
            },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/taskReview" } } },
                [_vm._v("投稿审核")]
              ),
              _c("el-breadcrumb-item", [_vm._v("作品详情")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("ContentCard", {
                attrs: {
                  title: _vm.taskDetail.title,
                  slotValue: "row-content",
                },
                scopedSlots: _vm._u([
                  {
                    key: "row-content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务编号："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskNumber)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务分类："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskStyleStr)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务站点："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.websiteName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务方式："),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.taskDetail.taskWay)),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v(" 任务积分："),
                          _c("span", { staticClass: "score" }, [
                            _vm._v(_vm._s(_vm.taskDetail.score)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ContentCard", {
                staticClass: "mt-16",
                attrs: { slotValue: "vertical-content" },
                scopedSlots: _vm._u([
                  {
                    key: "vertical-content",
                    fn: function () {
                      return [
                        _vm.articleDetail.title
                          ? _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品标题："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.articleDetail.title)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.url
                          ? _c("div", { staticClass: "item mt-12 flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "key",
                                  staticStyle: { "min-width": "73px" },
                                },
                                [_vm._v("作品链接：")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "url textOverOneLine",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$utils.download(
                                        _vm.articleDetail.url
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.articleDetail.url) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.content
                          ? _c("div", { staticClass: "work-content" }, [
                              _c("div", { staticClass: "key" }, [
                                _vm._v("作品内容："),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.articleDetail.content) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.articleDetail.fileJson &&
                        _vm.articleDetail.fileJson.length
                          ? _c(
                              "div",
                              { staticClass: "file-list" },
                              [
                                _c("div", { staticClass: "key" }, [
                                  _vm._v("截图/附件："),
                                ]),
                                _c("File", {
                                  attrs: {
                                    fileJson: _vm.articleDetail.fileJson,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("div", { staticClass: "wp-card mt-16 footer" }, [
                _c("div", { staticClass: "head" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("审核意见")]),
                  _c(
                    "div",
                    { staticClass: "score-box" },
                    [
                      _c("div", [
                        _vm._v(" 奖励积分： "),
                        _c("span", { staticClass: "score" }, [
                          _vm._v(_vm._s(_vm.articleDetail.rewardScore)),
                        ]),
                      ]),
                      _c(
                        "el-popover",
                        {
                          ref: "popoverDetailRef",
                          attrs: {
                            placement: "top",
                            trigger: "click",
                            width: "220",
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.hidePopover(_vm.articleDetail)
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { "margin-bottom": "5px" },
                            attrs: {
                              min: 0,
                              precision: 1,
                              max: 100,
                              size: "small",
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.changeScore($event)
                              },
                            },
                            model: {
                              value: _vm.articleDetail.rewardScore,
                              callback: function ($$v) {
                                _vm.$set(_vm.articleDetail, "rewardScore", $$v)
                              },
                              expression: "articleDetail.rewardScore",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "6px" },
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitScore(
                                    _vm.articleDetail,
                                    "popoverDetailRef"
                                  )
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                          _c("i", {
                            staticClass: "icon-bianji1",
                            attrs: { slot: "reference" },
                            on: {
                              click: function ($event) {
                                return _vm.editScore(_vm.articleDetail)
                              },
                            },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.articleDetail.articleStatus == 1
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入审核意见",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.rejectContent,
                            callback: function ($$v) {
                              _vm.rejectContent = $$v
                            },
                            expression: "rejectContent",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "comment" }, [
                      _c(
                        "div",
                        { staticClass: "user" },
                        [
                          _c("el-avatar", {
                            staticStyle: { width: "32px", height: "32px" },
                            attrs: { src: _vm.headUrl },
                          }),
                          _c("div", { staticClass: "user-name" }, [
                            _vm._v(_vm._s(_vm.rejectDetail.createName)),
                          ]),
                          _c("div", { staticClass: "publish-time" }, [
                            _vm._v(_vm._s(_vm.rejectDetail.createTime)),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "detail-content" }, [
                        _vm._v(
                          " " + _vm._s(_vm.rejectDetail.resonRemark) + " "
                        ),
                      ]),
                    ]),
                _c("div", { staticClass: "bottom" }, [
                  _vm.articleDetail.articleStatus == 1
                    ? _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { size: "medium", type: "danger" },
                              on: { click: _vm.handlerReject },
                            },
                            [_vm._v("驳回")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                "visible-arrow": false,
                                width: "350px",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "popover-content" },
                                [
                                  _vm._l(
                                    _vm.$globalData.STARLIST,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "grade-text",
                                          class:
                                            item.star == _vm.articleDetail.stars
                                              ? "active-star"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rateChange(item.star)
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }
                                  ),
                                  _vm._v(" | "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "margin-left": "13px",
                                        color: "#ff5959",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rateChange("0")
                                        },
                                      },
                                    },
                                    [_vm._v("清空 ")]
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.articleDetail.stars == 0,
                                      expression: "articleDetail.stars == 0",
                                    },
                                  ],
                                  staticClass: "btn",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    slot: "reference",
                                    size: "medium",
                                    type: "primary",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("质量评级")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.articleDetail.stars !== 0,
                                  expression: "articleDetail.stars !== 0",
                                },
                              ],
                              staticClass: "rate",
                              staticStyle: { "margin-left": "20px" },
                            },
                            [
                              _vm._v(" 质量评级："),
                              _c("span", { staticClass: "rate-text" }, [
                                _vm._v(_vm._s(_vm.articleDetail.grade)),
                              ]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    "visible-arrow": false,
                                    width: "350px",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "popover-content" },
                                    [
                                      _vm._l(
                                        _vm.$globalData.STARLIST,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticClass: "grade-text",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rateChange(
                                                    item.star
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" | "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "13px",
                                            color: "#ff5959",
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rateChange("0")
                                            },
                                          },
                                        },
                                        [_vm._v("清空 ")]
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("i", {
                                    staticClass: "icon-bianji1",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "rate" },
                          [
                            _vm._v(" 质量评级："),
                            _c("span", { staticClass: "rate-text" }, [
                              _vm._v(_vm._s(_vm.articleDetail.grade)),
                            ]),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top",
                                  "visible-arrow": false,
                                  width: "350px",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "popover-content" },
                                  [
                                    _vm._l(
                                      _vm.$globalData.STARLIST,
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "grade-text",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rateChange(item.star)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" | "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "margin-left": "13px",
                                          color: "#ff5959",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rateChange("0")
                                          },
                                        },
                                      },
                                      [_vm._v("清空 ")]
                                    ),
                                  ],
                                  2
                                ),
                                _c("i", {
                                  staticClass: "icon-bianji1",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button",
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.flipOver("pre")
                            },
                          },
                        },
                        [_vm._v("上一条")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button",
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.flipOver("next")
                            },
                          },
                        },
                        [_vm._v("下一条")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "hideDiv" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }