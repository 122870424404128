<template>
  <div class="detailHeader flex-CB flex-column">
    <div class="layout-top">
      <div class="h-50"></div>
      <div class="top-title flex-center ">
        <div class="title textOverOneLine " @click="toUrl">
          {{ taskDetial.keyword }}
        </div> <span class="jifen-btn" @click="showScoreRules">计分规则</span>
      </div>
      <div class="h-44"></div>
      <div class="top-data flex-CB">
        <div class="top-left w324 flex-CA">
          <div class="circular-w166 flex-center flex-column-JC">
            <div class="redQiu"></div>
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ aTeamHot }}</p><span>%</span>
              </div>
              <p class="label">热评占比</p>
            </div>
          </div>
          <div class="circular-w166 flex-center flex-column-JC">
            <div class="redQiu"></div>
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ aTeamAll }}</p><span>%</span>
              </div>
              <p class="label">评论数占比</p>
            </div>
          </div>
        </div>
        <div class="top-mid w352 flex-CB">
          <div class="red-block bf-w180 flex-center flex-column-JC">
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ aTeam.score | fixdeOne }}</p>
              </div>
              <p class="label">红方</p>
            </div>
          </div>
          <div class="vs flex-center">
            <div class="count-down flex-Acenter">
              <p>{{ remainingTime.mm }}</p>
              <span>:</span>
              <p>{{ remainingTime.ss }}</p>
              <span>:</span>
              <p>{{ remainingTime.hm }}</p>
            </div>
          </div>
          <div class="blue-block bf-w180 flex-center flex-column-JC">
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ bTeam.score | fixdeOne }}</p>
              </div>
              <p class="label">蓝方</p>
            </div>
          </div>
        </div>
        <div class="top-right w324 flex-CA">
          <div class="circular-w166 flex-center flex-column-JC">
            <div class="redQiu blueQiu"></div>
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ bTeamAll }}</p><span>%</span>
              </div>
              <p class="label">评论数占比</p>
            </div>
          </div>
          <div class="circular-w166 flex-center flex-column-JC">
            <div class="redQiu blueQiu"></div>
            <div class="num-data flex-center flex-column-JC">
              <div class="flex-EC label-value">
                <p>{{ bTeamHot }}</p><span>%</span>
              </div>
              <p class="label">热评占比</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-mid">
      <router-view></router-view>
    </div>
    <div class="layout-bottom">
      <div class="tab-btn" :class="{ 'active': activeNum == 2 }">
        <a href="javascript:;" @click="goPage(1)"></a>
        <a href="javascript:;" @click="goPage(2)"></a>
      </div>
    </div>
    <not-started ref="notStarted" @refresh="getDetailTask" :startTime="startTime" :curSysDateNow="curSysDate"
      :taskDetial="taskDetial" :distanceTimeP="distanceTime"></not-started>
    <score-rules ref="scoreRules"></score-rules>
    <result-view ref="resultView" :taskId="taskId" @handleClose="handleClose" :endTime="endTime" :curSysDate="curSysDate"
      :isPlayback="isPlayback"></result-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotStarted from '@/views/drill/components/notStarted.vue';
import ScoreRules from '@/views/drill/components/scoreRules.vue';
import ResultView from '@/views/drill/components/resultView.vue';
import * as  drillApi from '@/api/drill.js'
export default {
  name: 'detailHeader',
  components: { NotStarted, ScoreRules, ResultView },
  data () {
    return {
      activeNum: 1,
      taskId: '',
      taskDetial: {},
      taskStatus: 0,
      curSysDate: 0,// 系统时间
      startTime: 0,// 开始时间
      duration: 0, // 持续时间
      endTime: 0,// 结束时间
      remainingTime: {
        mm: '00',
        ss: '00',
        hm: '00'
      },
      timerTwo: null,
      timerThree: null,
      timerOne: null,
      timerFour: null,
      getTaskTimer: null,
      aTeam: { score: 0 },
      bTeam: { score: 0 },
      aTeamHot: 0,
      bTeamHot: 0,
      aTeamAll: 0,
      bTeamAll: 0,
      isPlayback: false, // 是否回放
      frequency: 1,
      childeRouters: ['drillDetail', 'bulletChat'],
      distanceTime: {
        hh: '00',
        mm: '00',
        ss: '00',
        hm: '00'
      },
      onceReslut: 1,  // 结果弹窗只弹一次
      isDelet: false
    };
  },
  props: {},
  created () {
    if (this.$route.query.id) {
      this.taskId = this.$decrypt(this.$route.query.id)
      // 判断是不是演练回放
      if (this.$route.meta.flag && this.$route.meta.flag == 'playback') {
        this.isPlayback = true
        // 设置底下的路由
        this.childeRouters = [
          'drillDetailPlayback',
          'bulletChatPlayback'
        ]
      }
      if (!this.isDelet) {
        this.getDetailTask()
      }
    } else {
      return this.goBack()
    }
    if (this.$route.name == 'bulletChat' || this.$route.name == 'bulletChatPlayback') {
      this.activeNum = 2
    }
    window.addEventListener('visibilitychange', this.visibilityState);
    if (this.isPlayback) return false
    this.initNormal()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['roleId'])
  },
  filters: {
    fixdeOne: function (val) {
      return val.toFixed(1);
    }
  },
  methods: {
    // 演练 正常的监听
    initNormal () {
      let that = this
      // 窗口切换后 显示后 重新获取 执行
      window.addEventListener('resize', () => {
        if (!this.isDelet) {
          that.getDetailTask();
        }
      });
      this.clearIntervalTwo()
      this.timerTwo = setInterval(() => {
        if (!this.isDelet) {
          this.getDetailTask()
        }
      }, 120000)
    },
    // 切换子路由
    goPage (num) {
      if (this.activeNum == num) return
      this.activeNum = num
      this.$router.push({
        name: this.childeRouters[num - 1], query: {
          id: this.$encrypt(this.taskId),
        }
      })
    },
    // 结果页关闭后要到投稿演练结果页
    handleClose () {
      // this.goPage(1)
    },
    // 展示规则
    showScoreRules () {
      this.$refs.scoreRules.show()
    },
    // 获取详情
    async getDetailTask () {
      if (this.isDelet) return
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      let timer = null
      if (res.returnCode == 80012) {
        this.isDelet = true
        this.clearIntervalTwo()
        timer = setTimeout(() => {
          this.goBack()

        }, 30000)
        // 任务被删除
        let that = this
        return this.$wpConfirm('提示', {
          tips: "演练已被删除",
          content: '',
          yesBtnText: '确定',
          isShowCancelBtn: false, //是否显示取消按钮
        }).then(() => {
          clearTimeout(timer)
          that.goBack()
        }).catch(() => {
          clearTimeout(timer)
          that.goBack()
        });

      }
      if (res.returnCode != 200) return
      this.taskDetial = res.returnData.taskDetial
      this.taskStatus = this.taskDetial.taskStatus
      // 服务器的时间 毫秒
      this.curSysDate = new Date(this.$utils.resetTime(this.taskDetial.curSysDate)).getTime()
      // 开始时间 毫秒 
      this.startTime = new Date(this.$utils.resetTime(this.taskDetial.startTime)).getTime()
      // 演练持续时间 毫秒
      this.duration = this.taskDetial.duration * 60 * 1000
      // 演练结束时间　毫秒
      this.endTime = this.startTime + this.duration
      if (this.isPlayback) return this.initPlayback()
      //任务状态0未开始1进行中2已结束
      this.$store.dispatch('drill/SetTaskStatus', this.taskStatus)
      if (this.taskStatus == 0) {
        // 未开始
        this.notStart()
        // 开始倒计时
      } else if (this.taskStatus == 1) {
        this.setProgress()
      } else if (this.taskStatus == 2) {
        // 结果弹窗
        this.setEnd()
      }
    },
    // 点击标题跳转
    toUrl () {
      if (!this.taskDetial.url) return
      window.open(this.taskDetial.url, '_blank')
    },
    // 未开始
    notStart () {
      // 显示演练时长
      this.setRemainingTime(this.duration);
      // 未开始倒计时 大于 1000 显示倒计时弹窗
      if (this.endTime - this.curSysDate <= 1000) {
        this.getDetailTask()
      } else {
        this.$refs.notStarted?.show()
      }

      this.clearIntervalOne()
      let that = this
      that.timerOne = setInterval(() => {
        that.countDown();
      }, 50);

    },
    // 正在进行时 
    setProgress () {
      // 隐藏 未开始弹窗
      this.clearIntervalOne()
      this.$refs.notStarted?.handleClose()
      let that = this
      // 开始倒计时
      if (that.timerThree) { this.clearIntervalThree() }
      that.timerThree = setInterval(() => {
        that.countDownRemainingTime();
      }, 50);
      // 获取比分
      that.getScore();
      if (that.timerFour) { this.clearIntervalFour() }
      that.timerFour = setInterval(() => {
        that.getScore();
      }, 10000);
    },
    // 结束时
    setEnd () {
      this.clearIntervalOne()
      this.clearIntervalThree()
      this.clearIntervalFour()

      this.remainingTime = {
        mm: '00',
        ss: '00',
        hm: '00'
      }
      this.getScore()
      // 获取最新的数据 显示结果弹窗
      if (this.onceReslut == 1) {
        this.onceReslut++
        this.$refs.resultView?.show()
      }

    },
    countDownRemainingTime () {
      //  开始的时间 + 持续的时间的等于  结束时间
      //  结束时间 - 服务器的时间 等于  = 剩余的毫秒数
      let diff
      if (this.isPlayback) {
        diff = this.duration
      } else {
        diff = this.endTime - this.curSysDate; //开始时间减去当前时间 时间差的毫秒数
      }
      // 开始时间 距离 现在时间的 时间戳 diff
      if (diff <= 1000) {
        this.remainingTime = {
          mm: '00',
          ss: '00',
          hm: '00'
        }
        if (this.isPlayback) {
          this.clearIntervalThree()
          return this.getTaskHistory('end');
        }
        return this.getDetailTask()
      } else {
        this.setRemainingTime(diff);
        if (this.isPlayback) {
          this.duration -= 50
        } else {
          this.curSysDate += 50
        }
      }
    },
    //计算屏幕中的数值 date 单位为毫秒
    setRemainingTime: function (date) {
      let c = date;
      let m = parseInt(c / 1000 / 60); //总毫秒 得到分钟数
      let s = parseInt(c / 1000 - 60 * m); //得到最后剩下的毫秒数除以1000 就是秒数，再剩下的毫秒自动忽略即可
      let hm = Math.floor((c - 60 * 1000 * m - s * 1000) / 10); // 毫秒数
      this.remainingTime.mm = m < 0 ? '00' : m.toString().padStart(2, '0');
      this.remainingTime.ss = s < 0 ? '00' : s.toString().padStart(2, '0');
      this.remainingTime.hm = hm < 0 ? '00' : hm.toString().padStart(2, '0');
    },
    clearIntervalOne () {
      clearInterval(this.timerOne)
      this.timerOne = null
    },
    clearIntervalTwo () {
      clearInterval(this.timerTwo)
      this.timerTwo = null
    },
    clearIntervalThree () {
      clearInterval(this.timerThree)
      this.timerThree = null
    },
    clearIntervalFour () {
      clearInterval(this.timerFour)
      this.timerFour = null
    },
    //获取比分统计
    async getScore () {
      const res = await drillApi.taskDetail({ taskId: this.taskId });
      if (res.returnCode != 200) return;
      this.aTeam = res.returnData.aTeam;
      this.bTeam = res.returnData.bTeam;
      //GetPercent
      // 热评占比: 红方的热评数量占（红方+蓝方的）总热评数量
      let hotCount = Number(res.returnData.aTeam.hotComments) + Number(res.returnData.bTeam.hotComments);
      this.aTeamHot = this.$utils.getPercent(res.returnData.aTeam.hotComments, hotCount);
      this.bTeamHot = this.$utils.getPercent(res.returnData.bTeam.hotComments, hotCount);
      // 评论总数：红方的评论数 占（红方+蓝方的）评论数数量
      let allCount = Number(res.returnData.aTeam.comments) + Number(res.returnData.bTeam.comments);
      this.aTeamAll = this.$utils.getPercent(res.returnData.aTeam.comments, allCount);
      this.bTeamAll = this.$utils.getPercent(res.returnData.bTeam.comments, allCount);
    },
    countDown () {
      //currentDate // 当前时间 毫秒
      let diff = this.startTime - this.curSysDate; //开始时间减去当前时间 时间差的毫秒数
      // 开始时间 距离 现在时间的 时间戳 diff
      if (diff <= 1000) {
        this.distanceTime = {
          hh: '00',
          mm: '00',
          ss: '00',
          hm: '00'
        }
        return this.getDetailTask()
      }
      this.distanceTime = this.countDistanceTime(diff);
      this.curSysDate += 50
    },
    //计算倒计时
    countDistanceTime: function (date) {
      let timeObj = {
        hh: '00',
        mm: '00',
        ss: '00',
        hm: '00'
      };
      // //计算出小时数
      var hours = Math.floor(date / (3600 * 1000));
      // //计算剩下的分钟数
      var leave1 = date % (3600 * 1000);
      var minutes = Math.floor(leave1 / (60 * 1000));
      // //计算相差秒数
      var leave2 = leave1 % (60 * 1000);
      var seconds = Math.round(leave2 / 1000);
      timeObj = {
        hh: hours < 0 ? '00' : hours.toString().padStart(2, '0'),
        mm: minutes < 0 ? '00' : minutes.toString().padStart(2, '0'),
        ss: seconds < 0 ? '00' : seconds.toString().padStart(2, '0'),
        hm: '00'
      };
      return timeObj;
    },
    // 演练回放的处理逻辑
    initPlayback () {
      // 开始倒计时
      let that = this
      if (that.timerThree) { this.clearIntervalThree() }
      that.timerThree = setInterval(() => {
        that.countDownRemainingTime();
      }, 50);
      this.getTaskHistory()
      // 循环获取 历史数据
      this.setIntervalgetTaskHistory()

      window.addEventListener('resize', () => {
        if (!this.isDelet) {
          that.getDeletion();
        }
      });
      this.clearIntervalTwo()
      this.timerTwo = setInterval(() => {
        if (!this.isDelet) {
          this.getDeletion()
        }
      }, 120000)
    },
    // 获取历史数据
    getTaskHistory (end) {
      let query = {
        id: this.taskId,
        //speed: this.frequency, // 不传speed 取最新的数据 end的时候
      };
      query.id = this.taskId
      if (!end || end != 'end') {
        query.speed = this.frequency
      } else if (end == 'end') {
        this.clearIntervalFive()
      }
      let that = this
      this.$store.dispatch('drill/TaskHistory', query).then(res => {
        if (res.returnCode != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        if (end == 'end') {
          this.clearIntervalOne()
          // this.clearIntervalTwo()
          this.clearIntervalFive()
          this.clearIntervalThree()
          this.clearIntervalFour()
          // 弹出结果弹窗
          this.$refs.resultView?.show()
        }
        if (JSON.stringify(res.returnData) == '{}' || !res.returnData) {
          return;
        }
        if (JSON.stringify(res.returnData.detail) !== '{}' && res.returnData.detail) {
          // 设置 比分 
          let detail = res.returnData.detail
          this.aTeam = detail.aTeam;
          this.bTeam = detail.bTeam;
          // 设置 热评比例
          //GetPercent
          // 热评占比: 红方的热评数量占（红方+蓝方的）总热评数量
          let hotCount = Number(detail.aTeam.hotComments) + Number(detail.bTeam.hotComments);
          this.aTeamHot = this.$utils.getPercent(detail.aTeam.hotComments, hotCount);
          this.bTeamHot = this.$utils.getPercent(detail.bTeam.hotComments, hotCount);
          // 评论总数：红方的评论数 占（红方+蓝方的）评论数数量
          let allCount = Number(detail.aTeam.comments) + Number(detail.bTeam.comments);
          this.aTeamAll = this.$utils.getPercent(detail.aTeam.comments, allCount);
          this.bTeamAll = this.$utils.getPercent(detail.bTeam.comments, allCount);
        }
      })
    },
    // 轮询历史记录
    setIntervalgetTaskHistory: function () {
      let that = this;
      this.clearIntervalFive()
      this.getTaskTimer = setInterval(() => {
        that.getTaskHistory();
        that.frequency++;
      }, 10000);
    },
    clearIntervalFive () {
      clearInterval(this.getTaskTimer);
      this.getTaskTimer = null;
    },
    goBack () {
      if (this.roleId == 2) {
        this.$router.push({ name: 'drillList' })
      } else if (this.roleId == 3 || this.roleId == 4) {
        this.$router.push({ name: 'wpydrillList' })
      }
    },
    // 回放状态获取 删除状态 2分钟一次
    async getDeletion () {
      if (this.isDelet) return
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      let timer = null
      if (res.returnCode == 80012) {
        this.isDelet = true
        this.clearIntervalTwo()
        timer = setTimeout(() => {
          this.goBack()
        }, 30000)
        // 任务被删除
        let that = this
        return this.$wpConfirm('提示', {
          tips: "演练已被删除",
          content: '',
          yesBtnText: '确定',
          isShowCancelBtn: false, //是否显示取消按钮
        }).then(() => {
          clearTimeout(timer)
          that.goBack()
        }).catch(() => {
          clearTimeout(timer)
          that.goBack()
        });

      }
    },
    visibilityState () {
      let that = this
      this.$store.dispatch('drill/DocumentVisible')
      if (this.isPlayback) {
        if (document.visibilityState === 'visible' && !this.isDelet) {
          that.getDeletion();
        }
      } else {
        if (document.visibilityState === 'visible') {
          that.getDetailTask()
        }
      }
    }
  },
  beforeDestroy () {
    this.clearIntervalTwo()
    this.clearIntervalThree()
    this.clearIntervalFour()
    this.clearIntervalFive()
    window.removeEventListener('visibilitychange', this.visibilityState)
  },
  watch: {
  }
};
</script>
<style scoped lang="scss">
@import "../css/detailHeader.scss";
</style>
