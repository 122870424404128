<template>
  <div class="userManagList">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
            <el-breadcrumb-item :to="{ name: 'unitManagList' }">单位管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理（{{ custName }}）</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <SearchCard title="用户管理" @handlerSearch="handlerSearch" class="title-card">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker v-model="searchQuery.datetimeValue" prefix-icon="" value-format="yyyy-MM-dd" clearable type="daterange" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" @change="changeDaterange">
              </el-date-picker>
            </div>
            <el-input class="search-w190 wp-input" v-model.trim="searchQuery.userName" clearable placeholder="请输入人员姓名">
            </el-input>
            <el-input class="search-w190 wp-input" v-model.trim="searchQuery.mobile" clearable placeholder="请输入手机号码" @input="resetNumMobile">
            </el-input>
          </template>
        </SearchCard>
        <div class="layout-block wp-moudle-style layout-block-bottom">
          <div class="action-row">
            <div class="count">
              用户数量： <span>{{ total }}</span>
            </div>
            <div>
              <el-button class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium" @click="createUser">创建人员
              </el-button>
            </div>
          </div>
          <div class="table-box">
            <!-- 包括“序号、人员姓名、登录账号、手机号码、创建时间、启用状态、操作。”   -->
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="70" align="center">
                </el-table-column>
                <el-table-column min-width="50" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" label="人员姓名" min-width="150" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="loginName" label="登录账号" min-width="150" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号码" min-width="150" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="150" align="center">
                </el-table-column>

                <el-table-column label="启用状态" min-width="100" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div class="enableState enableState1" v-if="scope.row.status == 1">
                      <span class="dot"></span> 启用
                    </div>
                    <div class="enableState enableState2" v-else>
                      <span class="dot"></span>禁用
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="30" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <div class="flex-center">
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big" @click="toEidtUser(scope.row)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="重置密码" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                        <el-button type="text" icon="icon-chongzhimima" class="wp-btn-icon-big" @click="resetPassword(scope.row)">
                        </el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div class="pagination-row">
              <wp-pagination :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
              </wp-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchCard from '@/views/components/searchCard'
import { sysUserList, resetPassAdmin } from '@/api/systemSettings.js';
import { resetNum } from '@/utils/validate.js';
export default {
  name: 'unitManagList',
  components: { SearchCard },
  data () {
    return {
      loading: false,
      tableData: [],
      total: 0,
      searchQuery: {
        datetimeValue: null,
        userName: '',
        mobile: ''
      },
      custId: '',// 单位id
      custName: '',// 单位名称
      query: {
        page: 1,
        limit: 30,
        custId: '',
        startTime: '',
        endTime: '',
        userName: '',
        mobile: ''
      }
    };
  },
  props: {},
  created () {
    if (this.$route.query._id && this.$route.query._custName) {
      this.custId = this.$decrypt(this.$route.query._id)
      this.custName = this.$decrypt(this.$route.query._custName)
      this.query.custId = this.$decrypt(this.$route.query._id)
    } else { this.$router.go(-1) }
  },
  mounted () {
    this.refresh()
  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    changeDaterange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      this.getSysUserList()
    },
    // 更新列表
    refresh () {
      this.query.page = 1
      this.getSysUserList()
    },
    // 搜索
    handlerSearch () {
      // console.log(this.searchQuery.datetimeValue)
      let datetimeValue = this.searchQuery.datetimeValue ? this.searchQuery.datetimeValue : []
      this.query = {
        ...this.query,
        ...this.searchQuery,
        startTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: datetimeValue.length > 1 ? this.$moment(datetimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      delete this.query.datetimeValue
      this.refresh()
    },
    //创建人员
    createUser () {
      this.$router.push({ name: 'addUser', query: { _custId: this.$encrypt(this.custId), _custName: this.$encrypt(this.custName) } })
    },
    //去编辑人员
    toEidtUser (item) {
      this.$router.push({ name: 'addUser', query: { _id: this.$encrypt(item.userId), _custId: this.$encrypt(this.custId), _custName: this.$encrypt(this.custName) } })
    },
    // 重置密码
    resetPassword (item) {
      this.$wpConfirm('重置密码', {
        tips: "确定重置密码吗？",
        content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        console.log("确认")
        this.postresetPassAdmin(item)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 获取用户列表
    async getSysUserList () {
      let query = {
        ...this.query
      }
      if (this.loading) return
      this.loading = true
      try {
        let res = await sysUserList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(element => {
          element.createTime = this.$moment(element.createTime).format("YYYY-MM-DD HH:mm")
        });
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    //重置密码
    async postresetPassAdmin (item) {
      let query = {
        userId: item.userId
      }
      try {
        let res = await resetPassAdmin(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 手机号重置 值
    resetNumMobile () {
      this.searchQuery.mobile = resetNum(this.searchQuery.mobile);
    }



  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/userManagList.scss";
</style>
