<template>
  <div class="searchContent">
    <slot name="searchContent"></slot>
    <el-button @click="handlerSearch" class="wp-button wp-btn-icon" type="primary" icon="el-icon-search" size="medium">
      搜索
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    handlerSearch () {
      this.$emit("handlerSearch")
    }
  },
}
</script>

<style lang="scss" scoped>
.searchContent {
  min-height: 40px;
  display: flex;
  align-items: center;
  // margin-top: 25px;
  margin-top: 9px;
  flex-wrap: wrap;
  // column-gap: 12px;
  // row-gap: 12px;

  &>div {
    margin-top: 16px;
    margin-right: 12px;
  }

  &>.wp-button {
    margin-top: 16px;
  }
}
</style>