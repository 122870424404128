<template>
  <div class="allStlye" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
    <!-- 全局scss 颜色 变量 -->
    <div>全局scss 颜色 变量</div>
    <div class="quan">dasdasdasd</div>
    <div>加载的样式</div>
    <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-bianji1" @click="loading = !loading">加载
    </el-button>
    <div>错误的提示</div>
    <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-bianji1" @click="showErr">错误的提示
    </el-button>
    <el-button class="wp-button wp-btn-icon" size="medium" icon="icon-bianji1" @click="showSuc">成功的提示
    </el-button>

    <div>分页</div>
    <wp-pagination :total="100" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>

    <div>日历</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="活动名称" prop="name">
        <div class="wp-date-picker" style="width: 300px">
          <el-date-picker v-model="ruleForm.name" prefix-icon="" value-format="yyyy-MM-dd" clearable type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDaterange">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="活动名称" prop="name3">
        <div class="wp-date-picker">
          <el-input v-model="ruleForm.name3"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="活动名称" prop="name2">
        <div class="wp-date-picker" style="width: 300px">
          <el-date-picker v-model="ruleForm.name2" type="date" prefix-icon="" clearable placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      </el-form-item>
    </el-form>

    <div>弹窗</div>
    <el-button type="primary" @click="dialogVisible = true">大弹窗</el-button>
    <wp-dialog @cancel="cancel" @determine="determine" :dialogVisible="dialogVisible" @handleClose="handleClose">
      <div style="height: 800px">adasdas</div>
    </wp-dialog>
    <el-button type="primary" @click="dialogVisible2 = true">小弹窗</el-button>
    <wp-dialog @cancel="cancel2" @determine="determine2" size="small" :dialogVisible="dialogVisible2" @handleClose="handleClose2">
      <div style="height: 800px">adasdas</div>
    </wp-dialog>
    <div>按钮系列</div>
    <div>中等按钮</div>
    <el-button @click="cancel" class="wp-button" size="medium">取消</el-button>
    <el-button class="wp-button" type="primary" @click="determine" size="medium">下一步
    </el-button>
    <div>大按钮</div>
    <el-button @click="cancel" class="wp-button">取消</el-button>
    <el-button class="wp-button" v-preventReClick type="primary" @click="determine">下一步
    </el-button>
    <div>带图标按钮</div>
    <el-button @click="cancel" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu">导出</el-button>
    <el-button class="wp-button wp-btn-icon" type="primary" @click="determine" icon="icon-bianji1" size="medium">添加人员
    </el-button>
    <div>表格</div>
    <div style="width: 500px; padding: 20px">
      <div class="wp-table">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="date" label="日期" width="200">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="120">
          </el-table-column>
          <el-table-column prop="province" label="省份" width="120">
          </el-table-column>
          <el-table-column prop="city" label="市区" width="120">
          </el-table-column>
          <el-table-column prop="address" label="地址" width="300">
          </el-table-column>
          <el-table-column prop="zip" label="邮编" width="120">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="50">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-end" trigger="click" class="wp-dropdown">
                <el-button type="primary" style="padding: 0">
                  菜
                  <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                </el-button>
                <el-dropdown-menu slot="dropdown" :visibleArrow="false" class="wp-dropdown-menu">
                  <el-dropdown-item icon="icon-bianji1">更多黄金糕</el-dropdown-item>
                  <el-dropdown-item>更多狮子头</el-dropdown-item>
                  <el-dropdown-item>更多螺蛳粉</el-dropdown-item>
                  <el-dropdown-item>更多双皮奶</el-dropdown-item>
                  <el-dropdown-item>更多蚵仔煎</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-popover placement="bottom-end" title="标题" width="200" trigger="click" content="这是一段内容">
							<el-button slot="reference">c</el-button>
						</el-popover> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData.length == 0">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      有边框的
      <div class="wp-table wp-table-border">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="date" label="日期"> </el-table-column>
          <el-table-column prop="name" label="姓名" width="120">
          </el-table-column>
          <el-table-column prop="province" label="省份" width="120">
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData.length == 0">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div>表格暂无数据</div>
    <div style="width: 500px; padding: 20px">
      <div class="wp-table">
        <el-table :data="tableData2" stripe style="width: 100%">
          <el-table-column prop="date" label="日期" width="200">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="120">
          </el-table-column>
          <el-table-column prop="province" label="省份" width="120">
          </el-table-column>
          <el-table-column prop="city" label="市区" width="120">
          </el-table-column>
          <el-table-column prop="address" label="地址" width="300">
          </el-table-column>
          <el-table-column prop="zip" label="邮编" width="120">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="50">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-end" trigger="click" class="wp-dropdown">
                <el-button type="primary" style="padding: 0"> 菜 </el-button>
                <el-dropdown-menu slot="dropdown" :visibleArrow="false" class="wp-dropdown-menu">
                  <el-dropdown-item icon="icon-bianji1">更多黄金糕</el-dropdown-item>
                  <el-dropdown-item>更多狮子头</el-dropdown-item>
                  <el-dropdown-item>更多螺蛳粉</el-dropdown-item>
                  <el-dropdown-item>更多双皮奶</el-dropdown-item>
                  <el-dropdown-item>更多蚵仔煎</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="wp-noData" v-if="tableData2.length == 0">
          <img src="~@/assets/images/noData.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div>确认提示框</div>
    <el-button type="primary" @click="showConfirm">确认提示框</el-button>
    <el-button type="primary" @click="showConfirm2">重置密码</el-button>

    <div>问号提示</div>

    <wp-question-mark content="任务发布时间"></wp-question-mark>

    <div>块样式</div>
    <div class="wp-moudle-style" style="width: 500px; height: 300px"></div>

    <SearchCard title="投稿审核" @handlerSearch="handlerSearch" class="title-card">
      <template v-slot:searchContent>
        <TaskTimeSelect @handlerSelect="handlerSelect"></TaskTimeSelect>

        <el-select class="search-w392 wp-select" v-model="searchParams.taskId" clearable placeholder="请选择任务">
          <el-option v-for="item in taskList" :key="item.id" :label="item.taskName" :value="item.id">
          </el-option>
        </el-select>

        <el-input class="search-w190 wp-input" v-model="searchParams.peopleName" clearable placeholder="请输入人员姓名">
        </el-input>

        <el-select class="search-w190 wp-select" v-model="searchParams.taskId" placeholder="请选择所在分组" clearable>
          <el-option v-for="item in taskList" :key="item.id" :label="item.taskName" :value="item.id">
          </el-option>
        </el-select>

        <el-select class="search-w190 wp-select" v-model="searchParams.taskId" placeholder="请选择质量评级" clearable>
          <el-option v-for="item in taskList" :key="item.id" :label="item.taskName" :value="item.id">
          </el-option>
        </el-select>

        <el-input class="search-w190 wp-input" v-model="searchParams.peopleName" placeholder="请输入转发/评论" clearable>
        </el-input>

        <el-select class="search-w190 wp-select" v-model="searchParams.taskId" placeholder="请选择审核状态" clearable>
          <el-option v-for="item in taskList" :key="item.id" :label="item.taskName" :value="item.id">
          </el-option>
        </el-select>
      </template>
    </SearchCard>

    <div style="margin-top: 20px">图标按钮 提示</div>
    <div>
      <el-tooltip class="item" effect="dark" content="下载" placement="top">
        <el-button type="text" icon="icon-xiazai1" class="wp-btn-icon-big"></el-button>
      </el-tooltip>
    </div>
    <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big">清空所有</el-button>

    <div class="f-14 wp-blue">AsideList</div>
    <AsideList :asideList="asideList" @handlerItem="handlerItem" @handlerAsideItem="handlerAsideItem"></AsideList>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import SearchCard from '@/views/components/searchCard'
import AsideList from '@/views/components/asideList'
export default {
  name: 'panel',
  components: { TaskTimeSelect, SearchCard, AsideList },
  data () {
    return {
      // 查询条件
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 所选任务ID
        peopleName: "", // 人员姓名
        timeType: 1, // 任务时间类型
      },
      // 侧边栏列表
      asideList: [
        { name: "任务分类111111111111111111111111111111111111111111111111", id: 1 },
        { name: "任务分类2", id: 2 },
        { name: "任务分类3", id: 3 },
        { name: "任务分类111111111111111111111111111111111111111111111111", id: 13 },
        { name: "任务分类2", id: 22 },
        { name: "任务分类3", id: 33 },
        { name: "任务分类111111111111111111111111111111111111111111111111", id: 132 },
        { name: "任务分类2", id: 2232 },
      ],
      taskList: [
        { id: 1, taskName: "任务一" },
        { id: 2, taskName: "任务二3" },
        { id: 3, taskName: "任务三4" },
      ],
      dialogVisible: false,
      dialogVisible2: false,
      loading: false,
      tableData2: [],
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-04',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1517 弄',
        zip: 200333
      }, {
        date: '2016-05-01',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1519 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1516 弄',
        zip: 200333
      }],
      ruleForm: {
        name: [],
        name2: '',
        name3: '',
      },
      rules: {
        name: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        name2: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        name3: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ]
      }
    };
  },
  props: {},
  created () {
    // this.$showMessage({
    // 		type: 'error',
    // 		msg: res.returnMsg
    // 	})
    // 	this.$showMessage({
    // 		type: 'success',
    // 		msg: res.returnMsg
    // 	})
  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
  },
  methods: {
    // 点击侧边栏单项数据
    handlerAsideItem (item) {
      this.title = item.name
      console.log(item)
    },

    // 对侧边栏单项数据进行操作
    handlerItem (data) {
      console.log(data)
    },

    handlerSearch () {

    },

    // 时间类型选择回调
    handlerSelect (data) {
      console.log(data)
    },


    handleSizeChange (val) {
      console.log('handleSizeChange')
      console.log(val)
    },
    handleCurrentChange (val) {
      console.log('handleCurrentChange')
      console.log(val)

    },
    cancel () {
      this.dialogVisible = false
    },
    determine () {
      this.dialogVisible = false
    },
    handleClose () {
      this.dialogVisible = false
    },
    cancel2 () {
      this.dialogVisible2 = false
    },
    determine2 () {
      this.dialogVisible2 = false
    },
    handleClose2 () {
      this.dialogVisible2 = false
    },
    showConfirm () {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '登录',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '否',
      }).then(() => {
        //点登录
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    showConfirm2 () {
      this.$wpConfirm('重置密码', {
        tips: "确定重置密码吗？",
        content: "重置后密码为：<span style='color:#F35454;' > <span>abc123456",
        yesBtnText: '登录',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '否',
      }).then(() => {
        //点登录
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    showErr () {
      this.$showMessage({
        type: 'error',
        msg: 'haha'
      })
    },
    showSuc () {
      this.$showMessage({
        type: 'success',
        msg: 'haha'
      })
    },
    changeDaterange (val) {
      console.log(val)
    }

  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.allStlye {
  background-color: #e5e5e5;
}

.quan {
  color: $activeColor;
}
</style>
