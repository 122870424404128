var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-breadcrumb",
        {
          staticClass: "wp-breadcrumb",
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/taskManagement" } } },
            [_vm._v("投稿管理")]
          ),
          _c("el-breadcrumb-item", [_vm._v("追加人员")]),
        ],
        1
      ),
      _c("div", { staticClass: "flex-CB mt-16 wp-w100" }, [
        _c(
          "div",
          { staticClass: "people-list" },
          [
            _c("div", { staticClass: "people-tab" }, [
              _c(
                "div",
                {
                  staticClass: "tab-item",
                  class: _vm.activeTab == 1 ? "active-left-tab" : "",
                  on: {
                    click: function ($event) {
                      _vm.activeTab = 1
                    },
                  },
                },
                [_vm._v(" 人员列表 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "tab-item",
                  class: _vm.activeTab == 2 ? "active-right-tab" : "",
                  on: {
                    click: function ($event) {
                      _vm.activeTab = 2
                    },
                  },
                },
                [_vm._v(" 常用人员 ")]
              ),
            ]),
            _c("el-divider"),
            _c("div", { staticClass: "search-box" }, [
              _c(
                "div",
                { staticClass: "search-input" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "wp-input",
                      attrs: { clearable: "", placeholder: "请输入搜索的内容" },
                      model: {
                        value: _vm.searchContent,
                        callback: function ($$v) {
                          _vm.searchContent = $$v
                        },
                        expression: "searchContent",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "tree" },
              [
                _c("PeopleTtee", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab == 1,
                      expression: "activeTab == 1",
                    },
                  ],
                  ref: "peopleRef",
                  attrs: {
                    isEdit: true,
                    defaultCheckedKeys: _vm.activePeople,
                    defaultExpanded: _vm.defaultExpanded,
                    peopleList: _vm.peopleList,
                    searchContent: _vm.searchContent,
                  },
                  on: {
                    "update:defaultCheckedKeys": function ($event) {
                      _vm.activePeople = $event
                    },
                    "update:default-checked-keys": function ($event) {
                      _vm.activePeople = $event
                    },
                    handleCheck: _vm.handleCheck,
                    addCommonUser: _vm.addCommonUser,
                  },
                }),
                _c("PeopleTtee", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab == 2,
                      expression: "activeTab == 2",
                    },
                  ],
                  ref: "commonRef",
                  attrs: {
                    isEdit: true,
                    defaultExpanded: _vm.defaultExpandedCommon,
                    type: "stock",
                    defaultCheckedKeys: _vm.activeStockPeopleIds,
                    searchContent: _vm.searchContent,
                    peopleList: _vm.commonPeople,
                    nodeKey: "commonId",
                  },
                  on: {
                    "update:defaultCheckedKeys": function ($event) {
                      _vm.activeStockPeopleIds = $event
                    },
                    "update:default-checked-keys": function ($event) {
                      _vm.activeStockPeopleIds = $event
                    },
                    handleCheck: _vm.handleCheck,
                    removeCommonUser: _vm.removeCommonUser,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "select-people" },
          [
            _c("div", { staticClass: "flex-CB fs-14 head" }, [
              _c("div", [
                _vm._v(" 已选择分组或人员（ "),
                _c("span", { staticClass: "wp-blue" }, [
                  _vm._v(_vm._s(_vm.list.length)),
                ]),
                _vm._v(" 人） "),
              ]),
              _c("div", { staticClass: "flex-Acenter" }),
            ]),
            _c("el-scrollbar", { staticClass: "people" }, [
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "list-item" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            tabindex: index + 1000,
                            disabled: !_vm.isShowTooltipWidth,
                            content: item.name,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "textOverOneLine wp-center flex-3",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.visibilityChangeWidth($event)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ]
                      ),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$utils.replacePhone(item.mobile))),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            tabindex: index + 1000,
                            disabled: !_vm.isShowTooltipWidth,
                            content: item.groupName,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "textOverOneLine wp-center flex-3",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.visibilityChangeWidth($event)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.groupName) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            tabindex: index,
                            disabled: !_vm.isShowTooltipWidth,
                            content: item.custName,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "textOverOneLine",
                              staticStyle: { flex: "3" },
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.visibilityChangeWidth($event)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.custName) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "icon" }, [
                        !item.disabled
                          ? _c("i", {
                              staticClass: "el-icon-error",
                              on: {
                                click: function ($event) {
                                  return _vm.tableRemovePeople(item, index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "wp-task-card mt-16 wp-center" },
        [
          _c(
            "el-button",
            { staticClass: "wp-button", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "wp-button",
              staticStyle: { "margin-left": "24px" },
              attrs: { type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定 ")]
          ),
          _c(
            "el-checkbox",
            {
              staticStyle: { "margin-left": "25px" },
              model: {
                value: _vm.sendMsg,
                callback: function ($$v) {
                  _vm.sendMsg = $$v
                },
                expression: "sendMsg",
              },
            },
            [
              _vm._v(" 短信通知（余量："),
              _c("span", { staticClass: "wp-blue" }, [
                _vm._v(_vm._s(_vm.messageNum)),
              ]),
              _vm._v(" ）"),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "16px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }