<template>
  <div id="particles" style="width: 100%; overflow: hidden; height: 100%">
    <!-- <div id="banner-canvas"><canvas width="1920" height="200" style="width: 1920px; height: 200px;"></canvas></div> -->
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
export default {
  name: 'switchLogin',
  components: {},
  data () {
    return {
      animatId: null
    };
  },
  props: {},
  created () {

  },
  mounted () {
    this.initParticles()
  },
  computed: {

  },
  methods: {
    initParticles () {
      let that = this
      var SEPARATION = 100,
        AMOUNTX = 50,
        AMOUNTY = 50;
      var container;
      var camera, scene, renderer;
      var particles, particle, count = 0;
      var mouseX = -660,
        mouseY = -510;
      // var mouseX = -300,
      //   mouseY = -100;
      var windowHalfX = window.innerWidth / 2;
      var windowHalfY = window.innerHeight / 1;
      init();
      animate();

      function init () {
        container = document.createElement("div");
        container.id = "banner-canvas";
        document.getElementById("particles").appendChild(container);
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
        camera.position.z = 1000;
        scene = new THREE.Scene();
        particles = new Array();
        var e = Math.PI * 2;
        var d = new THREE.ParticleCanvasMaterial({
          // color: "#46c37b",
          color: "#0D8EFF",
          program: function (f) {
            f.beginPath();
            f.arc(0, 0, 1, 0, e, true);
            f.fill()
          }
        });
        var a = 0;
        for (var b = 0; b < AMOUNTX; b++) {
          for (var c = 0; c < AMOUNTY; c++) {
            particle = particles[a++] = new THREE.Particle(d);
            particle.position.x = b * SEPARATION - ((AMOUNTX * SEPARATION) / 2);
            particle.position.z = c * SEPARATION - ((AMOUNTY * SEPARATION) / 2);
            scene.add(particle)
          }
        }
        renderer = new THREE.CanvasRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        // renderer.setSize(window.innerWidth, 200);
        container.appendChild(renderer.domElement);
        // document.addEventListener("mousemove", onDocumentMouseMove, false);
        window.addEventListener("resize", onWindowResize, false)
      }

      function onWindowResize () {
        windowHalfX = window.innerWidth / 2;
        windowHalfY = window.innerHeight / 2;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight)
      }

      function onDocumentMouseMove (a) {
        mouseX = a.clientX - windowHalfX;
        mouseY = a.clientY - windowHalfY
      }

      function onDocumentTouchStart (a) {
        if (a.touches.length === 1) {
          a.preventDefault();
          mouseX = a.touches[0].pageX - windowHalfX;
          mouseY = a.touches[0].pageY - windowHalfY
        }
      }

      function onDocumentTouchMove (a) {
        if (a.touches.length === 1) {
          a.preventDefault();
          mouseX = a.touches[0].pageX - windowHalfX;
          mouseY = a.touches[0].pageY - windowHalfY
        }
      }

      function animate () {
        that.animatId = requestAnimationFrame(animate);
        render()
      }

      function render () {
        camera.position.x += (mouseX - camera.position.x) * 0.05;
        camera.position.y += (-mouseY - camera.position.y) * 0.05;
        camera.lookAt(scene.position);
        var a = 0;
        for (var b = 0; b < AMOUNTX; b++) {
          for (var c = 0; c < AMOUNTY; c++) {
            particle = particles[a++];
            particle.position.y = (Math.sin((b + count) * 0.3) * 50) + (Math.sin((c + count) * 0.5) * 50);
            particle.scale.x = particle.scale.y = (Math.sin((b + count) * 0.3) + 1) * 2 + (Math.sin((c + count) * 0.5) +
              1) * 2
          }
        }
        renderer.render(scene, camera);
        count += 0.08
      }
    }
  },
  beforeDestroy () {
    cancelAnimationFrame(this.animatId)
  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
#particles {
  position: fixed;
  left: 0px;
  bottom: -50%;
  pointer-events: none;
}
</style>
