<template>
  <wp-dialog @cancel="cancel" :title="title" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="groupName" label="" :rules="{ required: true, message: placeholder, trigger: 'blur' }">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.groupName" :placeholder="placeholder" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { insert, update, getGroup } from '@/api/personnelTeam.js'
export default {
  name: 'addGroup',
  components: {},
  data () {
    return {
      title: "添加分组",// 添加分组，添加子分组，修改名称
      placeholder: '输入分组名称',//输入分组名称,输入子分组名称
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        groupName: ""
      },
      rules: {
        // name1: [
        //   { required: true, message: this.placeholder, trigger: 'blur' }
        // ]
      },
      groupinfo: {},
      isPosting: false

    };
  },
  props: {
    parentID: {
      type: String,
      default: ''
    },
    currentID: {
      type: String,
      default: ''
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.parentID && this.currentID) {
        this.isEidt = true
        // 修改子分组名称
        this.title = "修改子分组"
        this.placeholder = "输入子分组名称"
      } else if (this.parentID && this.currentID.length == 0) {
        // 添加子分组
        this.title = "添加子分组"
        this.placeholder = "输入子分组名称"
      } else if (this.parentID.length == 0 && this.currentID.length == 0) {
        // 添加分组
        this.title = "添加分组"
        this.placeholder = "输入分组名称"
      } else if (this.parentID.length == 0 && this.currentID) {
        this.isEidt = true
        // 修改分组名称
        this.title = "修改分组"
        this.placeholder = "输入分组名称"
      }
      if (this.isEidt) {
        // 获取名称
        this.getGroup()
      }
    },
    // 创建 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let query = {
        ...this.ruleForm
      }
      let res = null
      if (this.parentID.length > 0) {
        query.parentId = this.parentID
      }
      try {
        if (this.isEidt) {
          query.groupId = this.currentID
          res = await update(query)
        } else {
          res = await insert(query)
        }
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
    // 获取信息
    async getGroup () {
      try {
        let res = await getGroup({ groupId: this.currentID })
        if (Number(res.returnCode) !== 200) return
        this.groupinfo = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },
    setInitValue () {
      this.ruleForm.groupName = this.groupinfo.groupName
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
</style>