var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unitTab" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "wp-tabs",
          attrs: { value: _vm.active },
          on: { "tab-click": _vm.handleClick },
        },
        _vm._l(_vm.tabsList, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.custId, attrs: { name: item.custId } },
            [
              _c(
                "span",
                {
                  class: { "news-before": item.news == 1 },
                  attrs: { slot: "label" },
                  slot: "label",
                },
                [_vm._v(" " + _vm._s(item.label))]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }