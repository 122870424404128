<template>
  <div>
    <SearchContent @handlerSearch="handlerSearch">
      <template v-slot:searchContent>
        <TaskTimeSelect
          :days="30"
          :activeType="searchParams.timeType"
          @timeSelect="timeSelect"
          ref="taskTime"
        >
        </TaskTimeSelect>

        <el-cascader
          ref="cascaderRef"
          class="search-w190 wp-cascader align-top"
          :options="groupArr"
          @change="changeCascader"
          :props="defaultProps"
          collapse-tags
          placeholder="请选择所在分组"
          clearable
        ></el-cascader>

        <wpLoadSelect
          class="search-w392 wp-select align-top"
          v-model="searchParams.taskId"
          :data="taskSelectList"
          :page="taskObj.page"
          dictLabel="title"
          dictValue="taskId"
          dictTime="createTime"
          :hasMore="taskObj.more"
          :request="getData"
          @clearTitle="() => (taskObj.title = '')"
          placeholder="请选择任务"
        ></wpLoadSelect>
      </template>
    </SearchContent>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'

import SearchContent from '@/views/components/searchContent'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { TaskTimeSelect, SearchContent },
  props: {
    groupList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
        modelName: 'group',
      },
      taskSelectList: [], // 任务下拉框列表

      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        groupIds: [], // 分组Ids
        taskId: "", // 任务Id
        timeType: 2
      },

      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupArr: this.groupList, // 分组列表


    }
  },

  watch: {
    groupList: {
      handler (newVal, oldVal) {
        this.groupArr = newVal
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 清空搜索区域
    clearParams () {
      for (const key in this.searchParams) {
        this.searchParams[key] = ""
      }
      this.searchParams.groupIds = []
      // 清空级联选择器选中的节点
      this.$refs.cascaderRef.$refs.panel.clearCheckedNodes()
      // 设置为空可以让节点不高亮显示
      this.$refs.cascaderRef.$refs.panel.activePath = []
      // 清空时间选择器
      this.$refs.taskTime.clearTime()
    },

    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },

    // 获取筛选数据
    getParams () {
      this.searchParams.timeType = 2
      return this.searchParams
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskSelectList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskSelectList = [...this.taskSelectList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    // 搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.$emit("changeSearch", this.searchParams)
    },

    // 选择时间回调
    timeSelect (time) {
      this.searchParams.startTime = time.startTime
      this.searchParams.endTime = time.endTime
      this.searchParams.timeType = time.timeType
    }
  },
}
</script>

<style lang="scss" scoped>
</style>