var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailHeader flex-CB flex-column" },
    [
      _c("div", { staticClass: "layout-top" }, [
        _c("div", { staticClass: "h-50" }),
        _c("div", { staticClass: "top-title flex-center " }, [
          _c(
            "div",
            { staticClass: "title textOverOneLine ", on: { click: _vm.toUrl } },
            [_vm._v(" " + _vm._s(_vm.taskDetial.keyword) + " ")]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "jifen-btn", on: { click: _vm.showScoreRules } },
            [_vm._v("计分规则")]
          ),
        ]),
        _c("div", { staticClass: "h-44" }),
        _c("div", { staticClass: "top-data flex-CB" }, [
          _c("div", { staticClass: "top-left w324 flex-CA" }, [
            _c(
              "div",
              { staticClass: "circular-w166 flex-center flex-column-JC" },
              [
                _c("div", { staticClass: "redQiu" }),
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [_vm._v(_vm._s(_vm.aTeamHot))]),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("热评占比")]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "circular-w166 flex-center flex-column-JC" },
              [
                _c("div", { staticClass: "redQiu" }),
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [_vm._v(_vm._s(_vm.aTeamAll))]),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("评论数占比")]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "top-mid w352 flex-CB" }, [
            _c(
              "div",
              { staticClass: "red-block bf-w180 flex-center flex-column-JC" },
              [
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm._f("fixdeOne")(_vm.aTeam.score))),
                      ]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("红方")]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "vs flex-center" }, [
              _c("div", { staticClass: "count-down flex-Acenter" }, [
                _c("p", [_vm._v(_vm._s(_vm.remainingTime.mm))]),
                _c("span", [_vm._v(":")]),
                _c("p", [_vm._v(_vm._s(_vm.remainingTime.ss))]),
                _c("span", [_vm._v(":")]),
                _c("p", [_vm._v(_vm._s(_vm.remainingTime.hm))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "blue-block bf-w180 flex-center flex-column-JC" },
              [
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm._f("fixdeOne")(_vm.bTeam.score))),
                      ]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("蓝方")]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "top-right w324 flex-CA" }, [
            _c(
              "div",
              { staticClass: "circular-w166 flex-center flex-column-JC" },
              [
                _c("div", { staticClass: "redQiu blueQiu" }),
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [_vm._v(_vm._s(_vm.bTeamAll))]),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("评论数占比")]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "circular-w166 flex-center flex-column-JC" },
              [
                _c("div", { staticClass: "redQiu blueQiu" }),
                _c(
                  "div",
                  { staticClass: "num-data flex-center flex-column-JC" },
                  [
                    _c("div", { staticClass: "flex-EC label-value" }, [
                      _c("p", [_vm._v(_vm._s(_vm.bTeamHot))]),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("p", { staticClass: "label" }, [_vm._v("热评占比")]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "layout-mid" }, [_c("router-view")], 1),
      _c("div", { staticClass: "layout-bottom" }, [
        _c(
          "div",
          { staticClass: "tab-btn", class: { active: _vm.activeNum == 2 } },
          [
            _c("a", {
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.goPage(1)
                },
              },
            }),
            _c("a", {
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.goPage(2)
                },
              },
            }),
          ]
        ),
      ]),
      _c("not-started", {
        ref: "notStarted",
        attrs: {
          startTime: _vm.startTime,
          curSysDateNow: _vm.curSysDate,
          taskDetial: _vm.taskDetial,
          distanceTimeP: _vm.distanceTime,
        },
        on: { refresh: _vm.getDetailTask },
      }),
      _c("score-rules", { ref: "scoreRules" }),
      _c("result-view", {
        ref: "resultView",
        attrs: {
          taskId: _vm.taskId,
          endTime: _vm.endTime,
          curSysDate: _vm.curSysDate,
          isPlayback: _vm.isPlayback,
        },
        on: { handleClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }