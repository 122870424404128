<template>
  <div class="home">
    <div class="title-header">
      <div class="title-header-left">
        <unit-tab :tabsList="tabsList" :activeId="activeName" @handleClick="handleClick" v-show="tabsList.length > 1">
        </unit-tab>
      </div>
      <div class="title-header-right">
        <div class="label">任务发布时间:</div>
        <div class="wp-date-picker" style="width: 312px">
          <el-date-picker v-model="releaseTimeValue" @change="changeDaterange" size="small" prefix-icon="" clearable type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <!-- <div style="margin-left: 10px;">
								<wp-question-mark content="任务发布时间"></wp-question-mark>
							</div> -->
      </div>
    </div>
    <div class="layout-content">
      <el-scrollbar style="height: 100%">
        <div class="home-admin-content">
          <!-- 数据总览 -->

          <div class="layout-mid wp-moudle-style">
            <div class="layout-mid-left">
              <p class="wpy-name">{{ userInfo.userName }}</p>
              <div class="star">
                <el-rate v-model="userInfo.starLevel" disabled :colors="['#FAA548', '#FAA548', '#FAA548']" void-color="#D8D8D8">
                </el-rate>
              </div>
              <div class="tongji">
                <div class="tongji-item">
                  <img src="~@/assets/images/home/renwu.png" alt="" />
                  <p>
                    投稿数量（{{
                      summaryReportTaskObj.taskNum
                        ? summaryReportTaskObj.taskNum
                        : 0
                    }}）
                  </p>
                </div>
                <div class="tongji-item">
                  <img src="~@/assets/images/home/shangbao.png" alt="" />
                  <p>
                    上报数量（{{
                      summaryReport.topicNum ? summaryReport.topicNum : 0
                    }}）
                  </p>
                </div>
                <div class="tongji-item">
                  <img src="~@/assets/images/home/zhihui.png" alt="" />
                  <p>
                    演练数量（{{
                      summaryReport.drillNum ? summaryReport.drillNum : 0
                    }}）
                  </p>
                </div>
              </div>
            </div>
            <div class="layout-mid-right">
              <img src="~@/assets/images/home/ren.png" alt="" />
            </div>
          </div>

          <!-- 数据统计 -->
          <div class="layout-mid2">
            <div class="percent-w33-left">
              <div class="wp-moudle-style mid2-item">
                <div class="num-box">
                  <p>
                    {{
                      summaryReportTaskObj.taskFinshNum
                        ? summaryReportTaskObj.taskFinshNum
                        : 0
                    }}
                  </p>
                  <span>完成数量</span>
                </div>
                <div>
                  <img src="~@/assets/images/home/wanchengshuliang.png" alt="" style="width: 70px" />
                </div>
              </div>
            </div>
            <div class="percent-w33-mid">
              <div class="wp-moudle-style mid2-item">
                <div class="num-box">
                  <p>
                    {{
                      summaryReportTaskObj.ntaskFinshNum
                        ? summaryReportTaskObj.ntaskFinshNum
                        : 0
                    }}
                  </p>
                  <span>未完成数量</span>
                </div>
                <div>
                  <img src="~@/assets/images/home/weiwancheng.png" alt="" style="width: 70px" />
                </div>
              </div>
            </div>
            <div class="percent-w34-right">
              <div class="wp-moudle-style mid2-item">
                <div class="num-box">
                  <p>
                    {{
                      summaryReportTaskObj.completionRate
                        ? summaryReportTaskObj.completionRate
                        : 0
                    }}%
                  </p>
                  <span>完成率</span>
                </div>
                <div>
                  <img src="~@/assets/images/home/wanchenglv.png" alt="" style="width: 70px" />
                </div>
              </div>
            </div>
          </div>

          <!-- 积分情况 任务完成量 -->
          <div class="layout-mid3">
            <div class="percent-w50">
              <div class="wp-moudle-style mid3-item">
                <div class="item-title home-title">积分情况</div>
                <div class="item-content">
                  <div class="jifen-bg">
                    <p>总积分：</p>
                    <span>{{
                      personSummary.totalScore ? personSummary.totalScore : 0
                    }}</span>
                  </div>
                  <div class="jifen-list">
                    <div class="jifen-item jifen-item-after">
                      <p>
                        {{
                          personSummary.weekScore ? personSummary.weekScore : 0
                        }}
                      </p>
                      <span>本周积分</span>
                    </div>
                    <div class="jifen-item jifen-item-after">
                      <p>
                        {{
                          personSummary.monthScore
                            ? personSummary.monthScore
                            : 0
                        }}
                      </p>
                      <span>本月积分</span>
                    </div>
                    <div class="jifen-item">
                      <p>
                        {{
                          personSummary.yearScore ? personSummary.yearScore : 0
                        }}
                      </p>
                      <span>本年积分</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="percent-w50-right">
              <div class="wp-moudle-style mid3-item" v-loading="itemLoading1" element-loading-spinner="el-icon-loading">
                <div class="item-title home-title">任务完成量</div>
                <div class="item-content">
                  <bar-chart ref="barChart" id="barChart" :xAxisData="taskXAxisData" :yAxisData="taskYAxisData" v-if="taskXAxisData.length > 0">
                  </bar-chart>
                  <div class="wp-noData" v-if="taskXAxisData.length == 0 && !itemLoading1">
                    <img src="~@/assets/images/noData.png" alt="" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 最新待办 -->
          <div class="new-task wp-moudle-style">
            <div class="home-title">最新待办</div>
            <div class="wp-table new-task-table" v-loading="newTaskLoading" element-loading-spinner="el-icon-loading">
              <el-table :data="tableData" stripe style="width: 100%" @cell-click="handlerCellClick">
                <el-table-column prop="typeName" label="来源" width="300" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="left" min-width="60"> </el-table-column>
                <el-table-column prop="taskTitle" label="名称" align="left" show-overflow-tooltip class-name="wp-pointer-color" min-width="250">
                </el-table-column>
                <el-table-column align="left" min-width="50"> </el-table-column>
                <!-- <el-table-column prop="province" label="时间" min-width="120" align="left" show-overflow-tooltip>
								</el-table-column> -->
                <el-table-column prop="createTime" label="时间" min-width="150" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="address" label="状态" min-width="150" align="center" show-overflow-tooltip>
                  <!-- 投稿中心、上报中心中未完成的任务（状态包括：未接收、已接收）、投稿演练未开始的任务（状态包括：未开始、进行中）、 -->
                  <template slot-scope="scope">
                    <span style="color: #0064e6" v-if="
                        scope.row.status == 1 &&
                        (scope.row.type == 1 || scope.row.type == 2)
                      ">未接收</span>
                    <span style="color: #faa548" v-if="
                        scope.row.status == 2 &&
                        (scope.row.type == 1 || scope.row.type == 2)
                      ">已接收</span>
                    <span style="color: #0064e6" v-if="scope.row.status == 1 && scope.row.type == 3">未开始</span>
                    <span style="color: #faa548" v-if="scope.row.status == 2 && scope.row.type == 3">进行中</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="wp-noData" v-if="tableData.length == 0 && !newTaskLoading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import BarChart from '@/components/echartsComponents/barChart.vue';
import UnitTab from '@/components/unitTab/unitTab.vue';

import * as homeApi from '@/api/home.js';
import * as personnelTeamApi from '@/api/personnelTeam.js';
export default {
  name: 'panel',
  components: { BarChart, UnitTab },
  data () {
    return {
      activeName: '',
      starVal: 4,
      name1: [],
      tableData: [],
      newTaskLoading: false,// 表格加载
      taskXAxisData: [],
      taskYAxisData: [],
      itemLoading1: false,
      itemLoading2: false,
      itemLoading3: false,
      taskClassID: 1,//1.任务类 2.上报类
      custId: "",// 单位id
      userId: "",// 用户id
      releaseTimeValue: null,
      personSummary: {},//积分情况
      summaryReport: {},//任务、上报、演练统计
      summaryReportTaskObj: {},//任务、上报、演练统计
      userInfo: {}
    };
  },
  props: {},
  created () {

  },
  mounted () {
    // < !--默认本年 -->
    this.releaseTimeValue = [this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'), this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')]
    this.$nextTick(() => {
      this.activeName = this.tabsList[0].id
      this.userId = this.tabsList[0].userId
      this.custId = this.activeName
      this.userInfo = this.tabsList[0]
      // console.log('this.userInfo')
      // console.log(this.userInfo)
      this.init()
    })
  },
  computed: {
    ...mapGetters(['wpyUserList']),
    tabsList () {
      let tabs = this.wpyUserList.map(item => { return { ...item, id: item.sysCust.custId.toString(), custId: item.sysCust.custId, userId: item.userId.toString(), label: item.sysCust.aliasName, news: 0 } })
      return tabs
    },

  },
  methods: {
    handleClick (tab) {
      console.log(tab);
      this.custId = tab
      this.userId = this.tabsList.filter((item) => { return item.id == tab })[0]?.userId
      this.userInfo = this.tabsList.filter((item) => { return item.id == tab })[0]
      this.changeDaterange()
      this.getPersonSummary()
      this.getTaskFinsh()
    },
    // 任务发布时间更改
    changeDaterange () {
      // 获取 新的数据
      this.getNewTask()
      this.getSummaryReportNum()
    },
    // 网评员首页-最新待办
    async getNewTask () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        custId: this.custId,
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      // console.log(query)
      this.newTaskLoading = true
      try {
        let res = await homeApi.newTask(query)
        this.newTaskLoading = false
        if (res.returnCode != 200) return
        const typeList = {
          '1': '投稿中心',
          '2': '上报中心',
          '3': '投稿演练'
        }
        res.returnData.forEach((item) => {
          item.createTime = this.$moment(item.createTime).format("YYYY-MM-DD HH:mm")
          //任务中心、上报中心、投稿演练
          //1任务 2上报 3演练
          item.typeName = typeList[item.type + '']

        })
        this.tableData = res.returnData
        console.log(this.tableData)
      } catch (error) {
        this.newTaskLoading = false
        console.log(error);
      }
    },
    // 获取用户信息-汇总
    async getPersonSummary () {
      console.log('this.userId', this.userId)
      try {
        let res = await personnelTeamApi.personSummary({ userId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.personSummary = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    handlerCellClick (row, column, cell, event) {
      console.log(row, column, cell, event)
      let routeData
      if (column.label == '名称') {
        //  1任务 2上报 3演练
        switch (row.type) {
          case 1:
            this.$router.push({
              path: "/taskCenterDetail",
              query: {
                // taskId: this.$encrypt(row.taskId),
                // taskReceiverId: this.$encrypt(row.receiverId)
                taskId: row.taskId,
                taskReceiverId: row.receiverId
              }
            });
            break;
          case 2:
            this.$router.push({
              path: "/specialDetail",
              query: {
                // topicId: this.$encrypt(row.taskId),
                // topicReceiverId: this.$encrypt(row.receiverId)
                topicId: row.taskId,
                topicReceiverId: row.receiverId
              }
            })
            break;
          case 3:
            routeData = this.$router.resolve({
              path: "/drillDetail",
              query: {
                id: this.$encrypt(row.taskId)
              }
            })
            window.open(routeData.href, '_blank');
            break;

        }
      }
    },
    // 网评员首页-任务、上报、演练统计
    async getSummaryReportNum () {
      // 默认本年 当releaseTimeValue 是空数组时
      let releaseTimeValue = this.releaseTimeValue ? this.releaseTimeValue : []
      let query = {
        custId: this.custId,
        // startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : this.$moment().year(this.$moment().year()).startOf('year').format('YYYY-MM-DD 00:00:00'),
        // endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : this.$moment().year(this.$moment().year()).endOf('year').format('YYYY-MM-DD 23:59:59')
        startTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[0]).format("YYYY-MM-DD 00:00:00") : '',
        endTime: releaseTimeValue.length > 1 ? this.$moment(releaseTimeValue[1]).format("YYYY-MM-DD 23:59:59") : ''
      }
      try {
        let res = await homeApi.summaryReportNum(query)
        if (res.returnCode != 200) return
        this.summaryReport = res.returnData
        this.summaryReportTaskObj = res.returnData.taskObj ? res.returnData.taskObj : {}
      } catch (error) {

        console.log(error);
      }
    },
    //统计-任务分月完成(网评员)
    async getTaskFinsh () {
      let query = {
        custId: this.custId
      }
      this.itemLoading1 = true
      try {
        let res = await homeApi.taskFinsh(query)
        this.itemLoading1 = false
        if (res.returnCode != 200) return
        this.taskXAxisData = res.returnData.map(it => it.name)
        this.taskYAxisData = res.returnData.map(it => it.total)
        this.$nextTick(() => {
          if (this.$refs.barChart) {
            this.$refs.barChart.setData()
          }
        })
      } catch (error) {
        this.itemLoading1 = false
        console.log(error);
      }
    },
    init () {
      this.changeDaterange()
      this.getPersonSummary()
      this.getTaskFinsh()
    }
  },
  beforeDestroy () {

  },
  watch: {
    // tabsList: {
    //   handler (val, oldval) {
    //     if (val) {

    //     }
    //   },
    //   deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/home.scss";
</style>
