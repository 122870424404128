<template>
  <div>
    <SearchContent @handlerSearch="handlerSearch">
      <template v-slot:searchContent>
        <TaskTimeSelect
          :days="30"
          :activeType="searchParams.timeType"
          :taskType="timeType"
          @timeSelect="timeSelect"
          ref="taskTime"
        >
        </TaskTimeSelect>

        <el-input
          class="search-w392 wp-input"
          v-model="searchParams.taskNumber"
          clearable
          placeholder="请输入任务编号"
        ></el-input>

        <wpLoadSelect
          class="search-w392 wp-select align-top"
          v-model="searchParams.taskId"
          :data="taskSelectList"
          :page="taskObj.page"
          dictLabel="title"
          dictValue="taskId"
          dictTime="createTime"
          :hasMore="taskObj.more"
          :request="getData"
          @clearTitle="() => (taskObj.title = '')"
          placeholder="请选择任务"
        ></wpLoadSelect>
        <el-select
        class="search-w392"
        multiple
        v-model="searchParams.taskTypeIds"
        collapse-tags
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in taskClassify"
          :key="item.taskTypeId"
          :label="item.taskTypeName"
          :value="item.taskTypeId"
        ></el-option>
      </el-select>

        <el-select
          class="search-w190 wp-select"
          v-model="searchParams.taskStatus"
          clearable
          placeholder="请选择任务状态"
        >
          <el-option
            v-for="item in $globalData.TOPICSTATUS"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="search-w190 wp-select"
          v-model="searchParams.isUrgent"
          clearable
          placeholder="请选择是否紧急任务"
        >
          <el-option
            v-for="item in urgent"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        
      </template>
    </SearchContent>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'

import SearchContent from '@/views/components/searchContent'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { TaskTimeSelect, SearchContent },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      taskSelectList: [], // 任务下拉框列表

      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        taskId: "", // 任务Id
        taskNumber: "", // 任务编号
        taskStatus: "", // 任务状态
        timeType: 2,
        taskTypeIds:[],
        isUrgent:''
      },
      // 任务列表
      taskList: [],
      // 时间类型
      timeType: [
        {
          name: "任务发布时间",
          id: 2
        }
      ],
      taskClassify:[],// 任务分类数据
      urgent:[
        {label:'紧急任务',id:'1'},
      {label:'非紧急任务',id:'0'}]
    }
  },
  watch: {
    groupList: {
      handler (newVal, oldVal) {
        this.groupArr = newVal
      },
      deep: true,
      immediate: true
    }
  },
  mounted(){
     // 获取任务分类
     this.getTaskClassify()
  },
  methods: {
    // 清空搜索区域
    clearParams () {
      for (const key in this.searchParams) {
        if(key == 'taskTypeIds'){
          this.searchParams[key] = []
        }else{
          this.searchParams[key] = ""
        }
      }
      // 清空时间选择器
      this.$refs.taskTime.clearTime()
    },

    // 获取筛选数据
    getParams () {
      this.searchParams.timeType = 2
      return this.searchParams
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.taskSelectList = []
        this.taskObj = { ...this.taskObj, ...data }
      }

      const res = await taskApi.taskList({ ...this.taskObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 任务列表
        this.taskSelectList = [...this.taskSelectList, ...ret.data].reduce((prev, curr) => {
          obj[curr.taskId] ? '' : obj[curr.taskId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.taskObj.page < ret.totalPageNum) {
          this.taskObj.page++
          this.taskObj.more = true
        } else {
          this.taskObj.more = false
        }
      }
    },

    // 搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.$emit("changeSearch", this.searchParams)
    },

    // 选择时间回调
    timeSelect (time) {
      this.searchParams.startTime = time.startTime
      this.searchParams.endTime = time.endTime
      this.searchParams.timeType = time.timeType
      
    },
    // 任务分类
    async getTaskClassify () {
      const res = await taskApi.taskTypeList({ taskTypeName: "" })
      if (res.returnCode == 200) {
        this.taskClassify = res.returnData || []
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>