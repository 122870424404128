var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/drillList" } } },
                    [_vm._v("投稿演练")]
                  ),
                  _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.isEidt ? "编辑" : "创建") + "演练"),
                  ]),
                ],
                1
              ),
              _c("Steps", {
                staticClass: "mt-16",
                attrs: { active: _vm.active, stepsList: _vm.stepsList },
              }),
              _c("OneStep", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 1,
                    expression: "active == 1",
                  },
                ],
                ref: "oneStep",
                attrs: { taskDetial: _vm.taskDetial, isEidt: _vm.isEidt },
              }),
              _c("TwoStep", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 2 || _vm.active == 3,
                    expression: "active == 2 || active == 3",
                  },
                ],
                ref: "twoStep",
                attrs: {
                  active: _vm.active,
                  selRedUserIds: _vm.selRedUserIds,
                  selBlueUserIds: _vm.selBlueUserIds,
                },
                on: { changeUser: _vm.changeUser },
              }),
              _c("FourStep", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 4,
                    expression: "active == 4",
                  },
                ],
                attrs: { timeCount: _vm.timeCount },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 1,
                      expression: "active == 1",
                    },
                  ],
                  staticClass: "wp-task-card mt-16 wp-center",
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "wp-button", on: { click: _vm.oneCancel } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "wp-button",
                      staticStyle: { "margin-left": "24px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextStep },
                    },
                    [_vm._v("下一步 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 2,
                      expression: "active == 2",
                    },
                  ],
                  staticClass: "wp-task-card mt-16 wp-center",
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "wp-button", on: { click: _vm.twoCancel } },
                    [_vm._v("上一步")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "wp-button",
                      staticStyle: { "margin-left": "24px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextSteptwo },
                    },
                    [_vm._v("下一步 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 3,
                      expression: "active == 3",
                    },
                  ],
                  staticClass: "wp-task-card mt-16 wp-center",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "wp-button",
                      on: { click: _vm.threeCancel },
                    },
                    [_vm._v("上一步")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "preventReClick",
                          rawName: "v-preventReClick",
                          value: 1000,
                          expression: "1000",
                        },
                      ],
                      staticClass: "wp-button",
                      staticStyle: { "margin-left": "24px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextStepThree },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }