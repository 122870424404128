import { post, get } from '@/utils/requestTwo'

export default {
    // 上报统计-人员汇总
    peopleStatistics: (query = {}) => post("/wpTopicArticle/memberStatisticsList", query, false),

    // 上报统计-分组统计
    groupStatisticsList: (query = {}) => post("/wpTopicArticle/groupStatisticsList", query, false),

    // 上报统计-专题统计
    topicStatisticsList: (query = {}) => post("/wpTopicMain/topicStatisticsList", query, false),

    // 上报统计-专题下拉列表
    topicSelectList: (query = {}) => post("/wpTopicMain/topicSelectList", query),

    // 分组树
    groupTree: (query = {}) => post("/sysGroup/listTree", query, false),

    // 上报统计-专题汇总导出
    exportTopicStatisticsExcel: (query = {}) => post("/wpTopicMain/exportTopicStatisticsExcel", query, false),

    // 上报统计-分组统计导出
    groupStatisticsExport: (query = {}) => post("/wpTopicArticle/groupStatisticsExport", query, false),

    // 上报统计-人员汇总导出
    memberStatisticsExport: (query = {}) => post("/wpTopicArticle/memberStatisticsExport", query, false),

}