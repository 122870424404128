var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createUnits" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c("div", { staticClass: "layout-main" }, [
            _c(
              "div",
              [
                _c(
                  "el-breadcrumb",
                  {
                    staticClass: "wp-breadcrumb",
                    attrs: { "separator-class": "el-icon-arrow-right" },
                  },
                  [
                    _c(
                      "el-breadcrumb-item",
                      { attrs: { to: { name: "unitManagList" } } },
                      [_vm._v("单位管理")]
                    ),
                    _c("el-breadcrumb-item", [
                      _vm._v(_vm._s(_vm.isEidt ? "编辑单位" : "创建单位")),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [_vm._v("基础设置")]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm1",
                      staticClass: "ruleform",
                      attrs: {
                        model: _vm.ruleForm1,
                        rules: _vm.rules1,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "系统状态：", prop: "systemType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm1.systemType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm1, "systemType", $$v)
                                },
                                expression: "ruleForm1.systemType",
                              },
                            },
                            _vm._l(
                              _vm.$globalData.systemStateList,
                              function (item) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位名称：", prop: "custName" } },
                        [
                          _c("el-input", {
                            staticClass: "wp-input",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入单位名称",
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.ruleForm1.custName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm1,
                                  "custName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm1.custName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位别称：", prop: "aliasName" } },
                        [
                          _c("el-input", {
                            staticClass: "wp-input",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入单位别称",
                              maxlength: "10",
                            },
                            model: {
                              value: _vm.ruleForm1.aliasName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm1,
                                  "aliasName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm1.aliasName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位前缀：", prop: "prefix" } },
                        [
                          _c("el-input", {
                            staticClass: "wp-input",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入单位前缀",
                              disabled:
                                _vm.isEidt && _vm.custInfo.taskCount > 0,
                              maxlength: "3",
                            },
                            model: {
                              value: _vm.ruleForm1.prefix,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm1,
                                  "prefix",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm1.prefix",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级单位：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "wp-select",
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择上级单位",
                              },
                              model: {
                                value: _vm.ruleForm1.parentId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm1, "parentId", $$v)
                                },
                                expression: "ruleForm1.parentId",
                              },
                            },
                            _vm._l(_vm.tableData, function (item) {
                              return _c("el-option", {
                                key: item.custId,
                                attrs: {
                                  label: item.custName,
                                  value: item.custId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "人数上限：", prop: "maxMember" } },
                        [
                          _c("el-input", {
                            staticClass: "wp-input",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入人数上限",
                              maxlength: "5",
                            },
                            on: { change: _vm.resetNumName6 },
                            model: {
                              value: _vm.ruleForm1.maxMember,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm1,
                                  "maxMember",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm1.maxMember",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "line-row" }, [
                        _c(
                          "div",
                          { staticClass: "line-row-item" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "到期日期：", prop: "endTime" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "wp-date-picker" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        "prefix-icon": "",
                                        clearable: "",
                                        placeholder: "请选择日期",
                                        "picker-options": _vm.pickerOptions,
                                      },
                                      model: {
                                        value: _vm.ruleForm1.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm1,
                                            "endTime",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm1.endTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line-row-item" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "启用状态：", prop: "status" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "wp-select",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择启用状态",
                                    },
                                    model: {
                                      value: _vm.ruleForm1.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm1, "status", $$v)
                                      },
                                      expression: "ruleForm1.status",
                                    },
                                  },
                                  _vm._l(
                                    _vm.$globalData.enableStateList,
                                    function (item) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [_vm._v("高级选项")]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm2",
                      staticClass: "ruleform",
                      attrs: {
                        model: _vm.ruleForm2,
                        rules: _vm.rules2,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "任务站点：", prop: "taskStation" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "wp-select",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "collapse-tags": "",
                                multiple: "",
                                filterable: "",
                                placeholder: "请选择任务站点",
                              },
                              model: {
                                value: _vm.ruleForm2.taskStation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "taskStation", $$v)
                                },
                                expression: "ruleForm2.taskStation",
                              },
                            },
                            _vm._l(_vm.taskStationList, function (item) {
                              return _c("el-option", {
                                key: item.dataValue,
                                attrs: {
                                  label: item.dataKey,
                                  value: item.dataValue,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "功能权限：" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.accessDrill,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "accessDrill", $$v)
                                },
                                expression: "ruleForm2.accessDrill",
                              },
                            },
                            [_vm._v("投稿演练")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.accessIm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "accessIm", $$v)
                                },
                                expression: "ruleForm2.accessIm",
                              },
                            },
                            [_vm._v("即时通讯")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.accessShop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "accessShop", $$v)
                                },
                                expression: "ruleForm2.accessShop",
                              },
                            },
                            [_vm._v("积分兑换")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.accessExcellentReview,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm2,
                                    "accessExcellentReview",
                                    $$v
                                  )
                                },
                                expression: "ruleForm2.accessExcellentReview",
                              },
                            },
                            [_vm._v("优评中心")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.accessReview,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "accessReview", $$v)
                                },
                                expression: "ruleForm2.accessReview",
                              },
                            },
                            [_vm._v("阅评中心")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "line-row" }, [
                        _c(
                          "div",
                          { staticClass: "line-row-item" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "短信渠道：",
                                  prop: "smsChannel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "wp-select",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择短信渠道",
                                    },
                                    model: {
                                      value: _vm.ruleForm2.smsChannel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm2,
                                          "smsChannel",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm2.smsChannel",
                                    },
                                  },
                                  _vm._l(_vm.smsChannelList, function (item) {
                                    return _c("el-option", {
                                      key: item.dataValue,
                                      attrs: {
                                        label: item.dataKey,
                                        value: item.dataValue,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "短信通知：" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.smsState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "smsState", $$v)
                                },
                                expression: "ruleForm2.smsState",
                              },
                            },
                            [_vm._v("默认选中")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "即时通讯：" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.ruleForm2.imContacts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "imContacts", $$v)
                                },
                                expression: "ruleForm2.imContacts",
                              },
                            },
                            [_vm._v("仅对管理员展示联系人")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "值班账号：" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#0064E6",
                              "inactive-color": "#CCCCCC",
                              "active-value": "1",
                              "inactive-value": "0",
                            },
                            model: {
                              value: _vm.ruleForm2.dutyState,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm2, "dutyState", $$v)
                              },
                              expression: "ruleForm2.dutyState",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "星级设置：",
                            required: "",
                            error: _vm.starErrorStirng,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "star-level-form" },
                            _vm._l(_vm.starLevelList, function (item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "star-style" },
                                [
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(_vm._s(item.label) + ":"),
                                  ]),
                                  _c("el-input", {
                                    staticClass: "mini-input wp-input",
                                    attrs: {
                                      placeholder: "",
                                      clearable: false,
                                      maxlength: "3",
                                      disabled: item.minDisable,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resetNumCounts(item)
                                      },
                                    },
                                    model: {
                                      value: item.minValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "minValue", _vm._n($$v))
                                      },
                                      expression: "item.minValue",
                                    },
                                  }),
                                  _c("span", { staticClass: "seprate" }, [
                                    _vm._v("-"),
                                  ]),
                                  _c("el-input", {
                                    staticClass: "mini-input wp-input",
                                    attrs: {
                                      placeholder: "",
                                      clearable: false,
                                      maxlength: "3",
                                      disabled: item.maxDisable,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resetNumCounts(item)
                                      },
                                    },
                                    model: {
                                      value: item.maxValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "maxValue", _vm._n($$v))
                                      },
                                      expression: "item.maxValue",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "layout-block wp-moudle-style btn-block" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "wp-button",
                    attrs: { size: "medium" },
                    on: { click: _vm.returnBack },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" },
                    ],
                    staticClass: "wp-button",
                    attrs: { size: "medium", type: "primary" },
                    on: { click: _vm.validateFrom },
                  },
                  [_vm._v("确定 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }