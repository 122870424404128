var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main flex teamlist" },
    [
      _c("AsideList", {
        ref: "asideList",
        attrs: { asideList: _vm.asideList, loading: _vm.loadingTree },
        on: {
          searchTree: _vm.searchTree,
          handlerItem: _vm.handlerItem,
          handlerAsideItem: _vm.handlerAsideItem,
          addGroup: _vm.addGroup,
          nodeClick: _vm.nodeClick,
        },
      }),
      _c(
        "div",
        {
          staticClass: "ml-16 search-card",
          class: { groupLeader: _vm.roleId == 3 },
        },
        [
          _c(
            "el-scrollbar",
            { staticStyle: { height: "100%" } },
            [
              _c("SearchCard", {
                attrs: { title: _vm.commonName },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("el-input", {
                          staticClass: "search-w190 wp-input",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入人员姓名",
                          },
                          model: {
                            value: _vm.searchQuery.userName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchQuery,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchQuery.userName",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入单位名称",
                          },
                          model: {
                            value: _vm.searchQuery.custName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchQuery,
                                "custName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchQuery.custName",
                          },
                        }),
                        _c("el-cascader", {
                          ref: "cascaderRef",
                          staticClass: "search-w190 wp-cascader align-top",
                          attrs: {
                            options: _vm.groupList,
                            props: _vm.defaultProps,
                            "collapse-tags": "",
                            placeholder: "请选择所在分组",
                            clearable: "",
                          },
                          on: { change: _vm.changeCascader },
                          model: {
                            value: _vm.groupIdsAll,
                            callback: function ($$v) {
                              _vm.groupIdsAll = $$v
                            },
                            expression: "groupIdsAll",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c(
                      "div",
                      { staticClass: "fs-14 flex-Acenter" },
                      [
                        _c("div", { staticStyle: { "margin-right": "30px" } }, [
                          _vm._v(" 常用人数:"),
                          _c("span", { staticClass: "wp-blue" }, [
                            _vm._v(_vm._s(_vm.memberCount)),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(" 组长人数："),
                          _c("span", { staticClass: "wp-blue" }, [
                            _vm._v(_vm._s(_vm.groupCount)),
                          ]),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "wp-button-text wp-btn-icon",
                            staticStyle: { "margin-left": "16px" },
                            attrs: { type: "text", icon: "icon-bianji1" },
                            on: { click: _vm.batchEidt },
                          },
                          [_vm._v(" 批量改组 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "wp-button-text wp-btn-icon",
                            staticStyle: { "margin-left": "16px" },
                            attrs: { type: "text", icon: "icon-yichu" },
                            on: { click: _vm.batchDel },
                          },
                          [_vm._v(" 批量移除 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              attrs: { data: _vm.tableData, stripe: "" },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "40" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  "min-width": "40",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  label: "人员姓名",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "personnel-row",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: scope.row.userName,
                                                    placement: "top",
                                                    disabled:
                                                      !_vm.isShowTooltipWidth,
                                                    "popper-class":
                                                      "wp-visibilityPopper",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "textOverOneLine",
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.visibilityChangeWidth(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.userName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              scope.row.roleId == 3
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        effect: "plain",
                                                        size: "mini",
                                                      },
                                                    },
                                                    [_vm._v("组长")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  294615459
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "groupName",
                                  label: "所在分组",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "commonGroupStr",
                                  label: "常用分组",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "custName",
                                  label: "单位名称",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm.roleId != 3
                                ? _c("el-table-column", {
                                    attrs: {
                                      fixed: "right",
                                      label: "操作",
                                      "min-width": "150",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                        "visible-arrow": false,
                                                        effect: "dark",
                                                        content: "编辑",
                                                        placement: "top",
                                                        enterable: false,
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-bianji1",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.eidtUser(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "移除",
                                                        placement: "top",
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                        "visible-arrow": false,
                                                        enterable: false,
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-yichu",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delUser(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1079985974
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: { total: _vm.total, currentPage: _vm.query.page },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-group", {
        ref: "addGroup",
        attrs: { currentData: _vm.currentData },
        on: { success: _vm.getAsideList },
      }),
      _c("CommonUse", {
        ref: "commonUse",
        attrs: {
          userIds: _vm.userIds,
          isUpdateCommonGroup: true,
          isEidt: true,
        },
        on: { success: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }