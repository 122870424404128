<template>
  <div style="width: 100%">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="wpyUnitTabs.length > 1">
        <div class="title-header-left">
          <UnitTab :tabsList="wpyUnitTabs" :activeId="activeName" @handleClick="handleClick"></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="专题列表" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <el-input class="search-w392 wp-input" v-model="searchParams.title" placeholder="请输入专题名称" clearable></el-input>

            <el-input class="search-w190 wp-input" v-model="searchParams.topicNum" placeholder="请输入专题编号" clearable></el-input>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                专题数量：<span class="wp-blue">{{ total }}</span>
              </div>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column min-width="20">
                <template slot-scope="scope">
                  <div class="product_tip" v-if="scope.row.longItem">
                    <div class="product_tip_bg"></div>
                    <p>长期</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="topicNum" label="专题编号" min-width="90">
              </el-table-column>
              <el-table-column width="50"> </el-table-column>
              <el-table-column min-width="200" align="left" prop="title" label="专题名称" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="wp-pointer hover-title textOverOneLine" @click="goDetail(scope.row)">
                    {{ scope.row.title }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="150" v-if="isWpys" align="left" prop="custName" label="来源单位" show-overflow-tooltip>
              </el-table-column>
              <el-table-column min-width="150" align="center" prop="createTime" label="创建时间">
                <template slot-scope="scope">
                  {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                </template>
              </el-table-column>
              <el-table-column min-width="150" align="center" prop="endTime" label="结束时间">
                <template slot-scope="scope">
                  <!-- {{ $moment(scope.row.endTime).format('YYYY-MM-DD HH:mm') }} -->
                  {{scope.row.longItem == 1
                    ? '不限'
                    : $moment(scope.row.endTime).format('YYYY-MM-DD HH:mm')}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="receiverStatus" label="接收状态" min-width="80">
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.receiverStatus == 1">
                    <div class="noreceive-circle"></div>
                    <div class="noreceive-color">未接收</div>
                  </div>
                  <div class="flex-center" v-else-if="scope.row.receiverStatus == 2">
                    <div class="receive-circle"></div>
                    <div class="receive-color">已接收</div>
                  </div>
                  <div class="flex-center" v-else-if="scope.row.receiverStatus == 3">
                    <div class="disabled-circle"></div>
                    <div class="disabled-color">已完成</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :currentPage="searchParams.page" :pageSize="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template> 

<script>
import wpyReportedApi from '@/api/wpyReported.js'
import { mapGetters, mapState, mapMutations } from 'vuex';

import UnitTab from '../components/unitTab/unitTab.vue';
import RejectCause from '@/views/components/rejectCause'
import SearchCard from '@/views/components/searchCard'
export default {
  components: { SearchCard, UnitTab, RejectCause },
  data () {
    return {
      activeName: '',
      searchParams: {
        title: "", // 专题名称
        topicNum: "", // 专题编号
        page: 1, // 页码
        limit: 30, // 页大小
        custId: "", // 单位Id
      },
      total: 0,
      tableData: [],
      workVisible: false,
      workForm: {
        taskName: '', // 任务名称
        taskTitle: '', // 任务标题
        taskWay: '', // 任务方式
        workUrl: '', // 作品链接
        taskContent: '' // 作品内容
      },
      workFormRules: {
        taskTitle: { required: true, trigger: 'blur', message: '' },
        taskWay: { required: true, trigger: 'blur', message: '' },
        workUrl: { required: true, trigger: 'blur', message: '' },
        taskContent: { required: true, trigger: 'blur', message: '' },
      },
      loading: false
    }
  },

  mounted () {
    this.activeName = this.wpyUnitTabs[0].custId
    this.searchParams.custId = this.activeName
    // 获取专题列表
    this.getTopicList()
  },

  computed: {
    ...mapGetters(['isWpys', 'wpyUnitTabs']),
  },

  methods: {
    /** 专题列表 */
    async getTopicList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyReportedApi.getTopicList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData

          this.total = ret.totalCount
          this.tableData = ret.data?.map(item => {
            if (!item.longItem) {
              // 已完成
              item.receiverStatus = item.isArticle ? 3 : item.receiverStatus
            }
            return item
          })
        }
      } catch (error) {
        this.loading = false
      }
    },

    /** tab点击回调 */
    handleClick (tab) {
      // 恢复初始值
      this.searchParams.topicNum = ""
      this.searchParams.title = ""
      this.searchParams.page = 1
      this.searchParams.limit = 30

      this.searchParams.custId = tab
      // 获取专题列表
      this.getTopicList()
    },

    /** 点击搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getTopicList()
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTopicList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTopicList()
    },

    /** 跳转专题详情 */
    goDetail (item) {
      this.$router.push({
        path: '/specialDetail',
        query: {
          topicId: item.topicId,
          topicReceiverId: item.topicReceiverId
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>