var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "TabsCard",
        {
          attrs: { tabs: _vm.tabs, activeTab: _vm.activeTab },
          on: { handlerTabs: _vm.handlerTabs },
        },
        [
          _c("el-tab-pane", { attrs: { label: "组员统计", name: "people" } }),
          _c(
            "el-tab-pane",
            { attrs: { label: "作品明细", name: "workDetails" } },
            [
              _c("SearchCard", {
                staticClass: "title-wp-card",
                attrs: { title: "" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c("TaskTimeSelect", {
                          ref: "timeRef",
                          attrs: { activeType: _vm.searchParams.timeType },
                          on: { timeSelect: _vm.timeSelect },
                        }),
                        _vm.roleId == _vm.$ENUM.SYSROLE.TEAMADMIN
                          ? _c(
                              "el-select",
                              {
                                staticClass: "search-w190 wp-select",
                                attrs: { placeholder: "请选择任务来源" },
                                on: { change: _vm.changeLocalType },
                                model: {
                                  value: _vm.searchParams.localType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParams, "localType", $$v)
                                  },
                                  expression: "searchParams.localType",
                                },
                              },
                              _vm._l(
                                _vm.$globalData.TASKSOURCE,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _c("wpLoadSelect", {
                          staticClass: "search-w392 wp-select align-top",
                          attrs: {
                            data: _vm.taskList,
                            page: _vm.taskObj.page,
                            dictLabel: "title",
                            dictValue: "taskId",
                            dictTime: "createTime",
                            hasMore: _vm.taskObj.more,
                            request: _vm.getData,
                            placeholder: "请选择任务",
                          },
                          on: {
                            clearTitle: function () {
                              return (_vm.taskObj.title = "")
                            },
                          },
                          model: {
                            value: _vm.searchParams.taskId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "taskId", $$v)
                            },
                            expression: "searchParams.taskId",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "search-w190 wp-input",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入人员姓名",
                          },
                          model: {
                            value: _vm.searchParams.createName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "createName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchParams.createName",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: {
                              placeholder: "请选择审核状态",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchParams.articleStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "articleStatus", $$v)
                              },
                              expression: "searchParams.articleStatus",
                            },
                          },
                          _vm._l(_vm.$globalData.REVIEWSTATUS, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wp-card mt-16" },
        [
          _c("div", { staticClass: "flex-CB" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "export-box" },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "export-popover",
                      attrs: {
                        "popper-class": "export-popover",
                        placement: "bottom",
                        trigger: "click",
                      },
                    },
                    [
                      _c("div", { staticClass: "export-list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "export-item wp-pointer",
                            on: { click: _vm.collectExport },
                          },
                          [_vm._v(" 汇总导出 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "export-item wp-pointer",
                            on: { click: _vm.workExport },
                          },
                          [_vm._v(" 作品导出 ")]
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button",
                          staticStyle: { width: "90px" },
                          attrs: { slot: "reference", size: "medium" },
                          slot: "reference",
                        },
                        [
                          _vm._v(" 导出"),
                          _c("i", {
                            staticClass: "icon-zhankai el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "fs-14 flex" }, [
              _c("div", [
                _vm._v(" 提交总量："),
                _c("span", { staticClass: "wp-blue" }, [
                  _vm._v(_vm._s(_vm.total || 0)),
                ]),
              ]),
              _c("div", { staticClass: "ml-30" }, [
                _vm._v(" 奖励积分："),
                _c("span", { staticClass: "wp-blue" }, [
                  _vm._v(_vm._s(_vm.summariesData.score || 0)),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "wp-table mt-20",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-text": "正在加载",
              },
            },
            [
              _vm.tableData.length !== 0
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: { data: _vm.tableData, stripe: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "index",
                          label: "序号",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { "max-width": "20", "min-width": "5" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "fileJson",
                          "max-width": "150",
                          "min-width": "100",
                          label: "截图/附件",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.goDetail(
                                            scope.row.taskArticleId,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      JSON.parse(scope.row.fileJson).length
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "right-start",
                                                    "visible-arrow": false,
                                                    trigger: "hover",
                                                    "popper-class":
                                                      "img-popper",
                                                    tabindex: 3,
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticClass: "scaled-image",
                                                    attrs: {
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                      fit: "contain",
                                                    },
                                                  }),
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "22px",
                                                      height: "22px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: _vm.$utils.handlePreviewImg(
                                                        scope.row.fileJson
                                                      ),
                                                    },
                                                    slot: "reference",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          962668117
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { width: "20", "min-width": "5" },
                      }),
                      _c("el-table-column", {
                        attrs: { "max-width": "50", "min-width": "20" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "left",
                          prop: "createName",
                          "max-width": "120",
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                          label: "人员姓名",
                        },
                      }),
                      _c("el-table-column", { attrs: { width: "10" } }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "createTime",
                          label: "提交时间",
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$moment(scope.row.createTime)
                                            .format("YYYY-MM-DD HH:mm")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2503685475
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "max-width": "130",
                          "min-width": "100",
                          align: "center",
                          prop: "totalScore",
                          label: "奖励积分",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "articleStatus",
                          label: "审核状态",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    scope.row.articleStatus == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "flex-center" },
                                          [
                                            _c("div", {
                                              staticClass: "success-circle",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "success-color" },
                                              [_vm._v("已通过")]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "flex-center" },
                                          [
                                            _c("div", {
                                              staticClass: "noreceive-circle",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "noreceive-color",
                                              },
                                              [_vm._v("已驳回")]
                                            ),
                                          ]
                                        ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1979728929
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tableData.length == 0 && !_vm.loading
                ? _c("div", { staticClass: "wp-noData" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/noData.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("wp-pagination", {
            staticClass: "mt-21",
            attrs: {
              total: _vm.total,
              currentPage: _vm.searchParams.page,
              pageSize: _vm.searchParams.limit,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }