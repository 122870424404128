<template>
  <div class="echarts-div" :id="id"></div>
</template>

<script>
// import echarts from "echarts";

export default {
  // mixins: [resize],
  props: {
    pieData: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: "chart"
    },
    hoverTitle: {
      type: String,
      default: "任务数量"
    },
    seriesName: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      myChart: null
    };
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  computed: {

  },
  methods: {
    remFontSize: function (rem) {
      var fontSize = document.documentElement.style.fontSize;
      return Math.floor(rem * fontSize.replace('px', ''));
    },
    resizeW () {
      this.myChart.resize()
    },
    setData () {
      if (this.myChart) {
        this.setOption()
      } else {
        this.initChart()
      }
    },
    initChart: function () {
      if (!document.getElementById(this.id)) return;
      this.myChart = this.$echarts.init(document.getElementById(this.id));
      this.setOption();
      window.addEventListener('resize', () => {
        if (this.myChart) {
          this.myChart.resize();
        }
      });
    },
    setOption: function () {
      let that = this
      let colorList = ['#0064E6', '#DFDFDF'];
      this.pieData.forEach((item, index) => {
        item.itemStyle = {
          color: colorList[index]
        }
        if (index == 0) {
          item.label = {
            show: true,
            position: 'center',
            formatter: function (params) {
              return [
                `{b|${(params.percent).toFixed(1)}%}`,
                `{a|${params.name}}`,
              ].join('\n');
            },
            rich: {
              a: {
                color: '#999999',
                lineHeight: 40,
                fontSize: '14px'
              },
              b: {
                fontSize: '28px',
                color: '#333333',
                lineHeight: 32,
                fontFamily: 'Arial-BoldMT, Arial'
              },
            }
          }
        } else if (index == 1) {
          item.emphasis = { disabled: true }
        }
      })
      //总任务是0  完成是0 的 时候 这个 占比是 0%
      if (this.pieData[1].value == 0 && this.pieData[0].value == 0) {
        this.pieData[1].value = 1
      }

      console.log(this.pieData)
      let option = {
        tooltip: {
          show: false,
          trigger: 'item',
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 0px 5px 0px rgba(3,109,255,0.2000)',
          padding: 18,
          textStyle: {
            color: '#333333'
          },
          formatter: function (params) {
            console.log(params)
            return `<div style="background-color:${params.color};display:inline-block;width: 8px;height: 8px;border-radius:50%;margin-right: 4px;vertical-align: middle;"></div><span style="color:#333333;font-size: 16px;font-weight: bold;">${params.name}</span>
            <div style="margin-top: 8px;line-height:20px;"> <div style="display:inline-block;width: 8px;height: 8px;margin-right: 4px;"></div>${that.hoverTitle}: ${params.value} </div ><div style="margin-top: 8px;line-height:20px;"> <div style="display:inline-block;width: 8px;height: 8px;margin-right: 4px;"></div>占比: ${params.percent}%</div > `
          }
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: that.seriesName,
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['50%', '50%'],
            label: {
              show: false,
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
            },
            labelLine: {
              show: false,
              length: 15,
              length2: 20,
              maxSurfaceAngle: 80
            },
            data: this.pieData
          }
        ]
      };
      if (!this.myChart) {
        return;
      }
      this.myChart.setOption(option);
    },
  },
  watch: {

  },
};
</script>

<style scoped>
.chartsClass {
  padding-left: 1.2rem;
}
</style>
