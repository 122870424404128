<template>
  <wp-dialog @cancel="cancel" title="剪切板" @determine="determine" width="680px" height="436px" :dialogVisible="dialogVisible" @handleClose="handleClose" :modalAppendToBody="false" :modal="false">
    <div class="clipboardView">
      <quill-editor v-model="messagecontent" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)" style="height:370px;width:618px;"></quill-editor>
    </div>
  </wp-dialog>
</template>

<script>
import { uploadFile } from '@/api/personnelTeam.js'
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
  name: 'clipboardView',
  components: { quillEditor },
  data () {
    return {
      dialogVisible: false,
      isPosting: false,
      messagecontent: '',
      editorOption: {
        theme: 'snow',
        placeholder: `通过截图功能（比如QQ截图），截取要上传的图片，然后在本剪切板中按Ctrl+V键将所截图片粘贴到剪切板中进行上传`
      },
      imagesBase64Length: 0,
      uploadLength: 0
    };
  },
  props: {
    // 可上传图片最大数量
    imageMaxNum: {
      type: Number,
      default: 10
    },
    // 图片列表
    imageUrl: {
      type: [Array],
      default: () => []
    },
  },
  created () {

  },
  mounted () {
  },
  computed: {
  },
  methods: {
    // 初始化数据
    initDate () {
    },
    cancel () {
      this.messagecontent = null
      this.isPosting = false
      this.handleClose()
    },
    determine () {
      if (this.isPosting) return
      this.isPosting = true
      let _this = this
      if (_this.messagecontent != null && _this.messagecontent != '') {
        let messagecontent = _this.messagecontent.replaceAll('data:image/jpeg;base64,', '.jpeg,');
        messagecontent = messagecontent.replaceAll('data:image/png;base64,', '.png,');
        messagecontent = messagecontent.replaceAll('data:image/gif;base64,', '.gif,');
        messagecontent = messagecontent.replaceAll('data:image/bmp;base64,', '.bmp,');
        this.postImg()
      } else {
        this.isPosting = false
        this.$showMessage({ type: 'warning', msg: '请粘贴图片！' })
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    onEditorBlur () {
      //失去焦点事件
    },
    onEditorFocus () {
      //获得焦点事件
    },
    onEditorChange () {
      //内容改变事件
    },
    postImg () {
      let that = this
      const quillEditor = this.$refs.myQuillEditor.quill; // 获取Quill实例
      const delta = quillEditor.getContents();
      // 提取delta中的图片Base64数据
      const imagesBase64 = this.extractImagesFromDelta(delta);
      if ((imagesBase64.length + this.imageUrl.length) > this.imageMaxNum) {
        this.isPosting = false
        return this.$showMessage({ type: 'warning', msg: `附件限制最多${this.imageMaxNum}个` })
      }
      this.imagesBase64Length = imagesBase64.length
      // 假设你有以下辅助函数
      imagesBase64.forEach(base64 => {
        this.convertAndCompressImage(base64).then(file => {
          // 处理压缩后的file对象，比如上传到服务器
          that.httpRequest(file);
        }).catch(error => {
          console.error('Image conversion or compression failed:', error);
        });
      });
    },
    // 提取Delta中的图片Base64数据
    extractImagesFromDelta (delta) {
      const imagesBase64 = [];
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'object' && op.insert.image) {
          imagesBase64.push(op.insert.image);
        }
      });
      return imagesBase64;
    },

    // 转换Base64为File对象并尝试压缩
    async convertAndCompressImage (base64String) {
      const blob = this.base64ToBlob(base64String);
      const file = new File([blob], `${this.$utils.uuid()}.jpg`, { type: 'image/jpeg' });

      // 这里可以使用额外的库或方法进行实际的图片压缩
      // 例如，使用canvas进行简单压缩
      const compressedBlob = await this.$utils.handlerImageCompres(file);

      return new File([compressedBlob], `${this.$utils.uuid()}.jpg`, { type: 'image/jpeg' });
    },

    // Base64转Blob
    base64ToBlob (base64) {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: 'image/jpeg' });
    },
    async httpRequest (file) {
      let fd = new FormData(); //参数的格式是formData格式的
      fd.append("file", file); //参数
      fd.append("id", '1'); //参数
      try {
        let res = await uploadFile(fd)
        if (res.returnCode != 200) {
          this.isPosting = false
          return this.$showMessage({
            type: 'error',
            msg: `该文件上传异常`
          })
        }
        const ret = res.returnData
        const fileObj = {
          id: ret.fileId,
          name: ret.fileName,
          picId: ret?.picId
        }
        this.uploadLength++
        this.$emit("clipboardChange", [fileObj])
        if (this.imagesBase64Length == this.uploadLength) {
          this.cancel()
        }
      } catch (error) {

      } finally {

      }

    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('', [''])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.clipboardView {
  padding: 30px;
  /deep/.quill-editor {
    .ql-toolbar {
      display: none;
    }
    .ql-container.ql-snow {
      border: 1px solid #dddddd;
    }
  }
}
</style>