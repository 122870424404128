var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex-warp" },
        [
          _vm.imageUrl.length
            ? _c(
                "div",
                { staticClass: "image-list" },
                _vm._l(_vm.imageUrl, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "image-item" },
                    [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.baseURL + item.id, title: item.name },
                      }),
                      !_vm.noDelete
                        ? _c("img", {
                            staticClass: "icon-guanbi",
                            attrs: {
                              src: require("@/assets/images/close.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteImage(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.videoUrl.length
            ? _c(
                "div",
                { staticClass: "image-list" },
                _vm._l(_vm.videoUrl, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "image-item" },
                    [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.baseURL + item.picId,
                          title: item.name,
                        },
                      }),
                      !_vm.noDelete
                        ? _c("img", {
                            staticClass: "icon-guanbi",
                            attrs: {
                              src: require("@/assets/images/close.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteVideo(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "el-upload",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow,
                  expression: "isShow",
                },
              ],
              staticClass: "avatar-uploader",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "http-request": _vm.httpRequest,
                action: _vm.action,
                accept: _vm.accept,
                "show-file-list": false,
                "before-upload": _vm.handleBeforeUpload,
              },
            },
            [
              _c("div", { staticClass: "uploader-btn flex-column-center" }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/bendi.png"), alt: "" },
                }),
                _c("p", [_vm._v("从本地添加")]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.loading &&
                    _vm.videoUrl.length == 0 &&
                    _vm.imageUrl.length < _vm.imageMaxNum,
                  expression:
                    "\n        !loading && videoUrl.length == 0 && imageUrl.length < imageMaxNum\n      ",
                },
              ],
              staticClass: "uploader-btn flex-column-center",
              on: { click: _vm.handlerJqb },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/jqb.png"), alt: "" },
              }),
              _c("p", [_vm._v("从剪贴板添加")]),
            ]
          ),
          _vm.caution
            ? _c(
                "p",
                { staticClass: "fs-14", staticStyle: { color: "#f35454" } },
                [_vm._v(" " + _vm._s(_vm.caution) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "file-placeholder mt-14", class: _vm.className },
        [_vm._v(" " + _vm._s(_vm.remark) + " ")]
      ),
      _c("clipboardView", {
        ref: "clipboardView",
        attrs: { imageUrl: _vm.imageUrl, imageMaxNum: _vm.imageMaxNum },
        on: { clipboardChange: _vm.clipboardChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }