<template>
  <div style="width: 100%">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="wpyUnitTabs.length > 1">
        <div class="title-header-left">
          <UnitTab
            :tabsList="wpyUnitTabs"
            :activeId="activeName"
            @handleClick="handleClick"
          ></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="我的作品" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <TaskTimeSelect
              ref="taskTimeRef"
              @timeSelect="timeSelect"
              :activeType="searchParams.timeType"
              :taskType="timeType"
            ></TaskTimeSelect>

            <el-input
              class="search-w392 wp-input"
              v-model="searchParams.title"
              placeholder="请输入任务标题"
              clearable
            >
            </el-input>

            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.articleStatus"
              clearable
              placeholder="请选择审核状态"
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                提交总量：<span class="wp-blue">{{ total }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{ totalScore || 0 }}</span>
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                min-width="50"
              >
              </el-table-column>
              <el-table-column width="20"> </el-table-column>
              <el-table-column
                align="left"
                prop="taskTitle"
                show-overflow-tooltip
                min-width="150"
                label="任务标题"
              >
              </el-table-column>
              <el-table-column
                v-if="isWpys"
                align="center"
                prop="custName"
                label="来源单位"
                min-width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column align="center" prop="province" label="截图/附件">
                <template slot-scope="scope">
                  <p
                    v-if="JSON.parse(scope.row.fileJson).length"
                    @click.stop="goDetail(scope.row.taskArticleId)"
                    style="cursor: pointer"
                  >
                    <el-popover
                      placement="right-start"
                      :visible-arrow="false"
                      trigger="hover"
                      popper-class="img-popper"
                      :tabindex="3"
                    >
                      <el-image
                        class="scaled-image"
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        fit="contain"
                      ></el-image>
                      <img
                        style="width: 22px; height: 22px"
                        slot="reference"
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      />
                    </el-popover>
                  </p>
                </template>
              </el-table-column>
              <el-table-column width="30"> </el-table-column>
              <el-table-column
                align="left"
                prop="createName"
                label="人员姓名"
                min-width="120"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="提交时间"
                width="150"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.articleStatus == 2">
                    <div class="noreceive-circle"></div>
                    <RejectCause
                      :key="scope.row.taskArticleId"
                      popoverType="detail"
                      :rejectObj="rejectObj"
                    >
                      <div
                        class="reject"
                        @mouseenter="
                          rejectObj = { ...scope.row.wpTaskArticleVerify }
                        "
                      >
                        已驳回
                      </div>
                    </RejectCause>
                  </div>
                  <div v-else class="flex-center">
                    <div class="pass-circle"></div>
                    <div class="pass">已通过</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <el-button
                      type="text"
                      @click="editWork(scope.row)"
                      icon="icon-bianji1"
                      class="wp-btn-icon-big"
                      :class="scope.row.taskStatus == 2 ? 'no-drop' : ''"
                    >
                    </el-button>
                    <el-button
                      style="margin-left: 10px"
                      type="text"
                      @click="removeWork(scope.row)"
                      icon="icon-shanchu"
                      class="wp-btn-icon-big"
                      :class="
                        scope.row.taskStatus == 2 ||
                        scope.row.articleStatus == 2
                          ? 'no-drop'
                          : ''
                      "
                    >
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <task-dialog
      title="编辑"
      width="680px"
      height="100%"
      @cancel="cancel"
      @determine="submitForm"
      :dialogVisible.sync="workVisible"
      @handleClose="cancel"
    >
      <div class="word-dialog">
        <div class="reject-box" v-if="articleData.articleStatus == 2">
          <div class="fs-16 fw-bold">驳回原因：</div>
          <div class="reject-content fs-14">
            {{ verify.resonRemark }}
          </div>
        </div>

        <div class="wp-form">
          <el-form
            class="wp-form"
            :model="workForm"
            :rules="workFormRules"
            ref="workRef"
            label-width="95px"
          >
            <el-form-item
              class="item-content"
              label="任务标题："
              label-width="95px"
              prop="taskName"
            >
              <el-input
                disabled
                class="wp-input w480"
                v-model.trim="workForm.taskName"
                placeholder="请输入任务标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="
                taskData.modeStyleList && taskData.modeStyleList.length !== 1
              "
              class="item-content"
              label="任务方式："
              label-width="95px"
              prop="modeStyle"
            >
              <el-checkbox-group v-model="workForm.modeStyle" :max="1">
                <el-checkbox
                  v-for="item in taskData.modeStyleList"
                  :key="item.dictValue"
                  :label="item.dictValue"
                  >{{ item.dictKey }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <el-form-item
              v-if="setting.parmTitleShow"
              :rules="[
                {
                  required: setting.parmTitleMust,
                  trigger: 'blur',
                  message: '请输入作品标题',
                },
              ]"
              class="item-content"
              label="作品标题："
              prop="title"
            >
              <el-input
                class="wp-input w480"
                maxlength="100"
                v-model.trim="workForm.title"
                placeholder="请输入作品标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmUrlShow"
              :rules="[
                {
                  required: setting.parmUrlMust,
                  trigger: 'blur',
                  message: '请输入作品链接',
                },
              ]"
              class="item-content isUrl"
              label="作品链接："
              prop="url"
            >
              <el-input
                @blur="changeUrl"
                maxlength="2000"
                class="wp-input w480"
                v-model.trim="workForm.url"
                placeholder="请输入作品链接"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="作品内容："
              prop="content"
              v-if="setting.parmContentShow"
              :rules="[
                {
                  required: setting.parmContentMust,
                  trigger: 'blur',
                  message: '请输入作品内容',
                },
              ]"
            >
              <el-input
                class="w480 textarea"
                type="textarea"
                maxlength="1000"
                :rows="6"
                v-model.trim="workForm.content"
                placeholder="请输入任务内容"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmAttachShow"
              :rules="[
                {
                  required: setting.parmAttachMust,
                  trigger: ['blur', 'change'],
                  message: '请上传文件',
                },
              ]"
              label="截图/附件："
              prop="fileJson"
            >
              <!-- <UploadFile
                ref="upload"
                :imageMaxNum="5"
                key="image"
                accept=".jpg, .jpeg, .png, .mp4"
                placeholder="请上传jpg、jpeg、png、mp4格式文件"
                :fileList="workForm.fileJson"
                @changeUpload="changeUpload"
                @fileChange="fileChange"
                :fileType="['jpg', 'jpeg', 'png', 'mp4']"
              ></UploadFile> -->
              <UploadFile
                ref="upload"
                :imageMaxNum="5"
                key="image"
                accept=".jpg, .jpeg, .png, .mp4"
                :fileList="fileJson"
                @changeUpload="changeUpload"
                @fileChange="fileChange"
              ></UploadFile>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </task-dialog>
  </div>
</template> 

<script>
import { validTaskURL } from '@/utils/validate.js';
import wpyTaskApi from '@/api/wpyTask.js'
import { mapGetters, mapState, mapMutations } from 'vuex';
import UnitTab from '../components/unitTab/unitTab.vue';
// import UploadFile from '@/views/components/uploadFile'
import UploadFile from '@/views/components/uploadFileClipboard'
import RejectCause from '@/views/components/rejectCause'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { SearchCard, TaskTimeSelect, UnitTab, RejectCause, UploadFile },
  data () {
    return {
      isUpload: false,
      activeName: '',
      searchParams: {
        page: 1,
        limit: 30,
        timeType: 1, // 任务时间类型
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        articleStatus: "", // 文章状态
        title: "", // 任务标题
        verifyFlag: true,
        timeType: 2,
      },
      loading: false,
      timeType: [
        {
          name: "任务发布时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        }
      ],
      total: 0, // 总条数
      statusList: [],
      tableData: [],
      workVisible: false,
      workForm: {
        taskName: '', // 任务名称
        title: '', // 任务标题
        modeStyle: [], // 任务方式
        url: '', // 作品链接
        content: '', // 作品内容
        fileJson: '', // 附件
      },
      workFormRules: {
        title: { required: true, trigger: 'blur', message: '请输入作品标题' },
        modeStyle: { required: true, trigger: 'blur', message: '请选择任务方式' },
        url: { required: true, trigger: 'blur', message: '请输入作品链接' },
        content: { required: true, trigger: 'blur', message: '请输入作品内容' },
        fileJson: { required: true, trigger: 'blur', message: '请上传文件' },
      },
      taskData: {}, // 任务信息
      articleData: {}, // 作品信息
      setting: {}, // 高级设置
      rejectObj: {}, // 驳回原因
      totalScore: "", // 表格总奖励积分
      verify: {},
      verifyUrl: true, // 链接校验
      isPosting: false,
      fileJson: []
    }
  },
  mounted () {
    this.activeName = this.wpyUnitTabs[0].custId
    this.searchParams.custId = this.activeName
    /** 获取文章列表 */
    this.getArticleList()
    /** 获取统计表格信息 */
    this.getSummaries()
  },
  computed: {
    ...mapGetters(['isWpys', 'wpyUnitTabs']),
  },
  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },

    // 监听链接输入框
    async changeUrl () {
      const index = await this.$refs['workRef'].fields.findIndex(item =>
        item?.$el?._prevClass?.indexOf("isUrl") !== -1
      )

      if (this.workForm.url === '' && this.setting.parmUrlMust) {
        this.verifyUrl = await false
        this.$refs['workRef'].fields[index].validateMessage = await '请输入作品链接'
        this.$refs['workRef'].fields[index].validateState = await 'error'
      } else if (!validTaskURL(this.workForm.url) && this.workForm.url) {
        this.verifyUrl = await false
        this.$refs['workRef'].fields[index].validateMessage = await '请输入有效作品链接'
        this.$refs['workRef'].fields[index].validateState = await 'error'
      } else {
        this.verifyUrl = await true
        this.$refs['workRef'].fields[index].validateState = await 'success'
      }
      console.log(this.verifyUrl);
    },

    /** 获取统计表格信息 */
    async getSummaries () {
      const res = await wpyTaskApi.getSummaries({ ...this.searchParams })
      if (res.returnCode == 200) {
        // 奖励积分
        this.totalScore = res.returnData?.score || 0
      }
    },

    // 详情
    goDetail (articleId) {
      this.$router.push({
        path: 'workDetail',
        query: {
          articleId,
        }
      })
    },

    // /** 获取驳回原因 */
    // async getReject (articleId) {
    //   const res = await wpyTaskApi.getVerify({ articleId })
    //   if (res.returnCode == 200) {
    //     this.rejectObj = res.returnData
    //   }
    // },

    /** 作品列表 */
    async getArticleList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyTaskApi.getArticleList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
        }
      } catch (error) {
        this.loading = false
      }
    },

    /** 初始化搜索条件 */
    initParams () {
      // 置空时间筛选框
      this.$refs.taskTimeRef.clearTime()
      // 恢复初始值
      this.searchParams.title = ""
      this.searchParams.articleStatus = ""
      this.searchParams.startTime = ""
      this.searchParams.endTime = ""

      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.$set(this.searchParams, 'timeType', 2)
    },

    // 切换单位tabs
    handleClick (tab) {
      // 初始化搜索条件
      this.initParams()
      this.searchParams.custId = tab
      // 获取作品列表
      this.getArticleList()
      // 获取统计表格信息
      this.getSummaries()
    },

    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      // 获取作品列表
      this.getArticleList()
      // 获取统计表格信息
      this.getSummaries()
    },

    // 时间类型选择回调
    timeSelect (data) {
      this.searchParams = { ...this.searchParams, ...data }
    },

    /** 关闭编辑作品弹窗 */
    cancel () {
      // 链接校验恢复初始值
      this.verifyUrl = true
      this.isUpload = false
      this.workVisible = false
      // 清除表单信息
      this.$refs.workRef.resetFields()
      this.$refs.upload.resetFile()
    },

    /** 编辑作品 */
    editWork (item) {
      if (item.taskStatus == 1) {
        // 获取作品详情
        this.getWorkDetail(item.taskArticleId)
        this.workVisible = true
      }
    },

    /** 文件上传成功回调 */
    fileChange (fileList) {
      this.workForm.fileJson = fileList
    },

    /** 获取作品详情 */
    async getWorkDetail (articleId) {
      const res = await wpyTaskApi.getTaskArticle({ articleId })
      if (res.returnCode == 200) {
        const ret = res.returnData
        // 作品信息
        this.articleData = ret.wpTaskArticle || {}
        // 任务信息
        this.taskData = ret.wpTaskMain || {}
        console.log(this.taskData);
        // 驳回信息
        this.verify = ret.verify || {}

        this.workForm = { ...this.articleData }

        console.log(this.workForm.modeStyle);
        // 任务方式
        this.workForm.modeStyle = [this.workForm.modeStyle.toString()]
        console.log(this.workForm.modeStyle)
        // 任务名称
        this.workForm.taskName = ret.wpTaskMain.title
        // 附件
        this.workForm.fileJson = JSON.parse(this.articleData.fileJson)
        this.fileJson = JSON.parse(this.articleData.fileJson)

        // 高级设置
        this.setSetting(this.taskData)
      }
    },

    // 设置作品提交的高级设置
    setSetting (task) {
      // 显示标题
      this.setting.parmTitleShow = task.parmTitle.charAt(0) == '1';
      // 标题必填
      this.setting.parmTitleMust = task.parmTitle.charAt(1) == '1';

      // 显示链接
      this.setting.parmUrlShow = task.parmUrl.charAt(0) == '1';
      // 链接必填
      this.setting.parmUrlMust = task.parmUrl.charAt(1) == '1';

      // 显示内容
      this.setting.parmContentShow = task.parmContent.charAt(0) == '1';
      // 内容必填
      this.setting.parmContentMust = task.parmContent.charAt(1) == '1';

      // 显示附件
      this.setting.parmAttachShow = task.parmAttach.charAt(0) == '1';
      // 附件必填
      this.setting.parmAttachMust = task.parmAttach.charAt(1) == '1';
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getArticleList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getArticleList()
    },

    // 提交表单回调
    async submitForm () {
      if (this.verifyUrl) {
        if (this.isUpload) {
          this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
        } else {
          this.$refs.workRef.validate(async valid => {
            if (valid) {
              let formData = JSON.parse(JSON.stringify(this.workForm))

              if (formData?.fileJson?.length) {
                formData.fileJson = JSON.stringify(formData.fileJson)
              }

              // 任务方式
              formData.modeStyle = formData.modeStyle?.join(",")
              // 文章Id
              formData.taskArticleId = this.articleData.taskArticleId
              if (this.isPosting) return
              this.isPosting = true
              const res = await wpyTaskApi.updateTaskArticle(formData)
              this.isPosting = false
              if (res.returnCode == 200) {
                this.workVisible = false
                // 清除表单信息
                this.$refs.workRef.resetFields()
                this.$refs.upload.resetFile()
                this.getArticleList()
                // 获取统计表格信息
                this.getSummaries()
                this.$showMessage({ type: 'success', msg: res.returnMsg })
              } else {
                this.$showMessage({ type: 'error', msg: res.returnMsg })
              }
            } else {
              return false
            }
          })
        }
      }
    },

    /** 删除作品 */
    removeWork (item) {
      console.log(item);
      if (item.articleStatus !== 2 && item.taskStatus !== 2) {
        this.$wpConfirm('提示', {
          tips: "确定删除吗？",
          content: "删除后数据不可恢复",
          isShowCancelBtn: true, //是否显示取消按钮
        }).then(async () => {
          const res = await wpyTaskApi.deleteTaskArticle({ taskArticleId: item.taskArticleId })
          if (res.returnCode == 200) {
            // 重新获取任务列表
            this.getArticleList()
            /** 获取统计表格信息 */
            this.getSummaries()
            this.$showMessage({ type: 'success', msg: res.returnMsg })
          } else {
            this.$showMessage({ type: 'error', msg: res.returnMsg })
          }
        }).catch(() => {
          console.log("取消")
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>