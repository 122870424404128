<template>
  <div class="echarts-div" :id="id"></div>
</template>

<script>
// import echarts from "echarts";

export default {
  // mixins: [resize],
  props: {
    pieData: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: "chart"
    },
    hoverTitle: {
      type: String,
      default: "人员数量"
    },
    seriesName: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      myChart: null
    };
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  computed: {

  },
  methods: {
    setData () {
      if (this.myChart) {
        this.setOption()
      } else {
        this.initChart()
      }
    },
    remFontSize: function (rem) {
      var fontSize = document.documentElement.style.fontSize;
      return Math.floor(rem * fontSize.replace('px', ''));
    },
    resizeW () {
      this.myChart.resize()
    },
    initChart: function () {
      if (!document.getElementById(this.id)) return;
      this.myChart = this.$echarts.init(document.getElementById(this.id));
      this.setOption();
      window.addEventListener('resize', () => {
        if (this.myChart) {
          this.myChart.resize();
        }
      });
    },
    setOption: function () {
      let that = this
      let colorList = ['#0263ff', '#80eac8', '#a50df3', '#02b5ff', '#ffaf03', '#fa7ca3', '#ff7723', '#c981dd', '#ffa9dc'];
      this.pieData.forEach((item, index) => {
        if (item.name.length > 9) {
          item.name = item.name.slice(0, 9) + '...'
        }
        item.itemStyle = {
          color: colorList[index]
        }
      })
      console.log(this.pieData)
      let option = {
        legend: {
          show: true,
          orient: 'vertical',
          top: 'middle',
          right: '10%',
          itemGap: 16,
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8
        },
        tooltip: {
          trigger: 'item',
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 0px 5px 0px rgba(3,109,255,0.2000)',
          padding: 18,
          textStyle: {
            color: '#333333'
          },
          formatter: function (params) {
            console.log(params)
            return `<div style="background-color:${params.color};display:inline-block;width: 8px;height: 8px;border-radius:50%;margin-right: 4px;vertical-align: middle;"></div><span style="color:#333333;font-size: 16px;font-weight: bold;">${params.name}</span>
            <div style="margin-top: 8px;line-height:20px;"> <div style="display:inline-block;width: 8px;height: 8px;margin-right: 4px;"></div>${that.hoverTitle}: ${params.value}人 </div ><div style="margin-top: 8px;line-height:20px;"> <div style="display:inline-block;width: 8px;height: 8px;margin-right: 4px;"></div>占比: ${params.percent}%</div > `
          }
        },
        series: [
          {
            name: that.seriesName,
            type: 'pie',
            radius: ['20%', '70%'],
            center: ['40%', '50%'],
            label: {
              show: true,
              // alignTo: 'labelLine',
              formatter: '{d}%\n',
              position: 'outer',
              // minMargin: 10,
              minMargin: 5,
              edgeDistance: '10',
              lineHeight: 15,
              // labelLine: {
              //   show: true,
              //   length: 10,
              //   length2: 20,
              // }
              // rich: {
              //   time: {
              //     fontSize: 10,
              //     color: '#999'
              //   }
              // }
            },
            labelLine: {
              show: true,
              length: 20,
              length2: 20,
              maxSurfaceAngle: 90
            },
            labelLayout: function (params) {
              // const isLeft = params.labelRect.x < that.myChart.getWidth() / 2;
              // const points = params.labelLinePoints;
              // // Update the end point.
              // points[2][0] = isLeft
              //   ? params.labelRect.x
              //   : params.labelRect.x + params.labelRect.width;
              // return {
              //   labelLinePoints: points,
              //   // hideOverlap: true
              // };
            },
            data: this.pieData
            // data: [
            // 	{
            // 		value: 335,
            // 		name: '微信',
            // 		itemStyle: {
            // 			color: colorList[0]
            // 		}
            // 	},
            // 	{
            // 		value: 310,
            // 		name: '微博',
            // 		itemStyle: {
            // 			color: colorList[1]
            // 		}
            // 	},
            // 	{
            // 		value: 234,
            // 		name: '博客',
            // 		itemStyle: {
            // 			color: colorList[2]
            // 		}
            // 	},
            // 	{
            // 		value: 135,
            // 		name: '新闻',
            // 		itemStyle: {
            // 			color: colorList[3]
            // 		}
            // 	},
            // 	{
            // 		value: 1548,
            // 		name: '移动新闻',
            // 		itemStyle: {
            // 			color: colorList[4]
            // 		}
            // 	},
            // 	{
            // 		value: 1548,
            // 		name: '境外',
            // 		itemStyle: {
            // 			color: colorList[5]
            // 		}
            // 	},
            // 	{
            // 		value: 1548,
            // 		name: '论坛',
            // 		itemStyle: {
            // 			color: colorList[6]
            // 		}
            // 	},
            // 	{
            // 		value: 1548,
            // 		name: '其它',
            // 		itemStyle: {
            // 			color: colorList[7]
            // 		}
            // 	}
            // ]
          }
        ]
      };
      if (!this.myChart) {
        return;
      }
      this.myChart.setOption(option);
    },
  },
  watch: {
    // count: {
    // 	handler: function(val, oldval) {
    // 		if (val) {
    // 			this.resizeW()
    // 		}
    // 	},
    // 	deep: true //对象内部的属性监听，也叫深度监听
    // }
  },
};
</script>

<style scoped>
.chartsClass {
  padding-left: 1.2rem;
}
</style>
