<template>
  <wp-dialog @cancel="cancel" title="充值短信量" size="small" @determine="determine" width="420px" smallHeight="246px" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="smsRecharge">
      <div class="select-item">
        <ul>
          <li v-for="item in amountList" :key="item.id" :class="{ 'active': item.id == query.selectId }" @click="selectAmount(item)">
            {{  item.name  }}
          </li>
        </ul>
      </div>
      <div class="remarks">
        <el-input type="textarea" placeholder="请输入备注" v-model.trim="query.textareaVal" maxlength="500" resize="none" show-word-limit>
        </el-input>
      </div>
    </div>
  </wp-dialog>
</template>

<script>
// 充值量：必选，单选，无默认，选项包括“100、500、1000、2000、3000、5000”。未选时提示“请选择充值量”。
// 3）备注：选填，不限格式，500个字符。 
import { insertSysSmsCharge } from '@/api/systemSettings.js';
export default {
  name: 'sms-recharge',
  components: {},
  data () {
    return {
      dialogVisible: false,
      amountList: [
        { id: '100', name: '100', isSelect: false },
        { id: '500', name: '500', isSelect: false },
        { id: '1000', name: '1000', isSelect: false },
        { id: '2000', name: '2000', isSelect: false },
        { id: '3000', name: '3000', isSelect: false },
        { id: '5000', name: '5000', isSelect: false }
      ],
      query: {
        selectId: "",
        textareaVal: ""
      },
      isPosting: false

    };
  },
  props: {
    custId: {
      type: String,
      default: ''
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () {
      this.handleClose()
    },
    determine () {
      if (!this.query.selectId) return this.$showMessage({
        type: 'error',
        msg: '请选择充值量'
      })
      this.postAdd()
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () { },
    selectAmount (item) {
      this.query.selectId = item.id
    },
    // 创建用户 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let query = {
        custId: this.custId,
        chargeValue: this.query.selectId,
        remark: this.query.textareaVal
      }
      let res = null

      try {
        res = await insertSysSmsCharge(query)
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.isPosting = false
        console.log(error)
      }
    },
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.smsRecharge {
  padding: 12px 50px;

  .select-item {
    width: 100%;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 90px;
        height: 36px;
        line-height: 36px;
        /*no*/
        border-radius: 4px;
        /*no*/
        margin: 16px 0px 0px 0px;
        border: 1px solid #dddddd;
        /*no*/
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        position: relative;
        flex-shrink: 0;
        text-align: center;
        cursor: pointer;

        &.active {
          background: rgba(3, 109, 255, 0.1)
            url("~@/assets/images/selectImg.png") no-repeat;
          border: 1px solid #0064e6;
          /*no*/
          color: #0064e6;
          background-position: bottom right;
          background-size: 18px 18px;
          /*no*/
          background-origin: border-box;
        }
      }
    }
  }

  .remarks {
    // padding: 16px 10px;
    margin-top: 16px;

    /deep/.el-textarea {
      .el-textarea__inner {
        height: 86px;
      }
    }
  }

  /deep/.el-input__count {
    color: #0064e6;
    bottom: 10px;
    right: 8px;
  }
}
</style>