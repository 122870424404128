<template>
  <div class="messageCenter">
    <div class="title-header">
      <div class="title-header-left">
        消息中心
      </div>
      <div class="title-header-right flex-Acenter">
        <el-input placeholder="请输入搜索的内容" v-model="title" class="search-w190 wp-input" style="margin-right: 14px;" @change="searchData" @keyup.enter.native="searchData">
          <i slot="prefix" class="wp-input-icon icon-sousuo" style="font-size: 24px;"></i>
        </el-input>
        <el-button class="wp-button wp-btn-icon" type="primary" icon="icon-tianjia" size="medium" @click="showAddMessage">发布公告
        </el-button>
      </div>
    </div>
    <div class="layout-content">
      <el-scrollbar style="height: 100%;">
        <div class="home-admin-content">
          <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <ul class="message-list">
              <li class="wp-moudle-style" :class="{'active':item.remindStatus == 1}" v-for="(item, index) in tableData" :key="item.msgId">

                <el-tooltip class="item" effect="dark" :content="item.title" placement="top" :disabled="!isShowTooltipWidth" popper-class="wp-visibilityPopper" :tabindex="index + 1000">
                  <div class="title textOverOneLine flex-Acenter" @mouseenter="visibilityChangeWidth($event)"><img v-show="item.remindStatus == 1" class="tixi" src="~@/assets/images/tixi.png" alt="">{{ item.title }}</div>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" :content="item.content" placement="top" :disabled="!isShowTooltip" popper-class="wp-visibilityPopper" :tabindex="index">
                  <div class="introduction textOverTwoLine" @mouseenter="visibilityChange($event)">{{ item.content
									}}</div>
                </el-tooltip>

                <div class="action-row">
                  <div class="action-row-left">
                    <p>发布人：{{ item.custName }}-{{ item.createName }} </p>
                    <p>时间：{{ $moment(item.createTime).format("YYYY-MM-DD HH:mm") }}</p>
                  </div>
                  <div class="action-row-right">
                    <el-tooltip class="item" effect="dark" content="编辑" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                      <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big wp-btn-icon-big-grey" @click="eidtMsg(item)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class=" item" effect="dark" content="删除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                      <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big wp-btn-icon-big-grey" @click="delMsg(item)">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </li>
            </ul>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
          <div class="page-row">
            <wp-pagination :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
            </wp-pagination>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <add-message ref="addMessage" :eidtObj="msgObj" @success="refresh"></add-message>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import AddMessage from './components/addMessage.vue';
import { pageList, deleteMessage } from '@/api/messageCenter.js';
export default {
  name: 'messageCenter',
  components: { AddMessage },
  data () {
    return {
      title: '',
      total: 0,
      tableData: [],
      loading: false,
      query: {
        title: '',
        page: 1,
        limit: 30
      },
      msgObj: {},
      isShowTooltip: true,
      isShowTooltipWidth: true
    };
  },
  props: {},
  created () {
    this.getPageList()
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      this.getPageList()
    },
    showAddMessage () {
      this.msgObj = {}
      this.$refs.addMessage.show()
    },
    // 获取列表
    async getPageList () {
      let query = {
        ...this.query
      }
      if (this.loading) return
      try {
        this.loading = true
        let res = await pageList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 搜索
    searchData () {
      this.query.title = this.title
      this.refresh()
    },
    // 刷新列表
    refresh () {
      this.query.page = 1
      this.getPageList()
    },
    eidtMsg (item) {
      this.msgObj = item
      this.$refs.addMessage.show()
    },
    delMsg (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDel(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDel (item) {
      try {
        let res = await deleteMessage({ msgId: item.msgId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
            type: 'success',
            msg: res.returnMsg
          })
        this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    // hover 是否显示全文
    visibilityChange (event) {
      this.isShowTooltip = this.$utils.visibilityChange(event, 'height')
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

  },
  beforeDestroy () {

  },
  watch: {
  }
};
</script>
<style scoped lang="scss">
@import "./css/messageCenter.scss";
</style>
