<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <el-breadcrumb
        style="margin: 20px 24px 0"
        separator-class="el-icon-arrow-right"
        class="wp-breadcrumb"
      >
        <el-breadcrumb-item :to="{ path:isLeader?'/crewReportedWorkDetails':'/myReported' }"
          >{{isLeader?'组员统计':'我的上报'}}</el-breadcrumb-item
        >
        <el-breadcrumb-item>上报详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="main">
        <ContentCard :title="topicObj.title" slotValue="row-content">
          <template v-slot:row-content>
            <div class="key">
              专题编号：<span class="value">{{ topicObj.topicNum }}</span>
            </div>
            <div class="key">
              专题分类：<span class="value">{{ topicObj.topicTypeName }}</span>
            </div>
            <div class="key">
              专题积分：<span class="score">{{
                topicObj.score == -999 ? '按平台' : topicObj.score
              }}</span>
            </div>
          </template>
        </ContentCard>

        <ContentCard class="mt-16" slotValue="vertical-content">
          <template v-slot:vertical-content>
            <div class="item" v-if="articleObj.title">
              <div class="key">作品标题：</div>
              <p class="value">{{ articleObj.title }}</p>
            </div>

            <div class="work-content" v-if="articleObj.content">
              <div class="key">作品内容：</div>
              <p class="value">
                {{ articleObj.content }}
              </p>
            </div>

            <div class="item" v-if="articleObj.url">
              <div class="key" style="flex-shrink: 0;">作品链接：</div>
              <p class="url">
                {{ articleObj.url }}
              </p>
            </div>

            <div class="row-content">
              <div class="key" v-if="articleObj.media">
                媒体平台：<span class="value">{{ articleObj.media }}</span>
              </div>
              <div class="key" v-if="articleObj.publishType">
                发布类型：<span class="value">{{
                  articleObj.publishType
                }}</span>
              </div>
              <div class="key" v-if="articleObj.authorType">
                作者类别：<span class="value">{{ articleObj.authorType }}</span>
              </div>
              <div class="key" v-if="articleObj.author">
                作者名称：<span class="value">{{ articleObj.author }}</span>
              </div>
              <div class="key" v-if="articleObj.netName">
                网名/昵称：<span class="value">{{ articleObj.netName }}</span>
              </div>
            </div>

            <div class="row-content">
              <div class="key" v-if="articleObj.readNum">
                阅读数量：<span class="score">{{ articleObj.readNum }}万</span>
              </div>
              <div class="key" v-if="articleObj.reprint">
                转发数量：<span class="score">{{ articleObj.reprint }}</span>
              </div>
            </div>

            <div
              class="file-list"
              v-if="articleObj.fileJson && articleObj.fileJson.length"
            >
              <div class="key">截图/附件：</div>
              <File :fileJson="articleObj.fileJson"></File>
            </div>
          </template>
        </ContentCard>

        <div class="wp-card mt-16 footer" v-if="rejectData.resonRemark && !isLeader">
          <div class="head">
            <div class="title">审核意见</div>
          </div>

          <div class="comment">
            <div class="user">
              <el-avatar
                style="width: 32px; height: 32px"
                :src="headUrl"
              ></el-avatar>
              <div class="user-name">{{ rejectData.createName || '' }}</div>
              <div class="publish-time">
                {{ $moment(rejectData.createTime).format('YYYY-MM-DD HH:mm') }}
              </div>
            </div>
            <div class="detail-content">
              {{ rejectData.resonRemark }}
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import File from '@/views/components/fileDetail'
import reportedApi from '@/api/reported.js'
import wpyReportedApi from '@/api/wpyReported.js'
import ContentCard from '@/views/components/contentCard'
export default {
  components: { ContentCard, File },
  data () {
    return {
      rejectContent: "", // 驳回内容
      scoreNum: 0, // 分数// 质量评级列表
      gradeList: [
        { id: 3, name: "优秀", stars: 3 },
        { id: 2, name: "良好", stars: 2 },
        { id: 1, name: "合格", stars: 1 },
        { id: -1, name: "不合格", stars: -1 },
        { id: 0, label: "未评级", star: 0 },
      ],
      isDetail: false,
      detailId: "", // 专题Id
      topicObj: {}, // 专题详情
      articleObj: {}, // 文章详情
      articleRow: {}, // 文章详情(拷贝)
      rejectData: {}, // 驳回详情
      acticleIdx: 0, // 文章索引
      articleIds: [], // 文章列表
      videoVisible: false, // 播放视频弹窗
      headUrl: require("@/assets/images/touxiang.png"),
      topicArticleId: 0
    }
  },
  created () {
    if (this.$route.query?.articleId) {
      this.topicArticleId = this.$route.query.articleId
      this.getWorkDetail(this.topicArticleId)
    }
  },
  computed:{
    isLeader () {
      const route = this.$route
      const {path } = route
      console.log('route',route)
      if(path == '/leaderTopicWorkDetail'){
        return true
      }
      return false
    }
  },
  methods: {
    /** 获取作品详情 */
    async getWorkDetail (articleId) {
      const res = await wpyReportedApi.getTopicArticle({ articleId })
      if (res.returnCode == 200) {
        const ret = res.returnData

        // 作品信息
        this.articleObj = ret?.wpTopicArticle || {}

        // 任务信息
        this.topicObj = ret?.wpTopicMain || {}

        // 驳回信息
        this.rejectData = ret?.verify || {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>