<template>
  <div class="select-time wp-select">
    <el-select
      @change="handlerSelect"
      v-model="selectObj.timeType"
      class="search-w140"
    >
      <el-option
        v-for="item in timeType"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>

    <el-date-picker
      class="search-w252"
      :clearable="!days"
      @change="handlerTime"
      v-model="selectObj.timeSection"
      type="daterange"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00', '23:59:59']"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    taskType: {
      type: Array,
      default: () => []
    },
    activeType: [Number, String],
    days: [Number]
  },

  watch: {
    taskType: {
      handler (newVal) {
        if (newVal?.length) {
          this.timeType = newVal
        }
      },
      immediate: true,
      deep: true
    },

    activeType: {
      handler (newVal) {
        console.log(newVal);
        if (newVal) {
          console.log(newVal);
          this.$set(this.selectObj, 'timeType', newVal)
        }
      },
      deep: true,
      immediate: true,
    },

    days: {
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.initTime(newVal)
          })
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      // 时间类型
      timeType: [
        {
          name: "作品提交时间",
          id: 1
        },
        {
          name: "任务发布时间",
          id: 2
        }
      ],
      selectObj: {
        timeType: 1, // 所选类型
        timeSection: "", // 所选时间
        startTime: "", // 开始时间
        endTime: "", // 结束时间
      }
    }
  },
  computed: {
    ...mapGetters(['sysCust']),
    // 是否赣州
    isGZ(){
      return this.sysCust.custId == 2
    }
  },

  mounted () {
    if (this.activeType) {
      this.$set(this.selectObj, 'timeType', this.activeType)
      this.$forceUpdate()
    }

    /* 
      选中日期时间选择器元素，然后创建一个i标签，
      并指定其类名为el-icon-date，并将其插入到日期时间选择器元素中
      然后通过样式的控制调整其到时间选择器尾部的位置
    */
    let keyNode = document.querySelector(".el-date-editor");
    let iNode = document.createElement("i");
    iNode.setAttribute("class", "icon-rili"); // el-icon-bottom
    keyNode.appendChild(iNode);
  },

  methods: {
    // 时间类型选择回调
    handlerSelect () {
      this.$emit('timeSelect', this.selectObj)
    },

    initTime (days = 365) {
      const time = []
      time[0] = this.$moment().subtract(days, "days").format('YYYY-MM-DD 00:00:00')
      time[1] = this.$moment().format('YYYY-MM-DD 23:59:59')
      if(this.isGZ && this.$route.path == '/taskStatistics' ){
        time[0] = this.$moment('2023/01/01').format('YYYY-MM-DD 00:00:00')
        time[1] = this.$moment().format('YYYY-MM-DD 23:59:59')
      }

      this.$set(this.selectObj, 'timeSection', time)
      this.selectObj.startTime = time[0]
      this.selectObj.endTime = time[1]
      console.log(this.selectObj);
      this.$emit('timeSelect', this.selectObj)
    },

    // 选择时间回调
    handlerTime () {
      this.selectObj.startTime = this.selectObj.timeSection?.[0] || ""
      this.selectObj.endTime = this.selectObj.timeSection?.[1] || ""
      this.$emit('timeSelect', this.selectObj)
    },

    // 置空
    clearTime () {
      console.log(this.activeType)
      this.selectObj.timeType = this.activeType || 1
      this.$set(this.selectObj, 'timeSection', [])
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>