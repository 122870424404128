<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/reportedManage' }"
            >上报管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>专题详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <div class="task-head">
            <div class="task-state" v-if="topicInfo.topicStatus == 1">
              已发布
            </div>
            <div class="finsh-state" v-else>已结束</div>
            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{ topicInfo.title }}
            </div>
          </div>

          <div class="task-info mt-20 fs-14">
            <div class="flex">
              <div class="task-item">
                专题编号：<span>{{ topicInfo.topicNum }}</span>
              </div>
              <div class="task-item">
                专题分类：<span>{{ topicInfo.topicTypeName }}</span>
              </div>
              <div class="task-item">
                创建时间：<span>{{
                  $moment(topicInfo.createTime).format('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <div class="task-item flex-3 textOverOneLine">
                创建人：<span>{{ topicInfo.createName }}</span>
              </div>
              <div class="task-item"></div>
            </div>
            <div class="mt-20 flex">
              <div class="task-item">
                开始时间：<span>{{
                  $moment(topicInfo.startTime).format('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <div class="task-item">
                结束时间：<span class="orange">{{
                  topicInfo.longItem
                    ? '不限'
                    : $moment(topicInfo.endTime).format('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <div class="task-item flex-3">
                专题积分：<span class="orange">{{
                  topicInfo.score == -999 ? '按平台' : topicInfo.score
                }}</span>
              </div>
              <div class="task-item"></div>
              <div class="task-item"></div>
            </div>
            <div class="mt-20 task-item">
              专题描述：<span class="lh-24">{{ topicInfo.content }}</span>
            </div>
            <div
              class="mt-20 task-item"
              v-if="topicInfo.fileJson && topicInfo.fileJson.length"
            >
              专题附件：
              <File
                style="margin-top: 8px"
                :fileJson="topicInfo.fileJson"
              ></File>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="fs-16 fw-bold">汇总信息</div>

          <div class="num-list flex">
            <div class="num-item">
              <div>{{ summaryInfo.SEEDNUM }}</div>
              <div>发送人数</div>
            </div>
            <div class="num-item">
              <div>{{ summaryInfo.JSTOTAL }}</div>
              <div>接收人数</div>
            </div>
            <div class="num-item">
              <div>{{ summaryInfo.POPLENUM }}</div>
              <div>上报人数</div>
            </div>
            <div class="num-item">
              <div>{{ summaryInfo.REPORTTOTAL }}</div>
              <div>上报总量</div>
            </div>
            <div class="num-item">
              <div>
                {{ summaryInfo.completionRate }}<span class="fs-14">%</span>
              </div>
              <div>完成率</div>
            </div>
            <div class="num-item">
              <div>
                {{ summaryInfo.qualifiedRate }}<span class="fs-14">%</span>
              </div>
              <div>合格率</div>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="fs-16 fw-bold">队伍上报情况</div>

          <div
            class="wp-table mt-20"
            v-loading="teamLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="teamList" stripe v-if="teamList.length">
              <el-table-column width="50"> </el-table-column>
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column width="120"> </el-table-column>
              <el-table-column
                align="left"
                prop="groupName"
                width="240"
                show-overflow-tooltip
                label="分组名称"
              >
                <template slot-scope="scope">
                  <div
                    class="textOverOneLine"
                    :class="scope.row.child ? 'group-name' : ''"
                    @click="getPeopleDetail(scope.row)"
                  >
                    {{ scope.row.groupName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="seedNum" label="发送人数">
              </el-table-column>
              <el-table-column align="center" prop="jsTotal" label="接收人数">
              </el-table-column>
              <el-table-column align="center" prop="popleNum" label="完成人数">
              </el-table-column>
              <el-table-column align="center" prop="wcRate" label="完成率">
                <template slot-scope="scope">
                  <div>{{ scope.row.wcRate }}%</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="teamList.length == 0 && !teamLoading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            layout="prev, pager, next"
            :total="teamTotal"
            :page-size="10"
            @current-change="teamCurrentChange"
          ></wp-pagination>
        </div>

        <div class="wp-card mt-16">
          <div class="flex-CB people-info">
            <div class="flex-Acenter">
              <div class="fs-16 fw-bold">人员上报情况</div>
              <div
                class="fs-14 wp-blue flex-Acenter export"
                @click="exportFile"
              >
                <i class="icon-daochu"></i>
                <span>导出</span>
              </div>
            </div>
            <div class="is-finish flex">
              <div
                :class="activeFinish == 1 ? ' active-finish' : ''"
                @click="totggleFinish(1)"
              >
                已完成
              </div>
              <div
                :class="activeFinish == 0 ? ' active-finish' : ''"
                @click="totggleFinish(0)"
              >
                未完成
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="peopleLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <div v-if="activeFinish == 1">
              <el-table :data="peopoleList" stripe v-if="peopoleList.length">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="70"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileJson"
                  width="150"
                  label="截图/附件"
                >
                  <template slot-scope="scope">
                    <p
                      v-if="$utils.isImg(scope.row.fileJson)"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>

                    <img
                      v-else
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  align="left"
                  width="150"
                  show-overflow-tooltip
                  prop="createName"
                  label="人员姓名"
                >
                </el-table-column>
                <el-table-column align="left" prop="groupName" label="所在分组">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="createTime"
                  width="180"
                  label="上报时间"
                >
                  <template slot-scope="scope">
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="totalScore"
                  label="奖励积分"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.totalScore == -999
                        ? '按平台'
                        : scope.row.totalScore
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="articleStatus"
                  label="审核状态"
                >
                  <template slot-scope="scope">
                    <div
                      class="flex-center"
                      v-if="scope.row.articleStatus !== 1"
                    >
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">已驳回</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="receive-circle"></div>
                      <div class="receive-color">已通过</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <RejectCause
                      v-if="scope.row.articleStatus == 1"
                      popoverType="add"
                      placeholder="请输入审核意见"
                      @handlerReject="handlerReject"
                    >
                      <div
                        class="reject"
                        @click="topicArticleId = scope.row.topicArticleId"
                      >
                        驳回
                      </div>
                    </RejectCause>

                    <RejectCause
                      v-else
                      popoverType="detail"
                      :rejectObj="rejectObj"
                    >
                      <div
                        class="reject-disabled"
                        @mouseenter="getReject(scope.row.topicArticleId)"
                      >
                        驳回
                      </div>
                    </RejectCause>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div v-if="!activeFinish">
              <el-table :data="peopoleList" stripe v-if="peopoleList.length">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="70"
                >
                </el-table-column>
                <el-table-column width="40"> </el-table-column>
                <el-table-column
                  align="left"
                  width="150"
                  prop="receiverName"
                  label="人员姓名"
                >
                </el-table-column>
                <el-table-column align="left" prop="groupName" label="所在分组">
                </el-table-column>
                <el-table-column
                  align="center"
                  width="200"
                  show-overflow-tooltip
                  prop="custName"
                  label="所在单位"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="topicReceiverStatus"
                  label="接收状态"
                >
                  <template slot-scope="scope">
                    <div
                      class="flex-center"
                      v-if="scope.row.topicReceiverStatus == 1"
                    >
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">未接收</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="receive-circle"></div>
                      <div class="receive-color">已接收</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="receiverTime"
                  label="接收时间"
                >
                  <template slot-scope="scope">
                    {{
                      (scope.row.receiverTime &&
                        $moment(scope.row.receiverTime).format(
                          'YYYY-MM-DD HH:mm'
                        )) ||
                      '-'
                    }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div
                      class="wp-blue wp-pointer"
                      @click="sendMessage(scope.row)"
                    >
                      发送短信
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div
              class="wp-noData mt-20"
              v-show="peopoleList.length == 0 && !peopleLoading"
            >
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            layout="prev, pager, next"
            :total="peopleTotal"
            :page-size="10"
            @current-change="peopleCurrentChange"
          ></wp-pagination>
        </div>

        <div class="wp-card mt-16">
          <div class="fs-16 fw-bold">上报趋势图</div>

          <div
            class="chart-box"
            v-loading="trendLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <bar-chart
              v-if="yTrendData.length"
              hoverTitle="数量"
              ref="trendChart"
              id="trendChart"
              :xAxisData="xTrendData"
              :yAxisData="yTrendData"
            >
            </bar-chart>

            <div
              class="wp-noData mt-20"
              v-if="yTrendData.length == 0 && !trendLoading"
            >
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>

    <wp-dialog
      @cancel="messageCancel"
      size="small"
      :dialogVisible.sync="messageVisible"
      @determine="confirmMessage"
      @handleClose="messageCancel"
    >
      <div class="message-box">
        <div class="fs-16 fw-bold">确定发送短信吗？</div>
        <div class="message-text">发送短信将会提醒接收人</div>
      </div>
    </wp-dialog>

    <wp-dialog
      @cancel="groupingCancel"
      :dialogVisible.sync="groupingVisible"
      @handleClose="groupingCancel"
      :showDefaultFooter="false"
      height="550px"
      width="820px"
      :title="groupingName"
    >
      <div>
        <div
          class="grouping-box wp-table"
          v-loading="twoTeamLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="正在加载"
        >
          <el-table v-if="twoTeamList.length" :data="twoTeamList" stripe>
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="70"
            >
            </el-table-column>
            <el-table-column width="20"> </el-table-column>
            <el-table-column align="center" prop="groupName" label="分组名称">
            </el-table-column>
            <el-table-column align="center" prop="seedNum" label="发送人数">
            </el-table-column>
            <el-table-column align="center" prop="jsTotal" label="接收人数">
            </el-table-column>
            <el-table-column align="center" prop="popleNum" label="完成人数">
            </el-table-column>
            <el-table-column align="center" prop="wcRate" label="完成率">
              <template slot-scope="scope">
                <div>{{ scope.row.wcRate || 0 }}%</div>
              </template>
            </el-table-column>
          </el-table>

          <div
            class="wp-noData mt-20"
            v-if="twoTeamList.length == 0 && !twoTeamLoading"
          >
            <img src="~@/assets/images/noData.png" alt="" />
            <p>暂无数据</p>
          </div>

          <wp-pagination
            class="mt-16"
            layout="prev, pager, next"
            :total="twoTeamTotal"
            :page-size="10"
            @current-change="twoTeamCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </wp-dialog>
  </div>
</template>

<script>
import RejectCause from '@/views/components/rejectCause'
import BarChart from '@/components/echartsComponents/barChart.vue';
import File from '@/views/components/fileDetail'
import reportedApi from '@/api/reported'

export default {
  components: { RejectCause, BarChart, File },
  data () {
    return {
      activeFinish: 1, // 1已完成 0未完成
      messageVisible: false, // 短信弹窗
      groupingVisible: false, // 分组弹窗
      groupingName: "", // 分组名称
      xTrendData: [], // 柱状图x轴数据
      yTrendData: [], // 柱状图y轴数据
      trendLoading: false,
      topicId: "", // 专题id
      summaryInfo: {}, // 汇总信息
      topicInfo: {}, // 专题信息

      teamList: [], // 队伍上报列表
      teamLoading: false,
      teamTotal: 0, // 队伍上报总条数
      teamPage: 1, // 队伍列表页码

      twoTeamList: [], // 二级队伍上报列表
      twoTeamLoading: false,
      twoTeamTotal: 0, // 二级队伍上报总条数
      twoTeamPage: 1, // 二级队伍列表页码
      parentId: "", // 一级队伍Id

      peopoleList: [], // 人员上报列表
      peopleLoading: false,
      peoplePage: 1, // 人员上报页码
      peopleTotal: 0, // 人员上报总条数

      rejectObj: {}, // 获取驳回信息
      topicArticleId: "", // 文章ID 

      peopleItem: {}, // 人员信息
    }
  },

  created () {
    const topicId = this.$route.query?.topicId || ''

    // 有专题id则获取详情
    if (topicId) {
      this.topicId = this.$decrypt(topicId)

      // 获取专题详情
      this.topicDetail()

      // 获取队伍上报情况
      this.getTeamReportedDetail()

      // 获取人员上报情况
      this.getPeopleReportedList()

      // 获取上报趋势
      this.getTrendList()
    }
  },
  methods: {
    // 获取驳回信息
    async getReject (topicArticleId) {
      const res = await reportedApi.getRejectArticleInfo({ topicArticleId })
      if (res.returnCode == 200) {
        this.rejectObj = res.returnData
      }
    },

    /** 导出 */
    async exportFile (event) {
      try {
        if (this.activeFinish) {
          if (this.isExporting) return
          this.isExporting = true
          const res = await reportedApi.topicArticleExport({ exportParam: 1, topicId: this.topicId })
          this.isExporting = false
          if (res.returnCode == 200) {
            this.isExporting = false
            this.$store.dispatch('app/GetDownList')
            this.$utils.setBall(event)
          } else {
            if (Number(res.returnCode) == 1009) return this.$showMessage({
              type: 'warning',
              msg: '文件正在下载，请在下载中心查看'
            })
            this.$showMessage({
              type: 'error',
              msg: res.returnMsg
            })
          }
        } else {
          if (this.isExporting2) return
          this.isExporting2 = true
          const res = await reportedApi.topicInfoReceiverExport({ topicId: this.topicId })
          this.isExporting2 = false
          if (res.returnCode == 200) {
            this.isExporting2 = false
            this.$store.dispatch('app/GetDownList')
            this.$utils.setBall(event)
          } else {
            if (Number(res.returnCode) == 1009) return this.$showMessage({
              type: 'warning',
              msg: '文件正在下载，请在下载中心查看'
            })
            this.$showMessage({
              type: 'error',
              msg: res.returnMsg
            })
          }
        }
      } catch (error) {
        this.isExporting = false
        this.isExporting2 = false
      }
    },

    // 上报趋势
    async getTrendList () {
      if (this.trendLoading) return
      this.trendLoading = true

      try {
        const res = await reportedApi.trendList({ topicId: this.topicId })
        this.trendLoading = false
        if (res.returnCode == 200) {
          const xData = []
          const yData = []
          res.returnData.forEach(item => {
            xData.push(item.DAY.substring(8, 10))
            yData.push(item.TOTAL)
          })
          this.xTrendData = xData
          this.yTrendData = yData
        }
      } catch (error) {
        this.trendLoading = false
      }
    },

    // 二级队伍页码回调
    twoTeamCurrentChange (page) {
      this.twoTeamPage = page
      this.getTeamReportedDetail(this.parentId)
    },

    // 一级队伍页码回调
    teamCurrentChange (page) {
      this.teamPage = page
      this.getTeamReportedDetail()
    },

    // 人员上报页码回调
    peopleCurrentChange (page) {
      console.log(page);
      this.peoplePage = page
      this.getPeopleReportedList()
    },

    /** 获取队伍上报情况 */
    async getTeamReportedDetail (parentId = "") {
      if (parentId) {
        if (this.twoTeamLoading) return
        this.twoTeamLoading = true
      } else {
        if (this.teamLoading) return
        this.teamLoading = true
      }

      try {
        // 根据 parentId 判断是否为二级队伍
        let query = {
          page: parentId ? this.twoTeamPage : this.teamPage,
          limit: 10,
          topicId: this.topicId,
          parentId
        }

        const res = await reportedApi.teamReportedList(query)
        this.twoTeamLoading = false
        this.teamLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData

          if (parentId) {
            this.twoTeamList = data || []
            this.twoTeamTotal = totalCount
          } else {
            this.teamList = data || []
            this.teamTotal = totalCount
          }
        }
      } catch (error) {
        this.twoTeamLoading = false
        this.teamLoading = false
      }
    },

    // 人员上报tabs切换
    totggleFinish (flag) {
      this.activeFinish = flag
      this.peoplePage = 1
      this.peopoleList = []
      this.getPeopleReportedList()
    },

    /** 上报人员列表 */
    async getPeopleReportedList () {
      if (this.peopleLoading) return
      this.peopleLoading = true

      try {
        const query = {
          topicId: this.topicId,
          page: this.peoplePage,
          limit: 10,
        }
        this.peopoleList = []
        // 根据tabs切换来发送请求
        const res = this.activeFinish ? (await reportedApi.peopleReportedList(query)) : (await reportedApi.peopleNoReportedList(query))
        this.peopleLoading = false
        if (res.returnCode == 200) {
          const { data, totalCount } = res?.returnData
          this.peopoleList = data
          this.peopleTotal = totalCount
        } else {
          this.peopoleList = []
          this.peopleTotal = 0
        }
      } catch (error) {
        this.peopleLoading = false
      }
    },

    /** 专题详情 */
    async topicDetail () {
      const res = await reportedApi.topicDetail({ topicId: this.topicId })
      if (res.returnCode == 200) {
        const { total, wpTopicMain, wpTopicType } = res?.returnData

        // 汇总信息
        this.summaryInfo = total

        // 专题信息
        this.topicInfo = wpTopicMain

        this.topicInfo.fileJson = JSON.parse(this.topicInfo.fileJson)
        console.log(this.topicInfo.fileJson);

        // 分类名称
        this.topicInfo.topicTypeName = wpTopicType.topicTypeName
        console.log(total);
      }
    },

    // 获取二级队伍详情
    getPeopleDetail (item) {
      // 为true时,则代表有二级分组
      if (item.child) {
        this.parentId = item.groupId
        this.groupingName = item.groupName
        this.groupingVisible = true
        this.getTeamReportedDetail(item.groupId)
      }
    },

    // 确认驳回弹框回调
    async handlerReject (rejectMsg) {
      const res = await reportedApi.rejectArticle({ rejectMsg, articleId: this.topicArticleId })
      if (res.returnCode == 200) {
        this.getPeopleReportedList()
      }
    },

    // 发送短信
    sendMessage (item) {
      this.peopleItem = item
      this.messageVisible = true
    },

    // 确认发送短信
    async confirmMessage () {
      const userIds = []
      userIds.push(this.peopleItem.createId)
      const res = await reportedApi.sendNote({ userIds, topicId: this.peopleItem.topicId })
      // 发送短信成功关闭弹窗
      if (res.returnCode == 200 || res.returnCode == 10087) {
        this.messageVisible = false
        if (res.returnCode == 10087) {
          this.$showMessage({ type: 'warning', msg: res.returnMsg })
        } else {
          this.$showMessage({ type: 'success', msg: '发送成功' })
        }
      }
    },

    // 关闭短信弹窗或点击取消按钮
    messageCancel () {
      this.messageVisible = false
    },

    // 关闭分组弹窗或点击取消按钮
    groupingCancel () {
      this.groupingVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
