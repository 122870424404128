var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchContent" },
    [
      _vm._t("searchContent"),
      _c(
        "el-button",
        {
          staticClass: "wp-button wp-btn-icon",
          attrs: { type: "primary", icon: "el-icon-search", size: "medium" },
          on: { click: _vm.handlerSearch },
        },
        [_vm._v(" 搜索 ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }