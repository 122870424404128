<template>
  <wp-dialog @cancel="cancel" :title="title" @determine="determine" width="420px" smallHeight="200px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="importUser" v-loading.fullscreen.lock="fullscreenLoading">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="title" label="" :rules="{ required: true, message: '请输入标题', trigger: 'blur' }">
          <el-input class="wp-input" maxlength="100" type="textarea" resize="none" v-model.trim="ruleForm.title" placeholder="请输入标题" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item  label="" :error="errorMsg">
          <ReviewUpload ref="reviewUpload" @fileChange="fileChange" :fileList="fileArr"> </ReviewUpload>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import ReviewUpload from '@/views/components/reviewUpload'
import { insertWpReviewArticle, updateWpReviewArticle,getWpReviewArticle } from '@/api/review.js'
export default {
  name: 'importUser',
  components: { ReviewUpload },
  data () {
    return {
      errorMsg: '',
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
        title: ''
      },
      rules: {
      },
      fullscreenLoading: false,
      title: '阅评上报',
      fileList: [],
      fileArr: [],
      isPosting:false
    };
  },
  props: {
    userObj: {
      type: Object,
      default: () => { }
    },
    reviewObj:{
      type: Object,
      default: () => { }
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.errorMsg = ''

      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.fileList.length == 0) {
            return this.errorMsg = '请上传word文件'
          }
          this.postAdd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
      this.fileList = []
      this.$emit('close')
      this.$refs.reviewUpload.resetFile()
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if(this.reviewObj.reviewTitleId) this.isEidt = true
      if (this.isEidt) {
        this.title = '编辑阅评'
        this.getWpReviewArticle()
      }
    },
    async postAdd () {
      if(this.isPosting) return
      this.isPosting = true
      let query = {
        custId: this.userObj.custId,
        title: this.ruleForm.title,
        fileJson: JSON.stringify(this.fileList),
        createId: this.userObj.userId
      }
      try {
        this.fullscreenLoading = true
        let api = this.isEidt ? updateWpReviewArticle : insertWpReviewArticle
        if(this.isEidt) query.reviewTitleId = this.reviewObj.reviewTitleId
        let res = await api(query)
        this.fullscreenLoading = false
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        this.fullscreenLoading = false
        this.isPosting = false
      }
    },
    fileChange (fileList) {
      console.log('fileList', fileList)
      this.fileList = JSON.parse(JSON.stringify(fileList))
    },
    async getWpReviewArticle(){
      try {
        let api = getWpReviewArticle
        let res = await api({reviewTitleId: this.reviewObj.reviewTitleId})
        this.fullscreenLoading = false
        if (Number(res.returnCode) !== 200){
          this.setInitData(this.reviewObj)
          return 
        }
        this.setInitData(res.returnData)
      } catch (error) {
        console.log(error)
      }
    },
    setInitData(reviewObj){
       this.ruleForm.title = reviewObj.title
       this.fileArr = JSON.parse(reviewObj.fileJson)
       this.fileList = JSON.parse(reviewObj.fileJson)
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.importUser {
  padding: 10px 38px 0px 38px;

  .download {
    text-align: right;

    a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }

  .ruleform {
    margin-top: 8px;
    padding: 20px 20px 0px 20px;
  }

  .tips {
    display: flex;
    align-items: center;

    > P {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-right: 14px;
    }

    .redColor {
      color: #f35454;
    }

    > a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }
}
</style>