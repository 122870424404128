<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/taskList' }"
            >投稿中心</el-breadcrumb-item
          >
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <div class="task-head">
            <div class="task-state" v-if="receiverStatus == 2">已接收</div>
            <div class="finsh-state" v-if="receiverStatus == 3">已完成</div>
            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{ taskInfoObj.title }}
            </div>
          </div>

          <!-- 任务信息 -->
          <div class="task-info mt-20 fs-14">
            <div class="flex">
              <div class="flex" style="flex: 1">
                <div class="task-item">
                  任务编号：<span>{{ taskInfoObj.taskNumber }}</span>
                </div>
                <div class="task-item flex" style="flex: 2">
                  来源单位：
                  <span class="textOverOneLine" style="width: 200px">
                    {{ taskInfoObj.custName }}
                  </span>
                </div>
              </div>
              <div class="flex" style="flex: 1">
                <div class="task-item"></div>
                <div class="task-item"></div>
                <div class="task-item"></div>
              </div>
            </div>

            <div class="mt-20 flex">
              <div class="flex-Acenter" style="flex: 1">
                <div class="task-item">
                  任务站点：<span>{{ taskInfoObj.websiteName }}</span>
                </div>
                <div class="task-item flex-Acenter" style="flex: 2">
                  <div>任务方式：</div>
                  <div
                    class="way-item"
                    v-for="item in taskInfoObj.modeStyleList"
                    :key="item.dictId"
                  >
                    {{ item.dictKey }}
                  </div>
                </div>
              </div>
              <div class="flex" style="flex: 1">
                <div class="task-item"></div>
                <div class="task-item"></div>
                <div class="task-item"></div>
              </div>
            </div>

            <div class="mt-20 flex">
              <div class="flex" style="flex: 1">
                <div class="task-item">
                  单人上限：<span class="orange">{{
                    taskInfoObj.maxArticle == 9999
                      ? '不限'
                      : taskInfoObj.maxArticle
                  }}</span>
                </div>
                <div class="task-item flex" style="flex: 2">
                  字数要求：
                  <span class="orange">{{
                    taskInfoObj.minWord == 9999 ? '不限' : taskInfoObj.minWord
                  }}</span>
                </div>
              </div>
              <div class="flex" style="flex: 1">
                <div class="task-item"></div>
                <div class="task-item"></div>
                <div class="task-item"></div>
              </div>
            </div>

            <div
              class="mt-20 url-text task-item textOverOneLine"
              v-if="taskInfoObj.url"
            >
              <i>任务链接：</i
              ><span
                class="wp-blue"
                @click="$utils.download(taskInfoObj.url)"
                >{{ taskInfoObj.url }}</span
              >
            </div>
            <div class="mt-20 task-item flex" v-if="taskInfoObj.author">
              <span class="key-name">视频号：</span
              ><span>{{ taskInfoObj.author }}</span>
            </div>
            <div class="mt-16 task-item lh-24" v-if="taskInfoObj.content">
              <div class="work-content">
                <div class="key">任务要求：</div>
                <p class="value">
                  {{ taskInfoObj.content }}
                </p>
              </div>
            </div>

            <div
              class="mt-16 task-item"
              v-if="
                $utils.JsonParse(taskInfoObj.fileJson).length &&
                taskInfoObj.taskStyle == 2
              "
            >
              图片视频：
              <File
                style="margin-top: 8px"
                :fileJson="taskInfoObj.fileJson"
              ></File>
            </div>
            <div
              class="mt-16 task-item"
              v-else-if="
                $utils.JsonParse(taskInfoObj.fileJson).length &&
                taskInfoObj.taskStyle == 3
              "
            >
              目标图片：
              <File
                style="margin-top: 8px"
                :fileJson="taskInfoObj.fileJson"
              ></File>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="task-score flex-CB">
            <div class="left flex">
              <el-avatar class="avatar" :src="headUrl"></el-avatar>

              <div class="flex-column-JC score-box">
                <div class="fs-16 flex">
                  <div class="fw-bold">任务积分</div>
                  <div class="orange score">+{{ taskInfoObj.score }}</div>
                </div>

                <div class="flex time-box fs-14">
                  <div class="flex">
                    <div class="time-text">开始时间：</div>
                    <div>
                      {{
                        taskInfoObj.startTime &&
                        $moment(taskInfoObj.startTime).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      }}
                    </div>
                  </div>
                  <div class="flex" style="margin-left: 32px">
                    <div class="time-text">结束时间：</div>
                    <div class="orange">
                      {{
                        taskInfoObj.endTime &&
                        $moment(taskInfoObj.endTime).format('YYYY-MM-DD HH:mm')
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <el-button
                class="wp-button"
                @click="taskTransmit"
                v-if="isWpyAndAdmin"
                >任务转发</el-button
              >
              <el-button
                class="wp-button"
                @click="clickFinish"
                v-preventReClick
                type="primary"
                >提交作品
              </el-button>
            </div>
          </div>
        </div>

        <!-- 我的作品 -->
        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-16 fw-bold">我的作品</div>
            <div class="fs-14">
              作品数量：<span class="wp-blue">{{ total }}</span>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="date"
                width="300"
                label="截图/附件"
              >
                <template slot-scope="scope">
                  <p
                    v-if="$utils.JsonParse(scope.row.fileJson).length"
                    style="cursor: pointer"
                  >
                    <el-popover
                      placement="right-start"
                      :visible-arrow="false"
                      trigger="hover"
                      popper-class="img-popper"
                      :tabindex="3"
                    >
                      <el-image
                        class="scaled-image"
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        fit="contain"
                      ></el-image>
                      <img
                        style="width: 22px; height: 22px"
                        slot="reference"
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      />
                    </el-popover>
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="提交时间"
              >
                <template slot-scope="scope">
                  {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.articleStatus == 1">
                    <div class="receive-circle"></div>
                    <div class="receive-color">已通过</div>
                  </div>
                  <div
                    class="flex-center"
                    v-else-if="scope.row.articleStatus == 2"
                  >
                    <div class="noreceive-circle"></div>
                    <div class="noreceive-color">已驳回</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="workObj.page"
            :pageSize="workObj.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <!-- 任务转发 -->
    <wp-dialog
      @cancel="cancelTransmit"
      @determine="determine"
      size="small"
      title="任务转发"
      :dialogVisible="transmitVisible"
      @handleClose="cancelTransmit"
    >
      <div style="height: 170px" class="flex-center">
        <div class="wp-center">
          <div class="fs-16 fw-bold">任务转发</div>
          <div class="mt-16 fs-14" style="color: 666">
            此任务已转发过，是否需要再次转发？
          </div>
        </div>
      </div>
    </wp-dialog>

    <!-- 提交作品 -->
    <task-dialog
      title="提交作品"
      width="680px"
      height="100%"
      @cancel="taskCancel"
      @determine="submitEditForm"
      :dialogVisible.sync="taskVisible"
      @handleClose="taskCancel"
    >
      <div class="task-dialog">
        <div class="wp-form">
          <el-form
            class="wp-form"
            :model="taskForm"
            :rules="taskFormRules"
            ref="taskRef"
            label-width="100px"
          >
            <el-form-item
              class="item-content"
              label="任务标题："
              prop="taskName"
            >
              <el-input
                disabled
                class="wp-input w480"
                v-model.trim="taskForm.taskName"
                placeholder="请输入任务标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="$utils.JsonParse(taskInfoObj.modeStyleList).length !== 1"
              class="item-content"
              label="任务方式："
              prop="modeStyle"
            >
              <el-checkbox-group v-model="taskForm.modeStyle" :max="1">
                <el-checkbox
                  v-for="item in taskInfoObj.modeStyleList"
                  :key="item.dictValue"
                  :label="item.dictValue"
                  >{{ item.dictKey }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <el-form-item
              v-if="setting.parmTitleShow"
              class="item-content"
              label="作品标题："
              :rules="[
                {
                  required: setting.parmTitleMust,
                  trigger: 'blur',
                  message: '请输入作品标题',
                },
              ]"
              prop="title"
            >
              <el-input
                class="wp-input w480"
                maxlength="100"
                v-model.trim="taskForm.title"
                placeholder="请输入作品标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmUrlShow"
              class="item-content isUrl"
              label="作品链接："
              prop="url"
              :rules="[
                {
                  required: setting.parmUrlMust,
                  trigger: 'blur',
                  message: '请输入作品链接',
                },
              ]"
            >
              <el-input
                @blur="changeUrl"
                maxlength="2000"
                class="wp-input w480"
                v-model.trim="taskForm.url"
                placeholder="请输入作品链接"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="作品内容："
              prop="content"
              v-if="setting.parmContentShow"
              :rules="[
                {
                  required: setting.parmContentMust,
                  trigger: 'blur',
                  message: '请输入作品内容',
                },
              ]"
            >
              <el-input
                class="w480"
                type="textarea textarea"
                maxlength="1000"
                :rows="6"
                v-model.trim="taskForm.content"
                placeholder="请输入作品内容"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmAttachShow"
              :rules="[
                {
                  required: setting.parmAttachMust,
                  trigger: ['blur', 'change'],
                  message: '请上传文件',
                },
              ]"
              label="截图/附件："
              prop="fileJson"
            >
              <UploadFile
                ref="upload"
                :imageMaxNum="5"
                key="img"
                @fileChange="fileChange"
                @changeUpload="changeUpload"
                accept=".jpg, .jpeg, .png, .mp4"
              ></UploadFile>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </task-dialog>
  </div>
</template>

<script>
import { validTaskURL } from '@/utils/validate.js';
import File from '@/views/components/fileDetail'
import wpyTaskApi from '@/api/wpyTask.js'
import { mapGetters, mapState, mapMutations } from 'vuex';
// import UploadFile from '@/views/components/uploadFile'
import UploadFile from '@/views/components/uploadFileClipboard'
import RejectCause from '@/views/components/rejectCause'


export default {
  components: { RejectCause, UploadFile, File },
  props: {
    taskId: [Number, String]
  },
  data () {
    return {
      isUpload: false,
      tableData: [],
      groupingName: "", // 分组名称
      transmitVisible: false, // 转发弹窗
      taskVisible: false, // 手动完成弹窗
      taskForm: {
        taskName: '', // 任务名称
        title: '', // 任务标题
        modeStyle: [], // 任务方式
        url: '', // 作品链接
        content: '', // 作品内容
        fileJson: [], // 截图/附件
        opType: 1,// 操作类型 1:手动 2:自动
      },
      loading: false,
      taskFormRules: {
        url: [{ required: true, trigger: 'blur', message: '请输入作品链接' }],
        taskName: [{ required: true, trigger: 'blur', message: '' }],
        title: [{ required: true, trigger: 'blur', message: '请输入作品标题' }],
        modeStyle: [{ required: true, trigger: ['blur', 'change'], message: '请选择任务方式' }],
        content: [{ required: true, trigger: 'blur', message: '请输入作品内容' }],
        fileJson: [{ required: true, trigger: ['blur', 'change'], message: '请上传文件' }]
      },
      taskDetailId: "", // 任务Id
      taskInfoObj: {}, // 任务信息
      total: 0,
      workObj: {
        page: 1,
        limit: 10,
      },
      setting: {}, // 高级设置
      isForword: 0, // 是否已转发
      receiverStatus: 2,
      verifyUrl: true, // 校验作品链接是否通过 
      headUrl: require("@/assets/images/touxiang.png"),
      isPosting: false
    }
  },
  computed: {
    ...mapGetters(['isWpyAndAdmin']),
  },

  watch: {
    'taskForm.modeStyle': {
      handler (newVal) {
        console.log(newVal)
      }
    }
  },
  created () {
    if (this.$route.query?.taskId) {
      this.taskDetailId = this.$route.query?.taskId
      this.taskReceiverId = this.$route.query?.taskReceiverId

      // 获取任务详情
      this.getTaskDetail()
      this.workObj.taskId = this.taskDetailId
      // 获取作品列表
      this.getArticleList()
    }
  },
  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },

    // 监听链接输入框
    async changeUrl () {
      const index = await this.$refs['taskRef'].fields.findIndex(item =>
        item?.$el?._prevClass?.indexOf("isUrl") !== -1
      )

      if (this.taskForm.url === '' && this.setting.parmUrlMust) {
        this.verifyUrl = await false
        this.$refs['taskRef'].fields[index].validateMessage = await '请输入作品链接'
        this.$refs['taskRef'].fields[index].validateState = await 'error'
      } else if (!validTaskURL(this.taskForm.url) && this.taskForm.url) {
        this.verifyUrl = await false
        this.$refs['taskRef'].fields[index].validateMessage = await '请输入有效作品链接'
        this.$refs['taskRef'].fields[index].validateState = await 'error'
      } else {
        this.verifyUrl = await true
        this.$refs['taskRef'].fields[index].validateState = await 'success'
      }
      console.log(this.verifyUrl);
    },

    /** 文件上 传成功回调 */
    fileChange (fileList) {
      console.log(fileList);
      this.taskForm.fileJson = fileList
    },

    /** 作品列表 */
    async getArticleList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyTaskApi.getArticleList({ ...this.workObj })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
        }
      } catch (error) {
        this.loading = false
      }
    },

    /** 获取任务详情 */
    async getTaskDetail () {
      const res = await wpyTaskApi.getTaskDetail({ taskId: this.taskDetailId, taskReceiverId: this.taskReceiverId })
      if (res.returnCode == 200) {
        const ret = res.returnData
        // 是否已转发
        this.isForword = ret?.receiverObj.isForword
        this.receiverStatus = ret?.receiverObj.isArticle ? 3 : 2
        this.taskInfoObj = ret?.taskMain || {}

        this.taskInfoObj.fileJson = this.$utils.JsonParse(this.taskInfoObj.fileJson)

        if (this.taskInfoObj?.modeStyleList?.length == 1) {
          this.taskForm.modeStyle = [this.taskInfoObj.modeStyleList[0].dictValue]
        }

        this.setSetting(this.taskInfoObj)
      }
    },

    // 设置作品提交的高级设置
    setSetting (task) {
      // 显示标题
      this.setting.parmTitleShow = task.parmTitle.charAt(0) == '1';
      // 标题必填
      this.setting.parmTitleMust = task.parmTitle.charAt(1) == '1';

      // 显示链接
      this.setting.parmUrlShow = task.parmUrl.charAt(0) == '1';
      // 链接必填
      this.setting.parmUrlMust = task.parmUrl.charAt(1) == '1';
      // if (this.setting.parmUrlMust) {
      //   this.taskFormRules.url.push({ validator: validateUrl, trigger: 'change' })
      // }

      // 显示内容
      this.setting.parmContentShow = task.parmContent.charAt(0) == '1';
      // 内容必填
      this.setting.parmContentMust = task.parmContent.charAt(1) == '1';

      // 显示附件
      this.setting.parmAttachShow = task.parmAttach.charAt(0) == '1';
      // 附件必填
      this.setting.parmAttachMust = task.parmAttach.charAt(1) == '1';
    },

    // 点击手动完成
    clickFinish () {
      if (this.taskInfoObj.taskId) {
        if (this.taskInfoObj.maxArticle > this.total) {
          // 任务名称
          this.taskForm.taskName = this.taskInfoObj.title
          this.taskVisible = true
        } else {
          this.$showMessage({ type: 'warning', msg: '提交次数已达上限' })
        }
      }
    },

    // 关闭手动完成弹窗
    taskCancel () {
      // 清除表单信息
      this.$refs.taskRef.resetFields()
      this.$refs.upload.resetFile()

      // 链接校验恢复初始值
      this.verifyUrl = true
      this.taskVisible = false
      this.isUpload = false
      this.$set(this.taskForm, 'fileJson', [])
    },

    /** 提交手动完成文章表单 */
    async submitEditForm () {
      console.log(this.isUpload);
      if (this.verifyUrl) {
        if (this.isUpload) {
          this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
        } else {
          this.$refs.taskRef.validate(async valid => {
            if (valid) {
              let form = JSON.parse(JSON.stringify(this.taskForm))

              if (form.fileJson?.length) {
                form.fileJson = JSON.stringify(form.fileJson)
              }

              console.log(form);

              // 任务方式
              form.modeStyle = form.modeStyle.join(",")
              // 任务Id
              form.taskId = this.taskDetailId
              // 任务接收主键Id
              form.taskReceiverId = this.taskReceiverId
              try {
                if (this.isPosting) return
                this.isPosting = true
                const res = await wpyTaskApi.addTaskArticle(form)
                this.isPosting = false
                if (res.returnCode == 200) {
                  // 关闭添加作品弹窗
                  this.taskVisible = false
                  // 清除表单信息
                  this.$refs.taskRef.resetFields()
                  this.$refs.upload.resetFile()

                  console.log(this.taskForm)
                  this.$showMessage({ type: "success", msg: res.returnMsg })
                  // 获取任务详情
                  this.getTaskDetail()
                  // 更新我的作品
                  this.getArticleList()
                } else {
                  this.$showMessage({ type: 'error', msg: res.returnMsg })
                }
              } catch (error) {
                this.isPosting = false
              }

            } else {
              return false
            }
          })
        }
      }
    },

    // 任务转发
    taskTransmit () {
      console.log(this.isForword, '是否已转发');
      if (this.isForword) {
        this.transmitVisible = true
      } else {
        this.$router.push({
          path: '/taskCenterTranspondTask',
          query: {
            taskId: this.taskDetailId,
            taskReceiverId: this.taskReceiverId
          }
        })
      }
    },

    // 转发弹窗
    cancelTransmit () {
      this.transmitVisible = false
    },

    // 转发弹窗确认
    determine () {
      this.$router.push({
        path: '/taskCenterTranspondTask',
        query: {
          taskId: this.taskDetailId
        }
      })
      this.transmitVisible = false
    },

    // 获取二级队伍详情
    getDetail (item) {
      this.groupingVisible = true
      this.groupingName = item.date
      console.log(item)
    },

    // 页大小
    handleSizeChange (size) {
      this.workObj.limit = size
      this.workObj.page = 1
      this.getArticleList()
    },

    // 页码
    handleCurrentChange (current) {
      this.workObj.page = current
      this.getArticleList()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>