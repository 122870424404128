<template>
  <div class="flex shortcut-box">
    <div
      @click="clickShortcut(item)"
      class="shortcut-item flex-CB"
      v-for="item in shortcutList"
      :key="item.taskMainRemarkId"
    >
      <el-tooltip effect="dark" :content="item.remark" placement="top-start">
        <div class="textOverOneLine">{{ item.remark }}</div>
      </el-tooltip>
      <i class="icon-guanbi" @click="(event) => removeQuick(item, event)"></i>
    </div>

    <el-popover
      @after-leave="hidePopover"
      placement="top"
      width="520"
      popper-class="basics-popper"
      trigger="click"
    >
      <el-input
        style="width: 472px"
        type="textarea"
        :rows="6"
        maxlength="200"
        v-model="value"
        placeholder="请输入快捷语"
      ></el-input>

      <div class="btn">
        <el-button class="wp-button" size="medium" type="text" @click="hide"
          >取消</el-button
        >
        <el-button
          class="wp-button"
          size="medium"
          type="primary"
          @click="addQuick"
          >确定</el-button
        >
      </div>
      <div
        class="add-shortcut"
        :style="{ marginLeft: shortcutList.length == 0 ? '0px' : '12px' }"
        v-show="shortcutList.length < 3"
        slot="reference"
      >
        <i class="icon-tianjia"></i>
        <div>添加快捷语</div>
      </div>
    </el-popover>
    <div ref="hide"></div>
  </div>
</template>

<script>
export default {
  props: {// 快捷语列表
    shortcutList: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      value: "",
      visible: false
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        console.log(newVal);
      }
    }
  },
  methods: {
    // 点击快捷语
    clickShortcut (item) {
      console.log(item)
      this.$emit("clickShortcut", item.remark)
    },

    hide () {
      this.$refs.hide.click()
    },

    // 添加快捷语回调
    addQuick () {
      console.log(2);
      if (this.value !== '') {
        this.$emit("addQuick", this.value)
      }
    },

    // 删除快捷语
    removeQuick (item, event) {
      this.$emit("removeQuick", item)
      event.stopPropagation()
    },

    // 隐藏弹框时回调
    hidePopover () {
      this.value = ""
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">
.basics-popper {
  padding: 24px;
}
</style>