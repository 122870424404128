var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "loadmore",
              rawName: "v-loadmore",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
          attrs: {
            value: _vm.value,
            filterable: "",
            "filter-method": _vm.handleSearch,
            loading: _vm.loading,
            placeholder: _vm.placeholder,
            clearable: "",
          },
          on: { focus: _vm.focus, clear: _vm.clear, blur: _vm.blur },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._l(_vm.data, function (option) {
        return _c(
          "el-option",
          {
            key: option.value,
            staticStyle: { height: "40px" },
            attrs: {
              label: option[_vm.dictLabel],
              value: option[_vm.dictValue],
            },
          },
          [
            option[_vm.tipFlag]
              ? _c("div", { staticClass: "product_tip" }, [
                  _c("div", { staticClass: "product_tip_bg" }),
                  _c("p", [_vm._v("长期")]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  staticClass: "label",
                  style: { marginLeft: option[_vm.tipFlag] ? "17px" : "0px" },
                },
                [_vm._v(" " + _vm._s(option[_vm.dictLabel]) + " ")]
              ),
              _c(
                "div",
                { staticStyle: { color: "#999999", "font-weight": "400" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$moment(option[_vm.dictTime]).format("YYYY-MM-DD")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]
        )
      }),
      _vm.hasMore
        ? _c("el-option", {
            attrs: { disabled: "", label: "加载中...", value: "-1" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }