var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/specialList" } } },
                    [_vm._v("专题列表")]
                  ),
                  _c("el-breadcrumb-item", [_vm._v("专题详情")]),
                ],
                1
              ),
              _c("div", { staticClass: "wp-card mt-20 task-detail" }, [
                _c("div", { staticClass: "task-head" }, [
                  _vm.receiverStatus == 2
                    ? _c("div", { staticClass: "task-state" }, [
                        _vm._v("已接收"),
                      ])
                    : _vm._e(),
                  _vm.receiverStatus == 3
                    ? _c("div", { staticClass: "finsh-state" }, [
                        _vm._v("已完成"),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "textOverOneLine task-title fs-16 fw-bold" },
                    [_vm._v(" " + _vm._s(_vm.topicData.title) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "task-info mt-20 fs-14" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 专题编号："),
                      _c("span", [_vm._v(_vm._s(_vm.topicData.topicNum))]),
                    ]),
                    _c("div", { staticClass: "task-item" }, [
                      _vm._v(" 创建时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(_vm.topicData.createTime)
                              .format("YYYY-MM-DD HH:mm")
                          ) + " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-item textOverOneLine" }, [
                      _vm._v(" 创建人："),
                      _c("span", [_vm._v(_vm._s(_vm.topicData.createName))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "task-item",
                        staticStyle: { "margin-left": "10px" },
                      },
                      [
                        _vm._v(" 来源单位："),
                        _c("span", [_vm._v(_vm._s(_vm.topicData.custName))]),
                      ]
                    ),
                    _c("div", { staticClass: "task-item" }),
                  ]),
                  _c("div", { staticClass: "mt-20 task-item" }, [
                    _c("div", { staticClass: "work-content" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("专题描述：")]),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(_vm.topicData.content) + " "),
                      ]),
                    ]),
                  ]),
                  _vm.topicData.fileJson && _vm.topicData.fileJson.length
                    ? _c(
                        "div",
                        { staticClass: "mt-20 task-item" },
                        [
                          _vm._v(" 专题附件： "),
                          _c("File", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { fileJson: _vm.topicData.fileJson },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "wp-card mt-16" }, [
                _c("div", { staticClass: "task-score flex-CB" }, [
                  _c(
                    "div",
                    { staticClass: "left flex" },
                    [
                      _c("el-avatar", {
                        staticClass: "avatar",
                        attrs: { src: _vm.headUrl },
                      }),
                      _c("div", { staticClass: "flex-column-JC score-box" }, [
                        _c("div", { staticClass: "fs-16 flex" }, [
                          _c("div", { staticClass: "fw-bold" }, [
                            _vm._v("专题积分"),
                          ]),
                          _c("div", { staticClass: "orange score" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.topicData.score == -999
                                    ? "按平台"
                                    : "+" + _vm.topicData.score || 0
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "flex time-box fs-14" }, [
                          _c("div", { staticClass: "flex" }, [
                            _c("div", { staticClass: "time-text" }, [
                              _vm._v("开始时间："),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$moment(_vm.topicData.startTime)
                                      .format("YYYY-MM-DD HH:mm")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-left": "32px" },
                            },
                            [
                              _c("div", { staticClass: "time-text" }, [
                                _vm._v("结束时间："),
                              ]),
                              _c("div", { staticClass: "orange" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.topicData.longItem
                                        ? "不限"
                                        : _vm
                                            .$moment(_vm.topicData.endTime)
                                            .format("YYYY-MM-DD HH:mm")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick",
                            },
                          ],
                          staticClass: "wp-button",
                          attrs: { type: "primary" },
                          on: { click: _vm.clickFinish },
                        },
                        [_vm._v("上报贴文 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-16 fw-bold" }, [
                      _vm._v("我的上报"),
                    ]),
                    _c("div", { staticClass: "fs-14" }, [
                      _vm._v(" 上报数量："),
                      _c("span", { staticClass: "wp-blue" }, [
                        _vm._v(_vm._s(_vm.total)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  type: "index",
                                  label: "序号",
                                  width: "70",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "fileJson",
                                  width: "300",
                                  label: "截图/附件",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          !scope.row.fileJson ||
                                          JSON.parse(scope.row.fileJson)
                                            .length == 0
                                            ? _c("p", [_vm._v(" - ")])
                                            : _vm.$utils.isImg(
                                                scope.row.fileJson
                                              )
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        "visible-arrow": false,
                                                        trigger: "hover",
                                                        "popper-class":
                                                          "img-popper",
                                                        tabindex: 3,
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass:
                                                          "scaled-image",
                                                        attrs: {
                                                          src: _vm.$utils.handlePreviewImg(
                                                            scope.row.fileJson
                                                          ),
                                                          fit: "contain",
                                                        },
                                                      }),
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "22px",
                                                          height: "22px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          src: _vm.$utils.handlePreviewImg(
                                                            scope.row.fileJson
                                                          ),
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("img", {
                                                staticStyle: {
                                                  width: "22px",
                                                  height: "22px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  src: _vm.$utils.handlePreviewImg(
                                                    scope.row.fileJson
                                                  ),
                                                },
                                                slot: "reference",
                                              }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4152096221
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "createTime",
                                  label: "上报时间",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      scope.row.createTime
                                                    )
                                                    .format("YYYY-MM-DD HH:mm")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2503685475
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "totalScore",
                                  label: "奖励积分",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.totalScore == -999
                                                  ? "按平台"
                                                  : scope.row.totalScore
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1620228358
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "articleStatus",
                                  label: "审核状态",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.articleStatus == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "receive-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "receive-color",
                                                    },
                                                    [_vm._v("已通过")]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "flex-center" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "noreceive-circle",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "noreceive-color",
                                                    },
                                                    [_vm._v("已驳回")]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3838599373
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.articleParams.page,
                      pageSize: _vm.articleParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.taskVisible
        ? _c(
            "task-dialog",
            {
              attrs: {
                title: "上报贴文",
                width: "680px",
                height: "100%",
                maxHeight: "530px",
                dialogVisible: _vm.taskVisible,
              },
              on: {
                cancel: _vm.taskCancel,
                determine: _vm.submitForm,
                "update:dialogVisible": function ($event) {
                  _vm.taskVisible = $event
                },
                "update:dialog-visible": function ($event) {
                  _vm.taskVisible = $event
                },
                handleClose: _vm.taskCancel,
              },
            },
            [
              _c("div", { staticClass: "task-dialog" }, [
                _c(
                  "div",
                  { staticClass: "wp-form" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "taskRef",
                        staticClass: "wp-form",
                        attrs: {
                          model: _vm.taskForm,
                          rules: _vm.taskFormRules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _vm.setting.parmTitleShow
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content",
                                attrs: {
                                  label: "作品标题：",
                                  prop: "title",
                                  rules: [
                                    {
                                      required: _vm.setting.parmTitleMust,
                                      trigger: "blur",
                                      message: "请输入作品标题",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "100",
                                    placeholder: "请输入作品标题",
                                  },
                                  model: {
                                    value: _vm.taskForm.title,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "title",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.title",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmUrlShow
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content isUrl",
                                attrs: {
                                  label: "作品链接：",
                                  prop: "url",
                                  rules: [
                                    {
                                      required:
                                        _vm.setting.parmUrlMust &&
                                        !_vm.urlDisabled,
                                      trigger: "blur",
                                      message: "请输入作品链接",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "2000",
                                    placeholder: "请输入作品链接",
                                    disabled: _vm.urlDisabled,
                                  },
                                  on: { blur: _vm.changeUrl },
                                  model: {
                                    value: _vm.taskForm.url,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "url",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.url",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmContentShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "作品内容：",
                                  prop: "content",
                                  rules: [
                                    {
                                      required: _vm.setting.parmContentMust,
                                      trigger: "blur",
                                      message: "请输入作品内容",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "w480 textarea",
                                  attrs: {
                                    type: "textarea",
                                    maxlength: "1000",
                                    rows: 6,
                                    placeholder: "请输入作品内容",
                                  },
                                  model: {
                                    value: _vm.taskForm.content,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "content",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.content",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.mediaShow && _vm.mediaList.length
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "媒体平台：",
                                  prop: "media",
                                  rules: [
                                    {
                                      required: _vm.setting.mediaMust,
                                      trigger: "change",
                                      message: "请选择媒体平台",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择媒体平台",
                                    },
                                    on: { change: _vm.changeMedia },
                                    model: {
                                      value: _vm.taskForm.media,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "media", $$v)
                                      },
                                      expression: "taskForm.media",
                                    },
                                  },
                                  _vm._l(_vm.mediaList, function (item) {
                                    return _c("el-option", {
                                      key: item.custWebsiteId,
                                      attrs: {
                                        label: item.name,
                                        value: item.custWebsiteId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.mediaShow && !_vm.mediaList.length
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item-content isUrl",
                                attrs: {
                                  label: "媒体平台：",
                                  prop: "media",
                                  rules: [
                                    {
                                      required: _vm.setting.mediaMust,
                                      trigger: "blur",
                                      message: "请输入媒体平台",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "wp-input w480",
                                  attrs: {
                                    maxlength: "30",
                                    placeholder: "请输入媒体平台",
                                  },
                                  model: {
                                    value: _vm.taskForm.media,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.taskForm,
                                        "media",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "taskForm.media",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.publishShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "发布类型：",
                                  prop: "publishType",
                                  rules: [
                                    {
                                      required: _vm.setting.publishMust,
                                      trigger: "change",
                                      message: "请选择发布类型",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: { placeholder: "请选择发布类型" },
                                    model: {
                                      value: _vm.taskForm.publishType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskForm,
                                          "publishType",
                                          $$v
                                        )
                                      },
                                      expression: "taskForm.publishType",
                                    },
                                  },
                                  _vm._l(_vm.publishList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.typeName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.authorTypeShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "作者类型：",
                                  prop: "authorType",
                                  rules: [
                                    {
                                      required: _vm.setting.authorTypeMust,
                                      trigger: "change",
                                      message: "请选择作者类型",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w480 wp-select",
                                    attrs: { placeholder: "请选择作者类型" },
                                    model: {
                                      value: _vm.taskForm.authorType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskForm,
                                          "authorType",
                                          $$v
                                        )
                                      },
                                      expression: "taskForm.authorType",
                                    },
                                  },
                                  _vm._l(_vm.authTypeList, function (item) {
                                    return _c("el-option", {
                                      key: item.dictId,
                                      attrs: {
                                        label: item.dataKey,
                                        value: item.dataKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          { staticClass: "w580" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.authorShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作者姓名：",
                                          prop: "author",
                                          rules: [
                                            {
                                              required: _vm.setting.authorMust,
                                              trigger: "blur",
                                              message: "请输入作者姓名",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "30",
                                            placeholder: "请输入作者姓名",
                                          },
                                          model: {
                                            value: _vm.taskForm.author,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "author",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.author",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.netNameShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "网名/昵称：",
                                          prop: "netName",
                                          rules: [
                                            {
                                              required: _vm.setting.netNameMust,
                                              trigger: "blur",
                                              message: "请输入网名昵称",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "30",
                                            placeholder: "请输入网名昵称",
                                          },
                                          model: {
                                            value: _vm.taskForm.netName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "netName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.netName",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "w580" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.readNumShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "阅读数量：",
                                          prop: "readNum",
                                          rules: [
                                            {
                                              required: _vm.setting.readNumMust,
                                              trigger: "blur",
                                              message: "请输入阅读数量",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "wp-input form-w200",
                                            attrs: {
                                              maxlength: "8",
                                              placeholder: "请输入阅读数量",
                                            },
                                            on: { input: _vm.resetReadNum },
                                            model: {
                                              value: _vm.taskForm.readNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.taskForm,
                                                  "readNum",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "taskForm.readNum",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "unit",
                                                attrs: { slot: "suffix" },
                                                slot: "suffix",
                                              },
                                              [_vm._v("万")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _vm.setting.reprintShow
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "转载数量：",
                                          prop: "reprint",
                                          rules: [
                                            {
                                              required: _vm.setting.reprintMust,
                                              trigger: "blur",
                                              message: "请输入转载数量",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "wp-input form-w200",
                                          attrs: {
                                            maxlength: "8",
                                            placeholder: "请输入转载数量",
                                          },
                                          on: {
                                            input: _vm.resetReprint,
                                            blur: _vm.reprintBlur,
                                          },
                                          model: {
                                            value: _vm.taskForm.reprint,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm,
                                                "reprint",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "taskForm.reprint",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.setting.parmPushShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "全网推送：",
                                  rules: [
                                    {
                                      required: _vm.setting.parmPushMust,
                                      trigger: "change",
                                      message: "请选择全网推送",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.taskForm.parmPush,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "parmPush", $$v)
                                      },
                                      expression: "taskForm.parmPush",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.setting.parmAttachShow
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "截图/附件：",
                                  prop: "fileJson",
                                  rules: [
                                    {
                                      required: _vm.setting.parmAttachMust,
                                      trigger: "blur",
                                      message: "请提交截图/附件",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("UploadFile", {
                                  key: "img",
                                  ref: "upload",
                                  on: {
                                    fileChange: _vm.fileChange,
                                    changeUpload: _vm.changeUpload,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "奖励积分：", required: "" } },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v("基础分:"),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#faa548" } },
                                  [_vm._v(_vm._s(_vm.taskForm.baseScore))]
                                ),
                              ]),
                              _vm.rewardScore > 0
                                ? _c("span", [
                                    _vm._v("+ 转载分:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.rewardScore > 0
                                              ? "+" + _vm.rewardScore
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.wpTopicPushScore > 0 &&
                              _vm.taskForm.parmPush == "1"
                                ? _c("span", [
                                    _vm._v("+ 推送分:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#faa548" } },
                                      [_vm._v(_vm._s(_vm.wpTopicPushScore))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }