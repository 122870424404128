import { post, get, getImg } from '@/utils/requestTwo'

// 发送验证码
export function loginSend (query) {
	return post(`/login/send`, query)
}
// 登录接口
export function login (query) {
	return post(`/login/auth`, query)
}

// 湘江新区登录接口
export function xjxqLogin (query) {
	return post(`/login/hostLogin`, query)
}

// 账号列表
export function getUserList (query) {
	return post(`/login/userList`, query)
}

//个人信息
export function getInfo (query) {
	return post(`/sysUser/info`, query)
}
// 切换登录
export function changeUser (query) {
	return post(`/login/changeUser`, query)
}
// 登出接口
export function loginOut (query) {
	return post(`/login/out`, query)
}

// 修改密码
export function editPwd (query) {
	return post(`/sysUser/editPwd`, query)
}
// 找回密码 - 获取图片 /login/captcha
export function captchaImg () {
	return getImg(`/login/captcha`, Math.random())
}

// 找回密码 - 步骤1 - 根据用户名查找手机号
export function findMobile (query) {
	return post(`/login/findMobile`, query)
}


// 找回密码 - 步骤2 - 发送验证码
export function sendFind (query) {
	return post(`/login/sendFind`, query)
}

// 找回密码 - 步骤3 - 校验验证码
export function checkFindCode (query) {
	return post(`/login/checkFindCode`, query)
}
// 找回密码 - 步骤4 - 修改密码
export function getBackPass (query) {
	return post(`/login/getBackPass`, query)
}

// 第一次登录 - 修改密码
export function firstLoginEditPwd (query) {
	return post(`/sysUser/firstLoginEditPwd`, query)
}

//常德第三方登录（不需要加密）
export function threepartylogin (query) {
	return post(`/usercenter/threepartylogin`, query, false)
}

//获取客服电话
export function serviceMobile (query) {
	return post(`/sysDict/customer/serviceMobile`, query)
}

// 第三方 登录接口
export function postlogin (query) {
	return post(`/login/hostLogin`, query)
}

