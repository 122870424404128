<template>
  <el-form
    class="form"
    :model="taskForm"
    :rules="taskFormRules"
    ref="taskForm"
    label-width="100px"
  >
    <el-form-item class="item-content" label="任务方式：" prop="modeStyle">
      <el-select
        v-default-select="[disabledStyle]"
        class="wp-select task-w726"
        v-model="taskForm.modeStyle"
        multiple
        :multiple-limit="3"
        placeholder="请选择任务方式"
      >
        <el-option
          v-for="item in taskWayList"
          :key="item.dictId"
          :disabled="item.disabled"
          :label="item.dataKey"
          :value="item.dataValue"
        >
        </el-option>
      </el-select>

      <wp-question-mark
        style="margin-left: 10px"
        content="支持多选，多种方式完成其中一种即可"
      ></wp-question-mark>
    </el-form-item>
    <el-form-item class="item-content" label="任务时间：" prop="timeSection">
      <div class="wp-date-picker-single task-w436">
        <el-date-picker readonly prefix-icon="" value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.startTime" type="datetime" placeholder="请选择开始时间">
        </el-date-picker>
        <span class="el-range-separator">-</span>
        <el-date-picker prefix-icon="" default-time="23:59:59"  value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="taskForm.endTime" type="datetime" placeholder="请选择结束时间" :picker-options="pickerOptionsDatetime" @change="changeDatetime">
        </el-date-picker>
      </div>

      <div
        v-for="item in configData.timeList"
        :key="item.custConfigId"
        class="time-box"
        :class="taskForm.timeRangeType == item.configName ? 'active-text' : ''"
        @click="handlerTimeSection(item)"
      >
        {{ item.configName }}
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="任务积分：" prop="score">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !customScore && taskForm.score == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configData.scoreList"
          :key="item.configValue"
          @click="fastScore(item.configValue)"
        >
          {{ item.configValue }}
        </div>
        <el-input
          @change="verifyScore"
          maxlength="4"
          :class="customScore ? 'custom-text' : ''"
          class="custom-input wp-input"
          v-model="customScore"
          placeholder="自定义"
        ></el-input>
      </div>
    </el-form-item>

    <el-form-item class="item-content" label="字数要求：" prop="minWord">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !customWord && taskForm.minWord == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configData.taskWordsRequire"
          :key="item.configValue"
          @click="fastWord(item.configValue)"
        >
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input
          :class="customWord ? 'custom-text' : ''"
          @input="verifyWord"
          class="custom-input wp-input"
          v-model="customWord"
          placeholder="自定义"
        ></el-input>
      </div>
      <wp-question-mark
        style="margin-left: 10px"
        content="设置转发或评论时最少数字要求"
      ></wp-question-mark>
    </el-form-item>

    <el-form-item class="item-content" label="单人上限：" prop="maxArticle">
      <div class="flex">
        <div
          class="task-box"
          :class="
            !customArticle && taskForm.maxArticle == item.configValue
              ? 'active-text'
              : ''
          "
          v-for="item in configData.taskSingleLimit"
          :key="item.configValue"
          @click="fastArticle(item.configValue)"
        >
          {{ item.configValue == '9999' ? '不限' : item.configValue }}
        </div>
        <el-input
          :class="customArticle ? 'custom-text' : ''"
          @input="verifyArticle"
          class="custom-input wp-input"
          v-model="customArticle"
          placeholder="自定义"
        ></el-input>
      </div>
      <wp-question-mark
        style="margin-left: 10px"
        content="设置当前任务单个人员最多能提交的次数"
      ></wp-question-mark>
    </el-form-item>
  </el-form>
</template>

<script>
import taskApi from '@/api/task.js'
import { resetNumPointOne } from '@/utils/validate.js';
export default {
  props: {
    // 任务方式列表
    taskStyleList: {
      type: Array,
      default: () => []
    },
    configObj: {
      type: Object,
      default: () => { }
    },
    formData: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      visible: false,
      taskForm: {
        modeStyle: "", // 任务方式
        timeSection: "", // 时间区间
        score: "", // 任务积分
        minWord: "", // 字数要求
        maxArticle: "", // 单人上限
        author: "", // 作者
        timeRangeType: "", // 时间快捷项
        startTime:"",
        endTime:""
      },
      taskWayList: [], // 任务方式
      taskFormRules: {
        modeStyle: [{ required: true, trigger: "blur", message: "请选择任务方式" }],
        timeSection: [{ required: true, trigger: "blur", message: "请选择起止时间" }],
        score: [{ required: true, trigger: ["blur", "change"], message: "请选择任务积分" }],
        minWord: [{ required: true, trigger: ["blur", "change"], message: "请选择字数" }],
        maxArticle: [{ required: true, trigger: ["blur", "change"], message: "请选择单人上限" }],
      },
      classifyList: [], // 任务分类列表
      customScore: "", // 自定义任务积分
      customWord: "", // 自定义字数要求
      customArticle: "", // 自定义单人上限
      configData: [],
      count: 0,
      disabledStyle: "", // 不可编辑的任务方式
      initModeStyle: "", // 任务方式
      startTimeTemp:'',
      pickerOptionsDatetime:{
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  watch: {
    // 任务方式列表
    taskStyleList: {
      handler (newVal) {
        console.log(newVal)
        this.taskWayList = newVal
        this.$set(this.taskForm, 'modeStyle', [])
      },
      deep: true,
      immediate: true
    },

    // 任务配置项
    configObj: {
      handler (newVal) {
        console.log(newVal);
        // 拼接时间快捷项名称
        newVal.timeList.forEach(item => {
          if (item?.configUnitStr) {
            item.configName = item?.configValue + item?.configUnitStr
          }
        })

        this.configData = newVal
        console.log(this.configData);
      },
      deep: true,
    },

    formData: {
      handler (newVal) {
        console.log(newVal);
        const { modeStyle, score, minWord, maxArticle } = newVal
        this.initModeStyle = modeStyle

        const modeArr = modeStyle?.split(',') || []
        if (newVal.isDisbled) {
          this.disabledStyle = modeStyle
        }
        console.log(this.disabledStyle);

        const obj = {
          // 任务方式
          modeStyle: modeArr,
          // 任务积分
          score,
          // 字数要求
          minWord,
          // 单人上限
          maxArticle,
        }
        // 任务方式Id
        this.taskForm = { ...obj }

        // 回显任务方式
        this.$set(this.taskForm, 'modeStyle', modeArr)

        // 获取任务方式
        // this.getTaskStyleList(newVal)
        // 设置任务时间
        this.setTimeValue(newVal)

      },
      deep: true,
    },

    formAndConfig: {
      handler (newVal) {
        // 只有第一次进来并且配置数据与表单数据都成功赋值时,才设置自定义参数
        if (newVal?.taskForm?.maxArticle && newVal?.configData?.scoreList?.length && !this.count) {
          console.log(newVal);
          this.setConfigValue()
          this.count += 1
        }
      },
      deep: true
    }
  },

  computed: {
    formAndConfig () {
      const { taskForm, configData } = this
      return { taskForm, configData }
    }
  },

  methods: {
    setConfigValue () {
      this.$nextTick(() => {
        console.log(this.configData, this.taskForm);
        // 匹配任务积分
        const scoreValue = this.configData.scoreList.findIndex(it => it.configValue == this.taskForm.score)
        console.log(scoreValue);
        if (scoreValue == -1) {
          this.customScore = this.taskForm.score
        }

        // 匹配字数要求
        const wordValue = this.configData.taskWordsRequire.findIndex(it => it.configValue == this.taskForm.minWord)
        console.log(wordValue);
        if (wordValue == -1) {
          this.customWord = this.taskForm.minWord
        }

        // 匹配单人上限
        const articleValue = this.configData.taskSingleLimit.findIndex(it => it.configValue == this.taskForm.maxArticle)
        console.log(articleValue);
        if (articleValue == -1) {
          this.customArticle = this.taskForm.maxArticle
        }
        console.log(this.taskForm.score, this.taskForm.minWord, this.taskForm.maxArticle);
      })
    },

    // 校验自定义分数
    verifyScore (e) {
      this.taskForm.score = resetNumPointOne(e, 0, 100)
      this.customScore = this.taskForm.score
    },

    // 积分快捷选项回调
    fastScore (score) {
      this.taskForm.score = score
      this.customScore = ""
      this.$refs['taskForm'].fields[2].validateState = 'success'
    },

    // 校验自定义字数
    verifyWord (e) {
      console.log(e.replace(/[^\d]/g, ''));
      if (e > 100) {
        this.taskForm.minWord = 100
      } else if (e < 0) {
        this.taskForm.minWord = 1
      } else {
        this.taskForm.minWord = e.replace(/[^\d]/g, '')
      }
      this.customWord = this.taskForm.minWord
    },

    // 字数要求快捷选项回调
    fastWord (minWord) {
      this.taskForm.minWord = minWord
      this.customWord = ""
      this.$refs['taskForm'].fields[3].validateState = 'success'
    },

    // 校验单人上限
    verifyArticle (e) {
      const value = e.replace(/[^\d]/g, '')
      if (value > 100) {
        this.taskForm.maxArticle = 100
      } else if (value < 0) {
        this.taskForm.maxArticle = 1
      } else {
        this.taskForm.maxArticle = value
      }
      this.customArticle = this.taskForm.maxArticle
    },

    // 字数要求快捷选项回调
    fastArticle (maxArticle) {
      this.taskForm.maxArticle = maxArticle
      this.customArticle = ""
      this.$refs['taskForm'].fields[4].validateState = 'success'
    },

    /** 设置任务时间 */
    setTimeValue (formData) {
      console.log(formData);
      this.taskForm.timeRangeType = formData?.timeRangeType
      this.$nextTick(() => {
        this.startTimeTemp = formData.startTime
        this.$set(this.taskForm, 'timeSection', [formData.startTime, formData.endTime])
        this.$set(this.taskForm, 'startTime',formData.startTime)
        this.$set(this.taskForm, 'endTime',formData.endTime)
      })
    },

    /** 获取任务方式 */
    async getTaskStyleList (formData) {
      const query = {
        taskStationId: formData.websiteId,
        taskType: formData.taskStyle
      }
      const res = await taskApi.taskStyleList(query)
      if (res.returnCode == 200) {
        this.taskWayList = res.returnData
      }
    },
    // 选择快捷项回调
    handlerTimeSection (item) {
      console.log(item);
      if (item.configName == '月底') {
        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.startTimeTemp = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().endOf('month').format('yyyy-MM-DD HH:mm:ss')

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm.startTime
        this.taskForm.timeSection[1] = this.taskForm.endTime
      } else {
        let unit = ""
        if (item.configUnit == 1) {
          unit = "hours"
        } else {
          unit = "days"
        }

        // 当前时间
        this.taskForm.startTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.startTimeTemp = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        // 结束时间
        this.taskForm.endTime = this.$moment().add(+item.configValue, unit).format("YYYY-MM-DD HH:mm:ss")

        this.taskForm.timeSection = []
        this.taskForm.timeSection[0] = this.taskForm.startTime
        this.taskForm.timeSection[1] = this.taskForm.endTime
      }

      this.$refs['taskForm'].fields[1].validateState = 'success'
      // 时间快捷项名称
      this.taskForm.timeRangeType = item.configName
    },

    // 提交表单
    getFormData () {
      let flag = false
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      if (flag) {
        const query = JSON.parse(JSON.stringify(this.taskForm))
        query.pass = true
        return query
      } else {
        return { pass: false }
      }
    },

    // 清除表单数据项
    resetFields () {
      this.$refs.taskForm.resetFields()
    },
    changeDatetime(val){
      console.log(val)
      this.taskForm.timeRangeType = ""
      this.$forceUpdate()
      if (val) {
        // 开始时间限制为当前时间
        let startTime = this.startTimeTemp
        let endTime = val
        if (this.$moment(endTime).diff(this.$moment(startTime)) < 0) {
          endTime = startTime
        }
        this.taskForm.timeSection = [startTime, endTime]
        this.taskForm.startTime = startTime
        this.taskForm.endTime = endTime
      } else {
        this.taskForm.timeSection = []
        // this.taskForm.startTime = ""
        this.taskForm.endTime = ""
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>