<template>
  <el-dialog :title="title" :visible.sync="visible" :show-close="false" class="wpConfirm" width="420px" :close-on-click-modal="false">
    <div slot="title" class="header-title">
      <div class="el-dialog__title">{{title}}</div>
      <div><i class="el-dialog__close el-icon el-icon-close" @click="closeAction"></i></div>
    </div>
    <div class="dialog-body">
      <!-- <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%"> -->
      <div class="dialog-content">
        <p class="tips">{{ tips }}</p>
        <div class="texts" v-html="content"></div>
      </div>
      <!-- </el-scrollbar> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click.stop="handleAction('cancel')" size="medium" class="wp-button" v-if="isShowCancelBtn">{{
					cancelBtnText
			}}
      </el-button>
      <el-button class="wp-button" type="primary" size="medium" @click.stop="handleAction('yes')">{{ yesBtnText }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'WpConfirm',
  data () {
    return {
      visible: false,
      title: '提示',
      tips: "系统提示",
      content: '请选择要编辑的数据',
      yesBtnText: '确 定', //确定按钮的文字
      cancelBtnText: '取 消', //取消按钮的文字
      type: 'tips', //
      promiseStatus: null,
      isShowCancelBtn: false //是否显示取消按钮
    };
  },
  watch: {
    visible: function (curVal) {
      // if (curVal && document.body.scrollHeight > window.innerHeight) {
      // 	$('body').addClass('backdrop-open');
      // } else {
      // 	$('body').removeClass('backdrop-open');
      // }
    }
  },
  methods: {
    confirm () {
      let _this = this;
      this.visible = true;
      return new Promise(function (resolve, reject) {
        _this.promiseStatus = { resolve, reject };
      });
    },
    handleAction (action) {
      this.visible = false;
      if (action == 'yes') {
        this.promiseStatus && this.promiseStatus.resolve();
      } else {
        this.promiseStatus && this.promiseStatus.reject();
      }
    },
    closeAction () {
      this.handleAction('cancel')
    }
  }
};
</script>

<style lang="less" scoped>
.wpConfirm {
  z-index: 9999 !important;

  .el-dialog {
    width: 420px;
    // height: 280px;
  }

  /deep/.el-dialog__header {
    height: 40px;
    background: linear-gradient(48deg, #0064e5 0%, #00c9ff 100%);
    padding: 0px 22px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-dialog__title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .header-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .el-dialog__headerbtn {
      position: static;
    }
    .el-dialog__close {
      color: #fff;
      cursor: pointer;
      font-size: 18px;
    }
  }

  /deep/.el-dialog__body {
    padding: 0px;
  }

  .dialog-body {
    height: 170px;

    .dialog-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .tips {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }

      .texts {
        margin-top: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .el-scrollbar {
    overflow-y: auto;
  }

  /deep/.el-dialog__footer {
    border-top: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;
    padding: 0px;
  }
}
</style>
