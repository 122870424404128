var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-CB" }, [
      _c("div", { staticClass: "flex-Acenter fs-14" }, [
        _c("div", [
          _vm._v(" 上报人数："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.peopleNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 上报数量："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.articleNum || 0)),
          ]),
        ]),
        _c("div", { staticClass: "ml-30" }, [
          _vm._v(" 奖励积分："),
          _c("span", { staticClass: "wp-blue" }, [
            _vm._v(_vm._s(_vm.statisticsData.score || 0)),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: { size: "medium", icon: "icon-daochu" },
              on: { click: _vm.exportFile },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "wp-table mt-20" },
      [
        _vm.tableData.length !== 0
          ? _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  stripe: "",
                  sortable: "custom",
                  "default-sort": _vm.sortObj,
                },
                on: { "sort-change": _vm.sortChange },
              },
              [
                _c("el-table-column", {
                  attrs: { width: "30" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.longItem
                              ? _c("div", { staticClass: "product_tip" }, [
                                  _c("div", { staticClass: "product_tip_bg" }),
                                  _c("p", [_vm._v("长期")]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2800209195
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "topicNum",
                    label: "专题编号",
                    width: "130",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.topicNum))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3214627206
                  ),
                }),
                _c("el-table-column", { attrs: { "min-width": "30" } }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    prop: "title",
                    label: "专题名称",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "createTime",
                    "min-width": "150",
                    label: "创建时间",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$moment(scope.row.createTime)
                                      .format("YYYY-MM-DD HH:mm")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2503685475
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "memberNum",
                    label: "上报人数",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "90",
                    align: "center",
                    sortable: "",
                    prop: "workNum",
                    label: "上报数量",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    "min-width": "90",
                    sortable: "",
                    prop: "score",
                    label: "奖励积分",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.score == -999
                                    ? "按平台"
                                    : scope.row.score
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3698674086
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.tableData.length == 0
          ? _c("div", { staticClass: "wp-noData" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/noData.png"), alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }