<template>
  <wp-dialog @cancel="cancel" :title="title" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="addGroup">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform">
        <el-form-item label="">
          <el-input class="wp-input" maxlength="20" v-model.trim="ruleForm.nickName" :placeholder="placeholder" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { editUserMediaNickName } from '@/api/drill.js'
export default {
  name: 'addGroup',
  components: {},
  data () {
    return {
      title: "修改昵称",
      placeholder: '请输入微博昵称',//输入分组名称,输入子分组名称
      dialogVisible: false,
      ruleForm: {
        nickName: ""
      },
      rules: {
        // name1: [
        //   { required: true, message: this.placeholder, trigger: 'blur' }
        // ]
      },
      groupinfo: {}

    };
  },
  props: {
    member: {
      type: Object,
      default: () => { }
    },
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.postAdd()
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {
      if (this.member.userId) {
        this.ruleForm.nickName = this.member.oldWeiboName
      }
    },
    // 创建 发起请求
    async postAdd () {
      let query = {
        ...this.ruleForm,
        userId: this.member.userId,
        mediaType: 1
      }
      try {
        let res = await editUserMediaNickName(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        console.log(error)
      }
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.addGroup {
  padding: 70px 50px 0px 50px;
}
</style>