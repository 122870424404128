import { post, get } from '@/utils/requestTwo'

export default {
    // 专题管理-专题列表
    getTopicList: (query = {}) => post("/wpTopicMain/pageList", query, false),

    // 专题管理-专题详情
    getTopicDetail: (query = {}) => post("/wpTopicMain/getInfo", query),

    // 专题详情-我的上报
    getTopicArticleList: (query = {}) => post("/wpTopicArticle/pageList", query, false),

    // 我的上报-删除
    deleteTaskArticle: (query = {}) => post("/wpTopicArticle/delete", query),

    // 上报统计
    wpyReportSummary: (query = {}) => post("/wpTopicArticle/wpyReportSummary", query, false),

    // 组长统计
    groupLeaderStatisticsList: (query = {}) => post("/wpTopicArticle/groupLeaderStatisticsList", query, false),

    // 专题详情-手动完成
    insertTopicArticle: (query = {}) => post("/wpTopicArticle/insert", query, false),

    // 专题详情-修改文章状态
    updateTopicArticle: (query = {}) => post("/wpTopicArticle/update", query, false),

    // 上报审核-获取详情
    getTopicArticle: (query = {}) => post("/wpTopicArticle/get", query),

    // 组员统计导出
    groupLeaderStatisticsExport: (query = {}) => post("/wpTopicArticle/groupLeaderStatisticsExport", query, false),

    // 作者类型下拉框
    topicAuthorTypeList: (query = {}) => post("/sysCustWebsite/topicAuthorTypeList", query, false),

    // 单位配置的媒体平台列表
    getWebsiteList: (query = {}) => post("/sysCustWebsite/list", query),

    // 根据媒体平台获取发布类型
    getPublishTypeList: (query = {}) => post("/sysCustWebsiteScore/list", query),

    // 根据url查询平台信息
    urlToPlatform: (query = {}) => post("/wpTopicArticle/urlToPlatform", query, false),
    
    /**
 * @name: 获取转载数量 奖励分
 * @msg:
 * @param {*}
 * @return {*}
 */
    getReward: (query = {}) => post("/wpTopicArticle/getReward", query, false),
    // 校验链接是否存在
    existUrl: (query = {}) => post("/wpTopicArticle/existUrl", query, false),
    //相似数据
    similar: (query = {}) => post("/wpTopicArticle/similar", query, false),
    

   
}