<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/excellentReportManage' }">优评审核</el-breadcrumb-item>
          <el-breadcrumb-item>优评详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <img src="~@/assets/images/excellent.png" alt="" class="status-img" v-if="taskInfoObj.status==$globalData.EXCELLENT_STATUS.EXCELLENT">
          <div class="task-head">

            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{taskInfoObj.taskName}}
            </div>
          </div>

          <!-- 任务信息 -->
          <div class="task-info mt-20 fs-14">
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">任务标题：</div>
                <p class="value">
                  {{taskInfoObj.title}}
                </p>
              </div>
            </div>
            <div class="mt-20 url-text task-item textOverOneLine">
              <i>任务链接：</i><span class="wp-blue" @click="$utils.download(taskInfoObj.url)"> {{taskInfoObj.url}}</span>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">人员姓名：</div>
                <p class="value">
                  {{taskInfoObj.userName}}
                </p>
              </div>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">所在分组：</div>
                <p class="value">
                  {{taskInfoObj.userGroupName}}
                </p>
              </div>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">跟评内容：</div>
                <p class="value">
                  {{taskInfoObj.content}}
                </p>
              </div>
            </div>

            <div class="mt-16 task-item" v-if="$utils.JsonParse(taskInfoObj.fileJson).length 
              ">
              截图/附件：
              <File style="margin-top: 8px" :fileJson="taskInfoObj.fileJson"></File>
            </div>
          </div>
        </div>
        <div class="wp-card mt-16 footer">

          <div class="bottom flex-CB">
            <div class="flex">
              <el-button class="btn" size="medium" type="danger" @click="handlerReject" v-if="taskInfoObj.status==$globalData.EXCELLENT_STATUS.EXCELLENT">撤销优评</el-button>

              <el-button style="margin-left: 20px" slot="reference" class="btn" size="medium" type="primary" @click="handlerExcellent" v-else>优评</el-button>
            </div>

            <div>
              <el-button @click="flipOver('pre')" class="wp-button" size="medium">上一条</el-button>
              <el-button @click="flipOver('next')" class="wp-button" size="medium">下一条</el-button>
            </div>
          </div>
        </div>

      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import File from '@/views/components/fileDetail'
import { mapGetters } from 'vuex';
import { examineExcellent, getExcellent, excellentStatus,excellentPageList} from '@/api/excellentReport.js'
export default {
  components: { File },
  props: {
    taskId: [Number, String]
  },
  data () {
    return {
      taskInfoObj: {},
      searchParams: {},
      workId: '',
      acticleIdx: 1,
      total: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['isWpyAndAdmin']),
  },

  watch: {
    'taskForm.modeStyle': {
      handler (newVal) {
        console.log(newVal)
      }
    }
  },
  created () {
    if (this.$route.query?.id) {
      this.workId = this.$decrypt(this.$route.query?.id)
      const storageKey = this.$route.query?.storageKey
      this.acticleIdx = this.$route.query?.acticleIdx
      if (storageKey) {
        const excellentReport = localStorage.getItem(storageKey)
        this.searchParams = JSON.parse(excellentReport).searchParams
      }
      // 获取详情
      this.getReviewList()
    }
  },
  methods: {
    handlerExcellent () {
      this.$wpConfirm('提示', {
        tips: "确认设为优评吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postExcellentStatus(this.$globalData.EXCELLENT_STATUS.EXCELLENT)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    handlerReject () {
      this.$wpConfirm('提示', {
        tips: "确定撤销优评吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postExcellentStatus(this.$globalData.EXCELLENT_STATUS.NO_EXCELLENT)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 优评状态
    async postExcellentStatus (status) {
      try {
        const res = await excellentStatus({ id: this.workId, status: status })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.getReviewList()
      } catch (error) {
        this.loading = false
      }
    },
    flipOver (mode) {
      if (this.loading) return
      this.loading = true
      if(mode == 'pre' && this.acticleIdx <= 1){
          this.loading = false
          return this.$showMessage({ type: "warning", msg: '已是第一条' })
      }
      if(mode == 'next' && this.acticleIdx >= this.total){
          this.loading = false
          return this.$showMessage({ type: "warning", msg: '已是最后一条' })
      }
      this.acticleIdx = mode == 'pre'? this.acticleIdx - 1:this.acticleIdx + 1
      this.getReviewList ()
    },
    /** 审核列表 */
    async getReviewList () {
      // if (this.loading) return
      // this.loading = true
      const query = JSON.parse(JSON.stringify(this.searchParams))
      query.page = this.acticleIdx
      query.limit = 1
      try {
        const res = await excellentPageList(query)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData
          this.taskInfoObj = data.data[0]
          this.total = data?.totalCount || 0
          this.workId =  this.taskInfoObj.id
        }
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>