<template>
  <div class="unitTab">
    <el-tabs :value="active" @tab-click="handleClick" class="wp-tabs">
      <el-tab-pane
        :name="item.custId"
        v-for="item in tabsList"
        :key="item.custId"
      >
        <span slot="label" :class="{ 'news-before': item.news == 1 }">
          {{ item.label }}</span
        >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'unitTab',
  components: {},
  data () {
    return {
      active: ""
    };
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String,
    }
  },
  watch: {
    activeId: {
      handler (newVal) {
        console.log(newVal);
        this.active = newVal
      }
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
      this.$emit('handleClick', tab.name)
    },

  },
};
</script>
<style scoped lang="scss">
.unitTab {
  /deep/.wp-tabs {
    line-height: 50px;

    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }
    }

    .el-tabs__header {
      margin: 0px;
    }

    .el-tabs__item {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      padding-right: 10px;

      &.is-active {
        color: #0064e6;
      }
    }

    .news-before {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #f35454;
        border-radius: 50%;
        top: 0px;
        right: -10px;
      }
    }
  }
}
</style>
