<template>
  <div class="panel" id="panel">
    <el-container class="layout-container">
      <el-header class="layout-header" height="80px">
        <header-view></header-view>
      </el-header>
      <el-container class="layout-mid">
        <el-aside class="layout-aside">
          <el-scrollbar style="height: 100%;">
            <sidebar-nav></sidebar-nav>
          </el-scrollbar>
        </el-aside>
        <el-main class="layout-main">
          <div class="layout-main-content">
            <router-view class="routerView" />
          </div>
        </el-main>
      </el-container>
    </el-container>
    <Notice ref="notice" :noticeObj="noticeObj"></Notice>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderView from '@/layout/header/header.vue';
import SidebarNav from '@/layout/Sidebar/sidebarNav.vue';
import watermark from '@/utils/watermark.js';
import Notice from '@/layout/panel/components/notice.vue';
import { remindMsgList} from '@/api/messageCenter.js';
export default {
  name: 'panel',
  components: { HeaderView, SidebarNav, Notice },
  data () {
    return {
      timer: null,
      noticeObj:{}
    };
  },
  props: {},
  created () {

  },
  mounted () {
    watermark.initWatermark(this.userInfo.userName, this.userInfo.mobile, 'panel')
    clearTimeout(this.timer)
    this.timer = null
    this.timer = setTimeout(() => {
      this.setWarn()
      this.setNotice()
    }, 1000)
  },
  computed: {
    ...mapGetters(['userInfo', 'warn', 'roleId', 'notif'])
  },
  methods: {
    // 管理员到期提示
    setWarn () {
      if (!this.warn) return
      if (this.roleId == 3 || this.roleId == 4) return
      this.$wpConfirm('提示', {
        tips: "到期提醒",
        content: this.warn,
        yesBtnText: '确定',
        isShowCancelBtn: false, //是否显示取消按钮
        // cancelBtnText: '取消',
      }).then(() => {
        this.$store.commit('SET_WARN', '')
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 网评员的强提醒
    setStrongReminder () {
      console.log(sessionStorage.getItem("notif"))
      if (sessionStorage.getItem("notif")) return
      if (!this.notif) return
      if (this.roleId == 3 || this.roleId == 4) {
        this.$refs.strongReminder.show()
      }
    },
    async setNotice () {
      if (this.roleId != 3 && this.roleId != 4) return
      const res = await remindMsgList({})
      if (Number(res.returnCode) !== 200) return
      if(!res.returnData || res.returnData.length == 0) return
      this.noticeObj = res.returnData[0]
      this.$refs.notice.show()
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.timer = null
  },
  watch: {
    $route: {
      handler (val, oldval) {

      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import "./css/panel.scss";
</style>
