import { post, get } from '@/utils/requestTwo'

export default {
    // 任务分类-列表
    taskTypeList: (query = {}) => post("/wpTaskType/list", query),

    // 任务分类-新增
    taskTypeInsert: (query = {}) => post("/wpTaskType/insert", query),

    // 任务分类-修改
    taskTypeUpdate: (query = {}) => post("/wpTaskType/update", query),

    // 任务分类-移动
    taskTypeMoveOrder: (query = {}) => post("/wpTaskType/moveOrder", query),

    // 任务分类-删除校验
    taskTypeVerifyRemove: (query = {}) => post("/wpTaskType/verifyRemove", query),

    // 任务分类-删除
    taskTypeRemove: (query = {}) => post("/wpTaskType/remove", query),

    // 任务-快捷内容-新增
    remarkInsert: (query = {}) => post("/wpTaskMainRemark/insert", query, false),

    // 任务-快捷内容-列表
    remarkList: (query = {}) => post("/wpTaskMainRemark/list", query),

    // 任务-快捷内容-删除
    remarkDelete: (query = {}) => post("/wpTaskMainRemark/delete", query),

    // 系统字典
    dictList: (query = {}) => post("/sysDict/list", query),

    // 模型管理-任务方式
    taskStyleList: (query = {}) => post("/sysDictRel/taskStyleList", query),

    // 根据链接获取任务站点
    getWebsite: (query = {}) => post("/wpTaskMain/getWebsite", query, false),

    // 人员-树形结构
    groupUserTree: (query = {}) => post("/sysGroup/groupUserTree", query),

    // 人员-加入常用
    saveCommonUser: (query = {}) => post("/wpCommonUser/save", query, false),

    // 人员-删除常用
    deleteCommonUser: (query = {}) => post("/wpCommonUser/delete", query, false),

    // 常用人员-树形结构
    commonUserTree: (query = {}) => post("/wpCommonUser/groupUserTree", query),

    // 任务列表
    taskList: (query = {}) => post("/wpTaskMain/pageList", query, false),

    // 任务详情
    taskDetail: (query = {}) => post("/wpTaskMain/detail", query),

    // 删除任务
    deleteTask: (query = {}) => post("/wpTaskMain/delete", query),

    // 结束任务
    downStatus: (query = {}) => post("/wpTaskMain/downStatus", query),

    // 发送短信
    sendNote: (query = {}) => post("/wpTaskMain/sendByTaskId", query),

    // 人员队伍
    receiverList: (query = {}) => post("/wpTaskReceiver/pageList", query),

    // 人员完成情况
    articleList: (query = {}) => post("/wpTaskArticle/pageList", query, false),

    // 文章-驳回
    rejectArticle: (query = {}) => post("/wpTaskArticle/rejectArticle", query, false),

    // 文章-改分
    updateScore: (query = {}) => post("/wpTaskArticle/updateScore", query),

    // 文章-星级
    updateStars: (query = {}) => post("/wpTaskArticle/updateStars", query),

    // 批量评级
    updateStarsBatch: (query = {}) => post("/wpTaskArticle/updateStarsBatch", query, false),

    // 文章详情
    getArticleDetail: (query = {}) => post("/wpTaskArticle/get", query),

    // 获取单位默认参数
    getConfigList: (query = {}) => post("/sysCustConfig/pageList2", query),

    // 任务-获取上次选中人员
    getBackReceiver: (query = {}) => post("/wpTaskReceiver/getBackReceiver", query, false),

    // 创建任务
    taskMainInsert: (query = {}) => post("/wpTaskMain/insert", query, false),

    // 任务接收人员
    taskReceiverList: (query = {}) => post("/wpTaskReceiver/list", query),

    // 任务接收人员
    insertReceiverPeople: (query = {}) => post("/wpTaskReceiver/insertBatch", query, false),

    // 编辑任务
    updateTask: (query = {}) => post("/wpTaskMain/update", query, false),

    // 统计-人员统计
    memberStatistics: (query = {}) => post("/wpTaskArticle/memberStatisticsList", query, false),

    // 统计-分类统计
    typeStatistics: (query = {}) => post("/wpTaskArticle/typeStatisticsList", query),

    // 分类统计-导出
    typeStatisticsExport: (query = {}) => post("/wpTaskArticle/typeStatisticsExport", query),

    // 队伍完成情况(分组统计)
    teamMumberList: (query = {}) => post("/wpTaskMain/pageTeamMumberList", query, false),

    // 分组统计-导出
    teamMumberExport: (query = {}) => post("/wpTaskMain/teamMumberExport", query, false),

    // 统计-任务统计
    taskStatistics: (query = {}) => post("/wpTaskArticle/taskStatisticsList", query, false),

    // 任务统计-导出
    taskStatisticsExport: (query = {}) => post("/wpTaskArticle/taskStatisticsExport", query, false),

    // 分组树
    groupTree: (query = {}) => post("/sysGroup/listTree", query, false),

    // 统计-转发统计
    forwardStatistics: (query = {}) => post("/wpTaskMain/forwardStatisticsList", query),

    // 批量任务
    insertBatch: (query = {}) => post("/wpTaskMain/insertBatch", query, false),

    // 任务-链接获取站点批量(不加密)
    getWebsiteBatch: (query = {}) => post("/wpTaskMain/getWebsiteBatch", query, false),

    // 文章-获取驳回信息
    getVerify: (query = {}) => post("/wpTaskArticle/getVerify", query),

    // 统计分析-任务完成方式
    finishStyle: (query = {}) => post("/wpTask/analysis/finishStyle", query),

    // 统计分析-响应排行
    userSubmitTime: (query = {}) => post("/wpTask/analysis/userSubmitTime", query),

    // 统计分析-未完成人员
    userTask: (query = {}) => post("/wpTask/analysis/userTask", query),

    // 获取短信余量
    getSmsNum: (query = {}) => post("/sysCustOption/smsNum", query),

    // 任务列表 - 导出
    exportTaskList: (query = {}) => post("/wpTaskMain/exportTaskList", query, false),

    // 统计表格信息
    getSummaries: (query = {}) => post("/wpTaskArticle/summaries", query, false),

    // 统计表格信息
    getSummariesGroup: (query = {}) => post("/wpTaskArticle/summariesGroup", query, false),

    getSummariesTaskType: (query = {}) => post("/wpTaskArticle/summariesTaskType", query, false),

    // 人员汇总-明细导出
    memberStatisticsExport: (query = {}) => post("/wpTaskArticle/memberStatisticsExport", query, false),

    // 人员汇总-分月导出
    memberMonthExport: (query = {}) => post("/wpTaskArticle/memberMonthExport", query, false),

    // 分组汇总-分月导出
    teamMonthExport: (query = {}) => post("/wpTaskMain/teamMonthExport", query, false),

    // 转发统计-导出
    forwardExport: (query = {}) => post("/wpTaskMain/forwardExport", query),

    // 作品导出
    taskArticleExport: (query = {}) => post("/wpTaskArticle/taskArticleExport", query, false),

    // 截图导出
    taskArticleWord: (query = {}) => post("/wpTaskArticle/taskArticleWord", query),

    // 投稿审核(汇总导出)
    summaryArticleExport: (query = {}) => post("/wpTaskArticle/taskArticleZipExport", query, false),

    // 投稿审核-逐条审核
    taskInfoArticleIds: (query = {}) => post("/wpTaskArticle/taskInfoArticleIds", query, false),

    // 任务-未完成人员-导出
    exportTaskReceiver: (query = {}) => post("/wpTaskReceiver/export", query),

    // 获取管理员端短信配置与短信余量
    forwardConfig: (query = {}) => post("/wpTaskMain/forwardConfig", query),

    // 一键发布任务取值
    getUuid: (query = {}) => post("/login/getUuid", query, false),
    
    // 未完成人员 导出
    userTaskExport: (query = {}) => post("/wpTask/analysis/userTaskExport", query),

     //人员统计-明细
     taskMemberPage: (query = {}) => post("/wpTaskReceiver/taskMemberPage", query, false),

     //人员统计-明细-导出
     taskMemberPageExport: (query = {}) => post("/wpTaskReceiver/taskMemberPageExport", query, false),
}
