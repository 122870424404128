<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/excellentReport' }">优评上报</el-breadcrumb-item>
          <el-breadcrumb-item>优评详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <img src="~@/assets/images/excellent.png" alt="" class="status-img" v-if="taskInfoObj.status==$globalData.EXCELLENT_STATUS.EXCELLENT">
          <div class="task-head">
           
            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{taskInfoObj.taskName}}
            </div>
          </div>

          <!-- 任务信息 -->
          <div class="task-info mt-20 fs-14">
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">任务标题：</div>
                <p class="value">
                  {{taskInfoObj.title}}
                </p>
              </div>
            </div>
            <div class="mt-20 url-text task-item textOverOneLine">
              <i>任务链接：</i><span class="wp-blue" @click="$utils.download(taskInfoObj.url)">  {{taskInfoObj.url}}</span>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">人员姓名：</div>
                <p class="value">
                  {{taskInfoObj.userName}}
                </p>
              </div>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">所在分组：</div>
                <p class="value">
                  {{taskInfoObj.userGroupName}}
                </p>
              </div>
            </div>
            <div class="mt-16 task-item lh-24">
              <div class="work-content">
                <div class="key">跟评内容：</div>
                <p class="value">
                  {{taskInfoObj.content}}
                </p>
              </div>
            </div>


            <div class="mt-16 task-item" v-if="$utils.JsonParse(taskInfoObj.fileJson).length 
              ">
             截图/附件：
              <File style="margin-top: 8px" :fileJson="taskInfoObj.fileJson"></File>
            </div>
          </div>
        </div>

      
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import File from '@/views/components/fileDetail'
import { mapGetters } from 'vuex';
import {getExcellent} from '@/api/excellentReport.js'

export default {
 
  components: {  File },
  props: {
    taskId: [Number, String]
  },
  data () {
    return {
      taskInfoObj:{},
      workId:''
    }
  },
  computed: {
    ...mapGetters(['isWpyAndAdmin']),
  },

  watch: {
    'taskForm.modeStyle': {
      handler (newVal) {
        console.log(newVal)
      }
    }
  },
  created () {
    if (this.$route.query?.id) {
      this.workId = this.$decrypt(this.$route.query?.id) 
      // 获取详情
      this.getTaskDetail()
    }
  },
  methods: {
    /** 获取详情 */
    async getTaskDetail () {
      const res = await getExcellent({ id: this.workId})
      if (res.returnCode == 200) {
        const ret = res.returnData
        this.taskInfoObj = ret
        this.taskInfoObj.fileJson = this.$utils.JsonParse(this.taskInfoObj.fileJson)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>