import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import createVuexAlong from 'vuex-along' //vuex-along

// vuex 模块化引入 require.context
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  modules[moduleName] = modulesFiles(modulePath).default
  return modules
}, {})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules:modules,
  getters,
  // plugins: [createVuexAlong({ name: "wp-vuex-along", })]//vuex-along
})

export default store
