<template>
  <div class="wp-w100">
    <el-scrollbar ref="scroll" class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/reportedManage' }"
            >上报管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>创建专题</el-breadcrumb-item>
        </el-breadcrumb>

        <Steps class="mt-16" :active="active" :stepsList="stepsList"></Steps>

        <OneStep
          @note="getNote"
          :topicTypeId="topicTypeId"
          v-show="active == 1"
          ref="oneStep"
        ></OneStep>

        <TwoStep
          v-show="active == 2"
          ref="twoStep"
          @changeUser="changeUser"
        ></TwoStep>

        <ThreeStep
          :messageFlag="messageFlag"
          :timeCount="timeCount"
          v-show="active == 3"
        >
        </ThreeStep>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 1">
          <el-button @click="oneCancel" class="wp-button">取消</el-button>
          <el-button
            style="margin-left: 24px"
            class="wp-button"
            v-preventReClick
            type="primary"
            @click="nextStep"
            >下一步
          </el-button>
        </div>

        <div class="wp-task-card mt-16 wp-center" v-show="active == 2">
          <el-button @click="twoCancel" class="wp-button">上一步</el-button>
          <el-button
            style="margin-left: 24px"
            class="wp-button"
            v-preventReClick="2000"
            type="primary"
            @click="submitTask"
            >确定
          </el-button>
          <el-checkbox v-model="topicData.sendMsg" style="margin-left: 25px">
            短信通知（余量：<span class="wp-blue">{{ messageNum }}</span>
            ）</el-checkbox
          >
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import reportedApi from '@/api/reported'
import Steps from '@/views/components/steps'
import OneStep from './steps/oneStep' // 基础设置
import TwoStep from './steps/twoStep'
import ThreeStep from './steps/threeStep'
export default {
  components: { Steps, OneStep, TwoStep, ThreeStep },
  data () {
    return {
      stepsList: ["创建专题", "选择人员", "发布成功"], // 步骤条列表
      active: 1,
      queryData: {}, // 从列表带入的数据
      // 专题数据
      topicData: {
        wpTopicMain: {},
        userIdList: [],
        sendMsg: false, // 发送短信
      },
      messageNum: 0, // 短信余量
      timeCount: 0,
      messageFlag: true
    }
  },

  watch: {
    active: {
      handler (newVal) {
        this.$refs.scroll.wrap.scrollTop = 0
      }
    }
  },

  created () {
    // 专题分类Id
    this.topicTypeId = this.$route.query?.topicTypeId || ""
    // 获取短信余量
    this.getSmsNum()
  },
  methods: {
    getNote (note) {
      this.topicData.sendMsg = +note ? true : false
    },

    /** 监听选中人员 */
    changeUser (userList) {
      // 短信余量小于选中人员数量
      if (this.messageNum < userList.length) {
        this.topicData.sendMsg = false
      }
    },

    /** 获取短信余量 */
    async getSmsNum () {
      const res = await reportedApi.getSmsNum({})
      if (res.returnCode == 200) {
        this.messageNum = res.returnData
      }
    },

    // 下一步
    nextStep () {
      const wpTopicMain = this.$refs.oneStep.getFormData()
      console.log(wpTopicMain)

      if (wpTopicMain) {
        if (new Date(wpTopicMain.endTime).getTime() < Date.now()) {
          this.$showMessage({ type: 'warning', msg: '专题结束时间不能小于当前时间', duration: 2000 })
        } else {
          this.active = 2
          this.topicData.wpTopicMain = wpTopicMain
        }
      }
    },

    // 第一步取消回调
    oneCancel () {
      this.$router.push('/reportedManage')
    },

    // 第二步取消回调
    twoCancel () {
      this.active = 1
    },

    // 提交专题信息
    async submitTask () {
      const userList = this.$refs.twoStep.getFormData()
      console.log(userList);
      if (userList?.length) {
        const query = { ...this.topicData }

        query.userIdList = userList

        if (query?.wpTopicMain?.fileJson?.length) {
          query.wpTopicMain.fileJson = JSON.stringify(query.wpTopicMain.fileJson)
        }

        console.log(query);

        const res = await reportedApi.topicMainInsert(query)
        if (res.returnCode == 200 || res.returnCode == 10086) {
          this.messageFlag = true
          if (res.returnCode == 10086) this.messageFlag = false
          this.timeCount = 2000
          this.active = 3
        } else {
          this.$showMessage({ type: 'error', msg: res.returnMsg })
        }
      } else {
        this.$showMessage({ type: 'warning', msg: '请先选择接收人员' })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>