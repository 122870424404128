<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <el-breadcrumb
        style="margin: 20px 24px 0"
        separator-class="el-icon-arrow-right"
        class="wp-breadcrumb"
      >
        <el-breadcrumb-item :to="{ path: isLeader?'/crewWorkDetails':'/myWork' }"
          >{{isLeader?'组员统计':'我的作品'}}</el-breadcrumb-item
        >
        <el-breadcrumb-item>作品详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="main">
        <ContentCard :title="taskDetail.title" slotValue="row-content">
          <template v-slot:row-content>
            <div class="key">
              任务编号：<span class="value">{{ taskDetail.taskNumber }}</span>
            </div>
            <div class="key">
              任务分类：<span class="value">{{ taskDetail.taskStyleStr }}</span>
            </div>
            <div class="key">
              任务站点：<span class="value">{{ taskDetail.websiteName }}</span>
            </div>
            <div class="key">
              任务方式：<span class="value">{{ taskDetail.taskWay }}</span>
            </div>
            <div class="key">
              任务积分：<span class="score">{{ taskDetail.score }}</span>
            </div>
          </template>
        </ContentCard>

        <ContentCard class="mt-16" slotValue="vertical-content">
          <template v-slot:vertical-content>
            <div class="item" v-if="articleDetail.title">
              <div class="key">作品标题：</div>
              <p class="value">{{ articleDetail.title }}</p>
            </div>

            <div class="item mt-12 flex" v-if="articleDetail.url">
              <div class="key" style="min-width: 73px">作品链接：</div>
              <p
                class="url textOverOneLine"
                @click="$utils.download(articleDetail.url)"
              >
                {{ articleDetail.url }}
              </p>
            </div>

            <div class="work-content" v-if="articleDetail.content">
              <div class="key">作品内容：</div>
              <p class="value">
                {{ articleDetail.content }}
              </p>
            </div>

            <div
              class="file-list"
              v-if="articleDetail.fileJson && articleDetail.fileJson.length"
            >
              <div class="key" style="margin-bottom: 0px">截图/附件：</div>
              <File :fileJson="articleDetail.fileJson"></File>
            </div>
          </template>
        </ContentCard>

        <div class="wp-card mt-16 footer" v-if="rejectDetail.resonRemark && !isLeader">
          <div class="head">
            <div class="title">审核意见</div>
          </div>
          <div class="comment">
            <div class="user">
              <el-avatar
                style="width: 32px; height: 32px"
                :src="headUrl"
              ></el-avatar>
              <div class="user-name">{{ rejectDetail.createName }}</div>
              <div class="publish-time">
                {{
                  $moment(rejectDetail.createTime).format('YYYY-MM-DD HH:mm')
                }}
              </div>
            </div>
            <div class="detail-content">
              {{ rejectDetail.resonRemark }}
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div ref="hideDiv"></div>
  </div>
</template>

<script>
import taskApi from '@/api/task.js'
import File from '@/views/components/fileDetail'
import ContentCard from '@/views/components/contentCard'
export default {
  components: { ContentCard, File },
  data () {
    return {
      rejectContent: "", // 驳回内容
      textarea: "",
      scoreNum: 0, // 分数// 质量评级列表
      isDetail: false,
      articleDetail: {}, // 文章详情
      taskDetail: {}, // 任务详情
      rejectDetail: {}, // 驳回详情
      editRow: {},
      articleId: "", // 文章Id
      searchParams: {},
      headUrl: require("@/assets/images/touxiang.png")
    }
  },
  created () {
    console.log(this.$route.query)
    if (this.$route.query.articleId) {
      this.articleId = this.$route.query.articleId
      this.getDetail()
    }
  },
  computed:{
    isLeader () {
      const route = this.$route
      const {path } = route
      if(path == '/leaderTaskArticleDetail'){
        return true
      }
      return false
    }
  },
  methods: {
    // 详情
    async getDetail () {
      const res = await taskApi.getArticleDetail({ articleId: this.articleId })
      if (res.returnCode == 200) {
        const data = res.returnData

        // 文章详情
        this.articleDetail = data?.wpTaskArticle || {}
        this.articleDetail.fileJson = JSON.parse(this.articleDetail.fileJson) || []

        // 任务详情
        this.taskDetail = data?.wpTaskMain || {}

        // 任务方式
        this.taskDetail.taskWay = data.wpTaskMain.modeStyleList.map(item => item.dictKey).join(',')

        console.log(this.articleDetail?.fileJson);


        if (this.articleDetail.stars == 3) {
          this.$set(this.articleDetail, 'grade', '优秀')
        } else if (this.articleDetail.stars == 2) {
          this.$set(this.articleDetail, 'grade', '良好')
        } else if (this.articleDetail.stars == 1) {
          this.$set(this.articleDetail, 'grade', '合格')
        } else if (this.articleDetail.stars == -1) {
          this.$set(this.articleDetail, 'grade', '不合格')
        } else {
          this.$set(this.articleDetail, 'grade', '未评级')
        }

        console.log(this.articleDetail.fileJson);
        // 驳回详情
        this.rejectDetail = data?.verify || {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>