var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      { staticClass: "wp-tree" },
      [
        _c("el-tree", {
          ref: _vm.treeRef,
          staticClass: "tree",
          attrs: {
            data: _vm.peopleList,
            "show-checkbox": "",
            "default-expand-all": false,
            "node-key": _vm.nodeKey,
            "expand-on-click-node": false,
            "highlight-current": "",
            "default-expanded-keys": _vm.defaultExpandedKeys,
            "default-checked-keys": _vm.defaultCheckedKeys,
            props: _vm.defaultProps,
            "filter-node-method": _vm.filterNode,
          },
          on: {
            check: _vm.handleCheckClick,
            "check-change": _vm.handleCheckChange,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var node = ref.node
                var data = ref.data
                return _c(
                  "div",
                  { staticClass: "custom-tree-node" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "textOverOneLine people-name",
                        class:
                          node.level == 1
                            ? "title"
                            : node.level == 2
                            ? "two-title"
                            : node.level == 3
                            ? "three-title"
                            : "people-name",
                      },
                      [_vm._v(_vm._s(data.name))]
                    ),
                    !_vm.isEdit ? void 0 : _vm._e(),
                  ],
                  2
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }