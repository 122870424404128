var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wpPagination" },
    [
      _c("div", { staticClass: "total-text" }, [
        _vm._v(" 共"),
        _c("span", [_vm._v(_vm._s(_vm.total))]),
        _vm._v("条 "),
      ]),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": _vm.pageSizes,
          "page-size": _vm.pageSize,
          layout: _vm.layout,
          total: _vm.total,
          "pager-count": 5,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }