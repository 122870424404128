var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/excellentReportManage" } } },
                    [_vm._v("优评审核")]
                  ),
                  _c("el-breadcrumb-item", [_vm._v("优评详情")]),
                ],
                1
              ),
              _c("div", { staticClass: "wp-card mt-20 task-detail" }, [
                _vm.taskInfoObj.status ==
                _vm.$globalData.EXCELLENT_STATUS.EXCELLENT
                  ? _c("img", {
                      staticClass: "status-img",
                      attrs: {
                        src: require("@/assets/images/excellent.png"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "task-head" }, [
                  _c(
                    "div",
                    { staticClass: "textOverOneLine task-title fs-16 fw-bold" },
                    [_vm._v(" " + _vm._s(_vm.taskInfoObj.taskName) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "task-info mt-20 fs-14" }, [
                  _c("div", { staticClass: "mt-16 task-item lh-24" }, [
                    _c("div", { staticClass: "work-content" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("任务标题：")]),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(_vm.taskInfoObj.title) + " "),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-20 url-text task-item textOverOneLine" },
                    [
                      _c("i", [_vm._v("任务链接：")]),
                      _c(
                        "span",
                        {
                          staticClass: "wp-blue",
                          on: {
                            click: function ($event) {
                              return _vm.$utils.download(_vm.taskInfoObj.url)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.taskInfoObj.url))]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "mt-16 task-item lh-24" }, [
                    _c("div", { staticClass: "work-content" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("人员姓名：")]),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(_vm.taskInfoObj.userName) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "mt-16 task-item lh-24" }, [
                    _c("div", { staticClass: "work-content" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("所在分组：")]),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(
                          " " + _vm._s(_vm.taskInfoObj.userGroupName) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "mt-16 task-item lh-24" }, [
                    _c("div", { staticClass: "work-content" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("跟评内容：")]),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(_vm.taskInfoObj.content) + " "),
                      ]),
                    ]),
                  ]),
                  _vm.$utils.JsonParse(_vm.taskInfoObj.fileJson).length
                    ? _c(
                        "div",
                        { staticClass: "mt-16 task-item" },
                        [
                          _vm._v(" 截图/附件： "),
                          _c("File", {
                            staticStyle: { "margin-top": "8px" },
                            attrs: { fileJson: _vm.taskInfoObj.fileJson },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "wp-card mt-16 footer" }, [
                _c("div", { staticClass: "bottom flex-CB" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _vm.taskInfoObj.status ==
                      _vm.$globalData.EXCELLENT_STATUS.EXCELLENT
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { size: "medium", type: "danger" },
                              on: { click: _vm.handlerReject },
                            },
                            [_vm._v("撤销优评")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              staticStyle: { "margin-left": "20px" },
                              attrs: {
                                slot: "reference",
                                size: "medium",
                                type: "primary",
                              },
                              on: { click: _vm.handlerExcellent },
                              slot: "reference",
                            },
                            [_vm._v("优评")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button",
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.flipOver("pre")
                            },
                          },
                        },
                        [_vm._v("上一条")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "wp-button",
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.flipOver("next")
                            },
                          },
                        },
                        [_vm._v("下一条")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }