var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "download-list" }, [
    _c(
      "div",
      { staticClass: "download-top" },
      [
        _c("p", [_vm._v("下载中心")]),
        _c(
          "el-button",
          {
            staticClass: "wp-btn-icon-big",
            attrs: { type: "text", icon: "icon-shanchu" },
            on: { click: _vm.delAll },
          },
          [_vm._v("清空所有")]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "download-box",
        attrs: {
          "element-loading-spinner": "el-icon-loading",
          "element-loading-text": "正在加载",
        },
      },
      [
        _c(
          "ul",
          _vm._l(_vm.downList, function (item) {
            return _c(
              "li",
              { key: item.downloadId, staticClass: "download-li" },
              [
                _c("div", { staticClass: "download-left" }, [
                  _c("div", { staticClass: "file-img" }, [
                    item.type == "xls" || item.type == "xlsx"
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/file/excel2.png"),
                            alt: "",
                          },
                        })
                      : item.type == "docx"
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/file/word2.png"),
                            alt: "",
                          },
                        })
                      : item.type == "zip"
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/file/zip.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/images/file/defult.png"),
                            alt: "",
                          },
                        }),
                  ]),
                  _c("div", { staticClass: "file-info" }, [
                    _c("div", { staticClass: "file-title textOverOneLine" }, [
                      _vm._v(" " + _vm._s(item.fileName) + " "),
                    ]),
                    _c("div", { staticClass: "file-pro" }, [
                      _c("div", { staticClass: "file-pro-left" }, [
                        _c(
                          "div",
                          { staticClass: "progress-bar" },
                          [
                            item.downStatus == 3 || item.downStatus == 4
                              ? _c("el-progress", {
                                  staticClass: "progress-ani",
                                  attrs: {
                                    "show-text": false,
                                    percentage: 100,
                                    color: "#0064E6",
                                    "stroke-width": 4,
                                  },
                                })
                              : _vm._e(),
                            item.downStatus == 1 || item.downStatus == 0
                              ? _c("el-progress", {
                                  attrs: {
                                    "show-text": false,
                                    percentage: item.percentage,
                                    color: "#0064E6",
                                    "stroke-width": 4,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        item.downStatus == 3 || item.downStatus == 4
                          ? _c("div", { staticClass: "progress-name" }, [
                              _vm._v("文件生成中"),
                            ])
                          : _vm._e(),
                        item.downStatus == 1 || item.downStatus == 0
                          ? _c("div", { staticClass: "progress-name" }, [
                              _vm._v(_vm._s(item.percentage) + "%"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "file-pro-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .$moment(item.createTime)
                                .format("YYYY-MM-DD HH:mm")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "download-right" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "下载",
                          placement: "top",
                          "popper-class": "wp-btn-tooltip",
                          "visible-arrow": false,
                          enterable: false,
                        },
                      },
                      [
                        item.downStatus == 1 || item.downStatus == 0
                          ? _c("el-button", {
                              staticClass: "wp-btn-icon-big",
                              attrs: { type: "text", icon: "icon-xiazai1" },
                              on: {
                                click: function ($event) {
                                  return _vm.downItem(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "删除",
                          placement: "top",
                          "popper-class": "wp-btn-tooltip",
                          "visible-arrow": false,
                          enterable: false,
                        },
                      },
                      [
                        _c("el-button", {
                          staticClass: "wp-btn-icon-big",
                          attrs: { type: "text", icon: "icon-shanchu" },
                          on: {
                            click: function ($event) {
                              return _vm.delItem(item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm.downList.length == 0
          ? _c("div", { staticClass: "wp-noData" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/noData.png"), alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "page-row" },
      [
        _c("wp-pagination", {
          attrs: {
            total: _vm.totalCount,
            pageSize: 5,
            layout: "prev, pager, next",
            currentPage: _vm.query.page,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }