var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "basicsFormRef",
      staticClass: "form",
      attrs: {
        model: _vm.basicsForm,
        rules: _vm.basicsFormRules,
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "专题分类：", prop: "topicTypeId" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "wp-select task-w726",
              attrs: { placeholder: "请选择专题分类" },
              model: {
                value: _vm.basicsForm.topicTypeId,
                callback: function ($$v) {
                  _vm.$set(_vm.basicsForm, "topicTypeId", $$v)
                },
                expression: "basicsForm.topicTypeId",
              },
            },
            _vm._l(_vm.classifyList, function (item) {
              return _c("el-option", {
                key: item.topicTypeId,
                attrs: { label: item.topicTypeName, value: item.topicTypeId },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "专题名称：", prop: "title" },
        },
        [
          _c("el-input", {
            staticClass: "wp-input task-w726",
            attrs: { placeholder: "请输入专题名称" },
            model: {
              value: _vm.basicsForm.title,
              callback: function ($$v) {
                _vm.$set(_vm.basicsForm, "title", $$v)
              },
              expression: "basicsForm.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "专题积分：", prop: "score" },
        },
        [
          _c(
            "div",
            { staticClass: "flex score" },
            [
              _c(
                "div",
                { staticClass: "flex-CB task-w726" },
                [
                  _c("el-input", {
                    staticClass: "wp-input task-w338",
                    class: _vm.customScore ? "custom-text" : "",
                    attrs: { placeholder: "请输入专题积分", maxlength: "6" },
                    on: { input: _vm.scoreChange, change: _vm.verifyScore },
                    model: {
                      value: _vm.customScore,
                      callback: function ($$v) {
                        _vm.customScore =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "customScore",
                    },
                  }),
                  _vm._l(_vm.scoreList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.custConfigId,
                        staticClass: "score-item",
                        class: {
                          disabled:
                            _vm.isDisbledPlatform && item.configValue == -999,
                          "active-score":
                            _vm.scoreId &&
                            (_vm.customScore == "按平台"
                              ? -999
                              : _vm.customScore) == item.configValue,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.fastScore(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.configValue == -999
                                ? "按平台"
                                : item.configValue
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("wp-question-mark", {
                staticClass: "question",
                attrs: {
                  content:
                    "按平台是根据单位指定平台获取对应的积分奖励，例如：央广网、央视网转载A积分、新浪新闻、搜狐新闻转载B积分，平台不同所得积分也不同。",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-form-item",
        {
          staticClass: "item-content",
          attrs: { label: "起止时间：", prop: "timeSection" },
        },
        [
          _c(
            "div",
            { staticClass: "flex-CB task-w726" },
            [
              _c(
                "div",
                { staticClass: "wp-date-picker-single task-w436" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      readonly: "",
                      "prefix-icon": "",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      type: "datetime",
                      placeholder: "请选择开始时间",
                    },
                    model: {
                      value: _vm.basicsForm.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.basicsForm, "startTime", $$v)
                      },
                      expression: "basicsForm.startTime",
                    },
                  }),
                  _c("span", { staticClass: "el-range-separator" }, [
                    _vm._v("-"),
                  ]),
                  _c("el-date-picker", {
                    attrs: {
                      "prefix-icon": "",
                      "default-time": "23:59:59",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      type: "datetime",
                      placeholder: "请选择结束时间",
                      "picker-options": _vm.pickerOptionsDatetime,
                    },
                    on: { change: _vm.changeDatetime },
                    model: {
                      value: _vm.basicsForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.basicsForm, "endTime", $$v)
                      },
                      expression: "basicsForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.timeList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.configValue,
                    staticClass: "time-item",
                    class:
                      _vm.basicsForm.timeRangeType == item.configName
                        ? "active-time"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.handlerTimeSection(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.configName) + " ")]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "专题描述：", prop: "content" } },
        [
          _c("el-input", {
            staticClass: "task-w726 textarea",
            attrs: {
              type: "textarea",
              maxlength: "1000",
              rows: 6,
              placeholder: "请输入专题描述",
            },
            model: {
              value: _vm.basicsForm.content,
              callback: function ($$v) {
                _vm.$set(_vm.basicsForm, "content", $$v)
              },
              expression: "basicsForm.content",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "专题附件：" } },
        [
          _c("UploadFile", {
            key: "image",
            staticStyle: { "margin-top": "3px" },
            attrs: { caution: false, fileList: _vm.basicsForm.fileJson },
            on: { fileChange: _vm.fileChange, changeUpload: _vm.changeUpload },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }