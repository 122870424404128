<template>
  <div class="main flex teamlist">
    <AsideList :asideList="asideList" @searchTree="searchTree" @handlerItem="handlerItem" @handlerAsideItem="handlerAsideItem" @addGroup="addGroup" @nodeClick="nodeClick" :loading="loadingTree" ref="asideList"></AsideList>

    <div class="ml-16 search-card" :class="{ groupLeader: roleId == 3 }">
      <el-scrollbar style="height: 100%">
        <SearchCard :title="commonName" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <el-input class="search-w190 wp-input" v-model.trim="searchQuery.userName" clearable placeholder="请输入人员姓名">
            </el-input>
           
            <el-input class="search-w392 wp-input" v-model.trim="searchQuery.custName" clearable placeholder="请输入单位名称">
            </el-input>
            <el-cascader ref="cascaderRef" class="search-w190 wp-cascader align-top" :options="groupList" @change="changeCascader" v-model="groupIdsAll" :props="defaultProps" collapse-tags placeholder="请选择所在分组" clearable>
            </el-cascader>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex-Acenter">
              <div style="margin-right: 30px">
                常用人数:<span class="wp-blue">{{ memberCount }}</span>
              </div>
              <div>
                组长人数：<span class="wp-blue">{{ groupCount }}</span>
              </div>
              <el-button class="wp-button-text wp-btn-icon" type="text" icon="icon-bianji1" style="margin-left:16px;" @click="batchEidt"> 批量改组
              </el-button>
              <el-button class="wp-button-text wp-btn-icon" type="text" icon="icon-yichu" style="margin-left:16px;" @click="batchDel"> 批量移除
              </el-button>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <!-- 信息项包括“姓名、登录账号、手机号、所在分组、常用分组、单位名称、操作项（编辑、移除）”，点击“姓名、登录名”进入个人信息页面（同人员队伍）。
编辑：同“人员队伍”编辑页。
移除：点击“确定移除该人员吗？”点击确定，该人员从该组移除。 -->
            <el-table :data="tableData" stripe v-if="tableData.length !== 0" ref="multipleTable" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="40">
              </el-table-column>
              <el-table-column align="center" type="index" label="序号" min-width="40">
              </el-table-column>
              <el-table-column align="left" label="人员姓名" min-width="100">
                <template slot-scope="scope">
                  <div class="personnel-row" @click="showDetail(scope.row)">
                    <el-tooltip class="item" effect="dark" :content="scope.row.userName" placement="top" :disabled="!isShowTooltipWidth" popper-class="wp-visibilityPopper">
                      <p class="textOverOneLine" @mouseenter="visibilityChangeWidth($event)">
                        {{ scope.row.userName }}
                      </p>
                    </el-tooltip>

                    <el-tag effect="plain" size="mini" v-if="scope.row.roleId == 3">组长</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="left" prop="groupName" label="所在分组" min-width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="left" prop="commonGroupStr" label="常用分组" min-width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="left" prop="custName" label="单位名称" min-width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column fixed="right" label="操作" min-width="150" align="center" v-if="roleId != 3">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <el-tooltip popper-class="wp-btn-tooltip" :visible-arrow="false" effect="dark" content="编辑" placement="top" :enterable="false">
                      <el-button type="text" icon="icon-bianji1" class="wp-btn-icon-big" @click="eidtUser(scope.row)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="移除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
                      <el-button type="text" icon="icon-yichu" class="wp-btn-icon-big" @click="delUser(scope.row)">
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="query.page">
          </wp-pagination>
        </div>
      </el-scrollbar>
    </div>
    <add-group ref="addGroup" :currentData="currentData" @success="getAsideList"></add-group>
    <CommonUse ref="commonUse" @success="refresh" :userIds="userIds" :isUpdateCommonGroup="true" :isEidt="true"></CommonUse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AsideList from '@/views/personnelTeam/components/commonAsideList'
import AddGroup from '@/views/personnelTeam/components/addGroupCommon.vue'
import SearchCard from '@/views/components/searchCard'
import * as personnelTeamApi from '@/api/personnelTeam.js'
import reportedApi from '@/api/reported.js'
import { resetNum } from '@/utils/validate.js';
import CommonUse from '@/views/personnelTeam/components/commonUse.vue'
export default {
  name: 'panel',
  components: { AsideList, SearchCard, AddGroup, CommonUse },
  data () {
    return {
      loading: false,
      loadingTree: false,
      asideList: [], // 侧边栏专题列表
      searchQuery: {
        userName: "",
        custName: "",
        groupIds: [],
      },
      status: 2,
      tableData: [],
      parentID: '',
      currentID: '',
      commonId: '', // 当前选中的树节点
      commonName: '全部', //右侧的上方的节点名称
      parentId: '', //上级分组ID
      query: {
        page: 1,
        limit: 30,
        userName: "",
        custName: "",
        groupIds: [],
      },
      total: 0,
      // 组员人数。组长人数
      memberCount: 0,
      groupCount: 0,
      searchTreeQuery: {
        commonName: ''
      },
      nodeObj: {},
      allObj: { commonId: '', commonName: '全部', isAddUser: false },
      userStarsList: [],// 星级下拉列表
      isShowTooltipWidth: false,
      multipleSelection: [],
      userIds: [],
      currentData: null,// 要修改的当前组
      groupList: [], // 分组树
      groupIdsAll: [],
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      finalQuery:{}
    };
  },
  computed: {
    ...mapGetters(['sysUserInfo', 'roleId'])
  },
  props: {},
  created () {
  },
  mounted () {
    this.getAsideList(1)
    this.getGroupTree()
    this.refresh()
  },
  methods: {
    // 搜索树
    searchTree (data) {
      this.searchTreeQuery.commonName = data
      this.getAsideList()
    },
    // 个人信息
    showDetail (item) {
      this.$router.push({ name: 'peopleInformation', query: { userId: this.$encrypt(item.userId) } })
    },
    // 左侧树节点 点击后的事件
    nodeClick (data) {
      // 重置选中的树节点id
      let newData = JSON.parse(JSON.stringify(data))
      if (data.commonId.toString().length == 0) {
        // 选中全部
        newData = JSON.parse(JSON.stringify(this.allObj))
      }
      this.commonId = newData.commonId
      // 重置右侧的上方的节点名称
      this.commonName = newData.commonName
      this.nodeObj = newData
      this.refresh()

    },
    // 添加分组
    addGroup () {
      this.currentData = null
      this.$refs.addGroup.show()
    },
    // 获取侧边栏列表
    async getAsideList (frequency) {
      let query = { commonName: this.searchTreeQuery.commonName }
      if (this.loadingTree) return
      this.loadingTree = true
      try {
        let res = await personnelTeamApi.wpCommonGroupList(query)
        this.loadingTree = false
        if (Number(res.returnCode) !== 200) return
        let flag = false // 当前选中的节点是否在树中
        res.returnData.forEach((element, ind) => {
          if (element.commonId == this.commonId) {
            this.commonName = element.commonName
            flag = true
          }
          // 上一个id 下一个id
          element.preGroupId = res.returnData[ind - 1]?.commonId || ''
          element.nextGroupId = res.returnData[ind + 1]?.commonId || ''
          // 是否显示上移和下移的按钮 默认显示
          element.isShiftUp = true
          element.isShiftDown = true
          if (ind == 0) { element.isShiftUp = false }
          if (ind == res.returnData.length - 1) { element.isShiftDown = false }
        });
        this.asideList = res.returnData
        if (flag && this.commonId) {
          this.$nextTick(() => {
            this.$refs.asideList.setCheck(true)
          })
        }
        // 设置默认选中的节点
        if (frequency == 1 && this.commonId) {
          this.$nextTick(() => {
            this.$refs.asideList.setCurrentKey(this.commonId)
          })
        }
      } catch (error) {
        this.loadingTree = false
        console.log(error)
      }
    },
    // 点击侧边栏单项数据
    handlerAsideItem (item) {

    },
    // 对侧边栏单项数据进行操作
    handlerItem (data) {
      console.log(data)
      if (data.type == 'edit') {
        // 修改名称
        this.currentData = data
        this.$refs.addGroup.show()
      } else if (data.type == 'remove') {
        // 删除
        this.delTreeNode(data)
      } else if (data.type == 'shiftUp') {
        // 上移
        this.sortTree({ moveId: data.commonId, movedId: data.preGroupId })
      } else if (data.type == 'shiftDown') {
        // 下移
        this.sortTree({ moveId: data.commonId, movedId: data.nextGroupId })
      }
    },
    async sortTree (query) {
      console.log(query)
      try {
        let res = await personnelTeamApi.wpCommonGroupMoveOrder(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    // 删除树
    delTreeNode (data) {
      let tips = '删除后不可恢复'
      if (data.userCount && data.userCount > 0) {
        tips = "删除后组下成员会退出该组"
      }
      // 确定删除吗？（删除后不可恢复）
      this.$wpConfirm('删除', {
        tips: "确定删除该常用分组吗？",
        content: tips,
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postDelTreeNode(data)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelTreeNode (data) {
      try {
        let res = await personnelTeamApi.deleteCommonGroup({ commonId: data.commonId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        if (data.commonId == this.commonId) {
          // 设置默认选中的节点
          this.$nextTick(() => {
            this.$refs.asideList.setCheck(false)
          })
        }
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.refresh()
    },
    handleCurrentChange (val) {
      this.query.page = val
      // 获取接口
      this.getUserPageList()
    },
    // 搜索查询
    handlerSearch () {
      
      this.refresh()
    },
    // 刷新用户列表
    refresh () {
      // 页码重置为1
      this.query.page = 1
      // 获取接口
      this.getUserPageList()
    },
    delUser (item) {
      this.$wpConfirm('移除', {
        tips: "确定移除该人员吗？",
        content: "移除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelUser([item.userId])
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelUser (userIds) {
      try {
        let res = await personnelTeamApi.removeBatchUser({ userIds: userIds,commonGroupIds: this.nodeObj.commonId ? [this.nodeObj.commonId]:[]})
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        // 假如用户列表原本只有一个用户，那么删除后要刷新树，但是要是别的管理员也删除了呢？暂时都刷新树吧
        this.refresh()

        this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    // 获取用户列表
    async getUserPageList () {
      this.query = { ...this.query, ...this.searchQuery }
      let query = {
        commonId: this.commonId,
        ...this.query
      }
      console.log('this.searchQuery',this.searchQuery)
      this.finalQuery = JSON.parse(JSON.stringify({commonId: this.commonId,searchQuery:this.searchQuery}))
      console.log('this.finalQuery',this.finalQuery)
      if (this.loading) return
      this.loading = true
      try {
        let res = await personnelTeamApi.commonGrouppageUserList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        this.multipleSelection = []
        res.returnData.data.forEach(item => {
          item.roleName = this.$globalData.roleNames[item.roleId.toString()]
          item.commonGroupStr = ( item.commonGroups && item.commonGroups.length > 0 ) ? item.commonGroups.map(i=>i.commonGroupName).join(','):'-'
          if(this.roleId != this.$ENUM.SYSROLE.TEAMADMIN){
            item.groupName = `${item.groupParentName?item.groupParentName+'/':''}${item.groupName}`
          }
          
        })
        this.tableData = res.returnData.data
        this.total = res.returnData.totalCount
        // 组员人数。组长人数
        this.memberCount = res.returnData.memberCount
        this.groupCount = res.returnData.groupCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 编辑用户
    eidtUser (item) {
      this.$router.push({ name: 'addPeople', query: { userId: this.$encrypt(item.userId) } })
    },
    // 手机号重置 值
    resetNumMobile () {
      this.searchQuery.mobile = resetNum(this.searchQuery.mobile);
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    // 批量改组
    batchEidt () {
      if (this.multipleSelection.length == 0) return this.$showMessage({
        type: 'warning',
        msg: '请先勾选人员!'
      })
      this.userIds = this.multipleSelection
      this.$refs.commonUse.show()
    },
    // 批量移除
    batchDel () {
      if (this.multipleSelection.length == 0) return this.$showMessage({
        type: 'warning',
        msg: '请先勾选人员!'
      })
      this.userIds = this.multipleSelection
      this.$wpConfirm('移除', {
        tips: "确定将该批人员移除常用人员吗？",
        content: "移除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postDelUser(this.userIds)
      }).catch(() => {
      });
    },
    // 勾选事件
    handleSelectionChange (val) {
      this.multipleSelection = val.map(i => i.userId)
      console.log(this.multipleSelection)
    },
    /** 获取分组树 */
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },
    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchQuery.groupIds = [...new Set(idList)]
    }
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
