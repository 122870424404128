var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wp-w100" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "wp-breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: "/drillList" } } },
                    [_vm._v("投稿演练")]
                  ),
                  _c("el-breadcrumb-item", [
                    _vm._v(
                      "追加" + _vm._s(_vm.type == 1 ? "红方" : "蓝方") + "成员"
                    ),
                  ]),
                ],
                1
              ),
              _c("TwoStep", {
                ref: "twoStep",
                attrs: {
                  type: _vm.type,
                  selRedUserIds: _vm.selRedUserIds,
                  selBlueUserIds: _vm.selBlueUserIds,
                },
              }),
              _c(
                "div",
                { staticClass: "wp-task-card mt-16 wp-center" },
                [
                  _c(
                    "el-button",
                    { staticClass: "wp-button", on: { click: _vm.oneCancel } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "wp-button",
                      staticStyle: { "margin-left": "24px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextSteptwo },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }