<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="title-header" v-if="tabsList.length > 1">
        <div class="title-header-left">
          <UnitTab
            :tabsList="tabsList"
            :activeId="activeName"
            @handleClick="handleClick"
          ></UnitTab>
        </div>
      </div>

      <div class="main">
        <SearchCard title="优评列表" @handlerSearch="handlerSearch">
          <template v-slot:searchContent>
            <div class="wp-date-picker search-w392">
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                @change="changeTimeSection"
                type="datetimerange"
                v-model="timeSection"
                prefix-icon=""
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable
                range-separator="-"
                start-placeholder="请选择开始时间"
                end-placeholder="请选择结束时间"
              >
              </el-date-picker>
            </div>
            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.taskId"
              filterable
              clearable
              placeholder="请选择评选时段"
              @change="changeTaskId"
            >
              <el-option
                v-for="item in excellents"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.title"
              placeholder="请输入任务标题"
              clearable
            ></el-input>
            <el-input
              class="search-w190 wp-input"
              v-model.trim="searchParams.userName"
              placeholder="请输入人员姓名"
              clearable
            ></el-input>
            <el-select
              class="search-w190 wp-select"
              v-model="searchParams.status"
              clearable
              placeholder="请选择优评结果"
            >
              <el-option
                v-for="item in $globalData.EXCELLENT_REVIEW_STATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                上报数量：<span class="wp-blue">{{ total }}</span>
              </div>
            </div>
            <div class="flex">
              <el-button
                @click="exportFile"
                class="wp-button wp-btn-icon"
                size="medium"
                icon="icon-daochu"
                >导出
              </el-button>
              <el-button
                @click="handlerUpload"
                class="wp-button wp-btn-icon"
                type="primary"
                icon="icon-tianjia"
                size="medium"
                v-preventReClick
              >
                优评上报
              </el-button>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <!-- 信息列包括“序号、评选时段、任务标题、截图、评论内容、姓名、账号、评选结果、上报时间、操作” -->
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                min-width="80"
                label="序号"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="taskName"
                min-width="120"
                label="评选时段"
              >
              </el-table-column>
              <el-table-column max-width="50" min-width="10"> </el-table-column>
              <el-table-column
                align="left"
                prop="title"
                label="任务标题"
                min-width="160"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :tabindex="scope.$index"
                    :disabled="!isShowTooltipWidth"
                    :content="scope.row.title"
                    placement="top"
                  >
                    <div
                      class="textOverOneLine active-color"
                      @mouseenter="visibilityChangeWidth($event)"
                      @click="goDetail(scope.row)"
                    >
                      {{ scope.row.title }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="fileJson"
                width="150"
                label="截图"
              >
                <template slot-scope="scope">
                  <div class="active-color" @click.stop="goDetail(scope.row)">
                    <p
                      v-if="$utils.isImg(scope.row.fileJson)"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>

                    <img
                      v-else
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                prop="content"
                label="评论内容"
                min-width="120"
                max-width="130"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="userName"
                label="姓名"
                min-width="120"
                max-width="130"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="loginName"
                label="账号"
                min-width="120"
                max-width="130"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="评选结果"
                min-width="90"
              >
                <template slot-scope="scope">
                  <div
                    class="flex-center"
                    v-if="
                      scope.row.status == $globalData.EXCELLENT_STATUS.EXCELLENT
                    "
                  >
                    <div class="success-circle"></div>
                    <div class="success-color">优评</div>
                  </div>
                  <div class="flex-center" v-else>
                    <div class="disabled-color">-</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="上报时间"
                min-width="150"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                fixed="right"
                width="90"
              >
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div class="wp-action-icon">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="编辑"
                        placement="top"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                      >
                        <el-button
                          @click="editItem(scope.row)"
                          type="text"
                          icon="icon-bianji1"
                          class="wp-btn-icon-big"
                          :class="!scope.row.edit ? 'no-drop' : ''"
                          :disabled="!scope.row.edit"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div class="wp-action-icon">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                      >
                        <el-button
                          @click="delItem(scope.row)"
                          type="text"
                          icon="icon-shanchu"
                          class="wp-btn-icon-big"
                          :class="!scope.row.edit ? 'no-drop' : ''"
                          :disabled="!scope.row.edit"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
    <ExcellentDialog
      ref="excellentDialog"
      @success="handlerSearch"
      :excellentObj="excellentObj"
      :createId="userId"
    ></ExcellentDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UnitTab from './components/unitTab/unitTab.vue';
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import ExcellentDialog from './components/excellentDialog'
import { excellentList, deleteEexcellentArticle, excellentPageList, exportExcellentByPage } from '@/api/excellentReport.js'
export default {
  components: { SearchCard, TaskTimeSelect, UnitTab, ExcellentDialog },
  data () {
    return {
      activeName: "0",
      total: 0,
      searchParams: {
        page: 1,
        limit: 30,
        taskId: '',
        taskName: '',
        title: '',
        userName: '',
        startTime: "",
        endTime: "",
        status: '',
        createId: ''
      },
      tableData: [],
      isShowTooltipWidth: false,
      loading: false,
      timeSection: [],
      excellentObj: {},
      searchParamsTemp: {},
      excellents: [],
      isExporting: false,
      dateTime: '' // TODO
      //dateTime:'2024-03-05 23:59:59' // TODO 模拟数据
    }
  },
  created () {
    //
  },
  mounted () {
    this.activeName = this.tabsList.length == 1 ? this.sysCust.custId : this.tabsList[0].custId
    this.searchParams.createId = this.userObj.userId
    // 获取任务列表
    this.getTaskList()
    this.getExcellentList()
  },
  computed: {
    ...mapGetters(['userList', 'userInfo', 'isWpys', 'wpyUnitTabs', 'sysCust', 'reviewCustList', 'excellentCustList']),
    tabsList () {
      let tabs = this.excellentCustList.map(item => { return { ...item, custId: item.custId.toString(), userId: item.userId.toString(), label: item.custName, news: 0 } })
      return tabs
    },
    userObj () {
      return this.tabsList.length == 1 ? this.userInfo : this.tabsList.find(it => this.activeName == it.custId)
    },
    userId () {
      return this.userObj?.userId
    },

  },
  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    /** tab点击回调 */
    handleClick (tab) {
      this.activeName = tab
      this.searchParams.page = 1
      this.searchParams.limit = 30
      // 恢复初始值
      this.searchParams.taskId = ""
      this.searchParams.taskName = ""
      this.searchParams.title = ""
      this.searchParams.userName = ""
      this.searchParams.startTime = ""
      this.searchParams.endTime = ""
      this.searchParams.status = ""
      this.searchParams.createId = this.userId
      this.timeSection = []
      this.getExcellentList()
      // 获取任务列表
      this.getTaskList()
    },

    /** 搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getTaskList()
    },
    refresh () {
      this.searchParams.page = 1
      this.getTaskList()
    },
    /** 获取任务列表 */
    async getTaskList () {
      if (this.loading) return
      this.loading = true
      this.searchParamsTemp = JSON.parse(JSON.stringify(this.searchParams))
      try {
        const res = await excellentPageList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          ret.data.forEach(item => {
            // 每月5日前才可以编辑/删除上月时段优评的上报内容（若该数据状态评为“优评”也不可以编辑/删除），
            // 超过时间则不可以操作。（例如11月5日24点前可以编辑10月优评时段上报的内容）。
            item.edit = this.isBetweenFirstFiveDaysOfMonth(this.dateTime) && item.status == 1 && this.isBetweenFirstFiveDaysOfMonth(item.createTime)
            // 模拟数据
            //item.edit = true
          })
          this.tableData = ret.data
          this.total = ret.totalCount
        }
      } catch (error) {
        this.loading = false
      }

    },

    /** 跳转详情 */
    goDetail (item) {
      this.$router.push({
        path: "/excellentDetail",
        query: {
          id: this.$encrypt(item.id.toString())
        }
      })
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTaskList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTaskList()
    },
    /** 导出 */
    async exportFile (event) {
      if (this.total > 30000) return this.$showMessage({
        type: 'warning',
        msg: '最多导出30000条，请分批导出'
      })
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await exportExcellentByPage({ ...this.searchParamsTemp })
        this.isExporting = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },
    handlerUpload () {
      // 每个优评时段最多提交5篇，若已达到5篇，则提示“每月优评最多上报5条，已达上限”

      // 该按钮仅在每月1日0点-5日24点间才可以操作，不在时间段范围则点击提示“请在每月1-5日上报”
      // 2024-01-05 23:59:59
      // 2024-01-01 00:00:00
      // 2024-01-06 00:00:00
      if (this.isBetweenFirstFiveDaysOfMonth(this.dateTime)) {
        this.excellentObj = {}
        this.$refs.excellentDialog.show()
      } else {
        this.$showMessage({ type: 'warning', msg: '请在每月1-5日上报' })
      }

    },
    editItem (item) {
      this.excellentObj = item
      this.$refs.excellentDialog.show()
    },
    delItem (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postDelItem(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelItem (item) {
      try {
        const res = await deleteEexcellentArticle({ id: item.id })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.loading = false
      }
    },
    // 判断是否可操作
    canEditItem (item) {
      // 每月5日前才可以编辑/删除上月时段优评的上报内容（若该数据状态评为“优评”也不可以编辑/删除）
      return true
    },
    changeTimeSection (newVal) {
      if (newVal) {
        this.searchParams.startTime = newVal[0]
        this.searchParams.endTime = newVal[1]
      } else {
        this.timeSection = ""
        this.searchParams.startTime = ""
        this.searchParams.endTime = ""
      }
    },
    isBetweenFirstFiveDaysOfMonth (date) {
      const currentMonth = this.$moment().month() + 1;
      const dataMonth = date ? this.$moment(date).month() + 1 : currentMonth;
      console.log('isBetweenFirstFiveDaysOfMonth', date, this.$moment(date).month() + 1)
      if (currentMonth != dataMonth) return false
      // 将传入的日期转换为Moment对象  
      const inputDate = date ? this.$moment(date) : this.$moment(new Date());
      // 获取当前月的第一天  
      const firstDayOfMonth = inputDate.clone().startOf('month');;

      // 获取当前月的第五天  
      const fifthDayOfMonth = firstDayOfMonth.clone().add(4, 'days');


      // console.log('inputDate',inputDate)
      // console.log('fifthDayOfMonth',fifthDayOfMonth)
      // console.log('firstDayOfMonth',firstDayOfMonth)
      // console.log('inputDate=======',inputDate.isBetween(firstDayOfMonth,fifthDayOfMonth, 'day', '[]'))
      // 判断传入的日期是否在当前月的第一天和第五天之间  
      return inputDate.isBetween(firstDayOfMonth, fifthDayOfMonth, 'day', '[]');
    },
    // 优评分类
    async getExcellentList () {
      try {
        const res = await excellentList({ createId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.excellents = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    changeTaskId (val) {
      if (val) {
        const item = this.excellents.find(it => it.id == val)
        this.searchParams.taskName = item.taskName
      } else {
        this.searchParams.taskName = ''
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>