import { post, get, getImg } from '@/utils/requestTwo'

// 网评员首页-最新待办
export function newTask (query) {
	return post(`/index/newTask`, query)
}

// 网评员首页-任务、上报、演练统计
export function summaryReportNum (query) {
	return post(`/index/summaryReportNum`, query)
}
//统计-任务分月完成(网评员)

export function taskFinsh (query) {
	return post(`/index/taskFinsh`, query)
}



