<template>
  <div class="wp-question-mark">
    <el-tooltip
      class="item"
      effect="dark"
      :content="content"
      :placement="placement"
      :visible-arrow="false"
      popper-class="tooltip"
    >
      <i :class="iconClass" class="icon-style"></i>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  name: 'WpQuestionMark',
  components: {},
  data () {
    return {

    };
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'top'
    },
    iconClass: {
      type: String,
      default: 'icon-wenhao'
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.icon-style {
  color: #999999;
}
</style>

<style lang="scss">
.tooltip {
  min-width: auto !important;
  max-width: 360px !important;
  color: #333 !important;
  line-height: 21px;
  background: #fff !important;
  box-shadow: 0px 0px 5px 0px rgba(3, 109, 255, 0.2);
  border-radius: 4px;
  font-size: 14px;
}
</style>
