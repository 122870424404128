import { post, get } from '@/utils/requestTwo'

// 系统字典-任务站点 | 任务完成方式
export function sysDictList (query) {
  return post(`/sysDict/list`, query)
}

// 模型管理-任务方式
export function taskStyleList (query) {
  return post(`/sysDictRel/taskStyleList`, query)
}


// 获取单位默认参数
export function sysCustConfigList (query) {
  return post(`/sysCustConfig/pageList2`, query)
}

// 修改单位参数配置（不需要加密
export function sysCustConfigBatchUpdate (query) {
  return post(`/sysCustConfig/batchUpdate`, query, false)
}

// 单位列表
export function sysCustList (query) {
  return post(`/sysCust/pageList`, query, false)
}

// 单位新增
export function saveSysCust (query) {
  return post(`/sysCust/insert`, query, false)
}

// 获取单位
export function getSysCust (custId) {
  return get(`/sysCust/get/${custId}`)
}

// 获取单位配置
export function getSysCustOption (custId) {
  return post(`/sysCustOption/getCustId/${custId}`)
}

// 单位编辑
export function updateSysCust (query) {
  return post(`/sysCust/update`, query, false)
}



//模型列表分页查询
export function sysModelConfigList (query) {
  return post(`/sysModelConfig/pageList`, query)
}

//模型添加
export function insertSysModelConfig (query) {
  return post(`/sysModelConfig/insert`, query, false)
}

//模型修改
export function updateSysModelConfig (query) {
  return post(`/sysModelConfig/update`, query, false)
}

//模型单个查询
export function getSysModelConfig (query) {
  return post(`/sysModelConfig/get`,query)
}

// //单位删除
// export function updateSysModelConfig (id) {
//   return get(`/sysModelConfig/get/${id}`)
// }

//单位模型删除
export function deleteSysModelConfig (id) {
  return post(`/sysModelConfig/delete/${id}`)
}



//用户管理-列表
export function sysUserList (query) {
  return post(`/sysUser/pageList`, query, false)
}
//用户管理-新增
export function insertAdmin (query) {
  return post(`/sysUser/insertAdmin`, query, false)
}

//用户管理-修改
export function updateAdmin (query) {
  return post(`/sysUser/updateAdmin`, query, false)
}

//用户管理-重置密码
export function resetPassAdmin (query) {
  return post(`/sysUser/resetPassAdmin`, query)
}

//用户管理-获取单个用户
export function getUserAdmin (query) {
  return post(`/sysUser/getUserAdmin`, query)
}

//短信管理-充值列表
export function sysSmsChargeList (query) {
  return post(`/sysSmsCharge/pageList`, query)
}

//短信管理-新增充值
export function insertSysSmsCharge (query) {
  return post(`/sysSmsCharge/insert`, query, false)
}

//短信管理-短信日志
export function sysSmsChargeLog (query) {
  return post(`/sysSmsCharge/log/pageList`, query, false)
}

//修改个人信息（不需要加密
export function editPersonInfo (query) {
  return post(`/sysUser/editPersonInfo`, query, false)
}

//获取赣州网站列表
export function sysCustWebsiteList (query) {
  return post(`/sysCustWebsite/list`, query)
}



