var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wp-task-card" }, [
    _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("添加链接")]),
    _c(
      "div",
      { staticClass: "textarea" },
      [
        _c("el-input", {
          attrs: {
            type: "textarea",
            placeholder: "每一行一条链接，多个链接用回车换行进行输入",
            rows: 21,
          },
          model: {
            value: _vm.batchContent,
            callback: function ($$v) {
              _vm.batchContent = $$v
            },
            expression: "batchContent",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }