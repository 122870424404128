<template>
  <div>
    <SearchContent @handlerSearch="handlerSearch">
      <template v-slot:searchContent>
        <TaskTimeSelect
          :days="30"
          :activeType="searchParams.timeType"
          :taskType="timeType"
          @timeSelect="timeSelect"
          ref="taskTime"
        >
        </TaskTimeSelect>

        <el-cascader
          ref="cascaderRef"
          class="search-w190 wp-cascader"
          :options="groupArr"
          @change="changeCascader"
          :props="defaultProps"
          collapse-tags
          placeholder="请选择所在分组"
          clearable
        ></el-cascader>

        <el-select
          class="search-w190 wp-select wp-multiple"
          v-model="searchParams.topicTypeIds"
          multiple
          collapse-tags
          filterable
          placeholder="请选择专题分类"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.topicTypeId"
            :label="item.topicTypeName"
            :value="item.topicTypeId"
          >
          </el-option>
        </el-select>

        <wpLoadSelect
          class="search-w392 wp-select"
          v-model="searchParams.topicId"
          :data="topicList"
          :page="topicObj.page"
          dictLabel="title"
          dictValue="topicId"
          dictTime="createTime"
          :hasMore="topicObj.more"
          :request="getData"
          @clearTitle="() => (topicObj.title = '')"
          placeholder="请输入专题名称"
        ></wpLoadSelect>
      </template>
    </SearchContent>
  </div>
</template>

<script>
import reportedApi from '@/api/reported.js'
import SearchContent from '@/views/components/searchContent'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
export default {
  components: { TaskTimeSelect, SearchContent },
  props: {
    groupList: {
      type: Array,
      default: () => []
    },
    classifyList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      topicObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 专题名称
        limit: 10, // 页大小
        sort: 2,
      },
      topicList: [], // 专题下拉框列表
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        topicId: "", // 专题Id
        groupIds: [], // 组Id
        timeType: 2,
        topicTypeIds: []
      },
      timeType: [
        {
          name: "专题创建时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        }
      ],
      // 任务列表
      taskList: [],
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupArr: this.groupList, // 分组列表
    }
  },
  watch: {
    groupList: {
      handler (newVal) {
        this.groupArr = newVal
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 清空搜索区域
    clearParams () {
      for (const key in this.searchParams) {
        this.searchParams[key] = ""
      }
      // 专题分类
      this.searchParams.topicTypeIds = []
      this.searchParams.groupIds = []
      // 清空级联选择器选中的节点
      this.$refs.cascaderRef.$refs.panel.clearCheckedNodes()
      // 设置为空可以让节点不高亮显示
      this.$refs.cascaderRef.$refs.panel.activePath = []
      // 清空时间选择器
      this.$refs.taskTime.clearTime()
    },

    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.topicList = []
        this.topicObj = { ...this.topicObj, ...data }
      }

      const res = await reportedApi.topicSelectList({ ...this.topicObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 专题列表
        this.topicList = [...this.topicList, ...ret.data].reduce((prev, curr) => {
          obj[curr.topicId] ? '' : obj[curr.topicId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.topicObj.page < ret.totalPageNum) {
          this.topicObj.page++
          this.topicObj.more = true
        } else {
          this.topicObj.more = false
        }
      }
    },

    // 获取筛选数据
    getParams () {
      this.searchParams.timeType = 2
      return this.searchParams
    },

    // 搜索回调
    handlerSearch () {
      this.searchParams.page = 1
      this.searchParams.limit = 30
      this.$emit("changeSearch", this.searchParams)
      console.log(this.searchParams);
    },

    // 选择时间回调
    timeSelect (time) {
      this.searchParams.timeType = time.timeType
      this.searchParams.startTime = time.startTime
      this.searchParams.endTime = time.endTime
    }
  },
}
</script>

<style lang="scss" scoped>
</style>