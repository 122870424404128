<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
          <el-breadcrumb-item :to="{ path: '/drillList' }">投稿演练</el-breadcrumb-item>
          <el-breadcrumb-item>追加{{type == 1?'红方':'蓝方'}}成员</el-breadcrumb-item>
        </el-breadcrumb>
        <TwoStep ref="twoStep" :type="type" :selRedUserIds="selRedUserIds" :selBlueUserIds="selBlueUserIds"></TwoStep>

        <div class="wp-task-card mt-16 wp-center">
          <el-button @click="oneCancel" class="wp-button">取消</el-button>
          <el-button style="margin-left: 24px" class="wp-button" v-preventReClick type="primary" @click="nextSteptwo">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import taskApi from '@/api/task.js'
import * as  drillApi from '@/api/drill.js'
import TwoStep from './steps/twoStep'
export default {
  components: { TwoStep },
  data () {
    return {
      timeCount: 0,
      taskDetial: {},
      selRedUserIds: [],// 已选择的红方人员
      selBlueUserIds: [],// 已选择的蓝方人员
      type: 1,
      taskId: '',// 任务id
      selectUserIds: [] // 选择的人员
    }
  },

  computed: {
    // ...mapGetters(['sysUserInfo'])
  },

  mounted () {
  },

  created () {
    if (this.$route.query.id && this.$route.query.type) {
      this.type = this.$route.query.type
      this.taskId = this.$decrypt(this.$route.query.id)
      this.getDetailTask()
    } else {
      this.$router.push({ name: 'drillList' })
    }
  },

  methods: {
    // 验证第二步到第三步
    nextSteptwo () {
      this.selectUserIds = this.$refs.twoStep.getFormData().map(it => it.id)
      if (this.selectUserIds.length > 0) {
        this.submitTask()
      }
    },
    // 第一步取消回调
    oneCancel () {
      this.$router.push({ name: 'drillMembers', query: { id: this.$encrypt(this.taskId) } })
    },
    // 提交任务
    async submitTask () {
      // 判断红蓝方的 人员选择 情况
      if (this.selectUserIds.length == 0) {
        return this.$showMessage({ type: 'error', msg: `请选择${this.type == 1 ? '红' : '蓝'}方演练成员` })
      }

      // 提交
      if (this.isAdding) return
      this.isAdding = true
      try {
        let query = {
          id: this.taskId,
          type: this.type,
          userIds: this.selectUserIds
        }
        let res = await drillApi.addReceiver(query)
        this.isAdding = false
        if (res.returnCode != 200) return this.$showMessage({ type: 'error', msg: res.returnMsg })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.oneCancel()
      } catch (error) {
        this.isAdding = false
      }

    },
    // 获取详情
    async getDetailTask () {
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      if (res.returnCode != 200) return this.$showMessage({ type: 'error', msg: res.returnMsg })
      console.log(res.returnData)
      this.taskDetial = res.returnData.taskDetial
      this.selRedUserIds = res.returnData.redUserIds
      this.selBlueUserIds = res.returnData.blueUserIds
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>