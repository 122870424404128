var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parameterConfig" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _c("div", { staticClass: "layout-main" }, [
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [
                _vm._v("投稿中心-任务设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "ruleform",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "任务时间：",
                            required: "",
                            error: _vm.taskTimeValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              model: {
                                value: _vm.taskTimeValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taskTimeValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression: "taskTimeValue.configCheck",
                              },
                            },
                            [
                              _vm._l(_vm.taskTime, function (item) {
                                return [
                                  item.configValue != 999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "wp-input-select",
                                              attrs: {
                                                placeholder: "",
                                                maxlength: "2",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.resetNum(item)
                                                },
                                              },
                                              model: {
                                                value: item.configValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "configValue",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.configValue",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    slot: "append",
                                                    placeholder: "",
                                                  },
                                                  slot: "append",
                                                  model: {
                                                    value: item.configUnit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "configUnit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.configUnit",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "小时",
                                                      value: "1",
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "天",
                                                      value: "2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-radio",
                                        {
                                          key: item.configValue,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("月底")]
                                          ),
                                        ]
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "任务积分：",
                            required: "",
                            error: _vm.taskScoreValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              model: {
                                value: _vm.taskScoreValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taskScoreValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression: "taskScoreValue.configCheck",
                              },
                            },
                            _vm._l(_vm.taskScore, function (item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.custConfigId,
                                  attrs: { label: item.custConfigId },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "wp-short-input",
                                    attrs: { placeholder: "", maxlength: "4" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resetNumPoints(item)
                                      },
                                    },
                                    model: {
                                      value: item.configValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "configValue", $$v)
                                      },
                                      expression: "item.configValue",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "字数要求：",
                            required: "",
                            error: _vm.taskWordsRequireValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              model: {
                                value: _vm.taskWordsRequireValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taskWordsRequireValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression: "taskWordsRequireValue.configCheck",
                              },
                            },
                            [
                              _vm._l(_vm.taskWordsRequire, function (item) {
                                return [
                                  item.configValue == 9999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.configValue,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("不限")]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "wp-short-input",
                                            attrs: {
                                              placeholder: "",
                                              maxlength: "2",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.resetNumCounts(item)
                                              },
                                            },
                                            model: {
                                              value: item.configValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "configValue",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "item.configValue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单人上限：",
                            required: "",
                            error: _vm.taskSingleLimitValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              model: {
                                value: _vm.taskSingleLimitValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taskSingleLimitValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression: "taskSingleLimitValue.configCheck",
                              },
                            },
                            [
                              _vm._l(_vm.taskSingleLimit, function (item) {
                                return [
                                  item.configValue != 9999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "wp-short-input",
                                            attrs: {
                                              placeholder: "",
                                              maxlength: "2",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.resetNumPersons(item)
                                              },
                                            },
                                            model: {
                                              value: item.configValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "configValue",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "item.configValue",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-radio",
                                        {
                                          key: item.configValue,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("不限")]
                                          ),
                                        ]
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "layout-title layout-title2" }, [
                _vm._v("投稿中心-高级设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "advanced-form",
                      attrs: { "label-width": "100px" },
                    },
                    _vm._l(_vm.advancedSetting, function (item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.custConfigId,
                          attrs: { label: item.configName + "：" },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: item.isDisbled },
                              on: {
                                change: function ($event) {
                                  return _vm.checkboxChangeShow(item)
                                },
                              },
                              model: {
                                value: item.isShow,
                                callback: function ($$v) {
                                  _vm.$set(item, "isShow", $$v)
                                },
                                expression: "item.isShow",
                              },
                            },
                            [_vm._v("显示 ")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: item.isDisbled },
                              on: {
                                change: function ($event) {
                                  return _vm.checkboxChangeRequired(item)
                                },
                              },
                              model: {
                                value: item.isRequired,
                                callback: function ($$v) {
                                  _vm.$set(item, "isRequired", $$v)
                                },
                                expression: "item.isRequired",
                              },
                            },
                            [_vm._v("必填 ")]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "layout-title layout-title2" }, [
                _vm._v("投稿中心-其他设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "advanced-form",
                      attrs: { "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "短信通知：" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.taskSmsNotice,
                                callback: function ($$v) {
                                  _vm.taskSmsNotice = $$v
                                },
                                expression: "taskSmsNotice",
                              },
                            },
                            [_vm._v("默认选中 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [
                _vm._v("上报中心-专题设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "ruleform",
                      attrs: { "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "起止时间：",
                            required: "",
                            error: _vm.subjectStartEndTimeValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              model: {
                                value: _vm.subjectStartEndTimeValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.subjectStartEndTimeValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "subjectStartEndTimeValue.configCheck",
                              },
                            },
                            [
                              _vm._l(_vm.subjectStartEndTime, function (item) {
                                return [
                                  item.configValue != 999 &&
                                  item.configValue != 9999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "wp-input-select",
                                              attrs: {
                                                placeholder: "",
                                                maxlength: "2",
                                              },
                                              on: {
                                                change: _vm.resetNumReportIime,
                                              },
                                              model: {
                                                value: item.configValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "configValue",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.configValue",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    slot: "append",
                                                    placeholder: "",
                                                  },
                                                  slot: "append",
                                                  model: {
                                                    value: item.configUnit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "configUnit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.configUnit",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "小时",
                                                      value: "1",
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "天",
                                                      value: "2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.configValue == 999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("月底")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.configValue == 9999
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("不限")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "专题积分：",
                            required: "",
                            error: _vm.subjectScoreValue.error,
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "wp-radio-group",
                              on: { change: _vm.changeSubjectScoreValue },
                              model: {
                                value: _vm.subjectScoreValue.configCheck,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.subjectScoreValue,
                                    "configCheck",
                                    $$v
                                  )
                                },
                                expression: "subjectScoreValue.configCheck",
                              },
                            },
                            [
                              _vm._l(_vm.subjectScore, function (item) {
                                return [
                                  item.custConfigId != _vm.subjectScoreId
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: { label: item.custConfigId },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "wp-short-input",
                                            attrs: {
                                              placeholder: "",
                                              maxlength: "5",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.resetNumPoints2(item)
                                              },
                                            },
                                            model: {
                                              value: item.configValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "configValue",
                                                  $$v
                                                )
                                              },
                                              expression: "item.configValue",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.custConfigId == _vm.subjectScoreId
                                    ? _c(
                                        "el-radio",
                                        {
                                          key: item.custConfigId,
                                          attrs: {
                                            label: item.custConfigId,
                                            disabled: _vm.isDisbledPlatform,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "disable-input" },
                                            [_vm._v("按平台")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "layout-title layout-title2" }, [
                _vm._v("上报中心-高级设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "advanced-form advanced-checkboxs",
                      attrs: { "label-width": "100px" },
                    },
                    _vm._l(_vm.advancedSetting2, function (item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.custConfigId,
                          attrs: { label: item.configName + "：" },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: item.isDisbled },
                              on: {
                                change: function ($event) {
                                  return _vm.checkboxChangeShow2(item)
                                },
                              },
                              model: {
                                value: item.isShow,
                                callback: function ($$v) {
                                  _vm.$set(item, "isShow", $$v)
                                },
                                expression: "item.isShow",
                              },
                            },
                            [_vm._v("显示 ")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: item.isDisbledRequired },
                              on: {
                                change: function ($event) {
                                  return _vm.checkboxChangeRequired2(item)
                                },
                              },
                              model: {
                                value: item.isRequired,
                                callback: function ($$v) {
                                  _vm.$set(item, "isRequired", $$v)
                                },
                                expression: "item.isRequired",
                              },
                            },
                            [_vm._v("必填 ")]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "layout-title layout-title2" }, [
                _vm._v("上报中心-其他设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "advanced-form",
                      attrs: { "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发送短信：" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.subjectSmsNotice,
                                callback: function ($$v) {
                                  _vm.subjectSmsNotice = $$v
                                },
                                expression: "subjectSmsNotice",
                              },
                            },
                            [_vm._v("默认选中 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "layout-block wp-moudle-style" }, [
              _c("div", { staticClass: "layout-title" }, [
                _vm._v("投稿演练-演练设置"),
              ]),
              _c(
                "div",
                { staticClass: "layout-content" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "advanced-form",
                      attrs: { "label-width": "100px" },
                    },
                    _vm._l(_vm.drillSetting, function (item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.id,
                          attrs: {
                            label: item.configName + "：",
                            required: "",
                            error: item.error,
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "wp-short-input",
                            attrs: { placeholder: "", maxlength: "4" },
                            on: {
                              change: function ($event) {
                                return _vm.resetNumDrillSetting(item)
                              },
                            },
                            model: {
                              value: item.configValue,
                              callback: function ($$v) {
                                _vm.$set(item, "configValue", $$v)
                              },
                              expression: "item.configValue",
                            },
                          }),
                          _c("span", { staticClass: "nuit-block" }, [
                            _vm._v(_vm._s(item.unit)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "layout-block wp-moudle-style btn-block" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" },
                    ],
                    staticClass: "wp-button",
                    attrs: { size: "medium", type: "primary" },
                    on: { click: _vm.sure },
                  },
                  [_vm._v("确定 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }