<template>
  <div class="wpPagination">
    <div class="total-text">
      共<span>{{ total }}</span>条
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="pageSizes" :page-size="pageSize" :layout="layout" :total="total" :pager-count="5">
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: 'WpPagination',
  components: {},
  data () {
    return {

    };
  },
  props: {
    layout: {
      type: String,
      default: 'prev, pager, next, sizes'
    },
    total: {
      type: Number,
      default: 100
    },
    pageSize: {
      type: Number,
      default: 30
    },
    currentPage: Number,
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50]
    },
  },
  created () {

  },
  mounted () {

  },
  computed: {

  },
  methods: {
    handleSizeChange (val) {
      // console.log('handleSizeChange')
      // console.log(val)
      this.$emit('size-change', val)
    },
    handleCurrentChange (val) {
      // console.log('handleCurrentChange')
      // console.log(val)
      this.$emit('current-change', val)
    }
  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    //   handler (val, oldval) {
    //     // console.log(val, oldval);
    //     // if (val.meta.pPath && val.meta.pPath !== '/') {
    //     // 	this.menuSelect(val.meta.pPath);
    //     // } else {
    //     // 	this.menuSelect(val.path);
    //     // }
    //   },
    //   deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
.wpPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total-text {
    font-size: 14px;
    /*no */
    font-weight: 400;
    color: #666666;

    >span {
      color: #0064e6;
    }
  }

  /deep/.el-pager li {
    min-width: 32px;
    /*no */
    height: 32px;
    /*no */
    border-radius: 4px;
    /*no */
    border: 1px solid #cccccc;
    /*no */
    margin-right: 8px;
    /*no */
    line-height: 30px;
    /*no */
    font-size: 14px;
    /*no */
    color: #333333;
    font-weight: 400;

    &.active {
      border-color: $themeColor;
      color: $themeColor;
    }
  }

  /deep/.el-pagination {
    display: flex;
    align-items: center;
  }

  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next {
    width: 32px;
    /*no */
    height: 32px;
    /*no */
    border-radius: 4px;
    /*no */
    border: 1px solid #cccccc;
    /*no */
    color: #333333;
    padding: 0px;
    /*no */
    line-height: 30px;
    /*no */
    margin-right: 8px;
    /*no */
  }

  /deep/.el-pagination__sizes {
    // line-height: 30px;
    height: 32px;
    /*no */
    margin-right: 0px;
    /*no */

    .el-select {
      height: 32px;
      /*no */
      display: inline-block;
    }

    .el-input {
      height: 32px;
      /*no */
    }

    .el-input--mini .el-input__inner {
      height: 32px;
      /*no */
    }

    .el-input__inner {
      border-radius: 4px;
      /*no */
      border: 1px solid #CCCCCC;
      /*no */
      font-size: 14px;
      /*no */
      color: #333333;
    }
  }

  /deep/.el-pagination .el-select .el-input {
    margin: 0px;
    /*no */
  }
}
</style>
