var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-row flex-CB" },
    [
      _c(
        "div",
        { staticClass: "input-item" },
        _vm._l(_vm.fileArr, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "file-item",
              attrs: { title: item.fileName },
            },
            [
              _c("p", { staticClass: "textOverOneLine" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("img", {
                attrs: { src: require("@/assets/images/guanbi.png"), alt: "" },
                on: {
                  click: function ($event) {
                    return _vm.deleteFile(index)
                  },
                },
              }),
            ]
          )
        }),
        0
      ),
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "avatar-uploader",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "http-request": _vm.httpRequest,
            action: _vm.action,
            accept: _vm.accept,
            "show-file-list": false,
            "before-upload": _vm.handleBeforeUpload,
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "wp-button wp-btn-icon",
              attrs: {
                type: "primary",
                icon: "icon-daoru",
                size: "medium",
                disabled: _vm.fileArr.length >= _vm.fileMaxNum,
              },
            },
            [_vm._v(" 选择文件 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }