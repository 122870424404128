import Vue from 'vue'
import { encryptParams, Tool } from './jsencryptParams.js'
import router from '@/router'
import { baseURL } from './requestTwo.js'
import { fileImgs } from './global.js'
import * as imageConversion from 'image-conversion'
import store from '../store'
import ENUM from '@/utils/enum.js'
//重置表单，formRef为表单的ref值数组，excludeFields为要排除重新初始化值得属性
Vue.prototype.$reset = function (formRefs, excludeFields) {
	if (formRefs) {
		this.$refs[formRefs].resetFields();
	}
	let obj1 = this.$data;
	let obj2 = this.$options.data.call(this);
	if (!excludeFields || excludeFields.length === 0) {
		excludeFields = ["ruleValidate"];
	}
	for (let attrName in obj1) {
		if (excludeFields && excludeFields.includes(attrName)) {
			continue;
		}
		obj1[attrName] = obj2[attrName];
	}
};
// 吐司
// type:success  error 
//type, msg, duration
let mess = null
Vue.prototype.$showMessage = function (options) {
	let duration = options.duration ? options.duration : 1000
	let type = options.type ? options.type : 'success'
	let msg = options.msg ? options.msg : '操作成功'
	if (mess) {
		mess.close()
		mess = null
	}
	return mess = this.$message({
		type: type,
		customClass: 'wp-message',
		offset: 200,
		duration: duration,
		// dangerouslyUseHTMLString: true,
		message: msg
	})
}

// 按钮防抖
Vue.directive('preventReClick', {
	inserted: function (el, binding) {
		// console.log(el.disabled)
		el.addEventListener('click', () => {
			if (!el.disabled) {
				el.disabled = true
				setTimeout(() => {
					el.disabled = false
				}, binding.value || 1000)
				//binding.value可以自行设置。如果设置了则跟着设置的时间走
				//例如：v-preventReClick='500'
			}
		})
	}
});

// 判断文件类型
function setFileType (fileName) {
	if (!fileName) return
	const testmsg = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
	const img = ['jpg', 'jpeg', 'png']
	const mp4 = ['mp4']
	const word = ['doc', 'docx']
	const pdf = ['pdf']
	const xlx = ['xlsx', 'xls']
	const zip = ['zip', 'rar']
	let type = 'img'
	if (img.indexOf(testmsg) != -1) {
		type = 'img'
	} else if (mp4.indexOf(testmsg) != -1) {
		type = 'mp4'
	} else if (word.indexOf(testmsg) != -1) {
		type = 'word'
	} else if (pdf.indexOf(testmsg) != -1) {
		type = 'pdf'
	} else if (xlx.indexOf(testmsg) != -1) {
		type = 'xls'
	} else if (zip.indexOf(testmsg) != -1) {
		type = 'zip'
	}
	return type
}


export default {
	// 下载文件
	download (url) {
		if(!url) return
		window.open(url, '_blank')
	},
	// 预览图片点击遮罩关闭
	hideImg () {
		// 获取遮罩层dom
		setTimeout(function () {
			let domImageMask = document.querySelector('.el-image-viewer__wrapper');
			if (!domImageMask) {
				return;
			}

			domImageMask.addEventListener('click', e => {
				if (e.target.parentNode.className == 'el-image-viewer__actions__inner') {
					return; //如果点击底部菜单，不关闭
				}
				// console.log(e.target.parentNode.className)
				if (e.target.parentNode.className.indexOf('el-image-viewer__btn') != -1) {
					return; //如果点击按钮，不关闭
				}
				// 点击遮罩层时调用关闭按钮的 click 事件
				// document.querySelector('.el-image-viewer__close').click();
				// 点击遮罩层时调用关闭按钮的 click 事件
				if (document.querySelector('.el-image-viewer__close')) {
					document.querySelector('.el-image-viewer__close').click();
				}
			});
		}, 300);
	},
	// 预览图片 数组重排  index 是数组中需要排第一位置的元素下标
	arrayRearrangement (index, oldArray) {
		let newArray = []
		let afterlength = oldArray.length - index
		let prelength = oldArray.length - afterlength

		for (let i = index; i < oldArray.length; i++) {
			if (oldArray[i]) {
				newArray.push(oldArray[i])
			}
		}
		for (let i2 = 0; i2 < prelength; i2++) {
			if (oldArray[i2]) {
				newArray.push(oldArray[i2])
			}
		}

		return newArray
	},
	/** 参数加密 */
	paramsEncrypt (params = {}) {
		for (let prop in params) {
			// 非'' 非null 和 非 对象的 加密
			if (params[prop] != null && params[prop].toString().length > 0 && Object.prototype.toString.call(params[prop]) !== '[object Object]') {
				params[prop] = encryptParams(params[prop])
			}
		}
	},
	/** 获取用户信息 */
	getUserInfo (encrypt = true) {
		try {
			let cipherText = localStorage.getItem('WP_USERINFO')
			if (encrypt) {
				cipherText = Tool.decode(cipherText)

			}
			return cipherText
		} catch (e) {
			router.push('/login')
		}
	},
	/** 登录设置用户信息 */
	setUserInfo (obj, encrypt = true) {
		let cipherText = JSON.stringify(obj)
		if (encrypt) {
			cipherText = Tool.encode(cipherText).requestDataEnc
			// console.log(cipherText)
			if (!cipherText) {
				router.push('/login')
			}
		}
		localStorage.setItem('WP_USERINFO', cipherText)
	},
	/** 退出删除用户信息 */
	delUserInfo () {
		localStorage.removeItem('WP_USERINFO')
	},
	//将数组分成几组 array原数组，subGroupLength 一个数组的长度
	supGroup (array, subGroupLength) {
		let index = 0;
		let newArray = [];
		while (index < array.length) {
			newArray.push(array.slice(index, (index += subGroupLength)));
		}
		return newArray;
	},

	/**
	 * 
	 * @param {Array} arr 完整数组
	 * @param {Array} byArr 需要查询的数组
	 */
	isContained (arr, byArr) {
		console.log(arr, byArr);
		if (!(arr instanceof Array) || !(byArr instanceof Array)) return false
		const len = byArr.length
		// 任务方式的长度 小于 选中任务方式的长度,直接返回false
		if (arr.length < len) return false
		for (let i = 0; i < len; i++) {
			// 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
			if (!arr.includes(byArr[i])) return false;
		}
		// 遍历结束，返回true
		return true;
	},

	/** 截取小数点前的所有数字 */
	subpoint (str) {
		console.log(str)
		const text = str + ''
		return text.substring(0, str.indexOf('.'))
	},
	// 飞球
	setBall (event) {
		if (document.getElementById('ball') !== null) {
			document.getElementById('panel').removeChild(document.getElementById('ball'))
		}
		let $xiazai = document.getElementById('xiaZai').getBoundingClientRect()
		let $ball = document.createElement('div')
		$ball.id = 'ball'
		let evt = event
		console.log(evt.pageX, evt.pageY)
		$ball.style.top = evt.pageY + 'px';
		$ball.style.left = evt.pageX + 'px';
		$ball.style.transition = 'all 0s, top 0s';
		$ball.style.opacity = 1;
		document.getElementById('panel').appendChild($ball)
		setTimeout(() => {
			$ball.style.top = $xiazai.top + 'px';
			$ball.style.left = $xiazai.left + 'px';
			$ball.style.transition = 'all 1s linear, top 1s ease-in';
			$ball.style.width = 0;
			$ball.style.height = 0;
		}, 20)
	},
	// 小数 转换为 百分比，不保留小数
	changeDecimalToPercentage (data) {
		let data1 = (data * 100).toFixed(0)
		return data1
	},
	// 超出8个字符串，超出中间...“我是人名...我是人名”
	sliceMiddle (str, strLength = 8, minLength = 4) {
		if (!str) return ''
		let newStr = str.toString()
		if (newStr.length > strLength) {
			let pre4str = newStr.slice(0, minLength)
			let next4str = newStr.slice(-minLength)
			return `${pre4str}...${next4str}`
		} else {
			return newStr
		}
	},
	// 根据宽度计算文字 是否超出容器
	// 根据高度计算文字 是否超出容器
	visibilityChange (event, unit = 'width') {
		const ev = event.target;
		let ev_weight = 0
		let content_weight = 0
		if (unit == 'width') {
			ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
			content_weight = ev.clientWidth;// 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
		} else {
			ev_weight = ev.scrollHeight; // 文本的实际高度 。
			content_weight = ev.clientHeight;// 文本的可视高度
		}
		// const content_weight = this.$refs.tlp.$el.parentNode.clientWidth; // 文本容器宽度(父节点)
		if (ev_weight > content_weight) {
			// 实际宽度 > 可视宽度  文字溢出
			return true
		} else {
			// 否则为不溢出
			return false
		}
	},
	// 重置时间格式
	resetTime (time) {
		console.log(time);
		return time.replace(/-/g, '/')
	},
	// 计算文本实际宽度
	getTextWidth (textString, fontStyle) {
		const text = textString
		const font = fontStyle
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		context.font = font;
		const {
			width
		} = context.measureText(text);
		// console.log(width)
		return width
	},

	// 计算百分比
	getPercent: function (num, total) {
		num = parseFloat(num);
		total = parseFloat(total);
		if (isNaN(num) || isNaN(total)) {
			return '-';
		}
		return total <= 0 ? '0' : (Math.round((num / total) * 10000) / 100.0).toFixed(0);
	},

	isImg (list) {
		let item = ""
		if (typeof list == 'string') {
			item = JSON.parse(list)?.[0]
		} else {
			item = list?.[0]
		}
		if (setFileType(item?.name) == 'img' || setFileType(item?.name) == 'mp4') {
			return true
		}
	},

	previewImg (list) {
		let item = ""
		if (typeof list == 'string') {
			item = baseURL + '/file/' + JSON.parse(list)?.[0]?.id
		} else {
			item = baseURL + '/file/' + list?.[0]?.id
		}
		return item
	},

	// 处理图片地址
	handlePreviewImg (list) {
		let item = ""

		if (typeof list == 'string') {
			item = JSON.parse(list)?.[0]
		} else {
			item = list?.[0]
		}
		if (!item?.id) return
		let url = ""

		item.type = setFileType(item.name)

		switch (item.type) {
			case 'img':
				url = baseURL + '/file/' + item.id
				break;
			case 'mp4':
				url = baseURL + '/file/' + item.picId
				break;
			case 'xls':
				url = fileImgs.xls
				break;
			case 'word':
				url = fileImgs.word
				break;
			case 'zip':
				url = fileImgs.zip
				break;
			case 'pdf':
				url = fileImgs.pdf
				break;
			default:
				url = fileImgs.zip
				break;
		}
		return url
	},

	// 隐藏手机号中间四位数
	replacePhone (phone) {
		return phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
	},
	//清空cookie
	clearCookie () {
		var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
		if (keys) {
			for (var i = keys.length; i--;) {
				document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
				document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
				document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
			}
		}
		console.log('已清除');
	},
	uuid () {
		const tempUrl = URL.createObjectURL(new Blob());
		const uuid = tempUrl.toString();
		URL.revokeObjectURL(tempUrl);  // 释放这个url
		return uuid.substring(uuid.lastIndexOf("/") + 1)
	},

	setFileFileJson (files) {
		let fileJson = []
		if (files?.length) {
			files.forEach((item) => {
				item.type = setFileType(item.name)
				fileJson.push(item)
			})
		}
		return fileJson
	},

	JsonParse (file) {
		console.log(file);
		let list = []
		if (typeof file == 'string') {
			list = file && JSON.parse(file) || []
		} else {
			list = file || []
		}
		console.log(list);
		return list
	},
	// 处理图片地址
	handlePreviewImg2 (item) {
		let url = ""

		item.type = setFileType(item.name)

		switch (item.type) {
			case 'img':
				url = fileImgs.img
				break;
			case 'mp4':
				url = fileImgs.mp4
				break;
			case 'xls':
				url = fileImgs.xls
				break;
			case 'word':
				url = fileImgs.word
				break;
			case 'zip':
				url = fileImgs.zip
				break;
			case 'pdf':
				url = fileImgs.pdf
				break;
			default:
				url = fileImgs.zip
				break;
		}
		return url
	},

	// 演练头像更换域名
	handleAvatar (arr = []) {
		return arr.map(item => {
			item.imgUrl = 'http://open.hnroger.com/wpinterface/weibo/img?url=' + item.imgUrl
			return item
		})
	},
	// 压缩图片
	handlerImageCompres (file) {
		const fileType = setFileType(file.name)
		let roles = [ENUM.SYSROLE.GROUPLEADER,ENUM.SYSROLE.TEAMMEMBERS]
		return new Promise((resolve, reject) => {
			if (fileType === 'img' && file.size / 1024 > 200 && roles.indexOf(store.getters.roleId) != -1) {
				// 超过200kb的压缩
				imageConversion.compressAccurately(file, 30).then((compressedFile) => {
					resolve(compressedFile)
				}).catch(error => {
					reject(error)
				})
			} else {
				resolve(file)
			}
		})

	}
}
