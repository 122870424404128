<template>
  <div class="download-list">
    <div class="download-top">
      <p>下载中心</p>
      <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big" @click="delAll">清空所有</el-button>
    </div>
    <div class="download-box" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
      <ul>
        <li class="download-li" v-for="item in downList" :key="item.downloadId">
          <div class="download-left">
            <div class="file-img">
              <img v-if="item.type == 'xls' || item.type == 'xlsx'" src="~@/assets/images/file/excel2.png" alt="" />
              <img v-else-if="item.type == 'docx'" src="~@/assets/images/file/word2.png" alt="" />
              <img v-else-if="item.type == 'zip'" src="~@/assets/images/file/zip.png" alt="" />
              <img v-else src="~@/assets/images/file/defult.png" alt="" />

            </div>
            <div class="file-info">
              <div class="file-title textOverOneLine">
                {{ item.fileName }}
              </div>
              <div class="file-pro">
                <div class="file-pro-left">
                  <div class="progress-bar">
                    <!-- <el-progress :show-text="false" :percentage="item.percentage" color="#0064E6" :stroke-width="4">
                    </el-progress> -->
                    <el-progress :show-text="false" class="progress-ani" v-if="item.downStatus == 3 || item.downStatus == 4" :percentage="100" color="#0064E6" :stroke-width="4">
                    </el-progress>
                    <el-progress :show-text="false" v-if="item.downStatus == 1 || item.downStatus == 0" :percentage="item.percentage" color="#0064E6" :stroke-width="4">
                    </el-progress>
                  </div>
                  <div class="progress-name" v-if="item.downStatus == 3 || item.downStatus == 4">文件生成中</div>
                  <div class="progress-name" v-if="item.downStatus == 1 || item.downStatus == 0">{{item.percentage}}%</div>
                </div>
                <div class="file-pro-right">
                  {{ $moment(item.createTime).format('YYYY-MM-DD HH:mm') }}
                </div>
              </div>
            </div>
          </div>
          <div class="download-right">
            <el-tooltip class="item" effect="dark" content="下载" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
              <el-button type="text" icon="icon-xiazai1" v-if="item.downStatus == 1 || item.downStatus == 0" class="wp-btn-icon-big" @click="downItem(item)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" popper-class="wp-btn-tooltip" :visible-arrow="false" :enterable="false">
              <el-button type="text" icon="icon-shanchu" class="wp-btn-icon-big" @click="delItem(item)"></el-button>
            </el-tooltip>
          </div>
        </li>
      </ul>
      <div class="wp-noData" v-if="downList.length == 0">
        <img src="~@/assets/images/noData.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <div class="page-row">
      <wp-pagination :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange" :pageSize="5" layout="prev, pager, next" :currentPage="query.page">
      </wp-pagination>
    </div>
  </div>
</template>

<script>
// 2.下载列表逻辑
//      1.用户点击导出-》 全局管理器获取下载列表，缓存列表数据，显示红点
//      2.头部组件监听显示红点状态，并更改，弹出下载消息提示，获取消息列表
//      3.用户点击下载图标后，隐藏红点和隐藏消息提示，显示下载列表
//      4.显示下载列表后，开始轮询获取下载列表，更新下载进度，及下载按钮的显示   5.在下载列表，下载功能，删除功能，批量删除功能
//      6.监听到下载列表隐藏后，停止轮询
// import { mapGetters } from 'vuex';
import { downloadPageList, deleteDownload, deleteAllDownload } from '@/api/app.js';
export default {
  name: 'download-list',
  components: {},
  data () {
    return {
      query: {
        page: 1,
        limit: 5,
      },
      loading: false,
      timer: null,
      downList: [],
      totalCount: 0,

    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {

  },
  methods: {
    downItem (item) {
      this.$utils.download(`${this.$axios.defaults.baseURL}/file/${item.fileId}`)
    },
    delItem (item) {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelItem(item)
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 删除所有
    delAll () {
      this.$wpConfirm('删除', {
        tips: "确定删除吗？",
        content: "删除后数据不可恢复",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        //点登录
        this.postDelAllItem()
        console.log("确认")
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    async postDelItem (item) {
      try {
        let res = await deleteDownload({ downloadId: item.downloadId })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    async postDelAllItem () {
      try {
        let res = await deleteAllDownload({})
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    handleSizeChange (val) {
      this.query.limit = val
      this.getDownloadPageList()
    },
    handleCurrentChange (val) {
      this.query.page = val
      this.getDownloadPageList()
    },
    refresh () {
      this.query.page = 1
      this.getDownloadPageList()
    },
    async getDownloadPageList () {
      let query = {
        ...this.query
      }
      if (this.totalCount == 0) {
        this.loading = true
      }
      try {
        let res = await downloadPageList(query)
        this.loading = false
        if (Number(res.returnCode) !== 200) return
        res.returnData.data.forEach(item => {
          if (item.downStatus == 1 || item.downStatus == 0) {
            item.percentage = 100
          } else if (item.downStatus == 3) {
            item.percentage = 50
          } else if (item.downStatus == 4) {
            item.percentage = 0
          }
          item.type = item.fileName.substring(item.fileName.lastIndexOf('.') + 1).toLowerCase();

        })
        this.downList = res.returnData.data
        this.totalCount = res.returnData.totalCount
        this.clearTimer()
        this.timer = setTimeout(() => { this.getDownloadPageList() }, 10000)
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    clearList () {
      this.totalCount = 0
      this.downList = []
      this.clearTimer()
    },
    clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

    }
  },
  beforeDestroy () {
    this.clearList()
  },
  watch: {
    // 监听下载列表的显示与隐藏 
    isShow: {
      handler (val, oldval) {
        if (val) {
          // 开启 列表轮询
          this.refresh()
        } else {
          // 关闭列表轮询
          this.clearList()
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.download-list {
  width: 520px;
  height: 518px;

  .download-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }

  .download-box {
    min-height: 430px;

    .download-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 22px 0px;
      border-bottom: 1px solid #efefef;

      /*no*/
      &:last-child {
        border-bottom-color: transparent;
      }

      .download-left {
        display: flex;
        align-items: center;
        width: 80%;
        flex-shrink: 0;

        .file-img {
          margin-right: 14px;
          width: 36px;
          flex-shrink: 0;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .file-info {
          width: calc(100% - 50px);

          .file-title {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          .file-pro {
            margin-top: 6px;
            display: flex;
            align-items: center;

            .file-pro-left {
              display: flex;
              align-items: center;
              margin-right: 30px;

              .progress-bar {
                width: 120px;
                // /deep/.el-progress {
                //   .el-progress-bar__inner {
                //     background: repeating-linear-gradient(
                //       45deg,
                //       #0064e6 25%,
                //       #7db9e8 0,
                //       #7db9e8 50%,
                //       #0064e6 0,
                //       #0064e6 75%,
                //       #7db9e8 0
                //     );
                //     background-size: 20px 20px;
                //     animation: roll 1s linear infinite;
                //   }
                // }
              }

              .progress-bar {
                width: 120px;
                /deep/.el-progress.progress-ani {
                  .el-progress-bar__inner {
                    background: repeating-linear-gradient(
                      45deg,
                      #0064e6 25%,
                      #7db9e8 0,
                      #7db9e8 50%,
                      #0064e6 0,
                      #0064e6 75%,
                      #7db9e8 0
                    );
                    background-size: 20px 20px;
                    animation: roll 1s linear infinite;
                  }
                }
              }

              .progress-name {
                margin-left: 14px;
                font-size: 10px;
                font-weight: 400;
                color: #999999;
                width: 70px;
              }
            }

            .file-pro-right {
              font-size: 10px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }

      .download-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .wp-noData {
      min-height: 430px;
    }
  }
}

@keyframes roll {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 20px;
  }
}
</style>