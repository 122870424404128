<template>
  <div class="createUnits">
    <el-scrollbar class="wp-scrollbar" style="height: 100%;">
      <div class="layout-main">
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
            <el-breadcrumb-item :to="{ name: 'unitManagList' }">单位管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{  isEidt ? '编辑单位' : '创建单位'  }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 基础设置 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">基础设置</div>
          <div class="layout-content">
            <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="100px" class="ruleform">
              <el-form-item label="系统状态：" prop="systemType">
                <el-radio-group v-model="ruleForm1.systemType">
                  <el-radio :label="item.value" v-for="item in $globalData.systemStateList" :key="item.value">
                    {{  item.label  }}
                  </el-radio>
                  <!-- <el-radio :label="2">
										正式
									</el-radio> -->
                </el-radio-group>
              </el-form-item>
              <el-form-item label="单位名称：" prop="custName">
                <el-input class="wp-input" v-model.trim="ruleForm1.custName" clearable placeholder="请输入单位名称" maxlength="20">
                </el-input>
              </el-form-item>
              <el-form-item label="单位别称：" prop="aliasName">
                <el-input class="wp-input" v-model.trim="ruleForm1.aliasName" clearable placeholder="请输入单位别称" maxlength="10">
                </el-input>
              </el-form-item>
              <!-- 可输入最多三位英文字符 -->
              <!-- 设置一次（已开始发任务使用等）则固化不可修改。 -->
              <el-form-item label="单位前缀：" prop="prefix">
                <el-input class="wp-input" v-model.trim="ruleForm1.prefix" clearable placeholder="请输入单位前缀" :disabled="isEidt && custInfo.taskCount >0" maxlength="3">
                </el-input>
              </el-form-item>
              <!-- 带搜索功能下拉选框，单选，选项为所有启用状态的单位，上级单位将可以在“转发统计”模块中看到该单位的任务转发情况。 -->
              <el-form-item label="上级单位：">
                <el-select class="wp-select" style="width: 100%;" filterable v-model="ruleForm1.parentId" clearable placeholder="请选择上级单位">
                  <el-option v-for="item in tableData" :key="item.custId" :label="item.custName" :value="item.custId">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 限制五位数的非0整数字符。否则不可输入 -->
              <el-form-item label="人数上限：" prop="maxMember">
                <el-input class="wp-input" v-model.trim="ruleForm1.maxMember" clearable placeholder="请输入人数上限" @change="resetNumName6" maxlength="5">
                </el-input>
              </el-form-item>
              <div class="line-row">
                <div class="line-row-item">
                  <el-form-item label="到期日期：" prop="endTime">
                    <div class="wp-date-picker">
                      <el-date-picker v-model="ruleForm1.endTime" value-format="yyyy-MM-dd" type="date" prefix-icon="" clearable placeholder="请选择日期" :picker-options="pickerOptions">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </div>
                <div class="line-row-item">
                  <el-form-item label="启用状态：" prop="status">
                    <el-select class="wp-select" style="width: 100%;" v-model="ruleForm1.status" clearable placeholder="请选择启用状态">
                      <el-option :label="item.label" :value="item.value" v-for="item in $globalData.enableStateList" :key="item.value">
                        {{  item.label  }}
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 高级选项 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">高级选项</div>
          <div class="layout-content">
            <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="ruleform">
              <el-form-item label="任务站点：" prop="taskStation">
                <el-select class="wp-select" collapse-tags v-model="ruleForm2.taskStation" multiple filterable placeholder="请选择任务站点" style="width:100%">
                  <el-option v-for="item in taskStationList" :key="item.dataValue" :label="item.dataKey" :value="item.dataValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- accessIm	number	
非必须
即时通讯 0.禁用 1.启用	
accessDrill	number	
非必须
投稿演练 0.禁用 1.启用	
accessShop -->
              <el-form-item label="功能权限：">
                
                <el-checkbox v-model="ruleForm2.accessDrill">投稿演练</el-checkbox>
                <el-checkbox v-model="ruleForm2.accessIm">即时通讯</el-checkbox>
                <el-checkbox v-model="ruleForm2.accessShop">积分兑换</el-checkbox>
                <el-checkbox v-model="ruleForm2.accessExcellentReview">优评中心</el-checkbox>
                <el-checkbox v-model="ruleForm2.accessReview">阅评中心</el-checkbox>
              </el-form-item>
              <div class="line-row">
                <div class="line-row-item">
                  <el-form-item label="短信渠道：" prop="smsChannel">
                    <el-select filterable class="wp-select" style="width: 100%;" v-model="ruleForm2.smsChannel" clearable placeholder="请选择短信渠道">
                      <el-option v-for="item in smsChannelList" :key="item.dataValue" :label="item.dataKey" :value="item.dataValue">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <el-form-item label="短信通知：">
                <el-checkbox v-model="ruleForm2.smsState">默认选中</el-checkbox>
              </el-form-item>
              <el-form-item label="即时通讯：">
                <el-checkbox v-model="ruleForm2.imContacts">仅对管理员展示联系人</el-checkbox>
              </el-form-item>
              <el-form-item label="值班账号：">
                <el-switch v-model="ruleForm2.dutyState" active-color="#0064E6" inactive-color="#CCCCCC" active-value="1" inactive-value="0">
                </el-switch>
              </el-form-item>
              <!-- 1）默认参数根据“星级定义”来设置。输入框只能输入“非零正整数”。
2）零星：N<5；一星：5<=N<10；二星：10<=N<20；三星：20<=N<50
四星：50<=N<100；五星：N>=100
校验需要保证星级级别之间设置参数数值是连续的（没有缺失或重叠），否则提示“星级设置权重不符合规则，请重新设置”。 -->
              <el-form-item label="星级设置：" required :error="starErrorStirng">
                <div class="star-level-form">
                  <div v-for="item in starLevelList" :key="item.id" class="star-style">
                    <div class="label">{{  item.label  }}:</div>
                    <el-input placeholder="" :clearable="false" maxlength="3" v-model.number="item.minValue" :disabled="item.minDisable" @change="resetNumCounts(item)" class="mini-input wp-input">
                    </el-input>
                    <span class="seprate">-</span>
                    <el-input placeholder="" :clearable="false" maxlength="3" v-model.number="item.maxValue" :disabled="item.maxDisable" @change="resetNumCounts(item)" class="mini-input wp-input">
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <!-- <el-form-item label="强提醒弹窗：" :error="notifErrorStirng">
                <el-switch @change="changeNotification" v-model="ruleForm2.notification" active-color="#0064E6" inactive-color="#CCCCCC" active-value="1" inactive-value="0">
                </el-switch>
                <el-input class="texthight" v-model="ruleForm2.notificationContent" type="textarea" placeholder="请输入强提醒内容" minlength="10" maxlength="1000" resize="none">
                </el-input>
              </el-form-item> -->
            </el-form>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="layout-block wp-moudle-style btn-block">
          <el-button class="wp-button" size="medium" @click="returnBack">取消</el-button>
          <el-button class="wp-button" size="medium" v-preventReClick type="primary" @click="validateFrom">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import { resetNum, isEn } from '@/utils/validate.js';
import { saveSysCust, sysCustList, sysDictList, getSysCust, getSysCustOption, updateSysCust } from '@/api/systemSettings.js';
export default {
  name: 'createUnits',
  components: {},
  data () {
    var validateEn = (rule, value, callback) => {
      if (value === '') {
        callback('请输入单位前缀');
      } else if (!isEn(value)) {
        callback("可输入最多三位英文字符");
      } else {
        callback();
      }
    };
    return {
      custInfo: {},// 单位的详情
      ruleForm1: {
        systemType: "1",
        custName: "",
        aliasName: "",
        prefix: "",
        parentId: "",
        maxMember: "",
        endTime: "",
        status: "1",
      },
      rules1: {
        systemType: [
          { required: true, message: '请选择系统状态', trigger: 'change' }
        ],
        custName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        aliasName: [
          { required: true, message: '请输入单位别称', trigger: 'blur' }
        ],
        prefix: [{ required: true, message: '请输入单位前缀', trigger: 'blur' }, { validator: validateEn, trigger: 'blur' }],
        maxMember: [
          { required: true, message: '请输入人数上限', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择启用状态', trigger: 'change' }
        ],
      },
      isEidt: false,
      ruleForm2: {
        taskStation: [],
        // access: [],
        smsChannel: "",
        imContacts: true,
        smsState: true,
        dutyState: '0',
        accessDrill: process.env.VUE_APP_NAME=='hw'?false:true,// 红网不默认勾选
        accessIm: true,
        accessShop: false,
        notification: '0',
        notificationContent: '',
        accessExcellentReview:false,// 优评
        accessReview:false // 阅评
        
      },
      rules2: {
        taskStation: [
          { required: true, message: '请选择任务站点', trigger: 'change' }
        ],
        smsChannel: [
          { required: true, message: '请选短信渠道', trigger: 'change' }
        ],

      },
      starLevelList: [
        { id: "1", label: "零星", minValue: 0, maxValue: 5, minDisable: true, maxDisable: false },
        { id: "2", label: "一星", minValue: 6, maxValue: 10, minDisable: true, maxDisable: false },
        { id: "3", label: "二星", minValue: 11, maxValue: 20, minDisable: true, maxDisable: false },
        { id: "4", label: "三星", minValue: 21, maxValue: 50, minDisable: true, maxDisable: false },
        { id: "5", label: "四星", minValue: 51, maxValue: 100, minDisable: true, maxDisable: false },
        { id: "6", label: "五星", minValue: 101, maxValue: "", minDisable: true, maxDisable: true },
      ],
      starErrorStirng: null,
      notifErrorStirng: null,
      tableData: [], // 上级单位列表
      taskStationList: [],  // 任务站点列表
      smsChannelList: [],// 短信渠道列表
      custid: '',
      custOption: {},
      pickerOptions: {},
      isPosting: false
    };
  },
  props: {},
  created () {
    if (this.$route.query._id) {
      // 编辑
      this.custid = this.$decrypt(this.$route.query._id)
      this.isEidt = true
      // 获取单位信息
      this.getSysCustOne()
    } else {
      // 新建
      this.initData()
    }

  },
  mounted () {
    this.setPickerOptions()
  },
  computed: {
    appName(){
      return process.env.VUE_APP_NAME
    } 
  },
  methods: {
    setPickerOptions () {
      // 不能选择 选择今天及之前的时间
      let today = (new Date().getTime() - 24 * 60 * 60 * 1000)
      this.$nextTick(() => {
        this.pickerOptions = {
          disabledDate (time) {
            return time.getTime() < today
          }
        }
      })

    },
    initData () {
      this.getSysCustList()
      this.getSysDictList('taskStation')
      this.getSysDictList('smsChannel')
    },
    validateFrom () {
      let that = this
      Promise.all([this.$refs['ruleForm1'].validate(), this.$refs['ruleForm2'].validate()]).then(() => {
        //表示两个表单同时验证成功  进行接口请求操作
        if (that.validateStar() && that.validateNotification()) {
          console.log("校验成功")
          that.postAdd()
        }
      })

      // 校验需要保证星级级别之间设置参数数值是连续的（没有缺失或重叠），否则提示“星级设置权重不符合规则，请重新设置”。 

    },
    //校验星级
    validateStar () {
      let flag = true
      this.starErrorStirng = null
      //没有缺失
      //没有重叠
      for (let i = 0; i < this.starLevelList.length; i++) {
        if (!this.starLevelList[i].minDisable && this.starLevelList[i].minValue.toString() == '') {
          flag = false
        }
        if (!this.starLevelList[i].maxDisable && this.starLevelList[i].maxValue.toString() == '') {
          flag = false
        }
        // 判断前一个的 最大值+1要等于 后一个的最小值
        if (i < (this.starLevelList.length - 1)) {
          let curMin = this.starLevelList[i].minValue
          let curMax = this.starLevelList[i].maxValue + 1
          let nextMin = this.starLevelList[i + 1].minValue
          if (curMax != nextMin) {
            flag = false
          }
          // 判断每个值的最小值要小于自己的最大值
          if (curMin >= curMax) {
            flag = false
          }
        }
      }
      if (!flag) {
        this.starErrorStirng = "星级设置权重不符合规则，请重新设置"
        return false
      }
      return flag
    },
    //校验强提醒
    validateNotification () {
      let flag = true
      this.notifErrorStirng = null
      // 开启了强提醒 且 提醒数字少于10  
      console.log('this.ruleForm2.notification', this.ruleForm2.notification == 1)
      if (this.ruleForm2.notification == 1 && this.ruleForm2.notificationContent.length < 10) {
        this.notifErrorStirng = "字符数不可少于10"
        return false
      }
      return flag
    },
    changeNotification () {
      this.validateNotification()
    },
    // 重置数值
    resetNumCounts () {
      this.starLevelList.forEach((element, index) => {
        if (!element.minDisable) {
          element.minValue = resetNum(element.minValue)
        }
        if (!element.maxDisable) {
          element.maxValue = resetNum(element.maxValue)
        }
        if (index < this.starLevelList.length - 1) {
          this.starLevelList[index + 1].minValue = element.maxValue + 1
        }
      });
    },
    resetNumName6 () {
      if (this.ruleForm1.maxMember.toString().trim().length == 0) return
      this.ruleForm1.maxMember = resetNum(this.ruleForm1.maxMember)
      if (this.ruleForm1.maxMember == 0) {
        this.ruleForm1.maxMember = 1
      }
    },
    // 获取单位列表
    async getSysCustList () {
      let query = {
        page: 1,
        limit: 1000
      }
      try {
        let res = await sysCustList(query)
        if (Number(res.returnCode) !== 200) return
        this.tableData = res.returnData.data.filter(item => Number(item.custId) != Number(this.custid))
        if (this.isEidt) {
          this.ruleForm1.parentId = this.custInfo.parentId ? Number(this.custInfo.parentId) : ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取任务站点 短信渠道
    async getSysDictList (dataType) {
      let query = {
        dataType: dataType
      }
      try {
        let res = await sysDictList(query)
        if (Number(res.returnCode) !== 200) return
        if (dataType == 'taskStation') {

          this.taskStationList = res.returnData.filter(item => item.dataKey != '其他' && item.dataKey != '微信视频号' && item.dataKey != '附件')
          if (this.isEidt) {
            let taskStation = this.custOption?.taskStation || '[]'
            this.ruleForm2.taskStation = JSON.parse(taskStation).map(it => it?.dataValue || '')
          }
        } else if (dataType == 'smsChannel') {
          this.smsChannelList = res.returnData
          this.ruleForm2.smsChannel = this.smsChannelList.length > 0 ? this.smsChannelList[0].dataValue : ''
          if (this.isEidt) {
            this.ruleForm2.smsChannel = this.custOption?.smsChannel.toString() || this.ruleForm2.smsChannel.toString()
          }
        }

      } catch (error) {
        console.log(error)
      }
    },
    // 创建单位 发起请求
    async postAdd () {
      if (this.isPosting) return
      this.isPosting = true
      let taskStation = this.taskStationList.filter(item => {
        return this.ruleForm2.taskStation.indexOf(item.dataValue) != -1
      })
      let query = {
        ...this.ruleForm1,
        ...this.ruleForm2,
        accessDrill: this.ruleForm2.accessDrill ? 1 : 0,//0.禁用 1.启用
        accessIm: this.ruleForm2.accessIm ? 1 : 0,//0.禁用 1.启用
        accessShop: this.ruleForm2.accessShop ? 1 : 0, //0.禁用 1.启用
        imContacts: this.ruleForm2.imContacts ? 1 : 0,   //仅允许管理员可见联系人 0.否 1.是
        smsState: this.ruleForm2.smsState ? 1 : 0,   // 0.关闭 1.开启
        accessReview: this.ruleForm2.accessReview ? 1 : 0,//0.禁用 1.启用
        accessExcellentReview:this.ruleForm2.accessExcellentReview ? 1 : 0,//0.禁用 1.启用
        taskStation: JSON.stringify(taskStation),
        star1: this.starLevelList[0].maxValue,
        star2: this.starLevelList[1].maxValue,
        star3: this.starLevelList[2].maxValue,
        star4: this.starLevelList[3].maxValue,
        star5: this.starLevelList[4].maxValue
      }
      let res = null

      try {
        if (this.isEidt) {
          query.custId = this.custid
          query.coId = this.custOption.coId
          res = await updateSysCust(query)
        } else {
          res = await saveSysCust(query)
        }
        this.isPosting = false
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.returnBack()
      } catch (error) {
        console.log(error)
      }
    },
    // 返回
    returnBack () {
      this.$router.push({ name: 'unitManagList' })
    },
    // 获取单个单位
    async getSysCustOne () {
      try {
        let res = await getSysCust(this.custid)
        if (Number(res.returnCode) !== 200) return
        this.custInfo = res.returnData
        this.getSysCustOptionOne()
      } catch (error) {
        console.log(error)
      }
    },
    // 获取单个单位配置
    async getSysCustOptionOne () {
      try {
        let res = await getSysCustOption(this.custid)
        this.initData()
        if (Number(res.returnCode) !== 200) return
        this.custOption = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },

    // 设置默认值
    setInitValue () {
      //ruleForm1
      this.ruleForm1.systemType = this.custInfo.systemType?.toString()
      this.ruleForm1.custName = this.custInfo.custName
      this.ruleForm1.aliasName = this.custInfo.aliasName
      this.ruleForm1.prefix = this.custInfo.prefix
      this.ruleForm1.parentId = this.custInfo.parentId?.toString()
      this.ruleForm1.maxMember = this.custInfo.maxMember
      console.log(this.custInfo)
      console.log(this.ruleForm1.maxMember)
      this.ruleForm1.endTime = this.custInfo.endTime
      this.ruleForm1.status = this.custInfo.status?.toString()
      //ruleForm2
      this.ruleForm2.imContacts = this.custOption.imContacts == 1
      this.ruleForm2.smsState = this.custOption.smsState == 1
      this.ruleForm2.dutyState = this.custOption.dutyState?.toString()
      this.ruleForm2.accessDrill = this.custOption.accessDrill == 1
      this.ruleForm2.accessIm = this.custOption.accessIm == 1
      this.ruleForm2.accessShop = this.custOption.accessShop == 1
      this.ruleForm2.notification = this.custOption.notification.toString()
      this.ruleForm2.notificationContent = this.custOption.notificationContent
      this.ruleForm2.accessReview = this.custOption.accessReview == 1
      this.ruleForm2.accessExcellentReview = this.custOption.accessExcellentReview == 1
      // 星级
      this.starLevelList.forEach((item, index) => {
        item.maxValue = this.custOption['star' + (index + 1)]
        if (index > 0) {
          item.minValue = Number(this.custOption['star' + index]) + 1
        }
      })

    }

  },
  beforeDestroy () {

  },
  watch: {

  }
};
</script>
<style scoped lang="scss">
@import "./css/createUnits.scss";
</style>
