import { get, post } from '@/utils/requestTwo'

export default {
    // 上报分类-列表
    topicTypeList: (query = {}) => post("/wpTopicType/list", query),

    // 上报分类-移动
    topicTypeMoveOrder: (query = {}) => post("/wpTopicType/moveOrder", query),

    // 上报分类-新增
    topicTypeInsert: (query = {}) => post("/wpTopicType/insert", query),

    // 上报分类-编辑
    topicTypeUpdate: (query = {}) => post("/wpTopicType/update", query),

    // 上报分类-删除
    topicTypeVerifyRemove: (query = {}) => post("/wpTopicType/verifyRemove", query),

    // 上报分类-删除
    deleteTopicType: (query = {}) => post("/wpTopicType/delete", query),

    // 上报专题-列表
    topicMainList: (query = {}) => post("/wpTopicMain/pageList", query, false),

    // 上报专题-新增
    topicMainInsert: (query = {}) => post("/wpTopicMain/insert", query, false),

    // 专题详情 
    topicDetail: (query = {}) => post("/wpTopicMain/get", query),

    // 队伍上报情况
    teamReportedList: (query = {}) => post("/wpTopicMain/pageTeamMumberList", query),

    // 人员上报情况
    peopleReportedList: (query = {}) => post("/wpTopicArticle/topicInfoArticle", query, false),

    // 未完成人员上报
    peopleNoReportedList: (query = {}) => post("/wpTopicReceiver/topicInfoReceiver", query),

    // 上报趋势
    trendList: (query = {}) => post("/wpTopicArticle/trendList", query),

    // 文章驳回
    rejectArticle: (query = {}) => post("/wpTopicArticle/rejectArticle", query, false),

    // 获取驳回信息
    getRejectArticleInfo: (query = {}) => post("/wpTopicArticleVerify/get", query),

    // 发送短信
    sendNote: (query = {}) => post("/wpTopicMain/sendByTopicId", query),

    // 获取单位默认参数
    getConfigList: (query = {}) => post("/sysCustConfig/pageList2", query),

    // 删除专题任务
    deleteTopic: (query = {}) => post("/wpTopicMain/delete", query),

    // 编辑专题(结束专题)
    updateTopic: (query = {}) => post("/wpTopicMain/update", query, false),

    // 分组树
    groupTree: (query = {}) => post("/sysGroup/listTree", query, false),

    // 投稿审核-文章改分
    updateScore: (query = {}) => post("/wpTopicArticle/updateScore", query),

    // 投稿审核-审核详情
    getArticleDetail: (query = {}) => post("/wpTopicArticle/get", query),

    // 获取上次选中人员
    getBackReceiver: (query = {}) => post("/wpTopicReceiver/getBackReceiver", query),

    // 专题管理-追加人员
    addToPeople: (query = {}) => post("/wpTopicReceiver/insertBatch", query, false),

    // 专题下拉列表
    topicSelectList: (query = {}) => post("/wpTopicMain/topicSelectList", query),

    // 上报审核-逐条审核(上一条,下一条)
    topicInfoArticleIds: (query = {}) => post("/wpTopicArticle/topicInfoArticleIds", query, false),

    // 获取短信余量
    getSmsNum: (query = {}) => post("/sysCustOption/smsNum", query),

    // 上报审核-导出
    topicArticleExport: (query = {}) => post("/wpTopicArticle/topicArticleExport", query, false),

    // 未完成人员列表导出
    topicInfoReceiverExport: (query = {}) => post("/wpTopicReceiver/topicInfoReceiverExport", query),

    // 获取单位配置网站列表(赣州)
    getWebsiteList: (query = {}) => post("/sysCustWebsite/list", query),

    // 上报审核-人员导出
    memberStatisticsExport: (query = {}) => post("/wpTopicArticle/memberStatisticsExport", query, false),

    // 作者类型下拉框
    topicAuthorTypeList: (query = {}) => post("/sysCustWebsite/topicAuthorTypeList", query, false),

    // 上报审核-人员导出
    topicInfoArticleExport: (query = {}) => post("/wpTopicArticle/topicInfoArticleExport", query, false),
}