import { render, staticRenderFns } from "./panelTwo.vue?vue&type=template&id=f5b9a662&scoped=true&"
import script from "./panelTwo.vue?vue&type=script&lang=js&"
export * from "./panelTwo.vue?vue&type=script&lang=js&"
import style0 from "./panelTwo.vue?vue&type=style&index=0&id=f5b9a662&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5b9a662",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wulijuan\\code\\code20220621\\wp-web-new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5b9a662')) {
      api.createRecord('f5b9a662', component.options)
    } else {
      api.reload('f5b9a662', component.options)
    }
    module.hot.accept("./panelTwo.vue?vue&type=template&id=f5b9a662&scoped=true&", function () {
      api.rerender('f5b9a662', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/panel/panelTwo.vue"
export default component.exports