<template>
  <div class="upload-content">
    <div class="file-list">
      <p class="placeholder-text" v-if="fileList.length == 0">请上传大小在5mb以内的jpg、jpeg、png格式图片</p>
      <div v-else style="width: 100%;height: 100%;">
        <ul class="file-list-box">
          <li v-for="(item, index) in fileList" :key="item.id" class="textOverOneLine" ref="uploadList">
            <el-tooltip class="item" effect="dark" :content="item.nameNo" placement="bottom" :disabled="!isShowTooltipWidth">
              <p class="textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{  item.nameNo  }}</p>
            </el-tooltip>
            <img src="~@/assets/images/guanbi.png" alt="" class="del" @click="removeFile(item, index)" />
          </li>
        </ul>
      </div>
    </div>
    <!-- multiple -->
    <el-upload v-show="uploadList.length < limit" action="$globalData.file.uploadUrl" :http-request="httpRequest" ref="_upload" class="upload-demo" :accept="accept" :limit="limit" :on-exceed="handleExceed" :before-upload="handleBeforeUpload" :on-error="handleError" :on-success="handleSuccess" :on-change="handleChange" :on-progress="handleProgress" :show-file-list="false" :file-list="uploadList" :disabled="uploadList.length >= limit" multiple>
      <el-button class="wp-button wp-btn-icon" icon="icon-shanchuan" type="primary" :disabled="uploadList.length >= limit">上传资料</el-button>
    </el-upload>
  </div>
</template>

<script>
// 1）格式要求：支持jpg、jpeg、png图片格式
// 2）格式大小：最大5mb
// 3）上传图片：最多5个，达到5个该按钮置灰不可点。
// 4）上传后的文件仅显示文件名称前4个字符，超出...，对于超出字符的名称悬停显示全称（文件格式后缀不显示）。
// 5）点击X该图片删除。
// 1）格式不符：“请上传jpg、jpeg、png格式图片”。
// 2）超过大小：“请上传大小为5mb以内的图片”。
import { uploadFile } from '@/api/personnelTeam.js'
export default {
  name: 'Upload',
  data () {
    return {
      fileList: [],
      // fileList: [{ id: "1", nameNo: '上传图片上传图片上传图片' }, { id: "2", nameNo: '上传图片上传图片上传图片上传图片上传图片' }],
      uploadList: [],
      isZhaoZi: false,
      isShowTooltipWidth: false,
      quality: 0.6, // 压缩比例
    };
  },
  props: {
    datas: {
      type: Array,
      default: function () {
        return [];
      }
    },
    /**当上传的文件列表发生改变时*/
    changeFileList: {
      type: Function,
      default: function () {
        return [];
      }
    },
    /*删除文件数量*/
    limit: {
      type: Number,
      default: 5
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg'
    },
    /*上传文件最大大小单位：M*/
    size: {
      type: Number,
      default: 5
    },
    uploadTip: {
      type: String,
      default: ''
    },
    fileType: {
      type: Array,
      default: function () {
        return [];
      }
    },
    excludeFileType: {
      // 要排除的文件
      type: Array,
      default: function () {
        return ['jpg', 'jpeg', 'png'];
      }
    }
  },
  mounted () {
    console.log(this.fileList);
  },
  watch: {
    datas: {
      handler (curVal, oldVal) {
        this.fileList = JSON.parse(JSON.stringify(curVal)).map(it => { return { ...it, uid: it.fileId } })
        console.log(this.fileList)
        this.uploadList = JSON.parse(JSON.stringify(curVal)).map(it => { return { ...it, uid: it.fileId } });
      },
      immediate: true
    }
  },
  methods: {
    async httpRequest (option) {
      console.log("option")
      console.log(option)
      let fd = new FormData(); //参数的格式是formData格式的
      fd.append("file", option.file); //参数
      fd.append("id", '1'); //参数
      try {
        let res = await uploadFile(fd)
        if (Number(res.returnCode) !== 200) {
          // 清除 正在上传的文件中
          this.uploadList.splice(this.uploadList.indexOf(option.file), 1);
          return this.$showMessage({
            type: 'error',
            msg: `该文件上传异常`
          })
        }
        let file = res.returnData
        if (this.fileList.length < this.limit) {
          //this.fileList.push({ ...file, uid: option.file.uid, id: file.fileId, name: file.fileName, nameNo: file.fileName.substring(0, file.fileName.lastIndexOf('.')) })
          this.fileList.push({ fileId: file.fileId, uid: option.file.uid, id: file.fileId, name: file.fileName, nameNo: file.fileName.substring(0, file.fileName.lastIndexOf('.')) })
        }
        // console.log("this.fileList");
        // console.log(this.fileList);
        this.uploadList = JSON.parse(JSON.stringify(this.fileList))
        this.$emit('change-file-list', this.fileList);
      } catch (error) {
        console.log(error)
        // 清除 正在上传的文件中
        this.uploadList.splice(this.uploadList.indexOf(option.file), 1);
      }
    },
    handleProgress: function (event, file, fileList) {
      // console.log(file)
    },
    clearFiles () {
      this.uploadList = [];
      this.fileList = [];
      this.$refs._upload.clearFiles();
    },
    getFiles () {
      return this.fileList;
    },
    handleChange (file, fileList) {
      console.log("handleChange");
      console.log(file);
      console.log(fileList);
    },
    handleBeforeUpload (file) {
      let name = file.name.substring(0,file.name.lastIndexOf('.'))
      if(name.length > 100){
        this.$showMessage({
            type: 'error',
            msg: '文件名过长'
          })
        return false
      }
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      let extension = true;
      if (this.fileType.length > 0 && this.fileType.indexOf(testmsg) === -1) {
        extension = false;
      }
      if (!extension) {
        this.$showMessage({
          type: 'error',
          msg: `请上传${this.fileType.join('、')}格式图片`
        })
        this.uploadList.splice(this.uploadList.indexOf(file), 1);
        return false;
      }
      //判断文件大小
      if (this.size && file.size > this.size * 1024 * 1024) {
        this.$showMessage({
          type: 'error',
          msg: `请上传大小为${this.size}mb以内的图片`
        })
        this.uploadList.splice(this.uploadList.indexOf(file), 1);
        return false;
      }
      return true;
    },
    /**失败*/
    handleError (err, file, fileList) {
      this.isZhaoZi = false;
      console.log(file);
      this.uploadList.splice(this.uploadList.indexOf(file), 1);
    },
    /**成功*/
    handleSuccess (res, file, fileList) {
    },
    handleExceed (files, fileList) {
      // 移除保存的正在上传的文件
      this.uploadList.splice(this.uploadList.indexOf(files), 1);
      this.$showMessage({
        type: 'error',
        msg: '文件最多上传' + this.limit + '个'
      })
    },
    removeFile (file, index) {
      // console.log("file");
      // console.log(file);
      // console.log(this.fileList.find(s => s.id == file.id));
      let _this = this;
      let i = _this.fileList.indexOf(_this.fileList.find(s => s.id == file.id));
      if (i > -1) {
        _this.fileList.splice(i, 1);
      }
      let i2 = _this.uploadList.indexOf(_this.uploadList.find(s => s.uid == file.uid));

      if (i2 > -1) {
        _this.uploadList.splice(i2, 1);
      }
      this.uploadList = JSON.parse(JSON.stringify(this.fileList))
      _this.$emit('change-file-list', _this.fileList);
    },
    abort: function () {
      this.$refs._upload.abort()
      this.clearFiles()
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
  }
};
</script>
<style scoped lang="less">
.upload-content {
  // line-height: 36px;
  // height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .file-list {
    flex: 1;
    margin-right: 12px;
    height: 36px; /*no */
    border-radius: 4px;
    border: 1px solid #dddddd;
    /*no */
    padding: 0px 12px;

    .placeholder-text {
      text-indent: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 36px; /*no */
    }

    .file-list-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      > li {
        // width: 108px;
        height: 26px;
        background: #e8e8e8;
        border-radius: 4px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        padding: 0px 10px;

        p {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          padding: 0px 10px;
          line-height: 26px;
          margin-right: 4px;
        }

        .del {
          cursor: pointer;
          display: block;
          width: 18px;
        }
      }
    }
  }

  .upload-demo {
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: center;

    /deep/.el-upload {
      height: 36px;
    }

    .el-button {
      display: block;
    }
  }
}
</style>
