var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wp-w100" }, [
    _c(
      "div",
      { staticClass: "main" },
      [
        _c(
          "el-breadcrumb",
          {
            staticClass: "wp-breadcrumb",
            attrs: { "separator-class": "el-icon-arrow-right" },
          },
          [
            _c(
              "el-breadcrumb-item",
              { attrs: { to: { path: "/taskManagement" } } },
              [_vm._v("投稿管理")]
            ),
            _c("el-breadcrumb-item", [_vm._v("编辑任务")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wp-task-card mt-16" },
          [
            _c("div", { staticClass: "card-title fs-16 fw-bold" }, [
              _vm._v("基础设置"),
            ]),
            _c("BasicsForm", {
              ref: "basicsForm",
              staticClass: "center",
              attrs: { formData: _vm.formData },
              on: { getStyleList: _vm.getStyleList },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wp-task-card mt-16" },
          [
            _c("div", { staticClass: "card-title flex-CB" }, [
              _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("任务设置")]),
              _c(
                "div",
                { staticClass: "flex-Acenter instancy-task" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "isUrgent",
                      model: {
                        value: _vm.isUrgent,
                        callback: function ($$v) {
                          _vm.isUrgent = $$v
                        },
                        expression: "isUrgent",
                      },
                    },
                    [_vm._v("紧急任务")]
                  ),
                  _c("wp-question-mark", {
                    staticStyle: { "margin-left": "6px" },
                    attrs: { content: "任务发出后APP会强提醒30秒" },
                  }),
                ],
                1
              ),
            ]),
            _c("TaskForm", {
              ref: "taskForm",
              attrs: {
                formData: _vm.formData,
                configObj: _vm.configObj,
                taskStyleList: _vm.taskStyleList,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "wp-task-card mt-16",
            style: { height: _vm.foldFlag ? "174px" : "74px" },
          },
          [
            _c("div", { staticClass: "card-title flex-CB" }, [
              _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("高级选项")]),
              _c(
                "div",
                {
                  staticClass: "wp-blue toggle",
                  on: {
                    click: function ($event) {
                      _vm.foldFlag = !_vm.foldFlag
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(!_vm.foldFlag ? "展开" : "收起"))]),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.foldFlag,
                        expression: "foldFlag",
                      },
                    ],
                    staticClass: "el-icon-arrow-up fs-14",
                    staticStyle: { "margin-left": "6px" },
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.foldFlag,
                        expression: "!foldFlag",
                      },
                    ],
                    staticClass: "el-icon-arrow-down fs-14",
                    staticStyle: { "margin-left": "6px" },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "footer",
                style: { display: _vm.foldFlag ? "block" : "none" },
              },
              [
                _c(
                  "div",
                  { staticClass: "layout-content" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "advanced-form",
                        attrs: { "label-width": "100px" },
                      },
                      _vm._l(_vm.advancedSetting2, function (item) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.custConfigId,
                            attrs: { label: item.configName + "：" },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: item.isDisbled },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkboxChangeShow2(item)
                                  },
                                },
                                model: {
                                  value: item.isShow,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isShow", $$v)
                                  },
                                  expression: "item.isShow",
                                },
                              },
                              [_vm._v("显示 ")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: item.isDisbled },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkboxChangeRequired2(item)
                                  },
                                },
                                model: {
                                  value: item.isRequired,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isRequired", $$v)
                                  },
                                  expression: "item.isRequired",
                                },
                              },
                              [_vm._v("必填 ")]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "wp-task-card mt-16 wp-center" },
          [
            _c(
              "el-button",
              { staticClass: "wp-button", on: { click: _vm.cancel } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "wp-button",
                staticStyle: { "margin-left": "24px" },
                attrs: { type: "primary" },
                on: { click: _vm.submitTask },
              },
              [_vm._v("确定 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }