import JSEncrypt from 'jsencrypt/bin/jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

/**
 * -----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCycsmpksrg2Ai2TZvl/WLm9Efw
takcppq/7VsEyrPXBoNqCyYTxlsS0RVZ5PR7kWJv8aXSfH+jqnXGGIU2g/lthIjE
d5ig1Mh96U/QNc9RqxTfElTeco0gTUhxd9H+Y0gHdCHkElBhOa9MzTSHipB4j8IE
lczoem841hGE/G7BJwIDAQAB
-----END PUBLIC KEY----- */

/**
 * -----BEGIN PRIVATE KEY-----
MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBALJyyamSyuDYCLZN
m+X9Yub0R/C1qRymmr/tWwTKs9cGg2oLJhPGWxLRFVnk9HuRYm/xpdJ8f6OqdcYY
hTaD+W2EiMR3mKDUyH3pT9A1z1GrFN8SVN5yjSBNSHF30f5jSAd0IeQSUGE5r0zN
NIeKkHiPwgSVzOh6bzjWEYT8bsEnAgMBAAECgYAQj3qLlCXjy+izEmy/YU6GZHEw
s4soL4X4/cwOm8ko0V/svXAhMa1OB71VhFv0A8AA54oeCJPH2o/nfGsQbhr9mQ2x
F0CDKvpJ+PjLCR1QY4TVWD3AihRa5CKsxhHongEp/suwdqaA5q0JV2+yOM3myU6K
d5cgoLdtSgWvGhDUoQJBAOt4lEbhArhUwyiQvLM4GMU2MzUPTQ8XcfnHzoSEY/zM
GAPojLot2zVllRT8w0EY1T+ntUvNuaW5XhAHhLYMadkCQQDCAYjLi9Mq9FIlD2Ll
/vTX2BGYndfFv2JfY/nhQmC9HhJKsRSSr3bl/BmwQs8gINzX1XUqcf/HfcngQ13Q
BqL/AkBn004GBrbtUiqPG2qo12ZMpr5jefCvY9YUnd5Yngt8bv1z07khapDKdZUL
oPvzGyEEJU2SNA7UnOx1wuroXobZAkAjO6AaRzR8co6QiHDPkDPrFyRq8Q+ErDMz
W7a/wAxNjC6KPgqccR1IuzX3Gsr+7LjuwadbpARtuFx3TAS3iN0FAkADTkgQwnvL
S0XlS4kCRDRIMnK1lHEwe7x5RzaWXybVIidibx1mNVQAcCNYmw3/xXXNKyUmrXaQ
YZ5zd7jjsoIF
-----END PRIVATE KEY-----
*/

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCycsmpksrg2Ai2TZvl/WLm9Efw\n' +
  'takcppq/7VsEyrPXBoNqCyYTxlsS0RVZ5PR7kWJv8aXSfH+jqnXGGIU2g/lthIjE\n' +
  'd5ig1Mh96U/QNc9RqxTfElTeco0gTUhxd9H+Y0gHdCHkElBhOa9MzTSHipB4j8IE\n' +
  'lczoem841hGE/G7BJwIDAQAB'

const privateKey = 'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBALJyyamSyuDYCLZN\n' +
  'm+X9Yub0R/C1qRymmr/tWwTKs9cGg2oLJhPGWxLRFVnk9HuRYm/xpdJ8f6OqdcYY\n' +
  'hTaD+W2EiMR3mKDUyH3pT9A1z1GrFN8SVN5yjSBNSHF30f5jSAd0IeQSUGE5r0zN\n' +
  'NIeKkHiPwgSVzOh6bzjWEYT8bsEnAgMBAAECgYAQj3qLlCXjy+izEmy/YU6GZHEw\n' +
  's4soL4X4/cwOm8ko0V/svXAhMa1OB71VhFv0A8AA54oeCJPH2o/nfGsQbhr9mQ2x\n' +
  'F0CDKvpJ+PjLCR1QY4TVWD3AihRa5CKsxhHongEp/suwdqaA5q0JV2+yOM3myU6K\n' +
  'd5cgoLdtSgWvGhDUoQJBAOt4lEbhArhUwyiQvLM4GMU2MzUPTQ8XcfnHzoSEY/zM\n' +
  'GAPojLot2zVllRT8w0EY1T+ntUvNuaW5XhAHhLYMadkCQQDCAYjLi9Mq9FIlD2Ll\n' +
  '/vTX2BGYndfFv2JfY/nhQmC9HhJKsRSSr3bl/BmwQs8gINzX1XUqcf/HfcngQ13Q\n' +
  'BqL/AkBn004GBrbtUiqPG2qo12ZMpr5jefCvY9YUnd5Yngt8bv1z07khapDKdZUL\n' +
  'oPvzGyEEJU2SNA7UnOx1wuroXobZAkAjO6AaRzR8co6QiHDPkDPrFyRq8Q+ErDMz\n' +
  'W7a/wAxNjC6KPgqccR1IuzX3Gsr+7LjuwadbpARtuFx3TAS3iN0FAkADTkgQwnvL\n' +
  'S0XlS4kCRDRIMnK1lHEwe7x5RzaWXybVIidibx1mNVQAcCNYmw3/xXXNKyUmrXaQ\n' +
  'YZ5zd7jjsoIF'

// 加密
export function encrypt (txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt.toString()) // 对数据进行加密
}

// 解密
export function decrypt (txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

