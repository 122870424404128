<template>
  <div class="wp-w100">
    <el-scrollbar style="height: 100%">
      <div class="main">
        <TabsCard :tabs="tabs" :activeTab="activeTab" @handlerTabs="handlerTabs">
          <el-tab-pane label="组员统计" name="people">
            <SearchCard title="" @handlerSearch="handlerSearch" class="peopleSearch">
              <template v-slot:searchContent>
                <TaskTimeSelect :days="365" :activeType="searchParams.timeType" :taskType="timeType" @timeSelect="timeSelect" ref="timeRef"></TaskTimeSelect>

                <el-input class="search-w190 wp-input" v-model="searchParams.userName" placeholder="请输入上报人姓名" clearable></el-input>

                <el-input class="search-w190 wp-input" v-model="searchParams.custName" placeholder="请输入所在单位" clearable></el-input>

                <wpLoadSelect class="search-w392 wp-select align-top" v-model="searchParams.topicId" :data="topicList" :page="topicObj.page" dictLabel="title" dictValue="topicId" dictTime="createTime" :hasMore="topicObj.more" :request="getData" @clearTitle="() => (topicObj.title = '')" placeholder="请输入专题名称"></wpLoadSelect>
              </template>
            </SearchCard>
          </el-tab-pane>

          <el-tab-pane label="作品明细" name="workDetails">
          </el-tab-pane>

        </TabsCard>
        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="flex-Acenter fs-14">
              <div>
                上报数量：<span class="wp-blue">{{
                  infoData.articleNum || 0
                }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{ infoData.score || 0 }}</span>
              </div>
            </div>
            <div>
              <el-button @click="exportFile" class="wp-button wp-btn-icon" size="medium" icon="icon-daochu">导出
              </el-button>
            </div>
          </div>

          <div class="wp-table mt-20" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
            <el-table sortable="custom" @sort-change="sortChange" :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column align="center" type="index" label="序号" width="70">
              </el-table-column>
              <el-table-column min-width="30"> </el-table-column>
              <el-table-column min-width="150" show-overflow-tooltip align="left" prop="userName" label="人员姓名">
              </el-table-column>
              <el-table-column min-width="150" show-overflow-tooltip align="left" prop="custName" label="所在单位">
              </el-table-column>
              <el-table-column min-width="120" align="center" sortable prop="workNum" label="上报数量">
              </el-table-column>
              <el-table-column min-width="120" align="center" sortable prop="score" label="奖励积分">
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination class="mt-21" :total="total" :current-page="searchParams.page" :page-size="searchParams.limit" @size-change="handleSizeChange" @current-change="handleCurrentChange"></wp-pagination>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import wpyReportedApi from '@/api/wpyReported.js'
import reportedApi from '@/api/reported'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import TabsCard from '@/views/components/tabsCard'
export default {
  name: "transpondStatistics",
  components: { SearchCard, TaskTimeSelect, TabsCard },
  data () {
    return {
      topicObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 专题名称
        limit: 10, // 页大小
      },
      loading: false,
      topicList: [], // 专题下拉框列表
      // 查询条件
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        topicId: "", // 所选任务ID
        page: 1, // 页码
        limit: 30, // 页大小
        timeType: 2
      },
      timeType: [
        {
          name: "作品提交时间",
          id: 1
        },
        {
          name: "专题创建时间",
          id: 2
        },
      ],
      taskList: [],
      tableData: [],
      infoData: {}, // 表格统计信息
      total: 0,
      activeTab: 'people',
      tabs: [
        { label: '组员统计', name: "people" },
        { label: '作品明细', name: "workDetails" }
      ],
    }
  },
  mounted () {
    this.$refs.timeRef.initTime()
    /** 获取组员统计列表数据 */
    this.getTableData()
  },
  methods: {
    // 列表排序
    sortChange (column) {
      let { prop, order } = column
      if (order == "ascending") {
        order = 'asc'
      } else if (order == 'descending') {
        order = 'desc'
      } else {
        order = ''
      }
      this.searchParams.sort = order
      this.searchParams.orderField = prop

      console.log({ ...this.searchParams });
      this.getTableData()
    },

    /** 导出 */
    async exportFile (event) {
      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await wpyReportedApi.groupLeaderStatisticsExport({ ...this.searchParams })
        this.isExporting = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
      } catch (error) {
        this.isExporting = false
      }
    },

    /** 组员统计数据 */
    async getTableData () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyReportedApi.groupLeaderStatisticsList({ ...this.searchParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res?.returnData
          console.log(ret);
          this.tableData = ret?.page?.data || []
          this.total = ret?.page?.totalCount || 0
          this.infoData = res.returnData?.summaryVo || {}
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.topicList = []
        this.topicObj = { ...this.topicObj, ...data }
      }

      const res = await reportedApi.topicSelectList({ ...this.topicObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 专题列表
        this.topicList = [...this.topicList, ...ret.data].reduce((prev, curr) => {
          obj[curr.topicId] ? '' : obj[curr.topicId] = true && prev.push(curr);
          return prev;
        }, [])

        console.log(this.topicList);

        // 当前页小于总页数,+1
        if (this.topicObj.page < ret.totalPageNum) {
          this.topicObj.page++
          this.topicObj.more = true
        } else {
          this.topicObj.more = false
        }
      }
    },

    // 时间类型选择回调
    timeSelect (data) {
      this.searchParams = { ...this.searchParams, ...data }
    },

    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      /** 获取组员统计列表数据 */
      this.getTableData()
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getTableData()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getTableData()
    },
    /** tab切换 */
    handlerTabs (name) {
      if (name != 'people') {
        this.$router.push('/crewReportedWorkDetails')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>