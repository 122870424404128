var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.popoverType === "add"
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "top",
                width: "520",
                "popper-class": "reject-popper",
                trigger: "click",
              },
              on: { hide: _vm.hidePopover },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "472px" },
                attrs: {
                  type: "textarea",
                  rows: 6,
                  maxlength: "50",
                  placeholder: _vm.placeholder,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "wp-button",
                      attrs: { size: "medium", type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "wp-button",
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.handlerReject },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [_vm._t("default")],
                2
              ),
            ],
            1
          )
        : _c(
            "el-popover",
            {
              attrs: {
                placement: "top",
                width: "520",
                "popper-class": "reject-popper",
                trigger: "hover",
              },
            },
            [
              _c("div", { staticClass: "flex-CB" }, [
                _c(
                  "div",
                  { staticClass: "flex-Acenter" },
                  [
                    _c("el-avatar", {
                      staticClass: "avatar",
                      attrs: { src: _vm.headUrl },
                    }),
                    _c("div", { staticClass: "createName textOverOneLine" }, [
                      _vm._v(" " + _vm._s(_vm.rejectData.createName) + " "),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm
                          .$moment(_vm.rejectData.createTime)
                          .format("YYYY-MM-DD HH:mm")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "reject-text" }, [
                _vm._v(" " + _vm._s(_vm.rejectData.resonRemark) + " "),
              ]),
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }