<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <TabsCard
          :tabs="tabs"
          :activeTab="activeTab"
          @handlerTabs="handlerTabs"
        >
          <el-tab-pane label="投稿审核" name="taskReview"> </el-tab-pane>
          <el-tab-pane label="优评审核" name="excellentReview">
            <SearchCard
              title=""
              @handlerSearch="handlerSearch"
              class="peopleSearch"
            >
              <template v-slot:searchContent>
                <div class="wp-date-picker search-w392">
                  <el-date-picker
                    :default-time="['00:00:00', '23:59:59']"
                    @change="changeTimeSection"
                    type="datetimerange"
                    v-model="timeSection"
                    prefix-icon=""
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    clearable
                    range-separator="-"
                    start-placeholder="请选择开始时间"
                    end-placeholder="请选择结束时间"
                  >
                  </el-date-picker>
                </div>
                <el-select
                  class="search-w190 wp-select"
                  v-model="searchParams.taskId"
                  filterable
                  clearable
                  placeholder="请选择评选时段"
                  @change="changeTaskId"
                >
                  <el-option
                    v-for="item in excellents"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-input
                  class="search-w190 wp-input"
                  v-model.trim="searchParams.title"
                  placeholder="请输入任务标题"
                  clearable
                ></el-input>
                <el-input
                  class="search-w190 wp-input"
                  v-model.trim="searchParams.userName"
                  placeholder="请输入人员姓名"
                  clearable
                ></el-input>
                <el-cascader
                  ref="cascaderRef"
                  class="search-w190 wp-cascader align-top"
                  :options="groupList"
                  @change="changeCascader"
                  :props="defaultProps"
                  collapse-tags
                  placeholder="请选择所在分组"
                  clearable
                >
                </el-cascader>
                <el-select
                  class="search-w190 wp-select"
                  v-model="searchParams.status"
                  clearable
                  placeholder="请选择优评结果"
                >
                  <el-option
                    v-for="item in $globalData.EXCELLENT_REVIEW_STATUS"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </SearchCard>
          </el-tab-pane>
        </TabsCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="flex">
              <!-- 未勾选数据 -->
              <el-button
                class="wp-button"
                type="primary"
                @click="clickBatchBtn"
                :disabled="!multipleSelection || multipleSelection.length == 0"
                >批量优评</el-button
              >

              <el-button
                @click="exportFile"
                class="wp-button wp-btn-icon"
                size="medium"
                icon="icon-daochu"
                >导出
              </el-button>
            </div>

            <div class="fs-14 flex">
              <div>
                上报数量：<span class="wp-blue">{{ total || 0 }}</span>
              </div>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <!-- 信息列包括“序号、评选时段、任务标题、截图、评论内容、姓名、账号、评选结果、上报时间、操作” -->
            <el-table
              :data="tableData"
              stripe
              v-if="tableData.length !== 0"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
            >
              <el-table-column align="center" type="selection" width="55">
              </el-table-column>
              <el-table-column
                align="center"
                type="index"
                label="序号"
                max-width="70"
                min-width="30"
              >
              </el-table-column>
              <el-table-column max-width="20" min-width="5"> </el-table-column>
              <el-table-column
                align="left"
                prop="taskName"
                max-width="120"
                min-width="150"
                show-overflow-tooltip
                label="评选时段"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="title"
                max-width="120"
                min-width="100"
                show-overflow-tooltip
                label="任务标题"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :tabindex="scope.$index"
                    :disabled="!isShowTooltipWidth"
                    :content="scope.row.title"
                    placement="top"
                  >
                    <div
                      class="textOverOneLine active-color"
                      @mouseenter="visibilityChangeWidth($event)"
                      @click="goDetail(scope.row, scope.$index)"
                    >
                      {{ scope.row.title }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="fileJson"
                max-width="150"
                min-width="100"
                label="截图/附件"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="goDetail(scope.row, scope.$index)"
                    style="cursor: pointer"
                  >
                    <p
                      v-if="JSON.parse(scope.row.fileJson).length"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="20" min-width="5"> </el-table-column>
              <el-table-column
                align="left"
                prop="content"
                label="评论内容"
                min-width="120"
                max-width="130"
                show-overflow-tooltip
              >
              </el-table-column>

              <el-table-column max-width="50" min-width="20"> </el-table-column>
              <el-table-column
                align="left"
                prop="userName"
                max-width="120"
                min-width="100"
                show-overflow-tooltip
                label="姓名"
              >
              </el-table-column>
              <el-table-column width="10"> </el-table-column>
              <el-table-column
                align="left"
                prop="loginName"
                max-width="120"
                min-width="100"
                show-overflow-tooltip
                label="账号"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="userGroupName"
                label="所在分组"
                min-width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="评选结果"
                min-width="90"
              >
                <template slot-scope="scope">
                  <div
                    class="flex-center"
                    v-if="
                      scope.row.status == $globalData.EXCELLENT_STATUS.EXCELLENT
                    "
                  >
                    <div class="success-circle"></div>
                    <div class="success-color">优评</div>
                  </div>
                  <div class="flex-center" v-else>
                    <div class="disabled-color">-</div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="createUserName"
                label="上报人"
                min-width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="上报时间"
                min-width="150"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <div class="flex-center">
                    <div
                      class="wp-action-icon"
                      v-if="
                        scope.row.status !=
                        $globalData.EXCELLENT_STATUS.EXCELLENT
                      "
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="优评"
                        placement="top"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                      >
                        <el-button
                          @click="handlerExcellent(scope.row)"
                          type="text"
                          icon="icon-excellent"
                          class="wp-btn-icon-big"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div class="wp-action-icon" v-else>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="撤销优评"
                        placement="top"
                        :visible-arrow="false"
                        popper-class="wp-btn-tooltip"
                      >
                        <el-button
                          @click="handlerReject(scope.row)"
                          type="text"
                          icon=" icon-revokeexcellent"
                          class="wp-btn-icon-big wp-btn-icon-big-red"
                        >
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <div ref="hideDiv"></div>
  </div>
</template>

<script>
import reportedApi from '@/api/reported.js'
import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import TabsCard from '@/views/components/tabsCard'
import { mapGetters } from 'vuex';

import { excellentList, excellentPageList, exportExcellentByPage, batchUpdateStatus, excellentStatus } from '@/api/excellentReport.js'
export default {
  name: 'taskReview',
  components: { SearchCard, TaskTimeSelect, TabsCard },
  data () {
    return {
      taskObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 任务名称
        limit: 10, // 页大小
      },
      // 查询条件
      searchParams: {
        page: 1,
        limit: 30,
        taskId: '',
        taskName: '',
        title: '',
        userName: '',
        startTime: "",
        endTime: "",
        status: '',
        groupIds: []
      },
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      groupList: [], // 分组树
      tableData: [],
      total: 0,
      multipleSelection: [],
      activeTab: "excellentReview", // 所选tab
      tabs: [
        { label: '投稿审核', name: "taskReview" },
        { label: '优评审核', name: "excellentReview" }
      ],
      timeSection: '',
      searchParamsTemp: {}, //深拷贝后的搜素条件
      excellents: [],
      isExporting: false,
      isShowTooltipWidth: false
    };
  },

  created () {
    // 获取审核列表
    this.getReviewList()
    // 获取分组树
    this.getGroupTree()
    this.getExcellentList()
  },
  computed: {
    ...mapGetters(['userInfo']),
    userId () {
      return this.userInfo.userId
    }
  },
  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    /** 导出 */
    async exportFile (event) {
      if (this.total > 30000) return this.$showMessage({
        type: 'warning',
        msg: '最多导出30000条，请分批导出'
      })
      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await exportExcellentByPage({ ...this.searchParamsTemp })
        this.isExporting2 = false
        if (res.returnCode == 200) {
          this.isExporting2 = false
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }

    },
    /** 搜索回调 */
    handlerSearch () {
      this.searchParams.page = 1
      this.getReviewList()
    },
    refresh () {
      this.searchParams.page = 1
      this.getReviewList()
    },



    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },

    /** 获取分组树 */
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },



    /** 审核列表 */
    async getReviewList () {
      if (this.loading) return
      this.loading = true
      this.searchParamsTemp = JSON.parse(JSON.stringify(this.searchParams))
      // 设置条件缓存
      // localStorage.setItem('excellentDetailManage', JSON.stringify({ ...this.searchParams }))
      try {
        const res = await excellentPageList(this.searchParams)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData
          this.tableData = data?.data || []
          this.total = data?.totalCount || 0
        }
      } catch (error) {
        this.loading = false
      }
    },
    // 批量优评
    clickBatchBtn () {
      // 判断数据中是否有已优评的数据
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        return this.$showMessage({
          type: 'error',
          msg: '请选择要操作的数据'
        })
      } else {
        this.$wpConfirm('提示', {
          tips: "确认批量设为优评吗？",
          content: "",
          yesBtnText: '确定',
          isShowCancelBtn: true, //是否显示取消按钮
          cancelBtnText: '取消',
        }).then(() => {
          this.postBatchUpdateStatus(this.multipleSelection.map(it => it.id))
        }).catch(() => {
          //点取消
          console.log("取消")
        });
      }
    },

    // 详情
    goDetail (item, idx) {
      const acticleIdx = (this.searchParams.page - 1) * this.searchParams.limit + idx + 1
      const excellentReport = localStorage.getItem('excellentReport')
      let ind = 1
      if (excellentReport) {
        ind = JSON.parse(excellentReport).ind + 1
      }
      localStorage.setItem('excellentReport' + ind, JSON.stringify({ ind, searchParams: this.searchParamsTemp }))
      this.$router.push({
        path: '/excellentDetailManage',
        query: {
          id: this.$encrypt(item.id.toString()),
          acticleIdx: acticleIdx,
          storageKey: 'excellentReport' + ind
        }
      })
    },
    // 表格勾选回调
    handleSelectionChange (arr) {
      console.log(arr);
      this.multipleSelection = arr
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getReviewList()
    },
    handlerTabs (name) {
      if (name != "excellentReview") {
        this.$router.push('/taskReview')
      }
    },
    changeTimeSection (newVal) {
      if (newVal) {
        this.searchParams.startTime = newVal[0]
        this.searchParams.endTime = newVal[1]
      } else {
        this.timeSection = ""
        this.searchParams.startTime = ""
        this.searchParams.endTime = ""
      }
    },
    handlerExcellent (item) {
      this.$wpConfirm('提示', {
        tips: "确认设为优评吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postExcellentStatus(item.id, this.$globalData.EXCELLENT_STATUS.EXCELLENT)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    handlerReject (item) {
      this.$wpConfirm('提示', {
        tips: "确定撤销优评吗？",
        content: "",
        yesBtnText: '确定',
        isShowCancelBtn: true, //是否显示取消按钮
        cancelBtnText: '取消',
      }).then(() => {
        this.postExcellentStatus(item.id, this.$globalData.EXCELLENT_STATUS.NO_EXCELLENT)
      }).catch(() => {
        //点取消
        console.log("取消")
      });
    },
    // 优评分类
    async getExcellentList () {
      try {
        const res = await excellentList({ createId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.excellents = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    changeTaskId (val) {
      if (val) {
        const item = this.excellents.find(it => it.id == val)
        this.searchParams.taskName = item.taskName
      } else {
        this.searchParams.taskName = ''
      }
    },
    // 优评状态
    async postExcellentStatus (id, status) {
      try {
        const res = await excellentStatus({ id: id, status: status })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.loading = false
      }
    },
    // 批量优评
    async postBatchUpdateStatus (ids) {
      try {
        const res = await batchUpdateStatus({ ids: ids, status: this.$globalData.EXCELLENT_STATUS.EXCELLENT })
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.refresh()
      } catch (error) {
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss">
.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}

.export-box {
  margin-left: 12px;
}
</style>
<style scoped lang="scss">
@import './index.scss';
</style>
