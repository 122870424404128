import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/flexible'
import './directive'
import '../src/assets/css/reset.css' //重置base
import '../src/assets/css/animate.css' //animate.css
import ElementUI from 'element-ui';
Vue.use(ElementUI);
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用
import axios from 'axios'
// import axios from '@/utils/requestTwo'
Vue.prototype.$axios = axios;
// Vue.prototype.$store = store;
Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()

import VueCookies from 'vue-cookies'
import permission from './directive/permission'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

// 分页加载搜索框
import loadSelect from '@johnhom/el-load-select'

Vue.use(loadSelect)

import './assets/icons' // icon
import './permission' // permission control
import '@/utils/utils'
import utils from '@/utils/utils'
import globalData from '@/utils/global'
Vue.prototype.$globalData = globalData
Vue.prototype.$utils = utils
Vue.use(permission)

import ENUM from '@/utils/enum.js'
Vue.prototype.$ENUM = ENUM

Vue.use(VueCookies)
import WpUI from './components/wpUi/index.js'
Vue.use(WpUI);
import '../src/assets/fontello-6567f619/css/fontello.css'
import "@/assets/less/common.scss"
import "@/assets/less/flex.scss"


import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import { encrypt, decrypt } from '@/utils/jsencrypt.js'
Vue.prototype.$encrypt = encrypt
Vue.prototype.$decrypt = decrypt



Array.prototype.remove = function (val) {
  let index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')