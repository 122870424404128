<template>
  <div class="bulletChat">
    <div class="top3-block flex-CB">
      <div class="top3-item red flex-CB">
        <div
          class="medal-item flex-column-center"
          v-for="(item, index) in redTop"
          :key="item.id"
        >
          <div class="medal-info">
            <div class="medal-header">
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + jinPai + ');'"
                v-if="index == 0"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + yinPai + ');'"
                v-if="index == 1"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + dong + ');'"
                v-if="index == 2"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div class="name textOverOneLine">
                {{ item.nickName }}（{{ item.realName }}）
              </div>
            </div>
            <div class="first-bg" v-if="index == 0 && redShow"></div>
          </div>
          <div class="number-box flex-center">
            <img
              src="../../assets/images/drill/num1.png"
              alt=""
              v-if="index == 0"
            />
            <img
              src="../../assets/images/drill/num2.png"
              alt=""
              v-if="index == 1"
            />
            <img
              src="../../assets/images/drill/num3.png"
              alt=""
              v-if="index == 2"
            />
          </div>
        </div>
        <div
          class="medal-item flex-column-center"
          v-for="item in 3 - redTop.length"
          :key="item"
        ></div>
      </div>
      <div class="top3-item blue flex-CB">
        <div
          class="medal-item flex-column-center"
          v-for="(item, index) in blueTop"
          :key="item.id"
        >
          <div class="medal-info">
            <div class="medal-header">
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + jinPai + ');'"
                v-if="index == 0"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + yinPai + ');'"
                v-if="index == 1"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div
                class="bgIndex flex-center"
                :style="'background-image: url(' + dong + ');'"
                v-if="index == 2"
              >
                <el-image :src="item.imgUrl" alt="">
                  <div slot="error">
                    <img :src="$globalData.failAvatar" alt="" />
                  </div>
                </el-image>
              </div>
              <div class="name textOverOneLine">
                {{ item.nickName }}（{{ item.realName }}）
              </div>
            </div>
            <div class="first-bg" v-if="index == 0 && blueShow"></div>
          </div>
          <div class="number-box flex-center">
            <img
              src="../../assets/images/drill/num1.png"
              alt=""
              v-if="index == 0"
            />
            <img
              src="../../assets/images/drill/num2.png"
              alt=""
              v-if="index == 1"
            />
            <img
              src="../../assets/images/drill/num3.png"
              alt=""
              v-if="index == 2"
            />
          </div>
        </div>
        <div
          class="medal-item flex-column-center"
          v-for="item in 3 - blueTop.length"
          :key="item"
        ></div>
      </div>
    </div>
    <div class="bulletChat-block" ref="bulletChatBlock">
      <j-barrage
        ref="jBarrage"
        :repetition="repetition"
        :barrageClassName="barrageClassName"
        :barrageDate="barrageDate"
        :laneNum="laneNum"
        :time="10"
        :boxHeight="boxHeight"
        :full="false"
        :showBtn="true"
        startFrom="right"
      ></j-barrage>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as  drillApi from '@/api/drill.js'
import JBarrage from '@/views/drill/components/bullet.vue';
export default {
  name: 'bulletChat',
  components: { JBarrage },
  data () {
    return {
      jinPai: require('../../assets/images/drill/jin2.png'),
      yinPai: require('../../assets/images/drill/yin2.png'),
      dong: require('../../assets/images/drill/tong2.png'),
      messageHeight: 90,
      boxHeight: 300,
      barrageList: [],
      taskId: '',// 任务id
      taskDetial: {},
      taskStatus: 0, //任务状态0未开始1进行中2已结束
      updateFrequency: 10,
      topCommentsId: [],
      topComments: [],
      redTop: [],
      blueTop: [],
      scrollList: [],
      isPlayback: false,
      laneNum: 3,
      barrageDate: [],
      oncePost: 1,
      repetition: false,
      timerList: [],
      onceEnd: 1,// 第一次结束
      barrageClassName: 'j-barrage-span',// 弹幕的元素的类名
      resizeTimer1: null,
      resizeTimer2: null,
      redShow: false,
      blueShow: false,
    };
  },
  props: {},
  created () {
    if (this.$route.query.id) {
      this.taskId = this.$decrypt(this.$route.query.id)
      // 判断是不是演练回放
      if (this.$route.meta.flag && this.$route.meta.flag == 'playback') {
        this.isPlayback = true

      }
      this.getDetailTask()
    } else {
      return this.$router.push({ name: 'drillList' })
    }
    let that = this
    if (this.isPlayback) return

    window.addEventListener('resize', () => {
      clearTimeout(that.resizeTimer2)
      that.resizeTimer2 = setTimeout(() => {
        that.getDetailTask()
      }, 500)
    });
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['allCommentList', 'frequency', 'redTopList', 'blueTopList', 'isPlaybackEnd', 'visibleNum']),
    taskStatusP () {
      return this.$store.getters.taskStatus
    },

  },
  methods: {
    // 获取详情
    async getDetailTask () {
      let query = {
        id: this.taskId
      }
      let res = await drillApi.detailTask(query)
      if (res.returnCode != 200) return
      this.taskDetial = res.returnData.taskDetial
      this.taskStatus = this.taskDetial.taskStatus
      if (this.isPlayback) return this.initPlayback()
      //任务状态0未开始1进行中2已结束
      if (this.taskStatus == 1) {
        this.setProgress()
      } else if (this.taskStatus == 2) {
        // 演练结束
        // 这里每次切换标签都会执行一次，导致会重叠弹幕 
        // 又可以生成弹幕组了
        this.repetition = true

        this.setEnd()
      }
    },
    // 进行中
    setProgress () {
      // 开启定时器 获取数据
      // 获取列表
      // 获取积分
      // 获取折线图
      // 获取比分
      let that = this
      that.getArticleList()
      that.getHotCommentRankingList(1)
      that.getHotCommentRankingList(2)
      if (that.timerFour) { this.clearIntervalFour() }
      that.timerFour = setInterval(() => {
        that.getArticleList()
        that.getHotCommentRankingList(1)
        that.getHotCommentRankingList(2)
      }, that.updateFrequency * 1000);
    },
    clearIntervalFour () {
      clearInterval(this.timerFour)
      this.timerFour = null
    },
    // 结束
    setEnd () {
      this.clearIntervalFour()
      //获取一次
      this.getArticleList()
      this.getHotCommentRankingList(1)
      this.getHotCommentRankingList(2)
    },
    //红蓝热评列表、弹幕列表、结算页热评Top5
    async getArticleList () {
      let query = {
        taskId: this.taskId,
        pageNo: 1,
        limit: 1000,
      }
      const res = await drillApi.articleList(query);
      if (res.returnCode != 200) return;
      this.setVueSeamlessScroll(res.returnData.data)

    },
    //1.实时弹幕页中的红蓝Top3
    //2.结算弹窗中的得分TOP5
    async getHotCommentRankingList (typeId) {
      const res = await drillApi.getHotCommentRankingList({ taskId: this.taskId, typeId: typeId });
      if (res.returnCode != 200) return;
      if (typeId == 1) {
        this.redTop = this.$utils.handleAvatar(res.returnData.splice(0, 3).map((item, index) => {
          return { ...item, index: index + 1 }
        }))
      } else if (typeId == 2) {
        this.blueTop = this.$utils.handleAvatar(res.returnData.splice(0, 3).map((item, index) => {
          return { ...item, index: index + 1 }
        }))
      }
      this.whoShow()
    },
    // 设置弹幕内容
    setVueSeamlessScroll (data) {
      if (data.length == 0) return
      let dataList = data.map(item => {
        return { ...item, className: item.typeId == 1 ? 'red' : 'blue' }
      })
      //过滤出之前没有滚动过的，将新的id添加进入topCommentsId 
      // 正在进行时 或者 回放且正在回放
      if (this.taskStatus == 1 || (this.isPlayback && !this.isPlaybackEnd)) {
        let topComments = []
        dataList.forEach(item => {
          if (this.topCommentsId.indexOf(item.id) == -1) {
            topComments.push(item)
          }
        })
        this.topComments = topComments
        // 保存已经滚动过的弹幕id
        this.topCommentsId = [...this.topCommentsId, ...topComments.map(item => item.id)]
      } else if (this.taskStatus == 2) {
        // 已结束的 直接 将所有数据放在弹幕组
        this.topComments = dataList
      }

      // 根据 高度 和 泳道的高度 去 计算 会有多少条泳道
      // 根据泳道数量去  平均分布数组
      // 设置不同泳道的 速度

      this.$nextTick(() => {
        if (this.$refs.bulletChatBlock) {
          // 获取弹幕的高度
          this.boxHeight = this.$refs.bulletChatBlock?.offsetHeight
          // 计算 会有多少条泳道
          this.laneNum = Math.floor(this.boxHeight / (this.messageHeight + 10))
          let laneNum = this.laneNum
          let result = []
          let barrageDateList = []
          // 速度
          let times = [10, 14, 12, 13]
          for (let i = 0, len = this.topComments.length; i < len; i += laneNum) {
            result.push(this.topComments.slice(i, i + laneNum));
          }
          result.forEach((item) => {
            barrageDateList = [...barrageDateList, ...(item.map((it, ind) => {
              return { ...it, time: times[ind], position: ind + 1 }
            }))]
          })
          // 首次的 生成弹幕组
          if (this.oncePost == 1) {
            this.clearTimerList()
            this.oncePost++
            this.barrageDate = JSON.parse(JSON.stringify(barrageDateList))
            this.$refs.jBarrage.initDate()
          } else if (this.taskStatus == 1 || (this.isPlayback && !this.isPlaybackEnd)) {
            // 正在进行时 或者 回放且正在回放
            this.clearTimerList()
            let timerList = []
            // 没有发过的 一个弹幕一个弹幕的发 或者 延时发
            JSON.parse(JSON.stringify(barrageDateList)).forEach((item, index) => {
              let timer = setTimeout(() => {
                this.$refs.jBarrage.sendBarrage(item);
              }, index * 1500);
              timerList.push(timer)
            })
            // 定时器的id 数组
            this.timerList = timerList
          }
        }
      })
    },
    // 清除 定时器
    clearTimerList () {
      this.timerList.forEach(item => {
        clearTimeout(item)
        item = null
      })
    },
    //将数组分成几组 array原数组，subGroupLength 一个数组的长度
    supGroup: function (array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    // 演练回放的处理逻辑
    initPlayback () {
      this.$nextTick(() => {
        this.redTop = this.redTopList?.length > 0 ? this.$utils.handleAvatar(this.redTopList.slice(0, 3)) : []
        this.blueTop = this.blueTopList?.length > 0 ? this.$utils.handleAvatar(this.blueTopList.slice(0, 3)) : []
        this.setVueSeamlessScroll(this.allCommentList)
        this.whoShow()
      })
    },
    // 计算弹幕 泳道
    getlaneNum () {
      // 计算 会有多少条泳道
      this.laneNum = Math.floor(this.boxHeight / (this.messageHeight + 10))
      let scrollList = []
      let step = [2, 4, 3, 3.5, 4.5, 2.5]
      for (let i = 0; i < this.laneNum; i++) {
        scrollList.push({
          topComments: [],
          remainLen: 4,
          optionBlueList: {
            step: step[i] ? step[i] : 0.5, // 数值越大速度滚动越快
            limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: false, // 是否开启鼠标悬停stop
            direction: 2, // 0向下 1向上 2向左 3向右
            openWatch: false // 开启数据实时监控刷新dom}
          }
        })
      }
      this.scrollList = scrollList
    },
    // 计算哪个应该高亮
    whoShow () {
      let redScore = this.redTop[0] ? this.redTop[0].score : 0
      let blueScore = this.blueTop[0] ? this.blueTop[0].score : 0
      this.redShow = redScore > blueScore
      this.blueShow = blueScore > redScore
    }

  },
  beforeDestroy () {
    this.clearIntervalFour()
    this.clearTimerList()
  },
  watch: {
    taskStatusP: {
      handler (val, oldval) {
        if (val) {
          if (val == 2) {
            this.oncePost = 1

          }
          this.getDetailTask()
        }
      },
      deep: true
    },
    frequency: {
      handler (val, oldval) {
        if (val) {
          if (this.isPlayback) {
            this.initPlayback()
          }
        }
      },
      deep: true
    },
    isPlaybackEnd: {
      handler (val, oldval) {
        if (val) {
          if (this.isPlayback) {
            this.oncePost = 1
            this.repetition = true
          }
        }
      },
      deep: true
    },
    // 页面的显示与隐藏监听
    visibleNum: {
      handler (val, oldval) {
        let that = this
        if (val && !this.isPlayback) {
          // 重新监听是否结束
          if (document.visibilityState === 'visible' && that.taskStatus != 2) {
            that.getDetailTask()
          }
          if (that.taskStatus == 2) {
            // 清除弹幕,每次回到标签页清空已生成的弹幕
            that.$refs.jBarrage.clearBarrageAll()
          }
        }
      },
      deep: true
    }
  },
};
</script>
<style scoped lang="scss">
@import './css/bulletChat.scss';
</style>
