var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "asideList" }, [
    _c("div", { staticClass: "head" }, [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-input",
            {
              staticClass: "search-w176 wp-input",
              attrs: { clearable: "", placeholder: "请输入搜索内容" },
              model: {
                value: _vm.searchContent,
                callback: function ($$v) {
                  _vm.searchContent = $$v
                },
                expression: "searchContent",
              },
            },
            [
              _c("i", {
                staticClass: "wp-input-icon icon-sousuo",
                staticStyle: { "font-size": "20px" },
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "complete flex-CB",
        class: _vm.activeId == 0 ? "active_item" : "",
        on: { click: _vm.clickAll },
      },
      [
        _c("div", { staticClass: "fs-16 fw-bold" }, [_vm._v("全部")]),
        _c("div", [
          _c("i", {
            staticClass: "el-input__icon icon-tianjia fs-20",
            on: {
              click: function ($event) {
                return _vm.handlerItem(["add", { id: 0 }])
              },
            },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "list-box",
        attrs: {
          "element-loading-spinner": "el-icon-loading",
          "element-loading-text": "正在加载",
        },
      },
      [
        _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.asideList, function (item, index) {
              return _c(
                "div",
                {
                  key: item[_vm.keyId],
                  staticClass: "list-item flex-CB",
                  class: _vm.activeId == item[_vm.keyId] ? "active_item" : "",
                  on: {
                    click: function ($event) {
                      return _vm.getTitle(item, index)
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        tabindex: item[_vm.keyId],
                        disabled: !_vm.isShowTooltipWidth,
                        content: item[_vm.label],
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item fs-14 textOverOneLine",
                          on: {
                            mouseenter: function ($event) {
                              return _vm.visibilityChangeWidth($event)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item[_vm.label]) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "wp-dropdown",
                      attrs: { placement: "bottom-end", trigger: "click" },
                      on: { command: _vm.handlerItem },
                    },
                    [
                      _c(
                        "div",
                        { ref: "icon", refInFor: true, staticClass: "icon" },
                        [_c("i", { staticClass: "el-input_icon el-icon-more" })]
                      ),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "wp-dropdown-menu",
                          attrs: { slot: "dropdown", visibleArrow: false },
                          slot: "dropdown",
                        },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: ["edit", item, index],
                                icon: "icon-bianji1",
                              },
                            },
                            [_vm._v("修改名称")]
                          ),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: ["remove", item, index],
                                icon: "icon-shanchu",
                              },
                            },
                            [_vm._v("删除")]
                          ),
                          index !== 0
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: ["shiftUp", item, index],
                                    icon: "icon-shangyi",
                                  },
                                },
                                [_vm._v("上移")]
                              )
                            : _vm._e(),
                          index !== _vm.asideList.length - 1
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: ["shiftDown", item, index],
                                    icon: "icon-xiayi",
                                  },
                                },
                                [_vm._v("下移")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }