import { post, get, form } from '@/utils/requestTwo'

// 优评分类
export function excellentList (query) {
	return post(`/excellent/list`, query, false)
}
// 新增优评
export function insertEexcellentArticle (query) {
	return post(`/excellentArticle/insert`, query, false)
}
// 修改优评
export function updateEexcellentArticle (query) {
	return post(`/excellentArticle/update`, query, false)
}
// 删除优评
export function deleteEexcellentArticle (query) {
	return post(`/excellentArticle/delete`, query, true)
}
// 优评分页查询
export function excellentPageList (query) {
	return post(`/excellentArticle/pageList`, query, false)
}
// 单个查询
export function getExcellent (query) {
	return post(`/excellentArticle/get`, query, true)
}
// 修改状态优评
export function excellentStatus (query) {
	return post(`/excellentArticle/status`, query, true)
}
// 优评获取组员列表
export function userListByGroupId (query) {
	return post(`/sysUser/userListByGroupId`, query, false)
}
// 优评分页导出
export function exportExcellentByPage (query) {
	return post(`/excellentArticle/export`, query, false)
}
// 优评数据审核逐条审核
export function examineExcellent (query) {
	return post(`/excellentArticle/articleIds`, query, false)
}
// 批量修改状态优评
export function batchUpdateStatus (query) {
	return post(`/excellentArticle/batchUpdateStatus`, query, false)
}

