<template>
  <div class="parameterConfig">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="layout-main">
        <!-- 投稿中心 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">投稿中心-任务设置</div>
          <div class="layout-content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleform">
              <el-form-item label="任务时间：" required :error="taskTimeValue.error">
                <el-radio-group v-model="taskTimeValue.configCheck" class="wp-radio-group">
                  <template v-for="item in taskTime">
                    <el-radio :label="item.custConfigId" v-if="item.configValue != 999" :key="item.custConfigId">
                      <el-input placeholder="" maxlength="2" v-model.number="item.configValue" @change="resetNum(item)" class="wp-input-select">
                        <el-select v-model="item.configUnit" slot="append" placeholder="">
                          <!-- CONFIG_UNIT为单位1是小时2是天 -->
                          <el-option label="小时" value="1"></el-option>
                          <el-option label="天" value="2"></el-option>
                        </el-select>
                      </el-input>
                    </el-radio>
                    <el-radio :label="item.custConfigId" v-else :key="item.configValue">
                      <div class="disable-input">月底</div>
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="任务积分：" required :error="taskScoreValue.error">
                <el-radio-group v-model="taskScoreValue.configCheck" class="wp-radio-group">
                  <el-radio :label="item.custConfigId" v-for="item in taskScore" :key="item.custConfigId">
                    <el-input placeholder="" maxlength="4" v-model="item.configValue" @change="resetNumPoints(item)" class="wp-short-input">
                    </el-input>
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="字数要求：" required :error="taskWordsRequireValue.error">
                <el-radio-group v-model="taskWordsRequireValue.configCheck" class="wp-radio-group">
                  <template v-for="item in taskWordsRequire">
                    <el-radio :label="item.custConfigId" :key="item.configValue" v-if="item.configValue == 9999">
                      <div class="disable-input">不限</div>
                    </el-radio>
                    <el-radio :label="item.custConfigId" :key="item.custConfigId" v-else>
                      <el-input placeholder="" maxlength="2" v-model.number="item.configValue" @change="resetNumCounts(item)" class="wp-short-input">
                      </el-input>
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="单人上限：" required :error="taskSingleLimitValue.error">
                <el-radio-group v-model="taskSingleLimitValue.configCheck" class="wp-radio-group">
                  <template v-for="item in taskSingleLimit">
                    <el-radio :label="item.custConfigId" :key="item.custConfigId" v-if="item.configValue != 9999">
                      <el-input placeholder="" maxlength="2" v-model.number="item.configValue" @change="resetNumPersons(item)" class="wp-short-input">
                      </el-input>
                    </el-radio>
                    <el-radio :label="item.custConfigId" v-else :key="item.configValue">
                      <div class="disable-input">不限</div>
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="layout-title layout-title2">投稿中心-高级设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item :label="item.configName + '：'" v-for="item in advancedSetting" :key="item.custConfigId">
                <el-checkbox v-model="item.isShow" :disabled="item.isDisbled" @change="checkboxChangeShow(item)">显示
                </el-checkbox>
                <el-checkbox v-model="item.isRequired" :disabled="item.isDisbled" @change="checkboxChangeRequired(item)">必填
                </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <div class="layout-title layout-title2">投稿中心-其他设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item label="短信通知：">
                <el-checkbox v-model="taskSmsNotice">默认选中 </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 上报中心 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">上报中心-专题设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="ruleform">
              <el-form-item label="起止时间：" required :error="subjectStartEndTimeValue.error">
                <el-radio-group v-model="subjectStartEndTimeValue.configCheck" class="wp-radio-group">
                  <template v-for="item in subjectStartEndTime">
                    <el-radio :label="item.custConfigId" v-if="item.configValue != 999 && item.configValue != 9999" :key="item.custConfigId">
                      <el-input placeholder="" maxlength="2" v-model.number="item.configValue" @change="resetNumReportIime" class="wp-input-select">
                        <el-select v-model="item.configUnit" slot="append" placeholder="">
                          <!-- CONFIG_UNIT为单位1是小时2是天 -->
                          <el-option label="小时" value="1"></el-option>
                          <el-option label="天" value="2"></el-option>
                        </el-select>
                      </el-input>
                    </el-radio>
                    <el-radio :label="item.custConfigId" v-if="item.configValue == 999" :key="item.custConfigId">
                      <div class="disable-input">月底</div>
                    </el-radio>
                    <el-radio :label="item.custConfigId" v-if="item.configValue == 9999" :key="item.custConfigId">
                      <div class="disable-input">不限</div>
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="专题积分：" required :error="subjectScoreValue.error">
                <el-radio-group v-model="subjectScoreValue.configCheck" class="wp-radio-group" @change="changeSubjectScoreValue">
                  <template v-for="item in subjectScore">
                    <el-radio :label="item.custConfigId" v-if="item.custConfigId != subjectScoreId" :key="item.custConfigId">
                      <el-input placeholder="" maxlength="5" v-model="item.configValue" @change="resetNumPoints2(item)" class="wp-short-input">
                      </el-input>
                    </el-radio>
                    <el-radio :label="item.custConfigId" v-if="item.custConfigId == subjectScoreId" :key="item.custConfigId" :disabled="isDisbledPlatform">
                      <div class="disable-input">按平台</div>
                    </el-radio>
                  </template>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>

          <div class="layout-title layout-title2">上报中心-高级设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form advanced-checkboxs">
              <el-form-item :label="item.configName + '：'" v-for="item in advancedSetting2" :key="item.custConfigId">
                <el-checkbox v-model="item.isShow" :disabled="item.isDisbled" @change="checkboxChangeShow2(item)">显示
                </el-checkbox>
                <el-checkbox v-model="item.isRequired" :disabled="item.isDisbledRequired" @change="checkboxChangeRequired2(item)">必填
                </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <div class="layout-title layout-title2">上报中心-其他设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item label="发送短信：">
                <el-checkbox v-model="subjectSmsNotice">默认选中 </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 投稿演练 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">投稿演练-演练设置</div>
          <div class="layout-content">
            <el-form label-width="100px" class="advanced-form">
              <el-form-item :label="item.configName + '：'" required v-for="item in drillSetting" :key="item.id" :error="item.error">
                <el-input placeholder="" maxlength="4" v-model="item.configValue" @change="resetNumDrillSetting(item)" class="wp-short-input">
                </el-input><span class="nuit-block">{{ item.unit }}</span>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="layout-block wp-moudle-style btn-block">
          <!-- <el-button class="wp-button" size="medium">取消</el-button> -->
          <el-button class="wp-button" size="medium" v-preventReClick type="primary" @click="sure">确定
          </el-button>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { resetNumPointOne, resetNumInt, resetNumzero } from '@/utils/validate.js';
import { sysCustConfigList, sysCustConfigBatchUpdate, sysCustWebsiteList } from '@/api/systemSettings.js';
export default {
  name: 'parameterConfig',
  components: {},
  data () {
    return {
      ruleForm: {},
      rules: {},
      taskSmsNotice: true,// 短信通知
      subjectSmsNotice: true,// 短信通知
      //投稿中心-高级设置
      advancedSetting: [],
      //上报中心-高级设置
      advancedSetting2: [],
      //投稿演练
      drillSetting: [],
      // 任务时间的值与错误提示
      taskTimeValue: {
        configCheck: '',
        error: ""
      },
      taskTime: [],
      // 任务积分的值与错误提示
      taskScoreValue: {
        configCheck: '',
        error: ""
      },
      taskScore: [],
      // 字数要求
      taskWordsRequireValue: {
        configCheck: '',
        error: ""
      },
      taskWordsRequire: [],
      // 单人上限
      taskSingleLimit: [],
      taskSingleLimitValue: {
        configCheck: '',
        error: ""
      },
      // 上报 起止时间的值与错误提示
      subjectStartEndTimeValue: {
        configCheck: '',
        error: ""
      },
      subjectStartEndTime: [],
      // 专题积分 
      subjectScoreValue: {
        configCheck: '',
        error: ""
      },
      subjectScore: [],
      isDisbledPlatform: true,
      subjectScoreId: '',
      oldSubjectScoreValueConfigCheck: '' // 专题积分上一个勾选的值
    };
  },
  props: {},
  created () {

  },
  mounted () {
    this.getSysCustConfigList()
    this.getSysCustWebsiteList()
  },
  computed: {
    ...mapGetters(['sysCust'])
  },
  methods: {
    // 任务时间 输入框 验证并重置数值
    resetNum () {
      this.taskTime.forEach(element => {
        element.configValue = resetNumInt(element.configValue)

      });
    },
    // 任务积分 输入框 验证并重置数值
    resetNumPoints () {
      this.taskScore.forEach(element => {
        element.configValue = resetNumPointOne(element.configValue, 1, 99)
      });
    },
    // 专题积分 输入框 验证并重置数值
    resetNumPoints2 () {
      this.subjectScore.forEach(element => {
        if (element.custConfigId != this.subjectScoreId) {
          element.configValue = resetNumPointOne(element.configValue, 1, 10000)
        }
      });
    },
    // 任务字数限 输入框 验证并重置数值
    resetNumCounts () {
      this.taskWordsRequire.forEach(element => {
        element.configValue = resetNumInt(element.configValue)
      });
    },
    // 任务上 输入框 验证并重置数值
    resetNumPersons () {
      this.taskSingleLimit.forEach(element => {
        element.configValue = resetNumInt(element.configValue)
      });
    },
    // 任务高级设置 改变显示 
    checkboxChangeShow (item) {
      this.advancedSetting.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },
    // 任务高级设置 改变必填 
    checkboxChangeRequired () {
      this.advancedSetting.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },
    // 上报中心 高级设置 改变显示 
    checkboxChangeShow2 (item) {
      this.advancedSetting2.forEach(element => {
        if (!element.isShow) {
          element.isRequired = false
        }
      });
    },
    // 上报中心 高级设置 改变必填 
    checkboxChangeRequired2 () {
      this.advancedSetting2.forEach(element => {
        if (element.isRequired) {
          element.isShow = true
        }
      });
    },
    // 上报中心-时间，数值重置
    resetNumReportIime () {
      this.subjectStartEndTime.forEach(element => {
        element.configValue = resetNumInt(element.configValue)
      });
    },
    // 投稿演练 数值 
    resetNumDrillSetting () {
      this.drillSetting.forEach(element => {
        // let value = resetNumzero(element.configValue)
        // if (value > element.max) {
        //   element.configValue = element.max
        // } else if (value < element.min) {
        //   element.configValue = element.min
        // } else {
        // }
        if (element.initType) {
          element.configValue = resetNumPointOne(element.configValue, element.min, element.max, true)
        } else {
          element.configValue = resetNumPointOne(element.configValue, element.min, element.max, false)
        }
      });
    },
    // 获取参数配置
    async getSysCustConfigList () {
      try {
        let res = await sysCustConfigList({})
        if (Number(res.returnCode) !== 200) return
        this.sysCustConfig = res.returnData
        this.setInitValue()
      } catch (error) {
        console.log(error)
      }
    },
    setInitValue () {
      // 任务时间
      this.sysCustConfig.taskTime.forEach(item => {
        if (item.configCheck == 1) {
          this.taskTimeValue.configCheck = item.custConfigId
        }
      })
      this.taskTime = this.sysCustConfig.taskTime
      // 任务积分
      this.sysCustConfig.taskScore.forEach(item => {
        if (item.configCheck == 1) {
          this.taskScoreValue.configCheck = item.custConfigId
        }
      })
      this.taskScore = this.sysCustConfig.taskScore
      // 字数要求 taskWordsRequire
      this.sysCustConfig.taskWordsRequire.forEach(item => {
        if (item.configCheck == 1) {
          this.taskWordsRequireValue.configCheck = item.custConfigId
        }
      })
      this.taskWordsRequire = this.sysCustConfig.taskWordsRequire
      //单人上限
      this.sysCustConfig.taskSingleLimit.forEach(item => {
        if (item.configCheck == 1) {
          this.taskSingleLimitValue.configCheck = item.custConfigId
        }
      })
      this.taskSingleLimit = this.sysCustConfig.taskSingleLimit
      //投稿中心-高级设置
      //作品标题 taskArticleTitle
      let advancedSetting = []
      let taskArticleTitle = this.sysCustConfig.taskArticleTitle[0].configValue?.toString()
      advancedSetting.push({ ...this.sysCustConfig.taskArticleTitle[0], isShow: taskArticleTitle.charAt(0) == '1', isRequired: taskArticleTitle.charAt(1) == '1', isDisbled: false })
      //作品内容 taskAarticleContent
      let taskAarticleContent = this.sysCustConfig.taskAarticleContent[0].configValue?.toString()
      advancedSetting.push({ ...this.sysCustConfig.taskAarticleContent[0], isShow: taskAarticleContent.charAt(0) == '1', isRequired: taskAarticleContent.charAt(1) == '1', isDisbled: false })
      //作品链接 taskArticleUrl
      let taskArticleUrl = this.sysCustConfig.taskArticleUrl[0].configValue?.toString()
      advancedSetting.push({ ...this.sysCustConfig.taskArticleUrl[0], isShow: taskArticleUrl.charAt(0) == '1', isRequired: taskArticleUrl.charAt(1) == '1', isDisbled: false })
      //附件/截图 taskArticleFile
      let taskArticleFile = this.sysCustConfig.taskArticleFile[0].configValue?.toString()
      advancedSetting.push({ ...this.sysCustConfig.taskArticleFile[0], isShow: taskArticleFile.charAt(0) == '1', isRequired: taskArticleFile.charAt(1) == '1', isDisbled: true })
      this.advancedSetting = advancedSetting
      // 短信通知 taskSmsNotice
      this.taskSmsNotice = this.sysCustConfig.taskSmsNotice[0].configValue == '1'
      // 上报中心
      // 起止时间 subjectStartEndTime
      this.sysCustConfig.subjectStartEndTime.forEach(item => {
        if (item.configCheck == 1) {
          this.subjectStartEndTimeValue.configCheck = item.custConfigId
        }
      })
      this.subjectStartEndTime = this.sysCustConfig.subjectStartEndTime
      // 专题积分 subjectScore
      this.sysCustConfig.subjectScore.forEach(item => {
        if (item.configCheck == 1) {
          this.oldSubjectScoreValueConfigCheck = item.custConfigId
          this.subjectScoreValue.configCheck = item.custConfigId
        }
        if (item.configValue == -999) {
          this.subjectScoreId = item.custConfigId
        }
      })
      this.subjectScore = this.sysCustConfig.subjectScore

      //上报中心-高级设置
      //作品标题 subjectArticleTitle
      let advancedSetting2 = []
      let subjectArticleTitle = this.sysCustConfig.subjectArticleTitle[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectArticleTitle[0], isShow: subjectArticleTitle.charAt(0) == '1', isRequired: subjectArticleTitle.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      //作品内容 subjectArticleContent
      let subjectArticleContent = this.sysCustConfig.subjectArticleContent[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectArticleContent[0], isShow: subjectArticleContent.charAt(0) == '1', isRequired: subjectArticleContent.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      //作品链接 subjectArticleUrl
      let subjectArticleUrl = this.sysCustConfig.subjectArticleUrl[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectArticleUrl[0], isShow: subjectArticleUrl.charAt(0) == '1', isRequired: subjectArticleUrl.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 依次新增“媒体平台、发布类型、作者类别、作者姓名、网名 / 昵称、阅读数量、转载数量”信息项，
      // 除（媒体平台、发布类型、截图附件）不可以取消勾选外其他均可以取消勾选）
      // 媒体平台subjectMediaPlatform
      let subjectMediaPlatform = this.sysCustConfig.subjectMediaPlatform[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectMediaPlatform[0], isShow: subjectMediaPlatform.charAt(0) == '1', isRequired: subjectMediaPlatform.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 发布类型subjectPublishType
      let subjectPublishType = this.sysCustConfig.subjectPublishType[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectPublishType[0], isShow: subjectPublishType.charAt(0) == '1', isRequired: subjectPublishType.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 作者类别subjectAuthorType
      let subjectAuthorType = this.sysCustConfig.subjectAuthorType[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectAuthorType[0], isShow: subjectAuthorType.charAt(0) == '1', isRequired: subjectAuthorType.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 作者姓名subjectAuthorName
      let subjectAuthorName = this.sysCustConfig.subjectAuthorName[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectAuthorName[0], isShow: subjectAuthorName.charAt(0) == '1', isRequired: subjectAuthorName.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 网名 / 昵称subjectNickName
      let subjectNickName = this.sysCustConfig.subjectNickName[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectNickName[0], isShow: subjectNickName.charAt(0) == '1', isRequired: subjectNickName.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 阅读数量subjectReadNum
      let subjectReadNum = this.sysCustConfig.subjectReadNum[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectReadNum[0], isShow: subjectReadNum.charAt(0) == '1', isRequired: subjectReadNum.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })

      // 转载数量subjectReprintNum
      let subjectReprintNum = this.sysCustConfig.subjectReprintNum[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectReprintNum[0], isShow: subjectReprintNum.charAt(0) == '1', isRequired: subjectReprintNum.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      // 全网推送 
      if (this.sysCustConfig.subjectPush) {
        let subjectPush = this.sysCustConfig.subjectPush[0].configValue?.toString()
        advancedSetting2.push({ ...this.sysCustConfig.subjectPush[0], isShow: subjectPush.charAt(0) == '1', isRequired: subjectPush.charAt(1) == '1', isDisbled: false, isDisbledRequired: false })
      }
      //附件/截图 subjectArticleFile
      let subjectArticleFile = this.sysCustConfig.subjectArticleFile[0].configValue?.toString()
      advancedSetting2.push({ ...this.sysCustConfig.subjectArticleFile[0], isShow: subjectArticleFile.charAt(0) == '1', isRequired: subjectArticleFile.charAt(1) == '1', isDisbled: true, isDisbledRequired: false })
      this.advancedSetting2 = advancedSetting2
      console.log('this.advancedSetting2', this.advancedSetting2)

      // 短信通知 subjectSmsNotice
      this.subjectSmsNotice = this.sysCustConfig.subjectSmsNotice[0].configValue == '1'
      //投稿演练 drillTime winScore hotCommentNum participateScore
      let drillSetting = []
      drillSetting.push({ ...this.sysCustConfig.drillTime[0], unit: '分钟', min: 5, max: 30, error: "", initType: true })
      drillSetting.push({ ...this.sysCustConfig.hotCommentNum[0], unit: '', min: 30, max: 100, error: "", initType: true })
      drillSetting.push({ ...this.sysCustConfig.winScore[0], unit: '', min: 0, max: 100, error: "", initType: false })
      drillSetting.push({ ...this.sysCustConfig.participateScore[0], unit: '', min: 0, max: 100, error: "", initType: false })
      this.drillSetting = drillSetting
      // 设置 上报中心-高级设置-勾选项 是否勾选 是否禁用
      this.setAdvancedSetting2Disable()
    },
    // 确定
    sure () {
      this.setQuery()
    },
    // 设置提交的参数
    setQuery () {
      // 任务时间
      this.taskTime.forEach(item => {
        item.configCheck = '0'
        if (this.taskTimeValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      // 任务积分
      this.taskScore.forEach(item => {
        item.configCheck = '0'
        if (this.taskScoreValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      //字数要求
      this.taskWordsRequire.forEach(item => {
        item.configCheck = '0'
        if (this.taskWordsRequireValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      //单人上限
      this.taskSingleLimit.forEach(item => {
        item.configCheck = '0'
        if (this.taskSingleLimitValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      //投稿中心-高级设置
      this.advancedSetting.forEach(item => {
        item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
      })
      // 短信通知 taskSmsNotice
      let taskSmsNotice = this.sysCustConfig.taskSmsNotice[0]
      taskSmsNotice.configValue = this.taskSmsNotice ? '1' : '0'
      // 上报中心
      // 起止时间 subjectStartEndTime
      this.subjectStartEndTime.forEach(item => {
        item.configCheck = '0'
        if (this.subjectStartEndTimeValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      // 专题积分 subjectScore
      this.subjectScore.forEach(item => {
        item.configCheck = '0'
        if (this.subjectScoreValue.configCheck == item.custConfigId) {
          item.configCheck = '1'
        }
      })
      //上报中心-高级设置
      this.advancedSetting2.forEach(item => {
        item.configValue = `${item.isShow ? '1' : '0'}${item.isRequired ? '1' : '0'}`
      })
      // 短信通知 subjectSmsNotice
      let subjectSmsNotice = this.sysCustConfig.subjectSmsNotice[0]
      subjectSmsNotice.configValue = this.subjectSmsNotice ? '1' : '0'
      let query = [...this.taskTime, ...this.taskScore, ...this.taskWordsRequire, ...this.taskSingleLimit, ...this.advancedSetting, taskSmsNotice, ...this.subjectStartEndTime, ...this.subjectScore, ...this.advancedSetting2, subjectSmsNotice, ...this.drillSetting]
      this.postAdd(query)
    },
    // 提交、
    async postAdd (query) {
      try {
        let res = await sysCustConfigBatchUpdate(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
      } catch (error) {
        console.log(error)
      }
    },

    //获取赣州网站列表
    async getSysCustWebsiteList () {
      // 如果集合大于0就配置了
      let query = {
        custId: this.sysCust.custId
      }
      try {
        let res = await sysCustWebsiteList(query)
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.isDisbledPlatform = res.returnData.length > 0 ? false : true
      } catch (error) {
        console.log(error)
      }
    },
    //上报中心-专题积分更改 按平台
    changeSubjectScoreValue (val) {

      //   // 设置 上报中心-高级设置-勾选项 是否勾选 是否禁用
      this.setAdvancedSetting2Value()
      this.oldSubjectScoreValueConfigCheck = val
    },
    // 设置 上报中心-高级设置-勾选项 是否勾选 是否禁用
    // 初始  及 专题积分勾选时联动
    setAdvancedSetting2Value () {

      // 媒体平台、发布类型、作者类别、作者姓名、网名/昵称、阅读数量、转载数量
      // 依次新增“媒体平台、发布类型、作者类别、作者姓名、网名/昵称、阅读数量、转载数量”信息项，
      //该信息的显示必填勾选将决定投稿员提交作品时需要填写的信息项。
      // （当平台积分被设为默认时，新增的信息项均勾选，除（媒体平台、发布类型、截图附件）不可以取消勾选外其他均可以取消勾选）

      // 按平台要勾选的信息项
      let advancedSetting2Title = ['subjectMediaPlatform', 'subjectArticleUrl', 'subjectArticleTitle', 'subjectPublishType', 'subjectAuthorType',
        'subjectAuthorName', 'subjectNickName', 'subjectReadNum', 'subjectReprintNum', 'subjectArticleFile']
      // 按平台要禁用的信息项
      let advancedSetting2TitleDisable = ['subjectMediaPlatform', 'subjectPublishType']
      // 一直要禁用的勾选项 截图附件的必填可编辑 
      let advancedSetting2TitleDisableAll = ['subjectArticleFile']
      //截图附件 是所有都不可以取消  另外2个是按照 选中按平台
      this.advancedSetting2.forEach((item) => {
        if (this.subjectScoreValue.configCheck == this.subjectScoreId) {
          // 判断是否按平台 //当平台积分被设为默认时，新增的信息项均勾选
          if (advancedSetting2Title.indexOf(item.childConfigType) !== -1) {
            item.isShow = true
            item.isRequired = true
          } else {
            item.isShow = false
            item.isRequired = false
          }
          if (advancedSetting2TitleDisable.indexOf(item.childConfigType) !== -1) {
            item.isDisbled = true
            item.isDisbledRequired = true
          }
        } else {
          if (advancedSetting2TitleDisable.indexOf(item.childConfigType) !== -1) {
            item.isDisbled = false
            item.isDisbledRequired = false
          }
          // 之前的 按平台是禁用的 则清除所有的勾选
          if (this.oldSubjectScoreValueConfigCheck == this.subjectScoreId) {
            item.isShow = false
            item.isRequired = false
          }
        }
        if (advancedSetting2TitleDisableAll.indexOf(item.childConfigType) !== -1) {
          item.isShow = true
          item.isRequired = true
          item.isDisbled = true
        }

      })
    },
    // 设置 上报中心-高级设置-勾选项 是否禁用
    setAdvancedSetting2Disable () {
      // 按平台要禁用的信息项
      let advancedSetting2TitleDisable = ['subjectMediaPlatform', 'subjectPublishType']
     
      this.advancedSetting2.forEach((item) => {
        if (this.subjectScoreValue.configCheck == this.subjectScoreId) {
          if (advancedSetting2TitleDisable.indexOf(item.childConfigType) !== -1) {
            item.isShow = true
            item.isRequired = true
            item.isDisbled = true
            item.isDisbledRequired = true
          }
        } else {
          if (advancedSetting2TitleDisable.indexOf(item.childConfigType) !== -1) {
            item.isDisbled = false
            item.isDisbledRequired = false
          }
        }
      })
    }
  },
  beforeDestroy () {

  },
  watch: {

  }
};
</script>
<style scoped lang="scss">
@import "./css/parameterConfig.scss";
</style>
