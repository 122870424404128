<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <SearchCard
          title="上报审核"
          @handlerSearch="handlerSearch"
          class="title-wp-card"
        >
          <template v-slot:searchContent>
            <TaskTimeSelect
              :activeType="searchParams.timeType"
              :taskType="timeType"
              ref="taskTime"
              @timeSelect="timeSelect"
            ></TaskTimeSelect>

            <wpLoadSelect
              class="search-w392 wp-select align-top"
              v-model="searchParams.topicId"
              :data="topicList"
              :page="topicObj.page"
              dictLabel="title"
              dictValue="topicId"
              dictTime="createTime"
              :hasMore="topicObj.more"
              :request="getData"
              @clearTitle="() => (topicObj.title = '')"
              placeholder="请输入专题名称"
            ></wpLoadSelect>

            <el-select
              class="search-w190 wp-select wp-multiple"
              v-model="searchParams.topicTypeIds"
              multiple
              collapse-tags
              filterable
              placeholder="请选择专题分类"
            >
              <el-option
                v-for="item in classifyList"
                :key="item.topicTypeId"
                :label="item.topicTypeName"
                :value="item.topicTypeId"
              >
              </el-option>
            </el-select>

            <el-cascader
              ref="cascaderRef"
              class="search-w190 wp-cascader align-top"
              :options="groupList"
              @change="changeCascader"
              :props="defaultProps"
              collapse-tags
              placeholder="请选择所在分组"
              clearable
            >
            </el-cascader>

            <el-select
              class="search-w190 wp-select align-top"
              v-model="searchParams.articleStatus"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                v-for="item in $globalData.REVIEWSTATUS"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </SearchCard>

        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-14 flex">
              <div>
                上报总量：<span class="wp-blue">{{ countData.REPORTNUM }}</span>
              </div>
              <div class="ml-30">
                奖励积分：<span class="wp-blue">{{ countData.SCORE }}</span>
              </div>
            </div>

            <div class="export-box">
              <el-popover
                popper-class="export-popover"
                ref="export-popover"
                placement="bottom"
                trigger="click"
              >
                <div class="export-list">
                  <div class="export-item wp-pointer" @click="collectExport">
                    汇总导出
                  </div>
                  <div class="export-item wp-pointer" @click="exportFile">
                    作品导出
                  </div>
                </div>
                <el-button
                  style="width: 90px"
                  slot="reference"
                  class="wp-button"
                  size="medium"
                >
                  导出<i class="icon-zhankai el-icon--right"></i
                ></el-button>
              </el-popover>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column min-width="40"> </el-table-column>
              <el-table-column
                min-width="120"
                align="left"
                prop="title"
                label="作品标题"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="
                      goDetail(scope.row.topicArticleId, scope.$index)
                    "
                    style="cursor: pointer"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.title"
                      placement="top"
                      :disabled="!isShowTooltipWidth"
                      popper-class="wp-visibilityPopper"
                    >
                      <p
                        class="textOverOneLine"
                        @mouseenter="visibilityChangeWidth($event)"
                      >
                        {{ scope.row.title ? scope.row.title : '-' }}
                      </p>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="120"
                align="left"
                prop="url"
                label="作品链接"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="$utils.download(scope.row.url)"
                    style="cursor: pointer"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.title"
                      placement="top"
                      :disabled="!isShowTooltipWidth"
                      popper-class="wp-visibilityPopper"
                    >
                      <p
                        class="textOverOneLine"
                        @mouseenter="visibilityChangeWidth($event)"
                      >
                        {{ scope.row.url ? scope.row.url : '-' }}
                      </p>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="custName"
                width="80"
                label="截图/附件"
              >
                <template slot-scope="scope">
                  <div
                    @click.stop="
                      goDetail(scope.row.topicArticleId, scope.$index)
                    "
                    style="cursor: pointer"
                  >
                    <p
                      v-if="
                        !scope.row.fileJson ||
                        JSON.parse(scope.row.fileJson).length == 0
                      "
                    >
                      -
                    </p>
                    <p
                      v-else-if="$utils.isImg(scope.row.fileJson)"
                      style="cursor: pointer"
                    >
                      <el-popover
                        placement="right-start"
                        :visible-arrow="false"
                        trigger="hover"
                        popper-class="img-popper"
                        :tabindex="3"
                      >
                        <el-image
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                          class="scaled-image"
                          fit="contain"
                        ></el-image>
                        <img
                          style="width: 22px; height: 22px"
                          slot="reference"
                          :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        />
                      </el-popover>
                    </p>

                    <img
                      v-else
                      style="width: 22px; height: 22px"
                      slot="reference"
                      :src="$utils.handlePreviewImg(scope.row.fileJson)"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="80"> </el-table-column>
              <el-table-column
                min-width="120"
                show-overflow-tooltip
                align="left"
                prop="createName"
                label="人员姓名"
              >
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                align="center"
                width="150"
                prop="createTime"
                label="上报时间"
              >
                <template slot-scope="scope">
                  {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                </template>
              </el-table-column>
              <el-table-column min-width="10"> </el-table-column>
              <el-table-column
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div>
                    <div
                      class="flex-center"
                      v-if="scope.row.articleStatus == 1"
                    >
                      <div class="receive-circle"></div>
                      <div class="receive-color">已通过</div>
                    </div>
                    <div class="flex-center" v-else>
                      <div class="noreceive-circle"></div>
                      <div class="noreceive-color">已驳回</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                  <div class="step flex-center">
                    <div class="wp-action-icon">
                      <el-popover
                        :ref="`popover-${scope.$index}`"
                        placement="top"
                        trigger="click"
                        width="220"
                      >
                        <el-input-number
                          @input.native="changeScore($event)"
                          :min="0"
                          :precision="1"
                          :max="10000"
                          size="small"
                          v-model="editRow.totalScore"
                          style="margin-bottom: 5px"
                        >
                        </el-input-number>
                        <el-button
                          @click="submitScore(editRow)"
                          style="margin-left: 6px"
                          type="primary"
                          size="small"
                          >确定</el-button
                        >
                        <el-tooltip
                          class="item"
                          slot="reference"
                          effect="dark"
                          content="改分"
                          :visible-arrow="false"
                          popper-class="wp-btn-tooltip"
                          placement="top"
                        >
                          <el-button
                            @click="edit(scope.row)"
                            type="text"
                            icon="icon-bianji1"
                            class="wp-btn-icon-big"
                          >
                          </el-button>
                        </el-tooltip>
                      </el-popover>
                    </div>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="驳回"
                      placement="top"
                      :visible-arrow="false"
                      popper-class="wp-btn-tooltip"
                    >
                      <RejectCause
                        placeholder="请输入审核意见"
                        v-if="scope.row.articleStatus == 1"
                        popoverType="add"
                        @handlerReject="handlerReject($event, scope.row)"
                      >
                        <i class="icon-bohui wp-blue wp-pointer fs-20"></i>
                      </RejectCause>
                    </el-tooltip>

                    <RejectCause
                      v-if="scope.row.articleStatus !== 1"
                      :key="scope.row.topicArticleId"
                      popoverType="detail"
                      :rejectObj="rejectObj"
                    >
                      <i
                        class="icon-bohui wp-pointer fs-20"
                        @mouseenter="getReject(scope.row.topicArticleId)"
                        style="color: #ccc"
                      ></i>
                    </RejectCause>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="searchParams.page"
            :pageSize="searchParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <div ref="hideDiv"></div>
  </div>
</template>

<script>
import reportedApi from '@/api/reported'

import SearchCard from '@/views/components/searchCard'
import TaskTimeSelect from '@/views/components/taskTimeSelect'
import RejectCause from '@/views/components/rejectCause'

export default {
  name: 'taskReview',
  components: { SearchCard, TaskTimeSelect, RejectCause },
  data () {
    return {
      topicObj: {
        page: 1, // 页码
        more: true, // 是否还有下一页
        title: "", // 专题名称
        limit: 10, // 页大小
        sort: 2,
      },
      topicList: [], // 专题下拉框列表
      // 查询条件
      searchParams: {
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        topicId: "", // 所选任务ID
        peopleName: "", // 人员姓名
        timeType: 2, // 任务时间类型
        groupIds: [], // 组Id
        articleStatus: "",
        page: 1,
        limit: 30,
        topicTypeIds: [],
        orderType: "desc"
      },
      classifyList: [],
      timeType: [
        {
          name: "专题创建时间",
          id: 2
        },
        {
          name: "作品提交时间",
          id: 1
        }
      ],
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName',
        value: "groupId",
        multiple: true
      },
      total: 0,
      // 任务列表
      taskList: [],
      tableData: [],
      loading: false,
      editRow: {}, // 表格行内信息
      multipleSelection: [],
      groupList: [], // 分组树
      countData: {}, // 提交总数,奖励积分
      rejectObj: {},
      queryData: {},
      isShowTooltipWidth: false,
    };
  },
  created () {
    // 获取审核列表
    this.getReviewList()
    // 获取分组树
    this.getGroupTree()
    // 获取专题分类
    this.getClassifyList()
  },
  methods: {
    changeScore (e) {
      console.log(e.target.value)
      if ((e.target.value.indexOf('.') >= 0)) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 2)
      }
      console.log(e.target.value)
    },

    async getClassifyList () {
      const res = await reportedApi.topicTypeList({ topicTypeName: "" })
      if (res.returnCode == 200) {
        this.classifyList = res.returnData
      }
    },
    // 选择分组回调
    changeCascader (ids) {
      const idList = []
      ids?.forEach(item => {
        if (item.length > 1) {
          item?.forEach(value => {
            idList.push(value)
          })
        } else {
          idList.push(item[0])
        }
      })
      // 去掉多余选中的父分组Id
      this.searchParams.groupIds = [...new Set(idList)]
    },

    // 获取驳回信息
    async getReject (topicArticleId) {
      const res = await reportedApi.getRejectArticleInfo({ topicArticleId })
      if (res.returnCode == 200) {
        this.rejectObj = res.returnData
      }
    },

    // 获取分组树
    async getGroupTree () {
      const res = await reportedApi.groupTree({})
      if (res.returnCode == 200) {
        this.groupList = res.returnData
      }
    },

    // 获取审核列表
    async getReviewList () {
      if (this.loading) return
      this.loading = true

      try {
        this.queryData = { ...this.searchParams }
        const res = await reportedApi.peopleReportedList(this.queryData)
        this.loading = false
        if (res.returnCode == 200) {
          const data = res.returnData

          this.tableData = data.data
          this.countData = res.total
          this.total = data.totalCount
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 传入给load-select组件的函数
    async getData (data) {
      // 自定义内容搜索时,清空原数据列表
      if (data.clear) {
        this.topicList = []
        this.topicObj = { ...this.topicObj, ...data }
      }

      const res = await reportedApi.topicSelectList({ ...this.topicObj })
      if (res.returnCode == 200) {
        const ret = res.returnData

        const obj = {};
        // 专题列表
        this.topicList = [...this.topicList, ...ret.data].reduce((prev, curr) => {
          obj[curr.topicId] ? '' : obj[curr.topicId] = true && prev.push(curr);
          return prev;
        }, [])

        // 当前页小于总页数,+1
        if (this.topicObj.page < ret.totalPageNum) {
          this.topicObj.page++
          this.topicObj.more = true
        } else {
          this.topicObj.more = false
        }
      }
    },

    /** 汇总导出 */
    async collectExport (event) {
      const query = { ...this.queryData }
      query.orderType = 'asc'

      try {
        if (this.isExporting2) return
        this.isExporting2 = true
        const res = await reportedApi.topicInfoArticleExport(query)
        this.isExporting2 = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting2 = false
      }
    },

    /** 导出 */
    async exportFile (event) {
      this.queryData.exportParam = 2

      try {
        if (this.isExporting) return
        this.isExporting = true
        const res = await reportedApi.topicArticleExport(this.queryData)
        this.isExporting = false

        if (res.returnCode == 200) {
          this.$store.dispatch('app/GetDownList')
          this.$utils.setBall(event)
        } else {
          if (Number(res.returnCode) == 1009) return this.$showMessage({
            type: 'warning',
            msg: '文件正在下载，请在下载中心查看'
          })
          this.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        this.$refs['export-popover'].doClose()
      } catch (error) {
        this.isExporting = false
      }
    },

    // 时间类型选择回调
    timeSelect (data) {
      console.log(data)
      this.searchParams = { ...this.searchParams, ...data }
    },

    // 编辑分数
    edit (item) {
      console.log(item)
      this.editRow = JSON.parse(JSON.stringify(item))
    },

    // 详情
    goDetail (id, idx) {
      const acticleIdx = (this.searchParams.page - 1) * this.searchParams.limit + idx
      this.$router.push({
        path: 'reportedReviewDetail',
        query: {
          id,
          acticleIdx,
          params: JSON.stringify(this.searchParams)
        }
      })
    },

    /** 提交驳回信息回调 */
    async handlerReject (rejectMsg, item) {
      const query = {
        rejectMsg,
        articleId: item.topicArticleId
      }
      console.log(query);
      const res = await reportedApi.rejectArticle(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', msg: '操作成功' })
        this.getReviewList()
      }
    },

    // 提交编辑分数
    async submitScore (item, index) {
      let query = {
        topicArticleId: item.topicArticleId,
        rewardScore: item.totalScore
      }
      console.log(query);
      const res = await reportedApi.updateScore(query)
      if (res.returnCode == 200) {
        this.$showMessage({ type: 'success', message: '操作成功' })
        // 手动关闭弹出框
        this.$refs.hideDiv.click()
        this.getReviewList()
      }
    },

    // 搜索
    handlerSearch () {
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 表格勾选回调
    handleSelectionChange (arr) {
      this.multipleSelection = arr
    },

    // 页大小
    handleSizeChange (size) {
      this.searchParams.limit = size
      this.searchParams.page = 1
      this.getReviewList()
    },

    // 页码
    handleCurrentChange (current) {
      this.searchParams.page = current
      this.getReviewList()
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
  },
};
</script>


<style lang="scss">
.el-input-number--small .el-input__inner {
  padding-left: 35px;
  padding-right: 35px;
}

.export-popover.el-popover {
  padding: 0px;
  min-width: 0px;
  width: 90px;

  .export-list {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .export-item {
      width: 100%;
      text-align: center;
      color: #333;
      height: 36px;
      line-height: 36px;

      &:hover {
        background: rgba(3, 109, 255, 0.1);
      }
    }
  }
}

.export-box {
  margin-left: 12px;
}
</style>
<style scoped lang="scss"></style>
