<template>
  <div class="wp-task-card">
    <div class="fs-16 fw-bold">添加链接</div>

    <div class="textarea">
      <el-input
        type="textarea"
        placeholder="每一行一条链接，多个链接用回车换行进行输入"
        v-model="batchContent"
        :rows="21"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      batchContent: "", // 批量任务内容
    }
  },
  methods: {
    // 处理工具copy过来的内容
    batchTaskRule () {
      if (this.batchContent.trim() == '') {
        this.$showMessage({ type: 'error', msg: '请输入有效链接' })
        return
      }
      const batchContent = JSON.parse(JSON.stringify(this.batchContent))

      // 防止内容里有重复符号 --->  转成数组并去掉空值
      const strList = batchContent && batchContent.trim().replace(/\n|\r\n/g, '!!!!').split('!!!!').reduce((prev, curr) => {
        if ((curr ?? '') !== '') {
          prev.push(curr.trim())
        }
        return prev
      }, [])
      console.log(strList);

      const reg = /^(https?|http|ftp|file|rtsp|mms):\/\//g

      // 格式错误的数据的索引
      let errIndex = []
      strList.forEach((item, index) => {
        if (item.search(reg) === -1) {
          errIndex.push(index + 1)
        }
      })

      console.log(errIndex);
      // 有不符合格式的进行提示,反之传入下一步
      if (strList.length > 20) { // 最多批量发送20条任务
        this.$showMessage({
          type: 'error',
          msg: '最多支持20条链接，请分批创建'
        })
      } else if (errIndex.length) {
        errIndex = errIndex.join(',')
        this.$showMessage({
          type: 'error',
          msg: `第${errIndex}条链接无效，请输入有效链接`
        })
      } else {
        return strList
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>