<template>
  <div class="wp-w100">
    <el-scrollbar class="wp-scrollbar" style="height: 100%">
      <div class="main">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="wp-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/specialList' }"
            >专题列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>专题详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="wp-card mt-20 task-detail">
          <div class="task-head">
            <div class="task-state" v-if="receiverStatus == 2">已接收</div>
            <div class="finsh-state" v-if="receiverStatus == 3">已完成</div>
            <div class="textOverOneLine task-title fs-16 fw-bold">
              {{ topicData.title }}
            </div>
          </div>

          <!-- 任务信息 -->
          <div class="task-info mt-20 fs-14">
            <div class="flex">
              <div class="task-item">
                专题编号：<span>{{ topicData.topicNum }}</span>
              </div>
              <div class="task-item">
                创建时间：<span
                  >{{
                    $moment(topicData.createTime).format('YYYY-MM-DD HH:mm')
                  }}
                </span>
              </div>
              <div class="task-item textOverOneLine">
                创建人：<span>{{ topicData.createName }}</span>
              </div>
              <div class="task-item" style="margin-left: 10px">
                来源单位：<span>{{ topicData.custName }}</span>
              </div>
              <div class="task-item"></div>
            </div>

            <div class="mt-20 task-item">
              <div class="work-content">
                <div class="key">专题描述：</div>
                <p class="value">
                  {{ topicData.content }}
                </p>
              </div>
            </div>
            <div
              class="mt-20 task-item"
              v-if="topicData.fileJson && topicData.fileJson.length"
            >
              专题附件：
              <File
                style="margin-top: 10px"
                :fileJson="topicData.fileJson"
              ></File>
            </div>
          </div>
        </div>

        <div class="wp-card mt-16">
          <div class="task-score flex-CB">
            <div class="left flex">
              <el-avatar class="avatar" :src="headUrl"></el-avatar>

              <div class="flex-column-JC score-box">
                <div class="fs-16 flex">
                  <div class="fw-bold">专题积分</div>
                  <div class="orange score">
                    {{
                      topicData.score == -999
                        ? '按平台'
                        : '+' + topicData.score || 0
                    }}
                  </div>
                </div>

                <div class="flex time-box fs-14">
                  <div class="flex">
                    <div class="time-text">开始时间：</div>
                    <div>
                      {{
                        $moment(topicData.startTime).format('YYYY-MM-DD HH:mm')
                      }}
                    </div>
                  </div>
                  <div class="flex" style="margin-left: 32px">
                    <div class="time-text">结束时间：</div>
                    <div class="orange">
                      {{
                        topicData.longItem
                          ? '不限'
                          : $moment(topicData.endTime).format(
                              'YYYY-MM-DD HH:mm'
                            )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <el-button
                class="wp-button"
                @click="clickFinish"
                v-preventReClick
                type="primary"
                >上报贴文
              </el-button>
            </div>
          </div>
        </div>

        <!-- 我的作品 -->
        <div class="wp-card mt-16">
          <div class="flex-CB">
            <div class="fs-16 fw-bold">我的上报</div>
            <div class="fs-14">
              上报数量：<span class="wp-blue">{{ total }}</span>
            </div>
          </div>

          <div
            class="wp-table mt-20"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="正在加载"
          >
            <el-table :data="tableData" stripe v-if="tableData.length !== 0">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                width="70"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="fileJson"
                width="300"
                label="截图/附件"
              >
                <template slot-scope="scope">
                  <p
                    v-if="
                      !scope.row.fileJson ||
                      JSON.parse(scope.row.fileJson).length == 0
                    "
                  >
                    -
                  </p>
                  <p
                    v-else-if="$utils.isImg(scope.row.fileJson)"
                    style="cursor: pointer"
                  >
                    <el-popover
                      placement="right-start"
                      :visible-arrow="false"
                      trigger="hover"
                      popper-class="img-popper"
                      :tabindex="3"
                    >
                      <el-image
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                        class="scaled-image"
                        fit="contain"
                      ></el-image>
                      <img
                        style="width: 22px; height: 22px"
                        slot="reference"
                        :src="$utils.handlePreviewImg(scope.row.fileJson)"
                      />
                    </el-popover>
                  </p>

                  <img
                    v-else
                    style="width: 22px; height: 22px"
                    slot="reference"
                    :src="$utils.handlePreviewImg(scope.row.fileJson)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="上报时间"
              >
                <template slot-scope="scope">
                  <div>
                    {{
                      $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="totalScore"
                label="奖励积分"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.totalScore == -999
                      ? '按平台'
                      : scope.row.totalScore
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="articleStatus"
                label="审核状态"
              >
                <template slot-scope="scope">
                  <div class="flex-center" v-if="scope.row.articleStatus == 1">
                    <div class="receive-circle"></div>
                    <div class="receive-color">已通过</div>
                  </div>
                  <div class="flex-center" v-else>
                    <div class="noreceive-circle"></div>
                    <div class="noreceive-color">已驳回</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="wp-noData" v-if="tableData.length == 0 && !loading">
              <img src="~@/assets/images/noData.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <wp-pagination
            class="mt-21"
            :total="total"
            :currentPage="articleParams.page"
            :pageSize="articleParams.limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></wp-pagination>
        </div>
      </div>
    </el-scrollbar>

    <!-- 手动完成 -->
    <task-dialog
      v-if="taskVisible"
      title="上报贴文"
      width="680px"
      height="100%"
      maxHeight="530px"
      @cancel="taskCancel"
      @determine="submitForm"
      :dialogVisible.sync="taskVisible"
      @handleClose="taskCancel"
    >
      <div class="task-dialog">
        <div class="wp-form">
          <el-form
            class="wp-form"
            :model="taskForm"
            :rules="taskFormRules"
            ref="taskRef"
            label-width="100px"
          >
            <el-form-item
              v-if="setting.parmTitleShow"
              class="item-content"
              label="作品标题："
              prop="title"
              :rules="[
                {
                  required: setting.parmTitleMust,
                  trigger: 'blur',
                  message: '请输入作品标题',
                },
              ]"
            >
              <el-input
                class="wp-input w480"
                maxlength="100"
                v-model.trim="taskForm.title"
                placeholder="请输入作品标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="setting.parmUrlShow"
              class="item-content isUrl"
              label="作品链接："
              prop="url"
              :rules="[
                {
                  required: setting.parmUrlMust && !urlDisabled,
                  trigger: 'blur',
                  message: '请输入作品链接',
                },
              ]"
            >
              <el-input
                @blur="changeUrl"
                maxlength="2000"
                class="wp-input w480"
                v-model.trim="taskForm.url"
                placeholder="请输入作品链接"
                :disabled="urlDisabled"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="作品内容："
              prop="content"
              v-if="setting.parmContentShow"
              :rules="[
                {
                  required: setting.parmContentMust,
                  trigger: 'blur',
                  message: '请输入作品内容',
                },
              ]"
            >
              <el-input
                class="w480 textarea"
                type="textarea"
                maxlength="1000"
                :rows="6"
                v-model.trim="taskForm.content"
                placeholder="请输入作品内容"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="媒体平台："
              v-if="setting.mediaShow && mediaList.length"
              prop="media"
              :rules="[
                {
                  required: setting.mediaMust,
                  trigger: 'change',
                  message: '请选择媒体平台',
                },
              ]"
            >
              <el-select
                class="w480 wp-select"
                v-model="taskForm.media"
                filterable
                placeholder="请选择媒体平台"
                @change="changeMedia"
              >
                <el-option
                  v-for="item in mediaList"
                  :key="item.custWebsiteId"
                  :label="item.name"
                  :value="item.custWebsiteId"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="setting.mediaShow && !mediaList.length"
              class="item-content isUrl"
              label="媒体平台："
              prop="media"
              :rules="[
                {
                  required: setting.mediaMust,
                  trigger: 'blur',
                  message: '请输入媒体平台',
                },
              ]"
            >
              <el-input
                maxlength="30"
                class="wp-input w480"
                v-model.trim="taskForm.media"
                placeholder="请输入媒体平台"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="发布类型："
              prop="publishType"
              v-if="setting.publishShow"
              :rules="[
                {
                  required: setting.publishMust,
                  trigger: 'change',
                  message: '请选择发布类型',
                },
              ]"
            >
              <el-select
                class="w480 wp-select"
                v-model="taskForm.publishType"
                placeholder="请选择发布类型"
              >
                <el-option
                  v-for="item in publishList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="作者类型："
              prop="authorType"
              v-if="setting.authorTypeShow"
              :rules="[
                {
                  required: setting.authorTypeMust,
                  trigger: 'change',
                  message: '请选择作者类型',
                },
              ]"
            >
              <el-select
                class="w480 wp-select"
                v-model="taskForm.authorType"
                placeholder="请选择作者类型"
              >
                <el-option
                  v-for="item in authTypeList"
                  :key="item.dictId"
                  :label="item.dataKey"
                  :value="item.dataKey"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-row class="w580">
              <el-col :span="12">
                <el-form-item
                  label="作者姓名："
                  prop="author"
                  v-if="setting.authorShow"
                  :rules="[
                    {
                      required: setting.authorMust,
                      trigger: 'blur',
                      message: '请输入作者姓名',
                    },
                  ]"
                >
                  <el-input
                    maxlength="30"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.author"
                    placeholder="请输入作者姓名"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="网名/昵称："
                  prop="netName"
                  v-if="setting.netNameShow"
                  :rules="[
                    {
                      required: setting.netNameMust,
                      trigger: 'blur',
                      message: '请输入网名昵称',
                    },
                  ]"
                >
                  <el-input
                    maxlength="30"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.netName"
                    placeholder="请输入网名昵称"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row class="w580">
              <el-col :span="12">
                <el-form-item
                  label="阅读数量："
                  prop="readNum"
                  v-if="setting.readNumShow"
                  :rules="[
                    {
                      required: setting.readNumMust,
                      trigger: 'blur',
                      message: '请输入阅读数量',
                    },
                  ]"
                >
                  <el-input
                    @input="resetReadNum"
                    maxlength="8"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.readNum"
                    placeholder="请输入阅读数量"
                  >
                    <i slot="suffix" class="unit">万</i>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="转载数量："
                  prop="reprint"
                  v-if="setting.reprintShow"
                  :rules="[
                    {
                      required: setting.reprintMust,
                      trigger: 'blur',
                      message: '请输入转载数量',
                    },
                  ]"
                >
                  <el-input
                    @input="resetReprint"
                    @blur="reprintBlur"
                    maxlength="8"
                    class="wp-input form-w200"
                    v-model.trim="taskForm.reprint"
                    placeholder="请输入转载数量"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              label="全网推送："
              v-if="setting.parmPushShow"
              :rules="[
                {
                  required: setting.parmPushMust,
                  trigger: 'change',
                  message: '请选择全网推送',
                },
              ]"
            >
              <el-radio-group v-model="taskForm.parmPush">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              label="截图/附件："
              prop="fileJson"
              v-if="setting.parmAttachShow"
              :rules="[
                {
                  required: setting.parmAttachMust,
                  trigger: 'blur',
                  message: '请提交截图/附件',
                },
              ]"
            >
              <UploadFile
                ref="upload"
                key="img"
                @fileChange="fileChange"
                @changeUpload="changeUpload"
              ></UploadFile>
            </el-form-item>

            <el-form-item label="奖励积分：" required>
              <div>
                <span
                  >基础分:<span style="color: #faa548">{{
                    taskForm.baseScore
                  }}</span></span
                >
                <span v-if="rewardScore > 0"
                  >+ 转载分:<span style="color: #faa548">{{
                    rewardScore > 0 ? '+' + rewardScore : ''
                  }}</span></span
                >
                <span v-if="wpTopicPushScore > 0 && taskForm.parmPush == '1'"
                  >+ 推送分:<span style="color: #faa548">{{
                    wpTopicPushScore
                  }}</span></span
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </task-dialog>
  </div>
</template>

<script>
import { validTaskURL, resetNum, resetNumberDecimalPoints } from '@/utils/validate.js';
import { mapGetters } from 'vuex';
import File from '@/views/components/fileDetail'
import wpyReportedApi from '@/api/wpyReported.js'
import UploadFile from '@/views/components/reportedUpload'

export default {
  components: { UploadFile, File },
  data () {
    return {
      isUpload: false,
      publishList: [], // 发布类型
      authTypeList: [], // 作者类别
      mediaList: [], // 媒体平台
      tableData: [],
      activeFinish: 1, // 1已完成 0未完成
      groupingName: "", // 分组名称
      transmitVisible: false, // 转发弹窗
      taskVisible: false, // 手动完成弹窗
      taskForm: {
        taskName: '', // 任务名称
        title: '', // 任务标题
        url: '', // 作品链接
        content: '', // 作品内容
        fileJson: '', // 截图/附件
        media: "",
        publishType: "", // 发布类型
        baseScore: 0, // 分数
        parmPush: '0',// 全网推送
        reprint: 0,
        authorType: '',
        author: "",
        netName: "",
        readNum: '',
        score: 0, // 分数
      },
      loading: false,
      taskFormRules: {
        taskName: { required: true, trigger: 'blur', message: '' },
        title: { required: true, trigger: 'blur', message: '请输入作品标题' },
        url: { required: true, trigger: 'blur', message: '请输入作品链接' },
        content: { required: true, trigger: 'blur', message: '请输入作品内容' },
        fileJson: { required: true, trigger: 'blur', message: '请提交截图附件' }
      },
      ids: {}, // 专题Id与接收人Id
      topicData: {}, // 专题信息
      articleParams: { // 上报查询信息
        page: 1,
        limit: 10,
      },
      total: 0, // 上报总条数
      setting: {},
      verifyUrl: true, // 校验作品链接是否通过 
      isPlatform: false,// 专题任务是否为按平台
      headUrl: require("@/assets/images/touxiang.png"),
      mediaName: "", // 平台名称
      publishTypeName: "", // 发布类型名称
      receiverStatus: 2,
      rewardScore: 0,
      rewardList: [],
      wpTopicPushScore: 0, // 推送分
      isPosting: false,
      mediaType: '',
      urlDisabled: false,
      linkVering: false // 是否正在检验链接
    }
  },
  computed: {
    ...mapGetters(['sysCust']),
    // 是否赣州
    isGZ () {
      return this.sysCust.custId == 2
    }
  },
  created () {
    if (this.$route.query?.topicId) {
      this.ids.topicId = this.$route.query?.topicId
      this.ids.topicReceiverId = this.$route.query?.topicReceiverId
      this.articleParams.topicId = this.$route.query?.topicId

      // 获取专题详情
      this.getTopicDetail()
      // 获取我的上报
      this.getTopicArticleList()
    }
  },
  watch: {
    /** 监听媒体平台 */
    'taskForm.media': {
      handler (newVal) {
        if (newVal && this.mediaList.length) {
          const custWebsite = this.mediaList.find(it => it.custWebsiteId == newVal)
          this.mediaName = custWebsite?.name
          this.mediaType = custWebsite.mediaType || ''
          this.mediaType && this.reprintBlur()
          this.getPublishTypeList(newVal)
        }
      },
    },

    // 发布类型
    'taskForm.publishType': {
      handler (newVal) {
        console.log(newVal);
        if (newVal) {
          const item = this.publishList.find(item => item.id == newVal)
          this.publishTypeName = item?.typeName || ""

          if (this.topicData.score == -999) {
            this.taskForm.baseScore = item?.score || 0
          } else {
            this.taskForm.baseScore = this.topicData.score || 0
          }
          console.log(this.topicData.score, this.taskForm.baseScore)
        }
      },
    },
  },

  methods: {
    // 是否有文件在上传中
    changeUpload (isUpload) {
      console.log(isUpload);
      this.isUpload = isUpload
    },

    // 阅读数量
    resetReadNum (e) {
      this.taskForm.readNum = resetNumberDecimalPoints(e, 4)
    },

    // 转发数量
    resetReprint (e) {
      this.taskForm.reprint = resetNum(e)
    },

    // 监听链接输入框
    async changeUrl () {
      const index = await this.$refs['taskRef'].fields.findIndex(item =>
        item?.$el?._prevClass?.indexOf("isUrl") !== -1
      )

      if (this.taskForm.url === '' && this.setting.parmUrlMust) {
        this.verifyUrl = await false
        this.$refs['taskRef'].fields[index].validateMessage = await '请输入作品链接'
        this.$refs['taskRef'].fields[index].validateState = await 'error'
      } else if (!validTaskURL(this.taskForm.url) && this.taskForm.url) {
        this.verifyUrl = await false
        this.$refs['taskRef'].fields[index].validateMessage = await '请输入有效作品链接'
        this.$refs['taskRef'].fields[index].validateState = await 'error'
      } else {
        this.verifyUrl = await true
        this.$refs['taskRef'].fields[index].validateState = await 'success'

        // 如果是按平台的任务,则获取平台信息
        if (this.topicData.score == -999) {
          this.getPlatform(this.taskForm.url)
        }
        // 校验链接重复性
        this.verifyDuplicateLinks()
      }
      console.log(this.verifyUrl);
    },

    /** 根据链接获取平台信息 */
    async getPlatform (url) {
      const res = await wpyReportedApi.urlToPlatform({ url, topicId: this.ids.topicId })
      if (res.returnCode == 200) {
        this.mediaName = res.returnData?.name
        this.$set(this.taskForm, 'media', res.returnData.custWebsiteId)
      }
    },

    /** 文件上传成功回调 */
    fileChange (fileList) {
      console.log(fileList);
      this.taskForm.fileJson = fileList
    },

    // 页大小
    handleSizeChange (size) {
      this.articleParams.limit = size
      this.articleParams.page = 1
      this.getTopicArticleList()
    },

    // 页码
    handleCurrentChange (current) {
      this.articleParams.page = current
      this.getTopicArticleList()
    },

    /** 获取专题详情 */
    async getTopicDetail () {
      const res = await wpyReportedApi.getTopicDetail({ ...this.ids })
      if (res.returnCode == 200) {
        this.topicData = res.returnData
        this.topicData.fileJson = JSON.parse(this.topicData.fileJson)
        console.log(this.topicData.fileJson);

        // 不是长期任务才显示已完成
        if (!res.returnData?.longItem) {
          this.receiverStatus = res.returnData?.isArticle ? 3 : 2
        }

        if (this.topicData.score == -999) {
          // 全网推送分，按平台才有
          this.wpTopicPushScore = res.returnData.wpTopicPushScore
          this.isPlatform = true
        } else {
          this.taskForm.baseScore = this.topicData.score || 0
          this.getPublishTypeList("")
        }

        this.taskForm.taskName = res.returnData.title
        this.setSetting(this.topicData)
      }
    },

    // 发布类型
    async getPublishTypeList (custWebsiteId) {
      const res = await wpyReportedApi.getPublishTypeList({ custWebsiteId })
      if (res.returnCode == 200) {
        this.publishList = res.returnData

        const item = res.returnData.find(item => item.defaultValue)
        console.log(item);
        if (item?.id) {
          this.$nextTick(() => {
            this.$set(this.taskForm, 'publishType', item.id)
          })
        } else {
          this.$nextTick(() => {
            this.$set(this.taskForm, 'publishType', "")
          })
        }

      }
    },

    // 设置作品提交的高级设置
    setSetting (topic) {
      // 显示标题
      this.setting.parmTitleShow = topic.parmTitle.charAt(0) == '1';
      // 标题必填
      this.setting.parmTitleMust = topic.parmTitle.charAt(1) == '1';

      // 显示链接
      this.setting.parmUrlShow = topic.parmUrl.charAt(0) == '1';
      // 链接必填
      this.setting.parmUrlMust = topic.parmUrl.charAt(1) == '1';

      // 显示内容
      this.setting.parmContentShow = topic.parmContent.charAt(0) == '1';
      // 内容必填
      this.setting.parmContentMust = topic.parmContent.charAt(1) == '1';

      // 显示平台
      this.setting.mediaShow = topic.parmMedia.charAt(0) == '1';
      // 平台必填
      this.setting.mediaMust = topic.parmMedia.charAt(1) == '1';
      if (this.setting.mediaShow && this.topicData.score == -999) {
        // 获取媒体平台列表
        this.getMediaList()
      }

      // 显示发布类型
      this.setting.publishShow = topic.parmPublishType.charAt(0) == '1';
      // 发布类型必填
      this.setting.publishMust = topic.parmPublishType.charAt(1) == '1';
      if (this.setting.publishShow && this.topicData.score != -999) {
        this.getPublishTypeList()
      }

      // 显示作者类型
      this.setting.authorTypeShow = topic.parmAuthorType.charAt(0) == '1';
      // 作者类型必填
      this.setting.authorTypeMust = topic.parmAuthorType.charAt(1) == '1';
      // 获取作者类型
      if (this.setting.authorTypeShow) {
        // 获取作者类型
        this.getAuthType()
      }

      // 显示作者姓名
      this.setting.authorShow = topic.parmAuthor.charAt(0) == '1';
      // 作者姓名必填
      this.setting.authorMust = topic.parmAuthor.charAt(1) == '1';

      // 显示 网名/昵称
      this.setting.netNameShow = topic.parmNetName.charAt(0) == '1';
      // 网名/昵称 必填
      this.setting.netNameMust = topic.parmNetName.charAt(1) == '1';

      // 显示阅读数量
      this.setting.readNumShow = topic.parmReadNum.charAt(0) == '1';
      // 阅读数量必填
      this.setting.readNumMust = topic.parmReadNum.charAt(1) == '1';

      // 显示转载数量
      this.setting.reprintShow = topic.parmReprint.charAt(0) == '1';
      // 转载数量必填
      this.setting.reprintMust = topic.parmReprint.charAt(1) == '1';
      //全网推送
      this.setting.parmPushShow = topic.parmPush ? topic.parmPush.charAt(0) == '1' : false;
      this.setting.parmPushMust = topic.parmPush ? topic.parmPush.charAt(1) == '1' : false;
      // 显示附件
      this.setting.parmAttachShow = topic.parmAttach.charAt(0) == '1';
      // 附件必填
      this.setting.parmAttachMust = topic.parmAttach.charAt(1) == '1';
    },

    // 获取媒体平台列表
    async getMediaList () {
      console.log(this.sysCust);
      const res = await wpyReportedApi.getWebsiteList({ custId: this.topicData.custId })
      if (res.returnCode == 200) {
        this.mediaList = res?.returnData || []

        // 媒体平台列表为空时调接口取默认发布类型
        if (this.mediaList?.length === 0) {
          this.getPublishTypeList("")
        }
      }
    },

    // 作者类别
    async getAuthType () {
      const res = await wpyReportedApi.topicAuthorTypeList({})
      if (res.returnCode == 200) {
        this.authTypeList = res?.returnData || []
      }
    },

    /** 我的上报 */
    async getTopicArticleList () {
      if (this.loading) return
      this.loading = true

      try {
        const res = await wpyReportedApi.getTopicArticleList({ ...this.articleParams })
        this.loading = false
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.tableData = ret.data
          this.total = ret.totalCount
        }

      } catch (error) {
        this.loading = false
      }
    },

    // 点击手动完成 
    clickFinish () {
      if (this.topicData.topicId) {
        this.taskVisible = true
        // 针对于“积分按平台”的场景
        if (this.topicData.score == -999) {
          this.getReward()
        }
      }
    },

    // 关闭手动完成弹窗
    taskCancel () {
      this.taskForm = {
        taskName: '', // 任务名称
        title: '', // 任务标题
        url: '', // 作品链接
        fileJson: "", // 附件
        content: '', // 作品内容
        publishType: "", // 发布类型
        score: 0, // 分数
        parmPush: '0',// 全网推送
        baseScore: 0,
        readNum: '',// 阅读数量
        reprint: 0,
        netName: '',
        author: "",
        authorType: '',
        media: ''
      }
      this.urlDisabled = false
      // 清除表单信息
      if (this.topicData.score == -999) {
        this.taskForm.baseScore = 0
        this.publishList = []
      } else {
        this.getPublishTypeList("")
      }

      this.publishList = []
      this.$refs.taskRef.resetFields()
      this.$refs?.upload?.resetFile()

      this.isUpload = false
      this.verifyUrl = true
      this.taskVisible = false
      this.rewardScore = 0
    },

    // 手动完成
    async submitForm () {

      if (this.verifyUrl) {
        if (this.isUpload) {
          this.$showMessage({ type: 'warning', msg: '文件上传中，请稍后操作' })
        } else {
          this.$refs.taskRef.validate(async valid => {
            if (valid) {
              const query = { ...this.taskForm }
              query.media = this.mediaName || query.media
              query.publishType = this.publishTypeName
              query.topicId = this.ids.topicId
              query.topicReceiverId = this.ids.topicReceiverId
              query.rewardScore = this.rewardScore
              query.pushScore = this.taskForm.parmPush == '1' ? this.wpTopicPushScore : 0

              if (query?.fileJson?.length) {
                query.fileJson = JSON.stringify(query.fileJson)
              }
              // const isNoSimila=  await this.verifyAuthorAndTitle()
              // if(!isNoSimila) return
              if (this.isPosting) return
              this.isPosting = true
              console.log(query);
              const res = await wpyReportedApi.insertTopicArticle(query)
              this.isPosting = false
              if (res.returnCode == 200) {
                this.taskCancel()
                // this.taskVisible = false
                // this.$refs.taskRef.resetFields()
                // this.$refs.upload.resetFile()
                // 获取我的上报
                this.getTopicArticleList()
                this.$showMessage({ type: "success", msg: res.returnMsg })
              } else {
                this.$showMessage({ type: "error", msg: res.returnMsg })
              }
            } else {
              return false
            }
          })
        }
      }
    },

    // 获取二级队伍详情
    getDetail (item) {
      this.groupingVisible = true
      this.groupingName = item.date
      console.log(item)
    },
    // 转载数量 鼠标失焦事件
    reprintBlur () {
      this.rewardScore = 0
      // 针对于“积分按平台”的场景
      if (this.topicData.score != -999) return
      if (!this.taskForm.reprint) return
      if (this.taskForm.reprint.toString().trim() == '' || this.taskForm.reprint <= 0) return


      // 将taskForm.reprint数量传给 后端 请求接口后 ，后端给 奖励分值
      // 奖励分值 大于 0 则显示  小于等于则不显示
      // 从集合中获取对应的奖励分
      if (this.mediaType) {
        // 赣州指定的媒体平台 单独有奖励分
        const mediaReward = this.rewardList.find(it => it.mediaType == this.mediaType)
        if (mediaReward && this.taskForm.reprint >= mediaReward.start && this.taskForm.reprint <= mediaReward.end) {
          this.rewardScore = mediaReward.score
        }
      } else {
        this.rewardList.filter(it => !it.mediaType).forEach(it => {
          if (this.taskForm.reprint >= it.start && this.taskForm.reprint <= it.end) {
            this.rewardScore = it.score
          }
        })
      }
    },
    // 获取 奖励分的 数组集合
    async getReward () {
      try {
        const res = await wpyReportedApi.getReward({ custId: this.topicData.custId })
        if (res.returnCode == 200) {
          const ret = res.returnData
          this.rewardList = ret ? ret : []
        }

      } catch (error) {
        this.loading = false
      }
    },
    // 是赣州，且是按平台的，选择的是微信视频号的，清空链接并禁用链接
    changeMedia () {
      if (!this.isGZ) return
      if (!this.isPlatform) return
      if (this.taskForm.media) {
        let mediaObj = this.mediaList.find(it => it.custWebsiteId == this.taskForm.media)
        if (mediaObj.name.indexOf('微信视频号') !== -1) {
          this.taskForm.url = ''
          this.urlDisabled = true
          this.verifyUrl = true
          this.$refs['taskRef'].clearValidate()
        } else {
          this.urlDisabled = false
        }
      } else {
        this.urlDisabled = false
      }
    },
    //校验链接重复性
    async verifyDuplicateLinks () {
      if (!this.isGZ) return
      if (this.linkVering) return
      if (this.taskForm.url === '') return
      this.linkVering = true
      try {
        const res = await wpyReportedApi.existUrl({ url: this.taskForm.url })
        if (Number(res.returnCode) == 200) return
        if (Number(res.returnCode) != 80004) return
        const index = await this.$refs['taskRef'].fields.findIndex(item =>
          item?.$el?._prevClass?.indexOf("isUrl") !== -1
        )
        this.verifyUrl = false
        this.$refs['taskRef'].fields[index].validateMessage = await res.returnMsg
        this.$refs['taskRef'].fields[index].validateState = await 'error'
      } catch (error) {

      } finally {
        this.linkVering = false
      }
    },
    //相似数据
    verifyAuthorAndTitle () {
      if (!this.isGZ) return true
      const query = {
        title: this.taskForm.title, //标题
        author: this.taskForm.author //作者
      }
      return new Promise((resolve, reject) => {
        wpyReportedApi.similar(query).then(res => {
          if (Number(res.returnCode) == 200) return resolve(true)
          this.$showMessage({ type: 'warning', msg: res.returnMsg })
          resolve(false)
        }).catch(err => {
          reject(true)
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>