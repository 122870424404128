<template>
  <div class="asideList">
    <div class="head">
      <div class="search">
        <el-input
          class="search-w176 wp-input"
          v-model="searchContent"
          clearable
          placeholder="请输入搜索内容"
        >
          <i
            slot="prefix"
            class="wp-input-icon icon-sousuo"
            style="font-size: 20px"
          ></i>
        </el-input>
      </div>
    </div>

    <div
      class="complete flex-CB"
      :class="activeId == 0 ? 'active_item' : ''"
      @click="clickAll"
    >
      <div class="fs-16 fw-bold">全部</div>
      <div>
        <i
          class="el-input__icon icon-tianjia fs-20"
          @click="handlerItem(['add', { id: 0 }])"
        ></i>
      </div>
    </div>

    <div
      class="list-box"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="正在加载"
    >
      <el-scrollbar style="height: 100%">
        <div class="list">
          <div
            class="list-item flex-CB"
            :class="activeId == item[keyId] ? 'active_item' : ''"
            v-for="(item, index) in asideList"
            :key="item[keyId]"
            @click="getTitle(item, index)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :tabindex="item[keyId]"
              :disabled="!isShowTooltipWidth"
              :content="item[label]"
              placement="top"
            >
              <div
                class="item fs-14 textOverOneLine"
                @mouseenter="visibilityChangeWidth($event)"
              >
                {{ item[label] }}
              </div>
            </el-tooltip>

            <el-dropdown
              placement="bottom-end"
              trigger="click"
              class="wp-dropdown"
              @command="handlerItem"
            >
              <div class="icon" ref="icon">
                <i class="el-input_icon el-icon-more"></i>
              </div>
              <el-dropdown-menu
                slot="dropdown"
                :visibleArrow="false"
                class="wp-dropdown-menu"
              >
                <el-dropdown-item
                  :command="['edit', item, index]"
                  icon="icon-bianji1"
                  >修改名称</el-dropdown-item
                >
                <el-dropdown-item
                  :command="['remove', item, index]"
                  icon="icon-shanchu"
                  >删除</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="index !== 0"
                  :command="['shiftUp', item, index]"
                  icon="icon-shangyi"
                  >上移</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="index !== asideList.length - 1"
                  :command="['shiftDown', item, index]"
                  icon="icon-xiayi"
                  >下移</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 侧边栏列表
    asideList: {
      type: Array,
      default: []
    },
    // 唯一key
    keyId: {
      type: String,
      default: 'taskTypeId'
    },
    label: {
      type: String,
      default: 'taskTypeName'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchContent: "",
      activeId: 0,
      isShowTooltipWidth: false
    }
  },
  methods: {
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },

    // 点击全部
    clickAll () {
      this.activeId = 0
      const item = {
        [this.keyId]: '',
        [this.label]: '全部'
      }

      // this.$refs.icon.forEach((item, id) => {
      //   item.style.display = 'none'
      // })
      this.$emit("getTitle", item)
    },

    // 点击侧边栏单项数据
    getTitle (item, index) {
      // this.$refs.icon[index].style.display = 'block'
      // this.$refs.icon.forEach((item, id) => {
      //   if (index !== id) {
      //     item.style.display = 'none'
      //   }
      // })

      this.activeId = item[this.keyId]
      this.$emit("getTitle", item)
    },

    // 操作单项数据
    handlerItem (item) {
      const obj = item[1]
      obj.type = item[0]


      // 上移
      if (obj.type == 'shiftUp' || obj.type == 'shiftDown') {
        // 当前选中index
        let index = item[2]
        // 判断上移还是下移
        const movedIndex = obj.type == 'shiftUp' ? index - 1 : index + 1

        obj.moveId = this.asideList[index][this.keyId]
        obj.movedId = this.asideList[movedIndex][this.keyId]
      }

      this.$emit("handlerItem", obj)
    }
  },
  watch: {
    searchContent: {
      handler (newVal, oldVal) {
        this.$emit("handlerAsideSearch", newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>