<template>
  <div class="wp-drill-dialog notStarted">
    <el-dialog title="" :visible.sync="dialogVisible" width="900px" :before-close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="dialog-body">
        <div @click="handleClose" class="close-btn"></div>
        <div class="dialog-body-content ">
          <div class="flex-center">
            <div class="time-down flex-column-center">
              <div class="mode-label flex-CB">
                <span>开始倒计时</span>
              </div>
              <div class="count-down flex-Acenter">
                <p :style="'width:'+pWidth+'px;'">{{ distanceTime.hh }}</p>
                <span>:</span>
                <p :style="'width:'+pWidth+'px;'">{{ distanceTime.mm }}</p>
                <span>:</span>
                <p :style="'width:'+pWidth+'px;'">{{ distanceTime.ss }}</p>
              </div>
            </div>
          </div>
          <div class="flex-center">
            <div class="textOverOneLine title">
              {{ taskDetial.keyword }}
            </div>
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'personList',
  components: {},
  data () {
    return {
      dialogVisible: false,
      pWidth: 64,
      // distanceTime: {
      //   hh: '00',
      //   mm: '00',
      //   ss: '00',
      //   hm: '00'
      // },
      curSysDate: 0,
      timerOne: null,
    };
  },
  props: {
    distanceTimeP: {
      type: Object,
      default: () => { }
    },
    taskDetial: {
      type: Object,
      default: () => { }
    },
    startTime: {
      type: Number,
      default: 0
    },
    curSysDateNow: {
      type: Number,
      default: 0
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    // ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
    distanceTime () {
      return this.distanceTimeP
    }
  },
  beforeDestroy () {
    this.clearIntervalOne()
  },
  methods: {
    init () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.pWidth = this.$utils.getTextWidth(this.distanceTime.hh, '48px DS-Digital') + 18
        }, 500)
      })
      // let that = this
      // // that.countDown();
      // this.curSysDate = this.curSysDateNow
      // this.clearIntervalOne()
      // that.timerOne = setInterval(() => {
      //   that.countDown();
      // }, 50);
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.pWidth = this.$utils.getTextWidth(this.distanceTime.hh, '48px DS-Digital') + 18
      //   }, 500)
      // })
    },
    show () {
      this.dialogVisible = true
      this.init()
    },
    handleClose () {
      this.dialogVisible = false
    },
    clearIntervalOne () {
      clearInterval(this.timerOne)
      this.timerOne = null
    },
    countDown () {
      //currentDate // 当前时间 毫秒
      let diff = this.startTime - this.curSysDate; //开始时间减去当前时间 时间差的毫秒数
      // 开始时间 距离 现在时间的 时间戳 diff
      if (diff <= 1000) {
        this.distanceTime = {
          hh: '00',
          mm: '00',
          ss: '00',
          hm: '00'
        }
        return this.$emit('refresh')
      }
      this.distanceTime = this.countDistanceTime(diff);
      this.curSysDate += 50
      // console.log(this.distanceTime)
    },
    //计算倒计时
    countDistanceTime: function (date) {
      let timeObj = {
        hh: '00',
        mm: '00',
        ss: '00',
        hm: '00'
      };
      // //计算出小时数
      var hours = Math.floor(date / (3600 * 1000));
      // //计算剩下的分钟数
      var leave1 = date % (3600 * 1000);
      var minutes = Math.floor(leave1 / (60 * 1000));
      // //计算相差秒数
      var leave2 = leave1 % (60 * 1000);
      var seconds = Math.round(leave2 / 1000);
      timeObj = {
        hh: hours < 0 ? '00' : hours.toString().padStart(2, '0'),
        mm: minutes < 0 ? '00' : minutes.toString().padStart(2, '0'),
        ss: seconds < 0 ? '00' : seconds.toString().padStart(2, '0'),
        hm: '00'
      };
      return timeObj;
    },
  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.init()
        } else {
          this.clearIntervalOne()
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.notStarted {
  /deep/.el-dialog__body {
    width: 900px;
    height: 620px;
  }

  .dialog-body-content {
    width: 100%;
    height: 100%;
    height: 620px;
    background: url("~@/assets/images/drill/noStart.png") no-repeat center
      center;
    background-size: 100% 100%;
    // background-color: #1c5ac0;
  }

  .dialog-body {
    .time-down {
      margin-top: 68px;
      // width: 268px;
    }

    .count-down {
      // width: 100%;
      font-size: 48px;
      font-family: DS-Digital;
      font-weight: bold;
      color: #ffffff;

      p {
        min-width: 64px; /*no */
        height: 64px;
        background: #000000;
        border-radius: 10px;
        line-height: 64px;
        text-align: center;
        padding: 0px 8px; /*no */
        // box-sizing: border-box;
      }

      span {
        display: inline-block;
        width: 38px;
        text-align: center;
        line-height: 64px;
      }
    }

    .mode-label {
      width: 268px;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      line-height: 22px;
      margin-bottom: 14px;

      span {
        flex-shrink: 0;
        margin: 0px 10px;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 38%;
        height: 1px;
        background: rgba(255, 255, 255, 0.3);
      }

      &::before {
        width: 38%;
        content: "";
        display: inline-block;
        height: 1px;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .title {
    margin-top: 26px;
    width: 68%;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
  }
}
</style>
